import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createMerchantRequest,
  createMerchantSuccess,
  createMerchantFailure,
  updateMerchantFailure,
  updateMerchantRequest,
  updateMerchantSuccess,
  setMerchantDefaultLocationRequest,
  setMerchantDefaultLocationSuccess,
  setMerchantDefaultLocationFailure,
  deleteMerchantLocationRequest,
  deleteMerchantLocationSuccess,
  deleteMerchantLocationFailure,
  updateMerchantLocationRequest,
  updateMerchantLocationSuccess,
  updateMerchantLocationFailure,
  addMerchantLocationRequest,
  addMerchantLocationSuccess,
  addMerchantLocationFailure,
  addMerchantFeatureRequest,
  addMerchantFeatureSuccess,
  addMerchantFeatureFailure,
  toggleMerchantFeatureRequest,
  toggleMerchantFeatureSuccess,
  toggleMerchantFeatureFailure,
  deleteMerchantFeatureRequest,
  deleteMerchantFeatureSuccess,
  deleteMerchantFeatureFailure,
  addAgentWarehouseRequest,
  addAgentWarehouseSuccess,
  addAgentWarehouseFailure,
  removeAgentWarehouseRequest,
  removeAgentWarehouseSuccess,
  removeAgentWarehouseFailure,
  subscribeWebhookRequest,
  subscribeWebhookSuccess,
  subscribeWebhookFailure,
  toggleWebhookRequest,
  toggleWebhookSuccess,
  toggleWebhookFailure,
  deleteWebhookRequest,
  deleteWebhookSuccess,
  deleteWebhookFailure,
  testWebhookRequest,
  testWebhookSuccess,
  testWebhookFailure,
  toggleMessageChannelRequest,
  toggleMessageChannelSuccess,
  toggleMessageChannelFailure,
  deleteMessageChannelRequest,
  deleteMessageChannelSuccess,
  deleteMessageChannelFailure,
  toggleMessageNotificationTypeRequest,
  toggleMessageNotificationTypeSuccess,
  toggleMessageNotificationTypeFailure,
  createCloudPrinterRequest,
  createCloudPrinterSuccess,
  createCloudPrinterFailure,
  deleteCloudPrinterRequest,
  deleteCloudPrinterSuccess,
  deleteCloudPrinterFailure,
  postDeliveryExportTriggerRequest,
  postDeliveryExportTriggerSuccess,
  postDeliveryExportTriggerFailure,
  addCustomDeliveryPlanRequest,
  addCustomDeliveryPlanSuccess,
  addCustomDeliveryPlanFailure,
  deleteCustomDeliveryPlanRequest,
  deleteCustomDeliveryPlanSuccess,
  deleteCustomDeliveryPlanFailure,
} from '@/svc/delivery-svc/merchant/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createMerchant({ payload: data }: ReturnType<typeof createMerchantRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/sender', { data });

    if (response.ok) {
      yield put(createMerchantSuccess());
    } else {
      yield put(createMerchantFailure(response.data.message));
    }
  } catch (e) {
    yield put(createMerchantFailure((e as Error).message));
  }
}

function* updateMerchant({ payload: { id, data } }: ReturnType<typeof updateMerchantRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}`, { data });

    if (response.ok) {
      yield put(updateMerchantSuccess());
    } else {
      yield put(updateMerchantFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateMerchantFailure((e as Error).message));
  }
}

function* setMerchantDefaultLocation({ payload: { id, locationId } }: ReturnType<typeof setMerchantDefaultLocationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}/location/${locationId}/set_default`);

    if (response.ok) {
      yield put(setMerchantDefaultLocationSuccess());
    } else {
      yield put(setMerchantDefaultLocationFailure(response.data.message));
    }
  } catch (e) {
    yield put(setMerchantDefaultLocationFailure((e as Error).message));
  }
}

function* deleteMerchantLocation({ payload: { id, locationId } }: ReturnType<typeof deleteMerchantLocationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/location/${locationId}`);

    if (response.ok) {
      yield put(deleteMerchantLocationSuccess());
    } else {
      yield put(deleteMerchantLocationFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteMerchantLocationFailure((e as Error).message));
  }
}

function* updateMerchantLocation({ payload: { id, locationId, data } }: ReturnType<typeof updateMerchantLocationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}/location/${locationId}`, {
      data,
    });

    if (response.ok) {
      yield put(updateMerchantLocationSuccess());
    } else {
      yield put(updateMerchantLocationFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateMerchantLocationFailure((e as Error).message));
  }
}

function* addMerchantLocation({ payload: { id, data } }: ReturnType<typeof addMerchantLocationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/location`, {
      data,
    });

    if (response.ok) {
      yield put(addMerchantLocationSuccess());
    } else {
      yield put(addMerchantLocationFailure(response.data.message));
    }
  } catch (e) {
    yield put(addMerchantLocationFailure((e as Error).message));
  }
}

function* addMerchantFeature({ payload: { id, data } }: ReturnType<typeof addMerchantFeatureRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/user_feature`, { data });

    if (response.ok) {
      yield put(addMerchantFeatureSuccess());
    } else {
      yield put(addMerchantFeatureFailure(response.data.message));
    }
  } catch (e) {
    yield put(addMerchantFeatureFailure((e as Error).message));
  }
}

function* toggleMerchantFeature({ payload: { id, data } }: ReturnType<typeof toggleMerchantFeatureRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}/user_feature/toggle`, { data });

    if (response.ok) {
      yield put(toggleMerchantFeatureSuccess());
    } else {
      yield put(toggleMerchantFeatureFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleMerchantFeatureFailure((e as Error).message));
  }
}

function* deleteMerchantFeature({ payload: { id, data } }: ReturnType<typeof deleteMerchantFeatureRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/user_feature`, { data });

    if (response.ok) {
      yield put(deleteMerchantFeatureSuccess());
    } else {
      yield put(deleteMerchantFeatureFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteMerchantFeatureFailure((e as Error).message));
  }
}

function* addAgentWarehouse({ payload: { id, warehouseId } }: ReturnType<typeof addAgentWarehouseRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/warehouse/${warehouseId}`);

    if (response.ok) {
      yield put(addAgentWarehouseSuccess());
    } else {
      yield put(addAgentWarehouseFailure(response.data.message));
    }
  } catch (e) {
    yield put(addAgentWarehouseFailure((e as Error).message));
  }
}

function* removeAgentWarehouse({ payload: { id, warehouseId } }: ReturnType<typeof removeAgentWarehouseRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/warehouse/${warehouseId}`);

    if (response.ok) {
      yield put(removeAgentWarehouseSuccess());
    } else {
      yield put(removeAgentWarehouseFailure(response.data.message));
    }
  } catch (e) {
    yield put(removeAgentWarehouseFailure((e as Error).message));
  }
}

function* subscribeWebhook({ payload: { id, data } }: ReturnType<typeof subscribeWebhookRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/webhook/subscribe`, { data });

    if (response.ok) {
      yield put(subscribeWebhookSuccess());
    } else {
      yield put(subscribeWebhookFailure(response.data.message));
    }
  } catch (e) {
    yield put(subscribeWebhookFailure((e as Error).message));
  }
}

function* toggleWebhook({ payload: { id, data } }: ReturnType<typeof toggleWebhookRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/webhook/${id}/toggle`, { data });

    if (response.ok) {
      yield put(toggleWebhookSuccess());
    } else {
      yield put(toggleWebhookFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleWebhookFailure((e as Error).message));
  }
}

function* deleteWebhook({ payload: id }: ReturnType<typeof deleteWebhookRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/webhook/${id}`);

    if (response.ok) {
      yield put(deleteWebhookSuccess());
    } else {
      yield put(deleteWebhookFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteWebhookFailure((e as Error).message));
  }
}

function* testWebhook({ payload: id }: ReturnType<typeof testWebhookRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/webhook/${id}/test`);

    if (response.ok) {
      yield put(testWebhookSuccess());
    } else {
      yield put(testWebhookFailure(response.data.message));
    }
  } catch (e) {
    yield put(testWebhookFailure((e as Error).message));
  }
}

function* toggleMessageChannel({ payload: { id, data } }: ReturnType<typeof toggleMessageChannelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}/message_channel/toggle`, {
      data,
    });

    if (response.ok) {
      yield put(toggleMessageChannelSuccess());
    } else {
      yield put(toggleMessageChannelFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleMessageChannelFailure((e as Error).message));
  }
}

function* deleteMessageChannel({ payload: { id, data } }: ReturnType<typeof deleteMessageChannelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/message_channel`, { data });

    if (response.ok) {
      yield put(deleteMessageChannelSuccess());
    } else {
      yield put(deleteMessageChannelFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteMessageChannelFailure((e as Error).message));
  }
}

function* toggleMessageNotificationType({ payload: { id, data } }: ReturnType<typeof toggleMessageNotificationTypeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/notification/toggle`, {
      data,
    });

    if (response.ok) {
      yield put(toggleMessageNotificationTypeSuccess());
    } else {
      yield put(toggleMessageNotificationTypeFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleMessageNotificationTypeFailure((e as Error).message));
  }
}

function* createCloudPrinter({ payload: id }: ReturnType<typeof createCloudPrinterRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/cloudPrinter`);

    if (response.ok) {
      yield put(createCloudPrinterSuccess());
    } else {
      yield put(createCloudPrinterFailure(response.data.message));
    }
  } catch (e) {
    yield put(createCloudPrinterFailure((e as Error).message));
  }
}

function* deleteCloudPrinter({ payload: id }: ReturnType<typeof deleteCloudPrinterRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/cloudPrinter/${id}`);

    if (response.ok) {
      yield put(deleteCloudPrinterSuccess());
    } else {
      yield put(deleteCloudPrinterFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteCloudPrinterFailure((e as Error).message));
  }
}

function* postDeliveryExportTrigger({ payload: params }: ReturnType<typeof postDeliveryExportTriggerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/export_and_save:sender_delivery_summary', {
      params,
    });

    if (response.ok) {
      yield put(postDeliveryExportTriggerSuccess());
    } else {
      yield put(postDeliveryExportTriggerFailure(response.data.message));
    }
  } catch (e) {
    yield put(postDeliveryExportTriggerFailure((e as Error).message));
  }
}

function* addCustomDeliveryPlan({ payload: { id, data } }: ReturnType<typeof addCustomDeliveryPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/custom_plan`, { data });

    if (response.ok) {
      yield put(addCustomDeliveryPlanSuccess());
    } else {
      yield put(addCustomDeliveryPlanFailure(response.data.message));
    }
  } catch (e) {
    yield put(addCustomDeliveryPlanFailure((e as Error).message));
  }
}

function* deleteCustomDeliveryPlan({ payload: { id, data } }: ReturnType<typeof deleteCustomDeliveryPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/custom_plan`, { data });

    if (response.ok) {
      yield put(deleteCustomDeliveryPlanSuccess());
    } else {
      yield put(deleteCustomDeliveryPlanFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteCustomDeliveryPlanFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(createMerchantRequest.type, createMerchant);
  yield takeLatest(updateMerchantRequest.type, updateMerchant);
  yield takeLatest(setMerchantDefaultLocationRequest.type, setMerchantDefaultLocation);
  yield takeLatest(deleteMerchantLocationRequest.type, deleteMerchantLocation);
  yield takeLatest(updateMerchantLocationRequest.type, updateMerchantLocation);
  yield takeLatest(addMerchantLocationRequest.type, addMerchantLocation);
  yield takeLatest(addMerchantFeatureRequest.type, addMerchantFeature);
  yield takeLatest(toggleMerchantFeatureRequest.type, toggleMerchantFeature);
  yield takeLatest(deleteMerchantFeatureRequest.type, deleteMerchantFeature);
  yield takeLatest(addAgentWarehouseRequest.type, addAgentWarehouse);
  yield takeLatest(removeAgentWarehouseRequest.type, removeAgentWarehouse);
  yield takeLatest(subscribeWebhookRequest.type, subscribeWebhook);
  yield takeLatest(toggleWebhookRequest.type, toggleWebhook);
  yield takeLatest(deleteWebhookRequest.type, deleteWebhook);
  yield takeLatest(testWebhookRequest.type, testWebhook);
  yield takeLatest(toggleMessageChannelRequest.type, toggleMessageChannel);
  yield takeLatest(deleteMessageChannelRequest.type, deleteMessageChannel);
  yield takeLatest(toggleMessageNotificationTypeRequest.type, toggleMessageNotificationType);
  yield takeLatest(createCloudPrinterRequest.type, createCloudPrinter);
  yield takeLatest(deleteCloudPrinterRequest.type, deleteCloudPrinter);
  yield takeLatest(postDeliveryExportTriggerRequest.type, postDeliveryExportTrigger);
  yield takeLatest(addCustomDeliveryPlanRequest.type, addCustomDeliveryPlan);
  yield takeLatest(deleteCustomDeliveryPlanRequest.type, deleteCustomDeliveryPlan);
}

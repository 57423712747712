import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchPlaceDetailRequest,
  fetchPlaceDetailSuccess,
  fetchPlaceDetailFailure,
  createPlaceRequest,
  createPlaceSuccess,
  createPlaceFailure,
  uploadPlaceAndSubPlaceExcelRequest,
  uploadPlaceAndSubPlaceExcelSuccess,
  uploadPlaceAndSubPlaceExcelFailure,
  updatePlaceRequest,
  updatePlaceSuccess,
  updatePlaceFailure,
  deletePlaceRequest,
  deletePlaceSuccess,
  deletePlaceFailure,
  createSubPlaceRequest,
  createSubPlaceSuccess,
  createSubPlaceFailure,
  updateSubPlaceRequest,
  updateSubPlaceSuccess,
  updateSubPlaceFailure,
  deleteSubPlaceRequest,
  deleteSubPlaceSuccess,
  deleteSubPlaceFailure,
  fetchSuggestionPlaceListRequest,
  fetchSuggestionPlaceListSuccess,
  fetchSuggestionPlaceListFailure,
  fetchSuggestionPlaceDetailRequest,
  fetchSuggestionPlaceDetailSuccess,
  fetchSuggestionPlaceDetailFailure,
  fetchReverseGeoRequest,
  fetchReverseGeoSuccess,
  fetchReverseGeoFailure,
} from '@/pages/Place/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchPlaceDetail({ payload }: ReturnType<typeof fetchPlaceDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/places/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchPlaceDetailSuccess(response.data));
    } else {
      yield put(fetchPlaceDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchPlaceDetailFailure((e as Error).message));
  }
}

function* createPlace({ payload }: ReturnType<typeof createPlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/places', { data: payload });

    if (response.ok) {
      yield put(createPlaceSuccess());
    } else {
      yield put(createPlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(createPlaceFailure((e as Error).message));
  }
}

function* uploadSubPlaceExcel({ payload: { data } }: ReturnType<typeof uploadPlaceAndSubPlaceExcelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/places/bulkUploadPlaceAndSubPlace', {
      data,
      contentType: 'multipart/form-data',
      responseType: 'blob',
    });

    if (response.ok) {
      yield put(uploadPlaceAndSubPlaceExcelSuccess());
    } else {
      yield put(uploadPlaceAndSubPlaceExcelFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadPlaceAndSubPlaceExcelFailure((e as Error).message));
  }
}

function* updatePlace({ payload: { id, data } }: ReturnType<typeof updatePlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/places/${id}`, { data, schema: entity });

    if (response.ok) {
      yield put(updatePlaceSuccess(response.data));
    } else {
      yield put(updatePlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(updatePlaceFailure((e as Error).message));
  }
}

function* deletePlace({ payload: { id } }: ReturnType<typeof deletePlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/places/${id}`);

    if (response.ok) {
      yield put(deletePlaceSuccess());
    } else {
      yield put(deletePlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(deletePlaceFailure((e as Error).message));
  }
}

function* createSubPlace({ payload: { id, data } }: ReturnType<typeof createSubPlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/places/${id}/subPlaces`, { data, schema: entity });

    if (response.ok) {
      yield put(createSubPlaceSuccess(response.data));
    } else {
      yield put(createSubPlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(createSubPlaceFailure((e as Error).message));
  }
}

function* updateSubPlace({ payload: { id, subPlaceId, data } }: ReturnType<typeof updateSubPlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/places/${id}/subPlaces/${subPlaceId}`, { data, schema: entity });

    if (response.ok) {
      yield put(updateSubPlaceSuccess(response.data));
    } else {
      yield put(updateSubPlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateSubPlaceFailure((e as Error).message));
  }
}

function* deleteSubPlace({ payload: { id, subPlaceId } }: ReturnType<typeof deleteSubPlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/places/${id}/subPlaces/${subPlaceId}`, { schema: entity });

    if (response.ok) {
      yield put(deleteSubPlaceSuccess(response.data));
    } else {
      yield put(deleteSubPlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteSubPlaceFailure((e as Error).message));
  }
}

function* fetchSuggestionPlaceList({ payload: { query } }: ReturnType<typeof fetchSuggestionPlaceListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/places/autocomplete', { params: query });

    if (response.ok) {
      yield put(fetchSuggestionPlaceListSuccess(response.data));
    } else {
      yield put(fetchSuggestionPlaceListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchSuggestionPlaceListFailure((e as Error).message));
  }
}

function* fetchSuggestionPlaceDetail({ payload: { id, query } }: ReturnType<typeof fetchSuggestionPlaceDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/places/autocomplete/${id}`, { params: query });

    if (response.ok) {
      yield put(fetchSuggestionPlaceDetailSuccess(response.data));
    } else {
      yield put(fetchSuggestionPlaceDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchSuggestionPlaceDetailFailure((e as Error).message));
  }
}

function* fetchReverseGeo({ payload: { query } }: ReturnType<typeof fetchReverseGeoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/places/reverseGeo', { params: query });

    if (response.ok) {
      yield put(fetchReverseGeoSuccess(response.data));
    } else {
      yield put(fetchReverseGeoFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchReverseGeoFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchPlaceDetailRequest.type, fetchPlaceDetail);
  yield takeLatest(createPlaceRequest.type, createPlace);
  yield takeLatest(uploadPlaceAndSubPlaceExcelRequest.type, uploadSubPlaceExcel);
  yield takeLatest(updatePlaceRequest.type, updatePlace);
  yield takeLatest(deletePlaceRequest.type, deletePlace);
  yield takeLatest(createSubPlaceRequest.type, createSubPlace);
  yield takeLatest(updateSubPlaceRequest.type, updateSubPlace);
  yield takeLatest(deleteSubPlaceRequest.type, deleteSubPlace);
  yield takeLatest(fetchSuggestionPlaceListRequest.type, fetchSuggestionPlaceList);
  yield takeLatest(fetchSuggestionPlaceDetailRequest.type, fetchSuggestionPlaceDetail);
  yield takeLatest(fetchReverseGeoRequest.type, fetchReverseGeo);
}

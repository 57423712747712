import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardState, DashboardParam, DriverCountByTypeParam } from '@/pages/Dashboard/types';

const initialState: DashboardState = {
  metrics: {},
  driverTypeCount: {},
  rideCountByStatus: [],
  rideCountByRideType: [],
  isFetching: false,
  errorMessage: '',
  driverPingCount: {
    total: 0,
  },
  systemFeeStats: [],
};

const applyFetchRequest = (state: DashboardState) => ({
  ...state,
  isFetching: true,
});

const applyFetchFailure = (state: DashboardState, { payload }: PayloadAction<string>) => ({
  ...state,
  isFetching: false,
  errorMessage: payload,
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchMetricsRequest: {
      prepare: (state: DashboardParam) => ({ payload: state }),
      reducer: (state) => {
        Object.assign(state, applyFetchRequest(state));
      },
    },
    fetchMetricsSuccess(state, { payload }) {
      state.metrics = payload;
      state.isFetching = false;
    },
    fetchMetricsFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
    fetchRideCountByStatusRequest: {
      prepare: (state: DashboardParam) => ({ payload: state }),
      reducer: (state) => {
        Object.assign(state, applyFetchRequest(state));
      },
    },
    fetchRideCountByStatusSuccess(state, { payload }) {
      state.rideCountByStatus = payload;
      state.isFetching = false;
    },
    fetchRideCountByStatusFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
    fetchRideCountByRideTypeRequest: {
      prepare: (state: DashboardParam) => ({ payload: state }),
      reducer: (state) => {
        Object.assign(state, applyFetchRequest(state));
      },
    },
    fetchRideCountByRideTypeSuccess(state, { payload }) {
      state.rideCountByRideType = payload;
      state.isFetching = false;
    },
    fetchRideCountByRideTypeFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
    fetchDriverTypeCountRequest: {
      prepare: (state: DriverCountByTypeParam) => ({ payload: state }),
      reducer: (state) => {
        Object.assign(state, applyFetchRequest(state));
      },
    },
    fetchDriverTypeCountSuccess(state, { payload }) {
      state.driverTypeCount = payload;
      state.isFetching = false;
    },
    fetchDriverTypeCountFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
    fetchDriverPingCountRequest(state) {
      Object.assign(state, applyFetchRequest(state));
    },
    fetchDriverPingCountSuccess(state, { payload }) {
      state.driverPingCount = payload;
      state.isFetching = false;
    },
    fetchDriverPingCountFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
    fetchSystemFeeStatsRequest: {
      prepare: (state: DashboardParam) => ({ payload: state }),
      reducer: (state) => {
        Object.assign(state, applyFetchRequest(state));
      },
    },
    fetchSystemFeeStatsSuccess(state, { payload }) {
      state.systemFeeStats = payload;
      state.isFetching = false;
    },
    fetchSystemFeeStatsFailure(state, action: PayloadAction<string>) {
      Object.assign(state, applyFetchFailure(state, action));
    },
  },
});

export const {
  fetchMetricsRequest,
  fetchMetricsSuccess,
  fetchMetricsFailure,
  fetchRideCountByStatusRequest,
  fetchRideCountByStatusSuccess,
  fetchRideCountByStatusFailure,
  fetchRideCountByRideTypeRequest,
  fetchRideCountByRideTypeSuccess,
  fetchRideCountByRideTypeFailure,
  fetchDriverTypeCountRequest,
  fetchDriverTypeCountSuccess,
  fetchDriverTypeCountFailure,
  fetchDriverPingCountRequest,
  fetchDriverPingCountSuccess,
  fetchDriverPingCountFailure,
  fetchSystemFeeStatsRequest,
  fetchSystemFeeStatsSuccess,
  fetchSystemFeeStatsFailure,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDispatchConfigDetailFailure,
  fetchDispatchConfigDetailRequest,
  fetchDispatchConfigDetailSuccess,
  createDispatchConfigFailure,
  createDispatchConfigRequest,
  createDispatchConfigSuccess,
  updateDispatchConfigFailure,
  updateDispatchConfigRequest,
  updateDispatchConfigSuccess,
} from '@/pages/DispatchConfig/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

const basePath = '/dispatch_configs';

function* fetchDispatchConfigDetail({ payload }: ReturnType<typeof fetchDispatchConfigDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/${payload}`, { schema: entity });

    const action = response.ok ? fetchDispatchConfigDetailSuccess(response.data) : fetchDispatchConfigDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDispatchConfigDetailFailure((e as Error).message));
  }
}

function* createDispatchConfig({ payload }: ReturnType<typeof createDispatchConfigRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}`, { data: payload });

    const action = response.ok ? createDispatchConfigSuccess() : createDispatchConfigFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDispatchConfigFailure((e as Error).message));
  }
}

function* updateDispatchConfig({ payload: { id, body } }: ReturnType<typeof updateDispatchConfigRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateDispatchConfigSuccess() : updateDispatchConfigFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDispatchConfigFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchDispatchConfigDetailRequest.type, fetchDispatchConfigDetail);
  yield takeLatest(createDispatchConfigRequest.type, createDispatchConfig);
  yield takeLatest(updateDispatchConfigRequest.type, updateDispatchConfig);
}

import { ReactNode } from 'react';

import DateTime from '@/components/ValueField/DateTime';
import { FlexWrap } from '@/components/etc';

interface TimeStampProps {
  /**
   * 생성된 시간 (snake case)
   */
  created_at?: string | null;
  /**
   * 생성된 시간 (camel case)
   */
  createdAt?: string | null;
  /**
   * 수정된 시간 (snake case)
   */
  updated_at?: string | null;
  /**
   * 수정된 시간 (camel case)
   */
  updatedAt?: string | null;
  /**
   * 삭제된 시간 (snake case)
   */
  deleted_at?: string | null;
  /**
   * 삭제된 시간 (camel case)
   */
  deletedAt?: string | null;
}

const Wrap = (props: { children: ReactNode }) => (
  <FlexWrap
    customStyle={`
    & + & {
      margin-top: 5px;
    }

    > i { margin-right: 5px; }
  `}
  >
    {props.children}
  </FlexWrap>
);

/**
 * 생성된 시간과 수정된 시간을 받아서 DateTime과 아이콘으로 표현하는 컴포넌트이다.
 */
function TimeStamp(row: TimeStampProps) {
  return (
    <div>
      <Wrap>
        <i className='fa fa-plus-square-o' /> <DateTime date={row.created_at || row.createdAt} />
      </Wrap>
      <Wrap>
        <i className='fa fa-pencil-square-o' /> <DateTime date={row.updated_at || row.updatedAt} />
      </Wrap>
      {(row.deleted_at || row.deletedAt) && (
        <Wrap>
          <i className='fa fa-trash-o' /> <DateTime date={row.deleted_at || row.deletedAt} />
        </Wrap>
      )}
    </div>
  );
}

export default TimeStamp;

import { createSlice, createAction } from '@reduxjs/toolkit';

import { PipayTransactionState } from '@/pages/PipayTransactions/type';
import { detailSuccess } from '@/utils/reducerUtils';

const initialState: PipayTransactionState = {
  isFetching: false,
  error: null,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
};

export const checkFinishedPipayTransactionRequest = createAction('pipayTransaction/checkFinishedPipayTransactionRequest', (id: string) => ({
  payload: id,
}));
export const checkFinishedPipayTransactionFailure = createAction('pipayTransaction/checkFinishedPipayTransactionFailure', (error: string) => ({
  payload: error,
}));

const pipayTransactionSlice = createSlice({
  name: 'pipayTransaction',
  initialState,
  reducers: {
    checkFinishedPipayTransactionSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
  },
});

export const { checkFinishedPipayTransactionSuccess } = pipayTransactionSlice.actions;

export default pipayTransactionSlice.reducer;

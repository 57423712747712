import { createAction } from '@reduxjs/toolkit';

import { SupportPageDTO } from '@/pages/SupportPageManagement/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.csSvc}/supportPageManagement`;

export const upsertSupportPageManagementRequest = createAction<SupportPageDTO>(`${PREFIX}/upsertSupportPageManagementRequest`);
export const upsertSupportPageManagementSuccess = createAction<SupportPageDTO>(`${PREFIX}/upsertSupportPageManagementSuccess`);
export const upsertSupportPageManagementFailure = createAction<string>(`${PREFIX}/upsertSupportPageManagementFailure`);

export const deleteSupportPageManagementRequest = createAction<string>(`${PREFIX}/deleteSupportPageManagementRequest`);
export const deleteSupportPageManagementSuccess = createAction(`${PREFIX}/deleteSupportPageManagementSuccess`);
export const deleteSupportPageManagementFailure = createAction<string>(`${PREFIX}/deleteSupportPageManagementFailure`);

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  recoverDeletedAccountRequest,
  recoverDeletedAccountSuccess,
  recoverDeletedAccountFailure,
} from '@/svc/account-svc/recoverDeletedAccount/reducer';
import { authenticatedRequest } from '@/utils/request';

function* recoverDeletedAccount({ payload }: ReturnType<typeof recoverDeletedAccountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accounts/deletedLogs/${payload}`);

    if (response.ok) {
      yield put(recoverDeletedAccountSuccess());
    } else {
      yield put(recoverDeletedAccountFailure());
    }
  } catch (e) {
    yield put(recoverDeletedAccountFailure());
  }
}

export default function* recoverDeletedAccountSaga() {
  yield takeLatest(recoverDeletedAccountRequest.type, recoverDeletedAccount);
}

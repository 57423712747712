import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { fetchMobilesByUserIdRequest, fetchMobilesByUserIdSuccess, fetchMobilesByUserIdFailure } from '@/svc/mobile-svc/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchMobilesByUserId({ payload }: ReturnType<typeof fetchMobilesByUserIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/mobiles/by_user_id/${payload}`);

    const action = response.ok ? fetchMobilesByUserIdSuccess(payload, response.data) : fetchMobilesByUserIdFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchMobilesByUserIdFailure((e as Error).message));
  }
}

export default function* mobileSvcSaga() {
  yield takeLatest(fetchMobilesByUserIdRequest.type, fetchMobilesByUserId);
}

import { TargetKey } from '@/components/ColumnSelector/type';
import { ABA_TRANSACTION_COLUMNS_LIST, ABA_TRANSACTION_FORM_COLUMNS } from '@/pages/AbaTransaction/columns/constants';
import { ADYEN_TRANSACTION_COLUMNS_LIST, ADYEN_TRANSACTION_FORM_COLUMNS } from '@/pages/AdyenTransaction/columns/constants';
import { ALIPAY_TRANSACTION_COLUMNS_LIST, ALIPAY_TRANSACTION_FORM_COLUMNS } from '@/pages/AlipayTransactions/columns/constants';
import { CMCB_TRANSACTION_COLUMNS_LIST, CMCB_TRANSACTION_FORM_COLUMNS } from '@/pages/CmcbTransactions/columns/constants';
import { DEPOSIT_TRANSACTION_COLUMNS_LIST, DEPOSIT_TRANSACTION_FORM_COLUMNS } from '@/pages/DepositTransaction/columns/constants';
import { MOMOPAY_TRANSACTION_COLUMNS_LIST, MOMOPAY_TRANSACTION_FORM_COLUMNS } from '@/pages/MomopayTransactions/columns/constants';
import { PAO_TRANSACTION_COLUMNS_LIST, PAO_TRANSACTION_FORM_COLUMNS } from '@/pages/PaoTransactions/columns/constants';
import { PAYMENT_TRANSACTION_COLUMNS_LIST, PAYMENT_TRANSACTION_FORM_COLUMNS } from '@/pages/PaymentTransaction/columns/constants';
import { PIPAY_TRANSACTION_COLUMNS_LIST, PIPAY_TRANSACTION_FORM_COLUMNS } from '@/pages/PipayTransactions/columns/constants';
import { TRANSACTION_COLUMNS_LIST, TRANSACTION_FORM_COLUMNS } from '@/pages/Transaction/columns/constants';
import { WING_TRANSACTION_COLUMNS_LIST, WING_TRANSACTION_FORM_COLUMNS } from '@/pages/WingTransaction/columns/constants';

export const DEFAULT_COLUMNS: Record<TargetKey, string[]> = {
  ABATransactionList: ABA_TRANSACTION_COLUMNS_LIST,
  AlipayTransactionList: ALIPAY_TRANSACTION_COLUMNS_LIST,
  PaoTransactionList: PAO_TRANSACTION_COLUMNS_LIST,
  MomopayTransactionList: MOMOPAY_TRANSACTION_COLUMNS_LIST,
  PipayTransactionList: PIPAY_TRANSACTION_COLUMNS_LIST,
  CmcbTransactionList: CMCB_TRANSACTION_COLUMNS_LIST,
  AdyenTransactionList: ADYEN_TRANSACTION_COLUMNS_LIST,
  WingTransactionList: WING_TRANSACTION_COLUMNS_LIST,
  PaymentTransactionList: PAYMENT_TRANSACTION_COLUMNS_LIST,
  DepositTransactionList: DEPOSIT_TRANSACTION_COLUMNS_LIST,
  TransactionList: TRANSACTION_COLUMNS_LIST,
};

export const FORM_COLUMNS: Record<TargetKey, Array<{ key: string; label: string }>> = {
  ABATransactionList: ABA_TRANSACTION_FORM_COLUMNS,
  AlipayTransactionList: ALIPAY_TRANSACTION_FORM_COLUMNS,
  PaoTransactionList: PAO_TRANSACTION_FORM_COLUMNS,
  MomopayTransactionList: MOMOPAY_TRANSACTION_FORM_COLUMNS,
  PipayTransactionList: PIPAY_TRANSACTION_FORM_COLUMNS,
  CmcbTransactionList: CMCB_TRANSACTION_FORM_COLUMNS,
  AdyenTransactionList: ADYEN_TRANSACTION_FORM_COLUMNS,
  WingTransactionList: WING_TRANSACTION_FORM_COLUMNS,
  PaymentTransactionList: PAYMENT_TRANSACTION_FORM_COLUMNS,
  DepositTransactionList: DEPOSIT_TRANSACTION_FORM_COLUMNS,
  TransactionList: TRANSACTION_FORM_COLUMNS,
};

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  approveDepositTransactionFailure,
  approveDepositTransactionRequest,
  approveDepositTransactionSuccess,
  failDepositTransactionFailure,
  failDepositTransactionRequest,
  failDepositTransactionSuccess,
  rejectDepositTransactionFailure,
  rejectDepositTransactionRequest,
  rejectDepositTransactionSuccess,
  restoreDepositTransactionFailure,
  restoreDepositTransactionRequest,
  restoreDepositTransactionSuccess,
} from '@/svc/balance-svc/depositTransaction/reducer';
import { authenticatedRequest } from '@/utils/request';

function* approveDepositTransaction({ payload: id }: ReturnType<typeof approveDepositTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/depositTransaction/${id}/approve`);

    if (response.ok) {
      yield put(approveDepositTransactionSuccess());
    } else {
      yield put(approveDepositTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(approveDepositTransactionFailure((e as Error).message));
  }
}

function* rejectDepositTransaction({ payload: { id, data } }: ReturnType<typeof rejectDepositTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/depositTransaction/${id}/reject`, {
      data,
    });

    if (response.ok) {
      yield put(rejectDepositTransactionSuccess());
    } else {
      yield put(rejectDepositTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(rejectDepositTransactionFailure((e as Error).message));
  }
}

function* failDepositTransaction({ payload: { id, data } }: ReturnType<typeof failDepositTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/depositTransaction/${id}/fail`, {
      data,
    });

    if (response.ok) {
      yield put(failDepositTransactionSuccess());
    } else {
      yield put(failDepositTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(failDepositTransactionFailure((e as Error).message));
  }
}

function* restoreDepositTransaction({ payload: { id, data } }: ReturnType<typeof restoreDepositTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/depositTransaction/${id}/restore`, {
      data,
    });

    if (response.ok) {
      yield put(restoreDepositTransactionSuccess());
    } else {
      yield put(restoreDepositTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(restoreDepositTransactionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(approveDepositTransactionRequest.type, approveDepositTransaction);
  yield takeLatest(rejectDepositTransactionRequest.type, rejectDepositTransaction);
  yield takeLatest(failDepositTransactionRequest.type, failDepositTransaction);
  yield takeLatest(restoreDepositTransactionRequest.type, restoreDepositTransaction);
}

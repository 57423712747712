import { ModalBody as ModalBodyComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface ModalBodyProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function ModalBody(props: ModalBodyProps) {
  return <ModalBodyComponent {...props} />;
}

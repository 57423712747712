import { useHistory } from 'react-router';

import { Pages } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import ButtonContainer from '@/components/ButtonContainer';
import CheckPermission, { ActionType } from '@/components/CheckPermission';
import { Button } from '@/components/template';
import { usePermissionSelector } from '@/store/selector';

function ListFooterContent() {
  const history = useHistory();
  const permission = usePermissionSelector(Pages.OtpWhitelist);

  return (
    <ButtonContainer>
      <CheckPermission action={ActionType.WRITE} permission={permission}>
        <Button color={'primary'} onClick={() => history.push(`${PATH.OtpWhitelist}/new`)}>
          Add Phone Number
        </Button>
      </CheckPermission>
    </ButtonContainer>
  );
}

export default ListFooterContent;

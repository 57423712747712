import { Dispatch } from 'redux';

/*
 * impose penalty 부분에 response가 없어서 error가 나도 success로 넘어가는데 해당 부분을 수정하려면 추가로 success flag를 추가 하거나 success common action이 필요함
 * 아마 해당 부분 말고 response가 없는 부분이 많을 것 같은데 작업을 하기에는 이미 만들어진 action이 많아서
 * 일단 임시로 middleware에서 FAILURE action 인데 error가 없으면 'error occurred'를 띄워주도록 함
 */
const defaultErrorMiddleware = () => (next: Dispatch) => (action: any) => {
  if (action instanceof Promise) {
    return;
  }

  const { type } = action;

  if (type.includes('FAILURE')) {
    if (action.payload) {
      if (typeof action.payload === 'object' && !action.payload?.error) {
        action.payload.error = 'error occurred';
      }
    } else {
      if ((action?.error || null) === null) {
        action.error = 'error occurred';
      }
      action.payload = 'error occurred';
    }
  }

  return next(action);
};

export default defaultErrorMiddleware;

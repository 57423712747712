import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "ReviewList" */ '@/pages/Reviews/List'));
const Detail = lazy(() => import(/* webpackChunkName: "ReviewDetail" */ '@/pages/Reviews/Detail'));

export const reviewsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Reviews,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Reviews}/:id`,
    component: Detail,
    exact: true,
  },
];

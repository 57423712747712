import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

const prefix = `${Service.memberSvc}/hourlyRentalCar`;

export const registerHourlyRentalCarRequest = createAction<FormData>(`${prefix}/registerHourlyRentalCarRequest`);
export const registerHourlyRentalCarSuccess = createAction(`${prefix}/registerHourlyRentalCarSuccess`);
export const registerHourlyRentalCarFailure = createAction<string>(`${prefix}/registerHourlyRentalCarFailure`);

export const deleteHourlyRentalCarRequest = createAction<string>(`${prefix}/deleteHourlyRentalCarRequest`);
export const deleteHourlyRentalCarSuccess = createAction(`${prefix}/deleteHourlyRentalCarSuccess`);
export const deleteHourlyRentalCarFailure = createAction<string>(`${prefix}/deleteHourlyRentalCarFailure`);

export const getHourlyRentalCarExcelTemplateRequest = createAction(`${prefix}/getHourlyRentalCarExcelTemplateRequest`);
export const getHourlyRentalCarExcelTemplateSuccess = createAction(`${prefix}/getHourlyRentalCarExcelTemplateSuccess`);
export const getHourlyRentalCarExcelTemplateFailure = createAction<string>(`${prefix}/getHourlyRentalCarExcelTemplateFailure`);

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlipayRefundBody, AlipayTransactionState } from '@/pages/AlipayTransactions/type';
import { detailSuccess } from '@/utils/reducerUtils';

const initialState: AlipayTransactionState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  error: null,
};

const failure = (state: AlipayTransactionState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

const cancelAlipayTransactionRequest = createAction<number>('alipayTransaction/cancelAlipayTransactionRequest');
const cancelAlipayTransactionSuccess = createAction('alipayTransaction/cancelAlipayTransactionSuccess');
const cancelAlipayTransactionFailure = createAction<string>('alipayTransaction/cancelAlipayTransactionFailure');

const refundAlipayTransactionRequest = createAction('alipayTransaction/refundAlipayTransactionRequest', (id: number, data: AlipayRefundBody) => ({
  payload: { id, data },
}));
const refundAlipayTransactionSuccess = createAction('alipayTransaction/refundAlipayTransactionSuccess');
const refundAlipayTransactionFailure = createAction<string>('alipayTransaction/refundAlipayTransactionFailure');

const checkFinishedAlipayTransactionRequest = createAction<number>('alipayTransaction/checkFinishedAlipayTransactionRequest');
const checkFinishedAlipayTransactionFailure = createAction<string>('alipayTransaction/checkFinishedAlipayTransactionFailure');

const airportSlice = createSlice({
  name: 'alipayTransaction',
  initialState,
  reducers: {
    fetchAlipayTransactionDetailRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id) => ({ payload: id }),
    },
    fetchAlipayTransactionDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchAlipayTransactionDetailFailure: failure,

    checkFinishedAlipayTransactionSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
  },
});

export {
  cancelAlipayTransactionRequest,
  cancelAlipayTransactionSuccess,
  cancelAlipayTransactionFailure,
  refundAlipayTransactionRequest,
  refundAlipayTransactionSuccess,
  refundAlipayTransactionFailure,
  checkFinishedAlipayTransactionRequest,
  checkFinishedAlipayTransactionFailure,
};

export const {
  fetchAlipayTransactionDetailRequest,
  fetchAlipayTransactionDetailSuccess,
  fetchAlipayTransactionDetailFailure,
  checkFinishedAlipayTransactionSuccess,
} = airportSlice.actions;

export default airportSlice.reducer;

import styled from 'styled-components';

const Section = styled.section.attrs(({ $disableBorder }: { $disableBorder?: boolean }) => ({
  className: $disableBorder ? 'no-border' : '',
}))<{ $disableBorder?: boolean }>`
  & + & {
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &.no-border {
      margin-top: 3rem;
      padding-top: 0;
      border-top: 0;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 2rem;

  > h5 {
    margin: 0;
    font-size: 1.5rem;
  }
`;

export default Section;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  changePageStatusFailure,
  changePageStatusRequest,
  changePageStatusSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  sendResetPasswordEmailFailure,
  sendResetPasswordEmailRequest,
  sendResetPasswordEmailSuccess,
} from '@/svc/admin-proxy-svc/reducer';
import { authenticatedRequest, request } from '@/utils/request';

const BASE_URL = '/v1/admins';

function* resetPassword({ payload }: ReturnType<typeof resetPasswordRequest>): IterableIterator {
  try {
    const response = yield request.post(`${BASE_URL}/confirm_reset_password`, { params: payload });

    const action = response.ok ? resetPasswordSuccess() : resetPasswordFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(resetPasswordFailure((e as Error).message));
  }
}

function* sendResetPasswordEmail({ payload }: ReturnType<typeof sendResetPasswordEmailRequest>): IterableIterator {
  try {
    const response = yield request.post(`${BASE_URL}/reset_password`, { params: payload });

    const action = response.ok ? sendResetPasswordEmailSuccess() : sendResetPasswordEmailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(sendResetPasswordEmailFailure((e as Error).message));
  }
}

function* changePageStatus({ payload }: ReturnType<typeof changePageStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_URL}/change_page_status`, { data: payload });

    const action = response.ok ? changePageStatusSuccess(response.data) : changePageStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(changePageStatusFailure((e as Error).message));
  }
}

export default function* adminProxySaga() {
  yield takeLatest(resetPasswordRequest.type, resetPassword);
  yield takeLatest(sendResetPasswordEmailRequest.type, sendResetPasswordEmail);
  yield takeLatest(changePageStatusRequest.type, changePageStatus);
}

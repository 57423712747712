import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { ColumnStore, TargetKey, TARGET_KEY } from '@/components/ColumnSelector/type';
import { getShowColumnList } from '@/components/ColumnSelector/utils';
import { AppState } from '@/store/reducers';

const initialState: ColumnStore = {
  ABATransactionList: getShowColumnList(TARGET_KEY.ABATransactionList),
  AlipayTransactionList: getShowColumnList(TARGET_KEY.AlipayTransactionList),
  PaoTransactionList: getShowColumnList(TARGET_KEY.PaoTransactionList),
  MomopayTransactionList: getShowColumnList(TARGET_KEY.MomopayTransactionList),
  PipayTransactionList: getShowColumnList(TARGET_KEY.PipayTransactionList),
  CmcbTransactionList: getShowColumnList(TARGET_KEY.CmcbTransactionList),
  AdyenTransactionList: getShowColumnList(TARGET_KEY.AdyenTransactionList),
  WingTransactionList: getShowColumnList(TARGET_KEY.WingTransactionList),
  PaymentTransactionList: getShowColumnList(TARGET_KEY.PaymentTransactionList),
  DepositTransactionList: getShowColumnList(TARGET_KEY.DepositTransactionList),
  TransactionList: getShowColumnList(TARGET_KEY.TransactionList),
};

const columnSelectorSlice = createSlice({
  name: 'column-selector',
  initialState,
  reducers: {
    setShowColumnList(state, { payload }: PayloadAction<{ key: TargetKey; list: string[] }>) {
      state[payload.key] = payload.list;
    },
  },
});

export const { setShowColumnList } = columnSelectorSlice.actions;

export default columnSelectorSlice.reducer;

export function useColumnStore<T>(selector: (state: ReturnType<typeof columnSelectorSlice.reducer>) => T) {
  return useSelector((state: AppState) => selector(state.columnSelector));
}

import { createAction } from '@reduxjs/toolkit';

import { AdminDto } from '@/pages/Admin/type';
import { ResetPasswordRequestDto, SendResetPasswordEmailRequestDto } from '@/pages/ResetPassword/type';
import { UpdatePageStatusDto } from '@/svc/admin-proxy-svc/type';
import Service from '@/svc/serviceName';

export const resetPasswordRequest = createAction<ResetPasswordRequestDto>(`${Service.adminProxySvc}/resetPasswordRequest`);
export const resetPasswordSuccess = createAction(`${Service.adminProxySvc}/resetPasswordSuccess`);
export const resetPasswordFailure = createAction<string>(`${Service.adminProxySvc}/resetPasswordFailure`);

export const sendResetPasswordEmailRequest = createAction<SendResetPasswordEmailRequestDto>(`${Service.adminProxySvc}/sendResetPasswordEmailRequest`);
export const sendResetPasswordEmailSuccess = createAction(`${Service.adminProxySvc}/sendResetPasswordEmailSuccess`);
export const sendResetPasswordEmailFailure = createAction<string>(`${Service.adminProxySvc}/sendResetPasswordEmailFailure`);

export const changePageStatusRequest = createAction<UpdatePageStatusDto>(`${Service.adminProxySvc}/changePageStatusRequest`);
export const changePageStatusSuccess = createAction<AdminDto>(`${Service.adminProxySvc}/changePageStatusSuccess`);
export const changePageStatusFailure = createAction<string>(`${Service.adminProxySvc}/changePageStatusFailure`);

import { ReactNode } from 'react';

import Detail, { DetailProps } from '@/components/Detail';
import Section, { Title } from '@/components/Section/index';
import { Text } from '@/components/ValueField';

interface DetailSectionProps<T, F extends Object> extends Omit<DetailProps<NonNullable<T>, F>, 'data'> {
  title?: ReactNode;
  fallbackContent?: ReactNode;
  data: T;
}

function DetailSection<T = any, F extends Object = Object>(props: DetailSectionProps<T, F>) {
  const { title, data, fallbackContent } = props;

  return (
    <Section>
      {title && (
        <Title>
          <Text tag='h5'>{title}</Text>
        </Title>
      )}
      {data ? <Detail {...props} data={props.data as NonNullable<T>} /> : fallbackContent}
    </Section>
  );
}

export default DetailSection;

import { REGION } from '@/assets/constants';
import { valueOf } from '@/types';

export enum TRANSACTION_CHANNEL {
  RENTAL = 'RENTAL',
  RIDE = 'RIDE',
  BANK = 'BANK',
  WING = 'WING',
  PIPAY = 'PIPAY',
  SBC = 'SBC',
  ADMIN = 'ADMIN',
  RIDE_STREET_HAILING = 'RIDE_STREET_HAILING',
  DELIVERY = 'DELIVERY',
  ABA = 'ABA',
  DELIVERY_HAILING = 'DELIVERY_HAILING',
  REFERRAL_CODE = 'REFERRAL_CODE',
  DRIVER_INCENTIVE_REWARD = 'DRIVER_INCENTIVE_REWARD',
  CITI = 'CITI',
  CHIPMONG = 'CHIPMONG',
  PRODUCT = 'PRODUCT',
  FINE = 'FINE',
  SERVICE = 'SERVICE',
}

export const ALL_TRANSACTION_CHANNELS = [
  TRANSACTION_CHANNEL.RENTAL,
  TRANSACTION_CHANNEL.RIDE,
  TRANSACTION_CHANNEL.RIDE_STREET_HAILING,
  TRANSACTION_CHANNEL.BANK,
  TRANSACTION_CHANNEL.WING,
  TRANSACTION_CHANNEL.PIPAY,
  TRANSACTION_CHANNEL.SBC,
  TRANSACTION_CHANNEL.ADMIN,
  TRANSACTION_CHANNEL.DELIVERY,
  TRANSACTION_CHANNEL.ABA,
  TRANSACTION_CHANNEL.DELIVERY_HAILING,
  TRANSACTION_CHANNEL.REFERRAL_CODE,
  TRANSACTION_CHANNEL.DRIVER_INCENTIVE_REWARD,
  TRANSACTION_CHANNEL.CITI,
];

export const TRANSACTION_CASH_OUT_CHANNELS = [TRANSACTION_CHANNEL.BANK, TRANSACTION_CHANNEL.WING, TRANSACTION_CHANNEL.PIPAY];

export const TRANSACTION_CHANNEL_STRINGS = {
  [TRANSACTION_CHANNEL.RENTAL]: 'constants.transaction_channel_rental',
  [TRANSACTION_CHANNEL.RIDE]: 'constants.transaction_channel_ride',
  [TRANSACTION_CHANNEL.BANK]: 'constants.transaction_channel_bank',
  [TRANSACTION_CHANNEL.WING]: 'constants.transaction_channel_wing',
  [TRANSACTION_CHANNEL.PIPAY]: 'constants.transaction_channel_pipay',
  [TRANSACTION_CHANNEL.SBC]: 'constants.transaction_channel_sbc',
  [TRANSACTION_CHANNEL.ADMIN]: 'constants.transaction_channel_admin',
  [TRANSACTION_CHANNEL.RIDE_STREET_HAILING]: 'constants.transaction_channel_ride_street_hailing',
  [TRANSACTION_CHANNEL.DELIVERY]: 'constants.transaction_channel_delivery',
  [TRANSACTION_CHANNEL.ABA]: 'constants.transaction_channel_aba',
  [TRANSACTION_CHANNEL.DELIVERY_HAILING]: 'constants.transaction_channel_delivery_hailing',
  [TRANSACTION_CHANNEL.REFERRAL_CODE]: 'Referral Code',
  [TRANSACTION_CHANNEL.DRIVER_INCENTIVE_REWARD]: 'Driver mission reward',
  [TRANSACTION_CHANNEL.CITI]: 'CITI',
  [TRANSACTION_CHANNEL.CHIPMONG]: 'CHIPMONG',
  [TRANSACTION_CHANNEL.PRODUCT]: 'PRODUCT',
  [TRANSACTION_CHANNEL.FINE]: 'FINE',
  [TRANSACTION_CHANNEL.SERVICE]: 'SERVICE',
};

export enum TRANSACTION_FRAUD_TYPE {
  NOT_CHECKED = 1,
  NON_FRAUD = 10,
  FRAUD = 900,
}

export const ALL_TRANSACTION_FRAUD_TYPES = [TRANSACTION_FRAUD_TYPE.NOT_CHECKED, TRANSACTION_FRAUD_TYPE.NON_FRAUD, TRANSACTION_FRAUD_TYPE.FRAUD];

export const TRANSACTION_FRAUD_TYPE_STRINGS = {
  [TRANSACTION_FRAUD_TYPE.NOT_CHECKED]: 'constants.transaction_fraud_type.not_checked',
  [TRANSACTION_FRAUD_TYPE.NON_FRAUD]: 'constants.transaction_fraud_type.non_fraud',
  [TRANSACTION_FRAUD_TYPE.FRAUD]: 'constants.transaction_fraud_type.fraud',
};

export enum TRANSACTION_STATUS {
  PENDING = 10,
  APPROVED = 20,
  REJECTED = 900,
  FAILED = 999,
}

export const ALL_TRANSACTION_STATUSES = [
  TRANSACTION_STATUS.PENDING,
  TRANSACTION_STATUS.APPROVED,
  TRANSACTION_STATUS.REJECTED,
  TRANSACTION_STATUS.FAILED,
];

export enum TRANSACTION_TYPE {
  RIDE = 10,
  TOP_UP = 20,
  CASH_OUT = 30,
  DEDUCT = 40,
  CPF_PAYOUT = 100,
}

export const TRANSACTION_STATUS_STRINGS = {
  0: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.default.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.default.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.default.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.default.failed',
  },
  [TRANSACTION_TYPE.RIDE]: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.ride.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.ride.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.ride.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.ride.failed',
  },
  [TRANSACTION_TYPE.TOP_UP]: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.topup.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.topup.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.topup.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.topup.failed',
  },
  [TRANSACTION_TYPE.CASH_OUT]: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.cash_out.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.cash_out.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.cash_out.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.cash_out.failed',
  },
  [TRANSACTION_TYPE.DEDUCT]: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.deduct.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.deduct.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.deduct.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.deduct.failed',
  },
  [TRANSACTION_TYPE.CPF_PAYOUT]: {
    [TRANSACTION_STATUS.PENDING]: 'constants.transaction_status.pay_out.pending',
    [TRANSACTION_STATUS.APPROVED]: 'constants.transaction_status.pay_out.approved',
    [TRANSACTION_STATUS.REJECTED]: 'constants.transaction_status.pay_out.rejected',
    [TRANSACTION_STATUS.FAILED]: 'constants.transaction_status.pay_out.failed',
  },
};

export const TRANSACTION_ISSUE_TYPES = [TRANSACTION_TYPE.TOP_UP, TRANSACTION_TYPE.DEDUCT];

export const ALL_TRANSACTION_TYPES = [
  TRANSACTION_TYPE.RIDE,
  TRANSACTION_TYPE.TOP_UP,
  TRANSACTION_TYPE.CASH_OUT,
  TRANSACTION_TYPE.DEDUCT,
  TRANSACTION_TYPE.CPF_PAYOUT,
];

export const TRANSACTION_TYPE_STRINGS = {
  [TRANSACTION_TYPE.RIDE]: 'constants.transaction_type_ride',
  [TRANSACTION_TYPE.TOP_UP]: 'constants.transaction_type_top_up',
  [TRANSACTION_TYPE.CASH_OUT]: 'constants.transaction_type_cash_out',
  [TRANSACTION_TYPE.DEDUCT]: 'constants.transaction_type_deduct',
  [TRANSACTION_TYPE.CPF_PAYOUT]: 'constants.transaction_type_pay_out',
};

export const TRANSLATE_TRANSACTION_TYPE_STRINGS = {
  [TRANSACTION_TYPE.RIDE]: 'constants.transaction_type_ride',
  [TRANSACTION_TYPE.TOP_UP]: 'constants.transaction_type_top_up',
  [TRANSACTION_TYPE.CASH_OUT]: 'constants.transaction_type_cash_out',
  [TRANSACTION_TYPE.DEDUCT]: 'constants.transaction_type_deduct',
  [TRANSACTION_TYPE.CPF_PAYOUT]: 'constants.transaction_type_pay_out',
};

export enum TRANSACTION_CURRENCY {
  USD = 'USD',
  SGD = 'SGD',
  VND = 'VND',
  KHR = 'KHR',
  ETB = 'ETB',
  IDR = 'IDR',
  KRW = 'KRW',
  HKD = 'HKD',
  THB = 'THB',
}

export const ALL_TRANSACTION_CURRENCY = [
  TRANSACTION_CURRENCY.USD,
  TRANSACTION_CURRENCY.SGD,
  TRANSACTION_CURRENCY.VND,
  TRANSACTION_CURRENCY.KHR,
  TRANSACTION_CURRENCY.ETB,
  TRANSACTION_CURRENCY.IDR,
  TRANSACTION_CURRENCY.KRW,
  TRANSACTION_CURRENCY.HKD,
  TRANSACTION_CURRENCY.THB,
];

export const TRANSACTION_CURRENCY_STRINGS = {
  [TRANSACTION_CURRENCY.USD]: 'constants.transaction_currency.usd',
  [TRANSACTION_CURRENCY.SGD]: 'constants.transaction_currency.sgd',
  [TRANSACTION_CURRENCY.VND]: 'constants.transaction_currency.vnd',
  [TRANSACTION_CURRENCY.KHR]: 'constants.transaction_currency.khr',
  [TRANSACTION_CURRENCY.ETB]: 'constants.transaction_currency.etb',
  [TRANSACTION_CURRENCY.IDR]: 'constants.transaction_currency.idr',
  [TRANSACTION_CURRENCY.KRW]: 'constants.transaction_currency.krw',
  [TRANSACTION_CURRENCY.HKD]: 'constants.transaction_currency.hkd',
  [TRANSACTION_CURRENCY.THB]: 'constants.transaction_currency.thb',
};

export const TRANSACTION_CURRENCY_FROM_REGION: Record<REGION, TRANSACTION_CURRENCY> = {
  [REGION.SG]: TRANSACTION_CURRENCY.SGD,
  [REGION.VN]: TRANSACTION_CURRENCY.VND,
  [REGION.KH]: TRANSACTION_CURRENCY.KHR,
  [REGION.KR]: TRANSACTION_CURRENCY.KRW,
  [REGION.ET]: TRANSACTION_CURRENCY.ETB,
  [REGION.ID]: TRANSACTION_CURRENCY.IDR,
  [REGION.HK]: TRANSACTION_CURRENCY.HKD,
  [REGION.TH]: TRANSACTION_CURRENCY.THB,
  [REGION.GLOBAL]: TRANSACTION_CURRENCY.USD,
};

export enum TRANSACTION_PAYMENT_METHOD {
  CASH = 'CASH',
  PAO = 'PAO',
  ALIPAY_P = 'ALIPAY_P',
  PIPAY_P = 'PIPAY_P',
  MOMOPAY_P = 'MOMOPAY_P',
  CREDITCARD = 'CREDITCARD',
  WING = 'wing',
  TADAPAY = 'TADAPAY',
  CORPORATEPAY = 'CORPORATE',
  CMCB = 'CMCB',
  DELIVERY = 'DELIVERY',
  ABA = 'ABA',
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  NETSQR = 'NETSQR',
  OCBC_PAO = 'OCBC_PAO',
  ABA_AOF = 'ABA_AOF',
  CORPORATE_CARD = 'CORPORATE_CARD',
}

export const ALL_TRANSACTION_PAYMENT_METHODS = [
  TRANSACTION_PAYMENT_METHOD.CASH,
  TRANSACTION_PAYMENT_METHOD.PAO,
  TRANSACTION_PAYMENT_METHOD.ALIPAY_P,
  TRANSACTION_PAYMENT_METHOD.PIPAY_P,
  TRANSACTION_PAYMENT_METHOD.MOMOPAY_P,
  TRANSACTION_PAYMENT_METHOD.CREDITCARD,
  TRANSACTION_PAYMENT_METHOD.TADAPAY,
  TRANSACTION_PAYMENT_METHOD.CORPORATEPAY,
  TRANSACTION_PAYMENT_METHOD.CMCB,
  TRANSACTION_PAYMENT_METHOD.DELIVERY,
  TRANSACTION_PAYMENT_METHOD.ABA,
  TRANSACTION_PAYMENT_METHOD.CURRENT_BALANCE,
  TRANSACTION_PAYMENT_METHOD.NETSQR,
  TRANSACTION_PAYMENT_METHOD.OCBC_PAO,
  TRANSACTION_PAYMENT_METHOD.ABA_AOF,
  TRANSACTION_PAYMENT_METHOD.CORPORATE_CARD,
];

export const TRANSACTION_PAYMENT_METHOD_STRINGS = {
  [TRANSACTION_PAYMENT_METHOD.CASH]: 'constants.transaction_payment_method.cash',
  [TRANSACTION_PAYMENT_METHOD.PAO]: 'constants.transaction_payment_method.pao',
  [TRANSACTION_PAYMENT_METHOD.ALIPAY_P]: 'constants.transaction_payment_method.alipay_p',
  [TRANSACTION_PAYMENT_METHOD.PIPAY_P]: 'constants.transaction_payment_method.pipay_p',
  [TRANSACTION_PAYMENT_METHOD.MOMOPAY_P]: 'constants.transaction_payment_method.momopay_p',
  [TRANSACTION_PAYMENT_METHOD.CREDITCARD]: 'constants.transaction_payment_method.creditcard',
  [TRANSACTION_PAYMENT_METHOD.WING]: 'constants.transaction_payment_method.wing',
  [TRANSACTION_PAYMENT_METHOD.TADAPAY]: 'constants.transaction_payment_method.tadapay',
  [TRANSACTION_PAYMENT_METHOD.CORPORATEPAY]: 'constants.transaction_payment_method.corporatepay',
  [TRANSACTION_PAYMENT_METHOD.CMCB]: 'constants.transaction_payment_method.cmcb',
  [TRANSACTION_PAYMENT_METHOD.DELIVERY]: 'constants.transaction_payment_method.delivery',
  [TRANSACTION_PAYMENT_METHOD.ABA]: 'constants.transaction_payment_method.aba',
  [TRANSACTION_PAYMENT_METHOD.CURRENT_BALANCE]: 'constants.transaction_payment_method.current_balance',
  [TRANSACTION_PAYMENT_METHOD.NETSQR]: 'constants.transaction_payment_method.netsqr',
  [TRANSACTION_PAYMENT_METHOD.OCBC_PAO]: 'constants.transaction_payment_method.ocbc_pao',
  [TRANSACTION_PAYMENT_METHOD.ABA_AOF]: 'constants.transaction_payment_method.aba_aof',
  [TRANSACTION_PAYMENT_METHOD.CORPORATE_CARD]: 'constants.transaction_payment_method.corporate_card',
};

export enum CPF_OPT_IN_TYPE {
  MANDATORY = 1,
  OPTED_IN = 2,
  NOT_OPTED_IN = 3,
}

export const CPF_OPT_IN_TYPE_STRING = {
  [CPF_OPT_IN_TYPE.MANDATORY]: 'MANDATORY',
  [CPF_OPT_IN_TYPE.OPTED_IN]: 'OPTED_IN',
  [CPF_OPT_IN_TYPE.NOT_OPTED_IN]: 'NOT_OPTED_IN',
};

export enum TRANSACTION_ACCOUNT_MODEL {
  DRIVER = 'Driver',
  RIDER = 'Rider',
  DELIVERY_VENDOR = 'DeliveryVendor',
  DELIVERY_AGENT = 'DeliveryAgent',
}

export const TRANSACTION_ACCOUNT_MODEL_STRINGS: Record<TRANSACTION_ACCOUNT_MODEL, string> = {
  [TRANSACTION_ACCOUNT_MODEL.DRIVER]: 'constants.transaction_account_model.driver',
  [TRANSACTION_ACCOUNT_MODEL.RIDER]: 'constants.transaction_account_model.rider',
  [TRANSACTION_ACCOUNT_MODEL.DELIVERY_VENDOR]: 'constants.transaction_account_model.delivery_vendor',
  [TRANSACTION_ACCOUNT_MODEL.DELIVERY_AGENT]: 'constants.transaction_account_model.delivery_agent',
};

export const ALL_TRANSACTION_ACCOUNT_MODELS = [
  TRANSACTION_ACCOUNT_MODEL.DRIVER,
  TRANSACTION_ACCOUNT_MODEL.RIDER,
  TRANSACTION_ACCOUNT_MODEL.DELIVERY_VENDOR,
  TRANSACTION_ACCOUNT_MODEL.DELIVERY_AGENT,
];

export enum CASH_OUT_REQUEST_LOG_TYPE {
  UNKNOWN = 0,
  INSTANT_CASH_OUT = 10,
  SYNC_SHB = 100,
}

export const CASH_OUT_REQUEST_LOG_TYPE_STRINGS: { [key: number]: string } = {
  [CASH_OUT_REQUEST_LOG_TYPE.UNKNOWN]: 'constants.cash_out_request_log_type.unknown', // Unknown
  [CASH_OUT_REQUEST_LOG_TYPE.INSTANT_CASH_OUT]: 'constants.cash_out_request_log_type.instant_cashout', // Instant Cashout
  [CASH_OUT_REQUEST_LOG_TYPE.SYNC_SHB]: 'constants.cash_out_request_log_type.sync_sub', // Sync Shb
};

export enum INSTANT_CASH_OUT_REJECT_CODE {
  OTHERS = 0,
  TADA_BALANCE_INSUFFICIENT = 1,
}

export const INSTANT_CASH_OUT_REJECT_CODE_STRINGS: { [key: number]: string } = {
  [INSTANT_CASH_OUT_REJECT_CODE.OTHERS]: 'constants.instant_cash_out_reject_code.others', // Others
  [INSTANT_CASH_OUT_REJECT_CODE.TADA_BALANCE_INSUFFICIENT]: 'constants.instant_cash_out_reject_code.tada_balance_insufficient', // Tada Balance Insufficient
};

export const COORD_TYPE = {
  UNKNOWKN: -1,
  BAIDU: 0,
  GOOGLE: 1,
} as const;

export type CoordType = valueOf<typeof COORD_TYPE>;

export const TRANSACTION_DATE_PRESET_OPTIONS = [
  {
    value: '7',
    text: '7 Days',
  },
  {
    value: '30',
    text: '30 Days',
  },
  {
    value: '90',
    text: '90 Days',
  },
  {
    value: '365',
    text: '365 Days',
  },
  {
    value: 'custom',
    text: 'Custom',
  },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createTransactionRequest,
  createTransactionSuccess,
  createTransactionFailure,
  approveTransactionRequest,
  approveTransactionSuccess,
  approveTransactionFailure,
  rejectTransactionRequest,
  rejectTransactionSuccess,
  rejectTransactionFailure,
  failTransactionRequest,
  failTransactionSuccess,
  failTransactionFailure,
  restoreTransactionRequest,
  restoreTransactionSuccess,
  restoreTransactionFailure,
  changeCashTransactionRequest,
  changeCashTransactionSuccess,
  changeCashTransactionFailure,
  cashOutRetryTransactionRequest,
  cashOutRetryTransactionSuccess,
  cashOutRetryTransactionFailure,
  resolvePendingCashOutRequest,
  resolvePendingCashOutSuccess,
  resolvePendingCashOutFailure,
  replayTransactionRequest,
  replayTransactionSuccess,
  replayTransactionFailure,
  bulkApproveTransactionsRequest,
  bulkApproveTransactionsSuccess,
  bulkApproveTransactionsFailure,
  getBulkProcessExcelTemplateRequest,
  getBulkProcessExcelTemplateSuccess,
  getBulkProcessExcelTemplateFailure,
  uploadBulkProcessExcelRequest,
  uploadBulkProcessExcelSuccess,
  uploadBulkProcessExcelFailure,
  getBulkTransactionExcelTemplateRequest,
  getBulkTransactionExcelTemplateSuccess,
  getBulkTransactionExcelTemplateFailure,
  uploadBulkTransactionExcelRequest,
  uploadBulkTransactionExcelSuccess,
  uploadBulkTransactionExcelFailure,
  cashOutRetryBySelectedRequest,
  cashOutRetryBySelectedSuccess,
  cashOutRetryBySelectedFailure,
  cashOutRetryByFilteredRequest,
  cashOutRetryByFilteredSuccess,
  cashOutRetryByFilteredFailure,
  createCpfTransactionSuccess,
  createCpfTransactionFailure,
  createCpfTransactionRequest,
  reversalTransactionRequest,
  reversalTransactionFailure,
  reversalTransactionSuccess,
  getBulkCpfTransactionExcelTemplateFailure,
  getBulkCpfTransactionExcelTemplateSuccess,
  getBulkCpfTransactionExcelTemplateRequest,
  uploadBulkCpfTransactionExcelRequest,
  uploadBulkCpfTransactionExcelSuccess,
  uploadBulkCpfTransactionExcelFailure,
} from '@/svc/balance-svc/transaction/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* createTransaction({ payload: data }: ReturnType<typeof createTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/transaction:issue', { data });
    if (response.ok) {
      yield put(createTransactionSuccess());
    } else {
      yield put(createTransactionFailure({ message: response.data.message, status: response.status }));
    }
  } catch (e) {
    yield put(createTransactionFailure({ message: (e as Error).message }));
  }
}

function* createCpfTransaction({ payload: data }: ReturnType<typeof createCpfTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/transaction/issue/cpf', { data });
    if (response.ok) {
      yield put(createCpfTransactionSuccess());
    } else {
      yield put(createCpfTransactionFailure({ message: response.data.message, status: response.status }));
    }
  } catch (e) {
    yield put(createCpfTransactionFailure({ message: (e as Error).message }));
  }
}

function* approveTransaction({ payload: { id, data } }: ReturnType<typeof approveTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/approve`, { data });
    if (response.ok) {
      yield put(approveTransactionSuccess());
    } else {
      yield put(approveTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(approveTransactionFailure((e as Error).message));
  }
}

function* rejectTransaction({ payload: { id, data } }: ReturnType<typeof rejectTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/reject`, { data });
    if (response.ok) {
      yield put(rejectTransactionSuccess());
    } else {
      yield put(rejectTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(rejectTransactionFailure((e as Error).message));
  }
}

function* failTransaction({ payload: { id, data } }: ReturnType<typeof failTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/fail`, { data });
    if (response.ok) {
      yield put(failTransactionSuccess());
    } else {
      yield put(failTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(failTransactionFailure((e as Error).message));
  }
}

function* restoreTransaction({ payload: { id, data } }: ReturnType<typeof restoreTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/restore`, { data });
    if (response.ok) {
      yield put(restoreTransactionSuccess());
    } else {
      yield put(restoreTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(restoreTransactionFailure((e as Error).message));
  }
}

function* changeCashTransaction({ payload: { id, data } }: ReturnType<typeof changeCashTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/changeToCash`, { data });
    if (response.ok) {
      yield put(changeCashTransactionSuccess());
    } else {
      yield put(changeCashTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(changeCashTransactionFailure((e as Error).message));
  }
}

function* reversalTransaction({ payload: { id, data } }: ReturnType<typeof reversalTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/transaction/${id}/reversal`, { data });
    if (response.ok) {
      yield put(reversalTransactionSuccess());
    } else {
      yield put(reversalTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(reversalTransactionFailure((e as Error).message));
  }
}

function* cashOutRetryTransaction({ payload: { id, data } }: ReturnType<typeof cashOutRetryTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/transaction/${id}/cashOutRetry`, { data });
    if (response.ok) {
      yield put(cashOutRetryTransactionSuccess());
    } else {
      yield put(cashOutRetryTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(cashOutRetryTransactionFailure((e as Error).message));
  }
}

function* resolvePendingCashOut({ payload: { id, data } }: ReturnType<typeof resolvePendingCashOutRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/resolvePending`, { data });
    if (response.ok) {
      yield put(resolvePendingCashOutSuccess());
    } else {
      yield put(resolvePendingCashOutFailure(response.data.message));
    }
  } catch (e) {
    yield put(resolvePendingCashOutFailure((e as Error).message));
  }
}

function* replayTransaction({ payload: id }: ReturnType<typeof replayTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/transaction/${id}/replay`);
    if (response.ok) {
      yield put(replayTransactionSuccess());
    } else {
      yield put(replayTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(replayTransactionFailure((e as Error).message));
  }
}

function* bulkApproveTransactions({ payload: transactionIds }: ReturnType<typeof bulkApproveTransactionsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put('/v1/transaction:bulkApprove', { data: { transactionIds } });
    if (response.ok) {
      yield put(bulkApproveTransactionsSuccess());
    } else {
      yield put(bulkApproveTransactionsFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkApproveTransactionsFailure((e as Error).message));
  }
}

function* getBulkProcessExcelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/transaction/bulkProcessTemplate', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk_approve_template_en.xlsx');
      yield put(getBulkProcessExcelTemplateSuccess());
    } else {
      yield put(getBulkProcessExcelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(getBulkProcessExcelTemplateFailure((e as Error).message));
  }
}

function* uploadBulkProcessExcel({ payload: data }: ReturnType<typeof uploadBulkProcessExcelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/transaction/bulkProcessExcel', {
      data,
    });
    if (response.ok) {
      yield put(uploadBulkProcessExcelSuccess());
    } else {
      yield put(uploadBulkProcessExcelFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadBulkProcessExcelFailure((e as Error).message));
  }
}

function* getBulkTransactionExcelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v2/transaction/bulkTemplate', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'balance_bulk_create_transaction_template_en.xlsx');
      yield put(getBulkTransactionExcelTemplateSuccess());
    } else {
      yield put(getBulkTransactionExcelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(getBulkTransactionExcelTemplateFailure((e as Error).message));
  }
}

function* getBulkCpfTransactionExcelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v2/transaction/bulkTemplate/cpf', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'balance_bulk_create_transaction_template_en.xlsx');
      yield put(getBulkCpfTransactionExcelTemplateSuccess());
    } else {
      yield put(getBulkCpfTransactionExcelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(getBulkCpfTransactionExcelTemplateFailure((e as Error).message));
  }
}

function* uploadBulkTransactionExcel({
  payload: { admin, categoryType, data },
}: ReturnType<typeof uploadBulkTransactionExcelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v2/transaction/bulk?admin=${admin}&categoryType=${categoryType}`, {
      data,
      contentType: 'multipart/form-data',
      timeout: 3 * 60 * 1000,
    });

    if (response.ok) {
      yield put(uploadBulkTransactionExcelSuccess(response.data));
    } else {
      yield put(uploadBulkTransactionExcelFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadBulkTransactionExcelFailure((e as Error).message));
  }
}

function* uploadBulkCpfTransactionExcel({ payload: { data } }: ReturnType<typeof uploadBulkCpfTransactionExcelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v2/transaction/bulk/cpf`, {
      data,
      contentType: 'multipart/form-data',
      timeout: 3 * 60 * 1000,
    });

    if (response.ok) {
      yield put(uploadBulkCpfTransactionExcelSuccess(response.data));
    } else {
      yield put(uploadBulkCpfTransactionExcelFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadBulkCpfTransactionExcelFailure((e as Error).message));
  }
}

function* cashOutRetryBySelected({ payload: data }: ReturnType<typeof cashOutRetryBySelectedRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/transaction/cashOutRetry/id:bulk`, {
      data,
    });

    const action = response.ok ? cashOutRetryBySelectedSuccess(response.data) : cashOutRetryBySelectedFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(cashOutRetryBySelectedFailure((e as Error).message));
  }
}

function* cashOutRetryByFiltered({ payload: data }: ReturnType<typeof cashOutRetryByFilteredRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/transaction/cashOutRetry/filter:bulk`, {
      data,
    });

    const action = response.ok ? cashOutRetryByFilteredSuccess(response.data) : cashOutRetryByFilteredFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(cashOutRetryByFilteredFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createTransactionRequest.type, createTransaction);
  yield takeLatest(createCpfTransactionRequest.type, createCpfTransaction);
  yield takeLatest(approveTransactionRequest.type, approveTransaction);
  yield takeLatest(rejectTransactionRequest.type, rejectTransaction);
  yield takeLatest(failTransactionRequest.type, failTransaction);
  yield takeLatest(restoreTransactionRequest.type, restoreTransaction);
  yield takeLatest(changeCashTransactionRequest.type, changeCashTransaction);
  yield takeLatest(reversalTransactionRequest.type, reversalTransaction);
  yield takeLatest(cashOutRetryTransactionRequest.type, cashOutRetryTransaction);
  yield takeLatest(resolvePendingCashOutRequest.type, resolvePendingCashOut);
  yield takeLatest(replayTransactionRequest.type, replayTransaction);
  yield takeLatest(bulkApproveTransactionsRequest.type, bulkApproveTransactions);
  yield takeLatest(getBulkProcessExcelTemplateRequest.type, getBulkProcessExcelTemplate);
  yield takeLatest(uploadBulkProcessExcelRequest.type, uploadBulkProcessExcel);
  yield takeLatest(uploadBulkCpfTransactionExcelRequest.type, uploadBulkCpfTransactionExcel);
  yield takeLatest(getBulkTransactionExcelTemplateRequest.type, getBulkTransactionExcelTemplate);
  yield takeLatest(getBulkCpfTransactionExcelTemplateRequest.type, getBulkCpfTransactionExcelTemplate);
  yield takeLatest(uploadBulkTransactionExcelRequest.type, uploadBulkTransactionExcel);
  yield takeLatest(cashOutRetryBySelectedRequest.type, cashOutRetryBySelected);
  yield takeLatest(cashOutRetryByFilteredRequest.type, cashOutRetryByFiltered);
}

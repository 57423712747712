import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '@/components/template';
import { saveLanguage } from '@/utils/localStorage';

export default function SelectLanguage({ isVisibleDeliveryQrScanner }: { isVisibleDeliveryQrScanner: boolean }) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown className={isVisibleDeliveryQrScanner ? 'tw-ml-1' : 'tw-ml-auto'} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret outline color={'primary'}>
        {language === 'en' ? 'English' : 'Korean'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            i18n.changeLanguage('en').then(() => {
              setLanguage('en');
              saveLanguage('en');
            });
          }}
        >
          English
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

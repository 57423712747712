export const API_HOST = import.meta.env.VITE_ADMIN_API_HOST || 'https://adminproxy.tada-staging.com';

export const ADMIN_TYPE = import.meta.env.VITE_ADMIN_TYPE || 'TADA';

export const ADMIN_TYPES = {
  TADA: 'TADA',
};

export const ADMIN_TITLE = {
  [ADMIN_TYPES.TADA]: 'Tada Vendor Admin Panel',
};

export const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;

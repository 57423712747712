import { Formik } from 'formik';

import { HelmetTitle } from '@@components/HelmetTitle';

import { validationResetPasswordSchema } from '@/assets/constants/schema';
import { useToolkitActionToast } from '@/hooks/toast';
import { useSearch } from '@/hooks/useSearch';
import ResetPasswordFormContent from '@/pages/ResetPassword/parts/ResetPasswordFormContent';
import { ResetPasswordForm } from '@/pages/ResetPassword/type';
import { dispatch } from '@/store';
import { resetPasswordRequest } from '@/svc/admin-proxy-svc/reducer';

function ResetPassword() {
  const search = useSearch();

  const resetPassword = (form: ResetPasswordForm) => {
    dispatch(resetPasswordRequest({ newPassword: form.newPassword, token: form.token }));
  };

  const initialValues: ResetPasswordForm = {
    newPassword: '',
    confirmPassword: '',
    token: search.token,
  };

  useToolkitActionToast(resetPasswordRequest.type, {
    successMessage: 'Reset Password Successfully!',
    successRedirect: '/login',
  });

  return (
    <div>
      <HelmetTitle title='Reset Password' />
      <Formik initialValues={initialValues} onSubmit={resetPassword} validationSchema={validationResetPasswordSchema}>
        <ResetPasswordFormContent />
      </Formik>
    </div>
  );
}

export default ResetPassword;

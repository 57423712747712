export const DEPOSIT_TRANSACTION_COLUMNS = {
  ID: 'id',
  USER: 'userModel',
  CONTRACT_ID: 'contractId',
  TYPE: 'type',
  STATUS: 'status',
  CHANNEL: 'channel',
  CHANNEL_IDENTIFIER: 'channelIdentifier',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
  REGION: 'region',
  CREATED_AT: 'createdAt',
} as const;

export const DEPOSIT_TRANSACTION_COLUMNS_LIST = Object.values(DEPOSIT_TRANSACTION_COLUMNS);

export const DEPOSIT_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: DEPOSIT_TRANSACTION_COLUMNS.ID, label: 'Id' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.USER, label: 'user' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.CONTRACT_ID, label: 'Contract Id' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.TYPE, label: 'Type' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.CHANNEL, label: 'Channel' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.CHANNEL_IDENTIFIER, label: 'Channel Identifier' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.PAYMENT_METHOD, label: 'Payment Method' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.REGION, label: 'Region' },
  { key: DEPOSIT_TRANSACTION_COLUMNS.CREATED_AT, label: 'Created At' },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlockType, MaintenanceDto } from '@/pages/TadaMaintenance/type';
import { MaintenanceState } from '@/svc/inbox-svc/maintenance/type';
import Service from '@/svc/serviceName';

export const maintenanceInitialState: MaintenanceState = {
  flag: {
    tadaMaintenanceIsFetching: false,
    blockIsFetching: false,
  },
  error: null,
};

const maintenanceFailure = (state: MaintenanceState, { payload }: PayloadAction<string>) => {
  state.flag.blockIsFetching = false;
  state.flag.tadaMaintenanceIsFetching = false;
  state.error = payload;
};

const maintenanceSlice = createSlice({
  name: `${Service.inboxSvc}/maintenance`,
  initialState: maintenanceInitialState,
  reducers: {
    updateMaintenanceInfoRequest: {
      prepare: (body: MaintenanceDto) => ({ payload: body }),
      reducer: (state) => {
        state.flag.tadaMaintenanceIsFetching = true;
        state.error = null;
      },
    },
    updateMaintenanceInfoSuccess(state) {
      state.flag.tadaMaintenanceIsFetching = false;
    },
    updateMaintenanceInfoFailure: maintenanceFailure,
    updateMaintenanceBlockRequest: {
      prepare: (body: MaintenanceDto, blockType: BlockType) => ({
        payload: {
          body,
          blockType,
        },
      }),
      reducer: (state) => {
        state.flag.blockIsFetching = true;
        state.error = null;
      },
    },
    updateMaintenanceBlockSuccess: (state: MaintenanceState) => {
      state.flag.blockIsFetching = false;
      state.flag.tadaMaintenanceIsFetching = false;
    },
    updateMaintenanceBlockFailure: maintenanceFailure,
  },
});

export const {
  updateMaintenanceInfoRequest,
  updateMaintenanceInfoSuccess,
  updateMaintenanceInfoFailure,
  updateMaintenanceBlockRequest,
  updateMaintenanceBlockSuccess,
  updateMaintenanceBlockFailure,
} = maintenanceSlice.actions;

export default maintenanceSlice.reducer;

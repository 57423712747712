import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "HelpCenterListManagementList" */ '@/pages/HelpCenterListManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "HelpCenterListManagementDetail" */ '@/pages/HelpCenterListManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "HelpCenterListManagementNew" */ '@/pages/HelpCenterListManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "HelpCenterListManagementEdit" */ '@/pages/HelpCenterListManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "HelpCenterListManagementOrder" */ '@/pages/HelpCenterListManagement/Order'));

export const helpCenterListManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.HelpCenterListManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.HelpCenterListManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.HelpCenterListManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.HelpCenterListManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.HelpCenterListManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

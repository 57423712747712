import * as React from 'react';

import classNames from 'classnames';

import { Badge } from '@/components/template';
import { NavItemBadge } from '@/layout/Sidebar/_nav';

interface Props {
  badge: NavItemBadge;
}

const SideNavItemBadge: React.FunctionComponent<Props> = ({ badge }) => {
  const classes = classNames(badge.class);
  return (
    <Badge className={classes} color={badge.variant}>
      {badge.text}
    </Badge>
  );
};
export default SideNavItemBadge;

import { DropdownMenu as DropdownMenuComponent } from 'reactstrap';

import { CSSModule, Modifiers } from '@/components/template/type';

export interface DropdownMenuProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  right?: boolean;
  flip?: boolean;
  modifiers?: Modifiers;
  cssModule?: CSSModule;
  persist?: boolean;
  positionFixed?: boolean;
  container?: string | HTMLElement | React.RefObject<HTMLElement>;
}

export function DropdownMenu(props: DropdownMenuProps) {
  return <DropdownMenuComponent {...props} />;
}

import { CardGroup as CardGroupComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface CardGroupProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function CardGroup(props: CardGroupProps) {
  return <CardGroupComponent {...props} />;
}

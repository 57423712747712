import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createCouponRequest,
  createCouponSuccess,
  createCouponFailure,
  bulkCreateCouponRequest,
  bulkCreateCouponSuccess,
  bulkCreateCouponFailure,
  updateCouponStatusRequest,
  updateCouponStatusSuccess,
  updateCouponStatusFailure,
  publishCouponRequest,
  publishCouponSuccess,
  publishCouponFailure,
  downloadCouponBulkCancelTemplateRequest,
  downloadCouponBulkCancelTemplateSuccess,
  downloadCouponBulkCancelTemplateFailure,
  bulkCancelCouponRequest,
  bulkCancelCouponSuccess,
  bulkCancelCouponFailure,
} from '@/pages/Coupon/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/couponsvc/admin/v1/coupons';

function* createCoupon({ payload }: ReturnType<typeof createCouponRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(basePath, { data: payload });

    const action = res.ok ? createCouponSuccess(res.data) : createCouponFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(createCouponFailure((e as Error).message));
  }
}

function* bulkCreateCoupon({ payload }: ReturnType<typeof bulkCreateCouponRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${basePath}:bulkCreate`, { data: payload, responseType: 'blob' });

    if (res.ok) {
      saveFile(res, 'Bulk Coupon.txt');

      yield put(bulkCreateCouponSuccess());
    } else {
      const data = yield res.json();

      yield put(bulkCreateCouponFailure(data.message));
    }
  } catch (e) {
    yield put(bulkCreateCouponFailure((e as Error).message));
  }
}

function* updateCouponStatus({ payload: { id, status } }: ReturnType<typeof updateCouponStatusRequest>): IterableIterator {
  const body = {
    status,
  };

  try {
    const res = yield authenticatedRequest.put(`${basePath}/code/${id}`, { data: body });

    const action = res.ok ? updateCouponStatusSuccess(res.data) : updateCouponStatusFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateCouponStatusFailure((e as Error).message));
  }
}

function* publishCoupon({ payload: { id, userId } }: ReturnType<typeof publishCouponRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${basePath}/code/${id}/register`, { data: { userId } });

    if (res.ok) {
      yield put(publishCouponSuccess(res.data));
    } else {
      yield put(publishCouponFailure(res.data.message));
    }
  } catch (e) {
    yield put(publishCouponFailure((e as Error).message));
  }
}

function* downloadCouponBulkCancelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/bulk_cancel_excel_template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'coupon_bulk_cancel_template.xlsx');
      yield put(downloadCouponBulkCancelTemplateSuccess());
    } else {
      yield put(downloadCouponBulkCancelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadCouponBulkCancelTemplateFailure((e as Error).message));
  }
}

function* bulkCancelCoupon({ payload }: ReturnType<typeof bulkCancelCouponRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}:bulkCancel`, {
      data: payload,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(bulkCancelCouponSuccess(response.data));
    } else {
      yield put(bulkCancelCouponFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkCancelCouponFailure((e as Error).message));
  }
}

export default function* couponSaga() {
  yield takeLatest(createCouponRequest.type, createCoupon);
  yield takeLatest(bulkCreateCouponRequest.type, bulkCreateCoupon);
  yield takeLatest(updateCouponStatusRequest.type, updateCouponStatus);
  yield takeLatest(publishCouponRequest.type, publishCoupon);
  yield takeLatest(downloadCouponBulkCancelTemplateRequest.type, downloadCouponBulkCancelTemplate);
  yield takeLatest(bulkCancelCouponRequest.type, bulkCancelCoupon);
}

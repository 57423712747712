import React, { Suspense } from 'react';

import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import GoogleMapLoader from '@/components/GoogleMapLoader';
import { Loading } from '@/components/Loading';
import { Container } from '@/components/template';
import Footer from '@/layout/Footer';
import Header from '@/layout/Header';
import Sidebar from '@/layout/Sidebar';

interface LayoutProps {
  username: string;
  logoutAdmin: () => void;
  firstPageUrl: string;
  children: React.ReactNode;
}

export default function Layout({ username, logoutAdmin, firstPageUrl, children }: LayoutProps) {
  return (
    <>
      <Header username={username} logoutAdmin={logoutAdmin} />
      <div className='app-body'>
        <Sidebar />
        <GoogleMapLoader>
          <main className='main'>
            <Breadcrumb initialPath={firstPageUrl} />
            <Container fluid>
              <Suspense fallback={<Loading />}>{children}</Suspense>
            </Container>
          </main>
        </GoogleMapLoader>
      </div>
      <Footer />
    </>
  );
}

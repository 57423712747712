import _ from 'lodash';
import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator, TadaRequestConfig } from '@tada/tada-web-common';

import {
  fetchRestrictRideListRequest,
  fetchRestrictRideListSuccess,
  fetchRestrictRideListFailure,
  fetchRideListRequest,
  fetchRideListSuccess,
  fetchRideListFailure,
  fetchRideDetailRequest,
  fetchRideDetailSuccess,
  fetchRideDetailFailure,
  updateRideRequest,
  updateRideSuccess,
  updateRideFailure,
  updateRideStatusRequest,
  updateRideStatusSuccess,
  updateRideStatusFailure,
  updatePayStatusRequest,
  updatePayStatusSuccess,
  updatePayStatusFailure,
  generateBalanceItemRequest,
  generateBalanceItemSuccess,
  generateBalanceItemFailure,
  mapMatchingSimulationRequest,
  mapMatchingSimulationSuccess,
  mapMatchingSimulationFailure,
  exportDeliveryRideRequest,
  exportDeliveryRideSuccess,
  exportDeliveryRideFailure,
  sendReceiptRequest,
  sendReceiptSuccess,
  sendReceiptFailure,
  assignRideRequest,
  assignRideSuccess,
  assignRideFailure,
  dispatchRideRequest,
  dispatchRideSuccess,
  dispatchRideFailure,
  scheduleGeneratePickupRideRequest,
  scheduleGeneratePickupRideSuccess,
  scheduleGeneratePickupRideFailure,
  scheduleGenerateDropoffRideRequest,
  scheduleGenerateDropoffRideSuccess,
  scheduleGenerateDropoffRideFailure,
  scheduleGenerateReturnRideRequest,
  scheduleGenerateReturnRideSuccess,
  scheduleGenerateReturnRideFailure,
  scheduleReleaseAssignFailedRideRequest,
  scheduleReleaseAssignFailedRideSuccess,
  scheduleReleaseAssignFailedRideFailure,
  fetchDeliveryDataRequest,
  fetchDeliveryDataSuccess,
  fetchDeliveryDataFailure,
  clearDeliveryDepositRequest,
  clearDeliveryDepositSuccess,
  clearDeliveryDepositFailure,
  generateDeliveryRideRequest,
  generateDeliveryRideSuccess,
  generateDeliveryRideFailure,
  regenerateDeliveryRideRequest,
  regenerateDeliveryRideSuccess,
  regenerateDeliveryRideFailure,
  cancelRideRequest,
  cancelRideSuccess,
  cancelRideFailure,
  fetchGeneratedRideByIdsRequest,
  fetchGeneratedRideByIdsSuccess,
  fetchGeneratedRideByIdsFailure,
  fetchBranchesRequest,
  fetchBranchesSuccess,
  fetchBranchesFailure,
  removeFromCrRequest,
  removeFromCrSuccess,
  removeFromCrFailure,
  releaseDeliveryRequest,
  releaseDeliverySuccess,
  releaseDeliveryFailure,
  forceRemoveDeliveryFromRideRequest,
  forceRemoveDeliveryFromRideSuccess,
  forceRemoveDeliveryFromRideFailure,
  forceFinishDeliveryFromRideRequest,
  forceFinishDeliveryFromRideSuccess,
  forceFinishDeliveryFromRideFailure,
  forceReturnDeliveryFromRideRequest,
  forceReturnDeliveryFromRideSuccess,
  forceReturnDeliveryFromRideFailure,
  updateRidePriceRequest,
  updateRidePriceSuccess,
  updateRidePriceFailure,
  fetchMobilePushLogsRequest,
  fetchMobilePushLogsSuccess,
  fetchMobilePushLogsFailure,
  fetchChatLogsRequest,
  fetchChatLogsSuccess,
  fetchChatLogsFailure,
  cancelFinishedRideRequest,
  cancelFinishedRideSuccess,
  cancelFinishedRideFailure,
  removeRidePenaltyFeeRequest,
  removeRidePenaltyFeeSuccess,
  removeRidePenaltyFeeFailure,
  rollbackRidePenaltyFeeRequest,
  rollbackRidePenaltyFeeSuccess,
  rollbackRidePenaltyFeeFailure,
} from '@/pages/Ride/reducer';
import { getContentSchema } from '@/utils/api';
import { formatUnix } from '@/utils/dateUtils';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { schema } = getContentSchema();

function* fetchRestrictRideList({ payload }: ReturnType<typeof fetchRestrictRideListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides', { params: payload, schema });

    if (response.ok && (payload.page === 0 || !_.isEmpty(response.data.entities))) {
      yield put(fetchRestrictRideListSuccess(response.data));
    } else {
      const errorMessage = _.isEmpty(response.data.entities) ? 'Empty Data' : response.data.message;
      yield put(fetchRestrictRideListFailure(errorMessage));
    }
  } catch (e) {
    yield put(fetchRestrictRideListFailure((e as Error).message));
  }
}

function* fetchRideList({ payload }: ReturnType<typeof fetchRideListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides/list', { params: payload, schema });

    if (response.ok) {
      yield put(fetchRideListSuccess(response.data));
    } else {
      yield put(fetchRideListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRideListFailure((e as Error).message));
  }
}

function* fetchRideDetail({ payload }: ReturnType<typeof fetchRideDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/rides/${payload}`);

    if (response.ok) {
      yield put(fetchRideDetailSuccess(response.data));
    } else {
      yield put(fetchRideDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRideDetailFailure((e as Error).message));
  }
}

function* updateRide({ payload: { id, body } }: ReturnType<typeof updateRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/rides/${id}`, { data: body });

    if (response.ok) {
      yield put(updateRideSuccess(response.data));
    } else {
      yield put(updateRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateRideFailure((e as Error).message));
  }
}

function* updateRideStatus({ payload: { id, body } }: ReturnType<typeof updateRideStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/change_ride_status`, { data: body });

    if (response.ok) {
      yield put(updateRideStatusSuccess(response.data));
    } else {
      yield put(updateRideStatusFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateRideStatusFailure((e as Error).message));
  }
}

function* updatePayStatus({ payload: { id, body } }: ReturnType<typeof updatePayStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/change_pay_status`, { data: body });
    if (response.ok) {
      yield put(updatePayStatusSuccess(response.data));
    } else {
      yield put(updatePayStatusFailure(response.data.message));
    }
  } catch (e) {
    yield put(updatePayStatusFailure((e as Error).message));
  }
}

function* generateBalanceItem({ payload }: ReturnType<typeof generateBalanceItemRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${payload}/addDriverBalance`);

    if (response.ok) {
      yield put(generateBalanceItemSuccess());
    } else {
      yield put(generateBalanceItemFailure(response.data.message));
    }
  } catch (e) {
    yield put(generateBalanceItemFailure((e as Error).message));
  }
}

function* mapMatchingSimulation({ payload }: ReturnType<typeof mapMatchingSimulationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/rides/${payload}/map_matching`);

    if (response.ok) {
      yield put(mapMatchingSimulationSuccess(response.data));
    } else {
      yield put(mapMatchingSimulationFailure(response.data.message));
    }
  } catch (e) {
    yield put(mapMatchingSimulationFailure((e as Error).message));
  }
}

function* exportDeliveryRide({ payload }: ReturnType<typeof exportDeliveryRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride:export?${qs.stringify(payload)}`, {
      responseType: 'blob',
    });
    if (response.ok) {
      yield saveFile(
        response,
        `deliveryRide-${formatUnix({ date: payload.create_time_start })}~${formatUnix({ date: payload.create_time_end })}.xlsx`
      );
      yield put(exportDeliveryRideSuccess());
    } else {
      yield put(exportDeliveryRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportDeliveryRideFailure((e as Error).message));
  }
}

function* sendReceipt({ payload: { id, body } }: ReturnType<typeof sendReceiptRequest>): IterableIterator {
  try {
    const requestArgs: [string, TadaRequestConfig] = body?.email
      ? [`/rides/${id}/designated_receipt`, { data: body }]
      : [`/rides/${id}/receipt`, { data: null }];

    const response = yield authenticatedRequest.post(...requestArgs);

    if (response.ok) {
      yield put(sendReceiptSuccess(response.data));
    } else {
      yield put(sendReceiptFailure(response.data.message));
    }
  } catch (e) {
    yield put(sendReceiptFailure((e as Error).message));
  }
}

function* assignRide({ payload: { id, body } }: ReturnType<typeof assignRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/assign`, { data: body });

    if (response.ok) {
      yield put(assignRideSuccess(response.data));
    } else {
      yield put(assignRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(assignRideFailure((e as Error).message));
  }
}

function* dispatchRide({ payload }: ReturnType<typeof dispatchRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${payload}/dispatch`);

    if (response.ok) {
      yield put(dispatchRideSuccess(response.data));
    } else {
      yield put(dispatchRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(dispatchRideFailure((e as Error).message));
  }
}

function* scheduleGeneratePickupRide({ payload }: ReturnType<typeof scheduleGeneratePickupRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/schedule/run/generate_pickup', { data: payload });

    if (response.ok) {
      yield put(scheduleGeneratePickupRideSuccess());
    } else {
      yield put(scheduleGeneratePickupRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(scheduleGeneratePickupRideFailure((e as Error).message));
  }
}

function* scheduleGenerateDropoffRide({ payload }: ReturnType<typeof scheduleGenerateDropoffRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/schedule/run/generate_dropoff', { data: payload });

    if (response.ok) {
      yield put(scheduleGenerateDropoffRideSuccess());
    } else {
      yield put(scheduleGenerateDropoffRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(scheduleGenerateDropoffRideFailure((e as Error).message));
  }
}

function* scheduleGenerateReturnRide({ payload }: ReturnType<typeof scheduleGenerateReturnRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/schedule/run/generate_return', { data: payload });

    if (response.ok) {
      yield put(scheduleGenerateReturnRideSuccess());
    } else {
      yield put(scheduleGenerateReturnRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(scheduleGenerateReturnRideFailure((e as Error).message));
  }
}

function* scheduleReleaseAssignFailedRide({ payload }: ReturnType<typeof scheduleReleaseAssignFailedRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/schedule/run/release', { data: payload });

    if (response.ok) {
      yield put(scheduleReleaseAssignFailedRideSuccess());
    } else {
      yield put(scheduleReleaseAssignFailedRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(scheduleReleaseAssignFailedRideFailure((e as Error).message));
  }
}

function* fetchDeliveryData({ payload: { id, query } }: ReturnType<typeof fetchDeliveryDataRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/ride/${id}`, { params: query });

    if (response.ok) {
      yield put(fetchDeliveryDataSuccess(response.data));
    } else {
      yield put(fetchDeliveryDataFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryDataFailure((e as Error).message));
  }
}

function* clearDeliveryDeposit({ payload }: ReturnType<typeof clearDeliveryDepositRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/ride/${payload}/deposit`);

    if (response.ok) {
      yield put(clearDeliveryDepositSuccess(response.data));
    } else {
      yield put(clearDeliveryDepositFailure(response.data.message));
    }
  } catch (e) {
    yield put(clearDeliveryDepositFailure((e as Error).message));
  }
}

function* generateDeliveryRide({ payload }: ReturnType<typeof generateDeliveryRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/ride', { data: payload });

    if (response.ok) {
      yield put(generateDeliveryRideSuccess(response.data));
    } else {
      yield put(generateDeliveryRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(generateDeliveryRideFailure((e as Error).message));
  }
}

function* regenerateDeliveryRide({ payload: { id, body } }: ReturnType<typeof regenerateDeliveryRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${id}/regenerate`, { data: body });

    if (response.ok) {
      yield put(regenerateDeliveryRideSuccess(response.data));
    } else {
      yield put(regenerateDeliveryRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(regenerateDeliveryRideFailure((e as Error).message));
  }
}

function* cancelRide({ payload: { id, body } }: ReturnType<typeof cancelRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/cancel`, { data: body });

    if (response.ok) {
      yield put(cancelRideSuccess(response.data));
    } else {
      yield put(cancelRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(cancelRideFailure((e as Error).message));
  }
}

function* fetchGeneratedRideByIds({ payload }: ReturnType<typeof fetchGeneratedRideByIdsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/generated_rides:id_list', { params: { id: payload } });

    if (response.ok) {
      yield put(fetchGeneratedRideByIdsSuccess(response.data));
    } else {
      yield put(fetchGeneratedRideByIdsFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchGeneratedRideByIdsFailure((e as Error).message));
  }
}

function* fetchBranches({ payload }: ReturnType<typeof fetchBranchesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${payload}/branches`, {
      params: { corporate_id: payload },
    });

    if (response.ok) {
      yield put(fetchBranchesSuccess(response.data));
    } else {
      yield put(fetchBranchesFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchBranchesFailure((e as Error).message));
  }
}

function* removeFromCr({ payload }: ReturnType<typeof removeFromCrRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/rides/${payload}/offset_cancel`);

    if (response.ok) {
      yield put(removeFromCrSuccess());
    } else {
      yield put(removeFromCrFailure(response.data.message));
    }
  } catch (e) {
    yield put(removeFromCrFailure((e as Error).message));
  }
}

function* releaseDelivery({ payload }: ReturnType<typeof releaseDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${payload}/release`);

    if (response.ok) {
      yield put(releaseDeliverySuccess(response.data));
    } else {
      yield put(releaseDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(releaseDeliveryFailure((e as Error).message));
  }
}

function* forceRemoveDeliveryFromRide({ payload: { rideId, deliveryId } }: ReturnType<typeof forceRemoveDeliveryFromRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${rideId}/force_fail_and_release/${deliveryId}`);

    if (response.ok) {
      yield put(forceRemoveDeliveryFromRideSuccess(response.data));
    } else {
      yield put(forceRemoveDeliveryFromRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(forceRemoveDeliveryFromRideFailure((e as Error).message));
  }
}

function* updateRidePrice({ payload: { id, body } }: ReturnType<typeof updateRidePriceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/change_ride_price`, { data: body });

    if (response.ok) {
      yield put(updateRidePriceSuccess(response.data));
    } else {
      yield put(updateRidePriceFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateRidePriceFailure((e as Error).message));
  }
}

function* fetchMobilePushLogs({ payload }: ReturnType<typeof fetchMobilePushLogsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/rides/${payload}/pushLogs`);

    if (response.ok) {
      yield put(fetchMobilePushLogsSuccess({ id: payload, data: response.data }));
    } else {
      yield put(fetchMobilePushLogsFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchMobilePushLogsFailure((e as Error).message));
  }
}

function* fetchChatLogs({ payload }: ReturnType<typeof fetchChatLogsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/chat/${payload}/messages?size=200`);

    if (response.ok) {
      yield put(fetchChatLogsSuccess({ id: payload, data: response.data }));
    } else {
      yield put(fetchChatLogsFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchChatLogsFailure((e as Error).message));
  }
}

function* forceFinishDeliveryFromRide({ payload }: ReturnType<typeof forceFinishDeliveryFromRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/finish_force`);

    if (response.ok) {
      yield put(forceFinishDeliveryFromRideSuccess(response.data));
    } else {
      yield put(forceFinishDeliveryFromRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(forceFinishDeliveryFromRideFailure((e as Error).message));
  }
}

function* forceReturnDeliveryFromRide({ payload }: ReturnType<typeof forceReturnDeliveryFromRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/return_force`);

    if (response.ok) {
      yield put(forceReturnDeliveryFromRideSuccess(response.data));
    } else {
      yield put(forceReturnDeliveryFromRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(forceReturnDeliveryFromRideFailure((e as Error).message));
  }
}

function* cancelFinishedRide({ payload }: ReturnType<typeof cancelFinishedRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${payload.id}/drop`);

    if (response.ok) {
      yield put(cancelFinishedRideSuccess());
    } else {
      yield put(cancelFinishedRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(cancelFinishedRideFailure((e as Error).message));
  }
}

function* removeRidePenaltyFee({ payload }: ReturnType<typeof removeRidePenaltyFeeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/cancellation_fees/${payload.id}/remove`, {
      data: { reason: payload.reason },
    });

    response.ok ? yield put(removeRidePenaltyFeeSuccess()) : yield put(removeRidePenaltyFeeFailure(response.data.message));
  } catch (e) {
    yield put(removeRidePenaltyFeeFailure((e as Error).message));
  }
}

function* rollbackRidePenaltyFee({ payload }: ReturnType<typeof rollbackRidePenaltyFeeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/cancellation_fees/${payload}/rollback`);

    response.ok ? yield put(rollbackRidePenaltyFeeSuccess()) : yield put(rollbackRidePenaltyFeeFailure(response.data.message));
  } catch (e) {
    yield put(removeRidePenaltyFeeFailure((e as Error).message));
  }
}

export default function* rideSaga() {
  yield takeLatest(fetchRestrictRideListRequest.type, fetchRestrictRideList);
  yield takeLatest(fetchRideListRequest.type, fetchRideList);
  yield takeLatest(fetchRideDetailRequest.type, fetchRideDetail);
  yield takeLatest(updateRideRequest.type, updateRide);
  yield takeLatest(updateRideStatusRequest.type, updateRideStatus);
  yield takeLatest(updatePayStatusRequest.type, updatePayStatus);
  yield takeLatest(generateBalanceItemRequest.type, generateBalanceItem);
  yield takeLatest(mapMatchingSimulationRequest.type, mapMatchingSimulation);
  yield takeLatest(exportDeliveryRideRequest.type, exportDeliveryRide);
  yield takeLatest(sendReceiptRequest.type, sendReceipt);
  yield takeLatest(assignRideRequest.type, assignRide);
  yield takeLatest(dispatchRideRequest.type, dispatchRide);
  yield takeLatest(scheduleGeneratePickupRideRequest.type, scheduleGeneratePickupRide);
  yield takeLatest(scheduleGenerateDropoffRideRequest.type, scheduleGenerateDropoffRide);
  yield takeLatest(scheduleGenerateReturnRideRequest.type, scheduleGenerateReturnRide);
  yield takeLatest(scheduleReleaseAssignFailedRideRequest.type, scheduleReleaseAssignFailedRide);
  yield takeLatest(fetchDeliveryDataRequest.type, fetchDeliveryData);
  yield takeLatest(clearDeliveryDepositRequest.type, clearDeliveryDeposit);
  yield takeLatest(generateDeliveryRideRequest.type, generateDeliveryRide);
  yield takeLatest(regenerateDeliveryRideRequest.type, regenerateDeliveryRide);
  yield takeLatest(cancelRideRequest.type, cancelRide);
  yield takeLatest(fetchGeneratedRideByIdsRequest.type, fetchGeneratedRideByIds);
  yield takeLatest(fetchBranchesRequest.type, fetchBranches);
  yield takeLatest(removeFromCrRequest.type, removeFromCr);
  yield takeLatest(releaseDeliveryRequest.type, releaseDelivery);
  yield takeLatest(forceRemoveDeliveryFromRideRequest.type, forceRemoveDeliveryFromRide);
  yield takeLatest(forceFinishDeliveryFromRideRequest.type, forceFinishDeliveryFromRide);
  yield takeLatest(forceReturnDeliveryFromRideRequest.type, forceReturnDeliveryFromRide);
  yield takeLatest(updateRidePriceRequest.type, updateRidePrice);
  yield takeLatest(fetchMobilePushLogsRequest.type, fetchMobilePushLogs);
  yield takeLatest(fetchChatLogsRequest.type, fetchChatLogs);
  yield takeLatest(cancelFinishedRideRequest.type, cancelFinishedRide);
  yield takeLatest(removeRidePenaltyFeeRequest.type, removeRidePenaltyFee);
  yield takeLatest(rollbackRidePenaltyFeeRequest.type, rollbackRidePenaltyFee);
}

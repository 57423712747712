import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RegionsList" */ '@/pages/Regions/List'));
const Detail = lazy(() => import(/* webpackChunkName: "RegionsDetail" */ '@/pages/Regions/Detail'));
const New = lazy(() => import(/* webpackChunkName: "RegionsNew" */ '@/pages/Regions/New'));
const Edit = lazy(() => import(/* webpackChunkName: "RegionsEdit" */ '@/pages/Regions/Edit'));

export const regionsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Regions,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Regions}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Regions}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Regions}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

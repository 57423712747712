import { authenticatedRequestWithPromise } from './request/promise';

const MAX_WIDTH = 1000;
const MAX_HEIGHT = 1000;

const setCtx = (ctx: CanvasRenderingContext2D, width: number, height: number, degree: number) => {
  ctx.translate(width / 2, height / 2);
  ctx.rotate((degree * Math.PI) / 180);
  ctx.translate(-(height / 2), -(width / 2));
  return ctx;
};

/*
  비례식 사용
  a : b = c : d
  b * c = a * d
  (b * c) / a = d
*/
const getImageLength = (width: number, height: number): { imageWidth: number; imageHeight: number } => {
  if (width > MAX_WIDTH) {
    const imageWidth = MAX_WIDTH;
    const imageHeight = Math.ceil((height * MAX_WIDTH) / width);

    if (imageHeight > MAX_HEIGHT) {
      return getImageLength(imageWidth, imageHeight);
    }

    return { imageWidth, imageHeight };
  }

  if (height > MAX_HEIGHT) {
    const imageHeight = MAX_HEIGHT;
    const imageWidth = Math.ceil((width * MAX_HEIGHT) / height);

    if (imageWidth > MAX_WIDTH) {
      return getImageLength(imageWidth, imageHeight);
    }

    return { imageWidth, imageHeight };
  }

  return { imageWidth: width, imageHeight: height };
};

export const rotateImage = (image: HTMLImageElement, degree: number = 90): Promise<Blob> =>
  new Promise((res) => {
    const isRotate = (degree / 90) % 2 === 1;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const { imageWidth, imageHeight } = getImageLength(image.width, image.height);
    const width = isRotate ? imageHeight : imageWidth;
    const height = isRotate ? imageWidth : imageHeight;

    if (ctx) {
      canvas.width = width;
      canvas.height = height;

      setCtx(ctx, width, height, degree).drawImage(image, 0, 0, imageWidth, imageHeight);

      canvas.toBlob((blob) => {
        blob && res(blob);
      });
    }
  });

export const blobToFile = (blob: Blob, fileName: string): File => {
  const file = new File([blob], fileName, {
    type: blob.type,
  });

  return file;
};

export const getFileDataUrl = (data: File | Blob): Promise<string> =>
  new Promise((res) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => res(reader.result as string);
  });

export const getBlobFromS3 = async (src: string): Promise<Blob> => {
  const res = await authenticatedRequestWithPromise.get(`/v1/resource-proxy/s3`, {
    params: { path: src.replace(/http(s)*:\/\//, '') },
    responseType: 'blob',
  });

  return res.data;
};

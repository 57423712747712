import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductCreateBody, RideProduct, RideProductState } from '@/pages/RideProducts/type';
import { removeDuplicate } from '@/utils/etc';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

const initialState: RideProductState = {
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isSubmitting: false,
};

export const fetchProductListByIdListRequest = createAction('role/fetchProductListByIdListRequest', (search: { id: number[] }) => ({
  payload: search,
}));

export const fetchProductListByIdListFailure = createAction('role/fetchProductListByIdListFailure', (error: string) => ({
  payload: error,
}));

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    fetchProductListRequest: {
      // @TODO: Query Type
      prepare: (search: Partial<Record<string, string | number | boolean | null>>) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchProductListSuccess: {
      prepare: (payload: ListResponse<RideProduct>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<RideProduct>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchProductListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchProductDetailRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchProductDetailSuccess: {
      prepare: (payload: DetailResponse<RideProduct>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<RideProduct>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchProductDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createProductRequest: {
      prepare: (data: any) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createProductSuccess: {
      prepare: (payload: RideProduct) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createProductFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateProductRequest: {
      prepare: (id: number, data?: ProductCreateBody) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateProductSuccess: {
      prepare: (payload: RideProduct) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateProductFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },
    fetchProductListByIdListSuccess: {
      prepare: (payload: { result: string[]; entities: { content: RideProduct[] } }) => ({ payload }),
      reducer: (state, { payload }: PayloadAction<{ result: string[]; entities: { content: RideProduct[] } }>) => {
        (state.ids = removeDuplicate([...state.ids, ...payload.result])),
          (state.byId = {
            ...state.byId,
            ...payload.entities.content,
          });
      },
    },
  },
});

export const {
  fetchProductListRequest,
  fetchProductListSuccess,
  fetchProductListFailure,
  fetchProductDetailRequest,
  fetchProductDetailSuccess,
  fetchProductDetailFailure,
  createProductRequest,
  createProductSuccess,
  createProductFailure,
  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,
  fetchProductListByIdListSuccess,
} = roleSlice.actions;

export default roleSlice.reducer;

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryPriority, DELIVERY_STAKE_HOLDER } from '@/assets/constants';
import {
  Config,
  ContactLogInterface,
  CreateContactLogInterface,
  DeliveryBulkPreCreateResponse,
  DeliveryCreateDto,
  DeliveryEditFormInterface,
  DeliveryFormInterface,
  DeliveryInterface,
  DeliveryLocationFormInterface,
  DeliveryMoldInterface,
  DeliveryPriceFormInterface,
  DeliveryQueryInterface,
  DeliveryTagging,
  FinishDeliveryFormInterface,
  TemplatePayloadInterface,
  UpdateCommentDto,
} from '@/pages/Delivery/type';
import { DeliveryRequest } from '@/pages/DeliveryRide/type';
import { MerchantInterface, SenderCustomPlanCreateDto, UpdateOrDeleteRequestBody } from '@/pages/Merchant/type';
import { RideProduct } from '@/pages/RideProducts/type';
import deliveryTagReducer, { deliveryTagInitialState } from '@/svc/delivery-svc/tag/reducer';
import {
  DeliveryConfirmOrderBulkDto,
  DeliveryConfirmOrderDto,
  DeliveryFailDto,
  DeliveryKeyInResponse,
  DeliveryMoldUpdateDto,
  DeliveryPaymentGroup,
  DeliveryRescheduleBulkDto,
  DeliveryReturnDto,
  DeliverySvcState,
  DeliveryUpdateBulk,
  DeliveryUpdateStatusDto,
  MerchantSmartPricingConfig,
  SenderAddEmailDto,
  SenderDeliveryPriorityDto,
} from '@/svc/delivery-svc/type';
import warehouseReducer, { warehouseInitialState } from '@/svc/delivery-svc/warehouse/reducer';
import Service from '@/svc/serviceName';
import { DetailResponse, ListResponse } from '@/utils/types';

const deliveryInitialState = {
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  deliveryGlobalConfig: null,
  exportTypeLabel: {},
  preCreateData: {
    countValid: 0,
    countInvalid: 0,
    reasonsInvalid: {},
    deliveriesDtoValid: [],
    count: 0,
  },
  templatePayloads: null,
};

const initialState: DeliverySvcState = {
  keyIn: {},
  paymentGroup: {
    [DELIVERY_STAKE_HOLDER.SENDER]: {},
    [DELIVERY_STAKE_HOLDER.RECEIVER]: {},
    [DELIVERY_STAKE_HOLDER.ADMIN]: {},
    [DELIVERY_STAKE_HOLDER.AGENT]: {},
    [DELIVERY_STAKE_HOLDER.SYSTEM]: {},
    [DELIVERY_STAKE_HOLDER.DRIVER]: {},
  },
  tag: deliveryTagInitialState,
  flag: {
    isFetchingPaymentGroup: false,
  },
  rideProducts: [],
  warehouse: warehouseInitialState,
  ...deliveryInitialState,
};

export { fetchDeliveryTagListRequest, fetchDeliveryTagListSuccess, fetchDeliveryTagListFailure } from '@/svc/delivery-svc/tag/reducer';

export const fetchDeliveryKeyInByIdRequest = createAction<string[]>(`${Service.deliverySvc}/fetchDeliveryKeyInByIdRequest`);

export const exportDeliveryIdListRequest = createAction<string[]>(`${Service.deliverySvc}/exportDeliveryIdListRequest`);
export const exportDeliveryIdListSuccess = createAction(`${Service.deliverySvc}/exportDeliveryIdListSuccess`);
export const exportDeliveryIdListFailure = createAction<string>(`${Service.deliverySvc}/exportDeliveryIdListFailure`);

export const toggleDeliveryPriorityRequest = createAction(
  `${Service.deliverySvc}/toggleDeliveryPriorityRequest`,
  (id: string, body: SenderDeliveryPriorityDto) => ({ payload: { id, body } })
);
export const toggleDeliveryPrioritySuccess = createAction<MerchantInterface>(`${Service.deliverySvc}/toggleDeliveryPrioritySuccess`);
export const toggleDeliveryPriorityFailure = createAction<string>(`${Service.deliverySvc}/toggleDeliveryPriorityFailure`);

export const addMerchantEmailRequest = createAction(`${Service.deliverySvc}/addMerchantEmailRequest`, (id: string, body: SenderAddEmailDto) => ({
  payload: { id, body },
}));
export const addMerchantEmailSuccess = createAction<MerchantInterface>(`${Service.deliverySvc}/addMerchantEmailSuccess`);
export const addMerchantEmailFailure = createAction<string>(`${Service.deliverySvc}/addMerchantEmailFailure`);
export const addAgentCustomPlanRequest = createAction(
  `${Service.deliverySvc}/addAgentCustomPlanRequest`,
  (id: string, data: { currency: string; value: number; timeExpire: string }) => ({ payload: { id, data } })
);
export const addAgentCustomPlanSuccess = createAction<MerchantInterface>(`${Service.deliverySvc}/addAgentCustomPlanSuccess`);
export const addAgentCustomPlanFailure = createAction<string>(`${Service.deliverySvc}/addAgentCustomPlanFailure`);

export const deleteAgentCustomPlanRequest = createAction(`${Service.deliverySvc}/deleteAgentCustomPlanRequest`, (id: string) => ({
  payload: { id },
}));
export const deleteAgentCustomPlanSuccess = createAction<MerchantInterface>(`${Service.deliverySvc}/deleteAgentCustomPlanSuccess`);
export const deleteAgentCustomPlanFailure = createAction<string>(`${Service.deliverySvc}/deleteAgentCustomPlanFailure`);

export const updateMerchantSmartPricingConfigRequest = createAction<UpdateOrDeleteRequestBody<MerchantSmartPricingConfig>>(
  `${Service.deliverySvc}/updateMerchantSmartPricingConfigRequest`
);
export const updateMerchantSmartPricingConfigSuccess = createAction(`${Service.deliverySvc}/updateMerchantSmartPricingConfigSuccess`);
export const updateMerchantSmartPricingConfigFailure = createAction<string>(`${Service.deliverySvc}/updateMerchantSmartPricingConfigFailure`);

export const createMerchantCustomSmartPricingPlanRequest = createAction<UpdateOrDeleteRequestBody<SenderCustomPlanCreateDto>>(
  `${Service.deliverySvc}/createMerchantCustomSmartPricingPlanRequest`
);
export const createMerchantCustomSmartPricingPlanSuccess = createAction(`${Service.deliverySvc}/createMerchantCustomSmartPricingPlanSuccess`);
export const createMerchantCustomSmartPricingPlanFailure = createAction<string>(`${Service.deliverySvc}/createMerchantCustomSmartPricingPlanFailure`);

export const deleteMerchantCustomSmartPricingPlanRequest = createAction<UpdateOrDeleteRequestBody<SenderCustomPlanCreateDto>>(
  `${Service.deliverySvc}/deleteMerchantCustomSmartPricingPlanRequest`
);
export const deleteMerchantCustomSmartPricingPlanSuccess = createAction(`${Service.deliverySvc}/deleteMerchantCustomSmartPricingPlanSuccess`);
export const deleteMerchantCustomSmartPricingPlanFailure = createAction<string>(`${Service.deliverySvc}/deleteMerchantCustomSmartPricingPlanFailure`);

export const fetchAvailableRideProductsRequest = createAction<{ region?: string; deliveryPriority?: DeliveryPriority }>(
  `${Service.deliverySvc}/fetchAvailableRideProductsRequest`
);
export const fetchAvailableRideProductsFailure = createAction<string>(`${Service.deliverySvc}/fetchAvailableRideProductsFailure`);

export const createDeliveryRequest = createAction<DeliveryFormInterface>(`${Service.deliverySvc}/createDeliveryRequest`);
export const createDeliverySuccess = createAction(`${Service.deliverySvc}/createDeliverySuccess`);
export const createDeliveryFailure = createAction<string>(`${Service.deliverySvc}/createDeliveryFailure`);

export const updateDeliveryRequest = createAction(
  `${Service.deliverySvc}/updateDeliveryRequest`,
  (id: string, data: Omit<DeliveryEditFormInterface, 'tagging'> & { tagging: DeliveryTagging }) => ({
    payload: { id, data },
  })
);
export const updateDeliverySuccess = createAction(`${Service.deliverySvc}/updateDeliverySuccess`);
export const updateDeliveryFailure = createAction<string>(`${Service.deliverySvc}/updateDeliveryFailure`);

export const updateDeliveryStatusRequest = createAction(
  `${Service.deliverySvc}/updateDeliveryStatusRequest`,
  (id: string, data: DeliveryUpdateStatusDto) => ({
    payload: { id, data },
  })
);
export const updateDeliveryStatusSuccess = createAction(`${Service.deliverySvc}/updateDeliveryStatusSuccess`);
export const updateDeliveryStatusFailure = createAction<string>(`${Service.deliverySvc}/updateDeliveryStatusFailure`);

export const deleteDeliveryRequest = createAction<string>(`${Service.deliverySvc}/deleteDeliveryRequest`);
export const deleteDeliverySuccess = createAction(`${Service.deliverySvc}/deleteDeliverySuccess`);
export const deleteDeliveryFailure = createAction<string>(`${Service.deliverySvc}/deleteDeliveryFailure`);

export const confirmArrivalRequest = createAction<string>(`${Service.deliverySvc}/confirmArrivalRequest`);
export const confirmArrivalSuccess = createAction(`${Service.deliverySvc}/confirmArrivalSuccess`);
export const confirmArrivalFailure = createAction<string>(`${Service.deliverySvc}/confirmArrivalFailure`);

export const confirmDepartureRequest = createAction<string>(`${Service.deliverySvc}/confirmDepartureRequest`);
export const confirmDepartureSuccess = createAction(`${Service.deliverySvc}/confirmDepartureSuccess`);
export const confirmDepartureFailure = createAction<string>(`${Service.deliverySvc}/confirmDepartureFailure`);

export const confirmArrivalsRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/confirmArrivalsRequest`);
export const confirmArrivalsSuccess = createAction(`${Service.deliverySvc}/confirmArrivalsSuccess`);
export const confirmArrivalsFailure = createAction<string>(`${Service.deliverySvc}/confirmArrivalsFailure`);

export const confirmDeparturesRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/confirmDeparturesRequest`);
export const confirmDeparturesSuccess = createAction(`${Service.deliverySvc}/confirmDeparturesSuccess`);
export const confirmDeparturesFailure = createAction<string>(`${Service.deliverySvc}/confirmDeparturesFailure`);

export const confirmMissingRequest = createAction<string>(`${Service.deliverySvc}/confirmMissingRequest`);
export const confirmMissingSuccess = createAction(`${Service.deliverySvc}/confirmMissingSuccess`);
export const confirmMissingFailure = createAction<string>(`${Service.deliverySvc}/confirmMissingFailure`);

export const confirmMissingsRequest = createAction<{ deliveryIdList: string[] }>(`${Service.deliverySvc}/confirmMissingsRequest`);
export const confirmMissingsSuccess = createAction(`${Service.deliverySvc}/confirmMissingsSuccess`);
export const confirmMissingsFailure = createAction<string>(`${Service.deliverySvc}/confirmMissingsFailure`);

export const finishDeliveryRequest = createAction(
  `${Service.deliverySvc}/finishDeliveryRequest`,
  (id: string, data: FinishDeliveryFormInterface) => ({
    payload: { id, data },
  })
);
export const finishDeliverySuccess = createAction(`${Service.deliverySvc}/finishDeliverySuccess`);
export const finishDeliveryFailure = createAction<string>(`${Service.deliverySvc}/finishDeliveryFailure`);

export const sendSmsToDeliveryRequest = createAction<string>(`${Service.deliverySvc}/sendSmsToDeliveryRequest`);
export const sendSmsToDeliverySuccess = createAction(`${Service.deliverySvc}/sendSmsToDeliverySuccess`);
export const sendSmsToDeliveryFailure = createAction<string>(`${Service.deliverySvc}/sendSmsToDeliveryFailure`);

export const sendSmsToDeliveryBulkRequest = createAction<string[]>(`${Service.deliverySvc}/sendSmsToDeliveryBulkRequest`);
export const sendSmsToDeliveryBulkSuccess = createAction(`${Service.deliverySvc}/sendSmsToDeliveryBulkSuccess`);
export const sendSmsToDeliveryBulkFailure = createAction<string>(`${Service.deliverySvc}/sendSmsToDeliveryBulkFailure`);

export const unScheduleDeliveryRequest = createAction<string>(`${Service.deliverySvc}/unScheduleDeliveryRequest`);
export const unScheduleDeliverySuccess = createAction(`${Service.deliverySvc}/unScheduleDeliverySuccess`);
export const unScheduleDeliveryFailure = createAction<string>(`${Service.deliverySvc}/unScheduleDeliveryFailure`);

export const failDeliveryRequest = createAction(`${Service.deliverySvc}/failDeliveryRequest`, (id: string, data: DeliveryFailDto) => ({
  payload: { id, data },
}));
export const failDeliverySuccess = createAction(`${Service.deliverySvc}/failDeliverySuccess`);
export const failDeliveryFailure = createAction<string>(`${Service.deliverySvc}/failDeliveryFailure`);

export const expireDeliveryRequest = createAction<string>(`${Service.deliverySvc}/expireDeliveryRequest`);
export const expireDeliverySuccess = createAction(`${Service.deliverySvc}/expireDeliverySuccess`);
export const expireDeliveryFailure = createAction<string>(`${Service.deliverySvc}/expireDeliveryFailure`);

export const expireDeliveriesRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/expireDeliveriesRequest`);
export const expireDeliveriesSuccess = createAction(`${Service.deliverySvc}/expireDeliveriesSuccess`);
export const expireDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/expireDeliveriesFailure`);

export const fetchLocationRequest = createAction<string>(`${Service.deliverySvc}/fetchLocationRequest`);
export const fetchLocationSuccess = createAction(`${Service.deliverySvc}/fetchLocationSuccess`);
export const fetchLocationFailure = createAction<string>(`${Service.deliverySvc}/fetchLocationFailure`);

export const confirmPickupOrderRequest = createAction(
  `${Service.deliverySvc}/confirmPickupOrderRequest`,
  (id: string, data: DeliveryConfirmOrderDto) => ({
    payload: { id, data },
  })
);
export const confirmPickupOrderSuccess = createAction(`${Service.deliverySvc}/confirmPickupOrderSuccess`);
export const confirmPickupOrderFailure = createAction<string>(`${Service.deliverySvc}/confirmPickupOrderFailure`);

export const confirmDropoffOrderRequest = createAction(
  `${Service.deliverySvc}/confirmDropoffOrderRequest`,
  (id: string, data: DeliveryConfirmOrderDto) => ({
    payload: { id, data },
  })
);
export const confirmDropoffOrderSuccess = createAction(`${Service.deliverySvc}/confirmDropoffOrderSuccess`);
export const confirmDropoffOrderFailure = createAction<string>(`${Service.deliverySvc}/confirmDropoffOrderFailure`);

export const confirmPickupOrdersRequest = createAction<DeliveryConfirmOrderBulkDto>(`${Service.deliverySvc}/confirmPickupOrdersRequest`);
export const confirmPickupOrdersSuccess = createAction(`${Service.deliverySvc}/confirmPickupOrdersSuccess`);
export const confirmPickupOrdersFailure = createAction<string>(`${Service.deliverySvc}/confirmPickupOrdersFailure`);

export const confirmDropoffOrdersRequest = createAction<DeliveryConfirmOrderBulkDto>(`${Service.deliverySvc}/confirmDropoffOrdersRequest`);
export const confirmDropoffOrdersSuccess = createAction(`${Service.deliverySvc}/confirmDropoffOrdersSuccess`);
export const confirmDropoffOrdersFailure = createAction<string>(`${Service.deliverySvc}/confirmDropoffOrdersFailure`);

export const confirmReturnOrderRequest = createAction(
  `${Service.deliverySvc}/confirmReturnOrderRequest`,
  (id: string, data: DeliveryConfirmOrderDto) => ({
    payload: { id, data },
  })
);
export const confirmReturnOrderSuccess = createAction(`${Service.deliverySvc}/confirmReturnOrderSuccess`);
export const confirmReturnOrderFailure = createAction<string>(`${Service.deliverySvc}/confirmReturnOrderFailure`);

export const confirmReturnOrdersRequest = createAction<DeliveryConfirmOrderBulkDto>(`${Service.deliverySvc}/confirmReturnOrdersRequest`);
export const confirmReturnOrdersSuccess = createAction(`${Service.deliverySvc}/confirmReturnOrdersSuccess`);
export const confirmReturnOrdersFailure = createAction<string>(`${Service.deliverySvc}/confirmReturnOrdersFailure`);

export const confirmTransferOrderRequest = createAction(
  `${Service.deliverySvc}/confirmTransferOrderRequest`,
  (id: string, data: DeliveryConfirmOrderDto) => ({
    payload: { id, data },
  })
);
export const confirmTransferOrderSuccess = createAction(`${Service.deliverySvc}/confirmTransferOrderSuccess`);
export const confirmTransferOrderFailure = createAction<string>(`${Service.deliverySvc}/confirmTransferOrderFailure`);

export const confirmTransferOrdersRequest = createAction<DeliveryConfirmOrderBulkDto>(`${Service.deliverySvc}/confirmTransferOrdersRequest`);
export const confirmTransferOrdersSuccess = createAction(`${Service.deliverySvc}/confirmTransferOrdersSuccess`);
export const confirmTransferOrdersFailure = createAction<string>(`${Service.deliverySvc}/confirmTransferOrdersFailure`);

export const exportDeliveryRequest = createAction<DeliveryQueryInterface>(`${Service.deliverySvc}/exportDeliveryRequest`);
export const exportDeliverySuccess = createAction(`${Service.deliverySvc}/exportDeliverySuccess`);
export const exportDeliveryFailure = createAction<string>(`${Service.deliverySvc}/exportDeliveryFailure`);

export const createAddressRequest = createAction<DeliveryLocationFormInterface>(`${Service.deliverySvc}/createAddressRequest`);
export const createAddressSuccess = createAction(`${Service.deliverySvc}/createAddressSuccess`);
export const createAddressFailure = createAction<string>(`${Service.deliverySvc}/createAddressFailure`);

export const updateCommentRequest = createAction(`${Service.deliverySvc}/updateCommentRequest`, (id: string, data: UpdateCommentDto) => ({
  payload: { id, data },
}));
export const updateCommentSuccess = createAction(`${Service.deliverySvc}/updateCommentSuccess`);
export const updateCommentFailure = createAction<string>(`${Service.deliverySvc}/updateCommentFailure`);

export const updateDeliveryMoldByDeliveryIdRequest = createAction(
  `${Service.deliverySvc}/updateDeliveryMoldByDeliveryIdRequest`,
  (id: string, data: DeliveryMoldUpdateDto) => ({
    payload: { id, data },
  })
);
export const updateDeliveryMoldByDeliveryIdSuccess = createAction(`${Service.deliverySvc}/updateDeliveryMoldByDeliveryIdSuccess`);
export const updateDeliveryMoldByDeliveryIdFailure = createAction<string>(`${Service.deliverySvc}/updateDeliveryMoldByDeliveryIdFailure`);

export const rescheduleDeliveriesRequest = createAction<DeliveryRescheduleBulkDto>(`${Service.deliverySvc}/rescheduleDeliveriesRequest`);
export const rescheduleDeliveriesSuccess = createAction(`${Service.deliverySvc}/rescheduleDeliveriesSuccess`);
export const rescheduleDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/rescheduleDeliveriesFailure`);

export const unScheduleDeliveriesRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/unScheduleDeliveriesRequest`);
export const unScheduleDeliveriesSuccess = createAction(`${Service.deliverySvc}/unScheduleDeliveriesSuccess`);
export const unScheduleDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/unScheduleDeliveriesFailure`);

export const finishDeliveriesRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/finishDeliveriesRequest`);
export const finishDeliveriesSuccess = createAction(`${Service.deliverySvc}/finishDeliveriesSuccess`);
export const finishDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/finishDeliveriesFailure`);

export const updateDeliveryPriceRequest = createAction(
  `${Service.deliverySvc}/updateDeliveryPriceRequest`,
  (id: string, data: DeliveryPriceFormInterface) => ({
    payload: { id, data },
  })
);
export const updateDeliveryPriceSuccess = createAction(`${Service.deliverySvc}/updateDeliveryPriceSuccess`);
export const updateDeliveryPriceFailure = createAction<string>(`${Service.deliverySvc}/updateDeliveryPriceFailure`);

export const returnDeliveryRequest = createAction(`${Service.deliverySvc}/returnDeliveryRequest`, (id: string, data: DeliveryReturnDto) => ({
  payload: { id, data },
}));
export const returnDeliverySuccess = createAction(`${Service.deliverySvc}/returnDeliverySuccess`);
export const returnDeliveryFailure = createAction<string>(`${Service.deliverySvc}/returnDeliveryFailure`);

export const returnDeliveriesRequest = createAction<DeliveryUpdateBulk>(`${Service.deliverySvc}/returnDeliveriesRequest`);
export const returnDeliveriesSuccess = createAction(`${Service.deliverySvc}/returnDeliveriesSuccess`);
export const returnDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/returnDeliveriesFailure`);

export const recalculateDeliveryPriceRequest = createAction<string>(`${Service.deliverySvc}/recalculateDeliveryPriceRequest`);
export const recalculateDeliveryPriceSuccess = createAction(`${Service.deliverySvc}/recalculateDeliveryPriceSuccess`);
export const recalculateDeliveryPriceFailure = createAction<string>(`${Service.deliverySvc}/recalculateDeliveryPriceFailure`);

export const preCreateFromCsvRequest = createAction(
  `${Service.deliverySvc}/preCreateFromCsvRequest`,
  (userFromId: string, priority: number, body: FormData) => ({
    payload: { userFromId, priority, body },
  })
);

export const downloadTemplateRequest = createAction(`${Service.deliverySvc}/downloadTemplateRequest`);

export const createBulkDeliveryRequest = createAction(
  `${Service.deliverySvc}/createBulkDeliveryRequest`,
  (deliveries: DeliveryCreateDto[], createAtOnce: boolean) => ({
    payload: { deliveries, createAtOnce },
  })
);
export const createBulkDeliverySuccess = createAction<DeliveryBulkPreCreateResponse>(`${Service.deliverySvc}/createBulkDeliverySuccess`);
export const createBulkDeliveryFailure = createAction(
  `${Service.deliverySvc}/createBulkDeliveryFailure`,
  (error: string, bulk: DeliveryBulkPreCreateResponse | null) => ({
    payload: { error, bulk },
  })
);

export const createContactLogRequest = createAction(
  `${Service.deliverySvc}/createContactLogRequest`,
  (id: string, data: CreateContactLogInterface) => ({
    payload: { id, data },
  })
);
export const createContactLogSuccess = createAction<ContactLogInterface>(`${Service.deliverySvc}/createContactLogSuccess`);
export const createContactLogFailure = createAction<string>(`${Service.deliverySvc}/createContactLogFailure`);

export const rollbackDeliveriesRequest = createAction<string[]>(`${Service.deliverySvc}/rollbackDeliveriesRequest`);
export const rollbackDeliveriesSuccess = createAction<DeliveryInterface>(`${Service.deliverySvc}/rollbackDeliveriesSuccess`);
export const rollbackDeliveriesFailure = createAction<string>(`${Service.deliverySvc}/rollbackDeliveriesFailure`);

export const rollbackDeliveryByIdRequest = createAction<string>(`${Service.deliverySvc}/rollbackDeliveryByIdRequest`);
export const rollbackDeliveryByIdSuccess = createAction<DeliveryInterface>(`${Service.deliverySvc}/rollbackDeliveryByIdSuccess`);
export const rollbackDeliveryByIdFailure = createAction<string>(`${Service.deliverySvc}/rollbackDeliveryByIdFailure`);

export const fetchDeliveryListRequest = createAction<DeliveryQueryInterface>(`${Service.deliverySvc}/fetchDeliveryListRequest`);
export const fetchDeliveryListFailure = createAction<string>(`${Service.deliverySvc}/fetchDeliveryListFailure`);

export const fetchDeliveryDetailRequest = createAction<string>(`${Service.deliverySvc}/fetchDeliveryDetailRequest`);
export const fetchDeliveryDetailFailure = createAction<string>(`${Service.deliverySvc}/fetchDeliveryDetailFailure`);

export const fetchDeliveryGlobalConfigRequest = createAction(`${Service.deliverySvc}/fetchDeliveryGlobalConfigRequest`);
export const fetchDeliveryGlobalConfigFailure = createAction<string>(`${Service.deliverySvc}/fetchDeliveryGlobalConfigFailure`);

export const fetchDeliveryRequestInfoRequest = createAction<string>(`${Service.deliverySvc}/fetchDeliveryRequestInfoRequest`);
export const fetchDeliveryRequestInfoSuccess = createAction<DeliveryRequest>(`${Service.deliverySvc}/fetchDeliveryRequestInfoSuccess`);
export const fetchDeliveryRequestInfoFailure = createAction<string>(`${Service.deliverySvc}/fetchDeliveryRequestInfoFailure`);

export const preparePrintBulkDeliveryRequest = createAction<string>(`${Service.deliverySvc}/preparePrintBulkDeliveryRequest`);
export const preparePrintBulkDeliverySuccess = createAction<DeliveryMoldInterface>(`${Service.deliverySvc}/preparePrintBulkDeliverySuccess`);
export const preparePrintBulkDeliveryFailure = createAction<string>(`${Service.deliverySvc}/preparePrintBulkDeliveryFailure`);

export const templatePayloadRequest = createAction(`${Service.deliverySvc}/templatePayloadRequest`, (deliveryId: string, id: string) => ({
  payload: { deliveryId, id },
}));
export const templatePayloadFailure = createAction<string>(`${Service.deliverySvc}/templatePayloadFailure`);

const deliverySlice = createSlice({
  name: Service.deliverySvc,
  initialState,
  reducers: {
    fetchDeliveryKeyInByIdSuccess(state, { payload }: PayloadAction<Record<string, DeliveryKeyInResponse>>) {
      state.keyIn = {
        ...state.keyIn,
        ...payload,
      };
    },
    fetchDeliveryPaymentGroupRequest: {
      prepare: (id: string, stakeHolder: DELIVERY_STAKE_HOLDER) => ({ payload: { id, stakeHolder } }),
      reducer: (state) => {
        state.flag.isFetchingPaymentGroup = true;
      },
    },
    fetchDeliveryPaymentGroupSuccess: {
      prepare: (id: string, paymentGroup: DeliveryPaymentGroup) => ({ payload: { id, paymentGroup } }),
      reducer: (state, { payload: { id, paymentGroup } }: PayloadAction<{ id: string; paymentGroup: DeliveryPaymentGroup }>) => {
        state.paymentGroup[paymentGroup.type][id] = paymentGroup;
        state.flag.isFetchingPaymentGroup = false;
      },
    },
    fetchDeliveryPaymentGroupFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.flag.isFetchingPaymentGroup = false;
      },
    },
    fetchAvailableRideProductsSuccess(state, { payload }: PayloadAction<RideProduct[]>) {
      state.rideProducts = payload;
    },
    resetRideProducts(state) {
      state.rideProducts = [];
    },
    fetchDeliveryListSuccess(state, { payload }: PayloadAction<ListResponse<DeliveryInterface>>) {
      state.page = { total: payload.result.totalPages, current: payload.result.pageable.pageNumber + 1, limit: payload.result.size };
      state.ids = payload.result.content;
      state.byId = { ...state.byId, ...payload.entities.content };
      state.totalCount = payload.result.totalElements;
    },
    fetchDeliveryDetailSuccess(state, { payload }: PayloadAction<DetailResponse<DeliveryInterface>>) {
      //@ts-ignore
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    fetchDeliveryGlobalConfigSuccess(state, { payload }: PayloadAction<Config>) {
      state.deliveryGlobalConfig = payload;
      state.exportTypeLabel = payload.deliveryExportTypes.reduce<{ [key: string]: string }>((table, { id, option }) => {
        table[id] = option;

        return table;
      }, {});
    },
    preCreateFromCsvSuccess(state, { payload }: PayloadAction<DeliveryBulkPreCreateResponse>) {
      state.preCreateData = payload;
    },
    preCreateFromCsvFailure(state, { payload }: PayloadAction<{ error: string; bulk: DeliveryBulkPreCreateResponse | null }>) {
      state.preCreateData = {
        ...state.preCreateData,
        reasonsInvalid: payload.bulk?.reasonsInvalid ?? state.preCreateData.reasonsInvalid,
      };
    },
    cleanCsvInfo(state) {
      state.preCreateData = {
        ...deliveryInitialState.preCreateData,
      };
    },
    templatePayloadSuccess(state, { payload }: PayloadAction<TemplatePayloadInterface>) {
      state.templatePayloads = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.tag, deliveryTagReducer(state.tag, action));
      Object.assign(state.warehouse, warehouseReducer(state.warehouse, action));
    }),
});

export const {
  fetchDeliveryKeyInByIdSuccess,
  fetchDeliveryPaymentGroupRequest,
  fetchDeliveryPaymentGroupSuccess,
  fetchDeliveryPaymentGroupFailure,
  fetchAvailableRideProductsSuccess,
  resetRideProducts,
  fetchDeliveryListSuccess,
  fetchDeliveryDetailSuccess,
  fetchDeliveryGlobalConfigSuccess,
  preCreateFromCsvSuccess,
  preCreateFromCsvFailure,
  cleanCsvInfo,
  templatePayloadSuccess,
} = deliverySlice.actions;

export default deliverySlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import {
  PrefixCouponState,
  CreateCodeGeneratorDto,
  PrefixedCodeGeneratorResponseDto,
  UpdateCodeGeneratorDto,
  GenerateCodeDto,
} from '@/svc/coupon-svc/prefixCoupon/type';
import Service from '@/svc/serviceName';

export const initialState: PrefixCouponState = {
  isSubmit: false,
  isGenerate: false,
};

const prefixCouponSlice = createSlice({
  name: `${Service.couponSvc}/prefix-coupon`,
  initialState,
  reducers: {
    createPrefixCouponRequest: {
      prepare: (data: CreateCodeGeneratorDto) => ({ payload: data }),
      reducer: (state) => {
        state.isSubmit = true;
      },
    },
    createPrefixCouponSuccess: {
      prepare: (data: PrefixedCodeGeneratorResponseDto) => ({ payload: data }),
      reducer: (state) => {
        state.isSubmit = false;
      },
    },
    createPrefixCouponFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isSubmit = false;
      },
    },
    updatePrefixCouponRequest: {
      prepare: (id: string, data: UpdateCodeGeneratorDto) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isSubmit = true;
      },
    },
    updatePrefixCouponSuccess: {
      prepare: (data: PrefixedCodeGeneratorResponseDto) => ({ payload: data }),
      reducer: (state) => {
        state.isSubmit = false;
      },
    },
    updatePrefixCouponFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isSubmit = false;
      },
    },
    deletePrefixCouponRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isSubmit = true;
      },
    },
    deletePrefixCouponSuccess(state) {
      state.isSubmit = false;
    },
    deletePrefixCouponFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isSubmit = false;
      },
    },
    generatePrefixCouponRequest: {
      prepare: (id: string, data: GenerateCodeDto) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isGenerate = true;
      },
    },
    generatePrefixCouponSuccess(state) {
      state.isGenerate = false;
    },
    generatePrefixCouponFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isGenerate = false;
      },
    },
  },
});

export const {
  createPrefixCouponRequest,
  createPrefixCouponSuccess,
  createPrefixCouponFailure,
  updatePrefixCouponRequest,
  updatePrefixCouponSuccess,
  updatePrefixCouponFailure,
  deletePrefixCouponRequest,
  deletePrefixCouponSuccess,
  deletePrefixCouponFailure,
  generatePrefixCouponRequest,
  generatePrefixCouponSuccess,
  generatePrefixCouponFailure,
} = prefixCouponSlice.actions;

export default prefixCouponSlice.reducer;

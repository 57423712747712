import FileSaver from 'file-saver';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createScheduleSurgeFailure,
  createScheduleSurgeRequest,
  createScheduleSurgeSuccess,
  updateScheduleSurgeFailure,
  updateScheduleSurgeRequest,
  updateScheduleSurgeSuccess,
  downloadScheduleSurgeTemplateRequest,
  downloadScheduleSurgeTemplateFailure,
  deleteScheduleSurgeRequest,
  deleteScheduleSurgeSuccess,
  deleteScheduleSurgeFailure,
} from '@/pages/ScheduleSurge/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createScheduleSurge({ payload }: ReturnType<typeof createScheduleSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/scheduleSurges', { data: payload });

    const action = response.ok ? createScheduleSurgeSuccess(response.data) : createScheduleSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createScheduleSurgeFailure((e as Error).message));
  }
}

function* updateScheduleSurge({ payload: { id, body } }: ReturnType<typeof updateScheduleSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/scheduleSurges/${id}`, { data: body });

    const action = response.ok ? updateScheduleSurgeSuccess() : updateScheduleSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateScheduleSurgeFailure((e as Error).message));
  }
}

function* downloadScheduleSurgeTemplate({
  payload: { productId, ...params },
}: ReturnType<typeof downloadScheduleSurgeTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/scheduleSurges/forProduct/${productId}/template`, {
      params,
      responseType: 'blob',
    });

    if (response.ok) {
      FileSaver.saveAs(response.data, 'schedule_surge.xlsx');
    }
  } catch (e) {
    yield put(downloadScheduleSurgeTemplateFailure((e as Error).message));
  }
}

function* deleteScheduleSurge({ payload }: ReturnType<typeof deleteScheduleSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/scheduleSurges/${payload}`);

    const action = response.ok ? deleteScheduleSurgeSuccess() : deleteScheduleSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteScheduleSurgeFailure((e as Error).message));
  }
}

export default function* scheduleSurgeSaga() {
  yield takeLatest(createScheduleSurgeRequest.type, createScheduleSurge);
  yield takeLatest(updateScheduleSurgeRequest.type, updateScheduleSurge);
  yield takeLatest(downloadScheduleSurgeTemplateRequest.type, downloadScheduleSurgeTemplate);
  yield takeLatest(deleteScheduleSurgeRequest.type, deleteScheduleSurge);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { authenticatedRequest } from '@/utils/request';

import { fetchRoutesFailure, fetchRoutesRequest, fetchRoutesSuccess } from './reducer';

function* fetchRoutes({ payload: data }: ReturnType<typeof fetchRoutesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/routes', { data });

    if (response.ok) {
      yield put(fetchRoutesSuccess(response.data));
    } else {
      yield put(fetchRoutesFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRoutesFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchRoutesRequest.type, fetchRoutes);
}

import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import ButtonContainer from '@/components/ButtonContainer';
import { resetLeavePopupData } from '@/components/LeavePopup/reducer';
import { Button } from '@/components/template';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';

const DEFAULT_MESSAGE = "Changes you made may not be saved. You can't undo this action.";

const PopupContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 15px;
  flex-direction: column;

  > h3 {
    margin-bottom: 10px;
  }

  > div {
    margin: 10px 0 0 auto;
  }
`;

function LeavePopup() {
  const history = useHistory();
  const { open, link, content } = useAppState((state) => state.leavePopup);

  const handleCancel = () => {
    dispatch(resetLeavePopupData());
  };

  const handleLeave = () => {
    history.push(link);
    dispatch(resetLeavePopupData());
  };

  return (
    <Popup onClose={handleCancel} open={open} modal={true}>
      {(close) => (
        <PopupContainer>
          <h3>Leave?</h3>
          <span>{content || DEFAULT_MESSAGE}</span>
          <ButtonContainer>
            <Button color='primary' onClick={handleLeave}>
              Leave
            </Button>
            <Button color='secondary' onClick={close}>
              Cancel
            </Button>
          </ButtonContainer>
        </PopupContainer>
      )}
    </Popup>
  );
}

export default LeavePopup;

import { Button } from '@/components/template';

function FormFooterContent({ disabled }: { disabled: boolean }) {
  return (
    <Button className='tw-flex tw-ml-auto' color='primary' type='submit' disabled={disabled}>
      Confirm
    </Button>
  );
}

export default FormFooterContent;

import { createAction, createSlice } from '@reduxjs/toolkit';

import { RemarkLogAdminDto } from '@/pages/Rider/types';
import { CorporateMember } from '@/svc/corporate-svc/type';
import { Rider, RiderUpdateBodyAdmin } from '@/svc/ride-svc/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { ListResponse, PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface RiderState extends PaginationStoreState<Rider>, StandardStoreState {
  corporate: CorporateMember | null;
  remarkLog: RemarkLogAdminDto | null;
  isToggleFetching: boolean;
}

const initialState: RiderState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  isToggleFetching: false,
  error: null,
  corporate: null,
  remarkLog: null,
};

export const banUserRequest = createAction<string>('rider/banUserRequest');
export const banUserSuccess = createAction<any>('rider/banUserSuccess');
export const banUserFailure = createAction<string>('rider/banUserFailure');

export const createRemarkLogRequest = createAction('rider/createRemarkLogRequest', (data: any) => ({ payload: data }));
export const createRemarkLogSuccess = createAction<any>('rider/createRemarkLogSuccess');
export const createRemarkLogFailure = createAction<string>('rider/createRemarkLogFailure');

export const unbanRequest = createAction<string>('rider/unbanRequest');
export const unbanSuccess = createAction<any>('rider/unbanSuccess');
export const unbanFailure = createAction<string>('rider/unbanFailure');

export const fetchCorporateByUserIdFailure = createAction<string>('rider/fetchCorporateByUserIdFailure');

export const fetchRemarkLogRequest = createAction('rider/fetchRemarkLogRequest', (data: any) => ({ payload: data }));
export const fetchRemarkLogFailure = createAction<string>('rider/fetchRemarkLogFailure');

export const deleteLoginIdentifierRequest = createAction('rider/deleteLoginIdentifierRequest', (id: string, identifierId: string) => ({
  payload: { id, identifierId },
}));
export const deleteLoginIdentifierSuccess = createAction<any>('rider/deleteLoginIdentifierSuccess');
export const deleteLoginIdentifierFailure = createAction<string>('rider/deleteLoginIdentifierFailure');

export const deleteLongTokenIdentifierRequest = createAction<{ id: string; imei: string }>('rider/deleteLongTokenIdentifierRequest');
export const deleteLongTokenIdentifierSuccess = createAction('rider/deleteLongTokenIdentifierSuccess');
export const deleteLongTokenIdentifierFailure = createAction<string>('rider/deleteLongTokenIdentifierFailure');

export const updateRiderRequest = createAction('rider/updateRiderRequest', (id: string, body: RiderUpdateBodyAdmin) => ({
  payload: { id, body },
}));
export const updateRiderSuccess = createAction<any>('rider/updateRiderSuccess');
export const updateRiderFailure = createAction<string>('rider/updateRiderFailure');

export const deleteRiderRequest = createAction('rider/deleteRiderRequest', (id, data) => ({ payload: { id, data } }));
export const deleteRiderSuccess = createAction<any>('rider/deleteRiderSuccess');
export const deleteRiderFailure = createAction<string>('rider/deleteRiderFailure');

export const downloadBulkMappingMobileTemplateRequest = createAction(`rider/downloadBulkMappingMobileTemplateRequest`);
export const downloadBulkMappingMobileTemplateSuccess = createAction(`rider/downloadBulkMappingMobileTemplateSuccess`);
export const downloadBulkMappingMobileTemplateFailure = createAction<string>(`rider/downloadBulkMappingMobileTemplateFailure`);

export const bulkMappingMobileNumberRequest = createAction<File>(`rider/bulkMappingMobileNumberRequest`);
export const bulkMappingMobileNumberSuccess = createAction(`rider/bulkMappingMobileNumberSuccess`);
export const bulkMappingMobileNumberFailure = createAction<string>(`rider/bulkMappingMobileNumberFailure`);

const riderSlice = createSlice({
  name: 'rider',
  initialState,
  reducers: {
    fetchRiderListRequest(state) {
      state.isFetching = true;
      state.error = null;
    },
    fetchRiderListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchRiderListFailure(state, { payload }) {
      state.isFetching = false;
      state.error = payload;
    },

    fetchRiderRestrictListRequest: {
      prepare: (query) => ({ payload: { query } }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchRiderRestrictListSuccess(state, { payload }: { payload: ListResponse<Rider> }) {
      let ids = [...state.ids];

      if (payload.result.pageable.pageNumber === 0) {
        ids = payload.result.content as never[];
      } else {
        ids.push(...(payload.result.content as never[]));
      }

      state.isFetching = false;
      state.byId = { ...state.byId, ...payload.entities.content };
      state.ids = ids;
      state.page = {
        total: payload.result.totalPages,
        current: payload.result.pageable.pageNumber + 1,
      };
      state.totalCount = payload.result.totalElements;
    },
    fetchRiderRestrictListFailure(state, { payload }) {
      state.isFetching = false;
      state.error = payload;
    },

    fetchRiderDetailRequest: {
      prepare: (id: string, query?: { page: number; size: number }) => ({ payload: { id, query } }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchRiderDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchRiderDetailFailure(state, { payload }) {
      state.isFetching = false;
      state.error = payload;
    },
    removeRiderData(state) {
      state.byId = {};
      state.ids = [];
      state.isFetching = false;
      state.error = null;
    },

    fetchCorporateByUserIdRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.corporate = null;
      },
    },
    fetchCorporateByUserIdSuccess(state, { payload }) {
      state.corporate = payload;
    },

    fetchRemarkLogSuccess(state, { payload }) {
      state.remarkLog = payload;
    },

    fetchRiderConnectRequest: {
      prepare: (data) => ({ payload: data }),
      reducer: (state) => {
        state.isToggleFetching = true;
        state.error = null;
      },
    },
    fetchRiderConnectSuccess(state) {
      state.isToggleFetching = false;
    },
    fetchRiderConnectFailure(state, { payload }) {
      state.isToggleFetching = false;
      state.error = payload;
    },

    fetchRiderDisconnectRequest: {
      prepare: (userId) => ({ payload: userId }),
      reducer: (state) => {
        state.isToggleFetching = true;
        state.error = null;
      },
    },
    fetchRiderDisconnectSuccess(state) {
      state.isToggleFetching = false;
    },
    fetchRiderDisconnectFailure(state, { payload }) {
      state.isToggleFetching = false;
      state.error = payload;
    },
  },
});

export const {
  fetchRiderListRequest,
  fetchRiderListSuccess,
  fetchRiderListFailure,
  fetchRiderDetailRequest,
  fetchRiderDetailSuccess,
  fetchRiderDetailFailure,
  fetchRiderRestrictListRequest,
  fetchRiderRestrictListSuccess,
  fetchRiderRestrictListFailure,
  removeRiderData,
  fetchCorporateByUserIdRequest,
  fetchCorporateByUserIdSuccess,
  fetchRemarkLogSuccess,
  fetchRiderConnectRequest,
  fetchRiderConnectSuccess,
  fetchRiderConnectFailure,
  fetchRiderDisconnectRequest,
  fetchRiderDisconnectSuccess,
  fetchRiderDisconnectFailure,
} = riderSlice.actions;

export default riderSlice.reducer;

import moment from 'moment';
import { useParams } from 'react-router';

import { DetailParams } from '@tada/tada-web-common';

import { PATH, REGION } from '@/assets/constants';
import { Loading } from '@/components/Loading';
import { useToolkitActionToast } from '@/hooks/toast';
import Form from '@/pages/CpfContributionTable/Form';
import { sanitizeCorporateCpfContributionTable, transformToFormData, useCpfContributionTableDetail } from '@/pages/CpfContributionTable/hooks';
import { CpfContributionTableBody } from '@/pages/CpfContributionTable/type';
import { dispatch } from '@/store';
import { updateCpfContributionTableRequest } from '@/svc/balance-svc/cpfContributionTable/reducer';
import { formatDateWithRegion } from '@/utils/etc';

function Edit() {
  const { id } = useParams<DetailParams>();
  const data = useCpfContributionTableDetail(id);

  const onSubmit = (data: CpfContributionTableBody, file: File) => {
    dispatch(updateCpfContributionTableRequest({ id: id, data: transformToFormData(sanitizeCorporateCpfContributionTable(data, file)) }));
  };

  useToolkitActionToast(updateCpfContributionTableRequest.type, {
    successMessage: 'Update Successfully!',
    successRedirect: ({ payload }) => `${PATH.CpfContributionTable}/${payload}`,
  });

  const initialValues = {
    name: data?.title ?? '',
    region: REGION.SG,
    forOptIn: data?.forOptIn ?? true,
    validFrom: formatDateWithRegion({ date: moment(data?.validFrom), region: REGION.SG, format: 'DD-MM-YYYY HH:mm', keepLocalTime: false }),
    validUntil: data?.validUntil
      ? formatDateWithRegion({ date: moment(data.validUntil), region: REGION.SG, format: 'DD-MM-YYYY HH:mm', keepLocalTime: false })
      : null,
  };

  if (!data) {
    return <Loading />;
  }

  return <Form type='edit' initialValues={initialValues} onSubmit={onSubmit} />;
}

export default Edit;

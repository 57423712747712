import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  upsertBinManagementFailure,
  upsertBinManagementRequest,
  upsertBinManagementSuccess,
  deleteBinManagementFailure,
  deleteBinManagementRequest,
  deleteBinManagementSuccess,
} from '@/svc/pay-svc/binManagement/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deleteBinManagement({ payload }: ReturnType<typeof deleteBinManagementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/payment/issuers/${payload}`);

    if (response.ok) {
      yield put(deleteBinManagementSuccess());
    } else {
      yield put(deleteBinManagementFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteBinManagementFailure((e as Error).message));
  }
}

function* upsertBinManagement({ payload }: ReturnType<typeof upsertBinManagementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/issuers`, { data: payload });

    if (response.ok) {
      yield put(upsertBinManagementSuccess(response.data));
    } else {
      yield put(upsertBinManagementFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertBinManagementFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(deleteBinManagementRequest.type, deleteBinManagement);
  yield takeLatest(upsertBinManagementRequest.type, upsertBinManagement);
}

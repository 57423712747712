import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createCpfContributionTableFailure,
  createCpfContributionTableRequest,
  createCpfContributionTableSuccess,
  downloadCpfContributionTableTemplateFailure,
  downloadCpfContributionTableTemplateRequest,
  downloadCpfContributionTableTemplateSuccess,
  exportCpfContributionTableRequest,
  updateCpfContributionTableFailure,
  updateCpfContributionTableRequest,
  updateCpfContributionTableSuccess,
} from '@/svc/balance-svc/cpfContributionTable/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const prefix = `/admin/cpf/tables`;

function* downloadCpfContributionTableTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/cpf/table/template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'cpf_contribution_table_template.xlsx');
      yield put(downloadCpfContributionTableTemplateSuccess());
    } else {
      yield put(downloadCpfContributionTableTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadCpfContributionTableTemplateFailure((e as Error).message));
  }
}

function* exportCpfContributionTable({ payload }: ReturnType<typeof exportCpfContributionTableRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${prefix}/${payload.id}/export`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'cpf_contribution_table_export.xlsx');
      yield put(downloadCpfContributionTableTemplateSuccess());
    } else {
      yield put(downloadCpfContributionTableTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadCpfContributionTableTemplateFailure((e as Error).message));
  }
}

function* createCpfContributionTable({ payload }: ReturnType<typeof createCpfContributionTableRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${prefix}`, { data: payload, contentType: 'multipart/form-data' });
    if (response.ok) {
      yield put(createCpfContributionTableSuccess(response.data.id));
    } else {
      yield put(createCpfContributionTableFailure(response.data.message));
    }
  } catch (e) {
    yield put(createCpfContributionTableFailure((e as Error).message));
  }
}

function* updateCpfContributionTable({ payload }: ReturnType<typeof updateCpfContributionTableRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${prefix}/${payload.id}`, { data: payload.data, contentType: 'multipart/form-data' });
    if (response.ok) {
      yield put(updateCpfContributionTableSuccess(response.data.id));
    } else {
      yield put(updateCpfContributionTableFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateCpfContributionTableFailure((e as Error).message));
  }
}

export default function* cpfContributionTableSaga() {
  yield takeLatest(downloadCpfContributionTableTemplateRequest.type, downloadCpfContributionTableTemplate);
  yield takeLatest(createCpfContributionTableRequest.type, createCpfContributionTable);
  yield takeLatest(updateCpfContributionTableRequest.type, updateCpfContributionTable);
  yield takeLatest(exportCpfContributionTableRequest.type, exportCpfContributionTable);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createTaxPrefillDescriptionFailure,
  createTaxPrefillDescriptionRequest,
  createTaxPrefillDescriptionSuccess,
  updateTaxPrefillDescriptionFailure,
  updateTaxPrefillDescriptionRequest,
  updateTaxPrefillDescriptionSuccess,
} from '@/svc/member-svc/taxPrefillDescription/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createTaxPrefillDescription({ payload }: ReturnType<typeof createTaxPrefillDescriptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/tax-prefill/description', { data: payload });

    const action = response.ok ? createTaxPrefillDescriptionSuccess(response.data) : createTaxPrefillDescriptionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createTaxPrefillDescriptionFailure((e as Error).message));
  }
}

function* updateTaxPrefillDescription({ payload: { id, data } }: ReturnType<typeof updateTaxPrefillDescriptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/drivers/tax-prefill/description/${id}`, { data });

    const action = response.ok ? updateTaxPrefillDescriptionSuccess() : updateTaxPrefillDescriptionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateTaxPrefillDescriptionFailure((e as Error).message));
  }
}

export default function* taxPrefillDescriptionSaga() {
  yield takeLatest(createTaxPrefillDescriptionRequest.type, createTaxPrefillDescription);
  yield takeLatest(updateTaxPrefillDescriptionRequest.type, updateTaxPrefillDescription);
}

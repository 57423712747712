import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

export const rejectDeliveryRideReqRequest = createAction<string>(`${Service.deliverySvc}/deliveryRideRequest/rejectDeliveryRideReqRequest`);
export const rejectDeliveryRideReqSuccess = createAction(`${Service.deliverySvc}/deliveryRideRequest/rejectDeliveryRideReqSuccess`);
export const rejectDeliveryRideReqFailure = createAction<string>(`${Service.deliverySvc}/deliveryRideRequest/rejectDeliveryRideReqFailure`);

export const generateDeliveryRideReqRequest = createAction<string>(`${Service.deliverySvc}/deliveryRideRequest/generateDeliveryRideReqRequest`);
export const generateDeliveryRideReqSuccess = createAction(`${Service.deliverySvc}/deliveryRideRequest/generateDeliveryRideReqSuccess`);
export const generateDeliveryRideReqFailure = createAction<string>(`${Service.deliverySvc}/deliveryRideRequest/generateDeliveryRideReqFailure`);

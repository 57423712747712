import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { Resource } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import ScanDeliveryQRInput from '@/components/ScanDeliveryQRInput';
import { Button, Label } from '@/components/template';
import nav from '@/layout/Sidebar/_nav';
import { useAppState } from '@/store/selector';
import { closePopup, togglePopup } from '@/svc/common/reducer';
import { selectDeliverySearchOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';
import { getResourcePages, QR_CODE_TYPE } from '@/utils/etc';

const resourcePages = getResourcePages();
const deliveryPagePermissions = resourcePages[Resource.Delivery]; // permission[] | undefined
const deliveryRoute = nav.items
  .reduce((data: any[], item) => {
    if (item.children) {
      data = [...data, item, ...item.children];
    } else {
      data = [...data, item];
    }

    return data;
  }, [])
  .filter((item) => (deliveryPagePermissions || []).includes(item.Pages));

export const checkDeliveryRelationPage = () => deliveryRoute.find((item) => window.location.pathname.includes(item.url));

const SearchButton = styled(Button)`
  color: white;
  margin-left: auto !important;
`;
export function SearchDeliveryButton() {
  const dispatch = useDispatch();

  const showDeliverySearchPopup = () => dispatch(togglePopup(POPUP_TYPE.DeliverySearch));

  return (
    <SearchButton color={'primary'} onClick={showDeliverySearchPopup}>
      Search Delivery(Left Alt + Q)
    </SearchButton>
  );
}

function DeliveryQrSearchPopup() {
  const dispatch = useDispatch();
  const history = useHistory();

  const open = useAppState(selectDeliverySearchOpenFlag);

  const handleScanSuccess = useCallback((id: string, type?: QR_CODE_TYPE) => {
    dispatch(closePopup(POPUP_TYPE.DeliverySearch));

    history.push(type ? `${PATH.Delivery}/${id}` : `${PATH.Delivery}?parcelId=${id}&page=1&limit=20`);
  }, []);

  return (
    <Popup
      contentStyle={{ padding: '50px', minWidth: '60%', maxWidth: '80%', maxHeight: '80%', overflow: 'auto', minHeight: '500px' }}
      open={open}
      modal
    >
      {() => (
        <div>
          <Button
            style={{ display: 'contents' }}
            onClick={() => {
              dispatch(closePopup(POPUP_TYPE.DeliverySearch));
            }}
          >
            <i style={{ position: 'absolute', fontSize: '30px', padding: '15px', top: '0', right: '0' }} className={'fa fa-times'} />
          </Button>
          <h4 style={{ marginBottom: '30px' }}>Search Delivery with QR Code</h4>
          <Label>Please input your Uuid or scan your QR Code.</Label>
          <ScanDeliveryQRInput
            onSuccess={handleScanSuccess}
            innerRef={(ref) => {
              setTimeout(() => {
                ref && ref.focus();
              });
            }}
          />
        </div>
      )}
    </Popup>
  );
}

export default DeliveryQrSearchPopup;

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppVersion, AppVersionQuery, AppVersionState, TableRowAppVersion } from '@/pages/AppVersion/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

export const deleteAppVersionRequest = createAction<TableRowAppVersion>(`appVersion/deleteAppVersionRequest`);
export const deleteAppVersionSuccess = createAction<TableRowAppVersion>(`appVersion/deleteAppVersionSuccess`);
export const deleteAppVersionFailure = createAction<string>(`appVersion/deleteAppVersionFailure`);

const initialState: AppVersionState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
};

const appVersionSlice = createSlice({
  name: 'appVersion',
  initialState,
  reducers: {
    fetchAppVersionListRequest: {
      prepare: (search: AppVersionQuery) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchAppVersionListSuccess: {
      prepare: (payload: ListResponse<AppVersion>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<AppVersion>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchAppVersionListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchAppVersionDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchAppVersionDetailSuccess: {
      prepare: (payload: DetailResponse<AppVersion>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<AppVersion>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchAppVersionDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createAppVersionRequest: {
      prepare: (data: AppVersion) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createAppVersionSuccess: {
      prepare: (payload: AppVersion) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createAppVersionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateAppVersionRequest: {
      prepare: (id: string, data: AppVersion) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateAppVersionSuccess: {
      prepare: (payload: AppVersion) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateAppVersionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchAppVersionListRequest,
  fetchAppVersionListSuccess,
  fetchAppVersionListFailure,
  fetchAppVersionDetailRequest,
  fetchAppVersionDetailSuccess,
  fetchAppVersionDetailFailure,
  createAppVersionRequest,
  createAppVersionSuccess,
  createAppVersionFailure,
  updateAppVersionRequest,
  updateAppVersionSuccess,
  updateAppVersionFailure,
} = appVersionSlice.actions;

export default appVersionSlice.reducer;

import Popup from 'reactjs-popup';

import { Button } from '@/components/template';
import ResetPasswordPopupContent from '@/pages/ResetPassword/parts/ResetPasswordPopupContent';

function ResetPasswordPopup() {
  return (
    <Popup
      trigger={
        <Button type='button' color='secondary'>
          Forgot Password
        </Button>
      }
      contentStyle={{
        width: '800px',
        padding: '0',
        border: '0',
      }}
      modal
      closeOnDocumentClick
      closeOnEscape
    >
      {(close) => <ResetPasswordPopupContent close={close} />}
    </Popup>
  );
}

export default ResetPasswordPopup;

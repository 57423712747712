import { NavItem as NavItemComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface NavItemProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  active?: boolean;
  cssModule?: CSSModule;
}

export function NavItem(props: NavItemProps) {
  return <NavItemComponent {...props} />;
}

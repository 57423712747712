import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "SurgeCapList" */ '@/pages/SurgeCap/List'));
const Detail = lazy(() => import(/* webpackChunkName: "SurgeCapDetail" */ '@/pages/SurgeCap/Detail'));
const New = lazy(() => import(/* webpackChunkName: "SurgeCapNew" */ '@/pages/SurgeCap/New'));
const Edit = lazy(() => import(/* webpackChunkName: "SurgeCapEdit" */ '@/pages/SurgeCap/Edit'));

export const surgeCapRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.SurgeCap,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SurgeCap}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SurgeCap}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.SurgeCap}/:id`,
    component: Detail,
    exact: true,
  },
];

import branch from 'branch-sdk';
import * as pdfjsLib from 'pdfjs-dist';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import '@/config/i18n';
// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '@/index.css';
import '@/scss/style.scss';
import 'react-datetime/css/react-datetime.css';
import '@/pages/Full/SearchPageInput.css';
import 'react-phone-number-input/style.css';
import 'rc-slider/assets/index.css';
// global style
import GlobalStyle from '@/globalStyle';
import App from '@/pages/App';
import store from '@/store';
import SWRProvider from '@/utils/request/SWRProvider';

branch.init(import.meta.env.VITE_BRANCH_KEY as string);

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <SWRProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </SWRProvider>
  </Provider>,
  document.getElementById('root')
);

// cypress가 실행됐을 때 window.store에 store 지정
//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store;
}

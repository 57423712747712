import { all, call } from 'redux-saga/effects';

import binManagementSaga from '@/svc/pay-svc/binManagement/saga';
import paySvcCreditCardSaga from '@/svc/pay-svc/creditCards/saga';
import shbRequestHistorySaga from '@/svc/pay-svc/shbRequestHistory/saga';
import shbWalletSaga from '@/svc/pay-svc/shbWallet/saga';
import paySvcTransactionSaga from '@/svc/pay-svc/transaction/saga';
import wingTransactionSaga from '@/svc/pay-svc/wingTransaction/saga';

export default function* paySvcSaga() {
  yield all([
    call(paySvcTransactionSaga),
    call(paySvcCreditCardSaga),
    call(shbRequestHistorySaga),
    call(shbWalletSaga),
    call(wingTransactionSaga),
    call(binManagementSaga),
  ]);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "BannerList" */ '@/pages/Banner/List'));
const Detail = lazy(() => import(/* webpackChunkName: "BannerDetail" */ '@/pages/Banner/Detail'));
const New = lazy(() => import(/* webpackChunkName: "BannerNew" */ '@/pages/Banner/New'));
const Edit = lazy(() => import(/* webpackChunkName: "BannerEdit" */ '@/pages/Banner/Edit'));

export const bannerRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Banner,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Banner}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Banner}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Banner}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

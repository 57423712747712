import { ReactElement, useEffect, useState } from 'react';

const googleMapApiKey = {
  TADA: import.meta.env.VITE_TADA_GOOGLE_API_KEY as string,
  ETC: import.meta.env.VITE_ETC_GOOGLE_API_KEY as string,
};

const TADA_HOST = [
  'admin.tada.global',
  'admin.driver.tada-dev.com',
  'newadmin.tada-dev.com',
  'admin.driver.tada-staging.com',
  'newadmin.tada-staging.com',
  'admin.driver.tada.global',
  'newadmin.tada.global',
  'localhost',
  '127.0.0.1',
  'admin.tada-staging.com',
];

const getApiKey = () => googleMapApiKey[TADA_HOST.includes(window.location.hostname) ? 'TADA' : 'ETC'];

function GoogleMapLoader({ children }: { children: ReactElement }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const apiKey = getApiKey();

    const script = document.createElement('script');

    script.onload = () => {
      // eslint-disable-next-line no-console
      console.log('google script loaded');

      setLoaded(true);
    };

    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization,geometry&language=en`;

    document.head.appendChild(script);
  }, []);

  return loaded ? children : <div />;
}

export default GoogleMapLoader;

import { RouteProps as _RouteProps } from 'react-router';

import { Pageable, Sort } from '@tada/tada-web-common';

import { ActionType } from '@/components/CheckPermission';

export const removeDeprecatedType = <T = any>(arr: T[], ...deprecatedTypes: T[]) => {
  return arr.filter((type) => !deprecatedTypes.includes(type));
};

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

export type ById<T = any> = { [key in string]: T };

export type Nullable<T> = { [key in keyof T]: T[keyof T] | null };

export interface StandardStoreState {
  error: string | null;
  isFetching: boolean;
}

export interface PaginationStoreState<T = any, K = any> {
  page: { total: number; current: number; limit?: number };
  ids: K[];
  byId: ById<T>;
  totalCount: number;
}

export interface originListResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export interface ListResponse<T> {
  entities: {
    content: ById<T>;
  };
  result: {
    content: string[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
    totalElements: number;
    totalPages: number;
  };
}

export interface DetailResponse<T = any> {
  payload: {
    entities: {
      content: ById<T>;
    };
  };
  result: string;
}

export interface RouteProps extends _RouteProps {
  permission?: ActionType;
  path: string | string[];
}

export interface ErrorResponse {
  code: string;
  error: string;
  message: string;
}

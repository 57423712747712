import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createOtpSkipRuleFailure,
  createOtpSkipRuleRequest,
  createOtpSkipRuleSuccess,
  deleteOtpSkipRuleFailure,
  deleteOtpSkipRuleRequest,
  deleteOtpSkipRuleSuccess,
} from '@/svc/account-svc/otpSkipRule/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createOtpSkipRule({ payload }: ReturnType<typeof createOtpSkipRuleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/accountsvc/admin/otp-skip-rules', { data: payload });

    if (response.ok) {
      yield put(createOtpSkipRuleSuccess());
    } else {
      yield put(createOtpSkipRuleFailure(response.data.message));
    }
  } catch (e) {
    yield put(createOtpSkipRuleFailure((e as Error).message));
  }
}

function* deleteOtpSkipRule({ payload }: ReturnType<typeof deleteOtpSkipRuleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accountsvc/admin/otp-skip-rules/${payload}`);

    if (response.ok) {
      yield put(deleteOtpSkipRuleSuccess());
    } else {
      yield put(deleteOtpSkipRuleFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteOtpSkipRuleFailure((e as Error).message));
  }
}

export default function* otpSkipRuleSaga() {
  yield takeLatest(createOtpSkipRuleRequest.type, createOtpSkipRule);
  yield takeLatest(deleteOtpSkipRuleRequest.type, deleteOtpSkipRule);
}

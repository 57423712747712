import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateDriverGroup, DriverGroupDetail, DriverGroupListDto, DriverGroupParam, UpdateDriversInDriverGroup } from '@/pages/DriverGroup/type';
import { PenaltyFormInterface, PenaltyBulkReleaseDto } from '@/pages/Penalty/types';
import Service from '@/svc/serviceName';
import { originListResponse } from '@/utils/types';

export interface PenaltySvcState {
  isFetching: boolean;
  error: string | null;
  driverGroup: {
    isFetching: boolean;
    isDownload: boolean;
    driverGroups: DriverGroupListDto[];
    detail: DriverGroupDetail | null;
    page: { total: number; current: number };
    error: string | null;
    totalCount: number;
  };
}

const initialState: PenaltySvcState = {
  isFetching: false,
  error: null,
  driverGroup: {
    isFetching: false,
    isDownload: false,
    driverGroups: [],
    detail: null,
    page: { total: 1, current: 1 },
    error: null,
    totalCount: 0,
  },
};

const DriverGroupPrefix = `${Service.penaltySvc}/driverGroup`;

export const fetchDriverGroupListRequest = createAction<DriverGroupParam>(`${DriverGroupPrefix}/fetchDriverGroupListRequest`);
export const fetchDriverGroupListFailure = createAction<string>(`${DriverGroupPrefix}/fetchDriverGroupListFailure`);

export const fetchDriverGroupDetailRequest = createAction<string>(`${DriverGroupPrefix}/fetchDriverGroupDetailRequest`);
export const fetchDriverGroupDetailFailure = createAction<string>(`${DriverGroupPrefix}/fetchDriverGroupDetailFailure`);

export const createDriverGroupRequest = createAction<{ data: CreateDriverGroup }>(`${DriverGroupPrefix}/createDriverGroupRequest`);
export const createDriverGroupSuccess = createAction<DriverGroupDetail>(`${DriverGroupPrefix}/createDriverGroupSuccess`);
export const createDriverGroupFailure = createAction<string>(`${DriverGroupPrefix}/createDriverGroupFailure`);

export const updateDriverGroupRequest = createAction<{ id: string; data: CreateDriverGroup }>(`${DriverGroupPrefix}/updateDriverGroupRequest`);
export const updateDriverGroupSuccess = createAction<DriverGroupDetail>(`${DriverGroupPrefix}/updateDriverGroupSuccess`);
export const updateDriverGroupFailure = createAction<string>(`${DriverGroupPrefix}/updateDriverGroupFailure`);

export const deleteDriverGroupRequest = createAction<string>(`${DriverGroupPrefix}/deleteDriverGroupRequest`);
export const deleteDriverGroupSuccess = createAction(`${DriverGroupPrefix}/deleteDriverGroupSuccess`);
export const deleteDriverGroupFailure = createAction<string>(`${DriverGroupPrefix}/deleteDriverGroupFailure`);

export const updateDriverGroupUsersRequest = createAction<{ id: string; data: FormData | UpdateDriversInDriverGroup[] }>(
  `${DriverGroupPrefix}/updateDriverGroupUsersRequest`
);
export const updateDriverGroupUsersSuccess = createAction(`${DriverGroupPrefix}/updateDriverGroupUsersSuccess`);
export const updateDriverGroupUsersFailure = createAction<string>(`${DriverGroupPrefix}/updateDriverGroupUsersFailure`);

export const releasePenaltyRequest = createAction(`${Service.penaltySvc}/releasePenaltyRequest`, (id: string, data: any) => ({
  payload: { id, data },
}));
export const releasePenaltySuccess = createAction(`${Service.penaltySvc}/releasePenaltySuccess`);
export const releasePenaltyFailure = createAction<string>(`${Service.penaltySvc}/releasePenaltyFailure`);

export const imposedPenaltyRequest = createAction<PenaltyFormInterface>(`${Service.penaltySvc}/imposedPenaltyRequest`);
export const imposedPenaltySuccess = createAction(`${Service.penaltySvc}/imposedPenaltySuccess`);
export const imposedPenaltyFailure = createAction<string>(`${Service.penaltySvc}/imposedPenaltyFailure`);

export const exportPenaltyRequest = createAction<any>(`${Service.penaltySvc}/exportPenaltyRequest`);
export const exportPenaltySuccess = createAction(`${Service.penaltySvc}/exportPenaltySuccess`);
export const exportPenaltyFailure = createAction<string>(`${Service.penaltySvc}/exportPenaltyFailure`);

export const bulkReleasePenaltyRequest = createAction<PenaltyBulkReleaseDto>(`${Service.penaltySvc}/bulkReleasePenaltyRequest`);
export const bulkReleasePenaltySuccess = createAction(`${Service.penaltySvc}/bulkReleasePenaltySuccess`);
export const bulkReleasePenaltyFailure = createAction<string>(`${Service.penaltySvc}/bulkReleasePenaltyFailure`);

export const penaltySvcSlice = createSlice({
  name: Service.penaltySvc,
  initialState,
  reducers: {
    fetchDriverGroupListSuccess: {
      reducer: (state, { payload }: PayloadAction<originListResponse<DriverGroupListDto>>) => {
        state.driverGroup.driverGroups = payload.content;
        state.driverGroup.page.total = payload.totalPages;
        state.driverGroup.page.current = payload.pageable.pageNumber + 1;
        state.driverGroup.totalCount = payload.totalElements;
      },
      prepare: (data: originListResponse<DriverGroupListDto>) => ({ payload: data }),
    },
    fetchDriverGroupDetailSuccess(state, { payload }: PayloadAction<DriverGroupDetail>) {
      state.driverGroup.detail = payload;
    },
    getDriverGroupTemplateRequest(state) {
      state.driverGroup.isDownload = true;
    },
    getDriverGroupTemplateSuccess(state) {
      state.driverGroup.isDownload = false;
    },
    getDriverGroupTemplateFailure(state, { payload }: PayloadAction<string>) {
      state.driverGroup.isDownload = false;
      state.driverGroup.error = payload;
    },
  },
});

export const {
  fetchDriverGroupListSuccess,
  fetchDriverGroupDetailSuccess,
  getDriverGroupTemplateRequest,
  getDriverGroupTemplateSuccess,
  getDriverGroupTemplateFailure,
} = penaltySvcSlice.actions;

export default penaltySvcSlice.reducer;

import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchFraudLogDetailFailure,
  fetchFraudLogDetailRequest,
  fetchFraudLogDetailSuccess,
  fetchFraudLogListFailure,
  fetchFraudLogListRequest,
  fetchFraudLogListSuccess,
} from '@/pages/FraudLog/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity, schema } = getContentSchema();

function* fetchFraudLogList({ payload: { search } }: ReturnType<typeof fetchFraudLogListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/payment/fraud_detection/log', {
      params: search,
      schema,
    });

    if (response.ok) {
      yield put(fetchFraudLogListSuccess(response.data));
    } else {
      yield put(fetchFraudLogListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchFraudLogListFailure((e as Error).message));
  }
}

function* fetchFraudLogDetail({ payload: { id } }: ReturnType<typeof fetchFraudLogDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/payment/fraud_detection/log/${id}`, { schema: entity });

    if (response.ok) {
      yield put(fetchFraudLogDetailSuccess(response.data));
    } else {
      yield put(fetchFraudLogDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchFraudLogDetailFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchFraudLogListRequest.type, fetchFraudLogList);
  yield takeLatest(fetchFraudLogDetailRequest.type, fetchFraudLogDetail);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "BINManagementList" */ '@/pages/BINManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "BINManagementDetail" */ '@/pages/BINManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "BINManagementNew" */ '@/pages/BINManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "BINManagementEdit" */ '@/pages/BINManagement/Edit'));

export const binManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.BINManagement,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.BINManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.BINManagement}/:code/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.BINManagement}/:code`,
    exact: true,
    component: Detail,
  },
];

import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

export const createBannerRequest = createAction<any>(`${Service.inboxSvc}/banner/createBannerRequest`);
export const createBannerSuccess = createAction(`${Service.inboxSvc}/banner/createBannerSuccess`);
export const createBannerFailure = createAction<string>(`${Service.inboxSvc}/banner/createBannerFailure`);

export const updateBannerRequest = createAction(`${Service.inboxSvc}/banner/updateBannerRequest`, (id: string, data: FormData) => ({
  payload: { id, data },
}));
export const updateBannerSuccess = createAction(`${Service.inboxSvc}/banner/updateBannerSuccess`);
export const updateBannerFailure = createAction<string>(`${Service.inboxSvc}/banner/updateBannerFailure`);

export const deleteBannerRequest = createAction<string>(`${Service.inboxSvc}/banner/deleteBannerRequest`);
export const deleteBannerSuccess = createAction(`${Service.inboxSvc}/banner/deleteBannerSuccess`);
export const deleteBannerFailure = createAction<string>(`${Service.inboxSvc}/banner/deleteBannerFailure`);

// const bannerSlice = createSlice({
//   name: `${Service.inboxSvc}/banner`,
//   initialState: {},
//   reducers: {},
// });

// export default bannerSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { default as transactionReducer, initialState as transaction } from '@/svc/pay-svc/transaction/reducer';
import { PaySvcState } from '@/svc/pay-svc/type';
import Service from '@/svc/serviceName';

const initialState: PaySvcState = {
  transaction,
};

const paySvcSlice = createSlice({
  name: Service.paySvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.transaction, transactionReducer(state.transaction, action));
    }),
});

export default paySvcSlice.reducer;

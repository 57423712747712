export const PIPAY_TRANSACTION_COLUMNS = {
  ID: 'id',
  USER_ID: 'userId',
  RENTAL_NUMBER: 'rentalNumber',
  STATUS: 'status',
  AMOUNT: 'amount',
  APPLICATION_FEE: 'applicationFee',
  CREATED_AT: 'createdAt',
} as const;

export const PIPAY_TRANSACTION_COLUMNS_LIST = Object.values(PIPAY_TRANSACTION_COLUMNS);

export const PIPAY_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: PIPAY_TRANSACTION_COLUMNS.ID, label: 'ID' },
  { key: PIPAY_TRANSACTION_COLUMNS.USER_ID, label: 'User UUID' },
  { key: PIPAY_TRANSACTION_COLUMNS.RENTAL_NUMBER, label: 'Ride Id' },
  { key: PIPAY_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: PIPAY_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: PIPAY_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Application Fee' },
  { key: PIPAY_TRANSACTION_COLUMNS.CREATED_AT, label: 'Created At' },
];

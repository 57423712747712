import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CarList" */ '@/pages/Cars/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CarDetail" */ '@/pages/Cars/Detail'));

export const carRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Cars,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Cars}/:id`,
    exact: true,
    component: Detail,
  },
];

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CorporateManagerList" */ '@/pages/CorporateManager/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CorporateManagerDetail" */ '@/pages/CorporateManager/Detail'));
const New = lazy(() => import(/* webpackChunkName: "CorporateManagerNew" */ '@/pages/CorporateManager/New'));
const Edit = lazy(() => import(/* webpackChunkName: "CorporateManagerEdit" */ '@/pages/CorporateManager/Edit'));

export const corporateManagerRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.CorporateManager,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.CorporateManager}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.CorporateManager}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.CorporateManager}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

import { CSSProperties } from 'react';

import styled from 'styled-components';

interface BooleanProps {
  /**
   * 참인지 아닌지에 대한 여부
   */
  flag: any;
  /**
   * 스타일을 커스텀할 수 있도록 만든 prop
   */
  customStyle?: CSSProperties | string;
}

/**
 * `flag` 값이 참인지 아닌 지에 따라 BooleanIcon을 반환하는 컴포넌트이다.
 */
export //docs export

const BooleanIcon = styled.i.attrs(({ flag }: BooleanProps) => ({
  className: `fa fa-${flag ? 'check' : 'times'}`,
}))<BooleanProps>`
  color: ${({ flag }: BooleanProps) => (flag ? 'green' : 'red')};
  width: 14px;
  height: 14px;
  text-align: center;

  ${({ customStyle }: any) => customStyle}
`;

export default BooleanIcon;

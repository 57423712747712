import { DropdownItem as DropdownItemComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface DropdownItemProps extends React.HTMLAttributes<HTMLElement> {
  active?: boolean;
  disabled?: boolean;
  divider?: boolean;
  tag?: React.ElementType;
  header?: boolean;
  cssModule?: CSSModule;
  href?: string;
  toggle?: boolean;
  text?: boolean;
}

export function DropdownItem(props: DropdownItemProps) {
  return <DropdownItemComponent {...props} />;
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  updateMaintenanceInfoRequest,
  updateMaintenanceInfoSuccess,
  updateMaintenanceInfoFailure,
  updateMaintenanceBlockRequest,
  updateMaintenanceBlockSuccess,
  updateMaintenanceBlockFailure,
} from '@/svc/inbox-svc/maintenance/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateMaintenanceInfo({ payload }: ReturnType<typeof updateMaintenanceInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put('/maintenance/info', { data: payload });

    const action = response.ok ? updateMaintenanceInfoSuccess() : updateMaintenanceInfoFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateMaintenanceInfoFailure((e as Error).message));
  }
}

function* updateMaintenanceBlock({ payload }: ReturnType<typeof updateMaintenanceBlockRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/maintenance/${payload.blockType === 'blockApp' ? 'block_app' : 'block_ride'}`, {
      data: payload.body,
    });

    const action = response.ok ? updateMaintenanceBlockSuccess() : updateMaintenanceBlockFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateMaintenanceBlockFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(updateMaintenanceInfoRequest.type, updateMaintenanceInfo);
  yield takeLatest(updateMaintenanceBlockRequest.type, updateMaintenanceBlock);
}

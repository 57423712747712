const regex = {
  url: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
  uuid: /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/,
  uuidOnly: /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/,
  uuidRoute: '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}',
  deliveryRequestQR: /^DR:([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/,
  deliveryQR: /^([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})\|(.+(?=|))$/,
  drParcelId: /^dr:(.+)$/,
  coordinate: /^([\d.]+)[,\s]+([\d.]+)$/, // lat,lng
  cron: /^(((\d+,)+\d+|([\d*]+(\/|-)\d+)|\d+|\*) ?){6,6}$/,
  fetchAction: /^([^/]+)\/fetch(.+)Request$/,
  date: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
  dateTime: /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/,
  otpTarget: /^[0-9]{1,500}$/,
  wildcardDateStr: /^[?\d]{8}\d{4}$/,
  email: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
  corporateName: /^([\w가-힣@()\-.,#/&\s_])+$/,
  invalidCorporeateName: /[^\w가-힣@()\-.,#/&\s_]/g,
  image: /(\.gif|\.jpg|\.jpeg|\.png)$/i,
  file: /^[\w,\s-]+\.[0-9A-Za-z]{2,5}$/,
  sgPlate: /(?=.{4,8}$)^[sepSEP][a-zA-Z]{0,2}\d{1,4}[a-zA-Z]$/,
  passwordMinimumCharacterLength: /^.{8,}$/,
  passwordContainBothLetterAndNumber: /^(?=.*[a-zA-Z])(?=.*[0-9]).*$/,
  passwordContainUpperCaseLetter: /^(?=.*[A-Z]).*$/,
  passwordContainLowerCaseLetter: /^(?=.*[a-z]).*$/,
  passwordContainSymbol: /^(?=.*[\W_]).*$/,
  passwordNotIncludeSpace: /^\S*$/,
  SG: {
    name: /^[A-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~ ]*$/,
    driverEmail: /^[a-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]*$/,
    nric: /^[A-Z0-9]{9}$/,
    relation: /^[A-Z]+$/,
    address: /^[A-Z0-9\W]+$/,
    vlId: /^[A-Z0-9]{9,10}$/,
    carMaker: /^[A-Z0-9 ]+$/,
    carModel: /^[A-Z0-9 -]+$/,
    carModelInput: /[^\w\s-]|_/,
  },
  specialText: /[^\w\s]|_/,
  specialTextWithSpace: /[^\w\s]|_| /,
  maxTwoNewLines: /^(?:.*(?:\n.*)?){0,2}$/,
  alphabetAndNumberAndDash: /^[a-zA-Z0-9-]+$/,
  e164PhoneNumber: /^\+[1-9]\d{1,14}$/,
};

export default regex;

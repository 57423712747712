import { useState, useEffect } from 'react';

import { useLocation } from 'react-router';

import { checkDeliveryRelationPage } from '@/components/Popup/DeliveryQrSearchPopup';

function useDeliveryQrScanner() {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(checkDeliveryRelationPage());
  }, [location.pathname]);

  return { isVisible };
}

export default useDeliveryQrScanner;

import { ChangeEvent, useEffect, useState } from 'react';

import moment from 'moment';
import DT, { DatetimepickerProps } from 'react-datetime';
import styled from 'styled-components';

import { Input } from '@/components/template';
import { isValidDate } from '@/utils/dateUtils';
import { debounce } from '@/utils/etc';
import { checkFormat, getFormattingTime } from '@/utils/util';

export interface DateTimeProps extends DatetimepickerProps {
  debounce?: number;
}

const DateTimeClearInputWrapper = styled.div`
  position: relative;
  & > .btn_clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const ReactDateTime = ((DT as any).default ? (DT as any).default : DT) as typeof DT;

const dateTimeDebounce = debounce();

export function Datetime({ debounce: debounceTime, onChange, value: inputValue, ...props }: DateTimeProps) {
  const [value, setValue] = useState<string>('');

  const format = `${props.dateFormat ?? ''}${props.timeFormat ? ` ${props.timeFormat}` : ''}`;

  const handleChange = (value: string | moment.Moment) => {
    if (!onChange) return;
    if (debounceTime) {
      dateTimeDebounce(() => onChange(value), debounceTime);
    } else {
      onChange(value);
    }
  };

  const handleValue = (value: string) => {
    setValue(isValidDate(value) && checkFormat(value, format) ? getFormattingTime(value, format) : value);
  };

  useEffect(() => {
    if (inputValue !== value) {
      handleValue((inputValue as string) ?? '');
    }
  }, [inputValue]);

  return (
    <ReactDateTime
      {...props}
      inputProps={{
        ...props.inputProps,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          handleValue(e.target.value);
          props.inputProps?.onChange?.(e);
        },
        value: value,
      }}
      value={inputValue}
      onChange={handleChange}
    />
  );
}

export function DateTimeClearInput(props: DatetimepickerProps) {
  return (
    <Datetime
      {...props}
      renderInput={(props) => (
        <DateTimeClearInputWrapper>
          <Input {...props} />
          <i className='fa fa-close btn_clear' onClick={() => props.onChange('')} />
        </DateTimeClearInputWrapper>
      )}
    />
  );
}

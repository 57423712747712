import moment from 'moment-timezone';

import { ListResponse } from '@tada/tada-web-common';

import {
  CpfContributionTable,
  CpfContributionTableBody,
  CpfContributionTableDto,
  CpfContributionTableListQueryInterface,
} from '@/pages/CpfContributionTable/type';
import { createBlobJson } from '@/utils/etc';
import { formatSWRData, useSWRData, useSWRList } from '@/utils/request/hooks';

export const useCpfContributionTableList = (query: CpfContributionTableListQueryInterface) => {
  const { data, error } = useSWRList<ListResponse<CpfContributionTable>>('/admin/cpf/tables', { query });

  return formatSWRData(data, error);
};

export const useCpfContributionTableDetail = (id: string) => {
  const { data } = useSWRData<CpfContributionTable>(`/admin/cpf/tables/${id}`);

  return data?.data;
};

export const sanitizeCorporateCpfContributionTable = (data: CpfContributionTableBody, file: File): CpfContributionTableDto => ({
  dto: {
    name: data.name,
    forOptIn: data.forOptIn,
    region: data.region,
    validFromEpochMilli: moment.tz(data.validFrom, 'DD-MM-YYYY HH:mm', 'Asia/Singapore').unix() * 1000,
    validUntilEpochMilli: data.validUntil ? moment.tz(data.validUntil, 'DD-MM-YYYY HH:mm', 'Asia/Singapore').unix() * 1000 : null,
  },
  file: file,
});

export const transformToFormData = (data: CpfContributionTableDto) => {
  const formData = new FormData();
  formData.append('dto', createBlobJson(JSON.stringify(data.dto)));
  formData.append('file', data.file);

  return formData;
};

import { useSelector } from 'react-redux';

import { Pages } from '@/assets/constants';
import { Region } from '@/pages/Regions/type';
import { AppState } from '@/store/reducers';
import { getLastSelectedRegion } from '@/utils/localStorage';

export function useListSelector<T = any[]>(storeName: keyof AppState): T {
  const byId = useAppState((state) => state[storeName].byId);
  const ids = useAppState((state) => state[storeName].ids);

  return ids.map((id: number) => byId[id]);
}

export function useIdSelector<T = any>(storeName: keyof AppState, id: string): T {
  return useAppState((state) => state[storeName].byId)[id];
}

export function useConfigSelector<T = any>(storeName: keyof AppState): T {
  return useAppState((state) => state[storeName]);
}

export function usePermissionSelector(pages: Pages) {
  return useAppState((state: AppState) => state.auth.permissions[pages]);
}

export function getLastSelectedRegionSelector(state: AppState) {
  const lastSelectedRegion = getLastSelectedRegion();

  return state.regions.regionList.find((region) => region.country === lastSelectedRegion) as Region;
}

export function useLastSelectedRegionSelector() {
  return useAppState(getLastSelectedRegionSelector);
}

type StateSelector<T> = (state: AppState) => T;
type EqualityFn<T> = (left: T, right: T) => boolean;

export function useAppState<T>(selector: StateSelector<T>, equalityFn?: EqualityFn<T>) {
  return useSelector(selector, equalityFn);
}

export function getAvailableRegionSelector(state: AppState) {
  const { availableRegion } = state.auth;
  const regions = state.regions.regionList;

  return availableRegion.map((country) => regions.find((region) => region.country === country)).filter(Boolean) as Region[];
}

export function getFirstAvailableRegionSelector(state: AppState) {
  const availableRegion = getAvailableRegionSelector(state);

  return availableRegion[0];
}

export function useFirstAvailableRegionSelector() {
  return useAppState(getFirstAvailableRegionSelector);
}

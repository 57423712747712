import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';

import { Button } from '@/components/template';
import SearchPageInput from '@/pages/Full/SearchPageInput';
import { useAppState } from '@/store/selector';
import { closePopup } from '@/svc/common/reducer';
import { selectFindModalOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';

function PageFindPopup() {
  const dispatch = useDispatch();

  const open = useAppState(selectFindModalOpenFlag);

  return (
    <Popup
      contentStyle={{ padding: '50px', minWidth: '60%', maxWidth: '80%', maxHeight: '80%', overflow: 'auto', minHeight: '500px' }}
      open={open}
      modal
    >
      {() => (
        <div>
          <Button
            style={{ display: 'contents' }}
            onClick={() => {
              dispatch(closePopup(POPUP_TYPE.FindModal));
            }}
          >
            <i style={{ position: 'absolute', fontSize: '30px', padding: '15px', top: '0', right: '0' }} className={'fa fa-times'} />
          </Button>
          <h4 style={{ marginBottom: '30px' }}>Search Page</h4>
          <SearchPageInput
            closeModal={() => {
              dispatch(closePopup(POPUP_TYPE.FindModal));
            }}
          />
        </div>
      )}
    </Popup>
  );
}

export default PageFindPopup;

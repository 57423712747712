import { CSSProperties } from 'react';

import styled from 'styled-components';

import { Input, InputProps } from '@/components/template';

interface CheckboxProps extends InputProps {
  /**
   * 커스텀 스타일
   */
  customStyle?: CSSProperties;
  wrapperStyle?: CSSProperties | string;
}

const CheckboxWrapper = styled.label<{ wrapperStyle?: CSSProperties | string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: solid 1px #c2cfd6;
  background-color: #ffffff;
  border-radius: 2px;
  cursor: pointer;

  &.disabled {
    background-color: #d1d1d1;
    border: 0;
  }

  > * {
    cursor: pointer;
  }

  ${({ wrapperStyle }: any) => wrapperStyle}
`;

const StyledInput = styled(Input).attrs((props: CheckboxProps) => ({
  ...props,
  type: 'checkbox',
  bsSize: 'lg',
  checked: props.value ?? props.checked,
}))`
  margin: 0;
  opacity: 0;

  &:checked + i {
    opacity: 1;
  }

  &:disabled + i {
    color: #999;
  }

  ${({ customStyle }: any) => customStyle}
`;

const CheckIcon = styled.i.attrs({
  className: 'fa fa-check',
})`
  font-size: 16px;
  color: #21a8d8;
  text-align: center;
  opacity: 0;
`;

/**
 * 체크박스 형태의 Input 컴포넌트이다.
 */
function Checkbox({ wrapperStyle, ...inputProps }: CheckboxProps) {
  return (
    <CheckboxWrapper className={inputProps.disabled ? 'disabled' : ''} wrapperStyle={wrapperStyle}>
      <StyledInput {...inputProps} />
      <CheckIcon />
    </CheckboxWrapper>
  );
}

export default Checkbox;

import { ChangeEvent } from 'react';

import styled from 'styled-components';

import { InputGroup, Input } from '@/components/template';
import { useAppState } from '@/store/selector';

const SearchInputGroup = styled(InputGroup)<{ $isHiddenSidebar: boolean }>`
  width: ${({ $isHiddenSidebar }) => ($isHiddenSidebar ? '32px' : '90%')};
  height: 32px;
  margin: ${({ $isHiddenSidebar }) => ($isHiddenSidebar ? '11px 11px 11px auto' : '11px auto')};
  border: 1px solid #26353b;
  background-color: white;
  border-radius: 4px;

  > i {
    margin: auto;
    padding: 5px;
    color: black;
    font-size: 1rem;
  }

  > input {
    border: none;
    border-radius: 4px;
  }
`;

interface SidebarSearchProps {
  pageName: string;
  findMenu: (e: ChangeEvent<HTMLInputElement>) => void;
}

function SidebarSearch({ pageName, findMenu }: SidebarSearchProps) {
  const isHiddenSidebar = useAppState((state) => state.common.isHiddenSidebar);

  return (
    <SearchInputGroup $isHiddenSidebar={isHiddenSidebar}>
      <i className='fa fa-search' />
      {!isHiddenSidebar && <Input value={pageName} onChange={findMenu} placeholder='Find Menu..' />}
    </SearchInputGroup>
  );
}

export default SidebarSearch;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CityList" */ '@/pages/Cities/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CityDetail" */ '@/pages/Cities/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "CityEdit" */ '@/pages/Cities/Edit'));
const New = lazy(() => import(/* webpackChunkName: "CityNew" */ '@/pages/Cities/New'));
const NewWithZones = lazy(() => import(/* webpackChunkName: "CityNewWithZones" */ '@/pages/Cities/NewWithZones'));

export const cityRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Cities,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Cities}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Cities}/new-with-zone`,
    exact: true,
    component: NewWithZones,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Cities}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Cities}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

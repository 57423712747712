import { createGlobalStyle } from 'styled-components';

import { getLogo } from '@/utils/etc';

const GlobalStyle = createGlobalStyle`
  .app-header.navbar .navbar-brand.navbar-brand {
    background-image: url('${getLogo()}');
  }

  .__json-pretty__ {
    white-space: pre-wrap;
  }
`;

export default GlobalStyle;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  bulkApproveSubmissionRequest,
  bulkApproveSubmissionSuccess,
  bulkApproveSubmissionFailure,
  bulkRejectSubmissionRequest,
  bulkRejectSubmissionSuccess,
  bulkRejectSubmissionFailure,
  updatePoiSubmissionRequest,
  updatePoiSubmissionSuccess,
  updatePoiSubmissionFailure,
} from '@/svc/place-svc/userSubmission/reducer';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/places/change-requests';

function* bulkApproveSubmission({ payload }: ReturnType<typeof bulkApproveSubmissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/approve`, { data: payload });

    const action = response.ok ? bulkApproveSubmissionSuccess() : bulkApproveSubmissionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkApproveSubmissionFailure((e as Error).message));
  }
}

function* bulkRejectSubmission({ payload }: ReturnType<typeof bulkRejectSubmissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/reject`, { data: payload });

    const action = response.ok ? bulkRejectSubmissionSuccess() : bulkRejectSubmissionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkRejectSubmissionFailure((e as Error).message));
  }
}

function* updatePoiSubmission({ payload: { id, data } }: ReturnType<typeof updatePoiSubmissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data });

    const action = response.ok ? updatePoiSubmissionSuccess() : updatePoiSubmissionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updatePoiSubmissionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(bulkApproveSubmissionRequest.type, bulkApproveSubmission);
  yield takeLatest(bulkRejectSubmissionRequest.type, bulkRejectSubmission);
  yield takeLatest(updatePoiSubmissionRequest.type, updatePoiSubmission);
}

export const WING_TRANSACTION_COLUMNS = {
  ID: 'id',
  WING_TX_ID: 'wingTxId',
  STATUS: 'status',
  AMOUNT: 'amount',
  APPLICATION_FEE: 'applicationFee',
  TIMESTAMP: 'timestamps',
} as const;

export const WING_TRANSACTION_COLUMNS_LIST = Object.values(WING_TRANSACTION_COLUMNS);

export const WING_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: WING_TRANSACTION_COLUMNS.ID, label: 'Id' },
  { key: WING_TRANSACTION_COLUMNS.WING_TX_ID, label: 'Wing TX ID' },
  { key: WING_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: WING_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: WING_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Application Fee' },
  { key: WING_TRANSACTION_COLUMNS.TIMESTAMP, label: 'TimeStamps' },
];

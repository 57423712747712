import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlaceTesterState, Tester, TesterDto } from '@/svc/place-svc/placeTester/type';
import Service from '@/svc/serviceName';

export const placeTesterInitialState: PlaceTesterState = {
  error: null,
};

const placeTesterFailure = (state: PlaceTesterState, { payload }: PayloadAction<string>) => {
  state.error = payload;
};

export const addPlaceTesterSuccess = createAction<PayloadAction<Tester>>(`${Service.placeSvc}/placeTester/addPlaceTesterSuccess`);

export const deletePlaceTesterRequest = createAction<string>(`${Service.placeSvc}/placeTester/deletePlaceTesterRequest`);
export const deletePlaceTesterSuccess = createAction(`${Service.placeSvc}/placeTester/deletePlaceTesterSuccess`);

export const fetchPlaceTesterTemplateRequest = createAction(`${Service.placeSvc}/placeTester/fetchPlaceTesterTemplateRequest`);
export const fetchPlaceTesterTemplateSuccess = createAction(`${Service.placeSvc}/placeTester/fetchPlaceTesterTemplateSuccess`);

export const addPlaceTesterBulkSuccess = createAction(`${Service.placeSvc}/placeTester/addPlaceTesterBulkSuccess`);

const placeTesterSlice = createSlice({
  name: `${Service.placeSvc}/placeTester`,
  initialState: placeTesterInitialState,
  reducers: {
    addPlaceTesterRequest: {
      prepare: (dto: TesterDto) => ({ payload: dto }),
      reducer: (state) => {
        state.error = null;
      },
    },
    addPlaceTesterFailure: placeTesterFailure,
    deletePlaceTesterFailure: placeTesterFailure,
    fetchPlaceTesterTemplateFailure: placeTesterFailure,
    addPlaceTesterBulkRequest: {
      prepare: (file: FormData) => ({ payload: file }),
      reducer: (state) => {
        state.error = null;
      },
    },
    addPlaceTesterBulkFailure: placeTesterFailure,
  },
});

export const {
  addPlaceTesterRequest,
  addPlaceTesterFailure,
  deletePlaceTesterFailure,
  fetchPlaceTesterTemplateFailure,
  addPlaceTesterBulkRequest,
  addPlaceTesterBulkFailure,
} = placeTesterSlice.actions;

export default placeTesterSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "TaxPrefillDescriptionList" */ '@/pages/TaxPrefillDescription/List'));
const Detail = lazy(() => import(/* webpackChunkName: "TaxPrefillDescriptionDetail" */ '@/pages/TaxPrefillDescription/Detail'));
const New = lazy(() => import(/* webpackChunkName: "TaxPrefillDescriptionNew" */ '@/pages/TaxPrefillDescription/New'));
const Edit = lazy(() => import(/* webpackChunkName: "TaxPrefillDescriptionEdit" */ '@/pages/TaxPrefillDescription/Edit'));

export const taxPrefillDescriptionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.TaxPrefillDescription,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.TaxPrefillDescription}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.TaxPrefillDescription}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.TaxPrefillDescription}/:id`,
    component: Detail,
    exact: true,
  },
];

import { FC, PropsWithChildren } from 'react';

import { Action, Pages } from '@/assets/constants';
import { BaseNavItemType } from '@/layout/Sidebar/_nav';
import { Permissions } from '@/pages/LoginPage/types';
import { usePermissionSelector } from '@/store/selector';

// User Action Type
export enum ActionType {
  READ = 'READ',
  WRITE = 'WRITE',
  EXPORT = 'EXPORT',
  EDIT = 'EDIT',
  LIST = 'LIST',
  SUPER = 'SUPER',
}

export const ActionTypeToPermissionAction = {
  [ActionType.READ]: 'r',
  [ActionType.WRITE]: 'w',
  [ActionType.EXPORT]: 'x',
  [ActionType.LIST]: 'l',
  [ActionType.SUPER]: 's',
};

const checkPermissionWithAction = {
  [ActionType.READ]: (permission: Action[]) => permission.includes('r'),
  [ActionType.WRITE]: (permission: Action[]) => permission.includes('w'),
  [ActionType.EXPORT]: (permission: Action[]) => permission.includes('x'),
  [ActionType.EDIT]: (permission: Action[]) => permission.includes('r') && permission.includes('w'),
  [ActionType.LIST]: (permission: Action[]) => permission.includes('l'),
  [ActionType.SUPER]: (permission: Action[]) => permission.includes('s'),
};

export interface CheckPermissionProps {
  permission?: Array<Action | string>;
  action: ActionType;
  fallbackElement?: any;
  skip?: boolean;
}

export default function CheckPermission({ permission, action, children, fallbackElement, skip }: PropsWithChildren<CheckPermissionProps>) {
  if (skip) {
    return children;
  }

  if (permission && (permission.includes('a') || checkPermissionWithAction[action](permission as Action[]))) {
    return children;
  }

  return fallbackElement ? fallbackElement : null;
}

export function checkPermission(action: ActionType, permission?: Array<Action | string>) {
  if (permission === undefined) {
    return false;
  }

  if (permission.includes('a')) {
    return true;
  }

  switch (action) {
    case ActionType.READ:
      return permission.includes('r');
    case ActionType.WRITE:
      return permission.includes('w');
    case ActionType.EXPORT:
      return permission.includes('x');
    case ActionType.EDIT:
      return permission.includes('r') && permission.includes('w');
    case ActionType.LIST:
      return permission.includes('l');
    default:
      return false;
  }
}

export const checkRoutePermission = (item: BaseNavItemType, permission: Permissions) => {
  const type = item.permissionType || ActionType.READ;

  return checkPermission(type, permission[item.Pages as Pages]);
};

export function withPermission<T extends object>(page: Pages, action: ActionType) {
  return (Component: FC<T>) => {
    const Inner = (props: T) => {
      const permission = usePermissionSelector(page);

      return (
        <CheckPermission action={action} permission={permission}>
          <Component {...props} />
        </CheckPermission>
      );
    };

    return Inner;
  };
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import regex from '@/assets/regex';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DriverIncentiveList" */ '@/pages/DriverIncentive/List'));
const Detail = lazy(() => import(/* webpackChunkName: "DriverIncentiveDetail" */ '@/pages/DriverIncentive/Detail'));
const New = lazy(() => import(/* webpackChunkName: "DriverIncentiveNew" */ '@/pages/DriverIncentive/New'));
const Edit = lazy(() => import(/* webpackChunkName: "DriverIncentiveEdit" */ '@/pages/DriverIncentive/Edit'));
const ProgressList = lazy(
  () => import(/* webpackChunkName: "BulkSyncProgressList" */ '@/pages/DriverIncentive/BulkSyncProgress/BulkSyncProgressList')
);
const ProgressDetail = lazy(
  () => import(/* webpackChunkName: "BulkSyncProgressList" */ '@/pages/DriverIncentive/BulkSyncProgress/BulkSyncProgressDetail')
);
const BackgroundProgressDetail = lazy(
  () => import(/* webpackChunkName: "DriverMissionBackgroundProgressDetail" */ '@/pages/DriverIncentive/DriverBackgroundProgressDetail')
);

export const driverIncentiveRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DriverIncentive,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverIncentive}/:id(${regex.uuidRoute})`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DriverIncentive}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DriverIncentive}/:id(${regex.uuidRoute})/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverIncentive}/:id(${regex.uuidRoute})/progress`,
    component: ProgressList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverIncentive}/:id(${regex.uuidRoute})/progress/:progressId(${regex.uuidRoute})`,
    component: ProgressDetail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverIncentive}/:id(${regex.uuidRoute})/background-progress/:progressId(${regex.uuidRoute})`,
    component: BackgroundProgressDetail,
    exact: true,
  },
];

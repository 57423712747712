import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchRoleListRequest,
  fetchRoleListSuccess,
  fetchRoleListFailure,
  fetchRoleDetailRequest,
  fetchRoleDetailSuccess,
  fetchRoleDetailFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
  updateRoleFailure,
} from '@/pages/Roles/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchRoleList({ payload }: ReturnType<typeof fetchRoleListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/roles', { params: payload, schema: { content: [entity] } });

    if (response.ok) {
      yield put(fetchRoleListSuccess(response.data));
    } else {
      yield put(fetchRoleListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRoleListFailure((e as Error).message));
  }
}

function* fetchRoleDetail({ payload }: ReturnType<typeof fetchRoleDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/v1/roles/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchRoleDetailSuccess(response.data));
    } else {
      yield put(fetchRoleDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRoleDetailFailure((e as Error).message));
  }
}

function* createRole({ payload }: ReturnType<typeof createRoleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/roles', { data: payload });

    if (response.ok) {
      yield put(createRoleSuccess(response.data));
    } else {
      yield put(createRoleFailure(response.data.message));
    }
  } catch (e) {
    yield put(createRoleFailure((e as Error).message));
  }
}

function* updateRole({ payload }: ReturnType<typeof updateRoleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/roles/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateRoleSuccess(response.data));
    } else {
      yield put(updateRoleFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateRoleFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchRoleListRequest.type, fetchRoleList);
  yield takeLatest(fetchRoleDetailRequest.type, fetchRoleDetail);
  yield takeLatest(createRoleRequest.type, createRole);
  yield takeLatest(updateRoleRequest.type, updateRole);
}

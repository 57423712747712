import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchFraudCardDetailFailure,
  fetchFraudCardDetailRequest,
  fetchFraudCardDetailSuccess,
  fetchFraudCardListFailure,
  fetchFraudCardListRequest,
  fetchFraudCardListSuccess,
  updateFraudCardFailure,
  updateFraudCardRequest,
  updateFraudCardSuccess,
} from '@/pages/FraudCard/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity, schema } = getContentSchema();

function* fetchFraudCardList({ payload: { search } }: ReturnType<typeof fetchFraudCardListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/payment/fraud_detection/card', {
      params: search,
      schema,
    });

    if (response.ok) {
      yield put(fetchFraudCardListSuccess(response.data));
    } else {
      yield put(fetchFraudCardListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchFraudCardListFailure((e as Error).message));
  }
}

function* fetchFraudCardDetail({ payload: { id } }: ReturnType<typeof fetchFraudCardDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/payment/fraud_detection/card/${id}`, { schema: entity });

    if (response.ok) {
      yield put(fetchFraudCardDetailSuccess(response.data));
    } else {
      yield put(fetchFraudCardDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchFraudCardDetailFailure((e as Error).message));
  }
}

function* updateFraudCard({ payload: { id, data } }: ReturnType<typeof updateFraudCardRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/payment/fraud_detection/card/${id}`, { data });

    if (response.ok) {
      yield put(updateFraudCardSuccess(response.data));
    } else {
      yield put(updateFraudCardFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateFraudCardFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchFraudCardListRequest.type, fetchFraudCardList);
  yield takeLatest(fetchFraudCardDetailRequest.type, fetchFraudCardDetail);
  yield takeLatest(updateFraudCardRequest.type, updateFraudCard);
}

import { createSlice } from '@reduxjs/toolkit';

import { ProfilePictureChangeState } from '@/pages/ProfilePictureChange/types';

const initialState: ProfilePictureChangeState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  list: [],
  totalCount: 0,
};

const profilePictureChangeSlice = createSlice({
  name: 'profilePictureChange',
  initialState,
  reducers: {
    approveProfilePicturesRequest: {
      prepare: (payload: string[]) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    approveProfilePicturesSuccess(state) {
      state.isFetching = false;
    },
    approveProfilePicturesFailure: {
      prepare: (payload: any) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    rejectProfilePictureRequest: {
      prepare: (payload: string[]) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    rejectProfilePictureSuccess(state) {
      state.isFetching = false;
    },
    rejectProfilePictureFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
  },
});

export const {
  approveProfilePicturesRequest,
  approveProfilePicturesSuccess,
  approveProfilePicturesFailure,
  rejectProfilePictureRequest,
  rejectProfilePictureSuccess,
  rejectProfilePictureFailure,
} = profilePictureChangeSlice.actions;

export default profilePictureChangeSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  checkFinishedPipayTransactionFailure,
  checkFinishedPipayTransactionRequest,
  checkFinishedPipayTransactionSuccess,
} from '@/pages/PipayTransactions/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

const basePath = 'payment/pipay_transactions';

function* checkFinishedPipayTransaction({ payload }: ReturnType<typeof checkFinishedPipayTransactionRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${basePath}/${payload}/check_finished`, {
      schema: entity,
    });

    if (res.ok) {
      yield put(checkFinishedPipayTransactionSuccess(res.data));
    } else {
      yield put(checkFinishedPipayTransactionFailure(res.data.message));
    }
  } catch (e) {
    yield put(checkFinishedPipayTransactionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(checkFinishedPipayTransactionRequest.type, checkFinishedPipayTransaction);
}

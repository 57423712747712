import { Pagination as PaginationComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface PaginationProps extends React.HTMLAttributes<HTMLElement> {
  listClassName?: string;
  cssModule?: CSSModule;
  size?: string;
  tag?: React.ElementType;
  listTag?: React.ElementType;
  'aria-label'?: string;
}

export function Pagination(props: PaginationProps) {
  return <PaginationComponent {...props} />;
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAlipayTransactionDetailRequest,
  fetchAlipayTransactionDetailSuccess,
  fetchAlipayTransactionDetailFailure,
  cancelAlipayTransactionRequest,
  cancelAlipayTransactionSuccess,
  cancelAlipayTransactionFailure,
  refundAlipayTransactionRequest,
  refundAlipayTransactionSuccess,
  refundAlipayTransactionFailure,
  checkFinishedAlipayTransactionRequest,
  checkFinishedAlipayTransactionSuccess,
  checkFinishedAlipayTransactionFailure,
} from '@/pages/AlipayTransactions/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

function* fetchAlipayTransactionDetail({ payload }: ReturnType<typeof fetchAlipayTransactionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/payment/alipay_transactions/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchAlipayTransactionDetailSuccess(response.data));
    } else {
      yield put(fetchAlipayTransactionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAlipayTransactionDetailFailure((e as Error).message));
  }
}

function* cancelAlipayTransaction({ payload }: ReturnType<typeof cancelAlipayTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/alipay_transactions/${payload}/cancel`);

    if (response.ok) {
      yield put(cancelAlipayTransactionSuccess());
    } else {
      yield put(cancelAlipayTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(cancelAlipayTransactionFailure((e as Error).message));
  }
}

function* refundAlipayTransaction({ payload: { id, data } }: ReturnType<typeof refundAlipayTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/alipay_transactions/${id}/refund`, { data });

    if (response.ok) {
      yield put(refundAlipayTransactionSuccess());
    } else {
      yield put(refundAlipayTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(refundAlipayTransactionFailure((e as Error).message));
  }
}

function* checkFinishedAlipayTransaction({ payload }: ReturnType<typeof checkFinishedAlipayTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/alipay_transactions/${payload}/check_finished`, {
      schema: entity,
    });

    if (response.ok) {
      yield put(checkFinishedAlipayTransactionSuccess(response.data));
    } else {
      yield put(checkFinishedAlipayTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(checkFinishedAlipayTransactionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchAlipayTransactionDetailRequest.type, fetchAlipayTransactionDetail);
  yield takeLatest(cancelAlipayTransactionRequest.type, cancelAlipayTransaction);
  yield takeLatest(refundAlipayTransactionRequest.type, refundAlipayTransaction);
  yield takeLatest(checkFinishedAlipayTransactionRequest.type, checkFinishedAlipayTransaction);
}

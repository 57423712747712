import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { deleteLockFailure, deleteLockRequest, deleteLockSuccess } from '@/svc/delivery-svc/pendingRide/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deleteLock({ payload: id }: ReturnType<typeof deleteLockRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/pending_rides/locks/${id}`, { timeout: 5 * 60 * 1000 });

    if (response.ok) {
      yield put(deleteLockSuccess());
    } else {
      yield put(deleteLockFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteLockFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(deleteLockRequest.type, deleteLock);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchMetricsFailure,
  fetchMetricsRequest,
  fetchMetricsSuccess,
  fetchRideCountByStatusFailure,
  fetchRideCountByStatusRequest,
  fetchRideCountByStatusSuccess,
  fetchRideCountByRideTypeFailure,
  fetchRideCountByRideTypeRequest,
  fetchRideCountByRideTypeSuccess,
  fetchDriverTypeCountFailure,
  fetchDriverTypeCountRequest,
  fetchDriverTypeCountSuccess,
  fetchDriverPingCountFailure,
  fetchDriverPingCountRequest,
  fetchDriverPingCountSuccess,
  fetchSystemFeeStatsFailure,
  fetchSystemFeeStatsRequest,
  fetchSystemFeeStatsSuccess,
} from '@/pages/Dashboard/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchMetrics({ payload }: ReturnType<typeof fetchMetricsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/metrics', { params: payload });

    const action = response.ok ? fetchMetricsSuccess(response.data) : fetchMetricsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchMetricsFailure((e as Error).message));
  }
}

function* fetchRideCountByStatus({ payload }: ReturnType<typeof fetchRideCountByStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides/countByStatus', { params: payload });

    const action = response.ok ? fetchRideCountByStatusSuccess(response.data) : fetchRideCountByStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchRideCountByStatusFailure((e as Error).message));
  }
}

function* fetchRideCountByRideType({ payload }: ReturnType<typeof fetchRideCountByRideTypeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides/countByType', { params: payload });

    const action = response.ok ? fetchRideCountByRideTypeSuccess(response.data) : fetchRideCountByRideTypeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchRideCountByRideTypeFailure((e as Error).message));
  }
}

function* fetchDriverTypeCount({ payload }: ReturnType<typeof fetchDriverTypeCountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/member/drivers/driver-count-by-type', { params: payload });

    const action = response.ok ? fetchDriverTypeCountSuccess(response.data) : fetchDriverTypeCountFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverTypeCountFailure((e as Error).message));
  }
}

function* fetchDriverPingCount({ payload }: ReturnType<typeof fetchDriverPingCountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/driverPingCount', { params: payload });

    const action = response.ok ? fetchDriverPingCountSuccess(response.data) : fetchDriverPingCountFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverPingCountFailure((e as Error).message));
  }
}

function* fetchSystemFeeStats({ payload }: ReturnType<typeof fetchSystemFeeStatsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides/systemFeeStats', { params: payload });

    const action = response.ok ? fetchSystemFeeStatsSuccess(response.data) : fetchSystemFeeStatsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchSystemFeeStatsFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchMetricsRequest.type, fetchMetrics);
  yield takeLatest(fetchRideCountByStatusRequest.type, fetchRideCountByStatus);
  yield takeLatest(fetchRideCountByRideTypeRequest.type, fetchRideCountByRideType);
  yield takeLatest(fetchDriverTypeCountRequest.type, fetchDriverTypeCount);
  yield takeLatest(fetchDriverPingCountRequest.type, fetchDriverPingCount);
  yield takeLatest(fetchSystemFeeStatsRequest.type, fetchSystemFeeStats);
}

export const ADYEN_TRANSACTION_COLUMNS = {
  ID: 'id',
  RIDER_UUID: 'riderUuid',
  AMOUNT: 'amount',
  APPLICATION_FEE: 'applicationFee',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  REASON: 'reason',
  TIMESTAMP: 'timestamps',
} as const;

export const ADYEN_TRANSACTION_COLUMNS_LIST = Object.values(ADYEN_TRANSACTION_COLUMNS);

export const ADYEN_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: ADYEN_TRANSACTION_COLUMNS.ID, label: 'Id' },
  { key: ADYEN_TRANSACTION_COLUMNS.RIDER_UUID, label: 'Rider' },
  { key: ADYEN_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: ADYEN_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Transaction Fee' },
  { key: ADYEN_TRANSACTION_COLUMNS.TOTAL_AMOUNT, label: 'Total Amount' },
  { key: ADYEN_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: ADYEN_TRANSACTION_COLUMNS.REASON, label: 'Reason' },
  { key: ADYEN_TRANSACTION_COLUMNS.TIMESTAMP, label: 'TimeStamps' },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { PAY_STATUS, RIDE_STATUS_ENUM } from '@/assets/constants';
import {
  fetchDeliveryRequestListByIdsRequest,
  fetchDeliveryRequestListByIdsSuccess,
  fetchDeliveryRequestListByIdsFailure,
  fetchDeliveryRequestByIdRequest,
  fetchDeliveryRequestByIdSuccess,
  fetchDeliveryRequestByIdFailure,
  fetchDeliveryRequestMappingRequest,
  fetchDeliveryRequestMappingSuccess,
  fetchDeliveryRequestMappingFailure,
  linkDeliveryReqWithDeliveryRequest,
  linkDeliveryReqWithDeliverySuccess,
  linkDeliveryReqWithDeliveryFailure,
  forceAddDeliveryToRideRequest,
  forceAddDeliveryToRideSuccess,
  forceAddDeliveryToRideFailure,
  createAndBookDeliveryRequest,
  createAndBookDeliverySuccess,
  createAndBookDeliveryFailure,
  sendDeliveryRideReceiptRequest,
  sendDeliveryRideReceiptSuccess,
  sendDeliveryRideReceiptFailure,
  deliveryBulkUpdateRequest,
  deliveryBulkUpdateSuccess,
  deliveryBulkUpdateFailure,
} from '@/pages/DeliveryRide/reducer';
import { updateRideRequest } from '@/pages/Ride/reducer';
import { authenticatedRequest } from '@/utils/request';

function* getDeliveryRequests({ payload }: ReturnType<typeof fetchDeliveryRequestListByIdsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/deliveryRequests:id_list', { params: { id: payload.id } });

    if (response.ok) {
      yield put(fetchDeliveryRequestListByIdsSuccess(response.data));
    } else {
      yield put(fetchDeliveryRequestListByIdsFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRequestListByIdsFailure((e as Error).message));
  }
}

function* getDeliveryRequest({ payload }: ReturnType<typeof fetchDeliveryRequestByIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/deliveryRequests/${payload.id}`);

    if (response.ok) {
      yield put(fetchDeliveryRequestByIdSuccess(response.data));
    } else {
      yield put(fetchDeliveryRequestByIdFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRequestByIdFailure((e as Error).message));
  }
}

function* fetchDeliveryRequestMapping({ payload }: ReturnType<typeof fetchDeliveryRequestMappingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/deliveryRequestMapping:delivery_ids', {
      params: { id: payload },
    });

    if (response.ok) {
      yield put(fetchDeliveryRequestMappingSuccess(response.data));
    } else {
      yield put(fetchDeliveryRequestMappingFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRequestMappingFailure((e as Error).message));
  }
}

function* linkDeliveryReqWithDelivery({ payload }: ReturnType<typeof linkDeliveryReqWithDeliveryRequest>): IterableIterator {
  try {
    const { deliveryRequestId, deliveryId } = payload;

    const response = yield authenticatedRequest.post(`/delivery/deliveryRequests/${deliveryRequestId}/link`, {
      data: { deliveryId },
    });

    if (response.ok) {
      yield put(linkDeliveryReqWithDeliverySuccess(response.data));
    } else {
      yield put(linkDeliveryReqWithDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(linkDeliveryReqWithDeliveryFailure((e as Error).message));
  }
}

function* forceAddDeliveryToRide({ payload: { id, deliveryId } }: ReturnType<typeof forceAddDeliveryToRideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${id}/force_add/${deliveryId}`);

    if (response.ok) {
      yield put(forceAddDeliveryToRideSuccess());
    } else {
      yield put(forceAddDeliveryToRideFailure(response.data.message));
    }
  } catch (e) {
    yield put(forceAddDeliveryToRideFailure((e as Error).message));
  }
}

function* createAndBookDelivery({ payload }: ReturnType<typeof createAndBookDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/ride/createAndBook', { data: payload });

    if (response.ok) {
      yield put(createAndBookDeliverySuccess(response.data));
    } else {
      yield put(createAndBookDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(createAndBookDeliveryFailure((e as Error).message));
  }
}

function* sendReceipt({ payload }: ReturnType<typeof sendDeliveryRideReceiptRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/ride/${payload.id}/creator_receipt`, {
      data: payload.email ? { email: payload.email } : null,
    });
    if (response.ok) {
      yield put(sendDeliveryRideReceiptSuccess());
    } else {
      yield put(sendDeliveryRideReceiptFailure(response.data.message));
    }
  } catch (e) {
    yield put(sendDeliveryRideReceiptFailure((e as Error).message));
  }
}

function* deliveryBulkUpdate({ payload }: ReturnType<typeof deliveryBulkUpdateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/finish', { data: payload });
    if (response.ok) {
      yield put(deliveryBulkUpdateSuccess());
      yield put(
        updateRideRequest(payload.rideId, {
          payStatus: PAY_STATUS.PAYING,
          rideStatus: RIDE_STATUS_ENUM.RENTAL_STATUS_FINISHED,
        })
      );
    } else {
      yield put(deliveryBulkUpdateFailure(response.data.message));
    }
  } catch (e) {
    yield put(deliveryBulkUpdateFailure((e as Error).message));
  }
}

export default function* deliveryRideSaga() {
  yield takeLatest(fetchDeliveryRequestListByIdsRequest.type, getDeliveryRequests);
  yield takeLatest(fetchDeliveryRequestByIdRequest.type, getDeliveryRequest);
  yield takeLatest(fetchDeliveryRequestMappingRequest.type, fetchDeliveryRequestMapping);
  yield takeLatest(linkDeliveryReqWithDeliveryRequest.type, linkDeliveryReqWithDelivery);
  yield takeLatest(forceAddDeliveryToRideRequest.type, forceAddDeliveryToRide);
  yield takeLatest(createAndBookDeliveryRequest.type, createAndBookDelivery);
  yield takeLatest(sendDeliveryRideReceiptRequest.type, sendReceipt);
  yield takeLatest(deliveryBulkUpdateRequest.type, deliveryBulkUpdate);
}

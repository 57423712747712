import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Polygon } from '@/pages/Polygons/type';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface PolygonState extends StandardStoreState, PaginationStoreState<Polygon, number> {
  search: Pick<PaginationStoreState<Polygon>, 'ids' | 'byId'>;
  isUpdating: boolean;
  listByIds: Polygon[];
}

const initialState: PolygonState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  error: null,
  search: { ids: [], byId: {} },
  isUpdating: false,
  listByIds: [],
};

const failure = (state: PolygonState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

const PREFIX = 'polygon';

export const searchPolygonRequest = createAction(`${PREFIX}/searchPolygonRequest`);
export const searchPolygonFailure = createAction<string>(`${PREFIX}/searchPolygonFailure`);
export const fetchPolygonListByIdsFailure = createAction<string>(`${PREFIX}/fetchPolygonListByIdsFailure`);

const polygonSlice = createSlice({
  name: 'polygon',
  initialState,
  reducers: {
    createPolygonRequest: {
      prepare: (body: Polygon) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    createPolygonSuccess: {
      prepare: (data: Polygon) => ({ payload: data }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    createPolygonFailure: failure,

    searchPolygonSuccess(state, { payload }) {
      state.search.ids = payload.result.content;
      state.search.byId = { ...state.search.byId, ...payload.entities.content };
    },
    updatePolygonRequest: {
      prepare: (id: number, body: Omit<Polygon, 'id'> & { id?: number }) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.isUpdating = true;
      },
    },
    updatePolygonSuccess: {
      prepare: (data: Polygon) => ({ payload: data }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updatePolygonFailure: {
      prepare: (err: string) => ({ payload: err }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    fetchPolygonListByIdsRequest: {
      prepare: (ids) => ({ payload: ids }),
      reducer: (state) => {
        state.listByIds = [];
      },
    },
    fetchPolygonListByIdsSuccess(state, { payload }) {
      state.listByIds = payload;
    },
  },
});

export const {
  createPolygonRequest,
  createPolygonSuccess,
  createPolygonFailure,
  updatePolygonRequest,
  updatePolygonSuccess,
  updatePolygonFailure,
  searchPolygonSuccess,
  fetchPolygonListByIdsRequest,
  fetchPolygonListByIdsSuccess,
} = polygonSlice.actions;

export default polygonSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "MerchantList" */ '@/pages/Merchant/List'));
const Detail = lazy(() => import(/* webpackChunkName: "MerchantDetail" */ '@/pages/Merchant/Detail'));
const New = lazy(() => import(/* webpackChunkName: "MerchantNew" */ '@/pages/Merchant/New'));
const Edit = lazy(() => import(/* webpackChunkName: "MerchantEdit" */ '@/pages/Merchant/Edit'));

export const merchantRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Merchant,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Merchant}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Merchant}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Merchant}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

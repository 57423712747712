import { createSlice, createAction } from '@reduxjs/toolkit';

import { MomopayTransactionState } from '@/pages/MomopayTransactions/type';
import { detailSuccess } from '@/utils/reducerUtils';

const initialState: MomopayTransactionState = {
  isFetching: false,
  error: null,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
};

export const checkFinishedMomopayTransactionRequest = createAction<number>('momopayTransaction/checkFinishedMomopayTransactionRequest');
export const checkFinishedMomopayTransactionFailure = createAction<string>('momopayTransaction/checkFinishedMomopayTransactionFailure');

const momopayTransactionSlice = createSlice({
  name: 'momopayTransaction',
  initialState,
  reducers: {
    checkFinishedMomopayTransactionSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
  },
});

export const { checkFinishedMomopayTransactionSuccess } = momopayTransactionSlice.actions;

export default momopayTransactionSlice.reducer;

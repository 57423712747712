import { DocumentEditTitle, NavIconType, SidebarPathType } from '@/assets/constants/type';

export const PLACE_CHANGE_REQUEST = {
  REPORT: 0,
  ADD: 1,
} as const;

export const PLACE_CHANGE_REQUEST_STATUS = {
  PENDING: 0,
  APPROVED: 10,
  REJECTED: 20,
} as const;

export const PLACE_CATEGORY = {
  HDB: 0,
  CONDO: 1,
  OTHERS: 999,
} as const;

export const USER_SUBMISSION_BULK_TYPE = {
  APPROVE: 'approve',
  REJECT: 'reject',
} as const;

/* region */
export enum REGION {
  SG = 'SG',
  VN = 'VN',
  KH = 'KH',
  KR = 'KR',
  ET = 'ET',
  ID = 'ID',
  HK = 'HK',
  TH = 'TH',
  GLOBAL = 'GLOBAL',
}

export const REFERRAL_REWARD_TYPE = {
  COUPON: 'COUPON',
  BALANCE: 'BALANCE',
} as const;

export const PROMO_VALID_UNTIL_POLICY_INHERIT = 0;
export const PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE = 1;
export const PROMO_VALID_UNTIL_POLICY_FIXED = 2;

export const PROMO_TYPE_NORMAL = 0;
export const PROMO_TYPE_SIGN_UP = 1;
export const PROMO_TYPE_LOYALTY = 2;

export const NEWS_CONTENT_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  CALL_TO_ACTION_BUTTON: 'call_to_action_button',
} as const;

export const PASSWORD_CRITERIA = {
  NOT_SAME_CURRENT_PASSWORD: 'NOT_SAME_CURRENT_PASSWORD',
  MINIMUM_CHARACTER_LENGTH: 'MINIMUM_CHARACTER_LENGTH',
  CONTAIN_BOTH_LETTER_AND_NUMBER: 'CONTAIN_BOTH_LETTER_AND_NUMBER',
  CONTAIN_UPPER_CASE_LETTER: 'CONTAIN_UPPER_CASE_LETTER',
  CONTAIN_LOWER_CASE_LETTER: 'CONTAIN_LOWER_CASE_LETTER',
  CONTAIN_SYMBOL: 'CONTAIN_SYMBOL',
  DO_NOT_INCLUDE_SPACE: 'DO_NOT_INCLUDE_SPACE',
} as const;

export const CAR_TYPE = {
  CAR_TYPE_SEDAN: 0,
  CAR_TYPE_VAN: 1,
  CAR_TYPE_BUSINESS_VAN: 4,
  CAR_TYPE_BLACK_SEDAN: 13,
  CAR_TYPE_TUKTUK: 1000,
  CAR_TYPE_BIKE: 1001,
  CAR_TYPE_REMORQUE: 1002,
  CAR_TYPE_PREMIUM_BIKE: 1003,
  CAR_TYPE_WIN_BIKE: 1004,
  CAR_TYPE_BUS: 3000,
  CAR_TYPE_MINI_BUS: 3001,
  CAR_TYPE_TAXI_STANDARD: 10000,
  CAR_TYPE_TAXI_STANDARD_LARGE: 10001,
  CAR_TYPE_TAXI_PREMIUM: 10002,
  CAR_TYPE_TAXI_PREMIUM_LARGE: 10003,
  CAR_TYPE_DELIVERY_VAN: 20000,
  CAR_TYPE_DELIVERY_BIKE: 20001,
  CAR_TYPE_DELIVERY_CAR: 20002,
  CAR_TYPE_ONION_TUKTUK: 30000,
  // Deprecate Car Types
  CAR_TYPE_MIDDLE_SEDAN: 11,
  CAR_TYPE_BUSINESS_SEDAN: 12,
  CAR_TYPE_BUSINESS_BUS: 21,
  CAR_TYPE_SUV: 31,
  CAR_TYPE_COMPACT_SUV: 32,
  CAR_TYPE_FOOT: 2000,
} as const;

export const BANK_INFO_DOCUMENT_STATUS = {
  IDLE: 'IDLE',
  REGISTERED: 'REGISTERED',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export const MISSION_TYPE = {
  TOTAL_RIDES: 1,
  TOTAL_EARNING: 2,
  TOTAL_SYSTEM_FEE: 3,
  STAMP: 4,
} as const;

export const MISSION_TARGET_TYPE = {
  PUBLIC: 1,
  SIGN_UP: 3,
  PRIVATE: 2,
} as const;

export enum AVAILABLE_PAYMENT_METHOD_STRING {
  CASH = 'CASH',
  PAO = 'PAO',
  ALIPAY_P = 'ALIPAY_P',
  PIPAY_P = 'PIPAY_P',
  MOMOPAY_P = 'MOMO_P',
  CREDITCARD = 'CREDITCARD',
  TADAPAY = 'TADAPAY',
  CORPORATE = 'CORPORATE',
  CMCB = 'CMCB',
  DELIVERY = 'DELIVERY',
  ABA = 'ABA',
  WING = 'WING',
  NETSQR = 'NETSQR',
  OCBC_PAO = 'OCBC_PAO',
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  ABA_AOF = 'ABA_AOF',
  CORPORATE_CARD = 'CORPORATE_CARD',
}

export const CREDIT_CARD_BRAND = {
  VISA: 'visa',
  MASTER_CARD: 'mastercard',
  AMERICAN_EXPRESS: 'americanexpress',
  DINERS_CLUB: 'dinersclub',
  UNION_PAY: 'unionpay',
  JCB: 'JCB',
  NONE: 'none',
} as const;

export const SINGPASS_DRIVER_LICENSE_VALID_TYPE = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID',
  NOT_HOLDING_QDL: 'NOT_HOLDING_QDL',
} as const;

export const SINGPASS_DRIVER_LICENSE_CLASS_TYPE = {
  LICENSE_2B: '2B', // Motorcycles not exceeding 200cc; and Electric motorcycles not exceeding 15kW
  LICENSE_2A: '2A', // Motorcycles between 201cc and 400cc; and Electric motorcycles between 15.1kW and 25kW
  LICENSE_2: '2', // Motorcycles exceeding 400cc; and Electric motorcycles exceeding 25kW
  LICENSE_3CA: '3CA',
  LICENSE_3A: '3A', // Automatic Transmission Motor Vehicles
  LICENSE_3C: '3C',
  LICENSE_3: '3', // Manual and Automatic Transmissions Motor Vehicles
  LICENSE_4A: '4A', // Omnibus
  LICENSE_4: '4', // Heavy motor vehicles of unladen weight exceeding 2,500kg which are constructed to carry load or passengers
  LICENSE_5: '5',
} as const;

export const SINGPASS_LTA_LICENSE_STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
} as const;

export const DOCUMENT_TEMPLATE_KEY_TYPE = {
  PDVL_OR_TDVL: 'pdvl_or_tdvl',
  VEHICLE_OWNERSHIP: 'vehicle_ownership',
  TAXI_DETAIL: 'taxi_detail',
  VEHICLE_REGISTER: 'vehicle_register',
  TRANSPORT_BUSINESS_LICENSE: 'transport_business_license',
  VTI_DOCS: 'vti_docs',
  DRIVING_LICENSE: 'driving_license',
  VACCINATED_STATUS: 'vaccinated_status',
  JUDICIAL_RECORD: 'judicial_record',
  TDVL: 'tdvl',
  NRIC: 'nric',
  DECAL: 'decal',
  VL_EXPIRY_DATE: 'vl_expiry_date',
  EQUIPMENTS: 'equipments',
  EXTRAS: 'extras',
  CAR_SEAT: 'car_seat',
  INSURANCE: 'insurance',
  CAR_MODEL: 'car_model',
  VEHICLE_ELECTRONIC_MARKINGS: 'vehicle_electronic_markings',
  DRIVER_POLICE_CLEARANCE_CERTIFICATE: 'driver_police_clearance_certificate',
  PDVL: 'pdvl',
  HOURLY_RENTAL: 'hourly_rental',
  PROFILE_PICTURE: 'profile_picture',
  BANK_INFO: 'bank_info',
  TDIP: 'tdip',
} as const;

export const DOCUMENT_FIELD_TEMPLATE_KEY_TYPE = {
  VEHICLE_ELECTRONIC_MARKINGS_BACK: 'vehicleElectronicMarkingsBack',
  DRIVING_LICENSE_EXPIRY_DATE: 'drivingLicenseExpiryDate',
  VEHICLE_LICENSE_EXPIRY_DATE: 'vehicleLicenseExpiryDate',
  DRIVING_LICENSE_NUMBER: 'drivingLicenseNumber',
  VEHICLE_REGISTER: 'vehicleRegister',
  TAXI_FRONT: 'taxiFront',
  TAXI_TYPE: 'taxiType',
  TDVL_FRONT: 'tdvlFront',
  VACCINATED_PROOF_BACK: 'vaccinatedProofBack',
  VL_ID: 'vlId',
  ENGINE_MAKE: 'engineMake',
  RENTAL_START_AT: 'rentalStartAt',
  PDVL_FRONT: 'pdvlFront',
  VL_STATUS: 'vlStatus',
  CAR_PLATE_PROVINCE: 'carPlateProvince',
  CAR_OWNER_NAME: 'carOwnerName',
  ISSUANCE_DATE: 'issuanceDate',
  DRIVER_POLICE_CLEARANCE_CERTIFICATE: 'driverPoliceClearanceCertificate',
  VEHICLE_ELECTRONIC_MARKINGS_NUMBER: 'vehicleElectronicMarkingsNumber',
  NRIC_EXPIRY_DATE: 'nricExpiryDate',
  CAR_MAKER: 'carMaker',
  TBL_EXPIRY_DATE: 'tblExpiryDate',
  CAR_YEAR: 'carYear',
  SINGPASS_RETRIEVED_DATE: 'singpassRetrievedDate',
  ACRA_DOCS: 'acraDocs',
  VTI_DOCS: 'vtiDocs',
  PDVL_BACK: 'pdvlBack',
  VEHICLE_ORIGINAL_REGISTRATION_DATE: 'vehicleOriginalRegistrationDate',
  JUDICIAL_RECORD: 'judicialRecord',
  DECAL: 'decal',
  CAR_IVRD: 'carIvrd',
  NEXT_VEHICLE_INSPECTION_DATE: 'nextVehicleInspectionDate',
  DRIVING_LICENSE_FRONT: 'drivingLicenseFront',
  DRIVING_LICENSE_BACK: 'drivingLicenseBack',
  VEHICLE_LICENSE_FRONT: 'vehicleLicenseFront',
  VEHICLE_LICENSE_BACK: 'vehicleLicenseBack',
  DRIVING_LICENSE_CLASS: 'drivingLicenseClass',
  HORSE_POWER: 'horsePower',
  VEHICLE_IDENTIFICATION: 'vehicleIdentification',
  PDVL: 'pdvl',
  NUMBER_OF_SEATS: 'numberOfSeats',
  RENTAL_END_AT: 'rentalEndAt',
  VEHICLE_ELECTRONIC_MARKINGS_EXPIRY_DATE: 'vehicleElectronicMarkingsExpiryDate',
  AUTHORISATION_LETTER_DOCS: 'authorisationLetterDocs',
  VEHICLE_OWNERSHIP: 'vehicleOwnership',
  NRIC_BACK: 'nricBack',
  NATIVE_FIRST_NAME: 'nativeFirstName',
  BOOKING_CONFIRMATION_IMAGE: 'bookingConfirmationImage',
  TAXI_COMPANY: 'taxiCompany',
  CAR_PHOTO: 'carPhoto',
  CAR_IVRD_DOC: 'carIvrdDoc',
  DRIVING_LICENSE_TYPE: 'drivingLicenseType',
  VACCINATED_STATUS: 'vaccinatedStatus',
  TBL_IMAGE: 'tblImage',
  NRIC: 'nric',
  CAR_TYPE: 'carType',
  TBL_CERTIFICATE_NUMBER: 'tblCertificateNumber',
  CAR_COLOR: 'carColor',
  VEHICLE_ELECTRONIC_MARKINGS_FRONT: 'vehicleElectronicMarkingsFront',
  VL_EXPIRY_DATE: 'vlExpiryDate',
  EQUIPMENTS: 'equipments',
  ENGINE_TYPE: 'engineType',
  LAST_VACCINATED_DATE: 'lastVaccinatedDate',
  BOOKING_CONFIRMATION_NUMBER: 'bookingConfirmationNumber',
  CAR_HOLDER_NAME: 'carHolderName',
  TAXI_MDT: 'taxiMdt',
  INSURANCE_EXPIRE_DATE: 'insuranceExpireDate',
  RENTAL_AGREEMENT_DOCS: 'rentalAgreementDocs',
  RESIDENTIAL_ADDRESS: 'residentialAddress',
  VEHICLE_REGISTER_BACK: 'vehicleRegisterBack',
  NRIC_FRONT: 'nricFront',
  NATIVE_LAST_NAME: 'nativeLastName',
  CAR_PLATE_COLOR: 'carPlateColor',
  VACCINATED_PROOF: 'vaccinatedProof',
  TDVL_BACK: 'tdvlBack',
  CAR_PLATE: 'carPlate',
  DRIVING_LICENSE_VALIDITY: 'drivingLicenseValidity',
  ENGINE_CC: 'engineCc',
  INSURANCE_DOCS: 'insuranceDocs',
  RENTAL_COMPANY: 'rentalCompany',
  VEHICLE_OWNERSHIP_DOCS: 'vehicleOwnershipDocs',
  CAR_MODEL: 'carModel',
  TAXI_LICENSE_PLATE_NUMBER: 'taxiLicensePlateNumber',
  SINGPASS_SIGNUP: 'singpassSignup',
  NATIONALITY: 'nationality',
  PROFILE_PICTURE: 'profilePicture',
  TAXI_DRIVER_IDENTIFY_PLATE_FRONT: 'taxiDriverIdentityPlateFront',
  TAXI_DRIVER_IDENTIFY_PLATE_NUMBER: 'taxiDriverIdentityPlateNumber',
  TAXI_DRIVER_IDENTIFY_PLATE_ISSUANCE_DATE: 'taxiDriverIdentityPlateIssuanceDate',
  TAXI_DRIVER_IDENTIFY_PLATE_EXPIRE_DATE: 'taxiDriverIdentityPlateIssuanceExpireDate',
  HIRE_CAR_PERMIT_VALIDITY_DATE: 'hireCarPermitValidityDate',
  AUTHORISATION_LETTER: 'authorisationLetter',
  HIRE_CAR_PERMIT: 'hireCarPermit',
} as const;

export const NAV_ICON = {
  DASHBOARD: 'DASHBOARD',
  PLACE: 'PLACE',
  RIDE: 'RIDE',
  PAYMENT_TRANSACTION: 'PAYMENT_TRANSACTION',
  WITHDRAW_REQUEST: 'WITHDRAW_REQUEST',
  DRIVERS: 'DRIVERS',
  PROMOTION_COUPON: 'PROMOTION_COUPON',
  RIDER: 'RIDER',
  CARD: 'CARD',
  DELIVERY: 'DELIVERY',
  DELIVERY_OPTIONS: 'DELIVERY_OPTIONS',
  HOURLY_RENTAL_CAR: 'HOURLY_RENTAL_CAR',
  BALANCE: 'BALANCE',
  OTP_SKIP_RULE: 'OTP_SKIP_RULE',
  PENALTY: 'PENALTY',
  OPERATIONS: 'OPERATIONS',
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT',
  PRODUCTS: 'PRODUCTS',
  SURGES: 'SURGES',
  ADMIN_ROLE_SETTING: 'ADMIN_ROLE_SETTING',
  CORPORATE: 'CORPORATE',
  MAINTENANCE: 'MAINTENANCE',
  DEFAULT: 'DEFAULT',
} as const;

export const SIDEBAR_PATH = {
  DASHBOARD: '/dashboard',
  TADA_RIDES: '/TADA_RIDES',
  TADA_PLACE: '/TADA_PLACE',
  PAYMENT_TRANSACTIONS: '/PAYMENT_TRANSACTIONS',
  TADA_WITH_DRAW: '/TADA_WITH_DRAW',
  DRIVERS: '/DRIVERS',
  PROMOTION: '/PROMOTION',
  RIDER: '/RIDER',
  CARD: '/CARD',
  DELIVERIES: '/DELIVERIES',
  DELIVERY_RIDE_OPTIONS: '/DELIVERY_RIDE_OPTIONS',
  TADA_HOURLY_RENTAL_CAR: '/TADA_HOURLY_RENTAL_CAR',
  BALANCE: '/balance',
  TADA_OTP_SKIP_RULE: '/TADA_OTP_SKIP_RULE',
  PENALTY: '/PENALTY',
  OPERATIONS: '/OPERATIONS',
  TADA_CONTENT_MANAGEMENT: '/TADA_CONTENT_MANAGEMENT',
  PRODUCTS: '/PRODUCTS',
  GEO: '/GEO',
  SURGES: '/SURGES',
  ADMIN_ROLE_SETTING: '/ADMIN-ROLE-SETTING',
  CORPORATES: '/CORPORATES',
  MAINTENANCE: '/MAINTENANCE',
} as const;

export const NAV_ICON_PATH_MAP: Record<NavIconType, SidebarPathType> = {
  [NAV_ICON.DASHBOARD]: SIDEBAR_PATH.DASHBOARD,
  [NAV_ICON.PLACE]: SIDEBAR_PATH.TADA_PLACE,
  [NAV_ICON.RIDE]: SIDEBAR_PATH.TADA_RIDES,
  [NAV_ICON.PAYMENT_TRANSACTION]: SIDEBAR_PATH.PAYMENT_TRANSACTIONS,
  [NAV_ICON.WITHDRAW_REQUEST]: SIDEBAR_PATH.TADA_WITH_DRAW,
  [NAV_ICON.DRIVERS]: SIDEBAR_PATH.DRIVERS,
  [NAV_ICON.PROMOTION_COUPON]: SIDEBAR_PATH.PROMOTION,
  [NAV_ICON.RIDER]: SIDEBAR_PATH.RIDER,
  [NAV_ICON.CARD]: SIDEBAR_PATH.CARD,
  [NAV_ICON.DELIVERY]: SIDEBAR_PATH.DELIVERIES,
  [NAV_ICON.DELIVERY_OPTIONS]: SIDEBAR_PATH.DELIVERY_RIDE_OPTIONS,
  [NAV_ICON.HOURLY_RENTAL_CAR]: SIDEBAR_PATH.TADA_HOURLY_RENTAL_CAR,
  [NAV_ICON.BALANCE]: SIDEBAR_PATH.BALANCE,
  [NAV_ICON.OTP_SKIP_RULE]: SIDEBAR_PATH.TADA_OTP_SKIP_RULE,
  [NAV_ICON.PENALTY]: SIDEBAR_PATH.PENALTY,
  [NAV_ICON.OPERATIONS]: SIDEBAR_PATH.OPERATIONS,
  [NAV_ICON.CONTENT_MANAGEMENT]: SIDEBAR_PATH.TADA_CONTENT_MANAGEMENT,
  [NAV_ICON.PRODUCTS]: SIDEBAR_PATH.PRODUCTS,
  [NAV_ICON.SURGES]: SIDEBAR_PATH.SURGES,
  [NAV_ICON.ADMIN_ROLE_SETTING]: SIDEBAR_PATH.ADMIN_ROLE_SETTING,
  [NAV_ICON.CORPORATE]: SIDEBAR_PATH.CORPORATES,
  [NAV_ICON.MAINTENANCE]: SIDEBAR_PATH.MAINTENANCE,
  [NAV_ICON.DEFAULT]: SIDEBAR_PATH.GEO,
};

export const MISSION_BACKGROUND_PROGRESS_TYPE = {
  ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
  REPLACES_PARTICIPANTS: 'REPLACES_PARTICIPANTS',
  EXCLUDE_PARTICIPANTS: 'EXCLUDE_PARTICIPANTS',
} as const;

export const MISSION_BACKGROUND_PROGRESS_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
} as const;

export const REMARK_LOG_TYPE = {
  CHANNEL: 'Channel',
  INTERACTION: 'Interaction',
} as const;

export const BACKGROUND_PROGRESS_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
} as const;

export const EDIT_DOCUMENT_TITLE = {
  CAR_MODEL: 'carModel',
  DOCUMENT_INFO: 'documentInfo',
  INSURANCE: 'insurance',
  NRIC: 'nric',
  TAXI: 'taxi',
  LICENSE: 'license',
  MOTOR_INSURANCE: 'motorInsurance',
  ELECTRONIC_MARKING: 'electronicMarking',
  POLICE_CLEARANCE_CERTIFICATE: 'policeClearanceCertificate',
  TAXI_DRIVER_IDENTIFY_PLATE: 'tdip',
} as const;

export const EDIT_DOCUMENT_TITLE_BY_REGION: Record<REGION, DocumentEditTitle[]> = {
  [REGION.SG]: [EDIT_DOCUMENT_TITLE.INSURANCE, EDIT_DOCUMENT_TITLE.CAR_MODEL, EDIT_DOCUMENT_TITLE.DOCUMENT_INFO, EDIT_DOCUMENT_TITLE.TAXI],
  [REGION.KH]: [EDIT_DOCUMENT_TITLE.CAR_MODEL, EDIT_DOCUMENT_TITLE.TAXI, EDIT_DOCUMENT_TITLE.NRIC, EDIT_DOCUMENT_TITLE.LICENSE],
  [REGION.VN]: [EDIT_DOCUMENT_TITLE.CAR_MODEL, EDIT_DOCUMENT_TITLE.TAXI],
  [REGION.TH]: [
    EDIT_DOCUMENT_TITLE.CAR_MODEL,
    EDIT_DOCUMENT_TITLE.MOTOR_INSURANCE,
    EDIT_DOCUMENT_TITLE.LICENSE,
    EDIT_DOCUMENT_TITLE.ELECTRONIC_MARKING,
    EDIT_DOCUMENT_TITLE.TAXI,
  ],
  [REGION.HK]: [
    EDIT_DOCUMENT_TITLE.INSURANCE,
    EDIT_DOCUMENT_TITLE.CAR_MODEL,
    EDIT_DOCUMENT_TITLE.LICENSE,
    EDIT_DOCUMENT_TITLE.TAXI,
    EDIT_DOCUMENT_TITLE.TAXI_DRIVER_IDENTIFY_PLATE,
  ],
  [REGION.ID]: [],
  [REGION.KR]: [],
  [REGION.ET]: [],
  [REGION.GLOBAL]: [],
};

export const INTERNAL_DOCUMENT_PROGRESS_STATUS = {
  PENDING: 0,
  IN_PROGRESS: 10,
  FINISHED: 100,
} as const;

export const BANNER_DESCRIPTION_COLOR = {
  BLACK: '#000000',
  WHITE: '#ffffff',
} as const;

export const APP_AUTHORITY = {
  RIDER_APP: 'RIDER_APP',
  DRIVER_APP: 'DRIVER_APP',
  WEB_HOMEPAGE: 'WEB_HOMEPAGE',
} as const;

// Currently this is only used in HK
export const TAXI_TYPE = {
  URBAN_TAXI: 'URBAN_TAXI',
  NEW_TERRITORIES_TAXI: 'NEW_TERRITORIES_TAXI',
  LANTAU_TAXI: 'LANTAU_TAXI',
} as const;

export enum CPF_OPT_IN_TYPE {
  MANDATORY = 1,
  OPTED_IN = 2,
  NOT_OPTED_IN = 3,
}

export const CPF_OPT_IN_TYPE_STRING = {
  [CPF_OPT_IN_TYPE.MANDATORY]: 'Mandatory',
  [CPF_OPT_IN_TYPE.OPTED_IN]: 'Opted-In',
  [CPF_OPT_IN_TYPE.NOT_OPTED_IN]: 'Not-Opted-In',
};

export const SG_TRANSACTION_CATEGORY_TYPE = {
  INCENTIVES: 1,
  NON_PAID_TRIP_COMPENSATION: 2,
  FUNDED_SUBSIDY: 3,
  PAY_RIDER_FEE: 4,
  BALANCE_TRANSFER: 5,
  TIPS: 6,
  ERP: 7,
  CANCELLATION: 8,
  ADDITIONAL_FARES: 9,
  DELIVERY_COD: 11,
  DELIVERY_PAYMENT: 12,
  WAITING_TIME_FEE_CPF: 13,
  ADDITIONAL_FARES_CPF: 14,
  NON_PAID_TRIP_COMPENSATION_CPF: 15,
  FARE_ADJUSTMENT_CPF: 16,
  TIPS_CPF: 17,
  INSENTIVES_CPF: 18,
  CANCELLATION_COMPENSATION: 19,
  FARE_ADJUSTMENT: 20,
  GANTRY_FEES: 21,
  CLEANING_FEE: 22,
  ADMIN_FEE: 23,
  REFERRAL_REWARD: 24,
  ONION_LOAN_REPAYMENT: 101,
  RIDE_CPF: 201,
  CPF_CONTRIBUTION: 800,
  REVERSAL: 801,
  OTHERS_CPF: 998,
  OTHERS: 999,
} as const;

export const ADDITIONAL_FARE_TYPE = {
  TOLL_FEE: 0,
  ADDITIONAL_STOP: 1,
  CAR_SEAT: 2,
  BOOKING_FEE: 3,
  AIRPORT_FEE: 4,
  WAITING_FEE: 5,
  BOOSTER_SEAT: 6,
  CHANGE_OF_DESTINATION: 7,
  HARBOUR_CROSSING_FEE: 8,
  GANTRY_FEE: 9,
  ETC: 999,
  NONE: 1000,
};

export const RESTRICT_TYPE_AND_ALLOWANCE_TYPE = {
  BOTH: 0,
  PICKUP_ONLY: 1,
  DROPOFF_ONLY: 2,
} as const;

export const DISCLAIMER_TYPE = {
  NO_WAITING: 'NO_WAITING',
} as const;

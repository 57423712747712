import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RemarkLogSettingList" */ '@/pages/RemarkLogSetting/List'));

export const remarkLogSettingRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.RemarkLogSetting,
    component: List,
    exact: true,
  },
];

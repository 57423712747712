import { Badge as BadgeComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface BadgeProps extends React.HTMLAttributes<HTMLElement> {
  color?: string;
  pill?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLElement>;
  cssModule?: CSSModule;
}

export function Badge(props: BadgeProps) {
  return <BadgeComponent {...props} />;
}

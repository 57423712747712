import { useState } from 'react';

import { Formik } from 'formik';

import { REGION, validationCpfContributionTableSchema } from '@/assets/constants';
import FormContent from '@/pages/CpfContributionTable/parts/FormContent';
import { CpfContributionTableBody } from '@/pages/CpfContributionTable/type';

interface Props {
  type: 'new' | 'edit';
  initialValues?: CpfContributionTableBody;
  initialFile?: File;
  onSubmit: (data: CpfContributionTableBody, file: File) => void;
}

const initialForm: CpfContributionTableBody = {
  name: '',
  region: REGION.SG,
  forOptIn: true,
  validFrom: '',
  validUntil: '',
};

function Form({ type, initialFile, initialValues, onSubmit }: Props) {
  const [file, setFile] = useState<File | null>(initialFile ?? null);

  return (
    <Formik
      initialValues={initialValues ?? initialForm}
      setFile={setFile}
      onSubmit={(data) => onSubmit(data, file as File)}
      validationSchema={validationCpfContributionTableSchema}
    >
      <FormContent type={type} file={file} setFile={setFile} />
    </Formik>
  );
}

export default Form;

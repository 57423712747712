import { createSlice } from '@reduxjs/toolkit';

import { default as prefixCouponReducer, initialState as prefixCouponState } from '@/svc/coupon-svc/prefixCoupon/reducer';
import { CouponServiceState } from '@/svc/coupon-svc/type';
import Service from '@/svc/serviceName';

const initialState: CouponServiceState = {
  prefixCoupon: prefixCouponState,
};

const couponSlice = createSlice({
  name: Service.couponSvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.prefixCoupon, prefixCouponReducer(state.prefixCoupon, action));
    }),
});

// export const {} = couponSlice.actions;

export default couponSlice.reducer;

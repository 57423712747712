import _ from 'lodash';
import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { ERROR } from '@/assets/constants';
import {
  fetchInfiniteAdminLogListRequest,
  fetchInfiniteAdminLogListSuccess,
  fetchInfiniteAdminLogListFailure,
  fetchAdminLogDetailRequest,
  fetchAdminLogDetailSuccess,
  fetchAdminLogDetailFailure,
} from '@/pages/AdminLog/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchInfiniteAdminLogList({ payload }: ReturnType<typeof fetchInfiniteAdminLogListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/actionLogs', { params: payload, schema: { content: [entity] } });

    if (response.ok && (payload.page === 0 || !_.isEmpty(response.data.entities))) {
      yield put(fetchInfiniteAdminLogListSuccess(response.data));
    } else {
      const errorMessage = _.isEmpty(response.data.entities) ? ERROR.LAST_SCROLL : response.data.message;
      yield put(fetchInfiniteAdminLogListFailure(errorMessage));
    }
  } catch (e) {
    yield put(fetchInfiniteAdminLogListFailure((e as Error).message));
  }
}

function* fetchAdminLogDetail({ payload }: ReturnType<typeof fetchAdminLogDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/v1/actionLogs/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchAdminLogDetailSuccess(response.data));
    } else {
      yield put(fetchAdminLogDetailFailure(response.data));
    }
  } catch (e) {
    yield put(fetchAdminLogDetailFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchInfiniteAdminLogListRequest.type, fetchInfiniteAdminLogList);
  yield takeLatest(fetchAdminLogDetailRequest.type, fetchAdminLogDetail);
}

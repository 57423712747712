import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchWithdrawalListRequest,
  fetchWithdrawalListSuccess,
  fetchWithdrawalListFailure,
  cancelWithdrawalRequest,
  cancelWithdrawalSuccess,
  cancelWithdrawalFailure,
} from '@/svc/member-svc/user/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchWithdrawalList({ payload }: ReturnType<typeof fetchWithdrawalListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/member/users/withdrawalRequestList', { params: payload });

    const action = response.ok ? fetchWithdrawalListSuccess(response.data) : fetchWithdrawalListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchWithdrawalListFailure((e as Error).message));
  }
}

function* cancelWithdrawal({ payload }: ReturnType<typeof cancelWithdrawalRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete('/member/users/cancelWithdrawalRequest', { params: { id: payload } });

    const action = response.ok ? cancelWithdrawalSuccess() : cancelWithdrawalFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(cancelWithdrawalFailure((e as Error).message));
  }
}

export default function* userSaga() {
  yield takeLatest(fetchWithdrawalListRequest.type, fetchWithdrawalList);
  yield takeLatest(cancelWithdrawalRequest.type, cancelWithdrawal);
}

import { createAction } from '@reduxjs/toolkit';

import { AdminActionTracerWithReasonDto } from '@/pages/DepositTransaction/type';
import Service from '@/svc/serviceName';

const prefix = `${Service.balanceSvc}/depositTransaction`;

export const approveDepositTransactionRequest = createAction<string>(`${prefix}/approveDepositTransactionRequest`);
export const approveDepositTransactionSuccess = createAction(`${prefix}/approveDepositTransactionSuccesss`);
export const approveDepositTransactionFailure = createAction<string>(`${prefix}/approveDepositTransactionFailures`);

export const rejectDepositTransactionRequest = createAction<{ id: string; data: AdminActionTracerWithReasonDto }>(
  `${prefix}/rejectDepositTransactionRequest`
);
export const rejectDepositTransactionSuccess = createAction(`${prefix}/rejectDepositTransactionSuccesss`);
export const rejectDepositTransactionFailure = createAction<string>(`${prefix}/rejectDepositTransactionFailures`);

export const failDepositTransactionRequest = createAction<{ id: string; data: AdminActionTracerWithReasonDto }>(
  `${prefix}/failDepositTransactionRequest`
);
export const failDepositTransactionSuccess = createAction(`${prefix}/failDepositTransactionSuccesss`);
export const failDepositTransactionFailure = createAction<string>(`${prefix}/failDepositTransactionFailures`);

export const restoreDepositTransactionRequest = createAction<{ id: string; data: AdminActionTracerWithReasonDto }>(
  `${prefix}/restoreDepositTransactionRequest`
);
export const restoreDepositTransactionSuccess = createAction(`${prefix}/restoreDepositTransactionSuccesss`);
export const restoreDepositTransactionFailure = createAction<string>(`${prefix}/restoreDepositTransactionFailures`);

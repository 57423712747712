import { ReactElement, useEffect, useState } from 'react';

import fileSize from 'filesize';
import { TFunction } from 'i18next';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import xlsx, { WorkBook } from 'xlsx';

import { LoadingFull } from '@/components/Loading';
import { renderFormErrorMessage } from '@/components/etc';
import { Alert, Button } from '@/components/template';

function ExcelFileUpload({
  fileData,
  setFileData,
  validateExcelFile,
  isSubmitting,
  error,
  result,
  resultMessage,
  handleUpload,
  disabled,
  customLoading,
  isShowUpload = true,
  setIsValidExcelFile,
  uploadContent,
  responseError,
  successMessage,
  formError,
  onErrorChanged,
  className,
  isCpf,
}: {
  fileData: File | null;
  setFileData: (file: File) => void;
  validateExcelFile?: (workBook: WorkBook, t: TFunction, isCpf: boolean) => { errors?: any; count?: any };
  isSubmitting?: boolean;
  error?: any;
  result?: { created: number; updated: number } | null;
  resultMessage?: string | null;
  handleUpload?: () => void;
  disabled?: boolean;
  customLoading?: (isSubmitting: boolean) => ReactElement;
  isShowUpload?: boolean;
  setIsValidExcelFile?: (valid: boolean) => void;
  uploadContent?: string;
  responseError?: string | null;
  successMessage?: string;
  formError?: string;
  onErrorChanged?: (error: boolean) => void;
  className?: string;
  isCpf?: boolean;
}) {
  const { t } = useTranslation();
  const [errorFlag, setErrorFlag] = useState(false);
  const [messages, setMessages] = useState([t('drag_drop_excel_file')]);
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 33554432,
    onDropAccepted: (file: File[]) => {
      setFileData(file[0]);
      readExcelFile(file[0]);
    },
  });
  const isSetResult = !((!result && !resultMessage) || (result?.created === 0 && result?.updated === 0));

  const readExcelFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    setMessages([`${t('processing')}...`]);

    reader.onload = () => {
      let data: any = reader.result;
      data = new Uint8Array(data);
      let workbook;
      try {
        workbook = xlsx.read(data, { type: 'array' });
      } catch (e) {
        return setMessages([t('error.file_parse')]);
      }

      if (validateExcelFile) {
        const { errors, count = 0 } = validateExcelFile(workbook, t, isCpf ?? false);

        setErrorFlag(!!errors);
        setIsValidExcelFile && setIsValidExcelFile(!errors);
        setMessages(errors ?? [successMessage ?? t('file_parsed_total_found', { count })]);
      } else {
        setMessages(['Read Excel Files Success']);
      }
    };
  };

  useEffect(() => {
    onErrorChanged?.(errorFlag);
  }, [errorFlag]);

  return (
    <div className={className || ''}>
      {customLoading ? (
        customLoading(isSubmitting ?? false)
      ) : (
        <LoadingFull isFetching={isSubmitting} isBlock={true} message={`${t('uploading')}...`} />
      )}
      <div
        {...getRootProps()}
        style={{
          width: '100%',
          height: 200,
          borderStyle: 'dashed',
          borderWidth: 2,
          padding: 10,
          color: '#666',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <input {...getInputProps()} />
        <span>{t('drag_drop_excel_file')}</span>
        <span>&mdash;&nbsp;{t('or')}&nbsp;&mdash;</span>
        <Button size='md' color='primary' type='button'>
          <span>{t('select_file')}</span>
        </Button>
      </div>
      {fileData && (
        <div>
          <span>
            {t('file_name')}: {fileData.name}
          </span>
          <br />
          <span>
            {t('file_size')}: {fileSize(fileData.size, { round: 0 })}
          </span>
        </div>
      )}
      {messages?.map((message: string, idx: number) => {
        return (
          <span key={idx} style={{ color: errorFlag ? 'red' : 'green' }}>
            {message}
            <br />
          </span>
        );
      })}
      {formError && (
        <>
          {renderFormErrorMessage(formError)}
          <br />
        </>
      )}
      <br />
      {isShowUpload ? (
        <Button disabled={disabled || errorFlag} onClick={handleUpload} color='primary'>
          <i className='fa fa-upload' />
          &nbsp;{uploadContent ?? t('upload')}
        </Button>
      ) : null}
      {(error || isSetResult) && (
        <Alert color={(error ?? null) === null ? 'success' : 'danger'}>
          {(error ?? null) === null
            ? result
              ? t('created_and_updated_records', { created: result?.created, updated: result?.updated })
              : resultMessage
            : [t('error.error_occurred'), <hr key={'err'} />, error]}
        </Alert>
      )}
      {responseError && (
        <Alert color='danger'>
          <p className='tw-whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: responseError }} />
        </Alert>
      )}
    </div>
  );
}

export default ExcelFileUpload;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createDynamicSurgeSettingFailure,
  createDynamicSurgeSettingRequest,
  createDynamicSurgeSettingSuccess,
  updateDynamicSurgeSettingFailure,
  updateDynamicSurgeSettingRequest,
  updateDynamicSurgeSettingSuccess,
} from '@/svc/ride-svc/dynamicSurgeSetting/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createDynamicSurgeSetting({ payload }: ReturnType<typeof createDynamicSurgeSettingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admin/dynamic_surges/settings', { data: payload });

    const action = response.ok ? createDynamicSurgeSettingSuccess(response.data) : createDynamicSurgeSettingFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDynamicSurgeSettingFailure((e as Error).message));
  }
}

function* updateDynamicSurgeSetting({ payload: { id, data } }: ReturnType<typeof updateDynamicSurgeSettingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/admin/dynamic_surges/settings/${id}`, { data });

    const action = response.ok ? updateDynamicSurgeSettingSuccess() : updateDynamicSurgeSettingFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDynamicSurgeSettingFailure((e as Error).message));
  }
}

export default function* dynamicSurgeSaga() {
  yield takeLatest(createDynamicSurgeSettingRequest.type, createDynamicSurgeSetting);
  yield takeLatest(updateDynamicSurgeSettingRequest.type, updateDynamicSurgeSetting);
}

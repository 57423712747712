import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DeliveryList" */ '@/pages/Delivery/List'));
const NewCsv = lazy(() => import(/* webpackChunkName: "DeliveryNewCsv" */ '@/pages/Delivery/csv'));
const New = lazy(() => import(/* webpackChunkName: "DeliveryNew" */ '@/pages/Delivery/New'));
const Detail = lazy(() => import(/* webpackChunkName: "DeliveryDetail" */ '@/pages/Delivery/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "DeliveryEdit" */ '@/pages/Delivery/Edit'));
const DeliveryDashboard = lazy(() => import(/* webpackChunkName: "DeliveryDeliveryDashboard" */ '@/pages/DeliveryDashboard'));

export const deliveryRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Delivery,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.READ,
    path: PATH.DeliveryDashboard,
    exact: true,
    component: DeliveryDashboard,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Delivery}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Delivery}/new/csv`,
    exact: true,
    component: NewCsv,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Delivery}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Delivery}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

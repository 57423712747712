import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import BulkSyncDriverIncentivePopup from '@/components/Popup/BulkSyncDriverIncentivePopup';
import ConfirmPopup from '@/components/Popup/ConfirmPopup';
import DeliveryQrSearchPopup, { checkDeliveryRelationPage } from '@/components/Popup/DeliveryQrSearchPopup';
import PageFindPopup from '@/components/Popup/PageFindPopup';
import RidePenaltyFeeReasonPopup from '@/components/Popup/RidePenaltyFeeReasonPopup';
import ScheduleSurgeModal from '@/components/Popup/ScheduleSurgeModal';
import ScheduleSyncConfirmPopup from '@/components/Popup/ScheduleSyncConfirmPopup';
import { closeAll, togglePopup } from '@/svc/common/reducer';
import { CommonState, PopupType, POPUP_TYPE } from '@/svc/common/type';

import RetryCashOutResultPopup from './RetryCashOutResultPopup';

const newKeyPressed: Record<string, boolean> = { Meta: false, KeyE: false, AltLeft: false, KeyQ: false };

const popupShortCut: Partial<{ [key in keyof CommonState['popup']]: string[] }> = {
  [POPUP_TYPE.FindModal]: ['KeyE', 'Meta'],
  [POPUP_TYPE.DeliverySearch]: ['KeyQ', 'AltLeft'],
};

const getKeyFromEvent = (e: KeyboardEvent) => (e.code?.startsWith('Meta') ? 'Meta' : e.code);

function PopupController() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.code === 'F7') {
        dispatch(togglePopup(POPUP_TYPE.FindModal));

        return;
      }

      if (event.key === 'Escape') {
        dispatch(closeAll());

        return;
      }

      const key = getKeyFromEvent(event);

      if (key in newKeyPressed) {
        newKeyPressed[key] = true;

        const openedPopup: string[] = [];

        Object.entries(popupShortCut).forEach(([key, codes]) => {
          if (codes.every((keyCode) => newKeyPressed[keyCode])) {
            if (key === POPUP_TYPE.DeliverySearch && !checkDeliveryRelationPage()) {
              return;
            }

            dispatch(togglePopup(key as PopupType));

            openedPopup.push(key);
          }
        });

        openedPopup.forEach((key) => {
          popupShortCut[key as PopupType]?.forEach((keyCode) => {
            newKeyPressed[keyCode] = false;
          });
        });
      }
    };

    const handleKeyup = (event: KeyboardEvent) => {
      const key = getKeyFromEvent(event);

      newKeyPressed[key] = false;
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        for (const key in newKeyPressed) {
          newKeyPressed[key] = false;
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <PageFindPopup />
      <DeliveryQrSearchPopup />
      <ConfirmPopup />
      <ScheduleSurgeModal />
      <ScheduleSyncConfirmPopup />
      <RidePenaltyFeeReasonPopup />
      <BulkSyncDriverIncentivePopup />
      <RetryCashOutResultPopup />
    </>
  );
}

export default PopupController;

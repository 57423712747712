import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import List from '@/pages/OtpWhitelist/List';
import New from '@/pages/OtpWhitelist/New';
import { RouteProps } from '@/utils/types';

export const otpWhitelistRouter: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.OtpWhitelist,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.OtpWhitelist}/new`,
    component: New,
    exact: true,
  },
];

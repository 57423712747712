import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createInstantSurgeFailure,
  createInstantSurgeRequest,
  createInstantSurgeSuccess,
  getSurgeForCitiesFailure,
  getSurgeForCitiesRequest,
  getSurgeForCitiesSuccess,
  upsertBulkInstantSurgeFailure,
  upsertBulkInstantSurgeRequest,
  upsertBulkInstantSurgeSuccess,
  upsertInstantSurgeFailure,
  upsertInstantSurgeRequest,
  upsertInstantSurgeSuccess,
} from '@/pages/InstantSurgeRule/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createInstantSurge({ payload }: any): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/instantSurges:upsert', { data: payload });

    if (response.ok) {
      yield put(createInstantSurgeSuccess(response.data));
    } else {
      yield put(createInstantSurgeFailure(response.data.message));
    }
  } catch (e) {
    yield put(createInstantSurgeFailure((e as Error).message));
  }
}

function* upsertInstantSurge({ payload }: any): IterableIterator {
  try {
    const response = yield authenticatedRequest.put('/admin/instantSurges:upsert', { data: payload });

    if (response.ok) {
      yield put(upsertInstantSurgeSuccess(response.data));
    } else {
      yield put(upsertInstantSurgeFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertInstantSurgeFailure((e as Error).message));
  }
}

function* upsertBulkInstantSurge({ payload }: any): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/instantSurges:upsertAll', { data: payload });

    if (response.ok) {
      yield put(upsertBulkInstantSurgeSuccess(response.data));
    } else {
      yield put(upsertBulkInstantSurgeFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertBulkInstantSurgeFailure((e as Error).message));
  }
}

function* getSurgeForCities({ payload }: any): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/instantSurges/for_cities', { params: payload });
    if (response.ok) {
      yield put(getSurgeForCitiesSuccess(response.data));
    } else {
      yield put(getSurgeForCitiesFailure(response.data.message));
    }
  } catch (e) {
    yield put(getSurgeForCitiesFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(getSurgeForCitiesRequest.type, getSurgeForCities);
  yield takeLatest(createInstantSurgeRequest.type, createInstantSurge);
  yield takeLatest(upsertInstantSurgeRequest.type, upsertInstantSurge);
  yield takeLatest(upsertBulkInstantSurgeRequest.type, upsertBulkInstantSurge);
}

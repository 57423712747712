import { useEffect } from 'react';

import { useLocation } from 'react-router';

import { useRequestFlag } from '@/hooks/flag';
import { usePrevious } from '@/hooks/toast';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';
import { changePageStatusRequest } from '@/svc/admin-proxy-svc/reducer';

export const useMenuManager = () => {
  const menuManagerState = useAppState((state) => state.menuManager);
  const authState = useAppState((state) => state.auth);
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname);
  const isRequest = useRequestFlag(changePageStatusRequest.type);

  useEffect(() => {
    const pageStatusValueString = JSON.stringify(menuManagerState.pageStatusValues);

    if (menuManagerState.pageStatusValues && pageStatusValueString !== authState.pageStatus && !isRequest) {
      // 페이지를 이동했을 때 OR 이동하지 않았지만 메뉴를 닫거나 여는 동작이 아닐 때(=> 메뉴 순서 변경 시)
      if (location.pathname !== prevLocation || !menuManagerState.isToggleMenuAction) {
        dispatch(changePageStatusRequest({ pageStatus: pageStatusValueString }));
      }
    }
  }, [location.pathname, menuManagerState.pageStatusValues]);
};

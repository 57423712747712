import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryRidePriceOptionFormInterface, DeliveryRidePriceOptionQuery } from '@/pages/DeliveryRidePriceOption/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface DeliveryRidePriceOptionState extends PaginationStoreState, StandardStoreState {
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
}

const initialState: DeliveryRidePriceOptionState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

const deliveryRidePriceOptionSlice = createSlice({
  name: 'deliveryRidePriceOption',
  initialState,
  reducers: {
    fetchDeliveryRidePriceOptionListRequest: {
      prepare: (search: DeliveryRidePriceOptionQuery) => ({ payload: search }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchDeliveryRidePriceOptionListSuccess: listSuccess,
    fetchDeliveryRidePriceOptionListFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isFetching = false;
    },

    fetchDeliveryRidePriceOptionDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchDeliveryRidePriceOptionDetailSuccess: detailSuccess,
    fetchDeliveryRidePriceOptionDetailFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isFetching = false;
    },

    createDeliveryRidePriceOptionRequest: {
      prepare: (data: DeliveryRidePriceOptionFormInterface) => ({ payload: data }),
      reducer: (state) => {
        state.error = null;
        state.isCreating = true;
      },
    },
    createDeliveryRidePriceOptionSuccess: (state) => {
      state.isCreating = false;
    },
    createDeliveryRidePriceOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isCreating = false;
    },

    updateDeliveryRidePriceOptionRequest: {
      prepare: (id: string, data: DeliveryRidePriceOptionFormInterface) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.error = null;
        state.isUpdating = true;
      },
    },
    updateDeliveryRidePriceOptionSuccess: (state) => {
      state.isUpdating = false;
    },
    updateDeliveryRidePriceOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isUpdating = false;
    },

    deleteDeliveryRidePriceOptionRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
        state.isDeleting = true;
      },
    },
    deleteDeliveryRidePriceOptionSuccess: (state) => {
      state.isDeleting = false;
    },
    deleteDeliveryRidePriceOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isDeleting = false;
    },
  },
});

export const {
  fetchDeliveryRidePriceOptionListRequest,
  fetchDeliveryRidePriceOptionListSuccess,
  fetchDeliveryRidePriceOptionListFailure,
  fetchDeliveryRidePriceOptionDetailRequest,
  fetchDeliveryRidePriceOptionDetailSuccess,
  fetchDeliveryRidePriceOptionDetailFailure,
  createDeliveryRidePriceOptionRequest,
  createDeliveryRidePriceOptionSuccess,
  createDeliveryRidePriceOptionFailure,
  updateDeliveryRidePriceOptionRequest,
  updateDeliveryRidePriceOptionSuccess,
  updateDeliveryRidePriceOptionFailure,
  deleteDeliveryRidePriceOptionRequest,
  deleteDeliveryRidePriceOptionSuccess,
  deleteDeliveryRidePriceOptionFailure,
} = deliveryRidePriceOptionSlice.actions;

export default deliveryRidePriceOptionSlice.reducer;

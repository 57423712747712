import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import accountSaga from '@/svc/balance-svc/account/saga';
import depositTransactionSaga from '@/svc/balance-svc/depositTransaction/saga';
import {
  upsertBankMaintenanceRequest,
  upsertBankMaintenanceSuccess,
  upsertBankMaintenanceFailure,
  reCalculateShbLoanRequest,
  reCalculateShbLoanSuccess,
  reCalculateShbLoanFailure,
  deleteBankMaintenanceRequest,
  deleteBankMaintenanceSuccess,
  deleteBankMaintenanceFailure,
} from '@/svc/balance-svc/reducer';
import transactionSaga from '@/svc/balance-svc/transaction/saga';
import { authenticatedRequest } from '@/utils/request';

import cpfContributionTableSaga from './cpfContributionTable/saga';

function* updateBankMaintenance({ payload }: ReturnType<typeof upsertBankMaintenanceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/balance/bankMaintenance/v2', { data: payload });

    const action = response.ok
      ? upsertBankMaintenanceSuccess(response.data)
      : upsertBankMaintenanceFailure(response.data?.maintenance ?? response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertBankMaintenanceFailure((e as Error).message));
  }
}

function* reCalculateShbLoan({ payload }: ReturnType<typeof reCalculateShbLoanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/balance/reCalculate', { data: payload });

    const action = response.ok ? reCalculateShbLoanSuccess() : reCalculateShbLoanFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(reCalculateShbLoanFailure((e as Error).message));
  }
}

function* deleteBankMaintenance({ payload }: ReturnType<typeof deleteBankMaintenanceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/v1/balance/bankMaintenance/${payload}`);

    const action = response.ok ? deleteBankMaintenanceSuccess() : deleteBankMaintenanceFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteBankMaintenanceFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(upsertBankMaintenanceRequest.type, updateBankMaintenance);
  yield takeLatest(reCalculateShbLoanRequest.type, reCalculateShbLoan);
  yield takeLatest(deleteBankMaintenanceRequest.type, deleteBankMaintenance);
  yield all([call(depositTransactionSaga), call(transactionSaga), call(accountSaga), call(cpfContributionTableSaga)]);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createPreventMatchFailure,
  createPreventMatchRequest,
  createPreventMatchSuccess,
  deletePreventMatchFailure,
  deletePreventMatchRequest,
  deletePreventMatchSuccess,
  updatePreventMatchFailure,
  updatePreventMatchRequest,
  updatePreventMatchSuccess,
} from '@/svc/penalty-svc/preventMatch/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createPreventMatch({ payload }: ReturnType<typeof createPreventMatchRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/dispatchsvc/admin/prevent_users_match/create', { data: payload });

    if (response.ok) {
      yield put(createPreventMatchSuccess(response.data));
    } else {
      yield put(createPreventMatchFailure(response.data.message));
    }
  } catch (e) {
    yield put(createPreventMatchFailure((e as Error).message));
  }
}

function* updatePreventMatch({ payload: { id, data } }: ReturnType<typeof updatePreventMatchRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/dispatchsvc/admin/prevent_users_match/update/${id}`, { data });

    if (response.ok) {
      yield put(updatePreventMatchSuccess());
    } else {
      yield put(updatePreventMatchFailure(response.data.errorMessage));
    }
  } catch (e) {
    yield put(updatePreventMatchFailure((e as Error).message));
  }
}

function* deletePreventMatch({ payload }: ReturnType<typeof deletePreventMatchRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/dispatchsvc/admin/prevent_users_match/${payload}`);

    if (response.ok) {
      yield put(deletePreventMatchSuccess());
    } else {
      yield put(deletePreventMatchFailure(response.data.errorMessage));
    }
  } catch (e) {
    yield put(deletePreventMatchFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createPreventMatchRequest.type, createPreventMatch);
  yield takeLatest(updatePreventMatchRequest.type, updatePreventMatch);
  yield takeLatest(deletePreventMatchRequest.type, deletePreventMatch);
}

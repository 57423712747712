import FileSaver from 'file-saver';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  downloadNewTemplateRequest,
  downloadNewTemplateFailure,
  downloadEditTemplateRequest,
  downloadEditTemplateFailure,
  createScheduleSurgePresetRequest,
  createScheduleSurgePresetSuccess,
  createScheduleSurgePresetFailure,
  updateScheduleSurgePresetRequest,
  updateScheduleSurgePresetSuccess,
  updateScheduleSurgePresetFailure,
  updatePriceManagementRequest,
  updatePriceManagementSuccess,
  updatePriceManagementFailure,
} from '@/svc/ride-svc/scheduleSurgePreset/reducer';
import { authenticatedRequest } from '@/utils/request';

function* downloadNewTemplate({ payload }: ReturnType<typeof downloadNewTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/scheduleSurges/presets:template`, {
      params: {
        region: payload,
      },
      responseType: 'blob',
    });

    response.ok ? FileSaver.saveAs(response.data, 'schedule_surge_preset.xlsx') : yield put(downloadNewTemplateFailure(response.data.message));
  } catch (e) {
    yield put(downloadNewTemplateFailure((e as Error).message));
  }
}

function* downloadEditTemplate({ payload }: ReturnType<typeof downloadEditTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/scheduleSurges/presets/${payload}/template`, {
      responseType: 'blob',
    });

    response.ok
      ? FileSaver.saveAs(response.data, `schedule_surge_preset_${payload}.xlsx`)
      : yield put(downloadEditTemplateFailure(response.data.message));
  } catch (e) {
    yield put(downloadEditTemplateFailure((e as Error).message));
  }
}

function* createScheduleSurgePreset({ payload }: ReturnType<typeof createScheduleSurgePresetRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/scheduleSurges/presets', { data: payload });

    const action = response.ok ? createScheduleSurgePresetSuccess() : createScheduleSurgePresetFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createScheduleSurgePresetFailure((e as Error).message));
  }
}

function* updateScheduleSurgePreset({ payload: { id, data } }: ReturnType<typeof updateScheduleSurgePresetRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/scheduleSurges/presets/${id}`, { data });

    const action = response.ok ? updateScheduleSurgePresetSuccess() : updateScheduleSurgePresetFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateScheduleSurgePresetFailure((e as Error).message));
  }
}

function* updatePriceManagement({ payload }: ReturnType<typeof updatePriceManagementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/scheduleSurges/presetMappings`, { data: payload });

    const action = response.ok ? updatePriceManagementSuccess() : updatePriceManagementFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updatePriceManagementFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(downloadNewTemplateRequest.type, downloadNewTemplate);
  yield takeLatest(downloadEditTemplateRequest.type, downloadEditTemplate);
  yield takeLatest(createScheduleSurgePresetRequest.type, createScheduleSurgePreset);
  yield takeLatest(updateScheduleSurgePresetRequest.type, updateScheduleSurgePreset);
  yield takeLatest(updatePriceManagementRequest.type, updatePriceManagement);
}

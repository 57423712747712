import { createAction } from '@reduxjs/toolkit';

import { CreateOtpSkipRuleDto, OtpSkipRuleState } from '@/svc/account-svc/otpSkipRule/type';
import Service from '@/svc/serviceName';

const prefix = `${Service.accountSvc}/otpSkipRule`;

export const createOtpSkipRuleRequest = createAction<CreateOtpSkipRuleDto>(`${prefix}/createOtpSkipRuleRequest`);
export const createOtpSkipRuleSuccess = createAction(`${prefix}/createOtpSkipRuleSuccess`);
export const createOtpSkipRuleFailure = createAction<string>(`${prefix}/createOtpSkipRuleFailure`);

export const deleteOtpSkipRuleRequest = createAction<string>(`${prefix}/deleteOtpSkipRuleRequest`);
export const deleteOtpSkipRuleSuccess = createAction(`${prefix}/deleteOtpSkipRuleSuccess`);
export const deleteOtpSkipRuleFailure = createAction<string>(`${prefix}/deleteOtpSkipRuleFailure`);

export const otpSkipRuleInitialState: OtpSkipRuleState = {};

import { Alert as AlertComponent } from 'reactstrap';

import { CSSModule, FadeProps } from '@/components/template/type';

interface UncontrolledAlertProps extends React.HTMLAttributes<HTMLElement> {
  closeClassName?: string;
  closeAriaLabel?: string;
  cssModule?: CSSModule;
  color?: string;
  fade?: boolean;
  tag?: React.ElementType;
  transition?: FadeProps;
  innerRef?: React.Ref<HTMLElement>;
}

export interface AlertProps extends UncontrolledAlertProps {
  isOpen?: boolean;
  toggle?: React.MouseEventHandler<any>;
}

export function Alert(props: AlertProps) {
  return <AlertComponent {...props} />;
}

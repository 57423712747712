import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "FaqListManagementList" */ '@/pages/FaqListManagement/List'));
const New = lazy(() => import(/* webpackChunkName: "FaqListManagementNew" */ '@/pages/FaqListManagement/New'));
const Detail = lazy(() => import(/* webpackChunkName: "FaqListManagementDetail" */ '@/pages/FaqListManagement/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "FaqListManagementEdit" */ '@/pages/FaqListManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "FaqListManagementOrder" */ '@/pages/FaqListManagement/Order'));

export const faqListManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.FaqListManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.FaqListManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.FaqListManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.FaqListManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.FaqListManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { createSlice, createAction } from '@reduxjs/toolkit';

import { PaoTransactionState } from '@/pages/PaoTransactions/type';
import { detailSuccess } from '@/utils/reducerUtils';

const initialState: PaoTransactionState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  error: null,
};

export const checkFinishedPaoTransactionRequest = createAction<number>('paoTransaction/checkFinishedPaoTransactionRequest');
export const checkFinishedPaoTransactionFailure = createAction<string>('paoTransaction/checkFinishedPaoTransactionFailure');

const paoTransactionSlice = createSlice({
  name: 'paoTransaction',
  initialState,
  reducers: {
    checkFinishedPaoTransactionSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
  },
});

export const { checkFinishedPaoTransactionSuccess } = paoTransactionSlice.actions;

export default paoTransactionSlice.reducer;

import * as React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import draggingHandle from '@/assets/images/dragging_handle.svg';
import navItemIcon from '@/assets/images/icon_nav_item.svg';
import activeNavItemIcon from '@/assets/images/icon_nav_item_active.svg';
import { NAV_ICON_URL } from '@/assets/images/nav';
import { NavItem } from '@/components/template';
import SideNavItemBadge from '@/layout/Sidebar/SideNavItemBadge';
import navItem, { ChildrenNavItems } from '@/layout/Sidebar/_nav';
import { useMenuContext } from '@/layout/Sidebar/parts/SidebarContext';
import { getNavIconByUrl } from '@/layout/Sidebar/util';
import { cn } from '@/lib/utils';
import { useAppState } from '@/store/selector';

const SideMenuItemContainer = styled.div<{ $isHover: boolean; $isDragging: boolean }>`
  border-top: ${({ $isHover }) => ($isHover ? '3px solid #fff' : import.meta.env.VITE_NODE_ENV !== 'production' ? '0px' : '1px solid #0A171C')};
  opacity: ${({ $isDragging }) => ($isDragging ? '0.5' : '1')};
`;

function SideMenuItem() {
  const { t } = useTranslation();
  const { isAvailableDrag, isDragging, isHover, item, dragRef, dropRef, handleMouseDownOverTime, handleMouseOutOverTime, handleMouseUpOverTime } =
    useMenuContext();
  const isHiddenSidebar = useAppState((state) => state.common.isHiddenSidebar);
  const navLinkContentRef = React.useRef<HTMLDivElement | null>(null);

  const classes = classNames('nav-link', item?.class || '');

  const isDashboard = item?.name === 'menu.dashboard';

  const childrenItems: ChildrenNavItems = navItem.items.reduce((data: ChildrenNavItems, item) => {
    if (item.children) {
      data = [...data, ...item.children];
    }

    return data;
  }, []);

  const isSubMenu = childrenItems.find((value) => value.url === item?.url) !== undefined;
  const itemDataCy = item?.url.replaceAll('/', '-');

  if (!dragRef || !dropRef || !item) {
    return null;
  }

  return (
    <SideMenuItemContainer
      ref={(node) => dragRef(dropRef(node))}
      onMouseDown={handleMouseDownOverTime}
      onMouseUp={handleMouseUpOverTime}
      onMouseOut={handleMouseOutOverTime}
      $isHover={isHover}
      $isDragging={isDragging}
    >
      <NavItem data-cy={`nav${itemDataCy}`}>
        <NavLink to={item.url} className={classes} activeClassName='active' exact>
          <div className={isDashboard ? 'tw-flex' : 'tw-flex tw-pl-[20px]'} ref={navLinkContentRef}>
            {isSubMenu && (isDragging || isAvailableDrag) ? (
              <img src={draggingHandle} alt='dragging' />
            ) : (
              !isDashboard &&
              (navLinkContentRef.current?.parentElement?.classList.contains('active') ? (
                <img src={activeNavItemIcon} className={cn(isHiddenSidebar && 'tw-ml-auto')} alt='active-nav-item-icon' />
              ) : (
                <img src={navItemIcon} className={cn(isHiddenSidebar && 'tw-ml-auto')} alt='nav-item-icon' />
              ))
            )}
            <div className={!isSubMenu ? cn('tw-flex tw-items-center', isHiddenSidebar && 'tw-ml-auto') : 'tw-ml-[10px]'}>
              {!isSubMenu && <img src={`${NAV_ICON_URL[getNavIconByUrl(item.url)]}`} className='tw-text-white tw-p-[5px]' />}
              {!isHiddenSidebar && t(item.name)}
              {item.badge && <SideNavItemBadge badge={item.badge} />}
            </div>
          </div>
        </NavLink>
      </NavItem>
    </SideMenuItemContainer>
  );
}

export default SideMenuItem;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BranchFormInterface, LocationInfo, LocationInfoSvcState } from '@/svc/corporate-svc/locationInfo/type';

const initialState: LocationInfoSvcState = {
  isFetching: false,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  locationInfos: [],
};

const locationInfoSvcSlice = createSlice({
  name: 'locationInfo-svc',
  initialState,
  reducers: {
    fetchLocationInfosRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id: string) => ({ payload: { id } }),
    },
    fetchLocationInfosSuccess(state, { payload }: PayloadAction<LocationInfo[]>) {
      state.isFetching = false;
      state.locationInfos = [...payload];
    },
    fetchLocationInfosFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    createLocationInfosRequest: {
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
      prepare: (id: string, data: BranchFormInterface) => ({ payload: { id, data } }),
    },
    createLocationInfosSuccess(state) {
      state.isCreating = false;
    },
    createLocationInfosFailure(state, { payload }: PayloadAction<string>) {
      state.isCreating = false;
      state.error = payload;
    },
    updateLocationInfosRequest: {
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
      prepare: (id: string, data: BranchFormInterface) => ({ payload: { id, data } }),
    },
    updateLocationInfosSuccess(state) {
      state.isUpdating = false;
    },
    updateLocationInfosFailure(state, { payload }: PayloadAction<string>) {
      state.isCreating = false;
      state.error = payload;
    },
    deleteLocationInfosRequest: {
      reducer: (state) => {
        state.isDeleting = true;
        state.error = null;
      },
      prepare: (id: number) => ({ payload: { id } }),
    },
    deleteLocationInfosSuccess(state) {
      state.isDeleting = false;
    },
    deleteLocationInfosFailure(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
  },
});

export const {
  fetchLocationInfosRequest,
  fetchLocationInfosSuccess,
  fetchLocationInfosFailure,
  createLocationInfosRequest,
  createLocationInfosSuccess,
  createLocationInfosFailure,
  updateLocationInfosRequest,
  updateLocationInfosSuccess,
  updateLocationInfosFailure,
  deleteLocationInfosRequest,
  deleteLocationInfosSuccess,
  deleteLocationInfosFailure,
} = locationInfoSvcSlice.actions;

export default locationInfoSvcSlice.reducer;

import {
  CAR_TYPE,
  CREDIT_CARD_BRAND,
  MISSION_TARGET_TYPE,
  MISSION_TYPE,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE,
  DOCUMENT_TEMPLATE_KEY_TYPE,
  NEWS_CONTENT_TYPE,
  PASSWORD_CRITERIA,
  PLACE_CATEGORY,
  PLACE_CHANGE_REQUEST,
  PLACE_CHANGE_REQUEST_STATUS,
  PROMO_TYPE_LOYALTY,
  PROMO_TYPE_NORMAL,
  PROMO_TYPE_SIGN_UP,
  PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE,
  PROMO_VALID_UNTIL_POLICY_FIXED,
  PROMO_VALID_UNTIL_POLICY_INHERIT,
  REFERRAL_REWARD_TYPE,
  REGION,
  REMARK_LOG_TYPE,
  BANNER_DESCRIPTION_COLOR,
  SIDEBAR_PATH,
  APP_AUTHORITY,
  TAXI_TYPE,
  SG_TRANSACTION_CATEGORY_TYPE,
  ADDITIONAL_FARE_TYPE,
  RESTRICT_TYPE_AND_ALLOWANCE_TYPE,
  DISCLAIMER_TYPE,
} from '@/assets/constants/enum';
import { AVAILABLE_PAYMENT_METHOD_STRING } from '@/assets/constants/index';
import { DocumentTemplateKeyType, DocumentFieldTemplateKeyType, TaxiType } from '@/assets/constants/type';

export const ALL_PLACE_CHANGE_REQUEST = Object.values(PLACE_CHANGE_REQUEST);

export const ALL_PLACE_CHANGE_REQUEST_STATUS = Object.values(PLACE_CHANGE_REQUEST_STATUS);

export const ALL_PLACE_CATEGORY = Object.values(PLACE_CATEGORY);

export const ALL_REGION = Object.values(REGION);

export const ALL_REFERRAL_REWARD_TYPE = Object.values(REFERRAL_REWARD_TYPE);

export const ALL_PROMO_VALID_UNTIL_POLICY = [PROMO_VALID_UNTIL_POLICY_INHERIT, PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE, PROMO_VALID_UNTIL_POLICY_FIXED];

export const ALL_PROMO_TYPE = [PROMO_TYPE_SIGN_UP, PROMO_TYPE_LOYALTY, PROMO_TYPE_NORMAL];

export const ALL_NEWS_CONTENT_TYPE = Object.values(NEWS_CONTENT_TYPE);

export const ALL_PASSWORD_CRITERIA = Object.values(PASSWORD_CRITERIA);

export const ALL_CAR_TYPES = [
  CAR_TYPE.CAR_TYPE_SEDAN,
  CAR_TYPE.CAR_TYPE_VAN,
  CAR_TYPE.CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE.CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE.CAR_TYPE_TUKTUK,
  CAR_TYPE.CAR_TYPE_BIKE,
  CAR_TYPE.CAR_TYPE_REMORQUE,
  CAR_TYPE.CAR_TYPE_PREMIUM_BIKE,
  CAR_TYPE.CAR_TYPE_WIN_BIKE,
  CAR_TYPE.CAR_TYPE_MINI_BUS,
  CAR_TYPE.CAR_TYPE_TAXI_STANDARD,
  CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE,
  CAR_TYPE.CAR_TYPE_TAXI_PREMIUM,
  CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE,
  CAR_TYPE.CAR_TYPE_DELIVERY_VAN,
  CAR_TYPE.CAR_TYPE_DELIVERY_BIKE,
  CAR_TYPE.CAR_TYPE_DELIVERY_CAR,
  CAR_TYPE.CAR_TYPE_ONION_TUKTUK,
  // Deprercate Car Type
  CAR_TYPE.CAR_TYPE_MIDDLE_SEDAN,
  CAR_TYPE.CAR_TYPE_BUSINESS_SEDAN,
  CAR_TYPE.CAR_TYPE_BUSINESS_BUS,
  CAR_TYPE.CAR_TYPE_SUV,
  CAR_TYPE.CAR_TYPE_COMPACT_SUV,
  CAR_TYPE.CAR_TYPE_FOOT,
  CAR_TYPE.CAR_TYPE_BUS,
];

export const ALL_VEHICLE_MODEL_CAR_TYPES = [
  CAR_TYPE.CAR_TYPE_SEDAN,
  CAR_TYPE.CAR_TYPE_VAN,
  CAR_TYPE.CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE.CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE.CAR_TYPE_TAXI_STANDARD,
  CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE,
  CAR_TYPE.CAR_TYPE_TAXI_PREMIUM,
  CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE,
  CAR_TYPE.CAR_TYPE_TUKTUK,
  CAR_TYPE.CAR_TYPE_BIKE,
  CAR_TYPE.CAR_TYPE_PREMIUM_BIKE,
  CAR_TYPE.CAR_TYPE_REMORQUE,
];

export const ALL_MISSION_TYPES = Object.values(MISSION_TYPE);

export const ALL_MISSION_TARGET_TYPES = Object.values(MISSION_TARGET_TYPE);

export const ALL_AVAILABLE_PAYMENT_METHOD_STRINGS = [
  AVAILABLE_PAYMENT_METHOD_STRING.CASH,
  AVAILABLE_PAYMENT_METHOD_STRING.PAO,
  AVAILABLE_PAYMENT_METHOD_STRING.ALIPAY_P,
  AVAILABLE_PAYMENT_METHOD_STRING.PIPAY_P,
  AVAILABLE_PAYMENT_METHOD_STRING.MOMOPAY_P,
  AVAILABLE_PAYMENT_METHOD_STRING.CREDITCARD,
  AVAILABLE_PAYMENT_METHOD_STRING.TADAPAY,
  AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE,
  AVAILABLE_PAYMENT_METHOD_STRING.CMCB,
  AVAILABLE_PAYMENT_METHOD_STRING.DELIVERY,
  AVAILABLE_PAYMENT_METHOD_STRING.ABA,
  AVAILABLE_PAYMENT_METHOD_STRING.WING,
  AVAILABLE_PAYMENT_METHOD_STRING.NETSQR,
  AVAILABLE_PAYMENT_METHOD_STRING.OCBC_PAO,
  AVAILABLE_PAYMENT_METHOD_STRING.CURRENT_BALANCE,
  AVAILABLE_PAYMENT_METHOD_STRING.ABA_AOF,
  AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE_CARD,
];

export const ALL_CREDIT_CARD_BRANDS = Object.values(CREDIT_CARD_BRAND);

export const SINGPASS_UNAVAILABLE_INPUT_FIELDS: DocumentFieldTemplateKeyType[] = [
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.NRIC,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.NRIC_EXPIRY_DATE,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_FRONT,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_BACK,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_CLASS,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_EXPIRY_DATE,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_TYPE,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_NUMBER,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.DRIVING_LICENSE_VALIDITY,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.TDVL_FRONT,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.TDVL_BACK,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.PDVL,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.PDVL_FRONT,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.PDVL_BACK,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.VL_EXPIRY_DATE,
];

export const SINGPASS_UNAVAILABLE_DETAIL_FIELDS: DocumentTemplateKeyType[] = [
  DOCUMENT_TEMPLATE_KEY_TYPE.DRIVING_LICENSE,
  DOCUMENT_TEMPLATE_KEY_TYPE.PDVL,
  DOCUMENT_TEMPLATE_KEY_TYPE.TDVL,
  DOCUMENT_TEMPLATE_KEY_TYPE.PDVL_OR_TDVL,
];

export const ALL_REMARK_LOG_TYPES = Object.values(REMARK_LOG_TYPE);

export const CHANGE_REQUEST_PHOTO_TEMPLATE_KEYS: string[] = [
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.CAR_PHOTO,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.VEHICLE_LICENSE_FRONT,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.VEHICLE_LICENSE_BACK,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.VEHICLE_REGISTER,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.TAXI_DRIVER_IDENTIFY_PLATE_FRONT,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.AUTHORISATION_LETTER,
  DOCUMENT_FIELD_TEMPLATE_KEY_TYPE.HIRE_CAR_PERMIT,
];

export const ALL_BANNER_DESCRIPTION_COLORS = Object.values(BANNER_DESCRIPTION_COLOR);

export const ALL_SIDEBAR_PATH = Object.values(SIDEBAR_PATH);

export const ALL_MOBILE_APP_AUTHORITY_KEY = [APP_AUTHORITY.RIDER_APP, APP_AUTHORITY.DRIVER_APP];

export const ALL_APP_AUTHORITY_KEY = Object.keys(APP_AUTHORITY);

export const ALL_TAXI_TYPES: TaxiType[] = Object.values(TAXI_TYPE);

export const ALL_SG_TRANSACTION_CATEGORY_TYPE = Object.values(SG_TRANSACTION_CATEGORY_TYPE);

export const ALL_ADDITIONAL_FARE_TYPE = Object.values(ADDITIONAL_FARE_TYPE);

export const ALL_RESTRICT_TYPE_AND_ALLOWANCE_TYPE = Object.values(RESTRICT_TYPE_AND_ALLOWANCE_TYPE);

export const ALL_DISCLAIMER_TYPE = Object.values(DISCLAIMER_TYPE);

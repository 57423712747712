import { Form as FormComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  inline?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLFormElement>;
  cssModule?: CSSModule;
}

export function Form(props: FormProps) {
  return <FormComponent {...props} />;
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createCityRequest,
  createCitySuccess,
  createCityFailure,
  updateCityRequest,
  updateCitySuccess,
  updateCityFailure,
  createCityWithZoneRequest,
  createCityWithZoneSuccess,
  createCityWithZoneFailure,
  getCityGeoJsonRequest,
  getCityGeoJsonSuccess,
  getCityGeoJsonFailure,
} from '@/svc/ride-svc/cities/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* create({ payload }: ReturnType<typeof createCityRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/cities', { data: payload });

    if (response.ok) {
      yield put(createCitySuccess(response.data));
    } else {
      yield put(createCityFailure(response.data.message));
    }
  } catch (e) {
    yield put(createCityFailure((e as Error).message));
  }
}

function* update({ payload }: ReturnType<typeof updateCityRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/cities/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateCitySuccess());
    } else {
      yield put(updateCityFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateCityFailure((e as Error).message));
  }
}

function* createCityWithZone({ payload }: ReturnType<typeof createCityWithZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/cities/create_with_zones', { data: payload });

    if (response.ok) {
      yield put(createCityWithZoneSuccess(response.data));
    } else {
      yield put(createCityWithZoneFailure(response.data.message));
    }
  } catch (e) {
    yield put(createCityWithZoneFailure((e as Error).message));
  }
}

function* getCityGeoJSON({ payload }: ReturnType<typeof getCityGeoJsonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/cities/${payload}/geojson`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, `city-${payload}.geojson`);
      yield put(getCityGeoJsonSuccess());
    } else {
      yield put(getCityGeoJsonFailure(response.data.message));
    }
  } catch (e) {
    yield put(getCityGeoJsonFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createCityRequest.type, create);
  yield takeLatest(updateCityRequest.type, update);
  yield takeLatest(createCityWithZoneRequest.type, createCityWithZone);
  yield takeLatest(getCityGeoJsonRequest.type, getCityGeoJSON);
}

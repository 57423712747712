import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "MomopayTransactionsList" */ '@/pages/MomopayTransactions/List'));
const Detail = lazy(() => import(/* webpackChunkName: "MomopayTransactionsDetail" */ '@/pages/MomopayTransactions/Detail'));

export const momopayTransactionsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.MomopayTransactions,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.MomopayTransactions}/:id`,
    component: Detail,
    exact: true,
  },
];

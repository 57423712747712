import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchRouteParam, GoogleRoute } from '@/pages/Test/type';
import Service from '@/svc/serviceName';

import { TestState } from './type';

const prefix = `${Service.routingSvc}/test`;

export const initialTestState: TestState = {
  routeResult: null,
};

export const fetchRoutesRequest = createAction<FetchRouteParam>(`${prefix}/fetchRoutesRequest`);
export const fetchRoutesFailure = createAction<string>(`${prefix}/fetchRoutesFailure`);

const testSlice = createSlice({
  name: prefix,
  initialState: initialTestState,
  reducers: {
    fetchRoutesSuccess(state, { payload }: PayloadAction<GoogleRoute>) {
      state.routeResult = payload;
    },
  },
});

export const { fetchRoutesSuccess } = testSlice.actions;

export default testSlice.reducer;

import { useEffect } from 'react';

import { useHistory } from 'react-router';
import { ToastOptions } from 'react-toastify';
import { Dispatch } from 'redux';

import { ERROR } from '@/assets/constants';
import { showErrorToast, showSuccessToast } from '@/components/etc';

type Callback = (payload: any) => void;

interface Subscribes {
  action: string;
  callback: Callback;
}

interface Store {
  subscribes: Subscribes[]; // 일반 subscribe
  errorSubscribes: Subscribes[]; // useToastWithDispatch 전용
  addSubscribe: (action: string, callback: Callback) => () => void;
  addErrorSubscribe: (action: string, callback: Callback) => void;
  resetSubscribes: () => void;
  resetErrorSubscribes: () => void;
}

const initialErrorSubscribes: Store['errorSubscribes'] = [];

const store: Store = {
  subscribes: [],
  errorSubscribes: initialErrorSubscribes,
  addSubscribe(action: string, callback: Callback) {
    const item = {
      action,
      callback,
    };

    this.subscribes.push(item);

    return () => {
      const index = this.subscribes.indexOf(item);

      this.subscribes.splice(index, 1);
    };
  },
  addErrorSubscribe(action: string, callback: Callback) {
    const item = {
      action,
      callback,
    };

    const errorSubscribes = this.errorSubscribes.filter(({ action }) => action !== item.action);
    errorSubscribes.push(item);
    this.errorSubscribes = errorSubscribes;
  },
  resetSubscribes() {
    this.subscribes = [];
  },
  resetErrorSubscribes() {
    this.errorSubscribes = initialErrorSubscribes;
  },
};

export function addErrorSubscribes(key: string, toastOptions?: ToastOptions) {
  function failure(errorMessage: string) {
    if (errorMessage === ERROR.LAST_SCROLL) {
      return;
    }

    showErrorToast(errorMessage, toastOptions);
  }

  store.addErrorSubscribe(`${key}_FAILURE`, failure);
}

export function addToolkitErrorSubscribes(key: string, toastOptions?: ToastOptions) {
  function failure(errorMessage: string) {
    if (errorMessage === ERROR.LAST_SCROLL) {
      return;
    }

    showErrorToast(errorMessage, toastOptions);
  }

  store.addErrorSubscribe(`${key}Failure`, failure);
}

export function resetSubscribes() {
  store.resetSubscribes();
}

export function resetErrorSubscribes() {
  store.resetErrorSubscribes();
}

export const actionSubscribeMiddleware = () => (next: Dispatch) => (anyAction: any) => {
  next(anyAction);

  const { type: actionType, error, payload }: { type: string; error?: string; payload?: any } = anyAction;

  store.subscribes.slice().forEach(({ action, callback: actionCallback }) => {
    if (action === actionType) {
      if (action.includes('FAILURE')) {
        actionCallback(anyAction);
      } else {
        actionCallback(anyAction);
      }
    }
  });

  store.errorSubscribes.slice().forEach(({ action, callback: actionCallback }) => {
    if (action === actionType && actionType.includes('FAILURE')) {
      actionCallback(error || payload?.error || payload);
    }
  });
};

// T = payload ReturnType<typeof actionFunction>
export const useActionSubscribe = <T = any>(action: string, callback: (payload: T) => void) => {
  useEffect(() => {
    //success면 payload, fail이면 action을 넘겨줌
    const unsubscribe = store.addSubscribe(action, callback);

    return unsubscribe;
  }, [action, callback]);
};

interface Options {
  successMessage?: string;
  redirectURI?: string;
  delay?: number;
}

/*
 * useActionToast(Types.FETCH_LIST_REQUEST, {
 *   successMessage: 'Fetch Success',
 *   redirectURI: '/',
 *   delay: 1000,
 * });
 */
export const useActionToast = (key: string, options?: Options) => {
  const history = useHistory();

  useEffect(() => {
    const slicedKey = key.slice(0, key.indexOf('_REQUEST'));

    const success = () => {
      if (options) {
        const { successMessage, redirectURI, delay } = options;
        if (successMessage) {
          showSuccessToast(successMessage);
        }

        if (redirectURI) {
          setTimeout(() => history.push(redirectURI), delay);
        }
      }
    };

    const failure = (action: { error?: string; payload: any }) => {
      const errorMessage = action?.error || action.payload?.error || action.payload;
      showErrorToast(errorMessage);
    };

    const unsubscribeSuccess = store.addSubscribe(`${slicedKey}_SUCCESS`, success);
    const unsubscribeFailure = store.addSubscribe(`${slicedKey}_FAILURE`, failure);

    return () => {
      unsubscribeSuccess();
      unsubscribeFailure();
    };
  }, [key, options?.successMessage, options?.redirectURI, options?.delay]);
};

const sanitizeActionKey = (type: string) => {
  if (type.includes('_')) {
    return {
      successAction: type.replace(/^(.+)_REQUEST$/, '$1_SUCCESS'),
      failureAction: type.replace(/^(.+)_REQUEST$/, '$1_FAILURE'),
    };
  }

  return {
    successAction: type.replace(/^(.+)Request$/, '$1Success'),
    failureAction: type.replace(/^(.+)Request$/, '$1Failure'),
  };
};

export const useRequestActionCallback = (
  action: string,
  callback?: {
    success?: (data: any) => void;
    failure?: (data: any) => void;
  }
) => {
  const { successAction, failureAction } = sanitizeActionKey(action);

  useActionSubscribe(successAction, (...arg) => callback?.success?.(...arg));
  useActionSubscribe(failureAction, (...arg) => callback?.failure?.(...arg));
};

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RideList" */ '@/pages/Ride/List'));
const PublicList = lazy(() => import(/* webpackChunkName: "RidePublicList" */ '@/pages/Ride/PublicList'));
const Detail = lazy(() => import(/* webpackChunkName: "RideDetail" */ '@/pages/Ride/Detail'));
const ReservationList = lazy(() => import(/* webpackChunkName: "ReservationRideList" */ '@/pages/Ride/ReservationList'));
const ReservationRestrictList = lazy(() => import(/* webpackChunkName: "ReservationRideList" */ '@/pages/Ride/ReservationRestrictList'));
const ReservationDashboard = lazy(() => import(/* webpackChunkName: "ReservationRideList" */ '@/pages/Ride/ReservationDashboard'));

export const rideRoute: RouteProps[] = [
  {
    permission: ActionType.LIST,
    path: PATH.Ride,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Ride}/restrict`,
    component: PublicList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Ride}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.ReservationRide}/dashboard`,
    component: ReservationDashboard,
    exact: true,
  },
  {
    permission: ActionType.LIST,
    path: PATH.ReservationRide,
    component: ReservationList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.ReservationRide}/restrict`,
    component: ReservationRestrictList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.ReservationRide}/:id`,
    component: Detail,
    exact: true,
  },
];

import { createStore as createReduxStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { actionSubscribeMiddleware } from '@/store/middleware/actionSubscribe';
import defaultErrorMiddleware from '@/store/middleware/defaultError';
import paginationMiddleware from '@/store/middleware/pagination';
import permissionCheckerMiddleware from '@/store/middleware/permissionCheck';
import trimValueBeforeRequestMiddleware from '@/store/middleware/trimValueBeforeRequest';
import easi6VendorAdminApp from '@/store/reducers';
import sagas from '@/store/sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => any;
  }
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    permissionCheckerMiddleware,
    trimValueBeforeRequestMiddleware,
    actionSubscribeMiddleware,
    sagaMiddleware,
    defaultErrorMiddleware,
    paginationMiddleware,
  ];

  if (import.meta.env.NODE_ENV !== 'production' || window.location.host.includes('tada-staging.com') || window.localStorage['showReduxLog'] === '1') {
    // @ts-ignore
    middleware.push(logger);
  }

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createReduxStore);
  const store = createStoreWithMiddleware(easi6VendorAdminApp, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

  sagaMiddleware.run(sagas);

  return store;
};

const store = createStore();

export const { dispatch } = store;

export default store;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DeliveryExportResultInterface,
  DeliveryExportResultQueryInterface,
  DeliveryExportResultState,
  ExportDeliveryExportResultQuery,
} from '@/pages/DeliveryExportResults/type';
import { listSuccess } from '@/utils/reducerUtils';
import { ListResponse } from '@/utils/types';

const initialState: DeliveryExportResultState = {
  isFetching: false,
  isExporting: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
};

const deliveryExportResultSlice = createSlice({
  name: 'deliveryExportResult',
  initialState,
  reducers: {
    fetchDeliveryExportResultListRequest: {
      prepare: (search: DeliveryExportResultQueryInterface) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDeliveryExportResultListSuccess: {
      prepare: (payload: ListResponse<DeliveryExportResultInterface>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<DeliveryExportResultInterface>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchDeliveryExportResultListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    exportDeliveryResultRequest: {
      prepare: (search: ExportDeliveryExportResultQuery) => ({ payload: search }),
      reducer: (state) => {
        state.isExporting = true;
        state.error = null;
      },
    },
    exportDeliveryResultSuccess(state) {
      state.isExporting = false;
    },
    exportDeliveryResultFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isExporting = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchDeliveryExportResultListRequest,
  fetchDeliveryExportResultListSuccess,
  fetchDeliveryExportResultListFailure,
  exportDeliveryResultRequest,
  exportDeliveryResultSuccess,
  exportDeliveryResultFailure,
} = deliveryExportResultSlice.actions;

export default deliveryExportResultSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { checkWingTransactionRequest, checkWingTransactionSuccess, checkWingTransactionFailure } from '@/svc/pay-svc/wingTransaction/reducer';
import { authenticatedRequest } from '@/utils/request';

function* checkWingTransaction({ payload: txId }: ReturnType<typeof checkWingTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/payment/v1/wing-transactions/check/${txId}`, { params: { userModel: 'DRIVER' } });

    if (response.ok) {
      yield put(checkWingTransactionSuccess());
    } else {
      yield put(checkWingTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(checkWingTransactionFailure((e as Error).message));
  }
}

export default function* paySvcTransactionSaga() {
  yield takeLatest(checkWingTransactionRequest.type, checkWingTransaction);
}

import { useEffect } from 'react';

import i18next from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { findRouteName, getPaths } from '@@components/Breadcrumb/util';

import { Pages } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import { checkPermission } from '@/components/CheckPermission';
import { allNavItems } from '@/layout/Sidebar/_nav';
import { availableRegionSelector, isFetchMeSelector, permissionsSelector, usernameSelector } from '@/pages/Full/selector';
import { fetchMeRequest, postPushSubscriptionRequest, logout as performLogout, setAvailableRegionsRequest } from '@/pages/LoginPage/reducer';
import { fetchRegionListWithoutPageRequest } from '@/pages/Regions/reducer';
import { getRegionList } from '@/pages/Regions/selectors';
import { useAppState } from '@/store/selector';
import { getLanguage, saveLanguage, getLastSelectedRegion, saveLastSelectedRegion } from '@/utils/localStorage';
import { registerServiceWorker } from '@/utils/serviceWorker';

export const useRegionManager = () => {
  const dispatch = useDispatch();
  const regions = useAppState(getRegionList);
  const availableRegion = useAppState(availableRegionSelector);
  const { isFetchMe } = useAuth();

  const countries = regions.filter((reg) => reg.available).map((reg) => reg.country as string); // region api response + available filterd
  const availableRegionName = getLastSelectedRegion(); // localStorage
  const availableRegions = availableRegion.filter((region) => countries.includes(region)); // filter availableRegion with countries

  const validRenderPageCondition = () => {
    if (regions.length === 0) {
      return false;
    }

    const lastSelectedRegionName = getLastSelectedRegion() || saveRegionName();

    return lastSelectedRegionName;
  };

  const saveRegionName = () => {
    if (!availableRegionName || !availableRegions.includes(availableRegionName)) {
      const [region] = availableRegions;

      saveLastSelectedRegion(region);

      return region;
    }

    return availableRegionName;
  };

  useEffect(() => {
    if (!['en', 'ko'].includes(getLanguage())) {
      saveLanguage('en');
    } else {
      i18next.changeLanguage(getLanguage());
    }

    registerServiceWorker((subscription) => {
      dispatch(postPushSubscriptionRequest(subscription));
    });

    dispatch(fetchMeRequest());
  }, []);

  useEffect(() => {
    if (!isFetchMe) {
      dispatch(fetchRegionListWithoutPageRequest());
    }
  }, [isFetchMe]);

  useEffect(() => {
    if (regions.length !== 0) {
      saveRegionName();
      dispatch(setAvailableRegionsRequest(availableRegions));
    }
  }, [regions]);

  return { validRenderPageCondition };
};

export const useDetermineFirstPageAvailable = () => {
  const permissions = useAppState(permissionsSelector);
  const pagePermissions = Object.keys(permissions || {});

  const firstAvailable = allNavItems.find(
    (item) =>
      pagePermissions.includes(item.Pages || '') &&
      (item.permissionType ? checkPermission(item.permissionType, permissions[item.Pages as Pages]) : true)
  );

  return _.get(firstAvailable, 'url') || PATH.Dashboard;
};

export const usePageTitle = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const paths = getPaths(pathname);

  const result = paths.map((path) => t(findRouteName(path))).join(' / ');

  return `${result} - TADA Admin`;
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const username = useAppState(usernameSelector);
  const permissions = useAppState(permissionsSelector);
  const regions = useAppState(getRegionList);
  const isFetchMe = useAppState(isFetchMeSelector);

  const logout = () => {
    dispatch(postPushSubscriptionRequest({ push_subscription: null }));
    location.state = { logout: true };
    dispatch(performLogout());
  };

  return { username, permissions, regions, isFetchMe, logout };
};

import { NAV_ICON_PATH_MAP } from '@/assets/constants';
import AdminRoleSetting from '@/assets/images/nav/admin_role_setting.svg';
import Balance from '@/assets/images/nav/balance.svg';
import Card from '@/assets/images/nav/card.svg';
import ContentManagement from '@/assets/images/nav/content_management.svg';
import Corporate from '@/assets/images/nav/corporate.svg';
import Dashboard from '@/assets/images/nav/dashboard.svg';
import Default from '@/assets/images/nav/default.svg';
import Delivery from '@/assets/images/nav/delivery.svg';
import DeliveryOptions from '@/assets/images/nav/delivery_options.svg';
import Drivers from '@/assets/images/nav/driver.svg';
import HourlyRentalCar from '@/assets/images/nav/hourly_rental_car.svg';
import Maintenance from '@/assets/images/nav/maintenance.svg';
import Operations from '@/assets/images/nav/operations.svg';
import OtpSkipRule from '@/assets/images/nav/otp_skip_rule.svg';
import PaymentTransaction from '@/assets/images/nav/payment.svg';
import Penalty from '@/assets/images/nav/penalty.svg';
import Place from '@/assets/images/nav/place.svg';
import Products from '@/assets/images/nav/product.svg';
import Promotion from '@/assets/images/nav/promotion.svg';
import Ride from '@/assets/images/nav/ride.svg';
import Rider from '@/assets/images/nav/rider.svg';
import Surges from '@/assets/images/nav/surge.svg';
import WithdrawRequest from '@/assets/images/nav/withdraw_request.svg';

export const NAV_ICON_URL = {
  [NAV_ICON_PATH_MAP.DASHBOARD]: Dashboard,
  [NAV_ICON_PATH_MAP.PLACE]: Place,
  [NAV_ICON_PATH_MAP.RIDE]: Ride,
  [NAV_ICON_PATH_MAP.PAYMENT_TRANSACTION]: PaymentTransaction,
  [NAV_ICON_PATH_MAP.WITHDRAW_REQUEST]: WithdrawRequest,
  [NAV_ICON_PATH_MAP.DRIVERS]: Drivers,
  [NAV_ICON_PATH_MAP.PROMOTION_COUPON]: Promotion,
  [NAV_ICON_PATH_MAP.RIDER]: Rider,
  [NAV_ICON_PATH_MAP.CARD]: Card,
  [NAV_ICON_PATH_MAP.DELIVERY]: Delivery,
  [NAV_ICON_PATH_MAP.DELIVERY_OPTIONS]: DeliveryOptions,
  [NAV_ICON_PATH_MAP.HOURLY_RENTAL_CAR]: HourlyRentalCar,
  [NAV_ICON_PATH_MAP.BALANCE]: Balance,
  [NAV_ICON_PATH_MAP.OTP_SKIP_RULE]: OtpSkipRule,
  [NAV_ICON_PATH_MAP.PENALTY]: Penalty,
  [NAV_ICON_PATH_MAP.OPERATIONS]: Operations,
  [NAV_ICON_PATH_MAP.CONTENT_MANAGEMENT]: ContentManagement,
  [NAV_ICON_PATH_MAP.PRODUCTS]: Products,
  [NAV_ICON_PATH_MAP.SURGES]: Surges,
  [NAV_ICON_PATH_MAP.ADMIN_ROLE_SETTING]: AdminRoleSetting,
  [NAV_ICON_PATH_MAP.CORPORATE]: Corporate,
  [NAV_ICON_PATH_MAP.MAINTENANCE]: Maintenance,
  [NAV_ICON_PATH_MAP.DEFAULT]: Default,
};

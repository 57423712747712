import { combineReducers } from 'redux';

import columnSelector from '@/components/ColumnSelector/reducer';
import leavePopup from '@/components/LeavePopup/reducer';
import menuManager from '@/components/MenuManager/reducer';
import admin from '@/pages/Admin/reducer';
import adminLog from '@/pages/AdminLog/reducer';
import airports from '@/pages/Airports/reducer';
import alipayTransactions from '@/pages/AlipayTransactions/reducer';
import appVersion from '@/pages/AppVersion/reducer';
import areas from '@/pages/Areas/reducer';
import corporates from '@/pages/Corporate/reducer';
import corporateManager from '@/pages/CorporateManager/reducer';
import coupon from '@/pages/Coupon/reducer';
import dashboard from '@/pages/Dashboard/reducer';
import { DashboardState } from '@/pages/Dashboard/types';
import deliveryExportResult from '@/pages/DeliveryExportResults/reducer';
import deliveryPaymentOption from '@/pages/DeliveryPaymentOption/reducer';
import deliveryPriceOption from '@/pages/DeliveryPriceOption/reducer';
import deliveryRide, { DeliveryRideState } from '@/pages/DeliveryRide/reducer';
import deliveryRidePriceOption, { DeliveryRidePriceOptionState } from '@/pages/DeliveryRidePriceOption/reducer';
import deliveryRideProductOption, { DeliveryRideProductOptionState } from '@/pages/DeliveryRideProductOption/reducer';
import dispatchConfig from '@/pages/DispatchConfig/reducer';
import driverApplications from '@/pages/DriverApplications/reducer';
import drivers from '@/pages/Drivers/reducer';
import dynamicSurge from '@/pages/DynamicSurge/reducer';
import exportSchedule from '@/pages/ExportSchedule/reducer';
import fraudCard from '@/pages/FraudCard/reducer';
import fraudLog from '@/pages/FraudLog/reducer';
import auth from '@/pages/LoginPage/reducer';
import { LoginPageState } from '@/pages/LoginPage/types';
import momopayTransactions from '@/pages/MomopayTransactions/reducer';
import paoTransactions from '@/pages/PaoTransactions/reducer';
import paymentTransaction from '@/pages/PaymentTransaction/reducer';
import pipayTransactions from '@/pages/PipayTransactions/reducer';
import place, { PlaceState } from '@/pages/Place/reducer';
import polygons from '@/pages/Polygons/reducer';
import priceVerification from '@/pages/PriceVerification/reducer';
import profilePictureChange from '@/pages/ProfilePictureChange/reducer';
import { ProfilePictureChangeState } from '@/pages/ProfilePictureChange/types';
import promotion from '@/pages/Promotion/reducer';
import regions from '@/pages/Regions/reducer';
import rides from '@/pages/Ride/reducer';
import rideProducts from '@/pages/RideProducts/reducer';
import rider from '@/pages/Rider/reducer';
import role from '@/pages/Roles/reducer';
import zones from '@/pages/Zones/reducer';
import accountSvc from '@/svc/account-svc/reducer';
import common from '@/svc/common/reducer';
import { CommonState } from '@/svc/common/type';
import corporateManagerActionLogSvc from '@/svc/corporate-svc/CorporateManagerActionLog/reducer';
import { CorporateManagerActionLogState } from '@/svc/corporate-svc/CorporateManagerActionLog/type';
import InvoiceBulkSvc from '@/svc/corporate-svc/InvoiceBulk/reducer';
import locationInfoSvc from '@/svc/corporate-svc/locationInfo/reducer';
import couponSvc from '@/svc/coupon-svc/reducer';
import csSvc from '@/svc/cs-svc/reducer';
import deliverySvc from '@/svc/delivery-svc/reducer';
import driverTutorialSvc from '@/svc/driver-tutorial-svc/reducer';
import inboxSvc from '@/svc/inbox-svc/reducer';
import incentiveSvc from '@/svc/incentive-svc/reducer';
import carSvc from '@/svc/member-svc/car/reducer';
import memberSvc from '@/svc/member-svc/reducer';
import mobileSvc from '@/svc/mobile-svc/reducer';
import paySvc from '@/svc/pay-svc/reducer';
import penaltySvc from '@/svc/penalty-svc/reducer';
import placeSvc from '@/svc/place-svc/reducer';
import rideSvc from '@/svc/ride-svc/reducer';
import routingSvc from '@/svc/routing-svc/reducer';

const easi6VendorAdminApp = combineReducers({
  auth,
  airports,
  drivers,
  polygons,
  zones,
  dashboard,
  regions,
  areas,
  driverApplications,
  priceVerification,
  dispatchConfig,
  profilePictureChange,
  dynamicSurge,
  alipayTransactions,
  paoTransactions,
  rides,
  rideProducts,
  momopayTransactions,
  pipayTransactions,
  deliveryPaymentOption,
  deliveryRidePriceOption,
  rider,
  promotion,
  coupon,
  corporates,
  corporateManager,
  corporateManagerActionLogSvc,
  admin,
  adminLog,
  role,
  paymentTransaction,
  appVersion,
  exportSchedule,
  deliveryExportResult,
  deliveryRide,
  fraudCard,
  fraudLog,
  deliveryRideProductOption,
  place,
  deliveryPriceOption,
  paySvc,
  common,
  deliverySvc,
  rideSvc,
  carSvc,
  accountSvc,
  locationInfoSvc,
  InvoiceBulkSvc,
  memberSvc,
  mobileSvc,
  penaltySvc,
  couponSvc,
  inboxSvc,
  csSvc,
  incentiveSvc,
  placeSvc,
  driverTutorialSvc,
  routingSvc,
  columnSelector,
  leavePopup,
  menuManager,
});

export interface AppState {
  // TODO: Manually Add migrated states
  admin: ReturnType<typeof admin>;
  adminLog: ReturnType<typeof adminLog>;
  corporateManagerActionLogSvc: CorporateManagerActionLogState;
  auth: LoginPageState;
  dashboard: DashboardState;
  profilePictureChange: ProfilePictureChangeState;
  pipayTransactions: ReturnType<typeof pipayTransactions>;
  rides: any;
  deliveryPaymentOption: ReturnType<typeof deliveryPaymentOption>;
  deliveryRidePriceOption: DeliveryRidePriceOptionState;
  rideProducts: ReturnType<typeof rideProducts>;
  drivers: ReturnType<typeof drivers>;
  driverApplications: any;
  rider: any;
  role: ReturnType<typeof role>;
  areas: ReturnType<typeof areas>;
  zones: any;
  paymentTransaction: ReturnType<typeof paymentTransaction>;
  appVersion: ReturnType<typeof appVersion>;
  exportSchedule: ReturnType<typeof exportSchedule>;
  deliveryExportResult: ReturnType<typeof deliveryExportResult>;
  deliveryRide: DeliveryRideState;
  fraudLog: ReturnType<typeof fraudLog>;
  fraudCard: ReturnType<typeof fraudCard>;
  deliveryRideProductOption: DeliveryRideProductOptionState;
  place: PlaceState;
  regions: ReturnType<typeof regions>;
  deliveryPriceOption: ReturnType<typeof deliveryPriceOption>;
  paySvc: ReturnType<typeof paySvc>;
  airports: any;
  dynamicSurge: any;
  polygons: ReturnType<typeof polygons>;
  priceVerification: ReturnType<typeof priceVerification>;
  forceDispatchDashboard: any;
  forceDispatchDriver: any;
  forceDispatchCar: any;
  dispatchConfig: ReturnType<typeof dispatchConfig>;
  forceDispatchHubs: any;
  alipayTransactions: ReturnType<typeof alipayTransactions>;
  paoTransactions: ReturnType<typeof paoTransactions>;
  sdRides: any;
  momopayTransactions: ReturnType<typeof momopayTransactions>;
  promotion: ReturnType<typeof promotion>;
  coupon: any;
  corporates: ReturnType<typeof corporates>;
  corporateManager: ReturnType<typeof corporateManager>;
  common: CommonState;
  deliverySvc: ReturnType<typeof deliverySvc>;
  rideSvc: ReturnType<typeof rideSvc>;
  carSvc: ReturnType<typeof carSvc>;
  accountSvc: ReturnType<typeof accountSvc>;
  locationInfoSvc: ReturnType<typeof locationInfoSvc>;
  InvoiceBulkSvc: ReturnType<typeof InvoiceBulkSvc>;
  memberSvc: ReturnType<typeof memberSvc>;
  mobileSvc: ReturnType<typeof mobileSvc>;
  penaltySvc: ReturnType<typeof penaltySvc>;
  couponSvc: ReturnType<typeof couponSvc>;
  inboxSvc: ReturnType<typeof inboxSvc>;
  csSvc: ReturnType<typeof csSvc>;
  incentiveSvc: ReturnType<typeof incentiveSvc>;
  placeSvc: ReturnType<typeof placeSvc>;
  driverTutorialSvc: ReturnType<typeof driverTutorialSvc>;
  routingSvc: ReturnType<typeof routingSvc>;
  columnSelector: ReturnType<typeof columnSelector>;
  leavePopup: ReturnType<typeof leavePopup>;
  menuManager: ReturnType<typeof menuManager>;
}

// @ts-ignore
export default easi6VendorAdminApp;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "SafetyAccidentTopicManagementList" */ '@/pages/SafetyAccidentTopicManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "SafetyAccidentTopicManagementDetail" */ '@/pages/SafetyAccidentTopicManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "SafetyAccidentTopicManagementNew" */ '@/pages/SafetyAccidentTopicManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "SafetyAccidentTopicManagementEdit" */ '@/pages/SafetyAccidentTopicManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "SafetyAccidentTopicManagementEdit" */ '@/pages/SafetyAccidentTopicManagement/Order'));

export const safetyAccidentTopicManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.SafetyAccidentTopicManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.SafetyAccidentTopicManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SafetyAccidentTopicManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.SafetyAccidentTopicManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.SafetyAccidentTopicManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

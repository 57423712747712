import { useDispatch } from 'react-redux';

import { ScheduleSurgeLink } from '@/components/Links';
import DetailSection from '@/components/Section/DetailSection';
import BooleanIcon from '@/components/ValueField/Boolean';
import { Modal, ModalBody, ModalHeader } from '@/components/template';
import { useAppState } from '@/store/selector';
import { togglePopup } from '@/svc/common/reducer';
import { selectScheduleSurgeData, selectScheduleSurgeOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';

function ScheduleSurgeModal() {
  const dispatch = useDispatch();

  const open = useAppState(selectScheduleSurgeOpenFlag);
  const scheduleSurges = useAppState(selectScheduleSurgeData);

  const toggle = () => {
    dispatch(togglePopup(POPUP_TYPE.ScheduleSurge));
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Selected Schedule Surges</ModalHeader>
      <ModalBody>
        {scheduleSurges.length &&
          scheduleSurges.map((data) => (
            <DetailSection
              key={data.id}
              options={[
                {
                  key: 'id',
                  name: 'ID',
                },
                {
                  key: 'name',
                  name: 'Name',
                  renderContent: ({ name, id }) => <ScheduleSurgeLink id={id}>{name}</ScheduleSurgeLink>,
                },
                {
                  key: 'exclusive',
                  name: 'Exclusive',
                  renderContent: ({ exclusive }) => <BooleanIcon flag={exclusive} />,
                },
                {
                  key: 'rate',
                  name: 'Rate',
                },
                {
                  key: 'fixedAmount',
                  name: 'Fixed Amount',
                },
              ]}
              data={data}
            />
          ))}
      </ModalBody>
    </Modal>
  );
}

export default ScheduleSurgeModal;

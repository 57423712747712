import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DeliveryPriceOptionList" */ '@/pages/DeliveryPriceOption/List'));
const Detail = lazy(() => import(/* webpackChunkName: "DeliveryPriceOptionDetail" */ '@/pages/DeliveryPriceOption/Detail'));
const New = lazy(() => import(/* webpackChunkName: "DeliveryPriceOptionNew" */ '@/pages/DeliveryPriceOption/New'));
const Edit = lazy(() => import(/* webpackChunkName: "DeliveryPriceOptionEdit" */ '@/pages/DeliveryPriceOption/Edit'));

export const deliveryPriceOptionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DeliveryPriceOption,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DeliveryPriceOption}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DeliveryPriceOption}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DeliveryPriceOption}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { updateWaitingFeeStatusRequest, updateWaitingFeeStatusSuccess, updateWaitingFeeStatusFailure } from '@/svc/ride-svc/waitingFee/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateWaitingFeeStatus({ payload: { id, data } }: ReturnType<typeof updateWaitingFeeStatusRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/waiting-fee/${id}/update-waiting-fee-status`, { data });

    if (res.ok) {
      yield put(updateWaitingFeeStatusSuccess(res.data));
    } else {
      yield put(updateWaitingFeeStatusFailure(res.data.message));
    }
  } catch (e) {
    yield put(updateWaitingFeeStatusFailure((e as Error).message));
  }
}

export default function* waitingFeeSaga() {
  yield takeLatest(updateWaitingFeeStatusRequest.type, updateWaitingFeeStatus);
}

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchBulkBanDriverMissionsTemplateRequest,
  fetchBulkBanDriverMissionsTemplateSuccess,
  fetchBulkBanDriverMissionsTemplateFailure,
  bulkBanDriverMissionsRequest,
  bulkBanDriverMissionsSuccess,
  bulkBanDriverMissionsFailure,
  syncAllDriverMissionProgressRequest,
  syncAllDriverMissionProgressSuccess,
  syncAllDriverMissionProgressFailure,
  fetchBulkSyncDriverIncentiveTemplateRequest,
  fetchBulkSyncDriverIncentiveTemplateSuccess,
  fetchBulkSyncDriverIncentiveTemplateFailure,
  bulkSyncDriverIncentiveRequest,
  bulkSyncDriverIncentiveSuccess,
  bulkSyncDriverIncentiveFailure,
  downloadDriverParticipantsTemplateRequest,
  downloadDriverParticipantsTemplateSuccess,
  downloadDriverParticipantsTemplateFailure,
  downloadDriverExcludeTemplateSuccess,
  downloadDriverExcludeTemplateFailure,
  downloadDriverExcludeTemplateRequest,
  bulkAddDriverParticipantsRequest,
  bulkAddDriverParticipantsSuccess,
  bulkAddDriverParticipantsFailure,
  bulkExcludeDriverRequest,
  bulkExcludeDriverSuccess,
  bulkExcludeDriverFailure,
} from '@/svc/incentive-svc/reducer';
import riderMissionSaga from '@/svc/incentive-svc/riderMission/saga';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* fetchBulkBanDriverMissionsTemplate({ payload }: ReturnType<typeof fetchBulkBanDriverMissionsTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/v1/driver_missions/ban:template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, payload || 'bulk_ban_template.xlsx');
      yield put(fetchBulkBanDriverMissionsTemplateSuccess());
    } else {
      yield put(fetchBulkBanDriverMissionsTemplateFailure('Something Wrong'));
    }
  } catch (e) {
    yield put(fetchBulkBanDriverMissionsTemplateFailure((e as Error).message));
  }
}

function* bulkBanDriverMissions({ payload }: ReturnType<typeof bulkBanDriverMissionsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/driver_missions/ban:bulk', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    if (response.ok && response.data.invalidCount === 0) {
      yield put(bulkBanDriverMissionsSuccess());
    } else {
      yield put(
        bulkBanDriverMissionsFailure(
          response.data.message ||
            `${Object.entries(response.data.invalidReasons)
              .map(([key, value]) => `(${key}): ${value}`)
              .join('\n')}`
        )
      );
    }
  } catch (e) {
    yield put(bulkBanDriverMissionsFailure((e as Error).message));
  }
}

function* syncAllDriverMissionProgress({ payload: { id, delaySecond } }: ReturnType<typeof syncAllDriverMissionProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${id}/sync_all`, {
      params: {
        delaySecond,
      },
    });

    const action = response.ok ? syncAllDriverMissionProgressSuccess() : syncAllDriverMissionProgressFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(syncAllDriverMissionProgressFailure((e as Error).message));
  }
}

function* fetchBulkSyncDriverIncentiveTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/v1/driver_missions/sync_bulk:template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk_sync_template.xlsx');
      yield put(fetchBulkSyncDriverIncentiveTemplateSuccess());
    } else {
      yield put(fetchBulkSyncDriverIncentiveTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchBulkSyncDriverIncentiveTemplateFailure((e as Error).message));
  }
}

function* bulkSyncDriverIncentive({ payload: { id, data, params } }: ReturnType<typeof bulkSyncDriverIncentiveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${id}/sync:bulk`, {
      data,
      params,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkSyncDriverIncentiveSuccess() : bulkSyncDriverIncentiveFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(bulkSyncDriverIncentiveFailure((e as Error).message));
  }
}

function* downloadDriverParticipantsTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/v1/driver_missions/participants:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'driver_participants_template.xlsx');
      yield put(downloadDriverParticipantsTemplateSuccess());
    } else {
      yield put(downloadDriverParticipantsTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadDriverParticipantsTemplateFailure((e as Error).message));
  }
}

function* downloadDriverExcludeTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/v1/driver_missions/exclude:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'driver_exclude_template.xlsx');
      yield put(downloadDriverExcludeTemplateSuccess());
    } else {
      yield put(downloadDriverExcludeTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadDriverExcludeTemplateFailure((e as Error).message));
  }
}

function* bulkAddDriverParticipants({ payload: { id, data } }: ReturnType<typeof bulkAddDriverParticipantsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${id}/participants:bulk`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(bulkAddDriverParticipantsSuccess());
    } else {
      yield put(bulkAddDriverParticipantsFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkAddDriverParticipantsFailure((e as Error).message));
  }
}

function* bulkExcludeDriver({ payload: { id, data } }: ReturnType<typeof bulkExcludeDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${id}/exclude:bulk`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(bulkExcludeDriverSuccess());
    } else {
      yield put(bulkExcludeDriverFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkExcludeDriverFailure((e as Error).message));
  }
}

export default function* incentiveSaga() {
  yield takeLatest(fetchBulkBanDriverMissionsTemplateRequest.type, fetchBulkBanDriverMissionsTemplate);
  yield takeLatest(bulkBanDriverMissionsRequest.type, bulkBanDriverMissions);
  yield takeLatest(syncAllDriverMissionProgressRequest.type, syncAllDriverMissionProgress);
  yield takeLatest(fetchBulkSyncDriverIncentiveTemplateRequest.type, fetchBulkSyncDriverIncentiveTemplate);
  yield takeLatest(bulkSyncDriverIncentiveRequest.type, bulkSyncDriverIncentive);
  yield takeLatest(downloadDriverParticipantsTemplateRequest.type, downloadDriverParticipantsTemplate);
  yield takeLatest(downloadDriverExcludeTemplateRequest.type, downloadDriverExcludeTemplate);
  yield takeLatest(bulkAddDriverParticipantsRequest.type, bulkAddDriverParticipants);
  yield takeLatest(bulkExcludeDriverRequest.type, bulkExcludeDriver);
  yield all([call(riderMissionSaga)]);
}

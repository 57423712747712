import { createAction } from '@reduxjs/toolkit';

import { BankMaintenance, BankMaintenanceCreateDto } from '@/pages/BankMaintenance/type';
import Service from '@/svc/serviceName';

export const reCalculateShbLoanRequest = createAction<{ nric: string }>(`${Service.balanceSvc}/reCalculateShbLoanRequest`);
export const reCalculateShbLoanSuccess = createAction(`${Service.balanceSvc}/reCalculateShbLoanSuccess`);
export const reCalculateShbLoanFailure = createAction<string>(`${Service.balanceSvc}/reCalculateShbLoanFailure`);

export const upsertBankMaintenanceRequest = createAction<BankMaintenanceCreateDto>(`${Service.balanceSvc}/upsertBankMaintenanceRequest`);
export const upsertBankMaintenanceSuccess = createAction<BankMaintenance>(`${Service.balanceSvc}/upsertBankMaintenanceSuccess`);
export const upsertBankMaintenanceFailure = createAction<BankMaintenance | string>(`${Service.balanceSvc}/upsertBankMaintenanceFailure`);

export const deleteBankMaintenanceRequest = createAction<string>(`${Service.balanceSvc}/deleteBankMaintenanceRequest`);
export const deleteBankMaintenanceSuccess = createAction(`${Service.balanceSvc}/deleteBankMaintenanceSuccess`);
export const deleteBankMaintenanceFailure = createAction<string>(`${Service.balanceSvc}/deleteBankMaintenanceFailure`);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeDeliveryPaymentOptionQuery } from '@/pages/DeliveryPaymentOption/hooks';
import { DeliveryPaymentOptionInterface, DeliveryPaymentOptionFormInterface, DeliveryPaymentOptionState } from '@/pages/DeliveryPaymentOption/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

const initialState: DeliveryPaymentOptionState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
};

const deliveryPaymentOptionSlice = createSlice({
  name: 'deliveryPaymentOption',
  initialState,
  reducers: {
    fetchDeliveryPaymentOptionListRequest: {
      prepare: (search: ReturnType<typeof sanitizeDeliveryPaymentOptionQuery>) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDeliveryPaymentOptionListSuccess: {
      prepare: (payload: ListResponse<DeliveryPaymentOptionInterface>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<DeliveryPaymentOptionInterface>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchDeliveryPaymentOptionListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchDeliveryPaymentOptionDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDeliveryPaymentOptionDetailSuccess: {
      prepare: (payload: DetailResponse<DeliveryPaymentOptionInterface>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<DeliveryPaymentOptionInterface>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchDeliveryPaymentOptionDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createDeliveryPaymentOptionRequest: {
      prepare: (data: DeliveryPaymentOptionFormInterface) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createDeliveryPaymentOptionSuccess: {
      prepare: (payload: DeliveryPaymentOptionInterface) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createDeliveryPaymentOptionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateDeliveryPaymentOptionRequest: {
      prepare: (id: string, data: DeliveryPaymentOptionFormInterface) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateDeliveryPaymentOptionSuccess: {
      prepare: (payload: DeliveryPaymentOptionInterface) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateDeliveryPaymentOptionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchDeliveryPaymentOptionListRequest,
  fetchDeliveryPaymentOptionListSuccess,
  fetchDeliveryPaymentOptionListFailure,
  fetchDeliveryPaymentOptionDetailRequest,
  fetchDeliveryPaymentOptionDetailSuccess,
  fetchDeliveryPaymentOptionDetailFailure,
  createDeliveryPaymentOptionRequest,
  createDeliveryPaymentOptionSuccess,
  createDeliveryPaymentOptionFailure,
  updateDeliveryPaymentOptionRequest,
  updateDeliveryPaymentOptionSuccess,
  updateDeliveryPaymentOptionFailure,
} = deliveryPaymentOptionSlice.actions;

export default deliveryPaymentOptionSlice.reducer;

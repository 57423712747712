import { ListResponse } from '@tada/tada-web-common';

import { OtpWhitelist, OtpWhitelistListQueryInterface } from '@/pages/OtpWhitelist/types';
import { formatSWRData, useSWRList } from '@/utils/request/hooks';

export const useOtpWhitelistList = (query: OtpWhitelistListQueryInterface, skip: boolean) => {
  const { data, error, mutate } = useSWRList<ListResponse<OtpWhitelist>>('/accountsvc/admin/otp-whitelist', { query, skip });

  return { data: formatSWRData(data, error), mutate };
};

import { createAction } from '@reduxjs/toolkit';

import { TopicDisplayOrderDto } from '@/pages/FaqTopicManagement/type';
import { ReportQuestionDto, ReportQuestionUpsertDto } from '@/pages/HelpCenterListManagement/type';
import { ReportTopicDto, ReportTopicUpsertDto } from '@/pages/HelpCenterTopicManagement/type';
import Service from '@/svc/serviceName';

const helpCenterPrefix = `${Service.csSvc}/helpCenterManagement`;

export const deleteHelpCenterTopicRequest = createAction<number>(`${helpCenterPrefix}/deleteHelpCenterTopicRequest`);
export const deleteHelpCenterTopicSuccess = createAction(`${helpCenterPrefix}/deleteHelpCenterTopicSuccess`);
export const deleteHelpCenterTopicFailure = createAction<string>(`${helpCenterPrefix}/deleteHelpCenterTopicFailure`);
export const upsertHelpCenterTopicRequest = createAction<ReportTopicUpsertDto>(`${helpCenterPrefix}/upsertHelpCenterTopicRequest`);
export const upsertHelpCenterTopicSuccess = createAction<ReportTopicDto>(`${helpCenterPrefix}/upsertHelpCenterTopicSuccess`);
export const upsertHelpCenterTopicFailure = createAction<string>(`${helpCenterPrefix}/upsertHelpCenterTopicFailure`);
export const bulkUpdateDisplayOrderHelpCenterTopicRequest = createAction<TopicDisplayOrderDto[]>(
  `${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterTopicRequest`
);
export const bulkUpdateDisplayOrderHelpCenterTopicSuccess = createAction(`${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterTopicSuccess`);
export const bulkUpdateDisplayOrderHelpCenterTopicFailure = createAction<string>(`${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterTopicFailure`);

export const deleteHelpCenterQuestionRequest = createAction<number>(`${helpCenterPrefix}/deleteHelpCenterQuestionRequest`);
export const deleteHelpCenterQuestionSuccess = createAction(`${helpCenterPrefix}/deleteHelpCenterQuestionSuccess`);
export const deleteHelpCenterQuestionFailure = createAction<string>(`${helpCenterPrefix}/deleteHelpCenterQuestionFailure`);

export const releaseHelpCenterQuestionRequest = createAction<number>(`${helpCenterPrefix}/releaseHelpCenterQuestionRequest`);
export const releaseHelpCenterQuestionSuccess = createAction(`${helpCenterPrefix}/releaseHelpCenterQuestionSuccess`);
export const releaseHelpCenterQuestionFailure = createAction<string>(`${helpCenterPrefix}/releaseHelpCenterQuestionFailure`);

export const rejectHelpCenterQuestionRequest = createAction<number>(`${helpCenterPrefix}/rejectHelpCenterQuestionRequest`);
export const rejectHelpCenterQuestionSuccess = createAction(`${helpCenterPrefix}/rejectHelpCenterQuestionSuccess`);
export const rejectHelpCenterQuestionFailure = createAction<string>(`${helpCenterPrefix}/rejectHelpCenterQuestionFailure`);

export const upsertHelpCenterQuestionRequest = createAction<ReportQuestionUpsertDto>(`${helpCenterPrefix}/upsertHelpCenterQuestionRequest`);
export const upsertHelpCenterQuestionSuccess = createAction<ReportQuestionDto>(`${helpCenterPrefix}/upsertHelpCenterQuestionSuccess`);
export const upsertHelpCenterQuestionFailure = createAction<string>(`${helpCenterPrefix}/upsertHelpCenterQuestionFailure`);

export const bulkUpdateDisplayOrderHelpCenterListRequest = createAction<TopicDisplayOrderDto[]>(
  `${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterListRequest`
);
export const bulkUpdateDisplayOrderHelpCenterListSuccess = createAction(`${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterListSuccess`);
export const bulkUpdateDisplayOrderHelpCenterListFailure = createAction<string>(`${helpCenterPrefix}/bulkUpdateDisplayOrderHelpCenterListFailure`);

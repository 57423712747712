import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createRideProductInfoTemplateFailure,
  createRideProductInfoTemplateRequest,
  createRideProductInfoTemplateSuccess,
  deleteRideProductInfoTemplateFailure,
  deleteRideProductInfoTemplateRequest,
  deleteRideProductInfoTemplateSuccess,
  updateRideProductInfoTemplateFailure,
  updateRideProductInfoTemplateRequest,
  updateRideProductInfoTemplateSuccess,
} from '@/svc/ride-svc/rideProductInfoTemplate/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createRideProductInfoTemplate({ payload }: ReturnType<typeof createRideProductInfoTemplateRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post('/product_information', { data: payload, contentType: 'multipart/form-data' });

    if (res.ok) {
      yield put(createRideProductInfoTemplateSuccess(res.data));
    } else {
      yield put(createRideProductInfoTemplateFailure(res.data.message));
    }
  } catch (e) {
    yield put(createRideProductInfoTemplateFailure((e as Error).message));
  }
}

function* updateRideProductInfoTemplate({ payload: { id, data } }: ReturnType<typeof updateRideProductInfoTemplateRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.put(`/product_information/${id}`, { data, contentType: 'multipart/form-data' });

    if (res.ok) {
      yield put(updateRideProductInfoTemplateSuccess());
    } else {
      yield put(updateRideProductInfoTemplateFailure(res.data.message));
    }
  } catch (e) {
    yield put(updateRideProductInfoTemplateFailure((e as Error).message));
  }
}

function* deleteRideProductInfoTemplate({ payload }: ReturnType<typeof deleteRideProductInfoTemplateRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.delete(`/product_information/${payload}`);

    if (res.ok) {
      yield put(deleteRideProductInfoTemplateSuccess());
    } else {
      yield put(deleteRideProductInfoTemplateFailure(res.data.message));
    }
  } catch (e) {
    yield put(deleteRideProductInfoTemplateFailure((e as Error).message));
  }
}

export default function* rideProductInfoTemplateSaga() {
  yield takeLatest(createRideProductInfoTemplateRequest.type, createRideProductInfoTemplate);
  yield takeLatest(updateRideProductInfoTemplateRequest.type, updateRideProductInfoTemplate);
  yield takeLatest(deleteRideProductInfoTemplateRequest.type, deleteRideProductInfoTemplate);
}

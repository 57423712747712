import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createSurgeCapFailure,
  createSurgeCapRequest,
  createSurgeCapSuccess,
  deleteSurgeCapFailure,
  deleteSurgeCapRequest,
  deleteSurgeCapSuccess,
  updateSurgeCapFailure,
  updateSurgeCapRequest,
  updateSurgeCapSuccess,
} from '@/svc/ride-svc/surgeCap/reducer';
import { authenticatedRequest } from '@/utils/request';

const BASE_PATH = '/surge_caps';

function* createSurgeCap({ payload }: ReturnType<typeof createSurgeCapRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${BASE_PATH}`, { data: payload });

    if (res.ok) {
      yield put(createSurgeCapSuccess());
    } else {
      yield put(createSurgeCapFailure(res.data.message));
    }
  } catch (e) {
    yield put(createSurgeCapFailure((e as Error).message));
  }
}

function* updateSurgeCap({ payload: { id, data } }: ReturnType<typeof updateSurgeCapRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.put(`${BASE_PATH}/${id}`, {
      data,
    });

    if (res.ok) {
      yield put(updateSurgeCapSuccess());
    } else {
      yield put(updateSurgeCapFailure(res.data.message));
    }
  } catch (e) {
    yield put(updateSurgeCapFailure((e as Error).message));
  }
}

function* deleteSurgeCap({ payload }: ReturnType<typeof deleteSurgeCapRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.delete(`${BASE_PATH}/${payload}`);

    if (res.ok) {
      yield put(deleteSurgeCapSuccess());
    } else {
      yield put(deleteSurgeCapFailure(res.data.message));
    }
  } catch (e) {
    yield put(deleteSurgeCapFailure((e as Error).message));
  }
}

export default function* surgeCapSaga() {
  yield takeLatest(createSurgeCapRequest.type, createSurgeCap);
  yield takeLatest(updateSurgeCapRequest.type, updateSurgeCap);
  yield takeLatest(deleteSurgeCapRequest.type, deleteSurgeCap);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "SupportPageManagementList" */ '@/pages/SupportPageManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "SupportPageManagementDetail" */ '@/pages/SupportPageManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "SupportPageManagementNew" */ '@/pages/SupportPageManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "SupportPageManagementEdit" */ '@/pages/SupportPageManagement/Edit'));

export const supportPageManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.SupportPageManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SupportPageManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SupportPageManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.SupportPageManagement}/:id`,
    component: Detail,
    exact: true,
  },
];

import { schema } from 'normalizr';

import { ProductListQuery } from '@/components/InputField/Dropdown/type';
import { SuggestionAdditionalQuery } from '@/components/Suggestion/type';
import { AirportParam } from '@/pages/Airports/type';
import { VehicleMakerListSearchQuery, VehicleModelListSearchQuery } from '@/pages/VehicleMaker/type';
import { authenticatedRequestWithPromise } from '@/utils/request/promise';

export const getContentSchema = (options?: schema.EntityOptions) => {
  const entity = new schema.Entity('content', undefined, options);

  return {
    entity,
    schema: {
      content: [entity],
    },
  };
};

/* Search */
export const searchProduct = (query: ProductListQuery, additionalQuery?: SuggestionAdditionalQuery) =>
  authenticatedRequestWithPromise.get('/products/search', { params: { ...query, ...(additionalQuery ?? {}) } });
export const searchRider = (query: string) => authenticatedRequestWithPromise.get('/admin/riders/-/search', { params: { q: query } });
export const searchDriver = (query: string) => authenticatedRequestWithPromise.get('/admin/drivers/search', { params: { query, withVehicle: true } });
export const searchPromotion = (query: string) => authenticatedRequestWithPromise.get('/promotions/search', { params: { query } });
export const searchCorporate = (query: string) => authenticatedRequestWithPromise.get('/admin/corporates/search', { params: { name: query } });
export const searchZone = (query: string, additionalQuery?: SuggestionAdditionalQuery) =>
  authenticatedRequestWithPromise.get('/zones/search', { params: { query, ...(additionalQuery ?? {}) } });
export const searchRegion = (query: string) => authenticatedRequestWithPromise.get('/regions/search', { params: { query } });
export const searchPolygon = (query?: string, additionalQuery?: SuggestionAdditionalQuery) =>
  authenticatedRequestWithPromise.get('/polygons', { params: { name: query, ...(additionalQuery ?? {}) } });
export const searchScheduleSurgePreset = (query: string, additionalQuery?: SuggestionAdditionalQuery) =>
  authenticatedRequestWithPromise.get('/admin/scheduleSurges/presets:search', { params: { query, ...(additionalQuery ?? {}) } });
export const searchSender = (params: SuggestionAdditionalQuery) => authenticatedRequestWithPromise.get('/delivery/sender', { params });
export const searchPlace = (params: SuggestionAdditionalQuery) => authenticatedRequestWithPromise.get('/places/autocomplete', { params });
export const searchDelivery = (params: SuggestionAdditionalQuery) => authenticatedRequestWithPromise.get('/delivery', { params });
export const searchProductInfo = (params: SuggestionAdditionalQuery) => authenticatedRequestWithPromise.get('/product_information', { params });
export const searchAirport = (params: AirportParam) => authenticatedRequestWithPromise.get('/airports', { params });
export const searchVehicleMaker = (params: VehicleMakerListSearchQuery) => authenticatedRequestWithPromise.get('/admin/vehicle/makers', { params });
export const searchVehicleModel = (params: VehicleModelListSearchQuery) =>
  authenticatedRequestWithPromise.get(`/admin/vehicle/makers/${params.makerId}/models`, { params });
/* Detail */
export const fetchCity = (id: string | number) => authenticatedRequestWithPromise.get(`/cities/${id}`);
export const fetchScheduleSurge = (id: string | number) => authenticatedRequestWithPromise.get(`/admin/scheduleSurges/${id}`);
export const searchDriverDetail = (id: string) => authenticatedRequestWithPromise.get(`/member/drivers/${id}`);
export const searchCorporateDetail = (id: string) => authenticatedRequestWithPromise.get(`/admin/corporates/${id}`);
export const searchRiderDetail = (id: string) => authenticatedRequestWithPromise.get(`/admin/riders/${id}`);
export const searchPromotionDetail = (id: string) => authenticatedRequestWithPromise.get(`/couponsvc/admin/v1/promotions/${id}`);
export const searchZoneDetail = (id: string) => authenticatedRequestWithPromise.get(`/zones/${id}`);
export const searchRegionDetail = (id: string) => authenticatedRequestWithPromise.get(`/regions/${id}`);
export const searchPolygonDetail = (id: string) => authenticatedRequestWithPromise.get(`/polygons/${id}`);
export const searchProductDetail = (id: string) => authenticatedRequestWithPromise.get(`/products/${id}`);
export const searchScheduleSurgePresetDetail = (id: string) => authenticatedRequestWithPromise.get(`/admin/scheduleSurges/presets/${id}`);
export const searchSenderDetail = (id: string) => authenticatedRequestWithPromise.get(`/delivery/sender/${id}`);
export const searchPlaceDetail = (id: string, query?: SuggestionAdditionalQuery) =>
  authenticatedRequestWithPromise.get(`/places/autocomplete/${id}`, { params: query });
export const searchDeliveryDetail = (id: string) => authenticatedRequestWithPromise.get(`/delivery/${id}`);
export const searchProductInfoDetail = (id: string) => authenticatedRequestWithPromise.get(`/product_information/${id}`);
export const searchAirportDetail = (id: string) => authenticatedRequestWithPromise.get(`/airports/${id}`);
export const searchNewsDetail = (id: string) => authenticatedRequestWithPromise.get(`/news/${id}`);
export const searchCouponDetail = (id: string) => authenticatedRequestWithPromise.get(`/couponsvc/admin/v1/coupons/${id}`);
export const searchDriverIncentiveDetail = (id: string) => authenticatedRequestWithPromise.get(`/admin/v1/driver_missions/${id}`);
export const searchVehicleMakerDetail = (id: number) => authenticatedRequestWithPromise.get(`/admin/vehicle/makers/${id}`);
export const searchVehicleModelDetail = (id: number) => authenticatedRequestWithPromise.get(`/admin/vehicle/makers/models/${id}`);

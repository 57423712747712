import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  rejectProfilePictureFailure,
  rejectProfilePictureRequest,
  rejectProfilePictureSuccess,
  approveProfilePicturesFailure,
  approveProfilePicturesRequest,
  approveProfilePicturesSuccess,
} from '@/pages/ProfilePictureChange/reducer';
import { authenticatedRequest } from '@/utils/request';

function* rejectProfilePicture({ payload }: ReturnType<typeof rejectProfilePictureRequest>): IterableIterator {
  try {
    for (let i = 0; i < payload.length; i++) {
      const response = yield authenticatedRequest.post(`/member/drivers/profile-picture-logs/${payload[i]}/reject`);

      if (!response.ok) {
        throw new Error(response.data.message || 'Error Reject Profile Picture!');
      }
    }

    yield put(rejectProfilePictureSuccess());
  } catch (e) {
    yield put(rejectProfilePictureFailure((e as Error).message));
  }
}

function* approveProfilePictures({ payload }: ReturnType<typeof approveProfilePicturesRequest>): IterableIterator {
  try {
    for (let i = 0; i < payload.length; i++) {
      const response = yield authenticatedRequest.post(`/member/drivers/profile-picture-logs/${payload[i]}/approve`);

      if (!response.ok) {
        throw new Error(response.data.message || 'Error Approve Profile Picture!');
      }
    }

    yield put(approveProfilePicturesSuccess());
  } catch (e) {
    yield put(approveProfilePicturesFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(rejectProfilePictureRequest.type, rejectProfilePicture);
  yield takeLatest(approveProfilePicturesRequest.type, approveProfilePictures);
}

import { createSlice } from '@reduxjs/toolkit';

import { DocumentGuideState } from '@/svc/member-svc/documentGuide/type';
import Service from '@/svc/serviceName';

export const documentGuideInitialState: DocumentGuideState = {
  isFetching: false,
};

const documentGuideSlice = createSlice({
  name: `${Service.memberSvc}/documentGuide`,
  initialState: documentGuideInitialState,
  reducers: {
    deleteDocumentGuideRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    deleteDocumentGuideSuccess(state) {
      state.isFetching = false;
    },
    deleteDocumentGuideFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    createDocumentGuideRequest: {
      prepare: (data: FormData) => ({ payload: data }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    createDocumentGuideSuccess(state) {
      state.isFetching = false;
    },
    createDocumentGuideFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    updateDocumentGuideRequest: {
      prepare: (data: FormData, id: string) => ({ payload: { data, id } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    updateDocumentGuideSuccess(state) {
      state.isFetching = false;
    },
    updateDocumentGuideFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
  },
});

export const {
  deleteDocumentGuideRequest,
  deleteDocumentGuideSuccess,
  deleteDocumentGuideFailure,
  createDocumentGuideRequest,
  createDocumentGuideSuccess,
  createDocumentGuideFailure,
  updateDocumentGuideRequest,
  updateDocumentGuideSuccess,
  updateDocumentGuideFailure,
} = documentGuideSlice.actions;

const documentGuideReducer = documentGuideSlice.reducer;
export default documentGuideReducer;

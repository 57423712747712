import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

const PREFIX = `${Service.inboxSvc}/news`;

export const createNewsRequest = createAction<FormData>(`${PREFIX}/createNewsRequest`);
export const createNewsSuccess = createAction(`${PREFIX}/createNewsSuccess`);
export const createNewsFailure = createAction<string>(`${PREFIX}/createNewsFailure`);

export const deleteNewsRequest = createAction<string>(`${PREFIX}/deleteNewsRequest`);
export const deleteNewsSuccess = createAction(`${PREFIX}/deleteNewsSuccess`);
export const deleteNewsFailure = createAction<string>(`${PREFIX}/deleteNewsFailure`);

export const updateNewsRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/updateNewsRequest`);
export const updateNewsSuccess = createAction(`${PREFIX}/updateNewsSuccess`);
export const updateNewsFailure = createAction<string>(`${PREFIX}/updateNewsFailure`);

export const downloadBulkUploadNewsTargetTemplateRequest = createAction(`${PREFIX}/downloadBulkUploadNewsTargetTemplateRequest`);
export const downloadBulkUploadNewsTargetTemplateSuccess = createAction(`${PREFIX}/downloadBulkUploadNewsTargetTemplateSuccess`);
export const downloadBulkUploadNewsTargetTemplateFailure = createAction<string>(`${PREFIX}/downloadBulkUploadNewsTargetTemplateFailure`);

export const bulkUploadNewsTargetsRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/bulkUploadNewsTargetsRequest`);
export const bulkUploadNewsTargetsSuccess = createAction(`${PREFIX}/bulkUploadNewsTargetsSuccess`);
export const bulkUploadNewsTargetsFailure = createAction<string>(`${PREFIX}/bulkUploadNewsTargetsFailure`);

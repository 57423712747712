import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchPhoneVerificationCodeFailure,
  fetchPhoneVerificationCodeRequest,
  fetchPhoneVerificationCodeSuccess,
} from '@/svc/account-svc/phoneVerification/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchPhoneVerificationCode({ payload: phone }: ReturnType<typeof fetchPhoneVerificationCodeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/otp/fetch', { data: { phone } });
    if (response.ok) {
      yield put(fetchPhoneVerificationCodeSuccess(response.data.code));
    } else {
      yield put(fetchPhoneVerificationCodeFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchPhoneVerificationCodeFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchPhoneVerificationCodeRequest.type, fetchPhoneVerificationCode);
}

export const ALIPAY_TRANSACTION_COLUMNS = {
  ID: 'id',
  RENTAL_NUMBER: 'rentalNumber',
  OUT_TRADE_NO: 'outTradeNo',
  STATUS: 'status',
  TOTAL_FEE: 'totalFee',
  APPLICATION_FEE: 'applicationFee',
  REGION_CODE: 'regionCode',
} as const;

export const ALIPAY_TRANSACTION_COLUMNS_LIST = Object.values(ALIPAY_TRANSACTION_COLUMNS);

export const ALIPAY_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: ALIPAY_TRANSACTION_COLUMNS.ID, label: 'ID' },
  { key: ALIPAY_TRANSACTION_COLUMNS.RENTAL_NUMBER, label: 'Ride Id' },
  { key: ALIPAY_TRANSACTION_COLUMNS.OUT_TRADE_NO, label: 'Out Trade No' },
  { key: ALIPAY_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: ALIPAY_TRANSACTION_COLUMNS.TOTAL_FEE, label: 'Total Fee' },
  { key: ALIPAY_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Application Fee' },
  { key: ALIPAY_TRANSACTION_COLUMNS.REGION_CODE, label: 'Region Code' },
];

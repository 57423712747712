import { createAction } from '@reduxjs/toolkit';

import { VehicleMakerDto, VehicleModelDto } from '@/pages/VehicleMaker/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.memberSvc}/vehicleMaker`;

export const createVehicleMakerRequest = createAction<VehicleMakerDto>(`${PREFIX}/createVehicleMakerRequest`);
export const createVehicleMakerSuccess = createAction(`${PREFIX}/createVehicleMakerSuccess`);
export const createVehicleMakerFailure = createAction<string>(`${PREFIX}/createVehicleMakerFailure`);

export const updateVehicleMakerRequest = createAction<{ id: number; data: VehicleMakerDto }>(`${PREFIX}/updateVehicleMakerRequest`);
export const updateVehicleMakerSuccess = createAction(`${PREFIX}/updateVehicleMakerSuccess`);
export const updateVehicleMakerFailure = createAction<string>(`${PREFIX}/updateVehicleMakerFailure`);

export const deleteVehicleMakerRequest = createAction<number>(`${PREFIX}/deleteVehicleMakerRequest`);
export const deleteVehicleMakerSuccess = createAction(`${PREFIX}/deleteVehicleMakerSuccess`);
export const deleteVehicleMakerFailure = createAction<string>(`${PREFIX}/deleteVehicleMakerFailure`);

export const createVehicleModelRequest = createAction<{ makerId: number; data: VehicleModelDto }>(`${PREFIX}/createVehicleModelRequest`);
export const createVehicleModelSuccess = createAction(`${PREFIX}/createVehicleModelSuccess`);
export const createVehicleModelFailure = createAction<string>(`${PREFIX}/createVehicleModelFailure`);

export const createBulkVehicleModelRequest = createAction<{ makerId: number; data: FormData }>(`${PREFIX}/createBulkVehicleModelRequest`);
export const createBulkVehicleModelSuccess = createAction(`${PREFIX}/createBulkVehicleModelSuccess`);
export const createBulkVehicleModelFailure = createAction<string>(`${PREFIX}/createBulkVehicleModelFailure`);

export const fetchBulkVehicleModelTemplateRequest = createAction(`${PREFIX}/fetchBulkVehicleModelTemplateRequest`);
export const fetchBulkVehicleModelTemplateSuccess = createAction(`${PREFIX}/fetchBulkVehicleModelTemplateSuccess`);
export const fetchBulkVehicleModelTemplateFailure = createAction<string>(`${PREFIX}/fetchBulkVehicleModelTemplateFailure`);

export const exportVehicleModelRequest = createAction<string>(`${PREFIX}/exportVehicleModelRequest`);
export const exportVehicleModelSuccess = createAction(`${PREFIX}/exportVehicleModelSuccess`);
export const exportVehicleModelFailure = createAction<string>(`${PREFIX}/exportVehicleModelFailure`);

export const updateVehicleModelRequest = createAction<{ modelId: number; data: VehicleModelDto }>(`${PREFIX}/updateVehicleModelRequest`);
export const updateVehicleModelSuccess = createAction(`${PREFIX}/updateVehicleModelSuccess`);
export const updateVehicleModelFailure = createAction<string>(`${PREFIX}/updateVehicleModelFailure`);

export const deleteVehicleModelRequest = createAction<number>(`${PREFIX}/deleteVehicleModelRequest`);
export const deleteVehicleModelSuccess = createAction(`${PREFIX}/deleteVehicleModelSuccess`);
export const deleteVehicleModelFailure = createAction<string>(`${PREFIX}/deleteVehicleModelFailure`);

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Detail = lazy(() => import(/* webpackChunkName: "DynamicSurgeMonitorDetail" */ '@/pages/DynamicSurgeMonitor/Detail'));

export const dynamicSurgeMonitorRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DynamicSurgeMonitor,
    component: Detail,
    exact: true,
  },
];

import { ModalHeader as ModalHeaderComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface ModalHeaderProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
  wrapTag?: React.ElementType;
  toggle?: React.MouseEventHandler<any>;
}

export function ModalHeader(props: ModalHeaderProps) {
  return <ModalHeaderComponent {...props} />;
}

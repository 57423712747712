import { PATH } from '@/assets/constants/path';
import { useToolkitActionToast } from '@/hooks/toast';
import Form from '@/pages/CpfContributionTable/Form';
import { sanitizeCorporateCpfContributionTable, transformToFormData } from '@/pages/CpfContributionTable/hooks';
import { CpfContributionTableBody } from '@/pages/CpfContributionTable/type';
import { dispatch } from '@/store';
import { createCpfContributionTableRequest } from '@/svc/balance-svc/cpfContributionTable/reducer';

function New() {
  const onSubmit = (data: CpfContributionTableBody, file: File) => {
    dispatch(createCpfContributionTableRequest(transformToFormData(sanitizeCorporateCpfContributionTable(data, file))));
  };

  useToolkitActionToast(createCpfContributionTableRequest.type, {
    successMessage: 'Create Successfully!',
    successRedirect: ({ payload }) => `${PATH.CpfContributionTable}/${payload}`,
  });

  return <Form type='new' onSubmit={onSubmit} />;
}

export default New;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createVehicleMakerRequest,
  createVehicleMakerSuccess,
  createVehicleMakerFailure,
  updateVehicleMakerRequest,
  updateVehicleMakerSuccess,
  updateVehicleMakerFailure,
  deleteVehicleMakerRequest,
  deleteVehicleMakerSuccess,
  deleteVehicleMakerFailure,
  createVehicleModelRequest,
  createVehicleModelSuccess,
  createVehicleModelFailure,
  createBulkVehicleModelRequest,
  createBulkVehicleModelSuccess,
  createBulkVehicleModelFailure,
  exportVehicleModelRequest,
  exportVehicleModelSuccess,
  exportVehicleModelFailure,
  updateVehicleModelRequest,
  updateVehicleModelSuccess,
  updateVehicleModelFailure,
  deleteVehicleModelRequest,
  deleteVehicleModelSuccess,
  deleteVehicleModelFailure,
  fetchBulkVehicleModelTemplateRequest,
  fetchBulkVehicleModelTemplateSuccess,
  fetchBulkVehicleModelTemplateFailure,
} from '@/svc/member-svc/vehicleMaker/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const BASE_PATH = '/admin/vehicle/makers';

function* createVehicleMaker({ payload }: ReturnType<typeof createVehicleMakerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}`, { data: payload });

    const action = response.ok ? createVehicleMakerSuccess() : createVehicleMakerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createVehicleMakerFailure((e as Error).message));
  }
}

function* updateVehicleMaker({ payload: { id, data } }: ReturnType<typeof updateVehicleMakerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}`, { data });

    const action = response.ok ? updateVehicleMakerSuccess() : updateVehicleMakerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateVehicleMakerFailure((e as Error).message));
  }
}

function* deleteVehicleMaker({ payload }: ReturnType<typeof deleteVehicleMakerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_PATH}/${payload}`);

    const action = response.ok ? deleteVehicleMakerSuccess() : deleteVehicleMakerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteVehicleMakerFailure((e as Error).message));
  }
}

function* createVehicleModel({ payload: { makerId, data } }: ReturnType<typeof createVehicleModelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${makerId}/models`, { data });

    const action = response.ok ? createVehicleModelSuccess() : createVehicleModelFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createVehicleModelFailure((e as Error).message));
  }
}

function* createBulkVehicleModel({ payload: { makerId, data } }: ReturnType<typeof createBulkVehicleModelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${makerId}/models:bulk`, { data, contentType: 'multipart/form-data' });

    const action = response.ok ? createBulkVehicleModelSuccess() : createBulkVehicleModelFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createBulkVehicleModelFailure((e as Error).message));
  }
}

function* fetchBulkVehicleModelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/models:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response, 'vehicle_model_template.xlsx');
      yield put(fetchBulkVehicleModelTemplateSuccess());
    } else {
      yield put(fetchBulkVehicleModelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchBulkVehicleModelTemplateFailure((e as Error).message));
  }
}

function* exportVehicleModel({ payload }: ReturnType<typeof exportVehicleModelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/${payload}/models:export`, {
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response, 'vehicle_model.xlsx');
      yield put(exportVehicleModelSuccess());
    } else {
      yield put(exportVehicleModelFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportVehicleModelFailure((e as Error).message));
  }
}

function* updateVehicleModel({ payload: { modelId, data } }: ReturnType<typeof updateVehicleModelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/models/${modelId}`, { data });

    const action = response.ok ? updateVehicleModelSuccess() : updateVehicleModelFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateVehicleModelFailure((e as Error).message));
  }
}

function* deleteVehicleModel({ payload }: ReturnType<typeof deleteVehicleModelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_PATH}/models/${payload}`);

    const action = response.ok ? deleteVehicleModelSuccess() : deleteVehicleModelFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteVehicleModelFailure((e as Error).message));
  }
}

export default function* vehicleMakerSaga() {
  yield takeLatest(createVehicleMakerRequest.type, createVehicleMaker);
  yield takeLatest(updateVehicleMakerRequest.type, updateVehicleMaker);
  yield takeLatest(deleteVehicleMakerRequest.type, deleteVehicleMaker);
  yield takeLatest(createVehicleModelRequest.type, createVehicleModel);
  yield takeLatest(createBulkVehicleModelRequest.type, createBulkVehicleModel);
  yield takeLatest(fetchBulkVehicleModelTemplateRequest.type, fetchBulkVehicleModelTemplate);
  yield takeLatest(exportVehicleModelRequest.type, exportVehicleModel);
  yield takeLatest(updateVehicleModelRequest.type, updateVehicleModel);
  yield takeLatest(deleteVehicleModelRequest.type, deleteVehicleModel);
}

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAdminDetailRequest,
  fetchAdminDetailSuccess,
  fetchAdminDetailFailure,
  createAdminRequest,
  createAdminSuccess,
  createAdminFailure,
  updateAdminRequest,
  updateAdminSuccess,
  updateAdminFailure,
  deleteAdminRequest,
  deleteAdminSuccess,
  deleteAdminFailure,
  blockAdminRequest,
  blockAdminSuccess,
  blockAdminFailure,
} from '@/pages/Admin/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchAdminDetail({ payload }: ReturnType<typeof fetchAdminDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/v1/admins/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchAdminDetailSuccess(response.data));
    } else {
      yield put(fetchAdminDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAdminDetailFailure((e as Error).message));
  }
}

function* createAdmin({ payload }: ReturnType<typeof createAdminRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admins', { data: payload });

    if (response.ok) {
      yield put(createAdminSuccess(response.data));
    } else {
      yield put(createAdminFailure(response.data.message));
    }
  } catch (e) {
    yield put(createAdminFailure((e as Error).message));
  }
}

function* updateAdmin({ payload }: ReturnType<typeof updateAdminRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/admins/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateAdminSuccess(response.data));
    } else {
      yield put(updateAdminFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateAdminFailure((e as Error).message));
  }
}

function* deleteAdmin({ payload }: ReturnType<typeof deleteAdminRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/v1/admins/${payload}`);

    if (response.ok) {
      yield put(deleteAdminSuccess(response.data));
    } else {
      yield put(deleteAdminFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteAdminFailure((e as Error).message));
  }
}

function* blockAdmin({ payload }: ReturnType<typeof blockAdminRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/v1/admins/${payload.id}/blocked`, { data: payload.data });

    if (response.ok) {
      yield put(blockAdminSuccess(response.data));
    } else {
      yield put(blockAdminFailure(response.data.message));
    }
  } catch (e) {
    yield put(blockAdminFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchAdminDetailRequest.type, fetchAdminDetail);
  yield takeLatest(createAdminRequest.type, createAdmin);
  yield takeLatest(updateAdminRequest.type, updateAdmin);
  yield takeLatest(deleteAdminRequest.type, deleteAdmin);
  yield takeLatest(blockAdminRequest.type, blockAdmin);
}

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAreaDetailRequest,
  fetchAreaDetailSuccess,
  fetchAreaDetailFailure,
  createAreaRequest,
  createAreaSuccess,
  createAreaFailure,
  updateAreaRequest,
  updateAreaSuccess,
  updateAreaFailure,
  deleteAreaRequest,
  deleteAreaSuccess,
  deleteAreaFailure,
  reloadLocMgrRequest,
  reloadLocMgrSuccess,
  reloadLocMgrFailure,
} from '@/pages/Areas/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

const postOptions = { schema: entity };

function* fetchAreaDetail({ payload }: ReturnType<typeof fetchAreaDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/areas/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchAreaDetailSuccess(response.data));
    } else {
      yield put(fetchAreaDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAreaDetailFailure((e as Error).message));
  }
}

function* createArea({ payload }: ReturnType<typeof createAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/areas', { data: payload, ...postOptions });

    if (response.ok) {
      yield put(createAreaSuccess(response.data));
    } else {
      yield put(createAreaFailure(response.data.message));
    }
  } catch (e) {
    yield put(createAreaFailure((e as Error).message));
  }
}

function* updateArea({ payload }: ReturnType<typeof updateAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/areas/${payload.id}`, { data: payload.data, ...postOptions });

    if (response.ok) {
      yield put(updateAreaSuccess(response.data));
    } else {
      yield put(updateAreaFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateAreaFailure((e as Error).message));
  }
}

function* deleteArea({ payload }: ReturnType<typeof deleteAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/areas/${payload}`);
    if (response.ok) {
      yield put(deleteAreaSuccess());
    } else {
      yield put(deleteAreaFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteAreaFailure((e as Error).message));
  }
}

function* reloadLocMgr(): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/areas/reload_location_manager');

    if (response.ok) {
      yield put(reloadLocMgrSuccess());
    } else {
      yield put(reloadLocMgrFailure(response.data.message));
    }
  } catch (e) {
    yield put(reloadLocMgrFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchAreaDetailRequest, fetchAreaDetail);
  yield takeLatest(createAreaRequest, createArea);
  yield takeLatest(updateAreaRequest, updateArea);
  yield takeLatest(deleteAreaRequest, deleteArea);
  yield takeLatest(reloadLocMgrRequest, reloadLocMgr);
}

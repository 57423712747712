import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  banHourlyRentalFailure,
  banHourlyRentalRequest,
  banHourlyRentalSuccess,
  syncHourlyRentalFailure,
  syncHourlyRentalRequest,
  syncHourlyRentalSuccess,
} from '@/svc/member-svc/hourlyRentalRequest/reducer';
import { authenticatedRequest } from '@/utils/request';

function* banHourlyRental({ payload: { id, data } }: ReturnType<typeof banHourlyRentalRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/rental-schedule/${id}/ban`, {
      data,
    });

    if (response.ok) {
      yield put(banHourlyRentalSuccess());
    } else {
      yield put(banHourlyRentalFailure(response.data.message));
    }
  } catch (e) {
    yield put(banHourlyRentalFailure((e as Error).message));
  }
}

function* syncHourlyRental({ payload }: ReturnType<typeof syncHourlyRentalRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/rental-schedule/refresh?driverUuid=${payload}`);

    if (response.ok) {
      yield put(syncHourlyRentalSuccess());
    } else {
      yield put(syncHourlyRentalFailure(response.data.message));
    }
  } catch (e) {
    yield put(syncHourlyRentalFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(banHourlyRentalRequest.type, banHourlyRental);
  yield takeLatest(syncHourlyRentalRequest.type, syncHourlyRental);
}

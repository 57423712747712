import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DispatchConfigList" */ '@/pages/DispatchConfig/List'));
const New = lazy(() => import(/* webpackChunkName: "DispatchConfigNew" */ '@/pages/DispatchConfig/New'));
const Edit = lazy(() => import(/* webpackChunkName: "DispatchConfigDetail" */ '@/pages/DispatchConfig/Edit'));

export const dispatchConfigRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DispatchConfig,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DispatchConfig}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DispatchConfig}/:id([0-9]*)`,
    exact: true,
    component: Edit,
  },
];

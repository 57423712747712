import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';

import { DeliveryInterface, GenerateDeliveryRideCreate } from '@/pages/Delivery/type';
import { GeneratedRideDetail } from '@/pages/DeliveryDashboard/type';
import { DeliveryRideResponse, DeliveryRideScheduleRequestDto, RemakeFormInterface } from '@/pages/DeliveryRide/type';
import { ChatMessage, RideItemQuery, RidePriceForm } from '@/pages/Ride/types';
import { Branch } from '@/svc/corporate-svc/type';
import { Ride, RideWithDelivery, LegacyChatMessage, RideResponse, PushLog, RideCancelBody, MatchResult } from '@/svc/ride-svc/type';
import { updateWaitingFeeStatusSuccess } from '@/svc/ride-svc/waitingFee/reducer';
import { listSuccess } from '@/utils/reducerUtils';
import { ListResponse, originListResponse, PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface RideState extends PaginationStoreState, StandardStoreState {
  deliveryData: DeliveryRideResponse | null;
  branches: Branch[];
  exportingDeliveryRides: boolean;
  isSchedulingRide: boolean;
  isGeneratingDeliveryRide: boolean;
}

const initialState: RideState = {
  page: { total: 1, current: 1, limit: 1 },
  ids: [],
  byId: {},
  totalCount: 0,

  deliveryData: null,
  branches: [],
  error: null,
  isFetching: false,
  exportingDeliveryRides: false,
  isSchedulingRide: false,
  isGeneratingDeliveryRide: false,
};

const detailSuccess = (state: RideState, { payload }: PayloadAction<Ride>) => ({
  ...state,
  byId: { ...state.byId, [payload.id]: { ...state.byId[payload.id], ...payload } },
});

const failure = (state: RideState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

const schedulingRideRequest = (state: RideState) => ({
  ...state,
  isSchedulingRide: true,
  error: null,
});

const schedulingRideSuccess = (state: RideState) => ({
  ...state,
  isSchedulingRide: false,
});

const schedulingRideFailure = (state: RideState, { payload }: PayloadAction<string>) => ({
  ...state,
  isSchedulingRide: false,
  error: payload,
});

export const updateRideStatusRequest = createAction('ride/updateRideStatusRequest', (id: string | number, body) => ({
  payload: { id, body },
}));
export const updateRideStatusSuccess = createAction('ride/updateRideStatusSuccess', (param: Ride) => ({ payload: param }));
export const updateRideStatusFailure = createAction('ride/updateRideStatusFailure', (error: string) => ({
  payload: error,
}));

export const updatePayStatusRequest = createAction('ride/updatePayStatusRequest', (id: string | number, body) => ({
  payload: { id, body },
}));
export const updatePayStatusSuccess = createAction('ride/updatePayStatusSuccess', (param: Ride) => ({ payload: param }));
export const updatePayStatusFailure = createAction('ride/updatePayStatusFailure', (error: string) => ({
  payload: error,
}));

export const generateBalanceItemRequest = createAction('ride/generateBalanceItemRequest', (id: string | number) => ({
  payload: id,
}));
export const generateBalanceItemSuccess = createAction('ride/generateBalanceItemSuccess');
export const generateBalanceItemFailure = createAction('ride/generateBalanceItemFailure', (error: string) => ({
  payload: error,
}));

export const mapMatchingSimulationRequest = createAction('ride/mapMatchingSimulationRequest', (id: string | number) => ({
  payload: id,
}));
export const mapMatchingSimulationSuccess = createAction('ride/mapMatchingSimulationSuccess', (param: MatchResult) => ({
  payload: param,
}));
export const mapMatchingSimulationFailure = createAction('ride/mapMatchingSimulationFailure', (error: string) => ({
  payload: error,
}));

export const sendReceiptRequest = createAction('ride/sendReceiptRequest', (id: string | number, body?: { email: string }) => ({
  payload: { id, body },
}));
export const sendReceiptSuccess = createAction('ride/sendReceiptSuccess', (param: { ok: boolean }) => ({ payload: param }));
export const sendReceiptFailure = createAction('ride/sendReceiptFailure', (error: string) => ({ payload: error }));

export const assignRideRequest = createAction('ride/assignRideRequest', (id: string, body: { driverUuid: string }) => ({
  payload: { id, body },
}));
export const assignRideFailure = createAction('ride/assignRideFailure', (error: string) => ({ payload: error }));

export const dispatchRideRequest = createAction('ride/dispatchRideRequest', (id: string) => ({ payload: id }));
export const dispatchRideFailure = createAction('ride/dispatchRideFailure', (error: string) => ({ payload: error }));

export const fetchDeliveryDataRequest = createAction('ride/fetchDeliveryDataRequest', (id: string, query: { withPaymentGroup: boolean }) => ({
  payload: { id, query },
}));
export const fetchDeliveryDataFailure = createAction('ride/fetchDeliveryDataFailure', (error: string) => ({ payload: error }));

export const clearDeliveryDepositRequest = createAction('ride/clearDeliveryDepositRequest', (id: string) => ({ payload: id }));
export const clearDeliveryDepositFailure = createAction('ride/clearDeliveryDepositFailure', (error: string) => ({
  payload: error,
}));

export const regenerateDeliveryRideRequest = createAction('ride/regenerateDeliveryRideRequest', (id: string, body: RemakeFormInterface) => ({
  payload: { id, body },
}));
export const regenerateDeliveryRideSuccess = createAction('ride/regenerateDeliveryRideSuccess', (param: DeliveryRideResponse) => ({
  payload: param,
}));
export const regenerateDeliveryRideFailure = createAction('ride/regenerateDeliveryRideFailure', (error: string) => ({
  payload: error,
}));

export const cancelRideRequest = createAction('ride/cancelRideRequest', (id: string, body: RideCancelBody) => ({
  payload: { id, body },
}));
export const cancelRideSuccess = createAction('ride/cancelRideSuccess', (param: Ride) => ({ payload: param }));
export const cancelRideFailure = createAction('ride/cancelRideFailure', (error: string) => ({ payload: error }));

export const fetchGeneratedRideByIdsRequest = createAction('ride/fetchGeneratedRideByIdsRequest', (ids: string[]) => ({
  payload: ids,
}));
export const fetchGeneratedRideByIdsFailure = createAction('ride/fetchGeneratedRideByIdsFailure', (error: string) => ({
  payload: error,
}));

export const fetchBranchesRequest = createAction('ride/fetchBranchesRequest', (search: number) => ({ payload: search }));
export const fetchBranchesFailure = createAction('ride/fetchBranchesFailure', (error: string) => ({ payload: error }));

export const removeFromCrRequest = createAction('ride/removeFromCrRequest', (id: string | number) => ({ payload: id }));
export const removeFromCrSuccess = createAction('ride/removeFromCrSuccess');
export const removeFromCrFailure = createAction('ride/removeFromCrFailure', (error: string) => ({ payload: error }));

export const releaseDeliveryRequest = createAction('ride/releaseDeliveryRequest', (id: string) => ({ payload: id }));
export const releaseDeliverySuccess = createAction('ride/releaseDeliverySuccess', (param: DeliveryRideResponse) => ({
  payload: param,
}));
export const releaseDeliveryFailure = createAction('ride/releaseDeliveryFailure', (error: string) => ({ payload: error }));

export const forceRemoveDeliveryFromRideRequest = createAction('ride/forceRemoveDeliveryFromRideRequest', (rideId: string, deliveryId: string) => ({
  payload: { rideId, deliveryId },
}));
export const forceRemoveDeliveryFromRideSuccess = createAction('ride/forceRemoveDeliveryFromRideSuccess', (param: DeliveryRideResponse) => ({
  payload: param,
}));
export const forceRemoveDeliveryFromRideFailure = createAction('ride/forceRemoveDeliveryFromRideFailure', (error: string) => ({
  payload: error,
}));

export const forceFinishDeliveryFromRideRequest = createAction('ride/forceFinishDeliveryFromRideRequest', (id: string) => ({
  payload: id,
}));
export const forceFinishDeliveryFromRideSuccess = createAction('ride/forceFinishDeliveryFromRideSuccess', (param: DeliveryInterface) => ({
  payload: param,
}));
export const forceFinishDeliveryFromRideFailure = createAction('ride/forceFinishDeliveryFromRideFailure', (error: string) => ({
  payload: error,
}));

export const forceReturnDeliveryFromRideRequest = createAction('ride/forceReturnDeliveryFromRideRequest', (id: string) => ({
  payload: id,
}));
export const forceReturnDeliveryFromRideSuccess = createAction('ride/forceReturnDeliveryFromRideSuccess', (param: DeliveryInterface) => ({
  payload: param,
}));
export const forceReturnDeliveryFromRideFailure = createAction('ride/forceReturnDeliveryFromRideFailure', (error: string) => ({
  payload: error,
}));

export const updateRidePriceRequest = createAction('ride/updateRidePriceRequest', (id: string, body: RidePriceForm) => ({
  payload: { id, body },
}));
export const updateRidePriceSuccess = createAction('ride/updateRidePriceSuccess', (param: Ride) => ({ payload: param }));
export const updateRidePriceFailure = createAction('ride/updateRidePriceFailure', (error: string) => ({ payload: error }));

export const fetchMobilePushLogsRequest = createAction('ride/fetchMobilePushLogsRequest', (id: string | number) => ({
  payload: id,
}));
export const fetchMobilePushLogsFailure = createAction('ride/fetchMobilePushLogsFailure', (error: string) => ({
  payload: error,
}));

export const fetchChatLogsRequest = createAction('ride/fetchChatLogsRequest', (id: string | number) => ({ payload: id }));
export const fetchChatLogsFailure = createAction('ride/fetchChatLogsFailure', (error: string) => ({ payload: error }));

export const cancelFinishedRideRequest = createAction('ride/cancelFinishedRideRequest', (id: string) => ({
  payload: { id },
}));
export const cancelFinishedRideSuccess = createAction('ride/cancelFinishedRideSuccess');
export const cancelFinishedRideFailure = createAction<string>('ride/cancelFinishedRideFailure');

export const removeRidePenaltyFeeRequest = createAction('ride/removeRidePenaltyFeeRequest', (data: { id: string; reason: string }) => ({
  payload: data,
}));
export const removeRidePenaltyFeeSuccess = createAction('ride/removeRidePenaltyFeeSuccess');
export const removeRidePenaltyFeeFailure = createAction<string>('ride/removeRidePenaltyFeeFailure');

export const rollbackRidePenaltyFeeRequest = createAction<string>('ride/rollbackRidePenaltyFeeRequest');
export const rollbackRidePenaltyFeeSuccess = createAction('ride/rollbackRidePenaltyFeeSuccess');
export const rollbackRidePenaltyFeeFailure = createAction<string>('ride/rollbackRidePenaltyFeeFailure');

const rideSlice = createSlice({
  name: 'ride',
  initialState,
  reducers: {
    fetchRideListRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (param: RideItemQuery) => ({ payload: param }),
    },
    fetchRideListSuccess(state, payload: PayloadAction<originListResponse<Ride>>) {
      Object.assign(state, listSuccess(state, payload));
    },
    fetchRideListFailure: failure,

    fetchRestrictRideListRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (param: RideItemQuery) => ({ payload: param }),
    },
    fetchRestrictRideListSuccess(state, { payload }: PayloadAction<ListResponse<Ride>>) {
      state.ids = payload.result.pageable.pageNumber === 0 ? payload.result.content : [...state.ids, ...payload.result.content];
      state.byId = { ...state.byId, ...payload.entities.content };

      state.isFetching = false;
      state.error = null;

      state.page = {
        total: payload.result.totalPages,
        current: payload.result.pageable.pageNumber + 1,
        limit: payload.result.size,
      };

      state.totalCount = payload.result.totalElements;
    },
    fetchRestrictRideListFailure: failure,

    fetchRideDetailRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id: string | number | null) => ({ payload: id }),
    },
    fetchRideDetailSuccess(state, { payload }: { payload: RideResponse }) {
      state.isFetching = false;
      state.error = null;
      state.byId[payload.ride.id] = {
        ...state.byId[payload.ride.id],
        ..._.omit(payload, ['ride']),
        ...payload.ride,
      };
    },
    fetchRideDetailFailure: failure,

    updateRideRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id, body) => ({ payload: { id, body } }),
    },
    updateRideSuccess(state, { payload }: PayloadAction<Ride>) {
      state.isFetching = false;
      state.error = null;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },
    updateRideFailure: failure,

    // search
    exportDeliveryRideRequest: {
      reducer: (state) => {
        state.exportingDeliveryRides = true;
      },
      prepare: (payload: any) => ({ payload }),
    },

    exportDeliveryRideSuccess(state) {
      state.exportingDeliveryRides = false;
    },

    exportDeliveryRideFailure(state, { payload }: PayloadAction<string>) {
      state.exportingDeliveryRides = false;
      state.error = payload;
    },

    assignRideSuccess(state, payload: PayloadAction<Ride>) {
      Object.assign(state, detailSuccess(state, payload));
    },

    dispatchRideSuccess(state, payload: PayloadAction<Ride>) {
      Object.assign(state, detailSuccess(state, payload));
    },

    // body
    scheduleGeneratePickupRideRequest: {
      reducer: (state) => {
        Object.assign(state, schedulingRideRequest(state));
      },
      prepare: (payload: PayloadAction<DeliveryRideScheduleRequestDto>) => ({ payload }),
    },
    scheduleGeneratePickupRideSuccess(state) {
      Object.assign(state, schedulingRideSuccess(state));
    },
    scheduleGeneratePickupRideFailure(state, payload: PayloadAction<string>) {
      Object.assign(state, schedulingRideFailure(state, payload));
    },

    // body
    scheduleGenerateDropoffRideRequest: {
      reducer: (state) => {
        Object.assign(state, schedulingRideRequest(state));
      },
      prepare: (payload: PayloadAction<DeliveryRideScheduleRequestDto>) => ({ payload }),
    },
    scheduleGenerateDropoffRideSuccess(state) {
      Object.assign(state, schedulingRideSuccess(state));
    },
    scheduleGenerateDropoffRideFailure(state, payload: PayloadAction<string>) {
      Object.assign(state, schedulingRideFailure(state, payload));
    },

    // body
    scheduleGenerateReturnRideRequest: {
      reducer: (state) => {
        Object.assign(state, schedulingRideRequest(state));
      },
      prepare: (payload: PayloadAction<DeliveryRideScheduleRequestDto>) => ({ payload }),
    },
    scheduleGenerateReturnRideSuccess(state) {
      Object.assign(state, schedulingRideSuccess(state));
    },
    scheduleGenerateReturnRideFailure(state, payload: PayloadAction<string>) {
      Object.assign(state, schedulingRideFailure(state, payload));
    },

    // body
    scheduleReleaseAssignFailedRideRequest: {
      reducer: (state) => {
        Object.assign(state, schedulingRideRequest(state));
      },
      prepare: (payload: PayloadAction<DeliveryRideScheduleRequestDto>) => ({ payload }),
    },

    scheduleReleaseAssignFailedRideSuccess(state) {
      Object.assign(state, schedulingRideSuccess(state));
    },
    scheduleReleaseAssignFailedRideFailure(state, payload: PayloadAction<string>) {
      Object.assign(state, schedulingRideFailure(state, payload));
    },

    fetchDeliveryDataSuccess(state, { payload }: PayloadAction<DeliveryRideResponse>) {
      state.deliveryData = payload;
    },

    clearDeliveryDepositSuccess(state, { payload }: PayloadAction<DeliveryRideResponse>) {
      state.deliveryData = payload;
    },

    // body
    generateDeliveryRideRequest: {
      reducer: (state) => {
        state.isGeneratingDeliveryRide = true;
      },
      prepare: (payload: PayloadAction<GenerateDeliveryRideCreate>) => ({ payload }),
    },

    generateDeliveryRideSuccess: {
      reducer: (state) => {
        state.isGeneratingDeliveryRide = false;
      },
      prepare: (payload: PayloadAction<DeliveryRideResponse>) => ({ payload }),
    },

    generateDeliveryRideFailure(state, { payload }: PayloadAction<string>) {
      state.isGeneratingDeliveryRide = false;
      state.error = payload;
    },

    fetchGeneratedRideByIdsSuccess(state, { payload }: PayloadAction<GeneratedRideDetail[]>) {
      const byId = payload.reduce((data: Record<string, RideWithDelivery>, ride: GeneratedRideDetail) => {
        const locationFrom = ride.deliveries?.[0]?.locationFromFullAddress ?? null;
        const locationTo = ride.deliveries?.[0]?.locationToFullAddress ?? null;

        data[ride.id] = {
          ...data[ride.id],
          locationFrom,
          locationTo,
          count: ride.deliveryCount,
          deliveryPriority: ride.deliveryPriority,
          //@ts-ignore
          deliveryPhase: ride?.deliveryPhase,
          deliveries: ride.deliveries,
        };

        return data;
      }, state.byId);

      state.byId = byId;
    },

    fetchBranchesSuccess(state, { payload }: PayloadAction<Branch[]>) {
      state.branches = payload;
    },

    fetchMobilePushLogsSuccess(state, { payload: { id, data } }: PayloadAction<{ id: string | number; data: PushLog[] }>) {
      state.byId[id].mobilePushLogs = data;
    },

    fetchChatLogsSuccess(state, { payload: { id, data } }: PayloadAction<{ id: string | number; data: originListResponse<ChatMessage> }>) {
      const { driverInfo } = state.byId[id];

      const chatMessages: { messages?: LegacyChatMessage[] } = {};

      if ((data as originListResponse<ChatMessage>).content.length !== 0) {
        // Chat Svc Response
        chatMessages.messages = (data as originListResponse<ChatMessage>).content
          .sort((a: ChatMessage, b: ChatMessage) => Number(moment(a.createdAt).format('X')) - Number(moment(b.createdAt).format('X')))
          .map((message: ChatMessage) => ({
            type: message.fromUserId === driverInfo?.uuid ? 'D' : 'U',
            m: message.message,
            t: +moment(message.createdAt),
          }));
      }

      state.byId[id].chatMessages = chatMessages;
    },

    removeRideData(state) {
      state.byId = {};
      state.ids = [];
      state.error = null;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<typeof updateWaitingFeeStatusSuccess.type, ReturnType<typeof updateWaitingFeeStatusSuccess>>(
      updateWaitingFeeStatusSuccess.type,
      (state, action) => {
        state.byId[action.payload.rideId].waitingFeeLog = action.payload;
      }
    );
  },
});

export const {
  fetchRideListRequest,
  fetchRideListSuccess,
  fetchRideListFailure,
  fetchRestrictRideListRequest,
  fetchRestrictRideListSuccess,
  fetchRestrictRideListFailure,
  fetchRideDetailRequest,
  fetchRideDetailSuccess,
  fetchRideDetailFailure,
  updateRideRequest,
  updateRideSuccess,
  updateRideFailure,

  exportDeliveryRideRequest,
  exportDeliveryRideSuccess,
  exportDeliveryRideFailure,

  assignRideSuccess,

  dispatchRideSuccess,

  scheduleGeneratePickupRideRequest,
  scheduleGeneratePickupRideSuccess,
  scheduleGeneratePickupRideFailure,
  scheduleGenerateDropoffRideRequest,
  scheduleGenerateDropoffRideSuccess,
  scheduleGenerateDropoffRideFailure,
  scheduleGenerateReturnRideRequest,
  scheduleGenerateReturnRideSuccess,
  scheduleGenerateReturnRideFailure,
  scheduleReleaseAssignFailedRideRequest,
  scheduleReleaseAssignFailedRideSuccess,
  scheduleReleaseAssignFailedRideFailure,

  fetchDeliveryDataSuccess,
  clearDeliveryDepositSuccess,

  generateDeliveryRideRequest,
  generateDeliveryRideSuccess,
  generateDeliveryRideFailure,

  fetchGeneratedRideByIdsSuccess,
  fetchBranchesSuccess,
  fetchMobilePushLogsSuccess,
  fetchChatLogsSuccess,
  removeRideData,
} = rideSlice.actions;

export default rideSlice.reducer;

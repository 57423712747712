import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createPrefixCouponRequest,
  createPrefixCouponSuccess,
  createPrefixCouponFailure,
  updatePrefixCouponRequest,
  updatePrefixCouponSuccess,
  updatePrefixCouponFailure,
  deletePrefixCouponRequest,
  deletePrefixCouponSuccess,
  deletePrefixCouponFailure,
  generatePrefixCouponRequest,
  generatePrefixCouponSuccess,
  generatePrefixCouponFailure,
} from '@/svc/coupon-svc/prefixCoupon/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/couponsvc/admin/prefixed_code_generators';

function* createPrefixCoupon({ payload }: ReturnType<typeof createPrefixCouponRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createPrefixCouponSuccess(response.data) : createPrefixCouponFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createPrefixCouponFailure((e as Error).message));
  }
}

function* updatePrefixCoupon({ payload: { id, data } }: ReturnType<typeof updatePrefixCouponRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/prefix/${id}`, { data });

    const action = response.ok ? updatePrefixCouponSuccess(response.data) : updatePrefixCouponFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updatePrefixCouponFailure((e as Error).message));
  }
}

function* deletePrefixCoupon({ payload }: ReturnType<typeof deletePrefixCouponRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${basePath}/prefix/${payload}`);

    const action = response.ok ? deletePrefixCouponSuccess() : deletePrefixCouponFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deletePrefixCouponFailure((e as Error).message));
  }
}

function* generatePrefixCoupon({ payload: { id, data } }: ReturnType<typeof generatePrefixCouponRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}/prefix/${id}/generate`, { data, responseType: 'blob' });

    if (response.ok) {
      saveFile(response, 'coupon-code.csv');
    }

    const action = response.ok ? generatePrefixCouponSuccess() : generatePrefixCouponFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(generatePrefixCouponFailure((e as Error).message));
  }
}

export default function* prefixCouponSaga() {
  yield takeLatest(createPrefixCouponRequest.type, createPrefixCoupon);
  yield takeLatest(updatePrefixCouponRequest.type, updatePrefixCoupon);
  yield takeLatest(deletePrefixCouponRequest.type, deletePrefixCoupon);
  yield takeLatest(generatePrefixCouponRequest.type, generatePrefixCoupon);
}

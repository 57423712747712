import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { constcase } from 'stringcase';

import { checkRoutePermission } from '@/components/CheckPermission';
import nav, { BaseNavItemType, NavItems } from '@/layout/Sidebar/_nav';
import { Permissions } from '@/pages/LoginPage/types';

interface UseSidebarSearchConfig {
  pageStatusValues: NavItems | null;
  permissions: Permissions;
}

export const useSidebarSearch = ({ pageStatusValues, permissions }: UseSidebarSearchConfig) => {
  const [pageName, setPageName] = useState<string>('');
  const [findNavItems, setFindNavItems] = useState<any[]>([]);
  const navs: NavItems = findNavItems.length ? findNavItems : (pageStatusValues ?? nav.items);

  const findMenu = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: pageName } = e.target;

    if (pageName === '') {
      setFindNavItems([]);
      setPageName('');

      return;
    }

    setNavs();
    setPageName(pageName);
  };

  const setNavs = () => {
    const navs = pageStatusValues ?? nav.items;

    const checkNavItem = (item: BaseNavItemType, pageName: string, permissions: Permissions) => {
      return constcase(t(item.name)).indexOf(constcase(pageName)) !== -1 && checkRoutePermission(item, permissions);
    };

    const findNavItems = navs.reduce<BaseNavItemType[]>((data, item) => {
      // item의 name에서 찾은 후 있으면 nav data에 추가
      if (checkNavItem(item, pageName, permissions)) {
        data = [...data, item];
      } else {
        // 없을경우 children의 name과 비교하면서 속해있는 item들을 findnavitem에 추가
        const newItem = item.children?.filter((item) => checkNavItem(item, pageName, permissions)) ?? null;

        if (newItem?.length) {
          data = [...data, { ...item, children: newItem }];
        }
      }

      return data;
    }, []);

    setFindNavItems(findNavItems);
  };

  const handleResetPageItems = () => {
    setPageName('');
    setFindNavItems([]);
  };

  useEffect(() => {
    setNavs();
  }, [pageStatusValues]);

  return { pageName, findNavItems, navs, findMenu, handleResetPageItems };
};

import { all, call } from 'redux-saga/effects';

import faqManagementSaga from '@/svc/cs-svc/faqManagement/saga';
import helpCenterManagementSaga from '@/svc/cs-svc/helpCenterManagement/saga';
import remarkLogSettingSaga from '@/svc/cs-svc/remarkLogSetting/saga';
import safetyAccidentManagementSaga from '@/svc/cs-svc/safetyAccidentManagement/saga';
import supportPageManagementSaga from '@/svc/cs-svc/supportPageManagement/saga';

export default function* defaultSaga() {
  yield all([
    call(faqManagementSaga),
    call(helpCenterManagementSaga),
    call(safetyAccidentManagementSaga),
    call(supportPageManagementSaga),
    call(remarkLogSettingSaga),
  ]);
}

import * as React from 'react';

import Transition, { TransitionChildren } from 'react-transition-group/Transition';

const duration = 300;
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

const FadeInAndOut = ({ children: content, ...props }: React.PropsWithChildren<Omit<React.ComponentProps<typeof Transition>, 'children'>>) => {
  const children = (state: keyof typeof transitionStyles) =>
    (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {content}
      </div>
    ) as TransitionChildren;

  return (
    <Transition {...props} timeout={duration}>
      {/* @ts-ignore */}
      {children}
    </Transition>
  );
};

export default FadeInAndOut;

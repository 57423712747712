import { Label as LabelComponent } from 'reactstrap';

import { ColumnProps, CSSModule } from '@/components/template/type';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  hidden?: boolean;
  check?: boolean;
  size?: string;
  inline?: boolean;
  disabled?: boolean;
  for?: string;
  tag?: React.ElementType;
  cssModule?: CSSModule;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
  lg?: ColumnProps;
  xl?: ColumnProps;

  // custom widths
  widths?: string[];
}

export function Label(props: LabelProps) {
  return <LabelComponent {...props} />;
}

import { Dispatch, MiddlewareAPI } from 'redux';

import { Pages } from '@/assets/constants';
import { ActionType, checkPermission } from '@/components/CheckPermission';
import { fetchInfiniteAdminLogListRequest } from '@/pages/AdminLog/reducer';
import { AppState } from '@/store/reducers';

interface ApiPermissionMapper {
  [key: string]: {
    action: ActionType;
    permission: Pages;
  };
}

const apiPermissionMapper: ApiPermissionMapper = {
  [fetchInfiniteAdminLogListRequest.type]: {
    action: ActionType.READ,
    permission: Pages.AdminLog,
  },
};

const permissionCheckerMiddleware =
  ({ getState }: MiddlewareAPI<any, AppState>) =>
  (next: Dispatch) =>
  (action: any) => {
    const state = getState();
    const { type: actionType } = action;
    const apiPermission = apiPermissionMapper[actionType];

    if (apiPermission && !checkPermission(apiPermission.action, state.auth.permissions[apiPermission.permission])) {
      // Toast Error message
      const error = `${apiPermission.permission} ${apiPermission.action} permission denied. Please inquiry to admin.`;

      // eslint-disable-next-line no-console
      console.log(`%caction %c${actionType}%c is denied, Please check your permission.`, 'color: #666;', 'font-weight: bold; font-size: 12px;', '');

      return next({
        type: actionType.replace(/^(.+)Request$/, '$1Failure').replace(/_REQUEST$/, '_FAILURE'),
        error,
        payload: {
          error,
        },
      });
    }

    return next(action);
  };

export default permissionCheckerMiddleware;

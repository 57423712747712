import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/template';
import { removeRidePenaltyFeeRequest } from '@/pages/Ride/reducer';
import { useAppState } from '@/store/selector';
import { togglePopup } from '@/svc/common/reducer';
import { selectRidePenaltyFeeReasonOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';

function RidePenaltyFeeReasonPopup() {
  const dispatch = useDispatch();

  const open = useAppState(selectRidePenaltyFeeReasonOpenFlag);
  const id = useAppState((state) => state.common.ridePenaltyData.id);

  const [reason, setReason] = useState('');

  const toggle = () => {
    dispatch(togglePopup(POPUP_TYPE.RidePenaltyFeeReason));
  };

  const handleClickRemove = () => {
    dispatch(removeRidePenaltyFeeRequest({ id, reason }));
    toggle();
  };

  useEffect(() => {
    setReason('');
  }, [open]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Selected Schedule Surges</ModalHeader>
      <ModalBody>
        <h5>Penalty Fee Remove Reason</h5>
        <Input type='textarea' value={reason} onChange={(e) => setReason(e.target.value)} placeholder='Reason'></Input>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={handleClickRemove}>
          Remove
        </Button>
        <Button color='primary' onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default RidePenaltyFeeReasonPopup;

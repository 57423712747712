import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  deleteDocumentGuideRequest,
  deleteDocumentGuideSuccess,
  deleteDocumentGuideFailure,
  createDocumentGuideRequest,
  createDocumentGuideSuccess,
  createDocumentGuideFailure,
  updateDocumentGuideRequest,
  updateDocumentGuideSuccess,
  updateDocumentGuideFailure,
} from '@/svc/member-svc/documentGuide/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deleteDocumentGuide({ payload }: ReturnType<typeof deleteDocumentGuideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/document-guide/${payload}`);

    const action = response.ok ? deleteDocumentGuideSuccess() : deleteDocumentGuideFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteDocumentGuideFailure((e as Error).message));
  }
}

function* createDocumentGuide({ payload }: ReturnType<typeof createDocumentGuideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/document-guide`, {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? createDocumentGuideSuccess() : createDocumentGuideFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDocumentGuideFailure((e as Error).message));
  }
}

function* updateDocumentGuide({ payload: { data, id } }: ReturnType<typeof updateDocumentGuideRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/document-guide/${id}`, {
      data,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? updateDocumentGuideSuccess() : updateDocumentGuideFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDocumentGuideFailure((e as Error).message));
  }
}

export default function* documentGuideSaga() {
  yield takeLatest(deleteDocumentGuideRequest.type, deleteDocumentGuide);
  yield takeLatest(createDocumentGuideRequest.type, createDocumentGuide);
  yield takeLatest(updateDocumentGuideRequest.type, updateDocumentGuide);
}

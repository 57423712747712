import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DeliveryTagList" */ '@/pages/DeliveryTag/List'));
const New = lazy(() => import(/* webpackChunkName: "DeliveryTagNew" */ '@/pages/DeliveryTag/New'));
const Detail = lazy(() => import(/* webpackChunkName: "DeliveryTagDetail" */ '@/pages/DeliveryTag/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "DeliveryTagEdit" */ '@/pages/DeliveryTag/Edit'));

export const deliveryTagRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DeliveryTag,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DeliveryTag}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DeliveryTag}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DeliveryTag}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

import styled from 'styled-components';

import * as packageInfo from '../../package.json';

const FooterContainer = styled.footer`
  > * + * {
    margin-left: 10px;
  }
`;

// @ts-ignore
const version = packageInfo.default.version;

function Footer() {
  return (
    <FooterContainer className='app-footer'>
      <span>
        <a href='https://tada.global'>tada</a> &copy; MVL Foundation Pte. Ltd.
      </span>
      <span>Version {version}</span>
    </FooterContainer>
  );
}

export default Footer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  bulkUpdateSafetyAccidentTopicDisplayOrderFailure,
  bulkUpdateSafetyAccidentTopicDisplayOrderRequest,
  bulkUpdateSafetyAccidentTopicDisplayOrderSuccess,
  bulkUploadDisplayOrderSafetyAccidentQuestionFailure,
  bulkUploadDisplayOrderSafetyAccidentQuestionRequest,
  bulkUploadDisplayOrderSafetyAccidentQuestionSuccess,
  deleteSafetyAccidentQuestionFailure,
  deleteSafetyAccidentQuestionRequest,
  deleteSafetyAccidentQuestionSuccess,
  deleteSafetyAccidentTopicFailure,
  deleteSafetyAccidentTopicRequest,
  deleteSafetyAccidentTopicSuccess,
  rejectSafetyAccidentQuestionFailure,
  rejectSafetyAccidentQuestionRequest,
  rejectSafetyAccidentQuestionSuccess,
  releaseSafetyAccidentQuestionFailure,
  releaseSafetyAccidentQuestionRequest,
  releaseSafetyAccidentQuestionSuccess,
  updateSafetyAccidentTopicFailure,
  updateSafetyAccidentTopicRequest,
  updateSafetyAccidentTopicSuccess,
  upsertSafetyAccidentQuestionFailure,
  upsertSafetyAccidentQuestionRequest,
  upsertSafetyAccidentQuestionSuccess,
} from '@/svc/cs-svc/safetyAccidentManagement/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateSafetyAccidentTopic({ payload }: ReturnType<typeof updateSafetyAccidentTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/topic/upsert`, { data: payload });

    const action = response.ok ? updateSafetyAccidentTopicSuccess() : updateSafetyAccidentTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateSafetyAccidentTopicFailure((e as Error).message));
  }
}

function* deleteSafetyAccidentTopic({ payload }: ReturnType<typeof deleteSafetyAccidentTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/safetyAccident/topic/${payload}`);

    const action = response.ok ? deleteSafetyAccidentTopicSuccess() : deleteSafetyAccidentTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteSafetyAccidentTopicFailure((e as Error).message));
  }
}

function* bulkUpdateSafetyAccidentTopicDisplayOrder({
  payload,
}: ReturnType<typeof bulkUpdateSafetyAccidentTopicDisplayOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/topic/bulkUpdateDisplayOrder`, { data: payload });

    const action = response.ok
      ? bulkUpdateSafetyAccidentTopicDisplayOrderSuccess()
      : bulkUpdateSafetyAccidentTopicDisplayOrderFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateSafetyAccidentTopicDisplayOrderFailure((e as Error).message));
  }
}

function* deleteSafetyAccidentQuestion({ payload }: ReturnType<typeof deleteSafetyAccidentQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/safetyAccident/question/${payload}`);

    const action = response.ok ? deleteSafetyAccidentQuestionSuccess() : deleteSafetyAccidentQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteSafetyAccidentQuestionFailure((e as Error).message));
  }
}

function* rejectSafetyAccidentQuestion({ payload }: ReturnType<typeof rejectSafetyAccidentQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/question/${payload}/reject`);

    const action = response.ok ? rejectSafetyAccidentQuestionSuccess() : rejectSafetyAccidentQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(rejectSafetyAccidentQuestionFailure((e as Error).message));
  }
}

function* releaseSafetyAccidentQuestion({ payload }: ReturnType<typeof releaseSafetyAccidentQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/question/${payload}/release`);

    const action = response.ok ? releaseSafetyAccidentQuestionSuccess() : releaseSafetyAccidentQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(releaseSafetyAccidentQuestionFailure((e as Error).message));
  }
}

function* bulkUploadDisplayOrderSafetyAccidentQuestion({
  payload,
}: ReturnType<typeof bulkUploadDisplayOrderSafetyAccidentQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/question/bulkUpdateDisplayOrder`, { data: payload });

    const action = response.ok
      ? bulkUploadDisplayOrderSafetyAccidentQuestionSuccess()
      : bulkUploadDisplayOrderSafetyAccidentQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUploadDisplayOrderSafetyAccidentQuestionFailure((e as Error).message));
  }
}

function* upsertSafetyAccidentQuestion({ payload }: ReturnType<typeof upsertSafetyAccidentQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/safetyAccident/question/upsert`, { data: payload });

    const action = response.ok ? upsertSafetyAccidentQuestionSuccess() : upsertSafetyAccidentQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertSafetyAccidentQuestionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(updateSafetyAccidentTopicRequest.type, updateSafetyAccidentTopic);
  yield takeLatest(deleteSafetyAccidentTopicRequest.type, deleteSafetyAccidentTopic);
  yield takeLatest(bulkUpdateSafetyAccidentTopicDisplayOrderRequest.type, bulkUpdateSafetyAccidentTopicDisplayOrder);
  yield takeLatest(deleteSafetyAccidentQuestionRequest.type, deleteSafetyAccidentQuestion);
  yield takeLatest(rejectSafetyAccidentQuestionRequest.type, rejectSafetyAccidentQuestion);
  yield takeLatest(releaseSafetyAccidentQuestionRequest.type, releaseSafetyAccidentQuestion);
  yield takeLatest(bulkUploadDisplayOrderSafetyAccidentQuestionRequest.type, bulkUploadDisplayOrderSafetyAccidentQuestion);
  yield takeLatest(upsertSafetyAccidentQuestionRequest.type, upsertSafetyAccidentQuestion);
}

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import preventMatchSaga from '@/svc/penalty-svc/preventMatch/saga';
import {
  createDriverGroupFailure,
  createDriverGroupRequest,
  createDriverGroupSuccess,
  deleteDriverGroupFailure,
  deleteDriverGroupRequest,
  deleteDriverGroupSuccess,
  fetchDriverGroupDetailFailure,
  fetchDriverGroupDetailRequest,
  fetchDriverGroupDetailSuccess,
  fetchDriverGroupListFailure,
  fetchDriverGroupListRequest,
  fetchDriverGroupListSuccess,
  getDriverGroupTemplateFailure,
  getDriverGroupTemplateRequest,
  getDriverGroupTemplateSuccess,
  updateDriverGroupFailure,
  updateDriverGroupRequest,
  updateDriverGroupSuccess,
  updateDriverGroupUsersFailure,
  updateDriverGroupUsersRequest,
  updateDriverGroupUsersSuccess,
  bulkReleasePenaltyFailure,
  bulkReleasePenaltyRequest,
  bulkReleasePenaltySuccess,
  exportPenaltyFailure,
  exportPenaltyRequest,
  exportPenaltySuccess,
  imposedPenaltyFailure,
  imposedPenaltyRequest,
  imposedPenaltySuccess,
  releasePenaltyFailure,
  releasePenaltyRequest,
  releasePenaltySuccess,
} from '@/svc/penalty-svc/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* fetchDriverGroupList({ payload }: ReturnType<typeof fetchDriverGroupListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/usergroup', { params: payload });

    if (response.ok) {
      yield put(fetchDriverGroupListSuccess(response.data));
    } else {
      yield put(fetchDriverGroupListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDriverGroupListFailure((e as Error).message));
  }
}

function* createDriverGroup({ payload }: ReturnType<typeof createDriverGroupRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/usergroup', { data: payload.data });

    if (response.ok) {
      yield put(createDriverGroupSuccess(response.data));
    } else {
      yield put(createDriverGroupFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDriverGroupFailure((e as Error).message));
  }
}

function* fetchDriverGroupDetail({ payload }: ReturnType<typeof fetchDriverGroupDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/usergroup/${payload}`);

    if (response.ok) {
      yield put(fetchDriverGroupDetailSuccess(response.data));
    } else {
      yield put(fetchDriverGroupDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDriverGroupDetailFailure((e as Error).message));
  }
}

function* updateDriverGroup({ payload }: ReturnType<typeof updateDriverGroupRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/usergroup/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateDriverGroupSuccess(response.data));
    } else {
      yield put(updateDriverGroupFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDriverGroupFailure((e as Error).message));
  }
}

function* deleteDriverGroup({ payload }: ReturnType<typeof deleteDriverGroupRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/usergroup/${payload}`);

    if (response.ok) {
      yield put(deleteDriverGroupSuccess());
    } else {
      yield put(deleteDriverGroupFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDriverGroupFailure((e as Error).message));
  }
}

function* getDriverGroupTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/usergroup/update-user-group-template', { responseType: 'blob' });

    if (response.ok) {
      yield saveFile(response, 'DriverGroup_Template.xlsx');
      yield put(getDriverGroupTemplateSuccess());
    } else {
      yield put(getDriverGroupTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(getDriverGroupTemplateFailure((e as Error).message));
  }
}

function* updateDriverGroupUsers({ payload }: ReturnType<typeof updateDriverGroupUsersRequest>): IterableIterator {
  try {
    yield authenticatedRequest.put(`/usergroup/${payload.id}/users`, { data: payload.data, responseType: 'blob' });

    yield put(updateDriverGroupUsersSuccess());
  } catch (e) {
    yield put(updateDriverGroupUsersFailure((e as Error).message));
  }
}

function* releasePenalty({ payload }: ReturnType<typeof releasePenaltyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/penalty/${payload.id}/release`, { data: payload.data });

    if (response.ok) {
      yield put(releasePenaltySuccess());
    } else {
      yield put(releasePenaltyFailure(response.data.message));
    }
  } catch (e) {
    yield put(releasePenaltyFailure((e as Error).message));
  }
}

function* bulkReleasePenalty({ payload }: ReturnType<typeof bulkReleasePenaltyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put('/penalty/bulk/release', { data: payload });

    if (response.ok) {
      yield put(bulkReleasePenaltySuccess());
    } else {
      yield put(bulkReleasePenaltyFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkReleasePenaltyFailure((e as Error).message));
  }
}

function* imposedPenalty({ payload }: ReturnType<typeof imposedPenaltyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/penalty', { data: payload });

    if (response.ok) {
      yield put(imposedPenaltySuccess());
    } else {
      yield put(imposedPenaltyFailure(response.data.message));
    }
  } catch (e) {
    yield put(imposedPenaltyFailure((e as Error).message));
  }
}

function* exportPenalty({ payload }: ReturnType<typeof exportPenaltyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/penalty/export', { params: payload, responseType: 'blob' });

    if (response.ok) {
      yield saveFile(response, 'penalty');
      yield put(exportPenaltySuccess());
    } else {
      yield put(exportPenaltyFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportPenaltyFailure((e as Error).message));
  }
}

export default function* penaltySaga() {
  yield takeLatest(fetchDriverGroupListRequest.type, fetchDriverGroupList);
  yield takeLatest(createDriverGroupRequest.type, createDriverGroup);
  yield takeLatest(fetchDriverGroupDetailRequest.type, fetchDriverGroupDetail);
  yield takeLatest(updateDriverGroupRequest.type, updateDriverGroup);
  yield takeLatest(deleteDriverGroupRequest.type, deleteDriverGroup);
  yield takeLatest(getDriverGroupTemplateRequest.type, getDriverGroupTemplate);
  yield takeLatest(updateDriverGroupUsersRequest.type, updateDriverGroupUsers);
  yield takeLatest(releasePenaltyRequest.type, releasePenalty);
  yield takeLatest(imposedPenaltyRequest.type, imposedPenalty);
  yield takeLatest(exportPenaltyRequest.type, exportPenalty);
  yield takeLatest(bulkReleasePenaltyRequest.type, bulkReleasePenalty);
  yield all([call(preventMatchSaga)]);
}

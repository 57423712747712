import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryTag, DeliveryTagListParam, DeliveryTagState, DeliveryTagUpsertDto } from '@/svc/delivery-svc/tag/type';
import Service from '@/svc/serviceName';
import { originListResponse } from '@/utils/types';

export const deliveryTagInitialState: DeliveryTagState = {
  tags: [],
  detail: null,
  page: { total: 1, current: 1 },
  error: null,
  totalCount: 0,
};

const prefix = `${Service.deliverySvc}/tag`;

export const createDeliveryTagSuccess = createAction<DeliveryTag>(`${prefix}/createDeliveryTagSuccess`);
export const updateDeliveryTagSuccess = createAction<DeliveryTag>(`${prefix}/updateDeliveryTagSuccess`);
export const deleteDeliveryTagSuccess = createAction(`${prefix}/deleteDeliveryTagSuccess`);

const deliveryTagSlice = createSlice({
  name: prefix,
  initialState: deliveryTagInitialState,
  reducers: {
    fetchDeliveryTagListRequest: {
      prepare: (payload: DeliveryTagListParam) => ({ payload }),
      reducer(state) {
        state.error = null;
      },
    },
    fetchDeliveryTagListSuccess: {
      prepare: (payload: originListResponse<DeliveryTag>) => ({ payload }),
      reducer(state, { payload }: PayloadAction<originListResponse<DeliveryTag>>) {
        state.tags = payload.content;
        state.page.total = payload.totalPages;
        state.page.current = payload.pageable.pageNumber + 1;
        state.totalCount = payload.totalElements;
      },
    },
    fetchDeliveryTagListFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer(state, { payload }: PayloadAction<string>) {
        state.error = payload;
      },
    },
    fetchDeliveryTagDetailRequest: {
      prepare: (payload: string) => ({ payload }),
      reducer(state) {
        state.detail = null;
        state.error = null;
      },
    },
    fetchDeliveryTagDetailSuccess: {
      prepare: (payload: DeliveryTag) => ({ payload }),
      reducer(state, { payload }: PayloadAction<DeliveryTag>) {
        state.detail = payload;
      },
    },
    fetchDeliveryTagDetailFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer(state, { payload }: PayloadAction<string>) {
        state.error = payload;
      },
    },
    createDeliveryTagRequest: {
      prepare: (payload: DeliveryTagUpsertDto) => ({ payload }),
      reducer(state) {
        state.error = null;
      },
    },
    createDeliveryTagFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer(state, { payload }: PayloadAction<string>) {
        state.error = payload;
      },
    },
    updateDeliveryTagRequest: {
      prepare: (id: string, body: DeliveryTagUpsertDto) => ({ payload: { id, body } }),
      reducer(state) {
        state.error = null;
      },
    },
    updateDeliveryTagFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer(state, { payload }: PayloadAction<string>) {
        state.error = payload;
      },
    },
    deleteDeliveryTagRequest: {
      prepare: (payload: string) => ({ payload }),
      reducer(state) {
        state.error = null;
      },
    },
    deleteDeliveryTagFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer(state, { payload }: PayloadAction<string>) {
        state.error = payload;
      },
    },
  },
});

export const {
  fetchDeliveryTagListRequest,
  fetchDeliveryTagListSuccess,
  fetchDeliveryTagListFailure,
  fetchDeliveryTagDetailRequest,
  fetchDeliveryTagDetailSuccess,
  fetchDeliveryTagDetailFailure,
  createDeliveryTagRequest,
  createDeliveryTagFailure,
  updateDeliveryTagRequest,
  updateDeliveryTagFailure,
  deleteDeliveryTagRequest,
  deleteDeliveryTagFailure,
} = deliveryTagSlice.actions;

export default deliveryTagSlice.reducer;

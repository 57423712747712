import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeAdminForm } from '@/pages/Admin/hooks';
import { AdminInterface, AdminState } from '@/pages/Admin/type';
import { detailSuccess } from '@/utils/reducerUtils';
import { DetailResponse } from '@/utils/types';

const initialState: AdminState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isBlocking: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    fetchAdminDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchAdminDetailSuccess: {
      prepare: (payload: DetailResponse<AdminInterface>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<AdminInterface>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchAdminDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createAdminRequest: {
      prepare: (data: ReturnType<typeof sanitizeAdminForm>) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createAdminSuccess: {
      prepare: (payload: AdminInterface) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createAdminFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateAdminRequest: {
      prepare: (id: string, data: ReturnType<typeof sanitizeAdminForm>) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateAdminSuccess: {
      prepare: (payload: AdminInterface) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateAdminFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },

    deleteAdminRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isDeleting = true;
        state.error = null;
      },
    },
    deleteAdminSuccess: {
      prepare: (payload: AdminInterface) => ({ payload }),
      reducer: (state) => {
        state.isDeleting = false;
      },
    },
    deleteAdminFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isDeleting = false;
        state.error = payload;
      },
    },

    blockAdminRequest: {
      prepare: (id: string, data: { blocked: boolean }) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isBlocking = true;
        state.error = null;
      },
    },
    blockAdminSuccess(state, { payload }: PayloadAction<AdminInterface>) {
      state.isBlocking = false;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        blocked: !state.byId[payload.id].blocked,
      };
    },
    blockAdminFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isBlocking = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchAdminDetailRequest,
  fetchAdminDetailSuccess,
  fetchAdminDetailFailure,
  createAdminRequest,
  createAdminSuccess,
  createAdminFailure,
  updateAdminRequest,
  updateAdminSuccess,
  updateAdminFailure,
  deleteAdminRequest,
  deleteAdminSuccess,
  deleteAdminFailure,
  blockAdminRequest,
  blockAdminSuccess,
  blockAdminFailure,
} = adminSlice.actions;

export default adminSlice.reducer;

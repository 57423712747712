import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AppVersionList" */ '@/pages/AppVersion/List'));
const Detail = lazy(() => import(/* webpackChunkName: "AppVersionDetail" */ '@/pages/AppVersion/Detail'));
const New = lazy(() => import(/* webpackChunkName: "AppVersionNew" */ '@/pages/AppVersion/New'));

export const appVersionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.AppVersion,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.AppVersion}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.AppVersion}/:id`,
    exact: true,
    component: Detail,
  },
];

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DriverGroupList" */ '@/pages/DriverGroup/List'));
const Detail = lazy(() => import(/* webpackChunkName: "DriverGroupDetail" */ '@/pages/DriverGroup/Detail'));
const New = lazy(() => import(/* webpackChunkName: "DriverGroupNew" */ '@/pages/DriverGroup/New'));
const Edit = lazy(() => import(/* webpackChunkName: "DriverGroupEdit" */ '@/pages/DriverGroup/Edit'));

export const driverGroupRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DriverGroup,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DriverGroup}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverGroup}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DriverGroup}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

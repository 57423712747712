import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryRideProductOptionListRequest,
  fetchDeliveryRideProductOptionListSuccess,
  fetchDeliveryRideProductOptionListFailure,
  fetchDeliveryRideProductOptionDetailRequest,
  fetchDeliveryRideProductOptionDetailSuccess,
  fetchDeliveryRideProductOptionDetailFailure,
  createDeliveryRideProductOptionRequest,
  createDeliveryRideProductOptionSuccess,
  createDeliveryRideProductOptionFailure,
  updateDeliveryRideProductOptionRequest,
  updateDeliveryRideProductOptionSuccess,
  updateDeliveryRideProductOptionFailure,
  deleteDeliveryRideProductOptionRequest,
  deleteDeliveryRideProductOptionSuccess,
  deleteDeliveryRideProductOptionFailure,
} from '@/pages/DeliveryRideProductOption/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchDeliveryRideProductOptionList({ payload }: ReturnType<typeof fetchDeliveryRideProductOptionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config/options/product', {
      params: payload,
      schema: { content: [entity] },
    });

    if (response.ok) {
      yield put(fetchDeliveryRideProductOptionListSuccess(response.data));
    } else {
      yield put(fetchDeliveryRideProductOptionListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRideProductOptionListFailure((e as Error).message));
  }
}

function* fetchDeliveryRideProductOptionDetail({ payload }: ReturnType<typeof fetchDeliveryRideProductOptionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/config/options/product/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchDeliveryRideProductOptionDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryRideProductOptionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRideProductOptionDetailFailure((e as Error).message));
  }
}

function* createDeliveryRideProductOption({ payload }: ReturnType<typeof createDeliveryRideProductOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/options/product', { data: payload });

    if (response.ok) {
      yield put(createDeliveryRideProductOptionSuccess());
    } else {
      yield put(createDeliveryRideProductOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryRideProductOptionFailure((e as Error).message));
  }
}

function* updateDeliveryRideProductOption({ payload: { id, data } }: ReturnType<typeof updateDeliveryRideProductOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/options/product/${id}`, { data });

    if (response.ok) {
      yield put(updateDeliveryRideProductOptionSuccess());
    } else {
      yield put(updateDeliveryRideProductOptionFailure(response.data.messsage));
    }
  } catch (e) {
    yield put(updateDeliveryRideProductOptionFailure((e as Error).message));
  }
}

function* deleteDeliveryRideProductOption({ payload }: ReturnType<typeof deleteDeliveryRideProductOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/options/product/${payload}`);

    if (response.ok) {
      yield put(deleteDeliveryRideProductOptionSuccess());
    } else {
      yield put(deleteDeliveryRideProductOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryRideProductOptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchDeliveryRideProductOptionListRequest.type, fetchDeliveryRideProductOptionList);
  yield takeLatest(fetchDeliveryRideProductOptionDetailRequest.type, fetchDeliveryRideProductOptionDetail);
  yield takeLatest(createDeliveryRideProductOptionRequest.type, createDeliveryRideProductOption);
  yield takeLatest(updateDeliveryRideProductOptionRequest.type, updateDeliveryRideProductOption);
  yield takeLatest(deleteDeliveryRideProductOptionRequest.type, deleteDeliveryRideProductOption);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchPromotionListRequest,
  fetchPromotionListFailure,
  fetchPromotionListSuccess,
  createPromotionFailure,
  createPromotionRequest,
  createPromotionSuccess,
  updatePromotionFailure,
  updatePromotionRequest,
  updatePromotionSuccess,
  publishCouponToUserRequest,
  publishCouponToUserSuccess,
  publishCouponToUserFailure,
  getPublishCouponToUserTemplateSuccess,
  getPublishCouponToUserTemplateFailure,
  getPublishCouponToUserTemplateRequest,
} from '@/pages/Promotion/reducer';
import { getContentSchema } from '@/utils/api';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { schema } = getContentSchema();

const basePath = '/couponsvc/admin/v1/promotions';

function* fetchPromotionList({ payload }: ReturnType<typeof fetchPromotionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(basePath, { params: payload, schema });

    const action = response.ok ? fetchPromotionListSuccess(response.data) : fetchPromotionListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchPromotionListFailure((e as Error).message));
  }
}

function* createPromotion({ payload }: ReturnType<typeof createPromotionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createPromotionSuccess(response.data) : createPromotionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createPromotionFailure((e as Error).message));
  }
}

function* updatePromotion({ payload: { id, body } }: ReturnType<typeof updatePromotionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/code/${id}`, { data: body });

    const action = response.ok ? updatePromotionSuccess(response.data) : updatePromotionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updatePromotionFailure((e as Error).message));
  }
}

function* getPublishCouponToUserTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/promotions/issue_coupon_excel_template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'publish-coupon-template.xlsx');
      yield put(getPublishCouponToUserTemplateSuccess());
    } else {
      yield put(getPublishCouponToUserTemplateFailure());
    }
  } catch (e) {
    yield put(getPublishCouponToUserTemplateFailure());
  }
}

function* publishCouponToUser({ payload: { id, file } }: ReturnType<typeof publishCouponToUserRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/promotions/code/${id}/issue_coupon_excel`, {
      data: file,
      contentType: 'multipart/form-data',
      responseType: 'blob',
    });

    const action = response.ok ? publishCouponToUserSuccess() : publishCouponToUserFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(publishCouponToUserFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchPromotionListRequest.type, fetchPromotionList);
  yield takeLatest(createPromotionRequest.type, createPromotion);
  yield takeLatest(updatePromotionRequest.type, updatePromotion);
  yield takeLatest(getPublishCouponToUserTemplateRequest.type, getPublishCouponToUserTemplate);
  yield takeLatest(publishCouponToUserRequest.type, publishCouponToUser);
}

import { createAction, createSlice } from '@reduxjs/toolkit';

import { REGION } from '@/assets/constants';
import { UpsertProductScheduleSurgePresetMappingDto } from '@/pages/ScheduleSurgePreset/type';
import { ScheduleSurgePresetState } from '@/svc/ride-svc/scheduleSurgePreset/type';
import Service from '@/svc/serviceName';

export const scheduleSurgePresetInitialState: ScheduleSurgePresetState = {
  isFetching: false,
};

export const downloadNewTemplateRequest = createAction<REGION>(`${Service.rideSvc}/scheduleSurgePreset/downloadNewTemplateRequest`);
export const downloadNewTemplateFailure = createAction<string>(`${Service.rideSvc}/scheduleSurgePreset/downloadNewTemplateFailure`);

export const downloadEditTemplateRequest = createAction<number>(`${Service.rideSvc}/scheduleSurgePreset/downloadEditTemplateRequest`);
export const downloadEditTemplateFailure = createAction<string>(`${Service.rideSvc}/scheduleSurgePreset/downloadEditTemplateFailure`);

const scheduleSurgePresetSlice = createSlice({
  name: `${Service.rideSvc}/scheduleSurgePreset`,
  initialState: scheduleSurgePresetInitialState,
  reducers: {
    createScheduleSurgePresetRequest: {
      prepare: (data: FormData) => ({ payload: data }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    createScheduleSurgePresetSuccess(state) {
      state.isFetching = false;
    },
    createScheduleSurgePresetFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    updateScheduleSurgePresetRequest: {
      prepare: (id: number, data: FormData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    updateScheduleSurgePresetSuccess(state) {
      state.isFetching = false;
    },
    updateScheduleSurgePresetFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    updatePriceManagementRequest: {
      prepare: (data: UpsertProductScheduleSurgePresetMappingDto) => ({ payload: data }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    updatePriceManagementSuccess(state) {
      state.isFetching = false;
    },
    updatePriceManagementFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
  },
});

export const {
  createScheduleSurgePresetRequest,
  createScheduleSurgePresetSuccess,
  createScheduleSurgePresetFailure,
  updateScheduleSurgePresetRequest,
  updateScheduleSurgePresetSuccess,
  updateScheduleSurgePresetFailure,
  updatePriceManagementRequest,
  updatePriceManagementSuccess,
  updatePriceManagementFailure,
} = scheduleSurgePresetSlice.actions;

export default scheduleSurgePresetSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { resetOtpFailure, resetOtpRequest, resetOtpSuccess } from '@/svc/account-svc/deviceOtpReset/reducer';
import { authenticatedRequest } from '@/utils/request';

function* resetOtp({ payload }: ReturnType<typeof resetOtpRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/accountsvc/admin/v1/otp/dailyLimit/reset', { data: payload });

    if (response.ok) {
      yield put(resetOtpSuccess());
    } else {
      yield put(resetOtpFailure(response.data.message));
    }
  } catch (e) {
    yield put(resetOtpFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(resetOtpRequest.type, resetOtp);
}

import { ReactNode } from 'react';

import ReactLoading from 'react-loading';

import { Alert } from '@/components/template';

interface LoadingFullProps {
  isFetching?: boolean;
  isBlock?: boolean;
  message?: ReactNode;
}

export function LoadingFull({ isFetching, isBlock, message }: LoadingFullProps) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-32px',
        marginLeft: '-32px',
        textAlign: 'center',
        zIndex: 99,
        display: isFetching ? 'block' : 'none',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          width: '100%',
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          display: isBlock ? 'block' : 'none',
        }}
      />
      <ReactLoading type='spin' color='#444' delay={0} />
      <Alert
        style={{
          position: 'absolute',
          width: '300px',
          marginLeft: '-115px',
          marginTop: '15px',
        }}
        color='primary'
      >
        {message}
      </Alert>
    </div>
  );
}

import { ReactNode } from 'react';

import { ScheduleSurgeDto } from '@/pages/ScheduleSurge/types';
import { valueOf } from '@/types';

export const POPUP_TYPE = {
  FindModal: 'FindModal',
  DeliverySearch: 'DeliverySearch',
  KeyIn: 'KeyIn',
  IdentifierMigration: 'IdentifierMigration',
  Confirm: 'Confirm',
  ScheduleSurge: 'ScheduleSurge',
  ScheduleSyncConfirm: 'ScheduleSyncConfirm',
  RidePenaltyFeeReason: 'RidePenaltyFeeReason',
  BulkSyncDriverIncentive: 'BulkSyncDriverIncentive',
  RetryCashOutResult: 'RetryCashOutResult',
} as const;

export type PopupType = valueOf<typeof POPUP_TYPE>;

export interface CommonState {
  popup: Record<valueOf<typeof POPUP_TYPE>, boolean>;
  confirmData: {
    content: ReactNode;
    ok: () => void;
    fail: () => void;
    disabled: boolean;
  };
  ridePenaltyData: {
    id: string;
  };
  scheduleSurgeData: ScheduleSurgeDto[];
  collapse: Record<'bulkBanDriverIncentive', boolean>;
  retryCashOutResponse: {
    succeedIds: string[];
    failIds: string[];
  };
  isHiddenSidebar: boolean;
}

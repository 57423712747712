import { decamelizeKeys } from 'humps';

export const submitRequest =
  <T>(submitStatus: Partial<T>) =>
  (state: T) => ({
    ...state,
    ...submitStatus,
    error: null,
  });

export const submitFailure =
  <T>(submitStatus: Partial<T>) =>
  (state: T, { payload }: any) => ({
    ...state,
    ...submitStatus,
    error: payload.error,
  });

export const listSuccess = (state: any, { payload }: any) => ({
  ...state,
  isFetching: false,
  error: null,
  page: { total: payload.result.totalPages, current: payload.result.pageable.pageNumber + 1, limit: payload.result.size },
  ids: payload.result.content,
  byId: { ...state.byId, ...payload.entities.content },
  totalCount: payload.result.totalElements,
});

export const detailSuccess = (state: any, { payload }: any) => ({
  ...state,
  isFetching: false,
  error: null,
  byId: { ...state.byId, ...payload.entities.content },
});

export const decamelizeKeysListSuccess = (state: any, { payload }: any) => ({
  ...state,
  isFetching: false,
  error: null,
  page: { total: payload.result.totalPages, current: payload.result.pageable.pageNumber + 1, limit: payload.result.size },
  ids: payload.result.content,
  byId: { ...state.byId, ...decamelizeKeys(payload.entities.content) },
  totalCount: payload.result.totalElements,
});

export const decamelizeDetailSuccess = (state: any, { payload }: any) => ({
  ...state,
  isFetching: false,
  error: null,
  byId: { ...state.byId, ...decamelizeKeys(payload.entities.content) },
});

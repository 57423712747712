import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  checkFinishedMomopayTransactionFailure,
  checkFinishedMomopayTransactionRequest,
  checkFinishedMomopayTransactionSuccess,
} from '@/pages/MomopayTransactions/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

const basePath = 'payment/momo_transactions';

function* checkFinishedMomopayTransaction({ payload }: ReturnType<typeof checkFinishedMomopayTransactionRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${basePath}/${payload}/check_finished`, {
      schema: entity,
    });

    const action = res.ok ? checkFinishedMomopayTransactionSuccess(res.data) : checkFinishedMomopayTransactionFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(checkFinishedMomopayTransactionFailure((e as Error).message));
  }
}

export default function* momopayTransactionSaga() {
  yield takeLatest(checkFinishedMomopayTransactionRequest.type, checkFinishedMomopayTransaction);
}

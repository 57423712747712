import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { MediaFromUrl } from '@/components/etc';
import { Input } from '@/components/template';

export const MediaImg = styled.img`
  max-width: 250px;
  max-height: 250px;
`;

export const MediaVideo = styled.video.attrs({
  controls: true,
})`
  max-width: 450px;
  max-height: 300px;
`;

interface FilePreviewInterface {
  src?: string;
  onLoad: (result: any) => void;
}

function FilePreview({ src = '', onLoad }: FilePreviewInterface) {
  const [mediaLink, setMediaLink] = useState<string>(src);

  useEffect(() => {
    if (src !== mediaLink) {
      setMediaLink(src);
    }
  }, [src]);

  return (
    <>
      <MediaFromUrl url={mediaLink} />
      <Input
        type={'file'}
        onChange={(e) => {
          // @ts-ignore
          const file = e.target.files[0];

          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            setMediaLink(`${reader.result}`);

            onLoad(reader.result);
          };
        }}
      />
    </>
  );
}

export default FilePreview;

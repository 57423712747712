import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Verification = lazy(() => import(/* webpackChunkName: "Verification" */ '@/pages/PriceVerification/index'));

export const priceVerificationRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.PriceVerification,
    component: Verification,
    exact: true,
  },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { updateShbWalletNricFailure, updateShbWalletNricRequest, updateShbWalletNricSuccess } from '@/svc/pay-svc/shbWallet/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateShbWalletNric({ payload: { id, nric } }: ReturnType<typeof updateShbWalletNricRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/v1/shb_wallet/${id}/update_nric`, { data: { nric } });

    const action = response.ok ? updateShbWalletNricSuccess() : updateShbWalletNricFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateShbWalletNricFailure((e as Error).message));
  }
}
export default function* defaultSaga() {
  yield takeLatest(updateShbWalletNricRequest.type, updateShbWalletNric);
}

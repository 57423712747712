import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ALL_DELIVERY_SIZE, DeliverySize } from '@/assets/constants';
import { DeliveryFormInterface } from '@/pages/Delivery/type';
import {
  DeliverySizeAndRequest,
  DeliveryRequest,
  DeliveryRequestMapping,
  DeliveryRideResponse,
  DeliveryUpdateBulkDto,
} from '@/pages/DeliveryRide/type';

interface DeliveryRequestListByIdsRequestPayload {
  id: string[];
  ids: DeliverySizeAndRequest[];
}

export interface DeliveryRideState {
  isFetchingList: boolean;
  isFetching: boolean;
  isFetchingMap: boolean;
  isFetchingMapping: boolean;
  isLinking: boolean;
  isAddingDeliveryToRide: boolean;
  isCreateAndBook: boolean;
  isSend: boolean;
  error: string | null;

  deliveryRequestIds: string[]; // all delivery request ids
  deliveryRequests: DeliverySizeAndRequest[]; // sort by size
  currentIndex: string | null; // size index
  scannedRequests: string[]; // scanned delivery request ids
  currentRequest: DeliveryRequest | null; // current delivery request
  blockedRequest: { dr: DeliveryRequest; prev: DeliverySize } | null; // move delivery request
  mapping: DeliveryRequestMapping[];
  newCreatedRide: DeliveryRideResponse | null;
}

export const initialState: DeliveryRideState = {
  isFetchingList: false,
  isFetching: false,
  isFetchingMap: false,
  isFetchingMapping: false,
  isLinking: false,
  isAddingDeliveryToRide: false,
  isCreateAndBook: false,
  isSend: false,
  error: null,
  deliveryRequestIds: [],
  deliveryRequests: ALL_DELIVERY_SIZE.map((size) => ({
    size,
    deliveryRequestIds: [],
  })),
  currentIndex: null,
  scannedRequests: [],
  currentRequest: null,
  blockedRequest: null,
  mapping: [],
  newCreatedRide: null,
};

export const deliveryBulkUpdateRequest = createAction('deliveryRideSlice/deliveryBulkUpdateRequest', (data: DeliveryUpdateBulkDto) => ({
  payload: data,
}));
export const deliveryBulkUpdateSuccess = createAction('deliveryRideSlice/deliveryBulkUpdateSuccess');
export const deliveryBulkUpdateFailure = createAction<string>('deliveryRideSlice/deliveryBulkUpdateFailure');

const deliveryRideSlice = createSlice({
  name: 'deliveryRideSlice',
  initialState,
  reducers: {
    fetchDeliveryRequestListByIdsRequest: (state, { payload }: PayloadAction<DeliveryRequestListByIdsRequestPayload>) => {
      state.isFetchingList = true;
      state.error = null;
      state.deliveryRequestIds = payload.id;
      state.deliveryRequests = payload.ids;
    },
    fetchDeliveryRequestListByIdsSuccess: (state, { payload }: PayloadAction<DeliveryRequest[]>) => {
      state.isFetchingList = false;
      state.deliveryRequests = state.deliveryRequests.map(({ size, deliveryRequestIds }) => ({
        size,
        deliveryRequestIds: deliveryRequestIds.map((requestId) => payload.find((dr: any) => dr.id === requestId)),
      }));
    },
    fetchDeliveryRequestListByIdsFailure: (state, { payload }: PayloadAction<string>) => {
      state.isFetchingList = false;
      state.error = payload;
    },

    fetchDeliveryRequestByIdRequest: {
      prepare: (id: string, idx: string) => ({ payload: { id, idx } }),
      reducer: (state, { payload }: PayloadAction<{ id: string; idx: string }>) => {
        state.isFetching = true;
        state.error = null;
        state.currentIndex = payload.idx;
      },
    },
    fetchDeliveryRequestByIdSuccess: (state, { payload }: PayloadAction<DeliveryRequest>) => {
      state.isFetching = false;

      const { currentIndex } = state;

      if (currentIndex === null) {
        return;
      }

      const isset = state.deliveryRequestIds.includes(payload.id);

      // Size와 상관 없는 DeliveryRequest ID 스캔 Field
      if (currentIndex === '999') {
        if (state.scannedRequests.includes(payload.id)) {
          state.error = 'Delivery Request Already Exist.';
          return;
        }

        state.scannedRequests.push(payload.id);

        if (!isset) {
          // fetch에 성공한 deliveryRequest ID가 기존 mold.deliveryRequestIds에 포함되어 있지 않다면?
          state.deliveryRequestIds.push(payload.id);
          state.currentRequest = payload;
        }

        return;
      }

      if (isset) {
        const isSameSize = state.deliveryRequests[+currentIndex].deliveryRequestIds.find(({ id }: any) => id === payload.id);

        if (isSameSize) {
          // 해당 deliveryRequest ID가 mold.deliveryRequestIds에 이미 존재하고, 입력한 field와 같은 Size인 경우?
          state.scannedRequests.push(payload.id); // → 해당 Size의 Not Scanned 라벨을 Scanned로 변경
        } else {
          // 해당 deliveryRequest ID가 mold.deliveryRequestIds에 이미 존재하고, 입력한 field와 같은 Size가 아닌 경우?
          const prev = state.deliveryRequests.find(({ deliveryRequestIds }) => deliveryRequestIds.map(({ id }) => id).includes(payload.id))
            ?.size as DeliverySize;

          state.blockedRequest = {
            dr: payload,
            prev,
          };
        }
      } else {
        state.deliveryRequests[+currentIndex].deliveryRequestIds.push(payload); // → 해당 Size에 추가.
        state.deliveryRequestIds.push(payload.id);
        state.scannedRequests.push(payload.id);
      }
    },
    fetchDeliveryRequestByIdFailure: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = false;
      state.error = payload;
    },

    fetchDeliveryRequestMappingRequest: {
      prepare: (id: string[]) => ({ payload: id }),
      reducer: (state) => {
        state.isFetchingMapping = true;
        state.error = null;
      },
    },
    fetchDeliveryRequestMappingSuccess: (state, { payload }: PayloadAction<DeliveryRequestMapping[]>) => {
      state.isFetchingMapping = false;
      state.mapping = payload;
    },
    fetchDeliveryRequestMappingFailure: (state, { payload }: PayloadAction<string>) => {
      state.isFetchingMapping = false;
      state.error = payload;
    },

    linkDeliveryReqWithDeliveryRequest: {
      prepare: (data: DeliveryRequestMapping) => ({ payload: data }),
      reducer: (state) => {
        state.isLinking = true;
        state.error = null;
      },
    },
    linkDeliveryReqWithDeliverySuccess: (state, { payload }: PayloadAction<DeliveryRequest>) => {
      state.isLinking = false;
      state.mapping = state.mapping.map(({ deliveryId, deliveryRequestId }) =>
        deliveryId === payload.delivery?.id ? { deliveryId, deliveryRequestId: payload.id } : { deliveryId, deliveryRequestId }
      );
    },
    linkDeliveryReqWithDeliveryFailure: (state, { payload }: PayloadAction<string>) => {
      state.isLinking = false;
      state.error = payload;
    },

    deleteDeliveryRequestItem: {
      prepare: (uuid: string, sizeIndex?: number) => ({ payload: { uuid, sizeIndex } }),
      reducer: (state, { payload: { uuid, sizeIndex } }: PayloadAction<{ uuid: string; sizeIndex?: number }>) => {
        state.scannedRequests = state.scannedRequests.filter((id) => id !== uuid);
        state.deliveryRequestIds = state.deliveryRequestIds.filter((id) => id !== uuid);

        if (sizeIndex !== undefined) {
          state.deliveryRequests[sizeIndex].deliveryRequestIds = state.deliveryRequests[sizeIndex].deliveryRequestIds.filter(({ id }) => id !== uuid);
        }
      },
    },

    setCurrentDeliveryRequestSize: {
      prepare: (size: string) => ({ payload: size }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        const targetRequests = state.deliveryRequests.find((dr) => dr.size === payload);

        if (targetRequests) {
          targetRequests.deliveryRequestIds.push(state.currentRequest);
        }

        state.currentRequest = null;
      },
    },

    clearDeliveryRequests: (state) => {
      state.deliveryRequestIds = [];
      state.deliveryRequests = ALL_DELIVERY_SIZE.map((size) => ({
        size,
        deliveryRequestIds: [],
      }));
      state.currentIndex = null;
      state.scannedRequests = [];
      state.currentRequest = null;
    },

    forceAddDeliveryToRideRequest: {
      prepare: (id: string, deliveryId: string) => ({ payload: { id, deliveryId } }),
      reducer: (state) => {
        state.isAddingDeliveryToRide = true;
        state.error = null;
      },
    },
    forceAddDeliveryToRideSuccess: (state) => {
      state.isAddingDeliveryToRide = false;
    },
    forceAddDeliveryToRideFailure: (state, { payload }: PayloadAction<string>) => {
      state.isAddingDeliveryToRide = false;
      state.error = payload;
    },

    createAndBookDeliveryRequest: {
      prepare: (data: { deliveries: DeliveryFormInterface[]; rideProductId: number; dispatchImmediately: boolean }) => ({
        payload: data,
      }),
      reducer: (state) => {
        state.isCreateAndBook = true;
        state.error = null;
      },
    },
    createAndBookDeliverySuccess: (state, { payload }: PayloadAction<DeliveryRideResponse>) => {
      state.isCreateAndBook = false;
      state.newCreatedRide = payload;
    },
    createAndBookDeliveryFailure: (state, { payload }: PayloadAction<string>) => {
      state.isCreateAndBook = false;
      state.error = payload;
    },

    sendDeliveryRideReceiptRequest: {
      prepare: (id: string, email?: string | null) => ({ payload: { id, email } }),
      reducer: (state) => {
        state.isSend = true;
        state.error = null;
      },
    },
    sendDeliveryRideReceiptSuccess: (state) => {
      state.isSend = false;
    },
    sendDeliveryRideReceiptFailure: (state, { payload }: PayloadAction<string>) => {
      state.isSend = false;
      state.error = payload;
    },

    updateDeliveryRequestSize: (state) => {
      if (state.blockedRequest === null || state.currentIndex === null) {
        return;
      }

      const { blockedRequest } = state;

      // add to scannedRequest
      state.scannedRequests.push(blockedRequest.dr.id);

      // remove previous size dr
      const previousRequests = state.deliveryRequests.find((v) => v.size === blockedRequest.prev) as DeliverySizeAndRequest;
      previousRequests.deliveryRequestIds = previousRequests.deliveryRequestIds.filter(({ id }) => id !== blockedRequest.dr.id);

      // add current size dr
      state.deliveryRequests[+state.currentIndex].deliveryRequestIds.push(blockedRequest.dr);

      state.blockedRequest = null;
    },
  },
});

export const {
  fetchDeliveryRequestListByIdsRequest,
  fetchDeliveryRequestListByIdsSuccess,
  fetchDeliveryRequestListByIdsFailure,
  fetchDeliveryRequestByIdRequest,
  fetchDeliveryRequestByIdSuccess,
  fetchDeliveryRequestByIdFailure,
  fetchDeliveryRequestMappingRequest,
  fetchDeliveryRequestMappingSuccess,
  fetchDeliveryRequestMappingFailure,
  linkDeliveryReqWithDeliveryRequest,
  linkDeliveryReqWithDeliverySuccess,
  linkDeliveryReqWithDeliveryFailure,
  deleteDeliveryRequestItem,
  setCurrentDeliveryRequestSize,
  clearDeliveryRequests,
  forceAddDeliveryToRideRequest,
  forceAddDeliveryToRideSuccess,
  forceAddDeliveryToRideFailure,
  createAndBookDeliveryRequest,
  createAndBookDeliverySuccess,
  createAndBookDeliveryFailure,
  sendDeliveryRideReceiptRequest,
  sendDeliveryRideReceiptSuccess,
  sendDeliveryRideReceiptFailure,
  updateDeliveryRequestSize,
} = deliveryRideSlice.actions;

export default deliveryRideSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createZoneFailure,
  createZoneRequest,
  createZoneSuccess,
  fetchZoneDetailFailure,
  fetchZoneDetailRequest,
  fetchZoneDetailSuccess,
  fetchZoneListFailure,
  fetchZoneListRequest,
  fetchZoneListSuccess,
  updateZoneRequest,
  updateZoneSuccess,
  updateZoneFailure,
  searchZoneRequest,
  searchZoneSuccess,
  searchZoneFailure,
  fetchZoneGeoJsonRequest,
  fetchZoneGeoJsonSuccess,
  fetchZoneGeoJsonFailure,
  syncZoneRequest,
  syncZoneSuccess,
  syncZoneFailure,
} from '@/pages/Zones/reducer';
import { getContentSchema } from '@/utils/api/index';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { schema, entity } = getContentSchema();

const basePath = '/zones';

function* fetchZoneList({ payload }: ReturnType<typeof fetchZoneListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(basePath, { params: payload, schema });

    const action = response.ok ? fetchZoneListSuccess(response.data) : fetchZoneListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchZoneListFailure((e as Error).message));
  }
}

function* fetchZoneDetail({ payload }: ReturnType<typeof fetchZoneDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/${payload}`, { schema: entity });

    const action = response.ok ? fetchZoneDetailSuccess(response.data) : fetchZoneDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchZoneDetailFailure((e as Error).message));
  }
}

function* createZone({ payload }: ReturnType<typeof createZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createZoneSuccess(response.data) : createZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createZoneFailure((e as Error).message));
  }
}

function* updateZone({ payload: { id, body } }: ReturnType<typeof updateZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateZoneSuccess(response.data) : updateZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateZoneFailure((e as Error).message));
  }
}

function* searchZone({ payload }: ReturnType<typeof searchZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/zones/search', { params: payload, schema });

    const action = response.ok ? searchZoneSuccess(response.data) : searchZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(searchZoneFailure((e as Error).message));
  }
}

function* fetchZoneGeoJson({ payload }: ReturnType<typeof fetchZoneGeoJsonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/zones/${payload}/geojson`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, `zone-${payload}.geojson`);
    }

    const action = response.ok ? fetchZoneGeoJsonSuccess() : fetchZoneGeoJsonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchZoneGeoJsonFailure((e as Error).message));
  }
}

function* syncZone(): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/zones/h3_bq_mapping');

    const action = response.ok && response.data?.ok ? syncZoneSuccess() : syncZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(syncZoneFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchZoneListRequest.type, fetchZoneList);
  yield takeLatest(fetchZoneDetailRequest.type, fetchZoneDetail);
  yield takeLatest(createZoneRequest.type, createZone);
  yield takeLatest(updateZoneRequest.type, updateZone);
  yield takeLatest(searchZoneRequest.type, searchZone);
  yield takeLatest(searchZoneRequest.type, searchZone);
  yield takeLatest(fetchZoneGeoJsonRequest.type, fetchZoneGeoJson);
  yield takeLatest(syncZoneRequest.type, syncZone);
}

import { useEffect, useRef } from 'react';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { usePrevious } from '@tada/tada-web-common';

import { fetchMeRequest, fetchMeSuccess } from '@/pages/LoginPage/reducer';
import { useActionSubscribe } from '@/store/middleware/actionSubscribe';
import { getRefreshTime, saveRefreshTime } from '@/utils/localStorage';

function ForceRefresh() {
  const dispatch = useDispatch();
  const location = useLocation();

  const prevPath = usePrevious(location.pathname);
  const fetching = useRef(false);

  useEffect(() => {
    const refreshTime = getRefreshTime();

    if (refreshTime === undefined) {
      saveRefreshTime();
      return;
    }

    if (location.pathname !== prevPath && moment().diff(moment(refreshTime), 'hours') >= 8) {
      fetching.current = true;
      dispatch(fetchMeRequest());
    }
  }, [location.pathname]);

  useActionSubscribe(fetchMeSuccess.type, () => {
    if (fetching.current) {
      window.location.reload();
    }
  });

  return null;
}

export default ForceRefresh;

import { useCallback, useEffect } from 'react';

import qs, { IStringifyOptions } from 'qs';
import { useHistory } from 'react-router';

import { useSearch, SearchConfig } from '@/hooks/useSearch';
import { valueOf } from '@/types';

interface DefaultQuery {
  page: number;
  size: number;
}

type Key = string;
type Value = string | number | boolean | undefined;
export type QueryObject = { [key: string]: Value };
type KeyDescriptor<T> = Key | QueryObject | Partial<T>;
export type SetQuery<T> = (key: KeyDescriptor<T>, value?: Value | valueOf<T>, deleteFlag?: boolean) => void;

export interface QueryStringConfig<T> {
  initialState?: Partial<T>;
  initialSearch?: (query: Partial<T>) => boolean;
  searchConfig?: SearchConfig;
  onQueryChanged?: (query: T) => void;
  stringifyOptions?: IStringifyOptions;
}

export interface QueryStringData<T> {
  query: QueryObject & T;
  setQuery: SetQuery<T>;
  deleteQuery: (key: string | string[]) => void;
  resetQuery: (query?: QueryObject) => void;
  handleFilterChange: SetQuery<T>;
  handlePaginationChange: (value: number) => void;
  skip: boolean;
}

export const tableInitialQuery = {
  page: 1,
  size: 20,
};

export const defaultShouldUpdateSearch = <T extends DefaultQuery>(query: Partial<T>) => query.page === undefined;

const useQueryString = <T>({
  initialState,
  initialSearch = defaultShouldUpdateSearch,
  searchConfig,
  onQueryChanged,
  stringifyOptions,
}: QueryStringConfig<T> = {}): QueryStringData<T> => {
  const history = useHistory();
  const query = useSearch(searchConfig);

  const skip = initialSearch?.(query) ?? false;

  const push = (data: Record<string, unknown>, deleteFlag: boolean = true) => {
    for (const key in data) {
      if (deleteFlag && ['', null].includes(data[key] as string | null)) {
        delete data[key];
      }
    }

    history.push({
      search: qs.stringify(data, stringifyOptions),
    });
  };

  const setQuery = useCallback<SetQuery<T>>(
    (key, value, deleteFlag) => {
      // 임시 page reset
      const resetPageToken = query.page ? { page: 1 } : {};
      push({ ...query, ...resetPageToken, ...(typeof key === 'string' ? { [key]: value } : key) }, deleteFlag);
    },
    [query]
  );

  const deleteQuery = useCallback(
    (key: string | string[]) => {
      const clone = { ...query };

      if (typeof key === 'string') {
        delete clone[key];
      } else {
        key.forEach((key) => {
          delete clone[key];
        });
      }

      push(clone);
    },
    [query]
  );

  const resetQuery = useCallback((data?: QueryObject) => {
    push(data || {});
  }, []);

  const handleFilterChange = useCallback<SetQuery<T>>(
    (key, value) => {
      const data = {
        size: query.size,
        ...(typeof key === 'string' ? { [key]: value as Value } : key),
        page: 1,
      } as QueryObject;

      push(data);
    },
    [query.size]
  );

  const handlePaginationChange = useCallback(
    (value: number) => {
      setQuery('page', value);
    },
    [setQuery]
  );

  useEffect(() => {
    if (initialState && (location.search === '' || skip)) {
      history.replace({
        search: qs.stringify({ ...query, ...initialState }, stringifyOptions),
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (onQueryChanged) {
    }
    onQueryChanged && onQueryChanged(query);
  }, [query]);

  return {
    query,
    setQuery,
    deleteQuery,
    resetQuery,
    handleFilterChange,
    handlePaginationChange,
    skip,
  };
};

export default useQueryString;

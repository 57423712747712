import { ChangeEvent, useCallback, CSSProperties, useState, useEffect } from 'react';
import * as React from 'react';

import styled from 'styled-components';

import { DEFAULT_VALUE } from '@/assets/constants';
import { InputType, Input } from '@/components/template';
import { debounce } from '@/utils/etc';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * 기본값
   */
  defaultValue?: string;
  /**
   * Input 타입 설정
   */
  type?: InputType;
  /**
   * 입력 값 변경 시 실행할 함수
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * 커스텀 스타일
   */
  customStyle?: CSSProperties | string;
  /**
   * toggle debounce
   */
  debounce?: number;
}

export const StyledInput = styled(Input)<{ customStyle?: CSSProperties | string }>`
  height: ${({ type }: any) => (type === 'textarea' ? '120px' : '40px')};

  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 12px;
  }
  :-ms-input-placeholder {
    font-size: 12px;
  }

  ${({ customStyle }: any) => customStyle}
`;

const TextInputDebounce = debounce();

/**
 * 다양한 input 타입의 태그들을 사용할 수 있는 text 입력 컴포넌트이다.
 */
function TextInput({ type = 'text', onChange, debounce, ...inputProps }: InputProps) {
  const [value, setValue] = useState(inputProps.value ?? '');

  useEffect(() => {
    setValue(inputProps.value ?? DEFAULT_VALUE.STRING);
  }, [inputProps.value]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);

      if (debounce) {
        e.persist();

        TextInputDebounce(() => onChange(e), debounce);
      } else {
        onChange(e);
      }
    },
    [onChange, debounce]
  );

  return <StyledInput {...inputProps} value={value} type={type} onChange={onInputChange} />;
}

export default TextInput;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role, RoleFormInterface, RoleQueryInterface, RoleState } from '@/pages/Roles/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

const initialState: RoleState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    fetchRoleListRequest: {
      prepare: (search: RoleQueryInterface) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchRoleListSuccess: {
      prepare: (payload: ListResponse<Role>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<Role>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchRoleListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchRoleDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchRoleDetailSuccess: {
      prepare: (payload: DetailResponse<Role>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<Role>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchRoleDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createRoleRequest: {
      prepare: (data: RoleFormInterface) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createRoleSuccess: {
      prepare: (payload: Role) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createRoleFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateRoleRequest: {
      prepare: (id: string, data: RoleFormInterface) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateRoleSuccess: {
      prepare: (payload: Role) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateRoleFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchRoleListRequest,
  fetchRoleListSuccess,
  fetchRoleListFailure,
  fetchRoleDetailRequest,
  fetchRoleDetailSuccess,
  fetchRoleDetailFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
  updateRoleFailure,
} = roleSlice.actions;

export default roleSlice.reducer;

import { createAction } from '@reduxjs/toolkit';

import { TaxPrefillDescriptionDto } from '@/pages/TaxPrefillDescription/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.memberSvc}/taxPrefillDescription`;

export const createTaxPrefillDescriptionRequest = createAction<TaxPrefillDescriptionDto>(`${PREFIX}/createTaxPrefillDescriptionRequest`);
export const createTaxPrefillDescriptionSuccess = createAction<TaxPrefillDescriptionDto>(`${PREFIX}/createTaxPrefillDescriptionSuccess`);
export const createTaxPrefillDescriptionFailure = createAction<string>(`${PREFIX}/createTaxPrefillDescriptionFailure`);

export const updateTaxPrefillDescriptionRequest = createAction<{ id: string; data: TaxPrefillDescriptionDto }>(
  `${PREFIX}/updateTaxPrefillDescriptionRequest`
);
export const updateTaxPrefillDescriptionSuccess = createAction(`${PREFIX}/updateTaxPrefillDescriptionSuccess`);
export const updateTaxPrefillDescriptionFailure = createAction<string>(`${PREFIX}/updateTaxPrefillDescriptionFailure`);

import styled from 'styled-components';

import Number from '@/components/ValueField/Number';
import Text from '@/components/ValueField/Text';

interface CurrencyProps {
  /**
   * 돈과 같은 값
   */
  value: string | number;
  /**
   * `value` 옆에 붙는 단위
   */
  unit?: string;
  /**
   * 1000 단위로 `,`를 붙일 지에 대한 여부
   */
  enableComma?: boolean;
}

const CurrencyText = styled(Text)`
  > span + span {
    padding-left: 0.625rem;
  }
`;

/**
 * 돈과 단위를 함께 나타내는 컴포넌트이다.
 */
function Currency({ value, unit, enableComma }: CurrencyProps) {
  return (
    <CurrencyText>
      <Number value={+value} tag='span' enableComma={enableComma} />
      {unit && <Text tag='span'>{unit}</Text>}
    </CurrencyText>
  );
}

export default Currency;

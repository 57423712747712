import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { DriverDto, DriverWithData } from '@/pages/Drivers/type';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface DriverState {
  Driver: PaginationStoreState<DriverDto | DriverWithData> &
    StandardStoreState & {
      isSubmitting: boolean;
      isDeletingLoginIdentifier: boolean;
      isDriversExporting: boolean;
      isLoginIdentifiersMigrating: boolean;
      isAutoDispatchStatusSetting: boolean;
      isBanning: boolean;
      isDriverUpdating: boolean;
      isToggleDelivery: boolean;
      isResetStatus: boolean;
    };
  DriverPingLogs: StandardStoreState & {
    pingLogs: any[];
    page: { total: number; current: number; limit?: number };
    totalCount: number;
  };
  dispatchStatusList: {
    content: any[];
    page: any;
  };
  searchCars: Omit<PaginationStoreState, 'totalCount' | 'page'> & { isFetching: boolean };
  downloadingTemplate: boolean;
}

const initialState: DriverState = {
  Driver: {
    isFetching: false,
    page: { total: 1, current: 1 },
    ids: [],
    byId: {},
    totalCount: 0,
    error: null,
    isSubmitting: false,
    isDeletingLoginIdentifier: false,
    isDriversExporting: false,
    isLoginIdentifiersMigrating: false,
    isAutoDispatchStatusSetting: false,
    isBanning: false,
    isDriverUpdating: false,
    isToggleDelivery: false,
    isResetStatus: false,
  },
  DriverPingLogs: {
    isFetching: false,
    page: { total: 1, current: 1 },
    totalCount: 0,
    error: null,
    pingLogs: [],
  },
  dispatchStatusList: {
    content: [],
    page: {},
  },
  searchCars: { isFetching: false, byId: {}, ids: [] },
  downloadingTemplate: false,
};

export const deleteDriverRequest = createAction<string>('driver/deleteDriverRequest');
export const deleteDriverSuccess = createAction('driver/deleteDriverSuccess');
export const deleteDriverFailure = createAction<string>('driver/deleteDriverFailure');

export const setDynamicHeatmapRequest = createAction('driver/setDynamicHeatmapRequest', (id, body) => ({
  payload: { id, body },
}));
export const setDynamicHeatmapFailure = createAction<string>('driver/setDynamicHeatmapFailure');

export const banHourlyRentalDriverRequest = createAction<any>('driver/banHourlyRentalDriverRequest');
export const banHourlyRentalDriverSuccess = createAction<any>('driver/banHourlyRentalDriverSuccess');
export const banHourlyRentalDriverFailure = createAction<string>('driver/banHourlyRentalDriverFailure');

export const fetchDriverDispatchStatusRequest = createAction<any>('driver/fetchDriverDispatchStatusRequest');
export const fetchDriverDispatchStatusFailure = createAction<string>('driver/fetchDriverDispatchStatusFailure');

export const resignDriverRequest = createAction<string>('driver/resignDriverRequest');
export const resignDriverSuccess = createAction<any>('driver/resignDriverSuccess');
export const resignDriverFailure = createAction<string>('driver/resignDriverFailure');

export const recoverDriverRequest = createAction<string>('driver/recoverDriverRequest');
export const recoverDriverSuccess = createAction<any>('driver/recoverDriverSuccess');
export const recoverDriverFailure = createAction<string>('driver/recoverDriverFailure');

export const fetchRateInfoRequest = createAction<string>('driver/fetchRateInfoRequest');
export const fetchRateInfoFailure = createAction<string>('driver/fetchRateInfoFailure');

export const fetchDispatchStatusByDriverRequest = createAction<string>('driver/fetchDispatchStatusByDriverRequest');
export const fetchDispatchStatusByDriverFailure = createAction<string>('driver/fetchDispatchStatusByDriverFailure');

export const updateDispatchStatusByDriverRequest = createAction('driver/updateDispatchStatusByDriverRequest', (id, body) => ({
  payload: { id, body },
}));
export const updateDispatchStatusByDriverSuccess = createAction<any>('driver/updateDispatchStatusByDriverSuccess');
export const updateDispatchStatusByDriverFailure = createAction<string>('driver/updateDispatchStatusByDriverFailure');

export const updateOverrideDriverLocationRequest = createAction<{ id: string; data: { presetId: number } }>(
  'driver/updateOverrideDriverLocationRequest'
);
export const updateOverrideDriverLocationSuccess = createAction('driver/updateOverrideDriverLocationSuccess');
export const updateOverrideDriverLocationFailure = createAction<string>('driver/updateOverrideDriverLocationFailure');

const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    fetchDriverListRequest: {
      prepare: (param) => ({ payload: param }),
      reducer: (state) => {
        state.Driver.isFetching = true;
        state.Driver.error = null;
      },
    },
    fetchDriverListSuccess(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.byId = { ...state.Driver.byId, ...payload.entities.content };
      state.Driver.ids = payload.result.content;
      state.Driver.page = {
        total: payload.result.totalPages,
        current: payload.result.pageable.pageNumber + 1,
        limit: payload.result.size,
      };
      state.Driver.totalCount = payload.result.totalElements;
    },
    fetchDriverListFailure(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.error = payload;
    },

    fetchDriverRestrictListRequest: {
      prepare: (param) => ({ payload: param }),
      reducer: (state) => {
        state.Driver.isFetching = true;
        state.Driver.error = null;
      },
    },
    fetchDriverRestrictListSuccess(state, { payload }) {
      let ids = [...state.Driver.ids];

      if (payload.result.pageable.pageNumber === 0) {
        ids = payload.result.content;
      } else {
        ids.push(...payload.result.content);
      }

      state.Driver.isFetching = false;
      state.Driver.byId = { ...state.Driver.byId, ...payload.entities.content };
      state.Driver.ids = ids;
      state.Driver.page = {
        total: payload.result.totalPages,
        current: payload.result.pageable.pageNumber + 1,
        limit: payload.result.size,
      };
      state.Driver.totalCount = payload.result.totalElements;
    },
    fetchDriverRestrictListFailure(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.error = payload;
    },

    fetchDriverDetailRequest: {
      prepare: (id: string, query?: { page: number; size: number }) => ({ payload: { id, query } }),
      reducer: (state) => {
        state.Driver.isFetching = true;
        state.Driver.error = null;
      },
    },
    fetchDriverDetailSuccess(state, { payload }) {
      const driver = payload.entities.drivers[payload.result];

      state.Driver.isFetching = false;
      state.Driver.byId = {
        ...state.Driver.byId,
        ...payload.entities.drivers,
        [driver.id]: {
          ...state.Driver.byId[driver.id],
          ...driver,
        },
      };
    },
    fetchDriverDetailFailure(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.error = payload;
    },

    updateDriverRequest: {
      prepare: (id: string, body: any) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.Driver.isDriverUpdating = true;
        state.Driver.error = null;
      },
    },
    updateDriverSuccess(state) {
      state.Driver.isDriverUpdating = false;
    },
    updateDriverFailure(state, { payload }) {
      state.Driver.isDriverUpdating = false;
      state.Driver.error = payload;
    },

    banDriverRequest: {
      prepare: (id: string, body: any) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.Driver.isBanning = true;
      },
    },
    banDriverSuccess(state) {
      state.Driver.isBanning = false;
    },
    banDriverFailure(state, { payload }) {
      state.Driver.isBanning = false;
      state.Driver.error = payload;
    },

    resetStatusRequest: {
      prepare: (id) => ({ payload: id }),
      reducer: (state) => {
        state.Driver.isResetStatus = true;
      },
    },
    resetStatusSuccess(state) {
      state.Driver.isResetStatus = false;
    },
    resetStatusFailure(state) {
      state.Driver.isResetStatus = false;
    },

    resetLocationUpdatedAtRequest: {
      prepare: (body) => ({ payload: body }),
      reducer: (state) => {
        state.Driver.isFetching = true;
        state.Driver.error = null;
      },
    },
    resetLocationUpdatedAtSuccess(state) {
      state.Driver.isFetching = false;
    },
    resetLocationUpdatedAtFailure(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.error = payload;
    },

    setDeliveryRequest: {
      prepare: (id, body) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.Driver.isToggleDelivery = true;
        state.Driver.error = null;
      },
    },
    setDeliverySuccess(state) {
      state.Driver.isToggleDelivery = false;
    },
    setDeliveryFailure(state, { payload }) {
      state.Driver.isToggleDelivery = false;
      state.Driver.error = payload;
    },

    setDynamicHeatmapSuccess(state, { payload }) {
      state.Driver.isFetching = false;
      state.Driver.byId[payload.id] = { ...payload };
    },

    setAutoDispatchStatusRequest: {
      prepare: (id, body) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.Driver.isAutoDispatchStatusSetting = true;
        state.Driver.error = null;
      },
    },
    setAutoDispatchStatusSuccess(state, { payload }) {
      state.Driver.isAutoDispatchStatusSetting = false;
      (state.Driver.byId[payload.id] as DriverWithData).dispatchStatus.autoDispatch = payload.autoDispatch;
    },
    setAutoDispatchStatusFailure(state, { payload }) {
      state.Driver.isAutoDispatchStatusSetting = false;
      state.Driver.error = payload;
    },

    fetchDriverPingLogsRequest: {
      prepare: (id, param) => ({ payload: { id, param } }),
      reducer: (state) => {
        state.Driver.isFetching = true;
        state.Driver.error = null;
      },
    },
    fetchDriverPingLogsSuccess(state, { payload }) {
      state.DriverPingLogs.isFetching = false;
      state.DriverPingLogs.pingLogs = payload.content;
      state.DriverPingLogs.page = { total: payload.totalPages, current: payload.number + 1, limit: payload.size };
      state.DriverPingLogs.totalCount = payload.totalElements;
    },
    fetchDriverPingLogsFailure(state, { payload }) {
      state.DriverPingLogs.isFetching = false;
      state.DriverPingLogs.error = payload;
    },

    removeDriverPingLogs(state) {
      state.DriverPingLogs.pingLogs = [];
    },

    fetchDriverDispatchStatusSuccess(state, { payload }) {
      state.dispatchStatusList.content = payload.content;
      state.dispatchStatusList.page = {
        ...payload.pageable,
        totalPages: payload.totalPages,
        totalCount: payload.totalElements,
      };
    },

    deleteDriverLoginIdentifierRequest: {
      prepare: (userId, loginIdentifierId) => ({ payload: { userId, loginIdentifierId } }),
      reducer: (state) => {
        state.Driver.isDeletingLoginIdentifier = true;
        state.Driver.error = null;
      },
    },
    deleteDriverLoginIdentifierSuccess(state) {
      state.Driver.isDeletingLoginIdentifier = false;
    },
    deleteDriverLoginIdentifierFailure(state, { payload }) {
      state.Driver.error = payload;
      state.Driver.isDeletingLoginIdentifier = false;
    },

    fetchRateInfoSuccess(state, { payload }) {
      const driver = { ...state.Driver.byId[payload.uuid], ...payload };

      state.Driver.isFetching = false;
      state.Driver.byId[driver.id] = driver;
    },

    removeDriverData(state) {
      state.Driver = {
        ...state.Driver,
        byId: {},
        ids: [],
        isFetching: false,
        error: null,
      };
    },

    fetchDispatchStatusByDriverSuccess: {
      prepare: (driverId: string, dispatchStatus: any) => ({ payload: { driverId, dispatchStatus } }),
      reducer: (state, { payload: { driverId, dispatchStatus } }: PayloadAction<{ driverId: string; dispatchStatus: any }>) => {
        (state.Driver.byId[driverId] as DriverWithData).dispatchStatus = dispatchStatus;
        (state.Driver.byId[dispatchStatus.id] as DriverWithData).dispatchStatus = dispatchStatus;
      },
    },
  },
});

export const {
  fetchDriverListRequest,
  fetchDriverListSuccess,
  fetchDriverListFailure,
  fetchDriverRestrictListRequest,
  fetchDriverRestrictListSuccess,
  fetchDriverRestrictListFailure,
  fetchDriverDetailRequest,
  fetchDriverDetailSuccess,
  fetchDriverDetailFailure,
  updateDriverRequest,
  updateDriverSuccess,
  updateDriverFailure,
  banDriverRequest,
  banDriverSuccess,
  banDriverFailure,
  resetStatusRequest,
  resetStatusSuccess,
  resetStatusFailure,
  resetLocationUpdatedAtRequest,
  resetLocationUpdatedAtSuccess,
  resetLocationUpdatedAtFailure,
  setDeliveryRequest,
  setDeliverySuccess,
  setDeliveryFailure,
  setDynamicHeatmapSuccess,
  setAutoDispatchStatusRequest,
  setAutoDispatchStatusSuccess,
  setAutoDispatchStatusFailure,
  fetchDriverPingLogsRequest,
  fetchDriverPingLogsSuccess,
  fetchDriverPingLogsFailure,
  removeDriverPingLogs,
  fetchDriverDispatchStatusSuccess,
  deleteDriverLoginIdentifierRequest,
  deleteDriverLoginIdentifierSuccess,
  deleteDriverLoginIdentifierFailure,
  fetchRateInfoSuccess,
  removeDriverData,
  fetchDispatchStatusByDriverSuccess,
} = driverSlice.actions;

export default driverSlice.reducer;

import { PropsWithChildren } from 'react';

import { SWRConfig } from 'swr';

import { showErrorToast } from '@/components/etc';
import { fetcher } from '@/utils/request/hooks';

function SWRProvider({ children }: PropsWithChildren<{}>) {
  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (err) => {
          err?.message && showErrorToast(err.message);
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
      }}
    >
      {children}
    </SWRConfig>
  );
}

export default SWRProvider;

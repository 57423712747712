import { Modal as ModalComponent } from 'reactstrap';

import { CSSModule, FadeProps } from '@/components/template/type';

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  isOpen?: boolean;
  autoFocus?: boolean;
  size?: string;
  toggle?: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  keyboard?: boolean;
  backdrop?: boolean | 'static';
  scrollable?: boolean;
  onEnter?: () => void;
  onExit?: () => void;
  onOpened?: () => void;
  onClosed?: () => void;
  cssModule?: CSSModule;
  wrapClassName?: string;
  modalClassName?: string;
  backdropClassName?: string;
  contentClassName?: string;
  zIndex?: number | string;
  fade?: boolean;
  backdropTransition?: FadeProps;
  modalTransition?: FadeProps;
  centered?: boolean;
  external?: React.ReactNode;
  labelledBy?: string;
  unmountOnClose?: boolean;
  returnFocusAfterClose?: boolean;
  container?: string | HTMLElement | React.RefObject<HTMLElement>;
  innerRef?: React.Ref<HTMLElement>;
  trapFocus?: boolean;
}

export function Modal(props: ModalProps) {
  return <ModalComponent {...props} />;
}

import { Form, useFormikContext } from 'formik';

import CardTemplate from '@/components/CardTemplate';
import HeaderContent from '@/components/HeaderContent';
import { FormikErrorMessage } from '@/components/etc';
import { Button, Col, FormGroup, Input } from '@/components/template';
import { OtpWhitelistBody } from '@/pages/OtpWhitelist/types';

export default function FormContent() {
  const { handleSubmit, getFieldProps, isValid } = useFormikContext<OtpWhitelistBody>();

  return (
    <Form onSubmit={handleSubmit}>
      <CardTemplate
        headerChildren={<HeaderContent title={'Create Otp Whitelist'} />}
        footerChildren={
          <Button color='primary' type='submit' disabled={!isValid}>
            Create Otp Whitelist
          </Button>
        }
      >
        <FormGroup row>
          <Col md={3}>Phone Number</Col>
          <Col md={9}>
            <Input {...getFieldProps('phoneNumber')} placeholder='+1234567890' />
            <FormikErrorMessage name='phoneNumber' />
          </Col>
        </FormGroup>
      </CardTemplate>
    </Form>
  );
}

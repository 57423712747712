import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAvailableWarehouseRequest,
  fetchAvailableWarehouseFailure,
  fetchAvailableWarehouseSuccess,
  createWarehouseRequest,
  createWarehouseSuccess,
  createWarehouseFailure,
  updateWarehouseRequest,
  updateWarehouseSuccess,
  updateWarehouseFailure,
  addWarehouseAgentRequest,
  addWarehouseAgentSuccess,
  addWarehouseAgentFailure,
  deleteWarehouseAgentRequest,
  deleteWarehouseAgentSuccess,
  deleteWarehouseAgentFailure,
} from '@/svc/delivery-svc/warehouse/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchAvailableWarehouse({ payload: region }: ReturnType<typeof fetchAvailableWarehouseRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/warehouse/available', { params: { region: region }, schema: [entity] });

    if (response.ok) {
      yield put(fetchAvailableWarehouseSuccess(response.data));
    } else {
      yield put(fetchAvailableWarehouseFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAvailableWarehouseFailure((e as Error).message));
  }
}

function* createWarehouse({ payload: data }: ReturnType<typeof createWarehouseRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/warehouse', { data });

    if (response.ok) {
      yield put(createWarehouseSuccess());
    } else {
      yield put(createWarehouseFailure(response.data.message));
    }
  } catch (e) {
    yield put(createWarehouseFailure((e as Error).message));
  }
}

function* updateWarehouse({ payload: { id, data } }: ReturnType<typeof updateWarehouseRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/warehouse/${id}`, {
      data: { id, ...data },
    });

    if (response.ok) {
      yield put(updateWarehouseSuccess());
    } else {
      yield put(updateWarehouseFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateWarehouseFailure((e as Error).message));
  }
}

function* addWarehouseAgent({ payload: { id, agentId } }: ReturnType<typeof addWarehouseAgentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/warehouse/${id}/agent/${agentId}`);

    if (response.ok) {
      yield put(addWarehouseAgentSuccess());
    } else {
      yield put(addWarehouseAgentFailure(response.data.message));
    }
  } catch (e) {
    yield put(addWarehouseAgentFailure((e as Error).message));
  }
}

function* deleteWarehouseAgent({ payload: { id, agentId } }: ReturnType<typeof deleteWarehouseAgentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/warehouse/${id}/agent/${agentId}`);

    if (response.ok) {
      yield put(deleteWarehouseAgentSuccess());
    } else {
      yield put(deleteWarehouseAgentFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteWarehouseAgentFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchAvailableWarehouseRequest.type, fetchAvailableWarehouse);
  yield takeLatest(createWarehouseRequest.type, createWarehouse);
  yield takeLatest(updateWarehouseRequest.type, updateWarehouse);
  yield takeLatest(addWarehouseAgentRequest.type, addWarehouseAgent);
  yield takeLatest(deleteWarehouseAgentRequest.type, deleteWarehouseAgent);
}

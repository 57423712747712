import { Nav as NavComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface NavProps extends React.HTMLAttributes<HTMLUListElement> {
  tabs?: boolean;
  pills?: boolean;
  vertical?: boolean | string;
  horizontal?: string;
  justified?: boolean;
  fill?: boolean;
  navbar?: boolean;
  card?: boolean;
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function Nav(props: NavProps) {
  return <NavComponent {...props} />;
}

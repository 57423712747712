import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DocumentEditTitle } from '@/assets/constants';
import {
  DriverApplicationListInterface,
  DriverApplicationDetailInterface,
  AdminApproveCarChangeRequestDto,
  EditField,
  AdminUpdateDriverForm,
  DriverApplicationDetailQuery,
} from '@/pages/DriverApplications/type';
import { listSuccess } from '@/utils/reducerUtils';

export type DocumentFailTitle =
  | 'document'
  | 'carModel'
  | 'documentInfo'
  | 'insurance'
  | 'nric'
  | 'taxi'
  | 'license'
  | 'motorInsurance'
  | 'electronicMarking'
  | 'policeClearanceCertificate'
  | 'tdip';
export interface FailField {
  failMode: boolean;
  failedFields: string[];
  failedFieldsWithRemark: Record<string, string | null>;
}

export interface DriverApplicationState {
  ids: string[];
  byId: { [key: string]: DriverApplicationListInterface | DriverApplicationDetailInterface };
  page: { total: number; current: number; limit?: number };
  totalCount: number;
  isFetching: boolean;
  error: string | null;
  documentApprove: AdminApproveCarChangeRequestDto;
  documentFail: Record<DocumentFailTitle, FailField>;
  documentEdit: Record<DocumentEditTitle, EditField>;
}

const initialState: DriverApplicationState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  documentApprove: {
    engineType: null,
  },
  documentFail: {
    document: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    carModel: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    documentInfo: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    insurance: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    nric: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    taxi: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    tdip: { failMode: false, failedFields: [], failedFieldsWithRemark: {} },
    license: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    motorInsurance: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    electronicMarking: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
    policeClearanceCertificate: {
      failMode: false,
      failedFields: [],
      failedFieldsWithRemark: {},
    },
  },
  documentEdit: {
    carModel: {
      editMode: false,
      editField: {},
      exceptFields: [],
    },
    documentInfo: { editMode: false, editField: {}, exceptFields: [] },
    electronicMarking: { editMode: false, editField: {}, exceptFields: [] },
    insurance: { editMode: false, editField: {}, exceptFields: [] },
    license: { editMode: false, editField: {}, exceptFields: [] },
    motorInsurance: { editMode: false, editField: {}, exceptFields: [] },
    nric: { editMode: false, editField: {}, exceptFields: [] },
    policeClearanceCertificate: { editMode: false, editField: {}, exceptFields: [] },
    taxi: { editMode: false, editField: {}, exceptFields: [] },
    tdip: { editMode: false, editField: {}, exceptFields: [] },
  },
};

export const updateDocumentStatusRequest = createAction('driverApplication/updateDocumentStatusRequest', (id, body) => ({
  payload: { id, body },
}));
export const updateDocumentStatusSuccess = createAction<any>('driverApplication/updateDocumentStatusSuccess');
export const updateDocumentStatusFailure = createAction<string>('driverApplication/updateDocumentStatusFailure');

export const searchDriverApplicationRequest = createAction<any>('driverApplication/searchDriverApplicationRequest');
export const searchDriverApplicationSuccess = createAction<any>('driverApplication/searchDriverApplicationSuccess');
export const searchDriverApplicationFailure = createAction<string>('driverApplication/searchDriverApplicationFailure');

export const updateCarTypeRequest = createAction('driverApplication/updateCarTypeRequest', (id, body) => ({
  payload: { id, body },
}));
export const updateCarTypeSuccess = createAction('driverApplication/updateCarTypeSuccess');
export const updateCarTypeFailure = createAction<string>('driverApplication/updateCarTypeFailure');

export const updateChangeRequestRequest = createAction('driverApplication/updateChangeRequestRequest', (id, body) => ({
  payload: { id, body },
}));
export const updateChangeRequestSuccess = createAction('driverApplication/updateChangeRequestSuccess');
export const updateChangeRequestFailure = createAction<string>('driverApplication/updateChangeRequestFailure');

export const changeApproveOrRejectApplicationRequest = createAction(
  'driverApplication/changeApproveOrRejectApplicationRequest',
  (data: {
    driverId: string;
    applicationId: string;
    approve: boolean;
    approveFields?: AdminApproveCarChangeRequestDto;
    failedFieldsWithRemark?: Record<string, string | null>;
  }) => ({
    payload: data,
  })
);
export const changeApproveOrRejectApplicationSuccess = createAction('driverApplication/changeApproveOrRejectApplicationSuccess');
export const changeApproveOrRejectApplicationFailure = createAction<string>('driverApplication/changeApproveOrRejectApplicationFailure');

export const registerVehicleRequest = createAction<string>('driverApplication/registerVehicleRequest');
export const registerVehicleSuccess = createAction('driverApplication/registerVehicleSuccess');
export const registerVehicleFailure = createAction<string>('driverApplication/registerVehicleFailure');

export const updateInternalDocumentRequest = createAction<{ id: string; data: FormData }>('driverApplication/updateInternalDocumentRequest');
export const updateInternalDocumentSuccess = createAction('driverApplication/updateInternalDocumentSuccess');
export const updateInternalDocumentFailure = createAction<string>('driverApplication/updateInternalDocumentFailure');

export const updateDriverApplicationSuccess = createAction('driverApplication/updateDriverApplicationSuccess', (isChangeRegion: boolean) => ({
  payload: isChangeRegion,
}));

export const resetVehicleChangeStatusSuccess = createAction('driverApplication/resetVehicleChangeStatusSuccess');
export const resetVehicleChangeStatusFailure = createAction<string>('driverApplication/resetVehicleChangeStatusFailure');

const driverApplicationSlice = createSlice({
  name: 'driverApplication',
  initialState,
  reducers: {
    removeDriverApplicationData(state) {
      state.isFetching = false;
      state.error = null;
      state.byId = {};
      state.ids = [];
    },

    fetchDriverApplicationListRequest: {
      prepare: (param) => ({ payload: param }),
      reducer: (state) => {
        state.error = null;
      },
    },
    fetchDriverApplicationListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchDriverApplicationListFailure(state, { payload }) {
      state.error = payload;
    },

    fetchDriverApplicationRestrictListRequest: {
      prepare: (param) => ({ payload: param }),
      reducer: (state) => {
        state.error = null;
      },
    },
    fetchDriverApplicationRestrictListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchDriverApplicationRestrictListFailure(state, { payload }) {
      state.error = payload;
    },

    fetchDriverApplicationDetailRequest: {
      prepare: (id: string, query?: DriverApplicationDetailQuery) => ({ payload: { id, query } }),
      reducer: (state) => {
        state.error = null;
      },
    },
    fetchDriverApplicationDetailSuccess(state, { payload }) {
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    fetchDriverApplicationDetailFailure(state, { payload }) {
      state.error = payload;
    },

    updateDriverApplicationRequest: {
      prepare: (id, body, isChangeRegion?: boolean) => ({ payload: { id, body, isChangeRegion } }),
      reducer: (state) => {
        state.error = null;
      },
    },
    updateDriverApplicationFailure(state, { payload }) {
      state.error = payload;
    },

    resetVehicleChangeStatusRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
      },
    },

    toggleFailMode: (state, { payload }: PayloadAction<DocumentFailTitle>) => {
      state.documentFail[payload].failMode = !state.documentFail[payload].failMode;
      state.documentFail[payload].failedFields = [];
      state.documentFail[payload].failedFieldsWithRemark = {};
    },
    toggleFailField: (state, { payload: { title, field, id } }: PayloadAction<{ title: DocumentFailTitle; field: string; id: string }>) => {
      const fields = [...state.documentFail[title].failedFields];
      const fieldWithRemark = { ...state.documentFail[title].failedFieldsWithRemark };

      fieldWithRemark[field] = !fieldWithRemark?.[field]
        ? (state.byId[id] as DriverApplicationDetailInterface).driverDocument?.remarks[field] || null
        : null;

      state.documentFail[title].failedFields = fields.includes(field) ? fields.filter((e) => e !== field) : fields.concat(field);
      state.documentFail[title].failedFieldsWithRemark = fieldWithRemark;
    },
    setFailFieldWithRemark: (
      state,
      { payload: { title, field, remark } }: PayloadAction<{ title: DocumentFailTitle; field: string; remark: string }>
    ) => {
      state.documentFail[title].failedFieldsWithRemark = {
        ...state.documentFail[title].failedFieldsWithRemark,
        [field]: remark,
      };
    },

    setApproveField: (state, { payload }: PayloadAction<AdminApproveCarChangeRequestDto>) => {
      state.documentApprove = { ...payload };
    },

    toggleEditMode: (state, { payload }: PayloadAction<DocumentEditTitle>) => {
      state.documentEdit[payload].editMode = !state.documentEdit[payload].editMode;
      state.documentEdit[payload].editField = {};
      state.documentEdit[payload].exceptFields = [];
    },
    setEditField: (
      state,
      {
        payload: { title, field, exceptFields = [] },
      }: PayloadAction<{ title: DocumentEditTitle; field: AdminUpdateDriverForm; exceptFields?: string[] }>
    ) => {
      state.documentEdit[title].editField = {
        ...state.documentEdit[title].editField,
        ...field,
      };
      state.documentEdit[title].exceptFields = [...state.documentEdit[title].exceptFields, ...exceptFields];
    },
  },
});

export const {
  removeDriverApplicationData,
  fetchDriverApplicationListRequest,
  fetchDriverApplicationListSuccess,
  fetchDriverApplicationListFailure,
  fetchDriverApplicationRestrictListRequest,
  fetchDriverApplicationRestrictListSuccess,
  fetchDriverApplicationRestrictListFailure,
  fetchDriverApplicationDetailRequest,
  fetchDriverApplicationDetailSuccess,
  fetchDriverApplicationDetailFailure,
  updateDriverApplicationRequest,
  updateDriverApplicationFailure,
  resetVehicleChangeStatusRequest,
  toggleFailMode,
  toggleFailField,
  setFailFieldWithRemark,
  setApproveField,
  toggleEditMode,
  setEditField,
} = driverApplicationSlice.actions;

export default driverApplicationSlice.reducer;

import { CardFooter as CardFooterComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface CardFooterProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function CardFooter(props: CardFooterProps) {
  return <CardFooterComponent {...props} />;
}

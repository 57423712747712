import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "FaqListManagementList" */ '@/pages/ShbLoanInfo/List'));
const Detail = lazy(() => import(/* webpackChunkName: "FaqListManagementDetail" */ '@/pages/ShbLoanInfo/Detail'));

export const shbLoanInfoRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.ShbLoanInfo,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.ShbLoanInfo}/:id`,
    component: Detail,
    exact: true,
  },
];

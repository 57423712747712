import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SafetyAccidentLastSelectedData,
  SafetyAccidentQuestionDisplayOrderDto,
  SafetyAccidentQuestionUpsertDto,
} from '@/pages/SafetyAccidentListManagement/type';
import { SafetyAccidentTopicDisplayOrderDto, SafetyAccidentTopicUpsertDto } from '@/pages/SafetyAccidentTopicManagement/type';
import { SafetyAccidentState } from '@/svc/cs-svc/safetyAccidentManagement/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.csSvc}/safetyAccidentManagement`;

// topic
export const updateSafetyAccidentTopicRequest = createAction<SafetyAccidentTopicUpsertDto>(`${PREFIX}/updateSafetyAccidentTopicRequest`);
export const updateSafetyAccidentTopicSuccess = createAction(`${PREFIX}/updateSafetyAccidentTopicSuccess`);
export const updateSafetyAccidentTopicFailure = createAction<string>(`${PREFIX}/updateSafetyAccidentTopicFailure`);

export const deleteSafetyAccidentTopicRequest = createAction<string>(`${PREFIX}/deleteSafetyAccidentTopicRequest`);
export const deleteSafetyAccidentTopicSuccess = createAction(`${PREFIX}/deleteSafetyAccidentTopicSuccess`);
export const deleteSafetyAccidentTopicFailure = createAction<string>(`${PREFIX}/deleteSafetyAccidentTopicFailure`);

export const bulkUpdateSafetyAccidentTopicDisplayOrderRequest = createAction<SafetyAccidentTopicDisplayOrderDto[]>(
  `${PREFIX}/bulkUpdateSafetyAccidentTopicDisplayOrderRequest`
);
export const bulkUpdateSafetyAccidentTopicDisplayOrderSuccess = createAction(`${PREFIX}/bulkUpdateSafetyAccidentTopicDisplayOrderSuccess`);
export const bulkUpdateSafetyAccidentTopicDisplayOrderFailure = createAction<string>(`${PREFIX}/bulkUpdateSafetyAccidentTopicDisplayOrderFailure`);

// question
export const deleteSafetyAccidentQuestionRequest = createAction<string>(`${PREFIX}/deleteSafetyAccidentQuestionRequest`);
export const deleteSafetyAccidentQuestionSuccess = createAction(`${PREFIX}/deleteSafetyAccidentQuestionSuccess`);
export const deleteSafetyAccidentQuestionFailure = createAction<string>(`${PREFIX}/deleteSafetyAccidentQuestionFailure`);

export const rejectSafetyAccidentQuestionRequest = createAction<string>(`${PREFIX}/rejectSafetyAccidentQuestionRequest`);
export const rejectSafetyAccidentQuestionSuccess = createAction(`${PREFIX}/rejectSafetyAccidentQuestionSuccess`);
export const rejectSafetyAccidentQuestionFailure = createAction<string>(`${PREFIX}/rejectSafetyAccidentQuestionFailure`);

export const releaseSafetyAccidentQuestionRequest = createAction<string>(`${PREFIX}/releaseSafetyAccidentQuestionRequest`);
export const releaseSafetyAccidentQuestionSuccess = createAction(`${PREFIX}/releaseSafetyAccidentQuestionSuccess`);
export const releaseSafetyAccidentQuestionFailure = createAction<string>(`${PREFIX}/releaseSafetyAccidentQuestionFailure`);

export const bulkUploadDisplayOrderSafetyAccidentQuestionRequest = createAction<SafetyAccidentQuestionDisplayOrderDto[]>(
  `${PREFIX}/bulkUploadDisplayOrderSafetyAccidentQuestionRequest`
);
export const bulkUploadDisplayOrderSafetyAccidentQuestionSuccess = createAction(`${PREFIX}/bulkUploadDisplayOrderSafetyAccidentQuestionSuccess`);
export const bulkUploadDisplayOrderSafetyAccidentQuestionFailure = createAction<string>(
  `${PREFIX}/bulkUploadDisplayOrderSafetyAccidentQuestionFailure`
);

export const upsertSafetyAccidentQuestionRequest = createAction<SafetyAccidentQuestionUpsertDto>(`${PREFIX}/upsertSafetyAccidentQuestionRequest`);
export const upsertSafetyAccidentQuestionSuccess = createAction(`${PREFIX}/upsertSafetyAccidentQuestionSuccess`);
export const upsertSafetyAccidentQuestionFailure = createAction<string>(`${PREFIX}/upsertSafetyAccidentQuestionFailure`);

export const safetyAccidentManagementInitialState: SafetyAccidentState = {
  lastSelectedData: {},
};

const safetyAccidentSlice = createSlice({
  name: `${PREFIX}`,
  initialState: safetyAccidentManagementInitialState,
  reducers: {
    setLastSelectedData(state, { payload }: PayloadAction<SafetyAccidentLastSelectedData>) {
      state.lastSelectedData = payload;
    },
  },
});

export const { setLastSelectedData } = safetyAccidentSlice.actions;

export default safetyAccidentSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "HelpCenterTopicManagementList" */ '@/pages/HelpCenterTopicManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "HelpCenterTopicManagementDetail" */ '@/pages/HelpCenterTopicManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "HelpCenterTopicManagementNew" */ '@/pages/HelpCenterTopicManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "HelpCenterTopicManagementEdit" */ '@/pages/HelpCenterTopicManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "HelpCenterTopicManagementOrder" */ '@/pages/HelpCenterTopicManagement/Order'));

export const helpCenterTopicManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.HelpCenterTopicManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.HelpCenterTopicManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.HelpCenterTopicManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.HelpCenterTopicManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.HelpCenterTopicManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

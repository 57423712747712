import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AlipayTransactionsList" */ '@/pages/AlipayTransactions/List'));
const Detail = lazy(() => import(/* webpackChunkName: "AlipayTransactionsDetail" */ '@/pages/AlipayTransactions/Detail'));

export const alipayTransactionsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.AlipayTransactions,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.AlipayTransactions}/:id`,
    component: Detail,
    exact: true,
  },
];

import { ReactNode } from 'react';

import styled from 'styled-components';

import Null from '@/components/Null';
import { Col } from '@/components/template';

export interface Option<T, F = unknown> {
  key: string;
  name?: ReactNode;
  visible?: boolean;
  renderContent?: (data: T, additionalData: F) => ReactNode;
}

export interface DetailProps<T, F extends Object> {
  /**
   * 리스트화 시킬 Option 타입의 배열
   */
  options: Array<Option<T, F>>;
  /**
   * `any` 타입의 데이터
   */
  data: T;
  /**
   * 해당 아이템 `width` 값 조절 여부
   */
  halfWidth?: boolean;
  ratio?: [number, number];
  additionalData?: F;
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;

  & + & {
    margin-top: 1.25rem;
  }
`;

const StyledCol = styled(Col)`
  padding: 0 0.25rem;
`;

/**
 * `options`를 리스트화 시키는 Detail 컴포넌트이다.
 */
function Detail<T = any, F extends Object = Object>({ options, data, halfWidth, ratio, additionalData = {} as F }: DetailProps<T, F>) {
  return (
    <List>
      {options
        .filter(({ visible }) => visible ?? true)
        .map(({ key, name, renderContent }) => (
          <ListItem key={key}>
            <StyledCol sm={ratio?.[0] ?? (halfWidth ? 4 : 2)}>{name ?? key}</StyledCol>
            {/* @ts-ignore */}
            <StyledCol sm={ratio?.[1] ?? (halfWidth ? 8 : 10)}>{renderContent?.(data, additionalData) ?? data[key as keyof T] ?? <Null />}</StyledCol>
          </ListItem>
        ))}
    </List>
  );
}

export default Detail;

import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { CorporateManagerState } from '@/pages/CorporateManager/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';

const initialState: CorporateManagerState = {
  ids: [],
  byId: {},
  totalCount: 0,
  isFetching: false,
  error: null,
  page: { total: 1, current: 1 },
  search: { isFetching: false, byId: {}, ids: [] },
};

const failure = (state: CorporateManagerState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

export const deleteCorporateManagerSuccess = createAction('corporateManager/deleteCorporateManagerSuccess');

const corporateManagerSlice = createSlice({
  name: 'corporateManager',
  initialState,
  reducers: {
    fetchCorporateManagerListRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (param) => ({ payload: param }),
    },
    fetchCorporateManagerListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchCorporateManagerListFailure: failure,

    fetchCorporateManagerDetailRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id) => ({ payload: id }),
    },
    fetchCorporateManagerDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchCorporateManagerDetailFailure: failure,

    createCorporateManagerRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (body) => ({ payload: body }),
    },
    createCorporateManagerSuccess: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    createCorporateManagerFailure: failure,

    updateCorporateManagerRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id, body) => ({ payload: { id, body } }),
    },
    updateCorporateManagerSuccess: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    updateCorporateManagerFailure: failure,

    deleteCorporateManagerRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (id) => ({ payload: id }),
    },
    deleteCorporateManagerFailure: failure,
  },
});

export const {
  fetchCorporateManagerListRequest,
  fetchCorporateManagerListSuccess,
  fetchCorporateManagerListFailure,
  fetchCorporateManagerDetailRequest,
  fetchCorporateManagerDetailSuccess,
  fetchCorporateManagerDetailFailure,
  createCorporateManagerRequest,
  createCorporateManagerSuccess,
  createCorporateManagerFailure,
  updateCorporateManagerRequest,
  updateCorporateManagerSuccess,
  updateCorporateManagerFailure,
  deleteCorporateManagerRequest,
  deleteCorporateManagerFailure,
} = corporateManagerSlice.actions;

export default corporateManagerSlice.reducer;

import { useMenuManager } from '@/components/MenuManager/hooks';
import { useSidebarSearch } from '@/layout/Sidebar/hooks';
import SidebarContent from '@/layout/Sidebar/parts/SidebarContent';
import SidebarHeader from '@/layout/Sidebar/parts/SidebarHeader';
import SidebarSearch from '@/layout/Sidebar/parts/SidebarSearch';
import { useAppState, useConfigSelector } from '@/store/selector';

export default function Sidebar() {
  const { permissions } = useConfigSelector('auth');
  const pageStatusValues = useAppState((state) => state.menuManager.pageStatusValues);
  const { pageName, findNavItems, navs, findMenu, handleResetPageItems } = useSidebarSearch({ pageStatusValues, permissions });

  useMenuManager();

  return (
    <div className={`sidebar ${import.meta.env.VITE_NODE_ENV}`} style={{ width: '250px' }}>
      <SidebarHeader onClickLogo={handleResetPageItems} />
      <SidebarSearch pageName={pageName} findMenu={findMenu} />
      <SidebarContent pageName={pageName} navs={navs} findNavItems={findNavItems} />
    </div>
  );
}

import { createAction, createSlice } from '@reduxjs/toolkit';

import { BulkExportStatementsRequestDto, CorporateInvoiceBulkSvcState, DownloadProgressParam } from '@/svc/corporate-svc/InvoiceBulk/type';
import Service from '@/svc/serviceName';

const initialState: CorporateInvoiceBulkSvcState = {
  isFetching: false,
  error: null,
};

export const bulkCorporateStatementRequest = createAction<BulkExportStatementsRequestDto>(
  `${Service.corporateSvc}/corporateInvoiceBulk/bulkCorporateStatementRequest`
);
export const bulkCorporateStatementSuccess = createAction(`${Service.corporateSvc}/corporateInvoiceBulk/bulkCorporateStatementSuccess`);
export const bulkCorporateStatementFailure = createAction<string>(`${Service.corporateSvc}/corporateInvoiceBulk/bulkCorporateStatementFailure`);

export const downloadBulkProgressRequest = createAction<DownloadProgressParam>(
  `${Service.corporateSvc}/corporateInvoiceBulk/downloadBulkProgressRequest`
);
export const downloadBulkProgressSuccess = createAction(`${Service.corporateSvc}/corporateInvoiceBulk/downloadBulkProgressSuccess`);
export const downloadBulkProgressFailure = createAction<string>(`${Service.corporateSvc}/corporateInvoiceBulk/downloadBulkProgressFailure`);

const corporateInvoiceBulkSlice = createSlice({
  name: `${Service.corporateSvc}/corporateInvoiceBulk`,
  initialState,
  reducers: {},
});

export default corporateInvoiceBulkSlice.reducer;

import { createAction } from '@reduxjs/toolkit';

import { HourlyRentalBanData } from '@/pages/HourlyRentalRequest/type';
import Service from '@/svc/serviceName';

const prefix = `${Service.memberSvc}/hourlyRentalRequest`;

export const banHourlyRentalRequest = createAction<{ id: string; data: HourlyRentalBanData }>(`${prefix}/banHourlyRentalRequest`);
export const banHourlyRentalSuccess = createAction(`${prefix}/banHourlyRentalSuccess`);
export const banHourlyRentalFailure = createAction<string>(`${prefix}/banHourlyRentalFailure`);

export const syncHourlyRentalRequest = createAction<string>(`${prefix}/syncHourlyRentalRequest`);
export const syncHourlyRentalSuccess = createAction(`${prefix}/syncHourlyRentalSuccess`);
export const syncHourlyRentalFailure = createAction<string>(`${prefix}/syncHourlyRentalFailure`);

import { createAction } from '@reduxjs/toolkit';

import { CityFormInterface, City } from '@/pages/Cities/type';
import { CitySvcState } from '@/svc/ride-svc/cities/type';
import Service from '@/svc/serviceName';

export const cityInitialState: CitySvcState = {
  cities: [],
};

export const createCityRequest = createAction<CityFormInterface>(`${Service.rideSvc}/cities/createCityRequest`);
export const createCitySuccess = createAction<City>(`${Service.rideSvc}/cities/createCitySuccess`);
export const createCityFailure = createAction<string>(`${Service.rideSvc}/cities/createCityFailure`);

export const updateCityRequest = createAction(`${Service.rideSvc}/cities/updateCityRequest`, (id: number, data: CityFormInterface) => ({
  payload: { id, data },
}));
export const updateCitySuccess = createAction(`${Service.rideSvc}/cities/updateCitySuccess`);
export const updateCityFailure = createAction<string>(`${Service.rideSvc}/cities/updateCityFailure`);

export const createCityWithZoneRequest = createAction<any>(`${Service.rideSvc}/cities/createCityWithZoneRequest`);
export const createCityWithZoneSuccess = createAction<City>(`${Service.rideSvc}/cities/createCityWithZoneSuccess`);
export const createCityWithZoneFailure = createAction<string>(`${Service.rideSvc}/cities/createCityWithZoneFailure`);

export const getCityGeoJsonRequest = createAction<number>(`${Service.rideSvc}/cities/getCityGeoJsonRequest`);
export const getCityGeoJsonSuccess = createAction(`${Service.rideSvc}/cities/getCityGeoJsonSuccess`);
export const getCityGeoJsonFailure = createAction<string>(`${Service.rideSvc}/cities/getCityGeoJsonFailure`);

// const citiesSlice = createSlice({
//   name: `${Service.rideSvc}/cities`,
//   initialState: cityInitialState,
//   reducers: {},
// });

// export default citiesSlice.reducer;

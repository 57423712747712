import { MouseEvent, useEffect, useRef, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import { checkPermission } from '@/components/CheckPermission';
import { changeMenuChildOrder, changeMenuOrder } from '@/components/MenuManager/reducer';
import SideMenu from '@/layout/Sidebar/SideMenu';
import { BaseNavItemType } from '@/layout/Sidebar/_nav';
import { MenuProvider } from '@/layout/Sidebar/parts/SidebarContext';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';

interface SideMenuProps {
  item: BaseNavItemType;
  parentIndex?: number;
  index: number;
}

function SideMenuContainer({ item, parentIndex, index }: SideMenuProps) {
  const { permissions } = useAppState((state) => state.auth);
  const [isAvailableDrag, setIsAvailableDrag] = useState(false);

  const pagePermissions = Object.keys(permissions);
  const timeoutId = useRef<string | number | NodeJS.Timeout | undefined>();

  const handleMouseDownOverTime = (e: MouseEvent<HTMLLIElement | HTMLDivElement>) => {
    e.stopPropagation();
    timeoutId.current = setTimeout(() => {
      setIsAvailableDrag(true);
    }, 1000);
  };

  const handleMouseUpOverTime = () => {
    clearTimeout(timeoutId.current);
    setIsAvailableDrag(false);
  };

  const handleMouseOutOverTime = () => {
    clearTimeout(timeoutId.current);
    setIsAvailableDrag(false);
  };

  const [{ isDragging }, dragRef] = useDrag<{ index: number }, unknown, { isDragging: boolean }>({
    type: parentIndex ? `SIDEBAR_DROPDOWN_${parentIndex}` : 'SIDEBAR_ITEM',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isAvailableDrag,
  });

  const [{ isHover }, dropRef] = useDrop<{ index: number }, unknown, { isHover: boolean }>({
    accept: parentIndex ? `SIDEBAR_DROPDOWN_${parentIndex}` : 'SIDEBAR_ITEM',
    canDrop: (item) => item.index !== index,
    drop: (item) => {
      if (parentIndex) {
        dispatch(changeMenuChildOrder({ from: item.index, to: index, parentIdx: parentIndex }));
      } else {
        dispatch(changeMenuOrder({ from: item.index, to: index }));
      }
    },
    collect: (monitor) => ({
      isHover: monitor.isOver(),
    }),
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const navPermission = item.permissionType && item.Pages ? checkPermission(item.permissionType, permissions[item.Pages]) : true;

  // children page에서 권한이 없는 page가 있을 경우 or children 페이지가 없고 item 페이지에 권한이 없는 경우에서는 메뉴를 보여주지 않도록 처리
  if (
    item.children?.findIndex((children) => pagePermissions.includes(children.Pages || '')) === -1 ||
    (item.children === undefined && (!pagePermissions.includes(item.Pages || '') || !navPermission))
  ) {
    return <div key={index} />;
  }

  return (
    <MenuProvider
      permissions={permissions}
      item={item}
      index={index}
      key={index}
      dragRef={dragRef}
      dropRef={dropRef}
      isHover={isHover}
      isDragging={isDragging}
      isAvailableDrag={isAvailableDrag}
      handleMouseDownOverTime={handleMouseDownOverTime}
      handleMouseUpOverTime={handleMouseUpOverTime}
      handleMouseOutOverTime={handleMouseOutOverTime}
    >
      <SideMenu />
    </MenuProvider>
  );
}

export default SideMenuContainer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "VehicleMakerList" */ '@/pages/VehicleMaker/List'));
const VehicleModelList = lazy(() => import(/* webpackChunkName: "VehicleModelList" */ '@/pages/VehicleMaker/VehicleModelList'));
const VehicleModelBulkUpload = lazy(() => import(/* webpackChunkName: "VehicleModelBulkUpload" */ '@/pages/VehicleMaker/VehicleModelBulkUpload'));

export const vehicleMakerRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.VehicleMaker,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.VehicleMaker}/:id`,
    component: VehicleModelList,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.VehicleMaker}/:id/bulk`,
    component: VehicleModelBulkUpload,
    exact: true,
  },
];

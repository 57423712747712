import SideMenuItem from '@/layout/Sidebar/SideMenuItem';
import SideSubMenu from '@/layout/Sidebar/SideSubMenu';
import { useMenuContext } from '@/layout/Sidebar/parts/SidebarContext';

function SideMenu() {
  const { item } = useMenuContext();

  if (!item) {
    return null;
  }

  if (item.children) {
    return <SideSubMenu />;
  } else {
    return <SideMenuItem />;
  }
}

export default SideMenu;

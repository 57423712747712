import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';

import { MenuManagerState } from '@/components/MenuManager/type';
import { NavItemType } from '@/layout/Sidebar/_nav';

const initialState: MenuManagerState = {
  pageStatusValues: null,
  isToggleMenuAction: false,
};

const menuManagerSlice = createSlice({
  name: 'menu-manager',
  initialState,
  reducers: {
    setMenuManagerData(state, { payload }: PayloadAction<Partial<MenuManagerState>>) {
      Object.assign(state, payload);
    },
    changeMenuOrder(state, { payload: { from, to } }: PayloadAction<{ from: number; to: number }>) {
      const item = state.pageStatusValues?.[from];
      const pages = state.pageStatusValues;

      if (item && pages) {
        pages.splice(to, 0, item);
        pages.splice(to < from ? from + 1 : from, 1);
      }
    },
    changeMenuChildOrder(state, { payload: { from, to, parentIdx } }: PayloadAction<{ from: number; to: number; parentIdx: number }>) {
      const item = state.pageStatusValues?.[parentIdx].children?.[from];
      const children = state.pageStatusValues?.[parentIdx].children;

      if (item && children !== undefined) {
        children.splice(to, 0, item);
        children.splice(to < from ? from + 1 : from, 1);
      }
    },
    toggleMenu(state, { payload: { name, flag } }: PayloadAction<{ name: string; flag?: boolean }>) {
      const result =
        state.pageStatusValues?.reduce((data: NavItemType[], item: NavItemType) => {
          if (item.name === name) {
            item.isOpenMenu = flag ?? !item.isOpenMenu;
          }
          return [...data, item];
        }, []) ?? [];

      state.pageStatusValues = result;
      state.isToggleMenuAction = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: AnyAction) => action.type !== `${menuManagerSlice.name}/toggleMenu`,
      (state) => {
        state.isToggleMenuAction = false;
      }
    );
  },
});

export const { setMenuManagerData, changeMenuOrder, changeMenuChildOrder, toggleMenu } = menuManagerSlice.actions;

export default menuManagerSlice.reducer;

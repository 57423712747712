import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  bulkUploadRiderMissionParticipantsFailure,
  bulkUploadRiderMissionParticipantsRequest,
  bulkUploadRiderMissionParticipantsSuccess,
  createRiderMissionFailure,
  createRiderMissionRequest,
  createRiderMissionSuccess,
  deleteRiderMissionFailure,
  deleteRiderMissionRequest,
  deleteRiderMissionSuccess,
  excludeBulkRiderMissionFailure,
  excludeBulkRiderMissionRequest,
  excludeBulkRiderMissionSuccess,
  excludeRiderMissionFailure,
  excludeRiderMissionRequest,
  excludeRiderMissionSuccess,
  exportRewardHistoriesFailure,
  exportRewardHistoriesRequest,
  exportRewardHistoriesSuccess,
  exportRiderMissionParticipantsFailure,
  exportRiderMissionParticipantsRequest,
  exportRiderMissionParticipantsSuccess,
  fetchRiderMissionExcludeTemplateFailure,
  fetchRiderMissionExcludeTemplateRequest,
  fetchRiderMissionExcludeTemplateSuccess,
  fetchRiderMissionParticipantsTemplateFailure,
  fetchRiderMissionParticipantsTemplateRequest,
  fetchRiderMissionParticipantsTemplateSuccess,
  fetchRiderMissionSyncTemplateFailure,
  fetchRiderMissionSyncTemplateRequest,
  fetchRiderMissionSyncTemplateSuccess,
  fetchRiderMissionVoucherTemplateFailure,
  fetchRiderMissionVoucherTemplateRequest,
  fetchRiderMissionVoucherTemplateSuccess,
  syncAllRiderMissionFailure,
  syncAllRiderMissionRequest,
  syncAllRiderMissionSuccess,
  syncBulkRiderMissionFailure,
  syncBulkRiderMissionRequest,
  syncBulkRiderMissionSuccess,
  syncRiderMissionFailure,
  syncRiderMissionRequest,
  syncRiderMissionSuccess,
  updateRiderMissionConditionFailure,
  updateRiderMissionConditionRequest,
  updateRiderMissionConditionSuccess,
  updateRiderMissionFailure,
  updateRiderMissionRequest,
  updateRiderMissionSuccess,
  updateTierVoucherFailure,
  updateTierVoucherRequest,
  updateTierVoucherSuccess,
} from '@/svc/incentive-svc/riderMission/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const BASE_PATH = '/admin/v1/rider_missions';

function* createRiderMission({ payload }: ReturnType<typeof createRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}`, {
      data: payload,
      contentType: 'multipart/form-data',
      timeout: 2 * 60 * 1000,
    });

    const action = response.ok ? createRiderMissionSuccess(response.data) : createRiderMissionFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(createRiderMissionFailure((e as Error).message));
  }
}

function* updateRiderMission({ payload: { id, data } }: ReturnType<typeof updateRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_PATH}/${id}`, {
      data,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? updateRiderMissionSuccess(response.data) : updateRiderMissionFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(updateRiderMissionFailure((e as Error).message));
  }
}

function* fetchRiderMissionParticipantsTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/participants:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-participants-template.xlsx');
      yield put(fetchRiderMissionParticipantsTemplateSuccess());
    } else {
      yield put(fetchRiderMissionParticipantsTemplateFailure(response.data));
    }
  } catch (e) {
    yield put(fetchRiderMissionParticipantsTemplateFailure((e as Error).message));
  }
}

function* fetchRiderMissionVoucherTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/vouchers:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-voucher-template.xlsx');
      yield put(fetchRiderMissionVoucherTemplateSuccess());
    } else {
      yield put(fetchRiderMissionVoucherTemplateFailure(response.data));
    }
  } catch (e) {
    yield put(fetchRiderMissionVoucherTemplateFailure((e as Error).message));
  }
}

function* deleteRiderMission({ payload }: ReturnType<typeof deleteRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_PATH}/${payload}`);

    const action = response.ok ? deleteRiderMissionSuccess() : deleteRiderMissionFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(deleteRiderMissionFailure((e as Error).message));
  }
}

function* fetchRiderMissionExcludeTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/exclude:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-exclude-template.xlsx');
      yield put(fetchRiderMissionExcludeTemplateSuccess());
    } else {
      yield put(fetchRiderMissionExcludeTemplateFailure(response.data));
    }
  } catch (e) {
    yield put(fetchRiderMissionExcludeTemplateFailure((e as Error).message));
  }
}

function* syncRiderMission({ payload: { id, riderId } }: ReturnType<typeof syncRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/riders/${riderId}/sync`);

    if (response.ok) {
      yield put(syncRiderMissionSuccess(response.data));
    } else {
      yield put(syncRiderMissionFailure(response.data));
    }
  } catch (e) {
    yield put(syncRiderMissionFailure((e as Error).message));
  }
}

function* syncAllRiderMission({ payload: { id, data } }: ReturnType<typeof syncAllRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/sync_all`, {
      data,
    });

    if (response.ok) {
      yield put(syncAllRiderMissionSuccess());
    } else {
      yield put(syncAllRiderMissionFailure(response.data));
    }
  } catch (e) {
    yield put(syncAllRiderMissionFailure((e as Error).message));
  }
}

function* syncBulkRiderMission({ payload: { id, data, params } }: ReturnType<typeof syncBulkRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/sync:bulk`, {
      data,
      params,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(syncBulkRiderMissionSuccess());
    } else {
      yield put(syncBulkRiderMissionFailure(response.data));
    }
  } catch (e) {
    yield put(syncBulkRiderMissionFailure((e as Error).message));
  }
}

function* fetchRiderMissionSyncTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/sync_bulk:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-sync-template.xlsx');
      yield put(fetchRiderMissionSyncTemplateSuccess());
    } else {
      yield put(fetchRiderMissionSyncTemplateFailure(response.data));
    }
  } catch (e) {
    yield put(fetchRiderMissionSyncTemplateFailure((e as Error).message));
  }
}

function* excludeRiderMission({ payload: { id, data } }: ReturnType<typeof excludeRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/exclude`, {
      data,
    });

    if (response.ok) {
      yield put(excludeRiderMissionSuccess());
    } else {
      yield put(excludeRiderMissionFailure(response.data));
    }
  } catch (e) {
    yield put(excludeRiderMissionFailure((e as Error).message));
  }
}

function* excludeBulkRiderMission({ payload: { id, data } }: ReturnType<typeof excludeBulkRiderMissionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/exclude:bulk`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(excludeBulkRiderMissionSuccess());
    } else {
      yield put(excludeBulkRiderMissionFailure(response.data));
    }
  } catch (e) {
    yield put(excludeBulkRiderMissionFailure((e as Error).message));
  }
}

function* exportRewardHistories({ payload: { id, tierId } }: ReturnType<typeof exportRewardHistoriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/${id}/missionCriteria/${tierId}/rewardHistories/export`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-reward-info.xlsx');
      yield put(exportRewardHistoriesSuccess());
    } else {
      yield put(exportRewardHistoriesFailure(response.data));
    }
  } catch (e) {
    yield put(exportRewardHistoriesFailure((e as Error).message));
  }
}

function* updateRiderMissionCondition({ payload: { id, data } }: ReturnType<typeof updateRiderMissionConditionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_PATH}/${id}/info`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(updateRiderMissionConditionSuccess());
    } else {
      yield put(updateRiderMissionConditionFailure(response.data));
    }
  } catch (e) {
    yield put(updateRiderMissionConditionFailure((e as Error).message));
  }
}

function* updateTierVoucher({ payload: { id, tierId, data } }: ReturnType<typeof updateTierVoucherRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_PATH}/${id}/missionCriteria/${tierId}/vouchers/add`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(updateTierVoucherSuccess());
    } else {
      yield put(updateTierVoucherFailure(response.data));
    }
  } catch (e) {
    yield put(updateTierVoucherFailure((e as Error).message));
  }
}

function* bulkUploadRiderMissionParticipants({
  payload: { id, data },
}: ReturnType<typeof bulkUploadRiderMissionParticipantsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/${id}/participants:bulk`, {
      data,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkUploadRiderMissionParticipantsSuccess() : bulkUploadRiderMissionParticipantsFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(bulkUploadRiderMissionParticipantsFailure((e as Error).message));
  }
}

function* exportRiderMissionParticipants({ payload }: ReturnType<typeof exportRiderMissionParticipantsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${BASE_PATH}/${payload}/participants/export`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'rider-mission-participants.xlsx');
      yield put(exportRiderMissionParticipantsSuccess());
    } else {
      yield put(exportRiderMissionParticipantsFailure(response.data));
    }
  } catch (e) {
    yield put(exportRiderMissionParticipantsFailure((e as Error).message));
  }
}

export default function* riderMissionSaga() {
  yield takeLatest(createRiderMissionRequest.type, createRiderMission);
  yield takeLatest(updateRiderMissionRequest.type, updateRiderMission);
  yield takeLatest(fetchRiderMissionParticipantsTemplateRequest.type, fetchRiderMissionParticipantsTemplate);
  yield takeLatest(fetchRiderMissionVoucherTemplateRequest.type, fetchRiderMissionVoucherTemplate);
  yield takeLatest(fetchRiderMissionExcludeTemplateRequest.type, fetchRiderMissionExcludeTemplate);
  yield takeLatest(deleteRiderMissionRequest.type, deleteRiderMission);
  yield takeLatest(syncRiderMissionRequest.type, syncRiderMission);
  yield takeLatest(syncAllRiderMissionRequest.type, syncAllRiderMission);
  yield takeLatest(syncBulkRiderMissionRequest.type, syncBulkRiderMission);
  yield takeLatest(fetchRiderMissionSyncTemplateRequest.type, fetchRiderMissionSyncTemplate);
  yield takeLatest(excludeRiderMissionRequest.type, excludeRiderMission);
  yield takeLatest(excludeBulkRiderMissionRequest.type, excludeBulkRiderMission);
  yield takeLatest(exportRewardHistoriesRequest.type, exportRewardHistories);
  yield takeLatest(updateRiderMissionConditionRequest.type, updateRiderMissionCondition);
  yield takeLatest(updateTierVoucherRequest.type, updateTierVoucher);
  yield takeLatest(bulkUploadRiderMissionParticipantsRequest.type, bulkUploadRiderMissionParticipants);
  yield takeLatest(exportRiderMissionParticipantsRequest.type, exportRiderMissionParticipants);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDynamicSurgeFailure,
  fetchDynamicSurgeRequest,
  fetchDynamicSurgeSuccess,
  createDynamicSurgeFailure,
  createDynamicSurgeRequest,
  createDynamicSurgeSuccess,
  updateDynamicSurgeFailure,
  updateDynamicSurgeRequest,
  updateDynamicSurgeSuccess,
  updateAllDynamicSurgeFailure,
  updateAllDynamicSurgeRequest,
  updateAllDynamicSurgeSuccess,
  fetchSurgeHotZoneFailure,
  fetchSurgeHotZoneRequest,
  fetchSurgeHotZoneSuccess,
  fetchDynamicHotZoneFailure,
  fetchDynamicHotZoneRequest,
  fetchDynamicHotZoneSuccess,
  updateDynamicHotZoneFailure,
  updateDynamicHotZoneRequest,
  updateDynamicHotZoneSuccess,
} from '@/pages/DynamicSurge/reducer';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/v1/admin/dynamic_surges';

function* fetchDynamicSurge({ payload: { cityCode, param } }: ReturnType<typeof fetchDynamicSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/city/${cityCode}`, { params: param });

    const action = response.ok ? fetchDynamicSurgeSuccess(response.data) : fetchDynamicSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDynamicSurgeFailure((e as Error).message));
  }
}

function* createDynamicSurge({ payload }: ReturnType<typeof createDynamicSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}:upsertAll`, { data: payload });

    const action = response.ok ? createDynamicSurgeSuccess() : createDynamicSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDynamicSurgeFailure((e as Error).message));
  }
}

function* updateDynamicSurge({ payload: { id, body } }: ReturnType<typeof updateDynamicSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateDynamicSurgeSuccess(response.data) : updateDynamicSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDynamicSurgeFailure((e as Error).message));
  }
}

function* updateAllDynamicSurge({ payload }: ReturnType<typeof updateAllDynamicSurgeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}:updateAll`, { data: payload });

    const action = response.ok ? updateAllDynamicSurgeSuccess() : updateAllDynamicSurgeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateAllDynamicSurgeFailure((e as Error).message));
  }
}

function* fetchSurgeHotZone({ payload }: ReturnType<typeof fetchSurgeHotZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/admin/surge_hotzones', { params: { cityCode: payload } });

    const action = response.ok ? fetchSurgeHotZoneSuccess(response.data) : fetchSurgeHotZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchSurgeHotZoneFailure((e as Error).message));
  }
}

function* fetchDynamicHotZone({ payload }: ReturnType<typeof fetchDynamicHotZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/admin/dynamic_hotzones', { params: payload });

    const action = response.ok ? fetchDynamicHotZoneSuccess(response.data) : fetchDynamicHotZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDynamicHotZoneFailure((e as Error).message));
  }
}

function* updateDynamicHotZone({ payload }: ReturnType<typeof updateDynamicHotZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admin/dynamic_hotzones/update', { data: payload });

    const action = response.ok ? updateDynamicHotZoneSuccess(response.data) : updateDynamicHotZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDynamicHotZoneFailure((e as Error).message));
  }
}

export default function* dynamicSurgeSaga() {
  yield takeLatest(fetchDynamicSurgeRequest.type, fetchDynamicSurge);
  yield takeLatest(createDynamicSurgeRequest.type, createDynamicSurge);
  yield takeLatest(updateDynamicSurgeRequest.type, updateDynamicSurge);
  yield takeLatest(updateAllDynamicSurgeRequest.type, updateAllDynamicSurge);
  yield takeLatest(fetchSurgeHotZoneRequest.type, fetchSurgeHotZone);
  yield takeLatest(fetchDynamicHotZoneRequest.type, fetchDynamicHotZone);
  yield takeLatest(updateDynamicHotZoneRequest.type, updateDynamicHotZone);
}

import { createAction } from '@reduxjs/toolkit';

import { CreateScheduleSurgeDto, ScheduleSurge, ScheduleSurgesTemplateFilterQuery } from '@/pages/ScheduleSurge/types';

export const createScheduleSurgeRequest = createAction<CreateScheduleSurgeDto>('scheduleSurge/createScheduleSurgeRequest');
export const createScheduleSurgeSuccess = createAction<ScheduleSurge>('scheduleSurge/createScheduleSurgeSuccess');
export const createScheduleSurgeFailure = createAction<string>('scheduleSurge/createScheduleSurgeFailure');
export const updateScheduleSurgeRequest = createAction('scheduleSurge/updateScheduleSurgeRequest', (id: number, body: CreateScheduleSurgeDto) => ({
  payload: { id, body },
}));
export const updateScheduleSurgeSuccess = createAction('scheduleSurge/updateScheduleSurgeSuccess');
export const updateScheduleSurgeFailure = createAction<string>('scheduleSurge/updateScheduleSurgeFailure');

export const downloadScheduleSurgeTemplateRequest = createAction<ScheduleSurgesTemplateFilterQuery>(
  `scheduleSurge/downloadScheduleSurgeTemplateRequest`
);
export const downloadScheduleSurgeTemplateFailure = createAction<string>(`scheduleSurge/downloadScheduleSurgeTemplateFailure`);

export const deleteScheduleSurgeRequest = createAction<number>('scheduleSurge/deleteScheduleSurgeRequest');
export const deleteScheduleSurgeSuccess = createAction('scheduleSurge/deleteScheduleSurgeSuccess');
export const deleteScheduleSurgeFailure = createAction<string>('scheduleSurge/deleteScheduleSurgeFailure');

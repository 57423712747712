import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { RESERVATION_RIDE_STAT } from '@/assets/constants';
import scheduleSurgePresetReducer, { scheduleSurgePresetInitialState } from '@/svc/ride-svc/scheduleSurgePreset/reducer';
import { RequestMonthlyEarningBody, ReservationStatQuery, RideSvcState, RideTimeModificationDto } from '@/svc/ride-svc/type';
import Service from '@/svc/serviceName';

export const modifyRideTimeRequest = createAction<RideTimeModificationDto>(`${Service.rideSvc}modifyRideTimeRequest`);
export const modifyRideTimeSuccess = createAction(`${Service.rideSvc}modifyRideTimeSuccess`);
export const modifyRideTimeFailure = createAction<string>(`${Service.rideSvc}modifyRideTimeFailure`);

export const fetchReservationStatsRequest = createAction<ReservationStatQuery>(`${Service.rideSvc}/fetchReservationStatsRequest`);
export const fetchReservationStatsFailure = createAction<string>(`${Service.rideSvc}/fetchReservationStatsFailure`);

export const refreshDistanceRequest = createAction<string>(`${Service.rideSvc}/refreshDistanceRequest`);
export const refreshDistanceSuccess = createAction(`${Service.rideSvc}/refreshDistanceSuccess`);
export const refreshDistanceFailure = createAction<string>(`${Service.rideSvc}/refreshDistanceFailure`);

export const retryPayRequest = createAction<string>(`${Service.rideSvc}/retryPayRequest`);
export const retryPaySuccess = createAction(`${Service.rideSvc}/retryPaySuccess`);
export const retryPayFailure = createAction<string>(`${Service.rideSvc}/retryPayFailure`);

export const revertChangeOfDestinationRequest = createAction<string>(`${Service.rideSvc}/revertChangeOfDestinationRequest`);
export const revertChangeOfDestinationSuccess = createAction(`${Service.rideSvc}/revertChangeOfDestinationSuccess`);
export const revertChangeOfDestinationFailure = createAction<string>(`${Service.rideSvc}/revertChangeOfDestinationFailure`);

export const recalculateReverseTransactionRequest = createAction<{ id: string }>(`${Service.rideSvc}/recalculateReverseTransactionRequest`);
export const recalculateReverseTransactionSuccess = createAction(`${Service.rideSvc}/recalculateReverseTransactionSuccess`);
export const recalculateReverseTransactionFailure = createAction<string>(`${Service.rideSvc}/recalculateReverseTransactionFailure`);

const initialState: RideSvcState = {
  exportEarning: {
    isExporting: false,
    error: null,
  },
  reservationStats: {
    [RESERVATION_RIDE_STAT.CREATED]: 0,
    [RESERVATION_RIDE_STAT.FINISHED]: 0,
    [RESERVATION_RIDE_STAT.ONGOING]: 0,
    [RESERVATION_RIDE_STAT.UPCOMING]: 0,
    [RESERVATION_RIDE_STAT.ASSIGNED]: 0,
  },
  scheduleSurgePreset: scheduleSurgePresetInitialState,
};

const rideSvcSlice = createSlice({
  name: Service.rideSvc,
  initialState,
  reducers: {
    exportDriverEarningRequest: {
      reducer: (state) => {
        state.exportEarning.isExporting = true;
        state.exportEarning.error = null;
      },
      prepare: (id: string, body: RequestMonthlyEarningBody) => ({ payload: { id, body } }),
    },
    exportDriverEarningSuccess(state) {
      state.exportEarning.isExporting = false;
    },
    exportDriverEarningFailure(state, { payload }: PayloadAction<string>) {
      state.exportEarning.isExporting = false;
      state.exportEarning.error = payload;
    },
    fetchReservationStatsSuccess: (state, { payload }) => {
      state.reservationStats = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.scheduleSurgePreset, scheduleSurgePresetReducer(state.scheduleSurgePreset, action));
    }),
});

export const { exportDriverEarningRequest, exportDriverEarningSuccess, exportDriverEarningFailure, fetchReservationStatsSuccess } =
  rideSvcSlice.actions;

export default rideSvcSlice.reducer;

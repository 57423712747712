import { useState } from 'react';

import styled from 'styled-components';

import regex from '@/assets/regex';
import ButtonContainer from '@/components/ButtonContainer';
import { Button, Col, FormGroup, Input } from '@/components/template';
import { useToolkitActionToast } from '@/hooks/toast';
import { dispatch } from '@/store';
import { useActionSubscribe } from '@/store/middleware/actionSubscribe';
import { sendResetPasswordEmailRequest, sendResetPasswordEmailSuccess } from '@/svc/admin-proxy-svc/reducer';

const PopupContainer = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
`;

interface ResetPasswordPopupContentProps {
  close: () => void;
}

function ResetPasswordPopupContent({ close }: ResetPasswordPopupContentProps) {
  const [email, setEmail] = useState('');

  const handleResetPassword = () => {
    if (window.confirm(`Would you want to send email ${email}?`)) {
      dispatch(sendResetPasswordEmailRequest({ email }));
    }
  };
  const isDisabled = !email.trim() || !regex.email.test(email);

  useToolkitActionToast(sendResetPasswordEmailRequest.type, {
    successMessage: 'Email has been sent',
  });

  useActionSubscribe(sendResetPasswordEmailSuccess.type, () => {
    close();
    setEmail('');
  });

  return (
    <PopupContainer>
      <div className='tw-flex tw-bg-[#34454B] tw-border-[0] tw-p-[15px]'>
        <h5 className='tw-text-white tw-m-[0]'>Send Reset Password Email</h5>
        <i className='fa fa-times tw-ml-auto tw-text-[24px] tw-text-white ' onClick={close} />
      </div>

      <div className='tw-p-[15px]'>
        <FormGroup row>
          <Col md={3}>Email Address (Login ID)</Col>
          <Col md={9}>
            <Input value={email} className={isDisabled ? `tw-border-red-poi` : ''} onChange={(e) => setEmail(e.target.value)} />
            <div className='text-danger'>
              {!email.trim() ? 'Please Input Email Address (Login ID)' : !regex.email.test(email) ? 'It is not in email format' : ''}
            </div>
          </Col>
        </FormGroup>
        <ButtonContainer>
          <Button color='primary' className='tw-ml-auto' onClick={handleResetPassword} disabled={isDisabled}>
            Reset Password
          </Button>
        </ButtonContainer>
      </div>
    </PopupContainer>
  );
}

export default ResetPasswordPopupContent;

import { DropdownToggle as DropdownToggleComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface DropdownToggleProps extends React.HTMLAttributes<HTMLElement> {
  caret?: boolean;
  color?: string;
  cssModule?: CSSModule;
  disabled?: boolean;
  outline?: boolean;
  'data-toggle'?: string;
  'aria-haspopup'?: boolean;
  split?: boolean;
  tag?: React.ElementType;
  nav?: boolean;
  size?: string;
}

export function DropdownToggle(props: DropdownToggleProps) {
  return <DropdownToggleComponent {...props} />;
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Detail = lazy(() => import(/* webpackChunkName: "DynamicSurgeDetail" */ '@/pages/DynamicSurge/Detail'));
const Form = lazy(() => import(/* webpackChunkName: "DynamicSurgeForm" */ '@/pages/DynamicSurge/Form'));
const HotZone = lazy(() => import(/* webpackChunkName: "DynamicSurgeHotZone" */ '@/pages/DynamicSurge/HotZones'));
const updateAllSurge = lazy(() => import(/* webpackChunkName: "DynamicSurgeupdateAllSurge" */ '@/pages/DynamicSurge/updateAllSurge'));

export const dynamicSurgeRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DynamicSurge,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DynamicSurge}/form`,
    component: Form,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DynamicSurge}/hotzone`,
    component: HotZone,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DynamicSurge}/update_all`,
    component: updateAllSurge,
    exact: true,
  },
];

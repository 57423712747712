import { useDispatch } from 'react-redux';

import { HelmetTitle } from '@@components/HelmetTitle';

import LoginForm, { UserForm } from '@/components/LoginForm';
import { useToolkitActionToast } from '@/hooks/toast';
import { loginRequest } from '@/pages/LoginPage/reducer';

function LoginPage() {
  const dispatch = useDispatch();

  useToolkitActionToast(loginRequest.type);

  const processForm = (data: UserForm) => {
    dispatch(loginRequest(data));
  };

  return (
    <div>
      <HelmetTitle title='Login' />
      <LoginForm onSubmit={processForm} />
    </div>
  );
}

export default LoginPage;

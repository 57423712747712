import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CorporateInvoiceList" */ '@/pages/CorporateInvoice/List'));

export const corporateInvoiceRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    exact: true,
    path: PATH.CorporateInvoice,
    component: List,
  },
];

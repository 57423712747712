import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "DeliveryRideOptionList" */ '@/pages/DeliveryRideRouteOption/List'));
const New = lazy(() => import(/* webpackChunkName: "DeliveryRideOptionNew" */ '@/pages/DeliveryRideRouteOption/New'));
const Detail = lazy(() => import(/* webpackChunkName: "DeliveryRideOptionDetail" */ '@/pages/DeliveryRideRouteOption/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "DeliveryRideOptionEdit" */ '@/pages/DeliveryRideRouteOption/Edit'));

export const deliveryRideOptionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DeliveryRideOption,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DeliveryRideOption}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DeliveryRideOption}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DeliveryRideOption}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

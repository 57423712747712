import { createAction } from '@reduxjs/toolkit';

import { SurgeCapModificationDto } from '@/pages/SurgeCap/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.rideSvc}/surgeCap`;

export const createSurgeCapRequest = createAction<SurgeCapModificationDto>(`${PREFIX}/createSurgeCapRequest`);
export const createSurgeCapSuccess = createAction(`${PREFIX}/createSurgeCapSuccess`);
export const createSurgeCapFailure = createAction<string>(`${PREFIX}/createSurgeCapFailure`);

export const updateSurgeCapRequest = createAction(
  `${PREFIX}/updateSurgeCapRequest`,
  ({ id, data }: { id: string; data: SurgeCapModificationDto }) => ({
    payload: { id, data },
  })
);
export const updateSurgeCapSuccess = createAction(`${PREFIX}/updateSurgeCapSuccess`);
export const updateSurgeCapFailure = createAction<string>(`${PREFIX}/updateSurgeCapFailure`);

export const deleteSurgeCapRequest = createAction<string>(`${PREFIX}/deleteSurgeCapRequest`);
export const deleteSurgeCapSuccess = createAction(`${PREFIX}/deleteSurgeCapSuccess`);
export const deleteSurgeCapFailure = createAction<string>(`${PREFIX}/deleteSurgeCapFailure`);

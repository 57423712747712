import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "GooglePlaceList" */ '@/pages/GooglePlace/List'));
const Detail = lazy(() => import(/* webpackChunkName: "GooglePlaceDetail" */ '@/pages/GooglePlace/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "GooglePlaceEdit" */ '@/pages/GooglePlace/Edit'));

export const GooglePlaceRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.GooglePlace,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.GooglePlace}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.GooglePlace}/:id`,
    component: Detail,
    exact: true,
  },
];

export default {
  translation: {
    menu: {
      payment_transactions: '이용요금 결제',
      payment_transaction: '이용요금 결제',
      penalty: '페널티',
      promotion_and_coupon: '프로모션 & 쿠폰',
      promotion: '프로모션 ',
      coupon: '쿠폰',
      balance: '지갑 정보',
      account: '출금 계좌 정보',
      cashout_requests: '출금 내역',
      transaction: '거래 내역',
      admin_setting: '어드민 세팅',
      role_setting: '롤 세팅',
    },
    search: '입력',
    boolean: {
      true: '해당함',
      false: '해당하지 않음',
    },
    region: '지역',
    select_data: '{{data}} 선택',
    id: 'Id',
    currency: '통화 ',
    coupon_code: '쿠폰 코드',
    rider_uuid: '승객 Uuid',
    status: '상태',
    amount: '금액',
    action_logs: '황동 기록',
    list_title: '{{title}} 목록',
    reset_filter: '필터 리셋',
    create: '생성',
    description: '설명',
    user_id: '유저 Id ',
    title: '제목',
    create_data: '{{data}} 생성',
    promotion: '프로모션',
    discount_type: '할인방식',
    code: '코드',
    valid_from: '유효기간(시작)',
    valid_until: '유효기간(끝)',
    car_types: '차량타입',
    max_amount: '최대 값',
    payment_methods: '결제수단',
    hired_only: '고용기사',
    auto_apply: '자동배차대상',
    price_excess_policy: '초과 금액 정책',
    polygon_ids: '폴리곤 Id',
    times: '시간대',
    publish_coupon_to_user: '유저에게 쿠폰 지급하기',
    publish_coupon: '쿠폰 지급하기',
    cities: '도시',
    coupon_updated_at: '업데이트 일시',
    coupon_created_at: '등록 일시',
    simplified_address: '시-군',
    rider_system_fee: '중개팁',
    rider_point_payment_amount: '승객 포인트 결제 금액',
    rider_point_transaction_id: '승객 포인트 거래 ID',
    driver_point_payment_amount: '기사 포인트 결제 금액',
    driver_point_transaction_id: '기사 포인트 거래 ID',
    coupon: '쿠폰',
    promo_code: '프로모션  코드',
    image_url: '이미지 Url',
    min_ride_finished_count: '최소 트립 완료 수',
    max_ride_finished_count: '최대 트립 완료 수',
    update_coupon_status: '쿠폰 상태값 없데이트',
    coupon_log: '쿠폰 로그',
    coupon_id: '쿠폰 Id',
    original_price: '정가',
    discounted_price: '할인가',
    action: '활동',
    error_info: '에러정보',
    bulk_create: '벌크 생성',
    user_ids: '유저 Id',
    required_field: '필수영역',
    code_prefix: '코드 접두사',
    code_length: '코드 길이',
    character_enabled: '적용된 특징',
    selected_times: '선택한 시간대',
    add: '추가',
    times_preset: '지정 시간대',
    morning_rush_hour: '아침 출근시간',
    evening_rush_hour: '저녁 퇴근시간',
    weekend: '주말',
    all_times: '항상',
    create_coupon: '쿠폰 생성',
    count: '개수',
    coupon_log_created_at: '쿠폰 로그 생성 시간',
    target_type: '대상 유형',
    constants: {
      region_string: {
        singapore: '싱가포르',
        vietnam: '베트남',
        cambodia: '캄보디아',
        korea: '대한민국',
        ethiopia: '이디오피아',
        indonesia: '인도네시아',
      },
      car_type_sedan: '',
      payment_method_creditcard: '신용카드',
      payment_method_cash: '현금',
      product_type_chauffeur: '',
      discount_type: {
        percent: '퍼센트',
        fixed_amount: '고정금액',
        target_price: '목표금액',
      },
      city: {
        singapore: '싱가포르',
        hochiminh: '호치민',
        hanoi: '하노이',
        phnompenh: '프놈펜',
        siemreap: '시암립',
        bangkok: '방콕',
        seoul: '서울',
        addis_ababa: '아디스아바바',
      },
      coupon_status: {
        ready: '준비됨',
        used: '사용완료',
      },
      price_excess_policy: {
        apply: '적용',
        reject: '적용하지 않음',
      },
    },
    error: {
      null: '없음',
      no_record: '기록 없음',
    },
    paymentTransaction: {
      success_toast: '{{data}}에 성공했습니다!',
      update_payment_transaction: '결제 트랜잭션 수정',
      refund_prompt: '환불을 위해 트랜잭션의 결제금액을 정확히 입력해주세요',
      refund_alert: '입력한 금액이 잘못되었습니다.',
      refund: '환불',
      pay_with_existing_card_confirm: '입력된 카드 정보로 재결제 시도를 정말로 하시겠습니까?',
      pay_with_existing_card: '재결제 시도',
      cancel_confirm: '결제 트랜잭션을 취소하시겠습니까?',
      cancel: '취소처리',
      fail_confirm: '결제 트랜잭션을 실패처리하시겠습니까?',
      fail: '실패처리',
    },
  },
};

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAppVersionListRequest,
  fetchAppVersionListSuccess,
  fetchAppVersionListFailure,
  fetchAppVersionDetailRequest,
  fetchAppVersionDetailSuccess,
  fetchAppVersionDetailFailure,
  createAppVersionRequest,
  createAppVersionSuccess,
  createAppVersionFailure,
  updateAppVersionRequest,
  updateAppVersionSuccess,
  updateAppVersionFailure,
  deleteAppVersionRequest,
  deleteAppVersionSuccess,
  deleteAppVersionFailure,
} from '@/pages/AppVersion/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchAppVersionList({ payload }: ReturnType<typeof fetchAppVersionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/app_version', { params: payload, schema: { content: [entity] } });

    if (response.ok) {
      yield put(fetchAppVersionListSuccess(response.data));
    } else {
      yield put(fetchAppVersionListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAppVersionListFailure((e as Error).message));
  }
}

function* fetchAppVersionDetail({ payload }: ReturnType<typeof fetchAppVersionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/app_version/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchAppVersionDetailSuccess(response.data));
    } else {
      yield put(fetchAppVersionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchAppVersionDetailFailure((e as Error).message));
  }
}

function* createAppVersion({ payload }: ReturnType<typeof createAppVersionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/app_version', { data: payload });

    if (response.ok) {
      yield put(createAppVersionSuccess(response.data));
    } else {
      yield put(createAppVersionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createAppVersionFailure((e as Error).message));
  }
}

function* updateAppVersion({ payload }: ReturnType<typeof updateAppVersionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/app_version/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateAppVersionSuccess(response.data));
    } else {
      yield put(updateAppVersionFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateAppVersionFailure((e as Error).message));
  }
}

function* deleteAppVersion({ payload }: ReturnType<typeof deleteAppVersionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/app_version/${payload.id}`);

    if (response.ok) {
      yield put(deleteAppVersionSuccess(payload));
    } else {
      yield put(deleteAppVersionFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteAppVersionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchAppVersionListRequest.type, fetchAppVersionList);
  yield takeLatest(fetchAppVersionDetailRequest.type, fetchAppVersionDetail);
  yield takeLatest(createAppVersionRequest.type, createAppVersion);
  yield takeLatest(updateAppVersionRequest.type, updateAppVersion);
  yield takeLatest(deleteAppVersionRequest.type, deleteAppVersion);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "InboxItemList" */ '@/pages/InboxItem/List'));
const Detail = lazy(() => import(/* webpackChunkName: "InboxItemDetail" */ '@/pages/InboxItem/Detail'));
const New = lazy(() => import(/* webpackChunkName: "InboxItemNew" */ '@/pages/InboxItem/New'));
const Edit = lazy(() => import(/* webpackChunkName: "InboxItemEdit" */ '@/pages/InboxItem/Edit'));

export const inboxItemRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Inbox,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Inbox}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Inbox}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Inbox}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { createContext, ReactNode, useContext } from 'react';

import { ConnectDragSource, ConnectDropTarget } from 'react-dnd';

import { BaseNavItemType } from '@/layout/Sidebar/_nav';

interface MenuContextType {
  item: BaseNavItemType | null;
  index: number;
  permissions: any;
  dragRef?: ConnectDragSource;
  dropRef?: ConnectDropTarget;
  isDragging: boolean;
  isAvailableDrag: boolean;
  isHover: boolean;
  handleMouseDownOverTime: (e: React.MouseEvent<HTMLLIElement | HTMLDivElement>) => void;
  handleMouseUpOverTime: () => void;
  handleMouseOutOverTime: () => void;
}

export const MenuContext = createContext<MenuContextType>({
  item: null,
  index: 0,
  permissions: {},
  isDragging: false,
  isAvailableDrag: true,
  isHover: false,
  handleMouseDownOverTime: () => {},
  handleMouseUpOverTime: () => {},
  handleMouseOutOverTime: () => {},
});

export function MenuProvider({ children, ...menuContext }: MenuContextType & { children: ReactNode }) {
  return <MenuContext.Provider value={{ ...menuContext }}>{children}</MenuContext.Provider>;
}

export const useMenuContext = () => useContext(MenuContext);

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { ERROR } from '@/assets/constants';
import {
  fetchInfiniteCorporateManagerActionLogListFailure,
  fetchInfiniteCorporateManagerActionLogListRequest,
  fetchInfiniteCorporateManagerActionLogListSuccess,
} from '@/svc/corporate-svc/CorporateManagerActionLog/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchInfiniteCorporateManagerActionLogList({
  payload,
}: ReturnType<typeof fetchInfiniteCorporateManagerActionLogListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/corporates/actionlogs', { params: payload, schema: { content: [entity] } });

    if (response.ok && (payload.page === 0 || response.data.result.content.length !== 0)) {
      yield put(fetchInfiniteCorporateManagerActionLogListSuccess(response.data));
    } else {
      const errorMessage = response.data.result.last ? ERROR.LAST_SCROLL : response.data.message;
      yield put(fetchInfiniteCorporateManagerActionLogListFailure(errorMessage));
    }
  } catch (e) {
    yield put(fetchInfiniteCorporateManagerActionLogListFailure((e as Error).message));
  }
}

export default function* CorporateManagerActionLogSvc() {
  yield takeLatest(fetchInfiniteCorporateManagerActionLogListRequest.type, fetchInfiniteCorporateManagerActionLogList);
}

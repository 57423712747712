import { Form, useFormikContext } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { REGION } from '@/assets/constants';
import CardTemplate from '@/components/CardTemplate';
import HeaderContent from '@/components/HeaderContent';
import { DateInput, Dropdown } from '@/components/InputField';
import { useLeaveCheck } from '@/components/LeavePopup/hooks';
import Section, { Title } from '@/components/Section';
import { FormikErrorMessage } from '@/components/etc';
import ExcelFileUpload from '@/components/hooks/ExcelFileUpload';
import { Button, Col, FormGroup, Input } from '@/components/template';
import { CpfContributionTableBody } from '@/pages/CpfContributionTable/type';
import { downloadCpfContributionTableTemplateRequest } from '@/svc/balance-svc/cpfContributionTable/reducer';
import { formatDateWithRegion } from '@/utils/etc';

interface Props {
  type: 'new' | 'edit';
  cpfContributionTableBody?: CpfContributionTableBody;
  file: File | null;
  setFile: (file: File | null) => void;
}

function FormContent({ type, file, setFile }: Props) {
  const dispatch = useDispatch();
  const { handleSubmit, getFieldProps, setFieldValue, isValid } = useFormikContext<CpfContributionTableBody>();

  const handleDownloadTemplate = () => {
    dispatch(downloadCpfContributionTableTemplateRequest());
  };

  useLeaveCheck();

  return (
    <Form onSubmit={handleSubmit}>
      <CardTemplate
        headerChildren={<HeaderContent title={type === 'new' ? 'Create CPF Contribution Table' : 'Edit CPF Contribution Table'} />}
        footerChildren={
          <Button color='primary' type='submit' disabled={!isValid || (type === 'new' && !file)}>
            {type === 'new' ? 'Create CPF Contribution Table' : 'Edit'}
          </Button>
        }
      >
        <FormGroup row>
          <Col md={3}>Name</Col>
          <Col md={9}>
            <Input {...getFieldProps('name')} />
            <FormikErrorMessage name='name' />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3}>Type</Col>
          <Col md={9}>
            <Dropdown
              {...getFieldProps('forOptIn')}
              options={[
                { value: true, text: 'Mandatory/Opted In' },
                { value: false, text: 'Not Opted In' },
              ]}
            />
            <FormikErrorMessage name='forOptIn' />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3}>Start Date/Time</Col>
          <Col md={9}>
            <DateInput
              {...getFieldProps('validFrom')}
              timeFormat='HH:mm'
              dateFormat='DD-MM-YYYY'
              timeZoneCountry='SG'
              onChange={(value) =>
                setFieldValue(
                  'validFrom',
                  moment.isMoment(value) ? formatDateWithRegion({ date: moment(value), region: REGION.SG, format: 'DD-MM-YYYY HH:mm' }) : null
                )
              }
            />
            <FormikErrorMessage name='validFrom' />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3}>End Date/Time (Optional)</Col>
          <Col md={9}>
            <DateInput
              {...getFieldProps('validUntil')}
              timeFormat='HH:mm'
              dateFormat='DD-MM-YYYY'
              timeZoneCountry='SG'
              onChange={(value) =>
                setFieldValue(
                  'validUntil',
                  moment.isMoment(value) ? formatDateWithRegion({ date: moment(value), region: REGION.SG, format: 'DD-MM-YYYY HH:mm' }) : null
                )
              }
            />
            <FormikErrorMessage name='validUntil' />
          </Col>
        </FormGroup>
        <Section>
          <Title>
            <h5>Bulk Upload Section</h5>
          </Title>
          <ExcelFileUpload className='tw-my-3' fileData={file} setFileData={(file) => setFile(file)} isShowUpload={false} />
          <Button color='primary' onClick={handleDownloadTemplate}>
            Download Template
          </Button>
        </Section>
      </CardTemplate>
    </Form>
  );
}

export default FormContent;

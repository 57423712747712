import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Detail = lazy(() => import(/* webpackChunkName: "DeliveryRideRequestDetail" */ '@/pages/DeliveryRideRequest/Detail'));
const List = lazy(() => import(/* webpackChunkName: "DeliveryRideRequestList" */ '@/pages/DeliveryRideRequest/List'));

export const deliveryRideRequestRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.DeliveryRideRequest,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DeliveryRideRequest}/:id`,
    exact: true,
    component: Detail,
  },
];

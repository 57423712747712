import { AnchorHTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import qs from 'qs';
import { Link } from 'react-router-dom';

import { Pages, REGION, USER_MODEL } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import CheckPermission, { ActionType } from '@/components/CheckPermission';
import { GooglePlaceLanguageParam } from '@/pages/GooglePlace/type';
import { TransactionQuery } from '@/pages/Transaction/types';
import { usePermissionSelector } from '@/store/selector';

interface AccountLinkProps {
  id: string;
  model?: USER_MODEL;
  region?: REGION;
  fallbackElement?: ReactNode;
}

interface TransactionListLinkProps {
  search: Partial<TransactionQuery>;
}

interface DefaultLinkProps<T = unknown> extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'id'> {
  id: string | number | Array<string | number>;
  parameter?: T;
}

interface BulkSyncDriverIncentiveProgressDetailLinkProps {
  id: string;
  progressId: string;
}

export function AccountLink({ id, model, region, children }: PropsWithChildren<AccountLinkProps>) {
  const permission = usePermissionSelector(Pages.Account);

  const accountId = model && region ? `${id}_${model}_${region}` : id;

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? accountId}>
      <Link to={`${PATH.Account}/${accountId}`}>{children ?? accountId}</Link>
    </CheckPermission>
  );
}

export function AccountEditLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Account);

  return (
    <CheckPermission action={ActionType.EDIT} permission={permission} fallbackElement={children}>
      <Link to={`${PATH.Account}/${id}/edit`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PolygonLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Polygons);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Polygons}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RiderLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Rider);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Rider}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DriverIncentiveLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverIncentive);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DriverIncentive}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RideProductLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RideProducts);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RideProducts}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CreditCardLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.CreditCard);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.CreditCard}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RideLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Ride);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Ride}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PrefixCouponLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.PrefixCoupon);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.PrefixCoupon}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PromotionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Promotion);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Promotion}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DriverLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Drivers);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Drivers}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function FaqListLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.FaqListManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.FaqListManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function FaqTopicLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.FaqTopicManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.FaqTopicManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function BranchLink({ id, corporateId, children }: PropsWithChildren<DefaultLinkProps & { corporateId: string | number }>) {
  const branchPermission = usePermissionSelector(Pages.Corporate);

  return (
    <CheckPermission action={ActionType.READ} permission={branchPermission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Corporate}/${corporateId}/branch/${id}/detail`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function BranchEditLink({
  id,
  corporateId,
  locationId,
  children,
}: PropsWithChildren<DefaultLinkProps & { corporateId: string | number; locationId: number }>) {
  const branchPermission = usePermissionSelector(Pages.Corporate);

  return (
    <CheckPermission action={ActionType.READ} permission={branchPermission} fallbackElement={children ?? locationId}>
      <Link to={`${PATH.Corporate}/${corporateId}/branch/${id}/${locationId}/edit`}>{children ?? locationId}</Link>
    </CheckPermission>
  );
}

export function ReferralLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Referral);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Referral}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ReferralEditLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Referral);

  return (
    <CheckPermission action={ActionType.EDIT} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Referral}/${id}/edit`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ReferralCreateLink({ children }: { children: ReactNode }) {
  const permission = usePermissionSelector(Pages.Referral);

  return (
    <CheckPermission action={ActionType.WRITE} permission={permission} fallbackElement={children}>
      <Link to={`${PATH.Referral}/new`}>{children}</Link>
    </CheckPermission>
  );
}

export function ShbLoanLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.ShbLoanInfo);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.ShbLoanInfo}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ShbWalletLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.ShbWallet);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.ShbWallet}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CorporateManagerLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.CorporateManager);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.CorporateManager}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CorporateLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Corporate);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Corporate}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PipayLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.PipayTransactions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.PipayTransactions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PaoTransactionsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.PaoTransactions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.PaoTransactions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function MomopayLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.MomopayTransactions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.MomopayTransactions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function AlipayTransactionsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AlipayTransactions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AlipayTransactions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CouponLink({ id, children, code }: PropsWithChildren<DefaultLinkProps> & { code: string }) {
  const permission = usePermissionSelector(Pages.Coupon);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Coupon}/${id}?code=${code}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CarsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Cars);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Cars}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DispatchConfigLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DispatchConfig);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DispatchConfig}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function AbaTransactionsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AbaTransaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AbaTransaction}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ScheduleSurgeLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AdditionalPriceRules);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.NewAdditionalPriceRules}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ZoneLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Zones);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Zones}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DocumentGuideManagementLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DocumentGuideManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DocumentGuideManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ScheduleSurgePresetLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.ScheduleSurgePreset);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.ScheduleSurgePreset}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function HelpCenterTopicManagementLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.HelpCenterTopicManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.HelpCenterTopicManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function HelpCenterListManagementLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.HelpCenterListManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.HelpCenterListManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TransactionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Transaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Transaction}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TransactionListLink({ search, children }: PropsWithChildren<TransactionListLinkProps>) {
  const permission = usePermissionSelector(Pages.Transaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children}>
      <Link to={`${PATH.Transaction}?${qs.stringify(search)}`}>{children}</Link>
    </CheckPermission>
  );
}

export function AdyenTransactionListLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AdyenTransaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AdyenTransaction}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function WingTransactionDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.WingTransaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.WingTransaction}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function NewsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.News);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.News}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function CmcbTransactionDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.CmcbTransactions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.CmcbTransactions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PaymentTransactionDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.PaymentTransaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.PaymentTransaction}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function AofAccountDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AofAccount);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AofAccount}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RideProductInfoTemplateDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RideProductInfoTemplate);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RideProductInfoTemplate}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TaxPrefillDescriptionDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.TaxPrefillDescription);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.TaxPrefillDescription}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function GooglePlaceDetailLink({ id, children, parameter, ...props }: PropsWithChildren<DefaultLinkProps<GooglePlaceLanguageParam>>) {
  const permission = usePermissionSelector(Pages.GooglePlace);
  const param = `${qs.stringify(parameter)}`;

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.GooglePlace}/${id}?${param}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function SurgeCapDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.SurgeCap);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.SurgeCap}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function SafetyAccidentTopicDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.SafetyAccidentTopicManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.SafetyAccidentTopicManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function SafetyAccidentListDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.SafetyAccidentListManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.SafetyAccidentListManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function BulkSyncDriverIncentiveProgressDetailLink({
  id,
  progressId,
  children,
}: PropsWithChildren<BulkSyncDriverIncentiveProgressDetailLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverIncentive);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DriverIncentive}/${id}/progress/${progressId}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RestrictedAreaListDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RestrictedAreas);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RestrictedAreas}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PlaceDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Place);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Place}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function UserSubmissionDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.UserSubmission);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.UserSubmission}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function ReviewDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Reviews);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Reviews}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PreventMatchDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.PreventMatch);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.PreventMatch}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TollGantryDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.TollGantry);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.TollGantry}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TollTimeTableDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.TollTimeTable);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.TollTimeTable}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TollFreeDateDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.TollFreeDate);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.TollFreeDate}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function SupportPageManagementDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.SupportPageManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.SupportPageManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RiderMissionDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RiderMission);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RiderMission}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RiderMissionProgressListLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RiderMission);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RiderMission}/${id}/progress`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RiderMissionProgressDetailLink({ id, progressId, children }: PropsWithChildren<BulkSyncDriverIncentiveProgressDetailLinkProps>) {
  const permission = usePermissionSelector(Pages.RiderMission);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RiderMission}/${id}/progress/${progressId}`}>{children ?? progressId}</Link>
    </CheckPermission>
  );
}

export function RiderMissionBackgroundProgressDetailLink({
  id,
  progressId,
  children,
}: PropsWithChildren<BulkSyncDriverIncentiveProgressDetailLinkProps>) {
  const permission = usePermissionSelector(Pages.RiderMission);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RiderMission}/${id}/background-progress/${progressId}`}>{children ?? progressId}</Link>
    </CheckPermission>
  );
}

export function DriverBackgroundProgressDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Drivers);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Drivers}/driver-background-progress/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function VehicleModelListLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.VehicleMaker);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.VehicleMaker}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function InternalDocumentProgressDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverApplications);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DriverApplications}/progress/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function BINManagementDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.BINManagement);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.BINManagement}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryRideDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryRide);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryRide}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DriverMissionBackgroundProgressDetailLink({
  id,
  progressId,
  children,
}: PropsWithChildren<BulkSyncDriverIncentiveProgressDetailLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverIncentive);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? progressId}>
      <Link to={`${PATH.DriverIncentive}/${id}/background-progress/${progressId}`}>{children ?? progressId}</Link>
    </CheckPermission>
  );
}

export function FraudCardDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.FraudCard);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.FraudCard}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function AdminDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Admin);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Admin}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function AdminLogDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AdminLog);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AdminLog}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function AirportDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Airports);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Airports}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function AppVersionDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.AppVersion);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.AppVersion}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function AreaDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Areas);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Areas}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function BannerDetailLink({ id, children, ...props }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Banner);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Banner}/${id}`} {...props}>
        {children ?? id}
      </Link>
    </CheckPermission>
  );
}

export function DeliveryDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Delivery);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Delivery}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryPaymentOptionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryPaymentOption);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryPaymentOption}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryPriceOptionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryPriceOption);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryPriceOption}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryRidePriceOptionsLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryRidePriceOption);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryRidePriceOption}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryRideProductOptionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryRideProductOption);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryRideProductOption}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function WarehouseLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Warehouse);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Warehouse}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryRideOptionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryRideOption);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryRideOption}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DeliveryScheduleLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliverySchedule);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliverySchedule}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function MerchantLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Merchant);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Merchant}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DriverApplicationLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverApplications);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DriverApplications}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DriverTutorialLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DriverTutorial);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DriverTutorial}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function FraudLogLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.FraudLog);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.FraudLog}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function HourlyRentalRequestLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.HourlyRentalRequest);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.HourlyRentalRequest}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function InboxLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Inbox);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Inbox}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function PenaltyLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Penalty);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Penalty}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RegionLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Regions);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Regions}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RoleLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.Roles);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.Roles}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function RoutingResultLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.RoutingResult);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.RoutingResult}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function ShbRequestHistoryLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.ShbRequestHistory);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.ShbRequestHistory}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function TransactionCashoutRequestLogLink({ id, msgId, children }: PropsWithChildren<DefaultLinkProps> & { msgId: string }) {
  const permission = usePermissionSelector(Pages.Transaction);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? msgId}>
      <Link to={`${PATH.Transaction}/${id}/cashOutRequestLog/${msgId}`}>{children ?? msgId}</Link>
    </CheckPermission>
  );
}

export function DeliveryRideRequestLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DeliveryRideRequest);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DeliveryRideRequest}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

export function DynamicSurgeSettingDetailLink({ id, children }: PropsWithChildren<DefaultLinkProps>) {
  const permission = usePermissionSelector(Pages.DynamicSurgeSetting);

  return (
    <CheckPermission action={ActionType.READ} permission={permission} fallbackElement={children ?? id}>
      <Link to={`${PATH.DynamicSurgeSetting}/${id}`}>{children ?? id}</Link>
    </CheckPermission>
  );
}

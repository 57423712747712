import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createLocationInfosFailure,
  createLocationInfosRequest,
  createLocationInfosSuccess,
  deleteLocationInfosFailure,
  deleteLocationInfosRequest,
  deleteLocationInfosSuccess,
  fetchLocationInfosFailure,
  fetchLocationInfosRequest,
  fetchLocationInfosSuccess,
  updateLocationInfosFailure,
  updateLocationInfosRequest,
  updateLocationInfosSuccess,
} from '@/svc/corporate-svc/locationInfo/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchLocationInfo({ payload }: ReturnType<typeof fetchLocationInfosRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${payload.id}/locationInfos`);

    if (response.ok) {
      yield put(fetchLocationInfosSuccess(response.data));
    } else {
      yield put(fetchLocationInfosFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchLocationInfosFailure((e as Error).message));
  }
}

function* createLocationInfo({ payload }: ReturnType<typeof createLocationInfosRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/corporates/${payload.id}/locationInfos`, { data: payload.data });

    if (response.ok) {
      yield put(createLocationInfosSuccess());
    } else {
      yield put(createLocationInfosFailure(response.data.message));
    }
  } catch (e) {
    yield put(createLocationInfosFailure((e as Error).message));
  }
}

function* updateLocationInfo({ payload }: ReturnType<typeof updateLocationInfosRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/locationInfos/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateLocationInfosSuccess());
    } else {
      yield put(updateLocationInfosFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateLocationInfosFailure((e as Error).message));
  }
}

function* deleteLocationInfo({ payload }: ReturnType<typeof deleteLocationInfosRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/locationInfos/${payload.id}`);

    if (response.ok) {
      yield put(deleteLocationInfosSuccess());
    } else {
      yield put(deleteLocationInfosFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteLocationInfosFailure((e as Error).message));
  }
}

export default function* locationInfoSvcSaga() {
  yield takeLatest(fetchLocationInfosRequest.type, fetchLocationInfo);
  yield takeLatest(createLocationInfosRequest.type, createLocationInfo);
  yield takeLatest(updateLocationInfosRequest.type, updateLocationInfo);
  yield takeLatest(deleteLocationInfosRequest.type, deleteLocationInfo);
}

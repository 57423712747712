import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const New = lazy(() => import(/* webpackChunkName: "DriverTutorialNew" */ '@/pages/DriverTutorial/New'));
const Edit = lazy(() => import(/* webpackChunkName: "DriverTutorialEdit" */ '@/pages/DriverTutorial/Edit'));
const List = lazy(() => import(/* webpackChunkName: "DriverTutorialList" */ '@/pages/DriverTutorial/List'));
const Detail = lazy(() => import(/* webpackChunkName: "DriverTutorialDetail" */ '@/pages/DriverTutorial/Detail'));

export const driverTutorialRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    exact: true,
    path: PATH.DriverTutorial,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    exact: true,
    path: `${PATH.DriverTutorial}/new`,
    component: New,
  },
  {
    permission: ActionType.READ,
    exact: true,
    path: `${PATH.DriverTutorial}/:id`,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    exact: true,
    path: `${PATH.DriverTutorial}/:id/edit`,
    component: Edit,
  },
];

import { useEffect, useRef, useState } from 'react';

import { default as JSONInputEditor } from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import styled from 'styled-components';

const colors = {
  default: '#000',
  string: '#000',
  number: '#000',
  colon: '#000',
  keys: '#000',
  keys_whiteSpace: '#000',
  //@ts-ignore
  error: '#000',
  primitive: '#000',
  background: '#fff',
  background_warning: '#fff',
};

const style = {
  outerBox: {
    width: '100%',
  },
  contentBox: {
    color: '#000',
  },
  body: {
    fontFamily: 'inherit',
    fontSize: '0.75rem',
  },
  labels: {
    display: 'none',
  },
  labelColumn: {
    display: 'none',
  },
};

const JSONInputContainer = styled.div<{ disabled?: boolean }>`
  border: 1px solid #c2cfd6;

  * {
    font-family: inherit;

    ${({ disabled }) => (disabled ? 'background: #c2cfd6;' : '')}
  }
`;

type Value = Object | null | undefined;

interface JSONInputProps {
  width?: string;
  height?: string;
  placeholder?: Object;
  onChange: (obj: Object | null) => void;
  disabled?: boolean;
  value: Value;
}

const UpdateHelper = ({ callback }: { callback: (v: boolean) => void }) => {
  useEffect(() => {
    callback(false);
  }, []);

  return null;
};

const useForceUpdate = (value: Value) => {
  const prevValue = useRef<Value>(null);

  const [updateFlag, setUpdateFlag] = useState(false);

  const forceUpdate = () => {
    setUpdateFlag(true);
  };

  const helperCallback = () => {
    setUpdateFlag(false);
  };

  useEffect(() => {
    if (!value && prevValue.current) {
      forceUpdate();
    }

    setTimeout(() => {
      prevValue.current = value;
    }, 10);
  }, [value]);

  return {
    updateFlag,
    helperCallback,
  };
};

function JSONInput({ value, width, height, onChange, disabled }: JSONInputProps) {
  const { updateFlag, helperCallback } = useForceUpdate(value);

  const handleChange = ({ jsObject, plainText }: { jsObject?: Object; plainText?: string }) => {
    if ((jsObject || plainText === undefined || plainText?.trim() === '') && !disabled) {
      onChange(jsObject ?? null);
    }
  };

  return (
    <JSONInputContainer disabled={disabled}>
      {updateFlag ? (
        <UpdateHelper callback={helperCallback} />
      ) : (
        <JSONInputEditor
          width={width ?? '100%'}
          height={height ?? '350px'}
          placeholder={value}
          locale={locale}
          style={style}
          colors={colors}
          onChange={handleChange}
          confirmGood={false}
        />
      )}
    </JSONInputContainer>
  );
}

export default JSONInput;

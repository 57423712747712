import { ReactNode, CSSProperties, Fragment, useMemo } from 'react';

import styled from 'styled-components';

import Null from '@/components/Null';
import { TimeStamp } from '@/components/ValueField';
import { Table as BootStrapTable, TableProps as BootStrapTableProps } from '@/components/template';

export interface Column<T extends object> {
  key?: string | keyof T;
  title?: ReactNode;
  renderContent?: (item: T, idx: number) => ReactNode;
  renderHeader?: (item: T, idx: number) => ReactNode;
  minWidth?: string;
  width?: string;
  visible?: boolean;
}

export type TableProps<T extends object> = BootStrapTableProps & {
  /**
   * 테이블 속성 컬럼 배열
   */
  columns: Array<Column<T>>;
  /**
   * 데이터 배열
   */
  data: T[];
  /**
   * 추가적인 row 생성 함수
   */
  subCol?: (col: T, idx: number) => ReactNode;
  /**
   * Row를 클릭할 시 실행하는 이벤트 함수
   */
  onClickRow?: (row: T, idx: number) => void;
  /**
   * 클릭된 row 데이터
   */
  selectedRowData?: {
    index: number | string | number[] | null | undefined;
    backgroundColor: string;
    [key: string]: any;
  };
  /**
   * 커스터마이징 스타일
   */
  customStyle?: CSSProperties | string;
  /**
   * 스크롤 기능 가동 여부
   */
  scroll?: boolean;
  hover?: boolean;
  fallbackContent?: ReactNode;
  subRow?: (row: T, index: number) => ReactNode;
};

export const TableContainer = styled.div<{ scroll?: boolean }>`
  ${(props) => (props.scroll ? 'overflow-x: scroll;' : '')}
`;

export const ReactstrapTable = styled(BootStrapTable)<{ customstyle?: CSSProperties | string }>`
  margin-bottom: 0;

  td,
  th {
    overflow-wrap: anywhere;
  }

  &.table tbody &.table {
    background-color: transparent;
  }

  ${({ customstyle }: any) => customstyle}
`;

export const TableHeader = styled.th<{ minWidth?: string; width?: string }>`
  font-size: 14px;
  width: ${(prop) => prop.width};
  min-width: ${(prop) => prop.minWidth};
`;

export const TableData = styled.td`
  font-size: 14px;
`;

export const TableHeadRow = styled.tr`
  height: 3.5rem;
  border: solid 1px #c1d0d5;

  & > ${TableHeader} {
    padding: 0.6rem 0.5rem 0.5rem;
    vertical-align: top;

    &:first-child {
      padding-left: 1rem;
    }
  }
`;

export const TableRow = styled.tr<{ backgroundColor?: string | null }>`
  height: 3rem;
  border: solid 1px #c1d0d5;
  ${({ backgroundColor }) =>
    backgroundColor
      ? `
    background-color: ${backgroundColor};
    &&:hover{
      background-color: ${backgroundColor};
    }
  `
      : ''}

  & > ${TableData} {
    padding: 0.5rem;

    &:first-child {
      padding-left: 1rem;
    }
  }
`;

export const getContent = <T extends Record<string, any>>(column: Column<T>, row: T, idx: number) => {
  const arrayRegex = /(\[(\d)\])/g;
  if (column.renderContent) {
    return column.renderContent(row, idx);
  }

  if (column.key) {
    //object key path (ex: "driver.rides[0]")
    if (column.key.toString().includes('.') || arrayRegex.test(column.key.toString())) {
      const columnObjectKeys = column.key.toString().replace(arrayRegex, '.$2').replace(/^\./, '').split('.'); // a.b => [a, b], a.b[0] => [a, b, 0]
      let columnRow = row;
      let columnData = '';

      for (const key of columnObjectKeys) {
        if (columnRow[key] && typeof columnRow[key] === 'object') {
          columnRow = columnRow[key];
          continue;
        }

        columnData = columnRow[key];
      }

      return columnData;
    }

    return row[column.key];
  }

  return null;
};

export const renderTimestamp = <T extends object>(data: T) => <TimeStamp {...data} />;

/**
 * Table을 생성하는 컴포넌트로 `columns` 배열을 map 함으로서 테이블의 속성들과 테이블 바디의 컨텐츠를 생성한다.
 */
function Table<T extends Record<string, any>>({
  columns,
  data,
  subCol,
  onClickRow,
  selectedRowData,
  customStyle,
  scroll,
  hover,
  subRow,
  fallbackContent = <Null />,
  ...option
}: TableProps<T>) {
  const defaultMinWidth = useMemo(() => (scroll ? '50px' : 'auto'), [scroll]);

  return (
    <TableContainer scroll={scroll}>
      <ReactstrapTable customstyle={customStyle} hover={hover} {...option}>
        <thead>
          <TableHeadRow>
            {columns
              .filter(({ visible }) => visible ?? true)
              .map(({ title, renderHeader, minWidth = defaultMinWidth, width }, idx) => {
                if (renderHeader === undefined && title === undefined) {
                  console.error('please include renderHeader or title');
                }

                return (
                  <TableHeader minWidth={minWidth} width={width} key={`${title}_${idx}`}>
                    {renderHeader?.(data[idx], idx) ?? title}
                  </TableHeader>
                );
              })}
          </TableHeadRow>
        </thead>
        <tbody>
          {data.map((row, idx) => {
            let backgroundColor = null;

            if (
              (Array.isArray(selectedRowData?.index) && (selectedRowData?.index?.includes(row.id) || selectedRowData?.index?.includes(idx))) ||
              selectedRowData?.index === idx ||
              selectedRowData?.index === row.id
            ) {
              backgroundColor = selectedRowData?.backgroundColor || '';
            }

            return (
              <Fragment key={`row${idx}`}>
                <TableRow onClick={() => onClickRow?.(row, idx)} backgroundColor={backgroundColor}>
                  {columns
                    .filter(({ visible }) => visible ?? true)
                    .map((column, i) => {
                      const content = getContent(column, row, idx);

                      return <TableData key={`row${idx},col${i}`}>{content ?? fallbackContent}</TableData>;
                    })}
                  {subCol ? subCol(row, idx) : null}
                </TableRow>
                {subRow && subRow(row, idx)}
              </Fragment>
            );
          })}
        </tbody>
      </ReactstrapTable>
    </TableContainer>
  );
}

export default Table;

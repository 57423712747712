import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import deviceOtpResetSaga from '@/svc/account-svc/deviceOtpReset/saga';
import merchantSaga from '@/svc/account-svc/merchant/saga';
import otpSkipRuleSaga from '@/svc/account-svc/otpSkipRule/saga';
import phoneVerificationSaga from '@/svc/account-svc/phoneVerification/saga';
import recoverDeletedAccountSaga from '@/svc/account-svc/recoverDeletedAccount/saga';
import {
  migrateAccountIdentifierFailure,
  migrateAccountIdentifierRequest,
  migrateAccountIdentifierSuccess,
  updateRiderEmailRequest,
  updateRiderEmailSuccess,
  updateRiderEmailFailure,
  fetchAccountDetailRequest,
  fetchAccountDetailSuccess,
  fetchAccountDetailFailure,
  deleteOtpWhitelistSuccess,
  deleteOtpWhitelistFailure,
  deleteOtpWhitelistRequest,
  createOtpWhitelistSuccess,
  createOtpWhitelistFailure,
  createOtpWhitelistRequest,
} from '@/svc/account-svc/reducer';
import { authenticatedRequest } from '@/utils/request';

function* migrateAccountIdentifier({ payload }: ReturnType<typeof migrateAccountIdentifierRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/accounts/identifiers/migrate', { data: payload });
    const action = response.ok ? migrateAccountIdentifierSuccess(response.data) : migrateAccountIdentifierFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(migrateAccountIdentifierFailure((e as Error).message));
  }
}

function* updateRiderEmail({ payload: { id, email } }: ReturnType<typeof updateRiderEmailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/accounts/${id}/updateRiderEmail`, { data: { email } });
    const action = response.ok ? updateRiderEmailSuccess() : updateRiderEmailFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(updateRiderEmailFailure((e as Error).message));
  }
}

function* fetchAccountDetail({ payload }: ReturnType<typeof fetchAccountDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/accounts/${payload}`);
    const action = response.ok ? fetchAccountDetailSuccess(response.data) : fetchAccountDetailFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(fetchAccountDetailFailure((e as Error).message));
  }
}

function* createOtpWhitelist({ payload }: ReturnType<typeof createOtpWhitelistRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/accountsvc/admin/otp-whitelist', { data: payload });
    const action = response.ok ? createOtpWhitelistSuccess() : createOtpWhitelistFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(createOtpWhitelistFailure((e as Error).message));
  }
}

function* deleteOtpWhitelist({ payload }: ReturnType<typeof deleteOtpWhitelistRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accountsvc/admin/otp-whitelist/${payload}`);
    const action = response.ok ? deleteOtpWhitelistSuccess() : deleteOtpWhitelistFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(deleteOtpWhitelistFailure((e as Error).message));
  }
}

export default function* accountSaga() {
  yield takeLatest(migrateAccountIdentifierRequest.type, migrateAccountIdentifier);
  yield takeLatest(updateRiderEmailRequest.type, updateRiderEmail);
  yield takeLatest(fetchAccountDetailRequest.type, fetchAccountDetail);
  yield takeLatest(createOtpWhitelistRequest.type, createOtpWhitelist);
  yield takeLatest(deleteOtpWhitelistRequest.type, deleteOtpWhitelist);
  yield all([call(otpSkipRuleSaga), call(recoverDeletedAccountSaga), call(phoneVerificationSaga), call(merchantSaga), call(deviceOtpResetSaga)]);
}

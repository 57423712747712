import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REGION } from '@/assets/constants';
import { WarehouseFormInterface, WarehouseInterface } from '@/pages/Warehouse/type';
import { WarehouseState } from '@/svc/delivery-svc/warehouse/type';
import Service from '@/svc/serviceName';
import { getLastSelectedRegion } from '@/utils/localStorage';
import { ListResponse } from '@/utils/types';

const name = `${Service.deliverySvc}/warehouse`;

export const fetchAvailableWarehouseRequest = createAction<REGION>(`${name}/fetchAvailableWarehouseRequest`);
export const fetchAvailableWarehouseFailure = createAction<string>(`${name}/fetchAvailableWarehouseFailure`);

export const createWarehouseRequest = createAction<WarehouseFormInterface>(`${name}/createWarehouseRequest`);
export const createWarehouseSuccess = createAction(`${name}/createWarehouseSuccess`);
export const createWarehouseFailure = createAction<string>(`${name}/createWarehouseFailure`);

export const updateWarehouseRequest = createAction<{ id: string; data: Partial<WarehouseFormInterface> }>(`${name}/updateWarehouseRequest`);
export const updateWarehouseSuccess = createAction(`${name}/updateWarehouseSuccess`);
export const updateWarehouseFailure = createAction<string>(`${name}/updateWarehouseFailure`);

export const addWarehouseAgentRequest = createAction<{ id: string; agentId: string }>(`${name}/addWarehouseAgentRequest`);
export const addWarehouseAgentSuccess = createAction(`${name}/addWarehouseAgentSuccess`);
export const addWarehouseAgentFailure = createAction<string>(`${name}/addWarehouseAgentFailure`);

export const deleteWarehouseAgentRequest = createAction<{ id: string; agentId: string }>(`${name}/deleteWarehouseAgentRequest`);
export const deleteWarehouseAgentSuccess = createAction(`${name}/deleteWarehouseAgentSuccess`);
export const deleteWarehouseAgentFailure = createAction<string>(`${name}/deleteWarehouseAgentFailure`);

export const warehouseInitialState: WarehouseState = {
  region: getLastSelectedRegion(),
  availableWarehouses: {},
};

const warehouseSlice = createSlice({
  name,
  initialState: warehouseInitialState,
  reducers: {
    setRegion: {
      prepare: (region: REGION) => ({ payload: region }),
      reducer: (state, { payload }: PayloadAction<REGION>) => {
        state.region = payload;
      },
    },

    fetchAvailableWarehouseSuccess(state, { payload }: PayloadAction<ListResponse<WarehouseInterface>>) {
      state.availableWarehouses = { ...state.availableWarehouses, ...payload.entities.content };
    },
  },
});

export const { setRegion, fetchAvailableWarehouseSuccess } = warehouseSlice.actions;

export default warehouseSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "SMSTemplateList" */ '@/pages/SMSTemplate/List'));
const New = lazy(() => import(/* webpackChunkName: "SMSTemplateNew" */ '@/pages/SMSTemplate/New'));
const Edit = lazy(() => import(/* webpackChunkName: "SMSTemplateEdit" */ '@/pages/SMSTemplate/Edit'));

export const SMSTemplateRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.SMSTemplate,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SMSTemplate}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.SMSTemplate}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

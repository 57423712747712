import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "PenaltyList" */ '@/pages/Penalty/List'));
const ImposedPenalty = lazy(() => import(/* webpackChunkName: "PenaltyImposedPenalty" */ '@/pages/Penalty/ImposedPenalty'));
const Detail = lazy(() => import(/* webpackChunkName: "PenaltyDetail" */ '@/pages/Penalty/Detail'));

export const penaltyRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Penalty,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Penalty}/imposed`,
    component: ImposedPenalty,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Penalty}/:id`,
    component: Detail,
    exact: true,
  },
];

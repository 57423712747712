import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createExportScheduleFailure,
  createExportScheduleRequest,
  createExportScheduleSuccess,
  deleteExportScheduleFailure,
  deleteExportScheduleRequest,
  deleteExportScheduleSuccess,
  fetchExportScheduleDetailFailure,
  fetchExportScheduleDetailRequest,
  fetchExportScheduleDetailSuccess,
  fetchExportScheduleListFailure,
  fetchExportScheduleListRequest,
  fetchExportScheduleListSuccess,
  updateExportScheduleFailure,
  updateExportScheduleRequest,
  updateExportScheduleSuccess,
} from '@/pages/ExportSchedule/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

function* fetchExportScheduleList({ payload }: ReturnType<typeof fetchExportScheduleListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config/export_schedules', {
      params: payload.param,
      schema: [entity],
    });

    if (response.ok) {
      yield put(fetchExportScheduleListSuccess(response.data));
    } else {
      yield put(fetchExportScheduleListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchExportScheduleListFailure((e as Error).message));
  }
}

function* fetchExportScheduleDetail({ payload }: ReturnType<typeof fetchExportScheduleDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/config/export_schedules/${payload.id}`);

    if (response.ok) {
      yield put(fetchExportScheduleDetailSuccess(response.data));
    } else {
      yield put(fetchExportScheduleDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchExportScheduleDetailFailure((e as Error).message));
  }
}

function* createExportSchedule({ payload }: ReturnType<typeof createExportScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/export_schedules', { data: payload.param });

    if (response.ok) {
      yield put(createExportScheduleSuccess(response.data));
    } else {
      yield put(createExportScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(createExportScheduleFailure((e as Error).message));
  }
}

function* updateExportSchedule({ payload }: ReturnType<typeof updateExportScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/export_schedules/${payload.id}`, { data: payload.param });

    if (response.ok) {
      yield put(updateExportScheduleSuccess(response.data));
    } else {
      yield put(updateExportScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateExportScheduleFailure((e as Error).message));
  }
}

function* deleteExportSchedule({ payload }: ReturnType<typeof deleteExportScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/export_schedules/${payload.id}`);

    if (response.ok) {
      yield put(deleteExportScheduleSuccess());
    } else {
      yield put(deleteExportScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteExportScheduleFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchExportScheduleListRequest.type, fetchExportScheduleList);
  yield takeLatest(fetchExportScheduleDetailRequest.type, fetchExportScheduleDetail);
  yield takeLatest(createExportScheduleRequest.type, createExportSchedule);
  yield takeLatest(updateExportScheduleRequest.type, updateExportSchedule);
  yield takeLatest(deleteExportScheduleRequest.type, deleteExportSchedule);
}

import { ModalFooter as ModalFooterComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface ModalFooterProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function ModalFooter(props: ModalFooterProps) {
  return <ModalFooterComponent {...props} />;
}

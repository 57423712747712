export const PAYMENT_TRANSACTION_COLUMNS = {
  ID: 'id',
  REGION: 'region',
  STATUS: 'status',
  AMOUNT: 'amount',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
} as const;

export const PAYMENT_TRANSACTION_COLUMNS_LIST = Object.values(PAYMENT_TRANSACTION_COLUMNS);

export const PAYMENT_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: PAYMENT_TRANSACTION_COLUMNS.ID, label: 'id' },
  { key: PAYMENT_TRANSACTION_COLUMNS.REGION, label: 'region' },
  { key: PAYMENT_TRANSACTION_COLUMNS.STATUS, label: 'status' },
  { key: PAYMENT_TRANSACTION_COLUMNS.AMOUNT, label: 'amount' },
  { key: PAYMENT_TRANSACTION_COLUMNS.CREATED_AT, label: 'createdAt' },
  { key: PAYMENT_TRANSACTION_COLUMNS.UPDATED_AT, label: 'updatedAt' },
];

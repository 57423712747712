import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "OtpSkipRuleList" */ '@/pages/OtpSkipRule/List'));
const New = lazy(() => import(/* webpackChunkName: "OtpSkipRuleNew" */ '@/pages/OtpSkipRule/New'));

export const otpSkipRuleRouter: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.OtpSkipRule,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.OtpSkipRule}/new`,
    component: New,
    exact: true,
  },
];

import { createAction } from '@reduxjs/toolkit';

import { ProductInformationDto } from '@/pages/RideProductInfoTemplate/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.rideSvc}/rideProductInfoTemplate`;

export const createRideProductInfoTemplateRequest = createAction<FormData>(`${PREFIX}/createRideProductInfoTemplateRequest`);
export const createRideProductInfoTemplateSuccess = createAction<ProductInformationDto>(`${PREFIX}/createRideProductInfoTemplateSuccess`);
export const createRideProductInfoTemplateFailure = createAction<string>(`${PREFIX}/createRideProductInfoTemplateFailure`);

export const updateRideProductInfoTemplateRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/updateRideProductInfoTemplateRequest`);
export const updateRideProductInfoTemplateSuccess = createAction(`${PREFIX}/updateRideProductInfoTemplateSuccess`);
export const updateRideProductInfoTemplateFailure = createAction<string>(`${PREFIX}/updateRideProductInfoTemplateFailure`);

export const deleteRideProductInfoTemplateRequest = createAction<string>(`${PREFIX}/deleteRideProductInfoTemplateRequest`);
export const deleteRideProductInfoTemplateSuccess = createAction(`${PREFIX}/deleteRideProductInfoTemplateSuccess`);
export const deleteRideProductInfoTemplateFailure = createAction<string>(`${PREFIX}/deleteRideProductInfoTemplateFailure`);

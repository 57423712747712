import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import Edit from '@/pages/CpfContributionTable//Edit';
import Detail from '@/pages/CpfContributionTable/Detail';
import List from '@/pages/CpfContributionTable/List';
import New from '@/pages/CpfContributionTable/New';
import { RouteProps } from '@/utils/types';

export const cpfContributionTableRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.CpfContributionTable,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.CpfContributionTable}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.CpfContributionTable}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    exact: true,
    path: `${PATH.CpfContributionTable}/:id/edit`,
    component: Edit,
  },
];

import { Button as ButtonComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean;
  active?: boolean;
  block?: boolean;
  color?: string;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLButtonElement>;
  size?: string;
  cssModule?: CSSModule;
  close?: boolean;
  disable?: boolean;
  variant?: string;
}

export function Button(props: ButtonProps) {
  return <ButtonComponent {...props} />;
}

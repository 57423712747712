import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LeavePopupState } from '@/components/LeavePopup/type';

const initialState: LeavePopupState = {
  open: false,
  link: '',
  content: null,
};

const leavePopupSlice = createSlice({
  name: 'leave-popup',
  initialState,
  reducers: {
    setLeavePopupData(state, { payload }: PayloadAction<Partial<LeavePopupState>>) {
      Object.assign(state, payload);
    },
    resetLeavePopupData(state) {
      state.open = false;
      state.link = '';
      state.content = null;
    },
  },
});

export const { setLeavePopupData, resetLeavePopupData } = leavePopupSlice.actions;

export default leavePopupSlice.reducer;

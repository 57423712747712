import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createDeliveryScheduleFailure,
  createDeliveryScheduleRequest,
  createDeliveryScheduleSuccess,
  updateDeliveryScheduleRequest,
  updateDeliveryScheduleSuccess,
  updateDeliveryScheduleFailure,
  deleteDeliveryScheduleRequest,
  deleteDeliveryScheduleSuccess,
  deleteDeliveryScheduleFailure,
} from '@/svc/delivery-svc/deliverySchedule/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createDeliverySchedule({ payload }: ReturnType<typeof createDeliveryScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/config/ride_schedules`, {
      data: payload,
    });

    if (response.ok) {
      yield put(createDeliveryScheduleSuccess());
    } else {
      yield put(createDeliveryScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryScheduleFailure((e as Error).message));
  }
}

function* updateDeliverySchedule({ payload: { id, data } }: ReturnType<typeof updateDeliveryScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/ride_schedules/${id}`, {
      data,
    });

    if (response.ok) {
      yield put(updateDeliveryScheduleSuccess());
    } else {
      yield put(updateDeliveryScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryScheduleFailure((e as Error).message));
  }
}

function* deleteDeliverySchedule({ payload }: ReturnType<typeof deleteDeliveryScheduleRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/ride_schedules/${payload}`, {
      data: payload,
    });

    if (response.ok) {
      yield put(deleteDeliveryScheduleSuccess());
    } else {
      yield put(deleteDeliveryScheduleFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryScheduleFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(createDeliveryScheduleRequest.type, createDeliverySchedule);
  yield takeLatest(updateDeliveryScheduleRequest.type, updateDeliverySchedule);
  yield takeLatest(deleteDeliveryScheduleRequest.type, deleteDeliverySchedule);
}

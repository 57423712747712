import { Formik, FormikConfig } from 'formik';

import { otpWhitelistSchema } from '@/assets/constants';
import FormContent from '@/pages/OtpWhitelist/parts/FormContent';
import { OtpWhitelistBody } from '@/pages/OtpWhitelist/types';

interface FormProps {
  initialValues?: OtpWhitelistBody;
  onSubmit: FormikConfig<OtpWhitelistBody>['onSubmit'];
}

const initialForm: OtpWhitelistBody = {
  phoneNumber: '',
};

export default function Form({ initialValues, onSubmit }: FormProps) {
  return (
    <Formik initialValues={initialValues ?? initialForm} validationSchema={otpWhitelistSchema} onSubmit={onSubmit}>
      <FormContent />
    </Formik>
  );
}

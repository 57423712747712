import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  bulkCorporateStatementFailure,
  bulkCorporateStatementRequest,
  bulkCorporateStatementSuccess,
  downloadBulkProgressFailure,
  downloadBulkProgressRequest,
  downloadBulkProgressSuccess,
} from '@/svc/corporate-svc/InvoiceBulk/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* bulkCorporateStatement({ payload }: ReturnType<typeof bulkCorporateStatementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/corporates/statements/export:bulk', { data: payload });

    const action = response.ok ? bulkCorporateStatementSuccess() : bulkCorporateStatementFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkCorporateStatementFailure((e as Error).message));
  }
}

function* downloadBulkProgress({ payload }: ReturnType<typeof downloadBulkProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/statements/export/progress/${payload.progress_id}`, {
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response, 'CorporateInvoiceBulk.zip');

      yield put(downloadBulkProgressSuccess());
    } else {
      const data = yield response.json();

      yield put(downloadBulkProgressFailure(data.message));
    }
  } catch (e) {
    yield put(downloadBulkProgressFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(bulkCorporateStatementRequest.type, bulkCorporateStatement);
  yield takeLatest(downloadBulkProgressRequest.type, downloadBulkProgress);
}

import { ComponentType } from 'react';

import styled from 'styled-components';

import { Text } from '@/components/ValueField';

interface NullProps {
  color?: string;
  tag?: keyof JSX.IntrinsicElements | ComponentType<any>;
}

const StyledText = styled(Text)<NullProps>`
  color: ${(props: any) => props.color || '#536c79'};
`;

function Null({ color, tag }: NullProps) {
  return (
    <StyledText color={color} tag={tag}>
      NULL
    </StyledText>
  );
}

export default Null;

import { createAction } from '@reduxjs/toolkit';

import { InboxItem } from '@/pages/InboxItem/type';
import Service from '@/svc/serviceName';

export const inboxUpdateRequest = createAction(`${Service.inboxSvc}/inboxItem/inboxUpdateRequest`, (id: string, data: FormData) => ({
  payload: { id, data },
}));
export const inboxUpdateSuccess = createAction(`${Service.inboxSvc}/inboxItem/inboxUpdateSuccess`);
export const inboxUpdateFailure = createAction<string>(`${Service.inboxSvc}/inboxItem/inboxUpdateFailure`);

export const inboxCreateRequest = createAction<FormData>(`${Service.inboxSvc}/inboxItem/inboxCreateRequest`);
export const inboxCreateSuccess = createAction<InboxItem>(`${Service.inboxSvc}/inboxItem/inboxCreateSuccess`);
export const inboxCreateFailure = createAction<string>(`${Service.inboxSvc}/inboxItem/inboxCreateFailure`);

export const createBulkInboxItemRequest = createAction<FormData>(`${Service.inboxSvc}/inboxItem/createBulkInboxItemRequest`);
export const createBulkInboxItemSuccess = createAction(`${Service.inboxSvc}/inboxItem/createBulkInboxItemSuccess`);
export const createBulkInboxItemFailure = createAction<string>(`${Service.inboxSvc}/inboxItem/createBulkInboxItemFailure`);

export const downloadBulkInboxItemTemplateRequest = createAction(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateRequest`);
export const downloadBulkInboxItemTemplateSuccess = createAction(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateSuccess`);
export const downloadBulkInboxItemTemplateFailure = createAction<string>(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateFailure`);

export const inboxDeleteRequest = createAction<string>(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateRequest`);
export const inboxDeleteSuccess = createAction(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateSuccess`);
export const inboxDeleteFailure = createAction<string>(`${Service.inboxSvc}/inboxItem/downloadBulkInboxItemTemplateFailure`);

// const inboxItemSlice = createSlice({
//   name: `${Service.inboxSvc}/inboxItem`,
//   initialState: {},
//   reducers: {},
// });

// export default inboxItemSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DeliveryExportSchedule,
  DeliveryExportScheduleQuery,
  DeliveryExportScheduleSaveDto,
  ExportScheduleListResponse,
  ExportScheduleState,
} from '@/pages/ExportSchedule/type';

const initialState: ExportScheduleState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  createdId: null,
};

const exportScheduleSlice = createSlice({
  name: 'exportSchedule',
  initialState,
  reducers: {
    fetchExportScheduleListRequest: {
      prepare: (param: DeliveryExportScheduleQuery) => ({ payload: { param } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchExportScheduleListSuccess(state, { payload }: PayloadAction<ExportScheduleListResponse<DeliveryExportSchedule>>) {
      state.isFetching = false;
      state.error = null;
      state.byId = payload.entities.content ?? {};
      state.ids = payload.result;
    },
    fetchExportScheduleListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    fetchExportScheduleDetailRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchExportScheduleDetailSuccess(state, { payload }: PayloadAction<DeliveryExportSchedule>) {
      state.isFetching = false;
      state.byId = { ...state.byId, [payload.id as number]: payload };
    },
    fetchExportScheduleDetailFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    createExportScheduleRequest: {
      prepare: (param: DeliveryExportScheduleSaveDto) => ({ payload: { param } }),
      reducer: (state) => {
        state.isCreating = true;
      },
    },
    createExportScheduleSuccess: {
      prepare: (data: DeliveryExportSchedule) => ({ payload: { data } }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createExportScheduleFailure(state, { payload }: PayloadAction<string>) {
      state.isCreating = false;
      state.error = payload;
    },
    updateExportScheduleRequest: {
      prepare: (id: string, param: DeliveryExportScheduleSaveDto) => ({ payload: { id, param } }),
      reducer: (state) => {
        state.isUpdating = true;
      },
    },
    updateExportScheduleSuccess: {
      prepare: (data: DeliveryExportSchedule) => ({ payload: { data } }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateExportScheduleFailure(state, { payload }: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteExportScheduleRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.isDeleting = true;
      },
    },
    deleteExportScheduleSuccess(state) {
      state.isDeleting = false;
    },
    deleteExportScheduleFailure(state, { payload }: PayloadAction<string>) {
      state.isDeleting = false;
      state.error = payload;
    },
  },
});

export const {
  fetchExportScheduleListRequest,
  fetchExportScheduleListSuccess,
  fetchExportScheduleListFailure,
  fetchExportScheduleDetailRequest,
  fetchExportScheduleDetailSuccess,
  fetchExportScheduleDetailFailure,
  createExportScheduleRequest,
  createExportScheduleSuccess,
  createExportScheduleFailure,
  updateExportScheduleRequest,
  updateExportScheduleSuccess,
  updateExportScheduleFailure,
  deleteExportScheduleRequest,
  deleteExportScheduleSuccess,
  deleteExportScheduleFailure,
} = exportScheduleSlice.actions;

export default exportScheduleSlice.reducer;

import { createAction, createSlice } from '@reduxjs/toolkit';

import { PlaceExportQuery } from '@/pages/Place/type';
import placeTesterReducer, { placeTesterInitialState } from '@/svc/place-svc/placeTester/reducer';
import { PlaceServiceState, SubPlacesOrderUpdateDto, UpdateDisplayOrderDto } from '@/svc/place-svc/type';
import Service from '@/svc/serviceName';

const initialState: PlaceServiceState = {
  placeTester: placeTesterInitialState,
};

export const bulkUpdatePlaceDisplayOrderRequest = createAction<UpdateDisplayOrderDto[]>(`${Service.placeSvc}/bulkUpdatePlaceDisplayOrderRequest`);
export const bulkUpdatePlaceDisplayOrderSuccess = createAction(`${Service.placeSvc}/bulkUpdatePlaceDisplayOrderSuccess`);
export const bulkUpdatePlaceDisplayOrderFailure = createAction<string>(`${Service.placeSvc}/bulkUpdatePlaceDisplayOrderFailure`);

export const bulkUpdateSubPlaceDisplayOrderRequest = createAction<{ id: string; data: SubPlacesOrderUpdateDto }>(
  `${Service.placeSvc}/bulkUpdateSubPlaceDisplayOrderRequest`
);
export const bulkUpdateSubPlaceDisplayOrderSuccess = createAction(`${Service.placeSvc}/bulkUpdateSubPlaceDisplayOrderSuccess`);
export const bulkUpdateSubPlaceDisplayOrderFailure = createAction<string>(`${Service.placeSvc}/bulkUpdateSubPlaceDisplayOrderFailure`);

export const downloadBulkPlaceTemplateRequest = createAction(`${Service.placeSvc}/downloadBulkPlaceTemplateRequest`);
export const downloadBulkPlaceTemplateSuccess = createAction(`${Service.placeSvc}/downloadBulkPlaceTemplateSuccess`);
export const downloadBulkPlaceTemplateFailure = createAction<string>(`${Service.placeSvc}/downloadBulkPlaceTemplateFailure`);

export const exportPlaceRequest = createAction<PlaceExportQuery>(`${Service.placeSvc}/exportPlaceRequest`);
export const exportPlaceSuccess = createAction(`${Service.placeSvc}/exportPlaceSuccess`);
export const exportPlaceFailure = createAction<string>(`${Service.placeSvc}/exportPlaceFailure`);

const placeSlice = createSlice({
  name: Service.placeSvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.placeTester, placeTesterReducer(state.placeTester, action));
    }),
});

export default placeSlice.reducer;

import { Table as TableComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  cssModule?: CSSModule;
  size?: string;
  bordered?: boolean;
  borderless?: boolean;
  striped?: boolean;
  inverse?: boolean;
  hover?: boolean;
  reflow?: boolean;
  responsive?: boolean | string;
  tag?: React.ElementType;
  responsiveTag?: React.ElementType;
  innerRef?: React.Ref<HTMLTableElement>;
}

export function Table(props: TableProps) {
  return <TableComponent {...props} />;
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { authenticatedRequest } from '@/utils/request';

import { shbRequestHistoryRevertFailure, shbRequestHistoryRevertRequest, shbRequestHistoryRevertSuccess } from './reducer';

function* shbRequestHistoryRevert({ payload: { id, reason } }: ReturnType<typeof shbRequestHistoryRevertRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/v1/shb_request_history/${id}/revert`, {
      data: { reason },
    });

    if (response.ok) {
      yield put(shbRequestHistoryRevertSuccess());
    } else {
      yield put(shbRequestHistoryRevertFailure(response.data.message));
    }
  } catch (e) {
    yield put(shbRequestHistoryRevertFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(shbRequestHistoryRevertRequest.type, shbRequestHistoryRevert);
}

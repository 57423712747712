import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AdminLogList" */ '@/pages/AdminLog/List'));
const Detail = lazy(() => import(/* webpackChunkName: "AdminLogDetail" */ '@/pages/AdminLog/Detail'));

export const adminLogRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.AdminLog,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.AdminLog}/:id`,
    component: Detail,
    exact: true,
  },
];

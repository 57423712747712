import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { TRANSACTION_TYPE } from '@/pages/Transaction/constants';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "TransactionList" */ '@/pages/Transaction/List'));
const New = lazy(() => import(/* webpackChunkName: "TransactionNew" */ '@/pages/Transaction/New'));
const BulkTransaction = lazy(() => import(/* webpackChunkName: "TransactionBulkTransaction" */ '@/pages/Transaction/BulkTransaction'));
const BulkProcess = lazy(() => import(/* webpackChunkName: "TransactionBulkProcess" */ '@/pages/Transaction/BulkProcess'));
const Detail = lazy(() => import(/* webpackChunkName: "TransactionDetail" */ '@/pages/Transaction/Detail'));
const CashOutRequestLogDetail = lazy(
  () => import(/* webpackChunkName: "TransactionCashOutRequestLogDetail" */ '@/pages/Transaction/CashOutRequestLogDetail')
);

export const transactionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Transaction,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Transaction}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Transaction}/bulkTransaction`,
    component: BulkTransaction,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Transaction}/bulkProcess`,
    component: BulkProcess,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Transaction}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Transaction}/:txId/cashOutRequestLog/:msgId`,
    component: CashOutRequestLogDetail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: '/cashout_request',
    component: () => <List defaultType={TRANSACTION_TYPE.CASH_OUT} />,
    exact: true,
  },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDynamicSurgeChangeLogRequest,
  fetchDynamicSurgeChangeLogSuccess,
  fetchDynamicSurgeChangeLogFailure,
  fetchDynamicSurgeForCitiesRequest,
  fetchDynamicSurgeForCitiesSuccess,
  fetchDynamicSurgeForCitiesFailure,
  increaseDynamicSurgeLevelRequest,
  increaseDynamicSurgeLevelSuccess,
  increaseDynamicSurgeLevelFailure,
  updateDynamicSurgeAllowNegativeRequest,
  updateDynamicSurgeAllowNegativeSuccess,
  updateDynamicSurgeAllowNegativeFailure,
  updateDynamicSurgeEnabledRequest,
  updateDynamicSurgeEnabledSuccess,
  updateDynamicSurgeEnabledFailure,
} from '@/pages/DynamicSurgeMonitor/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchDynamicSurgeChangeLog({ payload: { id, data } }: ReturnType<typeof fetchDynamicSurgeChangeLogRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/v1/admin/dynamic_surges/${id}/surgeChangeLogs`, { params: data });

    if (response.ok) {
      yield put(fetchDynamicSurgeChangeLogSuccess(response.data));
    } else {
      yield put(fetchDynamicSurgeChangeLogFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDynamicSurgeChangeLogFailure((e as Error).message));
  }
}

function* fetchDynamicSurgeForCities({ payload }: ReturnType<typeof fetchDynamicSurgeForCitiesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/v1/admin/dynamic_surges/city/${payload.cityCode}/product/${payload.productCode}`, {
      params: payload,
    });

    if (response.ok) {
      yield put(fetchDynamicSurgeForCitiesSuccess(response.data));
    } else {
      yield put(fetchDynamicSurgeForCitiesFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDynamicSurgeForCitiesFailure((e as Error).message));
  }
}

function* updateDynamicSurgeEnabled({ payload }: ReturnType<typeof updateDynamicSurgeEnabledRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admin/dynamic_surges:toggleAllEnabled', { data: payload });

    if (response.ok) {
      yield put(updateDynamicSurgeEnabledSuccess());
    } else {
      yield put(updateDynamicSurgeEnabledFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDynamicSurgeEnabledFailure((e as Error).message));
  }
}

function* updateDynamicSurgeAllowNegative({ payload }: ReturnType<typeof updateDynamicSurgeAllowNegativeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admin/dynamic_surges:toggleAllowNegativeAll', { data: payload });

    if (response.ok) {
      yield put(updateDynamicSurgeAllowNegativeSuccess());
    } else {
      yield put(updateDynamicSurgeAllowNegativeFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDynamicSurgeAllowNegativeFailure((e as Error).message));
  }
}

function* increaseDynamicSurgeLevel({ payload }: ReturnType<typeof increaseDynamicSurgeLevelRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/v1/admin/dynamic_surges:incLevelNegative', { data: payload });

    if (response.ok) {
      yield put(increaseDynamicSurgeLevelSuccess());
    } else {
      yield put(increaseDynamicSurgeLevelFailure(response.data.message));
    }
  } catch (e) {
    yield put(increaseDynamicSurgeLevelFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchDynamicSurgeChangeLogRequest.type, fetchDynamicSurgeChangeLog);
  yield takeLatest(fetchDynamicSurgeForCitiesRequest.type, fetchDynamicSurgeForCities);
  yield takeLatest(updateDynamicSurgeEnabledRequest.type, updateDynamicSurgeEnabled);
  yield takeLatest(updateDynamicSurgeAllowNegativeRequest.type, updateDynamicSurgeAllowNegative);
  yield takeLatest(increaseDynamicSurgeLevelRequest.type, increaseDynamicSurgeLevel);
}

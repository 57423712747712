import { createAction } from '@reduxjs/toolkit';

import { SGTransactionCategoryType, TransactionCategoryType } from '@/assets/constants';
import { CPFTransactionRequestDto, TransactionProgressDto, TransactionRequestDto } from '@/pages/Transaction/types';
import { BulkCashOutRetryDto, BulkTransactionResultDto, FilteredCashOutRetryDto } from '@/svc/balance-svc/type';
import Service from '@/svc/serviceName';

const prefix = `${Service.balanceSvc}/transaction`;

export const createTransactionRequest = createAction<TransactionRequestDto>(`${prefix}/createTransactionRequest`);
export const createTransactionSuccess = createAction(`${prefix}/createTransactionSuccess`);
export const createTransactionFailure = createAction<{ status?: number; message: string }>(`${prefix}/createTransactionFailure`);

export const approveTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/approveTransactionRequest`);
export const approveTransactionSuccess = createAction(`${prefix}/approveTransactionSuccess`);
export const approveTransactionFailure = createAction<string>(`${prefix}/approveTransactionFailure`);

export const rejectTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/rejectTransactionRequest`);
export const rejectTransactionSuccess = createAction(`${prefix}/rejectTransactionSuccess`);
export const rejectTransactionFailure = createAction<string>(`${prefix}/rejectTransactionFailure`);

export const failTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/failTransactionRequest`);
export const failTransactionSuccess = createAction(`${prefix}/failTransactionSuccess`);
export const failTransactionFailure = createAction<string>(`${prefix}/failTransactionFailure`);

export const restoreTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/restoreTransactionRequest`);
export const restoreTransactionSuccess = createAction(`${prefix}/restoreTransactionSuccess`);
export const restoreTransactionFailure = createAction<string>(`${prefix}/restoreTransactionFailure`);

export const changeCashTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/changeCashTransactionRequest`);
export const changeCashTransactionSuccess = createAction(`${prefix}/changeCashTransactionSuccess`);
export const changeCashTransactionFailure = createAction<string>(`${prefix}/changeCashTransactionFailure`);

export const cashOutRetryTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/cashOutRetryTransactionRequest`);
export const cashOutRetryTransactionSuccess = createAction(`${prefix}/cashOutRetryTransactionSuccess`);
export const cashOutRetryTransactionFailure = createAction<string>(`${prefix}/cashOutRetryTransactionFailure`);

export const resolvePendingCashOutRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/resolvePendingCashOutRequest`);
export const resolvePendingCashOutSuccess = createAction(`${prefix}/resolvePendingCashOutSuccess`);
export const resolvePendingCashOutFailure = createAction<string>(`${prefix}/resolvePendingCashOutFailure`);

export const replayTransactionRequest = createAction<string>(`${prefix}/replayTransactionRequest`);
export const replayTransactionSuccess = createAction(`${prefix}/replayTransactionSuccess`);
export const replayTransactionFailure = createAction<string>(`${prefix}/replayTransactionFailure`);

export const bulkApproveTransactionsRequest = createAction<string[]>(`${prefix}/bulkApproveTransactionsRequest`);
export const bulkApproveTransactionsSuccess = createAction(`${prefix}/bulkApproveTransactionsSuccess`);
export const bulkApproveTransactionsFailure = createAction<string>(`${prefix}/bulkApproveTransactionsFailure`);

export const getBulkProcessExcelTemplateRequest = createAction(`${prefix}/getBulkProcessExcelTemplateRequest`);
export const getBulkProcessExcelTemplateSuccess = createAction(`${prefix}/getBulkProcessExcelTemplateSuccess`);
export const getBulkProcessExcelTemplateFailure = createAction<string>(`${prefix}/getBulkProcessExcelTemplateFailure`);

export const uploadBulkProcessExcelRequest = createAction<FormData>(`${prefix}/uploadBulkProcessExcelRequest`);
export const uploadBulkProcessExcelSuccess = createAction(`${prefix}/uploadBulkProcessExcelSuccess`);
export const uploadBulkProcessExcelFailure = createAction<string>(`${prefix}/uploadBulkProcessExcelFailure`);

export const getBulkTransactionExcelTemplateRequest = createAction(`${prefix}/getBulkTransactionExcelTemplateRequest`);
export const getBulkTransactionExcelTemplateSuccess = createAction(`${prefix}/getBulkTransactionExcelTemplateSuccess`);
export const getBulkTransactionExcelTemplateFailure = createAction<string>(`${prefix}/getBulkTransactionExcelTemplateFailure`);

export const uploadBulkTransactionExcelRequest = createAction<{
  admin: string;
  categoryType: TransactionCategoryType | SGTransactionCategoryType;
  data: FormData;
}>(`${prefix}/uploadBulkTransactionExcelRequest`);
export const uploadBulkTransactionExcelSuccess = createAction<TransactionProgressDto>(`${prefix}/uploadBulkTransactionExcelSuccess`);
export const uploadBulkTransactionExcelFailure = createAction<string>(`${prefix}/uploadBulkTransactionExcelFailure`);

export const uploadBulkCpfTransactionExcelRequest = createAction<{
  data: FormData;
}>(`${prefix}/uploadBulkCpfTransactionExcelRequest`);
export const uploadBulkCpfTransactionExcelSuccess = createAction<TransactionProgressDto>(`${prefix}/uploadBulkCpfTransactionExcelSuccess`);
export const uploadBulkCpfTransactionExcelFailure = createAction<string>(`${prefix}/uploadBulkCpfTransactionExcelFailure`);

export const getBulkCpfTransactionExcelTemplateRequest = createAction(`${prefix}/getBulkCpfTransactionExcelTemplateRequest`);
export const getBulkCpfTransactionExcelTemplateSuccess = createAction(`${prefix}/getBulkCpfTransactionExcelTemplateSuccess`);
export const getBulkCpfTransactionExcelTemplateFailure = createAction<string>(`${prefix}/getBulkCpfTransactionExcelTemplateFailure`);

export const cashOutRetryBySelectedRequest = createAction<BulkCashOutRetryDto>(`${prefix}/cashOutRetryBySelectedRequest`);
export const cashOutRetryBySelectedSuccess = createAction<BulkTransactionResultDto>(`${prefix}/cashOutRetryBySelectedSuccess`);
export const cashOutRetryBySelectedFailure = createAction<string>(`${prefix}/cashOutRetryBySelectedFailure`);

export const cashOutRetryByFilteredRequest = createAction<FilteredCashOutRetryDto>(`${prefix}/cashOutRetryByFilteredRequest`);
export const cashOutRetryByFilteredSuccess = createAction<BulkTransactionResultDto>(`${prefix}/cashOutRetryByFilteredSuccess`);
export const cashOutRetryByFilteredFailure = createAction<string>(`${prefix}/cashOutRetryByFilteredFailure`);

export const createCpfTransactionRequest = createAction<CPFTransactionRequestDto>(`${prefix}/createCpfTransactionRequest`);
export const createCpfTransactionSuccess = createAction(`${prefix}/createCpfTransactionSuccess`);
export const createCpfTransactionFailure = createAction<{ status?: number; message: string }>(`${prefix}/createCpfTransactionFailure`);

export const reversalTransactionRequest = createAction<{ id: string; data: Record<string, string> }>(`${prefix}/reversalTransactionRequest`);
export const reversalTransactionSuccess = createAction(`${prefix}/reversalTransactionSuccess`);
export const reversalTransactionFailure = createAction<string>(`${prefix}/reversalTransactionFailure`);

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Airport, AirportState } from '@/pages/Airports/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';

const initialState: AirportState = {
  isFetching: false,
  error: null,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
};

export const createAirportRequest = createAction<Airport>('airport/createAirportRequest');
export const createAirportSuccess = createAction<Airport>('airport/createAirportSuccess');
export const createAirportFailure = createAction<string>('airport/createAirportFailure');

const failure = (state: AirportState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

const airportSlice = createSlice({
  name: 'airport',
  initialState,
  reducers: {
    fetchAirportListRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (param) => ({ payload: param }),
    },
    fetchAirportListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchAirportListFailure: failure,

    fetchAirportDetailRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id) => ({ payload: id }),
    },
    fetchAirportDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchAirportDetailFailure: failure,

    updateAirportRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id, body) => ({ payload: { id, body } }),
    },
    updateAirportSuccess(state) {
      state.isFetching = false;
    },
    updateAirportFailure: failure,

    deleteAirportRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id) => ({ payload: id }),
    },
    deleteAirportSuccess(state) {
      state.isFetching = false;
    },
    deleteAirportFailure: failure,
  },
});

export const {
  fetchAirportListRequest,
  fetchAirportListSuccess,
  fetchAirportListFailure,
  fetchAirportDetailRequest,
  fetchAirportDetailSuccess,
  fetchAirportDetailFailure,
  updateAirportRequest,
  updateAirportSuccess,
  updateAirportFailure,
  deleteAirportRequest,
  deleteAirportSuccess,
  deleteAirportFailure,
} = airportSlice.actions;

export default airportSlice.reducer;

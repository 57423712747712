import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import regex from '@/assets/regex';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RiderMissionList" */ '@/pages/RiderMission/List'));
const Detail = lazy(() => import(/* webpackChunkName: "RiderMissionDetail" */ '@/pages/RiderMission/Detail'));
const New = lazy(() => import(/* webpackChunkName: "RiderMissionNew" */ '@/pages/RiderMission/New'));
const Edit = lazy(() => import(/* webpackChunkName: "RiderMissionEdit" */ '@/pages/RiderMission/Edit'));
const ProgressList = lazy(() => import(/* webpackChunkName: "RiderMissionProgressList" */ '@/pages/RiderMission/ProgressList'));
const ProgressDetail = lazy(() => import(/* webpackChunkName: "RiderMissionProgressDetail" */ '@/pages/RiderMission/ProgressDetail'));
const BackgroundProgressDetail = lazy(
  () => import(/* webpackChunkName: "RiderMissionBackgroundProgressDetail" */ '@/pages/RiderMission/BackgroundProgressDetail')
);

export const riderMissionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.RiderMission,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.RiderMission}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.RiderMission}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RiderMission}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RiderMission}/:id(${regex.uuidRoute})/progress`,
    component: ProgressList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RiderMission}/:id(${regex.uuidRoute})/progress/:progressId(${regex.uuidRoute})`,
    component: ProgressDetail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RiderMission}/:id(${regex.uuidRoute})/background-progress/:progressId(${regex.uuidRoute})`,
    component: BackgroundProgressDetail,
    exact: true,
  },
];

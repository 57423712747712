import { AppState } from '@/store/reducers';
import { PopupType, POPUP_TYPE } from '@/svc/common/type';

export const selectConfirmData = (state: AppState) => state.common.confirmData;
export const selectScheduleSurgeData = (state: AppState) => state.common.scheduleSurgeData;
export const selectRetryCashOutResponse = (state: AppState) => state.common.retryCashOutResponse;

const selectPopupFlag = (key: PopupType) => (state: AppState) => state.common.popup[key];

export const selectConfirmOpenFlag = selectPopupFlag(POPUP_TYPE.Confirm);
export const selectDeliverySearchOpenFlag = selectPopupFlag(POPUP_TYPE.DeliverySearch);
export const selectIdentifierMigrationOpenFlag = selectPopupFlag(POPUP_TYPE.IdentifierMigration);
export const selectKeyInOpenFlag = selectPopupFlag(POPUP_TYPE.KeyIn);
export const selectFindModalOpenFlag = selectPopupFlag(POPUP_TYPE.FindModal);
export const selectScheduleSurgeOpenFlag = selectPopupFlag(POPUP_TYPE.ScheduleSurge);
export const selectScheduleSyncConfirmOpenFlag = selectPopupFlag(POPUP_TYPE.ScheduleSyncConfirm);
export const selectRidePenaltyFeeReasonOpenFlag = selectPopupFlag(POPUP_TYPE.RidePenaltyFeeReason);
export const selectBulkSyncDriverincentiveOpenFlag = selectPopupFlag(POPUP_TYPE.BulkSyncDriverIncentive);
export const selectRetryCashOutResultOpenFlag = selectPopupFlag(POPUP_TYPE.RetryCashOutResult);

import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import { trimAllStringValues } from '@/utils/etc';

const trimValueBeforeRequestMiddleware = () => (next: Dispatch) => async (action: PayloadAction<unknown>) => {
  const { type, payload } = action;

  if (type.includes('Request')) {
    if (payload) {
      action.payload = await trimAllStringValues(payload);
    }
  }

  return next(action);
};

export default trimValueBeforeRequestMiddleware;

import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { BulkReSyncErrorResponse, BulkReSyncParams } from '@/svc/incentive-svc/type';
import Service from '@/svc/serviceName';

export interface IncentiveSvcState {
  error: Record<'bulkBan', string | null>;
}

const initialState: IncentiveSvcState = {
  error: {
    bulkBan: null,
  },
};

export const fetchBulkBanDriverMissionsTemplateRequest = createAction(
  `${Service.incentiveSvc}/fetchBulkBanDriverMissionsTemplateRequest`,
  (name?: string) => ({ payload: name })
);
export const fetchBulkBanDriverMissionsTemplateSuccess = createAction(`${Service.incentiveSvc}/fetchBulkBanDriverMissionsTemplateSuccess`);
export const fetchBulkBanDriverMissionsTemplateFailure = createAction<string>(`${Service.incentiveSvc}/fetchBulkBanDriverMissionsTemplateFailure`);

export const bulkBanDriverMissionsSuccess = createAction(`${Service.incentiveSvc}/bulkBanDriverMissionsSuccess`);

export const syncAllDriverMissionProgressRequest = createAction<{ id: string; delaySecond?: number }>(
  `${Service.incentiveSvc}/syncAllDriverMissionProgressRequest`
);
export const syncAllDriverMissionProgressSuccess = createAction(`${Service.incentiveSvc}/syncAllDriverMissionProgressSuccess`);
export const syncAllDriverMissionProgressFailure = createAction<string>(`${Service.incentiveSvc}/syncAllDriverMissionProgressFailure`);

export const fetchBulkSyncDriverIncentiveTemplateRequest = createAction(`${Service.incentiveSvc}/fetchBulkSyncDriverIncentiveTemplateRequest`);
export const fetchBulkSyncDriverIncentiveTemplateSuccess = createAction(`${Service.incentiveSvc}/fetchBulkSyncDriverIncentiveTemplateSuccess`);
export const fetchBulkSyncDriverIncentiveTemplateFailure = createAction<string>(
  `${Service.incentiveSvc}/fetchBulkSyncDriverIncentiveTemplateFailure`
);

export const bulkSyncDriverIncentiveRequest = createAction<{ id: string; data: FormData; params: BulkReSyncParams }>(
  `${Service.incentiveSvc}/bulkSyncDriverIncentiveRequest`
);
export const bulkSyncDriverIncentiveSuccess = createAction(`${Service.incentiveSvc}/bulkSyncDriverIncentiveSuccess`);
export const bulkSyncDriverIncentiveFailure = createAction<BulkReSyncErrorResponse | string>(
  `${Service.incentiveSvc}/bulkSyncDriverIncentiveFailure`
);

export const downloadDriverParticipantsTemplateRequest = createAction(`${Service.incentiveSvc}/downloadDriverParticipantsTemplateRequest`);
export const downloadDriverParticipantsTemplateSuccess = createAction(`${Service.incentiveSvc}/downloadDriverParticipantsTemplateSuccess`);
export const downloadDriverParticipantsTemplateFailure = createAction<string>(`${Service.incentiveSvc}/downloadDriverParticipantsTemplateFailure`);

export const downloadDriverExcludeTemplateRequest = createAction(`${Service.incentiveSvc}/downloadDriverExcludeTemplateRequest`);
export const downloadDriverExcludeTemplateSuccess = createAction(`${Service.incentiveSvc}/downloadDriverExcludeTemplateSuccess`);
export const downloadDriverExcludeTemplateFailure = createAction<string>(`${Service.incentiveSvc}/downloadDriverExcludeTemplateFailure`);

export const bulkAddDriverParticipantsRequest = createAction<{ id: string; data: FormData }>(
  `${Service.incentiveSvc}/bulkAddDriverParticipantsRequest`
);
export const bulkAddDriverParticipantsSuccess = createAction(`${Service.incentiveSvc}/bulkAddDriverParticipantsSuccess`);
export const bulkAddDriverParticipantsFailure = createAction<string>(`${Service.incentiveSvc}/bulkAddDriverParticipantsFailure`);

export const bulkExcludeDriverRequest = createAction<{ id: string; data: FormData }>(`${Service.incentiveSvc}/bulkExcludeDriverRequest`);
export const bulkExcludeDriverSuccess = createAction(`${Service.incentiveSvc}/bulkExcludeDriverSuccess`);
export const bulkExcludeDriverFailure = createAction<string>(`${Service.incentiveSvc}/bulkExcludeDriverFailure`);

export const incentiveSvcSlice = createSlice({
  name: Service.incentiveSvc,
  initialState,
  reducers: {
    bulkBanDriverMissionsRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.error.bulkBan = null;
      },
    },
    bulkBanDriverMissionsFailure(state, { payload }: PayloadAction<string>) {
      state.error.bulkBan = payload;
    },
  },
});

export const { bulkBanDriverMissionsRequest, bulkBanDriverMissionsFailure } = incentiveSvcSlice.actions;

export default incentiveSvcSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchCorporateManagerListRequest,
  fetchCorporateManagerListFailure,
  fetchCorporateManagerListSuccess,
  fetchCorporateManagerDetailFailure,
  fetchCorporateManagerDetailRequest,
  fetchCorporateManagerDetailSuccess,
  createCorporateManagerFailure,
  createCorporateManagerRequest,
  createCorporateManagerSuccess,
  updateCorporateManagerFailure,
  updateCorporateManagerRequest,
  updateCorporateManagerSuccess,
  deleteCorporateManagerFailure,
  deleteCorporateManagerRequest,
  deleteCorporateManagerSuccess,
} from '@/pages/CorporateManager/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity, schema } = getContentSchema();

const basePath = '/admin/managers';

function* fetchCorporateManagerList({ payload }: ReturnType<typeof fetchCorporateManagerListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(basePath, { params: payload, schema });

    const action = response.ok ? fetchCorporateManagerListSuccess(response.data) : fetchCorporateManagerListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchCorporateManagerListFailure((e as Error).message));
  }
}

function* fetchCorporateManagerDetail({ payload }: ReturnType<typeof fetchCorporateManagerDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/${payload}`, { schema: entity });

    const action = response.ok ? fetchCorporateManagerDetailSuccess(response.data) : fetchCorporateManagerDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchCorporateManagerDetailFailure((e as Error).message));
  }
}

function* createCorporateManager({ payload }: ReturnType<typeof createCorporateManagerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createCorporateManagerSuccess(response.data.id) : createCorporateManagerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createCorporateManagerFailure((e as Error).message));
  }
}

function* updateCorporateManager({ payload: { id, body } }: ReturnType<typeof updateCorporateManagerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateCorporateManagerSuccess(response.data.id) : updateCorporateManagerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateCorporateManagerFailure((e as Error).message));
  }
}

function* deleteCorporateManager({ payload }: ReturnType<typeof deleteCorporateManagerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${basePath}/${payload}`);

    const action = response.ok ? deleteCorporateManagerSuccess() : deleteCorporateManagerFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteCorporateManagerFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchCorporateManagerListRequest.type, fetchCorporateManagerList);
  yield takeLatest(fetchCorporateManagerDetailRequest.type, fetchCorporateManagerDetail);
  yield takeLatest(createCorporateManagerRequest.type, createCorporateManager);
  yield takeLatest(updateCorporateManagerRequest.type, updateCorporateManager);
  yield takeLatest(deleteCorporateManagerRequest.type, deleteCorporateManager);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  addPlaceTesterRequest,
  addPlaceTesterSuccess,
  addPlaceTesterFailure,
  deletePlaceTesterRequest,
  deletePlaceTesterSuccess,
  deletePlaceTesterFailure,
  fetchPlaceTesterTemplateRequest,
  fetchPlaceTesterTemplateSuccess,
  fetchPlaceTesterTemplateFailure,
  addPlaceTesterBulkRequest,
  addPlaceTesterBulkSuccess,
  addPlaceTesterBulkFailure,
} from '@/svc/place-svc/placeTester/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/places/testers';

function* addPlaceTester({ payload }: ReturnType<typeof addPlaceTesterRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}`, {
      data: payload,
    });

    const action = response.ok ? addPlaceTesterSuccess(response.data) : addPlaceTesterFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(addPlaceTesterFailure((e as Error).message));
  }
}

function* deletePlaceTester({ payload }: ReturnType<typeof deletePlaceTesterRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${basePath}/${payload}`);

    const action = response.ok ? deletePlaceTesterSuccess() : deletePlaceTesterFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deletePlaceTesterFailure((e as Error).message));
  }
}

function* fetchPlaceTesterTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/template:upload`, { responseType: 'blob', headers: { accept: '*/*' } });

    if (response.ok) {
      yield saveFile(response, 'place_tester.xlsx');
      yield put(fetchPlaceTesterTemplateSuccess());
    } else {
      yield put(fetchPlaceTesterTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchPlaceTesterTemplateFailure((e as Error).message));
  }
}

function* addPlaceTesterBulk({ payload }: ReturnType<typeof addPlaceTesterBulkRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}/bulk_upload`, {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? addPlaceTesterBulkSuccess() : addPlaceTesterBulkFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(addPlaceTesterBulkFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(addPlaceTesterRequest.type, addPlaceTester);
  yield takeLatest(deletePlaceTesterRequest.type, deletePlaceTester);
  yield takeLatest(fetchPlaceTesterTemplateRequest.type, fetchPlaceTesterTemplate);
  yield takeLatest(addPlaceTesterBulkRequest.type, addPlaceTesterBulk);
}

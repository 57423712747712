import { createAction } from '@reduxjs/toolkit';

import { BankUnverifyDto } from '@/svc/balance-svc/type';
import Service from '@/svc/serviceName';

export const updateCurrentAccountBankInfoRequest = createAction(
  `${Service.balanceSvc}/account/updateCurrentAccountBankInfoRequest`,
  (id: string, data: any) => ({
    payload: { id, data },
  })
);
export const updateCurrentAccountBankInfoSuccess = createAction(`${Service.balanceSvc}/account/updateCurrentAccountBankInfoSuccess`);
export const updateCurrentAccountBankInfoFailure = createAction<string>(`${Service.balanceSvc}/account/updateCurrentAccountBankInfoFailure`);

export const replayCurrentAccountRequest = createAction<string>(`${Service.balanceSvc}/account/replayCurrentAccountRequest`);
export const replayCurrentAccountSuccess = createAction<any>(`${Service.balanceSvc}/account/replayCurrentAccountSuccess`);
export const replayCurrentAccountFailure = createAction<string>(`${Service.balanceSvc}/account/replayCurrentAccountFailure`);

export const verifyCurrentAccountBankInfoRequest = createAction<string>(`${Service.balanceSvc}/account/verifyCurrentAccountBankInfoRequest`);
export const verifyCurrentAccountBankInfoSuccess = createAction(`${Service.balanceSvc}/account/verifyCurrentAccountBankInfoSuccess`);
export const verifyCurrentAccountBankInfoFailure = createAction<string>(`${Service.balanceSvc}/account/verifyCurrentAccountBankInfoFailure`);

export const unverifyCurrentAccountBankInfoRequest = createAction<{ id: string; data: BankUnverifyDto }>(
  `${Service.balanceSvc}/account/unverifyCurrentAccountBankInfoRequest`
);
export const unverifyCurrentAccountBankInfoSuccess = createAction(`${Service.balanceSvc}/account/unverifyCurrentAccountBankInfoSuccess`);
export const unverifyCurrentAccountBankInfoFailure = createAction<string>(`${Service.balanceSvc}/account/unverifyCurrentAccountBankInfoFailure`);

export const replayDepositAccountRequest = createAction<string>(`${Service.balanceSvc}/account/replayDepositAccountRequest`);
export const replayDepositAccountSuccess = createAction<any>(`${Service.balanceSvc}/account/replayDepositAccountSuccess`);
export const replayDepositAccountFailure = createAction<string>(`${Service.balanceSvc}/account/replayDepositAccountFailure`);

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "PrefixCouponList" */ '@/pages/PrefixCoupon/List'));
const Detail = lazy(() => import(/* webpackChunkName: "PrefixCouponDetail" */ '@/pages/PrefixCoupon/Detail'));
const New = lazy(() => import(/* webpackChunkName: "PrefixCouponNew" */ '@/pages/PrefixCoupon/New'));
const Edit = lazy(() => import(/* webpackChunkName: "PrefixCouponEdit" */ '@/pages/PrefixCoupon/Edit'));

export const prefixCouponRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.PrefixCoupon,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.PrefixCoupon}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.PrefixCoupon}/:id`,
    exact: true,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.PrefixCoupon}/:id/edit`,
    exact: true,
    component: Edit,
  },
];

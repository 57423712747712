import { createAction } from '@reduxjs/toolkit';

import { ExportZoneParam, ImportZoneDto } from '@/svc/ride-svc/type';

const PREFIX = 'zone';

export const deleteZoneRequest = createAction<string>(`${PREFIX}/deleteZoneRequest`);
export const deleteZoneSuccess = createAction(`${PREFIX}/deleteZoneSuccess`);
export const deleteZoneFailure = createAction<string>(`${PREFIX}/deleteZoneFailure`);

export const downloadZoneGeoJsonRequest = createAction<ExportZoneParam>(`${PREFIX}/downloadZoneGeoJsonRequest`);
export const downloadZoneGeoJsonSuccess = createAction(`${PREFIX}/downloadZoneGeoJsonSuccess`);
export const downloadZoneGeoJsonFailure = createAction<string>(`${PREFIX}/downloadZoneGeoJsonFailure`);

export const importZonesGeoJsonRequest = createAction<ImportZoneDto>(`${PREFIX}/importZonesGeoJsonRequest`);
export const importZonesGeoJsonSuccess = createAction(`${PREFIX}/importZonesGeoJsonSuccess`);
export const importZonesGeoJsonFailure = createAction<string>(`${PREFIX}/importZonesGeoJsonFailure`);

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Nav } from '@/components/template';
import SideMenuContainer from '@/layout/Sidebar/SideMenuContainer';
import { BaseNavItemType, NavItems } from '@/layout/Sidebar/_nav';

interface SidebarContentProps {
  pageName: string;
  navs: NavItems;
  findNavItems: BaseNavItemType[];
}

export default function SidebarContent({ pageName, navs, findNavItems }: SidebarContentProps) {
  return (
    <nav className='sidebar-nav'>
      {pageName !== '' && !findNavItems.length ? (
        <div className='tw-ml-1 tw-mt-1'>Sorry. No matches.</div>
      ) : (
        <Nav className='tw-pb-8'>
          <DndProvider backend={HTML5Backend}>
            {navs.map((item, itemIdx) => (
              <SideMenuContainer item={item} index={itemIdx} key={item.url} />
            ))}
          </DndProvider>
        </Nav>
      )}
    </nav>
  );
}

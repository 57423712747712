import { lazy } from 'react';

import { Pages, SidebarPathType } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard'));
const PhoneVerification = lazy(() => import(/* webpackChunkName: "PhoneVerification" */ '@/pages/PhoneVerification'));
const DeliveryDriver = lazy(() => import(/* webpackChunkName: "DeliveryDriver" */ '@/pages/DeliveryDriver'));
const PendingRide = lazy(() => import(/* webpackChunkName: "PendingRide" */ '@/pages/PendingRide'));
const DeviceOtpReset = lazy(() => import(/* webpackChunkName: "DeviceOtpReset" */ '@/pages/DeviceOtpReset'));

export interface BaseNavItemType {
  name: string;
  url: string;
  Pages?: Pages;
  component?: any;
  title?: string;
  divider?: any;
  children?: ChildrenNavItems;
  class?: any;
  badge?: NavItemBadge;
  wrapper?: any;
  permission?: string[];

  permissionType?: ActionType;

  isOpenMenu?: boolean;
}

export interface NavItemType extends BaseNavItemType {
  url: SidebarPathType;
}

export interface ChildrenNavItemType extends BaseNavItemType {
  url: string;
}

export interface NavItemBadge {
  class: string;
  variant: string;
  text: string;
}

export type NavItems = NavItemType[];

export type ChildrenNavItems = ChildrenNavItemType[];

export interface Nav {
  items: NavItems;
}

const nav: Nav = {
  items: [
    {
      name: 'menu.dashboard',
      url: PATH.Dashboard,
      component: Dashboard,
      Pages: Pages.Dashboard,
    },
    {
      name: 'menu.tada_rides',
      url: '/TADA_RIDES',
      isOpenMenu: true,
      children: [
        {
          name: 'Full Ride',
          url: PATH.Ride,
          Pages: Pages.Ride,
          permissionType: ActionType.LIST,
        },
        {
          name: 'Ride',
          url: `${PATH.Ride}/restrict`,
          Pages: Pages.Ride,
        },
        {
          name: 'Reservation Dashboard',
          url: `${PATH.ReservationRide}/dashboard`,
          Pages: Pages.ReservationRide,
          permissionType: ActionType.READ,
        },
        {
          name: 'Full Reservation Ride',
          url: PATH.ReservationRide,
          Pages: Pages.ReservationRide,
          permissionType: ActionType.LIST,
        },
        {
          name: 'Reservation Ride',
          url: `${PATH.ReservationRide}/restrict`,
          Pages: Pages.ReservationRide,
        },
        {
          name: 'menu.reviews',
          url: PATH.Reviews,
          Pages: Pages.Reviews,
        },
        {
          name: 'Route Test',
          url: PATH.RouteTest,
          Pages: Pages.RouteTest,
        },
      ],
    },
    {
      name: 'Place',
      url: '/TADA_PLACE',
      isOpenMenu: true,
      children: [
        {
          name: 'Place',
          url: PATH.Place,
          Pages: Pages.Place,
        },
        {
          name: 'Google Place',
          url: PATH.GooglePlace,
          Pages: Pages.GooglePlace,
        },
        {
          name: 'Place Tester',
          url: PATH.PlaceTester,
          Pages: Pages.PlaceTester,
        },
        {
          name: 'User Submissions',
          url: PATH.UserSubmission,
          Pages: Pages.UserSubmission,
        },
        {
          name: 'Toll Gantry',
          url: PATH.TollGantry,
          Pages: Pages.TollGantry,
        },
        {
          name: 'Toll Time Table',
          url: PATH.TollTimeTable,
          Pages: Pages.TollTimeTable,
        },
        {
          name: 'Toll Free & Holiday Fee Date',
          url: PATH.TollFreeDate,
          Pages: Pages.TollFreeDate,
        },
      ],
    },
    {
      name: 'menu.payment_transactions',
      url: '/PAYMENT_TRANSACTIONS',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.alipay_transactions',
          url: PATH.AlipayTransactions,
          Pages: Pages.AlipayTransactions,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.pao_transactions',
          url: PATH.PaoTransactions,
          Pages: Pages.PaoTransactions,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.momo_transactions',
          url: PATH.MomopayTransactions,
          Pages: Pages.MomopayTransactions,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.pipay_transactions',
          url: PATH.PipayTransactions,
          Pages: Pages.PipayTransactions,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.cmcb_transactions',
          url: PATH.CmcbTransactions,
          Pages: Pages.CmcbTransactions,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.shb_request_history',
          url: PATH.ShbRequestHistory,
          Pages: Pages.ShbRequestHistory,
          permissionType: ActionType.READ,
        },
        {
          name: 'ABA Transactions',
          url: PATH.AbaTransaction,
          Pages: Pages.AbaTransaction,
          permissionType: ActionType.READ,
        },
        {
          name: 'Adyen Transactions',
          url: PATH.AdyenTransaction,
          Pages: Pages.AdyenTransaction,
          permissionType: ActionType.READ,
        },
        {
          name: 'Wing Transactions',
          url: PATH.WingTransaction,
          Pages: Pages.WingTransaction,
          permissionType: ActionType.READ,
        },
        {
          name: 'menu.payment_transaction',
          url: PATH.PaymentTransaction,
          Pages: Pages.PaymentTransaction,
        },
      ],
    },
    {
      name: 'Withdraw Request',
      url: '/TADA_WITH_DRAW',
      isOpenMenu: true,
      children: [
        {
          name: 'Withdraw Request List',
          url: PATH.Withdraw,
          Pages: Pages.Withdraw,
        },
        {
          name: 'Recover Deleted Account',
          url: PATH.RecoverDeletedAccount,
          Pages: Pages.RecoverDeletedAccount,
        },
      ],
    },
    {
      name: 'menu.drivers',
      url: '/DRIVERS',
      isOpenMenu: true,
      children: [
        {
          name: 'Full Driver List',
          url: PATH.Drivers,
          Pages: Pages.Drivers,
          permissionType: ActionType.LIST,
        },
        {
          name: 'Driver List',
          url: `${PATH.Drivers}/restrict`,
          Pages: Pages.Drivers,
        },
        {
          name: 'menu.driver_dispatch_status',
          url: `${PATH.Drivers}/dispatch-status`,
          Pages: Pages.Drivers,
        },
        {
          name: 'Full Driver Application List',
          url: PATH.DriverApplications,
          Pages: Pages.DriverApplications,
          permissionType: ActionType.LIST,
        },
        {
          name: 'Driver Applications List',
          url: `${PATH.DriverApplications}/restrict`,
          Pages: Pages.DriverApplications,
        },
        {
          name: 'menu.driver_pinglogs',
          url: `${PATH.Drivers}/pingLogs`,
          Pages: Pages.Drivers,
        },
        {
          name: 'menu.cars',
          url: PATH.Cars,
          Pages: Pages.Cars,
        },
        {
          name: 'menu.profile_picture_change',
          url: PATH.ProfilePictureChange,
          Pages: Pages.ProfilePictureChange,
        },
        {
          name: 'Driver Group',
          url: PATH.DriverGroup,
          Pages: Pages.DriverGroup,
        },
        {
          name: 'Driver Incentive',
          url: PATH.DriverIncentive,
          Pages: Pages.DriverIncentive,
        },
        {
          name: 'Tax Prefill Description',
          url: PATH.TaxPrefillDescription,
          Pages: Pages.TaxPrefillDescription,
        },
      ],
    },
    {
      name: 'menu.promotion_and_coupon',
      url: '/PROMOTION',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.promotion',
          url: PATH.Promotion,
          Pages: Pages.Promotion,
        },
        {
          name: 'menu.coupon',
          url: PATH.Coupon,
          Pages: Pages.Coupon,
        },
        {
          name: 'menu.prefix_coupon',
          url: PATH.PrefixCoupon,
          Pages: Pages.PrefixCoupon,
        },
        {
          name: 'menu.referral',
          url: PATH.Referral,
          Pages: Pages.Referral,
        },
      ],
    },
    {
      name: 'menu.rider',
      url: '/RIDER',
      isOpenMenu: true,
      children: [
        {
          name: 'Full Rider List',
          Pages: Pages.Rider,
          url: PATH.Rider,
          permissionType: ActionType.LIST,
        },
        {
          name: 'Rider List',
          Pages: Pages.Rider,
          url: `${PATH.Rider}/restrict`,
        },
        {
          name: 'Rider Mission',
          Pages: Pages.RiderMission,
          url: PATH.RiderMission,
        },
      ],
    },
    {
      name: 'Card',
      url: '/CARD',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.credit_card',
          url: PATH.CreditCard,
          Pages: Pages.CreditCard,
        },
        {
          name: 'Fraud Card',
          url: PATH.FraudCard,
          Pages: Pages.FraudCard,
        },
        {
          name: 'Fraud Log',
          url: PATH.FraudLog,
          Pages: Pages.FraudLog,
        },
        {
          name: 'Aof Account',
          url: PATH.AofAccount,
          Pages: Pages.AofAccount,
        },
        {
          name: 'BIN Management',
          url: PATH.BINManagement,
          Pages: Pages.BINManagement,
        },
      ],
    },
    {
      name: 'menu.delivery',
      url: '/DELIVERIES',
      isOpenMenu: true,
      children: [
        {
          name: 'Delivery Dashboard',
          Pages: Pages.DeliveryDashboard,
          url: PATH.DeliveryDashboard,
        },
        {
          name: 'menu.delivery_drivers',
          component: DeliveryDriver,
          Pages: Pages.DeliveryDriver,
          url: PATH.DeliveryDriver,
        },
        {
          name: 'menu.warehouse',
          Pages: Pages.Warehouse,
          url: PATH.Warehouse,
        },
        {
          name: 'menu.merchant',
          Pages: Pages.Merchant,
          url: PATH.Merchant,
        },
        {
          name: 'menu.delivery',
          Pages: Pages.Delivery,
          url: PATH.Delivery,
        },
        {
          name: 'menu.delivery_ride',
          Pages: Pages.DeliveryRide,
          url: PATH.DeliveryRide,
        },
        {
          name: 'menu.delivery_ride_request',
          Pages: Pages.DeliveryRideRequest,
          url: PATH.DeliveryRideRequest,
        },
        {
          name: 'Delivery Export Result',
          url: PATH.DeliveryExportResult,
          Pages: Pages.DeliveryExportResult,
        },
        {
          name: 'menu.routing_result',
          Pages: Pages.RoutingResult,
          url: PATH.RoutingResult,
        },
        {
          name: 'menu.delivery_ride_schedule',
          Pages: Pages.DeliverySchedule,
          url: PATH.DeliverySchedule,
        },
        {
          name: 'menu.delivery_export_schedule',
          Pages: Pages.ExportSchedule,
          url: PATH.ExportSchedule,
        },
        {
          name: 'menu.pending_ride',
          component: PendingRide,
          url: PATH.PendingRide,
          Pages: Pages.PendingRide,
        },
        {
          name: 'menu.sms_template',
          url: PATH.SMSTemplate,
          Pages: Pages.SMSTemplate,
        },
        {
          name: 'Delivery Tag',
          url: PATH.DeliveryTag,
          Pages: Pages.DeliveryTag,
        },
      ],
    },
    {
      name: 'Delivery Options',
      url: '/DELIVERY_RIDE_OPTIONS',
      isOpenMenu: true,
      children: [
        {
          name: 'Delivery Price Options',
          url: PATH.DeliveryPriceOption,
          Pages: Pages.DeliveryPriceOption,
        },
        {
          name: 'menu.delivery_ride_route_option',
          Pages: Pages.DeliveryRideOption,
          url: PATH.DeliveryRideOption,
        },
        {
          name: 'menu.delivery_ride_payment_option',
          url: PATH.DeliveryPaymentOption,
          Pages: Pages.DeliveryPaymentOption,
        },
        {
          name: 'menu.delivery_ride_price_option',
          url: PATH.DeliveryRidePriceOption,
          Pages: Pages.DeliveryRidePriceOption,
        },
        {
          name: 'Delivery Ride Product Option',
          url: PATH.DeliveryRideProductOption,
          Pages: Pages.DeliveryRideProductOption,
        },
      ],
    },
    {
      name: 'menu.hourly_rental_car',
      url: '/TADA_HOURLY_RENTAL_CAR',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.hourly_rental_request',
          Pages: Pages.HourlyRentalRequest,
          url: PATH.HourlyRentalRequest,
        },
        {
          name: 'menu.hourly_rental_car',
          url: PATH.HourlyRentalCar,
          Pages: Pages.HourlyRentalCar,
        },
      ],
    },
    {
      name: 'menu.balance',
      url: '/balance',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.account',
          url: PATH.Account,
          Pages: Pages.Account,
        },
        {
          name: 'menu.bank_info',
          url: PATH.BankInfo,
          Pages: Pages.BankInfo,
        },
        {
          name: 'menu.cashout_requests',
          url: '/cashout_request',
          Pages: Pages.Transaction,
        },
        {
          name: 'menu.transaction',
          url: PATH.Transaction,
          Pages: Pages.Transaction,
        },
        {
          name: 'menu.deposit_transaction',
          url: PATH.DepositTransaction,
          Pages: Pages.DepositTransaction,
        },
        {
          name: 'menu.shb_wallet_list',
          url: PATH.ShbWallet,
          Pages: Pages.ShbWallet,
        },
        {
          name: 'Shb Loan Info',
          url: PATH.ShbLoanInfo,
          Pages: Pages.ShbLoanInfo,
        },
      ],
    },
    {
      name: 'Otp Skip Rule',
      url: '/TADA_OTP_SKIP_RULE',
      isOpenMenu: true,
      children: [
        {
          name: 'Otp Skip Rule',
          url: PATH.OtpSkipRule,
          Pages: Pages.OtpSkipRule,
        },
        {
          name: 'Device Otp Reset',
          url: PATH.DeviceOtpReset,
          component: DeviceOtpReset,
          Pages: Pages.DeviceOtpReset,
        },
        {
          name: 'menu.phone_verification',
          url: PATH.PhoneVerification,
          component: PhoneVerification,
          Pages: Pages.PhoneVerification,
        },
        {
          name: 'Otp Whitelist',
          url: PATH.OtpWhitelist,
          Pages: Pages.OtpWhitelist,
        },
      ],
    },
    {
      name: 'menu.penalty',
      url: '/PENALTY',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.penalty',
          url: PATH.Penalty,
          Pages: Pages.Penalty,
        },
        {
          name: 'PreventMatch',
          url: PATH.PreventMatch,
          Pages: Pages.PreventMatch,
        },
      ],
    },
    {
      name: 'menu.operations',
      url: '/OPERATIONS',
      isOpenMenu: true,
      children: [
        {
          name: 'Remark Log Setting',
          url: PATH.RemarkLogSetting,
          Pages: Pages.RemarkLogSetting,
        },
        {
          name: 'menu.inbox',
          url: PATH.Inbox,
          Pages: Pages.Inbox,
        },
        {
          name: 'menu.banner',
          url: PATH.Banner,
          Pages: Pages.Banner,
        },
        {
          name: 'App Version',
          url: PATH.AppVersion,
          Pages: Pages.AppVersion,
        },
        {
          name: 'News',
          url: PATH.News,
          Pages: Pages.News,
        },
        {
          name: 'menu.driver_tutorial',
          url: PATH.DriverTutorial,
          Pages: Pages.DriverTutorial,
        },
        {
          name: 'Vehicle Maker & Model',
          url: PATH.VehicleMaker,
          Pages: Pages.VehicleMaker,
        },
        {
          name: 'CPF Contribution Table',
          url: PATH.CpfContributionTable,
          Pages: Pages.CpfContributionTable,
        },
      ],
    },
    {
      name: 'Content Management',
      url: '/TADA_CONTENT_MANAGEMENT',
      isOpenMenu: true,
      children: [
        {
          name: 'FAQ Topic Management',
          url: PATH.FaqTopicManagement,
          Pages: Pages.FaqTopicManagement,
        },
        {
          name: 'FAQ List Management',
          url: PATH.FaqListManagement,
          Pages: Pages.FaqListManagement,
        },
        {
          name: 'Safety & Accident Topic Management',
          url: PATH.SafetyAccidentTopicManagement,
          Pages: Pages.SafetyAccidentTopicManagement,
        },
        {
          name: 'Safety & Accident List Management',
          url: PATH.SafetyAccidentListManagement,
          Pages: Pages.SafetyAccidentListManagement,
        },
        {
          name: 'Help Center Topic Management',
          url: PATH.HelpCenterTopicManagement,
          Pages: Pages.HelpCenterTopicManagement,
        },
        {
          name: 'Help Center List Management',
          url: PATH.HelpCenterListManagement,
          Pages: Pages.HelpCenterListManagement,
        },
        {
          name: 'Support Page Management',
          url: PATH.SupportPageManagement,
          Pages: Pages.SupportPageManagement,
        },
        {
          name: 'Document Guide Management',
          url: PATH.DocumentGuideManagement,
          Pages: Pages.DocumentGuideManagement,
        },
      ],
    },
    {
      name: 'menu.products',
      url: '/PRODUCTS',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.ride_products',
          url: PATH.RideProducts,
          Pages: Pages.RideProducts,
        },
        {
          name: 'Ride Product Info Template',
          url: PATH.RideProductInfoTemplate,
          Pages: Pages.RideProductInfoTemplate,
        },
        {
          name: 'menu.dispatch_config',
          url: PATH.DispatchConfig,
          Pages: Pages.DispatchConfig,
        },
      ],
    },
    {
      name: 'menu.geo',
      url: '/GEO',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.cities',
          url: PATH.Cities,
          Pages: Pages.Cities,
        },
        {
          name: 'menu.areas',
          url: PATH.Areas,
          Pages: Pages.Areas,
        },
        {
          name: 'menu.zones',
          url: PATH.Zones,
          Pages: Pages.Zones,
        },
        {
          name: 'menu.polygons',
          url: PATH.Polygons,
          Pages: Pages.Polygons,
        },
        {
          name: 'menu.airports',
          url: PATH.Airports,
          Pages: Pages.Airports,
        },
        {
          name: 'menu.regions',
          url: PATH.Regions,
          Pages: Pages.Regions,
        },
        {
          name: 'menu.restricted_areas',
          url: PATH.RestrictedAreas,
          Pages: Pages.RestrictedAreas,
        },
      ],
    },
    {
      name: 'menu.surges',
      url: '/SURGES',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.dynamic_surge',
          url: PATH.DynamicSurge,
          Pages: Pages.DynamicSurge,
        },
        {
          name: 'menu.dynamic_hot_zone',
          url: `${PATH.DynamicSurge}/hotzone`,
          Pages: Pages.DynamicSurge,
        },
        {
          name: 'menu.dynamic_surge_monitor',
          url: PATH.DynamicSurgeMonitor,
          Pages: Pages.DynamicSurgeMonitor,
        },
        {
          name: 'Dynamic Surge Setting',
          url: PATH.DynamicSurgeSetting,
          Pages: Pages.DynamicSurgeSetting,
        },
        {
          name: 'menu.instant_surge_rule',
          url: PATH.InstantSurgeRule,
          Pages: Pages.InstantSurgeRule,
        },
        {
          name: 'menu.additional_price_timetable',
          url: PATH.AdditionalPriceRules,
          Pages: Pages.AdditionalPriceRules,
        },
        {
          name: 'menu.additional_price_rules',
          url: PATH.NewAdditionalPriceRules,
          Pages: Pages.NewAdditionalPriceRules,
        },
        {
          name: 'menu.price_verification',
          url: PATH.PriceVerification,
          Pages: Pages.PriceVerification,
        },
        {
          name: 'Schedule Surge Preset',
          url: PATH.ScheduleSurgePreset,
          Pages: Pages.ScheduleSurgePreset,
        },
        {
          name: 'Surge Cap',
          url: PATH.SurgeCap,
          Pages: Pages.SurgeCap,
        },
      ],
    },
    {
      name: 'menu.admin_and_role_setting',
      url: '/ADMIN-ROLE-SETTING',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.admin_setting',
          url: PATH.Admin,
          Pages: Pages.Admin,
          permissionType: ActionType.WRITE,
        },
        {
          name: 'menu.role_setting',
          url: PATH.Roles,
          Pages: Pages.Roles,
          permissionType: ActionType.WRITE,
        },
        {
          name: 'menu.admin_log',
          url: PATH.AdminLog,
          Pages: Pages.AdminLog,
          permissionType: ActionType.READ,
        },
      ],
    },
    {
      name: 'menu.corporate',
      url: '/CORPORATES',
      isOpenMenu: true,
      children: [
        {
          name: 'menu.corporates',
          url: PATH.Corporate,
          Pages: Pages.Corporate,
        },
        {
          name: 'menu.corporate_manager',
          url: PATH.CorporateManager,
          Pages: Pages.CorporateManager,
        },
        {
          name: 'menu.corporate_invoice',
          url: PATH.CorporateInvoice,
          Pages: Pages.CorporateInvoice,
        },
      ],
    },
    {
      name: 'Maintenance',
      url: '/MAINTENANCE',
      isOpenMenu: true,
      children: [
        {
          name: 'Bank maintenance',
          url: PATH.BankMaintenance,
          Pages: Pages.BankMaintenance,
        },
        {
          name: 'TADA maintenance',
          url: PATH.TadaMaintenance,
          Pages: Pages.TadaMaintenance,
        },
      ],
    },
  ],
};

export const allNavItems = nav.items.reduce((data: any[], item) => {
  if (item.children !== undefined) {
    data = [...data, item, ...item.children];
  } else {
    data = [...data, item];
  }

  return data;
}, []);

export default nav;

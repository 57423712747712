import { Pages } from '@/assets/constants/permission';

export const PATH = {
  [Pages.Cars]: '/cars',
  [Pages.Drivers]: '/drivers',
  [Pages.Airports]: '/airports',
  [Pages.Cities]: '/cities',
  [Pages.Polygons]: '/polygons',
  [Pages.Zones]: '/zones',
  [Pages.Regions]: '/regions',
  [Pages.Areas]: '/areas',
  [Pages.DriverApplications]: '/driver-applications',
  [Pages.RestrictedAreas]: '/restricted-areas',
  [Pages.Dashboard]: '/dashboard',
  [Pages.PriceVerification]: '/price-verification',
  [Pages.Reviews]: '/review',
  [Pages.InstantSurgeRule]: '/instant-surge-rule',
  [Pages.AdditionalPriceRules]: '/additional-prices-view',
  [Pages.NewAdditionalPriceRules]: '/additional-prices',
  [Pages.DispatchConfig]: '/dispatch-config',
  [Pages.ProfilePictureChange]: '/profile-pictures',
  [Pages.DynamicSurge]: '/dynamic-surge',
  [Pages.DynamicSurgeMonitor]: '/dynamic-surge-monitor',
  [Pages.AlipayTransactions]: '/alipay-transactions',
  [Pages.PaoTransactions]: '/pao-transactions',
  [Pages.Ride]: '/ride',
  [Pages.RideProducts]: '/ride-products',
  [Pages.MomopayTransactions]: '/momopay-transactions',
  [Pages.PipayTransactions]: '/pipay-transactions',
  [Pages.Inbox]: '/inbox',
  [Pages.Transaction]: '/transaction',
  [Pages.CmcbTransactions]: '/cmcb-transactions',
  [Pages.ShbRequestHistory]: '/shb-request-history',
  [Pages.ShbWallet]: '/shb-wallet',
  [Pages.HourlyRentalRequest]: '/hourly-rental-request',
  [Pages.HourlyRentalCar]: '/hourly-rental-car',
  [Pages.PhoneVerification]: '/phone-verification',
  [Pages.Banner]: '/banner',
  [Pages.Delivery]: '/delivery',
  [Pages.DeliveryRide]: '/delivery-ride',
  [Pages.Merchant]: '/merchant',
  [Pages.Warehouse]: '/warehouse',
  [Pages.DeliveryDriver]: '/delivery-driver',
  [Pages.RoutingResult]: '/routing-result',
  [Pages.DepositTransaction]: '/deposit-transaction',
  [Pages.Penalty]: '/penalty',
  [Pages.DeliverySchedule]: '/delivery-schedule',
  [Pages.Account]: '/account',
  [Pages.DeliveryRideOption]: '/delivery-ride-route-option',
  [Pages.PendingRide]: '/pending-ride',
  [Pages.Rider]: '/rider',
  [Pages.DeliveryRideRequest]: '/delivery-ride-request',
  [Pages.Promotion]: '/promotion',
  [Pages.Coupon]: '/coupon',
  [Pages.Admin]: '/admin',
  [Pages.AdminLog]: '/admin-log',
  [Pages.SMSTemplate]: '/sms-template',
  [Pages.Roles]: '/roles',
  [Pages.Corporate]: '/corporate',
  [Pages.CorporateManager]: '/corporate-manager',
  [Pages.CorporateInvoice]: '/corporate-invoice',
  [Pages.PaymentTransaction]: '/payment-transaction',
  [Pages.OtpSkipRule]: '/otp-skip-rule',
  [Pages.CreditCard]: '/credit-cards',
  [Pages.DeliveryPaymentOption]: '/delivery-ride-payment-option',
  [Pages.DeliveryRidePriceOption]: '/delivery-ride-price-option',
  [Pages.DriverTutorial]: '/driver-tutorial',
  [Pages.AppVersion]: '/app-version',
  [Pages.DeliveryDashboard]: '/delivery/dashboard',
  [Pages.ExportSchedule]: '/delivery-export-schedule',
  [Pages.DeliveryExportResult]: '/delivery-export-result',
  [Pages.FraudCard]: '/fraud-card',
  [Pages.FraudLog]: '/fraud-log',
  [Pages.DeliveryRideProductOption]: '/delivery-ride-product-option',
  [Pages.Place]: '/place',
  [Pages.DeliveryPriceOption]: '/delivery-price-option',
  [Pages.RouteTest]: '/test/route',
  [Pages.DeliveryTag]: '/delivery-tag',
  [Pages.ReservationRide]: '/reservation/ride',
  [Pages.DriverGroup]: '/driver-group',
  [Pages.Withdraw]: '/withdraw',
  [Pages.RecoverDeletedAccount]: '/recover-deleted-account',
  [Pages.DriverIncentive]: '/driver-incentive',
  [Pages.BankMaintenance]: '/bank-maintenance',
  [Pages.PrefixCoupon]: '/prefix-coupon',
  [Pages.TadaMaintenance]: '/tada-maintenance',
  [Pages.BankInfo]: '/bank-info',
  [Pages.FaqListManagement]: '/faq-list-management',
  [Pages.FaqTopicManagement]: '/faq-topic-management',
  [Pages.Referral]: '/referral',
  [Pages.ShbLoanInfo]: '/shb-loan-info',
  [Pages.AbaTransaction]: '/aba-transactions',
  [Pages.DocumentGuideManagement]: '/document-guide-management',
  [Pages.ScheduleSurgePreset]: '/schedule-surge-preset',
  [Pages.HelpCenterListManagement]: '/help-center-list-management',
  [Pages.HelpCenterTopicManagement]: '/help-center-topic-management',
  [Pages.PlaceTester]: '/place-tester',
  [Pages.AdyenTransaction]: '/adyen-transaction',
  [Pages.WingTransaction]: '/wing-transaction',
  [Pages.News]: '/news',
  [Pages.AofAccount]: '/aof-account',
  [Pages.DeviceOtpReset]: '/device-otp-reset',
  [Pages.RideProductInfoTemplate]: '/ride-product-info-template',
  [Pages.TaxPrefillDescription]: '/tax-prefill-description',
  [Pages.GooglePlace]: '/google-place',
  [Pages.SurgeCap]: '/surge-cap',
  [Pages.SafetyAccidentListManagement]: '/safety-accident-list-management',
  [Pages.SafetyAccidentTopicManagement]: '/safety-accident-topic-management',
  [Pages.UserSubmission]: '/user-submission',
  [Pages.PreventMatch]: '/prevent-match',
  [Pages.TollGantry]: '/toll-gantry',
  [Pages.TollTimeTable]: '/toll-time-table',
  [Pages.TollFreeDate]: '/toll-free-date',
  [Pages.SupportPageManagement]: '/support-page-management',
  [Pages.RiderMission]: '/rider-mission',
  [Pages.RemarkLogSetting]: '/remark-log-setting',
  [Pages.VehicleMaker]: '/vehicle-maker',
  [Pages.BINManagement]: '/bin-management',
  [Pages.CpfContributionTable]: '/cpf-contribution-table',
  [Pages.OtpWhitelist]: '/otp-white-list',
  [Pages.DynamicSurgeSetting]: '/dynamic-surge-setting',
} as const;

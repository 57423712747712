import 'formdata-polyfill';
import FileSaver from 'file-saver';
import geojson2h3 from 'geojson2h3/dist/src/geojson2h3';
import _ from 'lodash';
import moment, { MomentInput } from 'moment';
import { schema } from 'normalizr';
import qs from 'qs';

import {
  ADMIN_TYPE,
  ADMIN_TYPES,
  ALL_PRODUCT_TYPES,
  DEFAULT_VALUE,
  Pages,
  REGION,
  REGION_STRINGS,
  TADA_RESOURCE_PAGES,
  TADA_RESOURCES,
  CURRENCIES_FROM_REGION,
  MINIMUM_DESKTOP_WIDTH,
} from '@/assets/constants';
import tadaLogo from '@/assets/logo/tada.png';
import regex from '@/assets/regex';
import { defaultObject } from '@/pages/Drivers/Form';

export function getGeoJsonToHexagon(geoJson: any, h3Resolution: any) {
  return geojson2h3.featureToH3Set(geoJson, h3Resolution);
}

export function ObjectMap<S extends Record<string, any>>(Obj: S, callback: (value: any) => any) {
  return Object.keys(Obj).reduce<{ [key in keyof S]: any }>(
    (result, key: keyof S) => {
      result[key] = callback(Obj[key]);

      return result;
    },
    {} as { [key in keyof S]: any }
  );
}

export function shortenStr(origin: string, length: number = 20, suffix: boolean = true) {
  const shortened = origin.substr(0, length);
  return shortened + (suffix && origin.length > length ? '..' : '');
}

export function shortenUuidStr(uuid: string) {
  return _.head(_.split(uuid, '-'));
}

export const country2Region = (regionCode: string, defaultValue: number | null = 1): number | null => {
  switch (regionCode) {
    case 'SG':
      return 1;
    case 'VN':
      return 2;
    case 'KH':
      return 3;
    case 'KR':
      return 4;
    case 'ET':
      return 5;
    default:
      return defaultValue;
  }
};

export const urlRegex = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/);
export const uuidRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

export function saveFile(response: any, defaultFileName: string = 'excelData.xlsx') {
  const contentDisposition =
    _.get(response.headers, 'content-disposition') || _.get(response.headers, 'Content-Disposition', `filename=${defaultFileName}`);

  const filename = contentDisposition.match(/.*filename="?([^;"]+)"?.*/)[1];

  if (_.get(response, 'data.size', 0) <= 0) {
    throw new Error('Invalid File.');
  }

  FileSaver.saveAs(response.data, decodeURI(filename));
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }
  return emailRegex.test(email);
};

const passwordRegex = /^[ -~]+$/;
export const validatePassword = (password: string) => {
  if (!password || password.length < 6) {
    return false;
  }
  return passwordRegex.test(password);
};

export function parseStringToInt(value?: string) {
  if (value === undefined || value === '' || isNaN(+value)) {
    return undefined;
  }

  return +value;
}

export function validImageUrl(url?: string) {
  try {
    new URL(url || DEFAULT_VALUE.STRING);
    return true;
  } catch (e) {
    return false;
  }
}

export function setMultiSelectBoxValue(data: any, dataString?: any) {
  return data.map((value: any) => {
    return { value, label: dataString ? dataString[value] : value };
  });
}

export function isValidEmail(email: string) {
  const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  return emailRegex.test(email);
}

interface amount {
  exists: boolean;
  khrAmount: number;
  sgdAmount: number;
  usdAmount: number;
  vndAmount: number;
}

interface USDExchangeRate {
  KHR: number;
  SGD: number;
  VND: number;
}

const sumAmount = (amt: amount, exchangeRate: USDExchangeRate) => {
  return amt.khrAmount / exchangeRate.KHR + amt.sgdAmount / exchangeRate.SGD + amt.vndAmount / exchangeRate.VND + amt.usdAmount;
};

interface CheckTransactionAmountInterface {
  balanceAmount: amount;
  liabilityAmount: amount;
  amount: number;
  currency: string;
  exchangeRate: {
    USD: USDExchangeRate;
  };
}

export function checkTransactionAmount({ balanceAmount, liabilityAmount, amount, currency, exchangeRate: { USD } }: CheckTransactionAmountInterface) {
  const balance = sumAmount(balanceAmount, USD);
  const liability = sumAmount(liabilityAmount, USD);
  const translationAmount = USD[currency as keyof USDExchangeRate] ? amount / USD[currency as keyof USDExchangeRate] : amount;

  if (balance - liability + translationAmount >= 20) {
    return window.confirm('Are you sure to make transactions?\nThe users’s balance will be over $20');
  }

  return true;
}

/** param type 값과 현재 ADMIN_TYPE 값이 동일한지 확인 */
export function checkAdminType(type: string) {
  return type === ADMIN_TYPE;
}

export const isTada = checkAdminType(ADMIN_TYPES.TADA);

export function getProductTypesByPlatform() {
  switch (ADMIN_TYPE) {
    case ADMIN_TYPES.TADA:
      return ALL_PRODUCT_TYPES;
    default:
      return [];
  }
}

export function getResource() {
  switch (ADMIN_TYPE) {
    case ADMIN_TYPES.TADA:
      return TADA_RESOURCES;
    default:
      return [];
  }
}

export function getResourcePages(): { [key: string]: Pages[] } {
  switch (ADMIN_TYPE) {
    case ADMIN_TYPES.TADA:
      return TADA_RESOURCE_PAGES;
    default:
      return {};
  }
}

export function getLogo() {
  switch (ADMIN_TYPE) {
    case ADMIN_TYPES.TADA:
      return tadaLogo;
    default:
      return '';
  }
}

export const getFaviconByType = () => {
  switch (ADMIN_TYPE) {
    default:
      return '/favicon.svg';
  }
};

export const debounce = () => {
  let timeout = 0;

  return (handler: TimerHandler, num: number) => {
    clearTimeout(timeout);

    timeout = setTimeout(handler, num);
  };
};

// List.js파일 ComponentWillMount에서 호출
export function fetchListInitialQuery(history: any, search: any, fetchListFn: (query: any) => void) {
  if (search.page === undefined) {
    search.page = 1;
    history.push({ search: qs.stringify(search) });
  } else {
    fetchListFn(search);
  }
}

export function getDefaultCenterOfRegion(region?: REGION) {
  switch (region) {
    case REGION.SG:
      return { lat: 1.332416, lng: 103.8903482 };
    case REGION.VN:
      return { lat: 10.7963254, lng: 106.7314253 };
    case REGION.KH:
      return { lat: 11.5639796, lng: 104.9304278 };
    case REGION.HK:
      return { lat: 22.3226, lng: 114.175802 };
    default:
      return { lat: 1.332416, lng: 103.8903482 };
  }
}

export function getTimeZoneOfRegion(regionCode: REGION) {
  switch (regionCode) {
    case REGION.SG:
    case REGION.HK:
      return 8;
    case REGION.VN:
    case REGION.KH:
    case REGION.ID:
    case REGION.TH:
      return 7;
    case REGION.KR:
      return 9;
    case REGION.ET:
      return 3;
    default:
      return 8;
  }
}

export const getRegionString = (regionCode: REGION | string) => REGION_STRINGS[regionCode as REGION];

export const formatDateWithRegion = ({
  date,
  region,
  format = 'YYYY-MM-DDTHH:mm:ssZ',
  keepLocalTime = true,
}: {
  date: MomentInput;
  region: REGION | string;
  format?: string;
  keepLocalTime?: boolean;
}) =>
  moment(date)
    .utcOffset(getTimeZoneOfRegion(region as REGION), keepLocalTime)
    .format(format);

export function createSchema(entityKey: string | undefined = 'content', pagedModelKey: string | undefined = 'content') {
  const entity = new schema.Entity(entityKey);
  const arrayOfModels = [entity];
  const pagedModels = {
    [pagedModelKey]: arrayOfModels,
  };

  return {
    entity,
    arrayOfModels,
    pagedModels,
  };
}

export enum QR_CODE_TYPE {
  ID = 'ID',
  DR_ID = 'DR_ID',
}

export function parseQRCode(id: string) {
  if (regex.uuidOnly.test(id)) {
    return {
      id,
      type: QR_CODE_TYPE.ID,
    };
  }

  const isDeliveryRequestQR = regex.deliveryRequestQR.exec(id);

  if (isDeliveryRequestQR) {
    return {
      id: isDeliveryRequestQR[1],
      type: QR_CODE_TYPE.DR_ID,
    };
  }

  const isDeliveryQR = regex.deliveryQR.exec(id);

  if (isDeliveryQR) {
    return {
      id: isDeliveryQR[1],
      type: QR_CODE_TYPE.ID,
    };
  }

  return { id };
}

export const checkValidTimestamp = (t: MomentInput) => moment(t, 'x', true).isValid();

export const convertURLtoFile = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();

  const ext = url.split('.').pop();
  const filename = url.split('/').pop() as string;

  const metadata = { type: `image/${ext}` };

  return new File([data], filename, metadata);
};

export function getRandomUuid() {
  // UUID v4 generator in JavaScript (RFC4122 compliant)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 3) | 8;
    return v.toString(16);
  });
}

export const testImageURI = (src: string) =>
  new Promise<boolean>((res) => {
    const image = new Image();

    image.onload = () => {
      res(true);
    };
    image.onerror = () => {
      res(false);
    };

    image.src = src;
  });

export const removeDuplicate = (arr: any[]) => Array.from(new Set(arr));

export const parseToFormData = ({
  data,
  isImageFile = true,
  allowEmptyKeys = [],
  nullCheck = false,
}: {
  data: any;
  isImageFile?: boolean;
  allowEmptyKeys?: string[];
  nullCheck?: boolean;
}) => {
  const fd = new FormData(); // eslint-disable-line
  _.forEach(data, (v, k) => {
    if (((v ?? null) === null || v === '' || v === 'null' || (_.isArray(v) && v.length === 0)) && !allowEmptyKeys.includes(k)) {
      return;
    }
    if (_.isArray(v)) {
      if (!isImageFile) {
        if (typeof v[0] === 'object') {
          fd.append(`${k}`, JSON.stringify(v));

          return;
        }

        _.forEach(v, (data) => {
          fd.append(`${k}`, data);
        });

        return;
      }

      _.forEach(v, (data) => {
        if (data !== -1 && !_.isString(data)) {
          const [defaultKey] = k.split('ImageFile');
          if (nullCheck) {
            if (data) {
              fd.append(`${k}`, data);
              fd.append(`${defaultKey}ImageBody`, 'newfile');
            }
          } else {
            fd.append(`${k}`, data);
            fd.append(`${defaultKey}ImageBody`, 'newfile');
          }
        } else {
          fd.append(`${k}`, data);
        }
      });
    } else {
      if (k.includes('ImageFile')) {
        const [defaultKey] = k.split('ImageFile');
        fd.append(k, v);
        fd.append(`${defaultKey}ImageBody`, 'newfile');
      } else if (k.includes('ImageBody')) {
        if (v !== null) {
          fd.append(k, v);
        }
      } else {
        fd.append(k, v);
      }
    }
  });

  return fd;
};

export const getImageFilesOnly = (data: Record<string, unknown>) => {
  return Object.keys(data)
    .map((key) => {
      if (!key.includes('ImageFile')) {
        return;
      }

      return { key, value: data[key] };
    })
    .filter((file) => file && file.value);
};

export const sanitizeNewImageFile = (form: defaultObject) => {
  const clone = { ...form };

  Object.keys(form).forEach((key) => {
    if (key.includes('ImageFile') && form[key]) {
      if (Array.isArray(form[key])) {
        form[key].forEach((formValue?: File) => {
          if (formValue) {
            const [imageKey] = key.split('ImageFile');

            clone[`${imageKey}ImageBody`].push('newfile');
            delete clone[`${imageKey}ImageFile`];
          }
        });
      } else {
        const [imageKey] = key.split('ImageFile');

        clone[`${imageKey}ImageBody`] = 'newfile';
        delete clone[`${imageKey}ImageFile`];
      }
    }
  });

  return clone;
};

export const getRequestPartData = (form: defaultObject) => {
  const data = new FormData();

  const json = JSON.stringify(sanitizeNewImageFile(form));
  const blob = createBlobJson(json);
  const files = getImageFilesOnly(form);

  files?.length &&
    files.forEach((file) => {
      if (file) {
        if (Array.isArray(file.value)) {
          file.value.forEach((fileValue) => {
            if (fileValue) {
              data.append(file.key, fileValue);
            }
          });
        } else {
          data.append(file.key, file.value as File);
        }
      }
    });

  data.append('body', blob);

  return data;
};

export const queryToMultiSelectValue = <Value extends string | number>({
  query,
  stringMap,
}: {
  query?: Value[] | Value;
  stringMap: Record<Value, string>;
}) => {
  if (query === undefined) {
    return query;
  }

  const queryArr = Array.isArray(query) ? query : [query];

  return queryArr.map((value) => ({
    value,
    label: stringMap[value],
  }));
};

export const createBlobJson = (json: string) =>
  new Blob([json], {
    type: 'application/json',
  });

export const getCurrencyByRegion = (region: REGION | string) => CURRENCIES_FROM_REGION[region][0];

export const getWindowDimensions = () => {
  const { innerWidth, innerHeight } = window;

  return {
    innerWidth,
    innerHeight,
  };
};

export const getIsMobileView = () => {
  const { innerWidth } = getWindowDimensions();
  const isMobile = innerWidth < MINIMUM_DESKTOP_WIDTH;

  return isMobile;
};

export const trimAllStringValues = async <T>(data: T): Promise<T | string | object> => {
  if (typeof data === 'string') {
    return data.trim();
  } else if (Array.isArray(data)) {
    const trimmedArray = [];

    for (let i = 0; i < data.length; i++) {
      const value = data[i];
      const trimmed = await trimAllStringValues(value);
      trimmedArray.push(trimmed);
    }

    return trimmedArray;
  } else if (data instanceof FormData) {
    const trimmedFormData = new FormData();

    // @ts-ignore
    for (const [key, value] of data.entries()) {
      if (typeof value === 'string') {
        trimmedFormData.append(key, value.trim());
      } else if (value instanceof Blob && value.type === 'application/json') {
        const dto = await value.text();
        const trimmedDto = await trimAllStringValues(JSON.parse(dto));
        const json = JSON.stringify(trimmedDto);
        const blob = createBlobJson(json);

        trimmedFormData.append(key, blob);
      } else {
        trimmedFormData.append(key, value);
      }
    }

    return trimmedFormData;
  } else if (data !== null && typeof data === 'object') {
    const trimmedObj = {};
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        const trimmed = await trimAllStringValues(data[key]);
        (trimmedObj as T)[key] = trimmed as T[Extract<keyof T, string>];
      }
    }
    return trimmedObj;
  }

  return data;
};

import { createAction } from '@reduxjs/toolkit';

import { DeliveryRideRouteOptionCreateOrUpdateDto } from '@/pages/DeliveryRideRouteOption/type';
import Service from '@/svc/serviceName';

export const createDeliveryRideOptionRequest = createAction<DeliveryRideRouteOptionCreateOrUpdateDto>(
  `${Service.deliverySvc}/deliveryRideRouteOption/createDeliveryRideOptionRequest`
);
export const createDeliveryRideOptionSuccess = createAction(`${Service.deliverySvc}/deliveryRideRouteOption/createDeliveryRideOptionSuccess`);
export const createDeliveryRideOptionFailure = createAction<string>(`${Service.deliverySvc}/deliveryRideRouteOption/createDeliveryRideOptionFailure`);

export const updateDeliveryRideOptionRequest = createAction(
  `${Service.deliverySvc}/deliveryRideRouteOption/updateDeliveryRideOptionRequest`,
  (id: string, data: DeliveryRideRouteOptionCreateOrUpdateDto) => ({
    payload: { id, data },
  })
);
export const updateDeliveryRideOptionSuccess = createAction(`${Service.deliverySvc}/deliveryRideRouteOption/updateDeliveryRideOptionSuccess`);
export const updateDeliveryRideOptionFailure = createAction<string>(`${Service.deliverySvc}/deliveryRideRouteOption/updateDeliveryRideOptionFailure`);

export const deleteDeliveryRideOptionRequest = createAction<string>(`${Service.deliverySvc}/deliveryRideRouteOption/deleteDeliveryRideOptionRequest`);
export const deleteDeliveryRideOptionSuccess = createAction(`${Service.deliverySvc}/deliveryRideRouteOption/deleteDeliveryRideOptionSuccess`);
export const deleteDeliveryRideOptionFailure = createAction<string>(`${Service.deliverySvc}/deliveryRideRouteOption/deleteDeliveryRideOptionFailure`);

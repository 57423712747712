import { useState } from 'react';

import { useActionSubscribe } from '@/store/middleware/actionSubscribe';

export const useRequestFlag = (requestType: string) => {
  const [flag, setFlag] = useState(false);

  const actionKey = requestType.replace(/(.+)Request$/, '$1');

  useActionSubscribe(requestType, () => {
    setFlag(true);
  });

  useActionSubscribe(`${actionKey}Success`, () => {
    setFlag(false);
  });

  useActionSubscribe(`${actionKey}Failure`, () => {
    setFlag(false);
  });

  return flag;
};

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchAirportListRequest,
  fetchAirportListFailure,
  fetchAirportListSuccess,
  fetchAirportDetailFailure,
  fetchAirportDetailRequest,
  fetchAirportDetailSuccess,
  createAirportFailure,
  createAirportRequest,
  createAirportSuccess,
  updateAirportFailure,
  updateAirportRequest,
  updateAirportSuccess,
  deleteAirportFailure,
  deleteAirportRequest,
  deleteAirportSuccess,
} from '@/pages/Airports/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity, schema } = getContentSchema();

function* fetchAirportList({ payload }: ReturnType<typeof fetchAirportListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/airports', { params: payload, schema });

    const action = response.ok ? fetchAirportListSuccess(response.data) : fetchAirportListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchAirportListFailure((e as Error).message));
  }
}

function* fetchAirportDetail({ payload }: ReturnType<typeof fetchAirportDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/airports/${payload}`, { schema: entity });

    const action = response.ok ? fetchAirportDetailSuccess(response.data) : fetchAirportDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchAirportDetailFailure((e as Error).message));
  }
}

function* createAirport({ payload }: ReturnType<typeof createAirportRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/airports', { data: payload });

    const action = response.ok ? createAirportSuccess(response.data) : createAirportFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createAirportFailure((e as Error).message));
  }
}

function* updateAirport({ payload: { id, body } }: ReturnType<typeof updateAirportRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/airports/${id}`, { data: body });

    const action = response.ok ? updateAirportSuccess() : updateAirportFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateAirportFailure((e as Error).message));
  }
}

function* deleteAirport({ payload }: ReturnType<typeof deleteAirportRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/airports/${payload}`);

    const action = response.ok ? deleteAirportSuccess() : deleteAirportFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteAirportFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchAirportListRequest.type, fetchAirportList);
  yield takeLatest(fetchAirportDetailRequest.type, fetchAirportDetail);
  yield takeLatest(createAirportRequest.type, createAirport);
  yield takeLatest(updateAirportRequest.type, updateAirport);
  yield takeLatest(deleteAirportRequest.type, deleteAirport);
}

import { createAction } from '@reduxjs/toolkit';

const prefix = `/admin/cpf`;

export const downloadCpfContributionTableTemplateRequest = createAction(`${prefix}/table/templateRequest`);
export const downloadCpfContributionTableTemplateSuccess = createAction(`${prefix}table/templateSuccess`);
export const downloadCpfContributionTableTemplateFailure = createAction<string>(`${prefix}/table/templateFailure`);

export const createCpfContributionTableRequest = createAction<FormData>(`${prefix}/createTablesRequest`);
export const createCpfContributionTableSuccess = createAction<number>(`${prefix}/createTablesSuccess`);
export const createCpfContributionTableFailure = createAction<string>(`${prefix}/createTablesFailure`);

export const updateCpfContributionTableRequest = createAction<{ id: string; data: FormData }>(`${prefix}/UpdateTablesRequest`);
export const updateCpfContributionTableSuccess = createAction<number>(`${prefix}/UpdateTablesSuccess`);
export const updateCpfContributionTableFailure = createAction<string>(`${prefix}/UpdateTablesFailure`);

export const exportCpfContributionTableRequest = createAction<{ id: string }>(`${prefix}/table/exportRequest`);
export const exportCpfContributionTableSuccess = createAction(`${prefix}/table/exportSuccess`);
export const exportCpfContributionTableFailure = createAction<string>(`${prefix}/table/exportFailure`);

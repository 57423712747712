import Popup from 'reactjs-popup';
import styled from 'styled-components';

import resetIcon from '@/assets/images/icon_reset_sidebar.svg';
import ButtonContainer from '@/components/ButtonContainer';
import { setMenuManagerData } from '@/components/MenuManager/reducer';
import { Button } from '@/components/template';
import nav from '@/layout/Sidebar/_nav';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';
import { changePageStatusRequest } from '@/svc/admin-proxy-svc/reducer';

const ResetButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 8px 0 auto;
    padding: 0;
    border-radius: 16px;
    background-color: #e6e6e6;
    border: 0;

    &:hover {
      background-color: #cfcfcf;
    }

    &:active {
      background-color: #afafaf;
    }

    > img {
      width: 20px;
      height: 20px;
      margin: auto;
    }
  }
`;

const ResetSidebarPopupContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 120px;
  padding: 16px 16px 12px 16px;
  border-radius: 8px;
  background: #fff;
  color: #000;

  > h4 {
    color: #000;
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > p {
    margin: 4px 0 16px 0;
    color: #000;
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  > div > button {
    width: 80px;
  }
`;

function SidebarResetPopup() {
  const { pageStatus } = useAppState((state) => state.auth);

  const handleResetButton = () => {
    dispatch(setMenuManagerData({ pageStatusValues: nav.items }));
    dispatch(changePageStatusRequest({ pageStatus: '' }));
  };

  if (!pageStatus) {
    return null;
  }

  return (
    <Popup
      trigger={
        <ResetButton color='primary'>
          <img src={resetIcon} alt='resetSidebarIcon' />
        </ResetButton>
      }
      contentStyle={{
        width: '210px',
        padding: '0',
        border: '0',
      }}
      modal
      closeOnDocumentClick
      closeOnEscape
    >
      {(close) => (
        <ResetSidebarPopupContainer>
          <h4>Are you sure?</h4>
          <p>The menu list will be reset.</p>
          <ButtonContainer>
            <Button color='dark' onClick={close}>
              No
            </Button>
            <Button
              color='dark'
              onClick={() => {
                handleResetButton();
                close();
              }}
            >
              Reset
            </Button>
          </ButtonContainer>
        </ResetSidebarPopupContainer>
      )}
    </Popup>
  );
}

export default SidebarResetPopup;

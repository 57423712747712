import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "TollFreeDateList" */ '@/pages/TollFreeDate/List'));
const Detail = lazy(() => import(/* webpackChunkName: "TollFreeDateDetail" */ '@/pages/TollFreeDate/Detail'));
const New = lazy(() => import(/* webpackChunkName: "TollFreeDateNew" */ '@/pages/TollFreeDate/New'));
const Edit = lazy(() => import(/* webpackChunkName: "TollFreeDateEdit" */ '@/pages/TollFreeDate/Edit'));

export const tollFreeDateRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.TollFreeDate,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.TollFreeDate}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.TollFreeDate}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.TollFreeDate}/:id`,
    component: Detail,
    exact: true,
  },
];

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import citySaga from '@/svc/ride-svc/cities/saga';
import dynamicSurgeSettingSaga from '@/svc/ride-svc/dynamicSurgeSetting/saga';
import polygonSaga from '@/svc/ride-svc/polygon/saga';
import {
  exportDriverEarningFailure,
  exportDriverEarningRequest,
  exportDriverEarningSuccess,
  modifyRideTimeFailure,
  modifyRideTimeRequest,
  modifyRideTimeSuccess,
  fetchReservationStatsFailure,
  fetchReservationStatsRequest,
  fetchReservationStatsSuccess,
  refreshDistanceRequest,
  refreshDistanceFailure,
  refreshDistanceSuccess,
  retryPayRequest,
  retryPayFailure,
  retryPaySuccess,
  revertChangeOfDestinationRequest,
  revertChangeOfDestinationFailure,
  revertChangeOfDestinationSuccess,
  recalculateReverseTransactionSuccess,
  recalculateReverseTransactionFailure,
  recalculateReverseTransactionRequest,
} from '@/svc/ride-svc/reducer';
import rideProductInfoTemplateSaga from '@/svc/ride-svc/rideProductInfoTemplate/saga';
import scheduleSurgePresetSaga from '@/svc/ride-svc/scheduleSurgePreset/saga';
import surgeCapSaga from '@/svc/ride-svc/surgeCap/saga';
import waitingFeeSaga from '@/svc/ride-svc/waitingFee/saga';
import zoneSaga from '@/svc/ride-svc/zone/saga';
import { authenticatedRequest } from '@/utils/request';

function* exportDriverEarning({ payload: { id, body } }: ReturnType<typeof exportDriverEarningRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/drivers/${id}/monthly_earning`, { data: body });

    if (response.ok) {
      yield put(exportDriverEarningSuccess());
    } else {
      yield put(exportDriverEarningFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportDriverEarningFailure((e as Error).message));
  }
}

function* modifyRideTime({ payload }: ReturnType<typeof modifyRideTimeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/rides/v1/rideTimeModification', { data: payload });

    if (response.ok) {
      yield put(modifyRideTimeSuccess());
    } else {
      yield put(modifyRideTimeFailure(response.data.message));
    }
  } catch (e) {
    yield put(modifyRideTimeFailure((e as Error).message));
  }
}

function* fetchReservationStats({ payload }: ReturnType<typeof fetchReservationStatsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/rides/reservationStats', { params: payload });

    if (response.ok) {
      yield put(fetchReservationStatsSuccess(response.data));
    } else {
      yield put(fetchReservationStatsFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchReservationStatsFailure((e as Error).message));
  }
}

function* refreshDistance({ payload }: ReturnType<typeof refreshDistanceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${payload}/distanceRefresh`);

    const action = response.ok ? refreshDistanceSuccess() : refreshDistanceFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(refreshDistanceFailure((e as Error).message));
  }
}

function* retryPay({ payload }: ReturnType<typeof retryPayRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${payload}/retry_pay`);

    const action = response.ok ? retryPaySuccess() : retryPayFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(retryPayFailure((e as Error).message));
  }
}

function* revertCOD({ payload }: ReturnType<typeof revertChangeOfDestinationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${payload}/revert_cod`);

    const action = response.ok ? revertChangeOfDestinationSuccess() : revertChangeOfDestinationFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(revertChangeOfDestinationFailure((e as Error).message));
  }
}

function* recalculateReverseTransaction({ payload: { id } }: ReturnType<typeof recalculateReverseTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/rides/${id}/cpf/recharge-balance`);

    const action = response.ok ? recalculateReverseTransactionSuccess() : recalculateReverseTransactionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(recalculateReverseTransactionFailure((e as Error).message));
  }
}

export default function* rideSvcSaga() {
  yield takeLatest(exportDriverEarningRequest.type, exportDriverEarning);
  yield takeLatest(modifyRideTimeRequest.type, modifyRideTime);
  yield takeLatest(fetchReservationStatsRequest.type, fetchReservationStats);
  yield takeLatest(refreshDistanceRequest.type, refreshDistance);
  yield takeLatest(retryPayRequest.type, retryPay);
  yield takeLatest(revertChangeOfDestinationRequest.type, revertCOD);
  yield takeLatest(recalculateReverseTransactionRequest.type, recalculateReverseTransaction);
  yield all([
    call(waitingFeeSaga),
    call(scheduleSurgePresetSaga),
    call(citySaga),
    call(rideProductInfoTemplateSaga),
    call(surgeCapSaga),
    call(polygonSaga),
    call(zoneSaga),
    call(dynamicSurgeSettingSaga),
  ]);
}

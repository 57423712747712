import { createSlice } from '@reduxjs/toolkit';

import faqManagementReducer, { faqManagementInitialState } from '@/svc/cs-svc/faqManagement/reducer';
import safetyAccidentManagementReducer, { safetyAccidentManagementInitialState } from '@/svc/cs-svc/safetyAccidentManagement/reducer';
import { CsServiceState } from '@/svc/cs-svc/type';
import Service from '@/svc/serviceName';

const initialState: CsServiceState = {
  faqManagement: faqManagementInitialState,
  safetyAccidentManagement: safetyAccidentManagementInitialState,
};

const csSlice = createSlice({
  name: Service.csSvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.faqManagement, faqManagementReducer(state.faqManagement, action));
      Object.assign(state.safetyAccidentManagement, safetyAccidentManagementReducer(state.safetyAccidentManagement, action));
    }),
});

// export const {} = csSlice.actions;

export default csSlice.reducer;

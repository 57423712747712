import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CmcbTransactionList" */ '@/pages/CmcbTransactions/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CmcbTransactionDetail" */ '@/pages/CmcbTransactions/Detail'));

export const cmcbTransactionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    exact: true,
    path: PATH.CmcbTransactions,
    component: List,
  },
  {
    permission: ActionType.READ,
    exact: true,
    path: `${PATH.CmcbTransactions}/:id`,
    component: Detail,
  },
];

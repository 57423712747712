import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "TollTimeTableList" */ '@/pages/TollTimeTable/List'));
const Detail = lazy(() => import(/* webpackChunkName: "TollTimeTableDetail" */ '@/pages/TollTimeTable/Detail'));
const New = lazy(() => import(/* webpackChunkName: "TollTimeTableNew" */ '@/pages/TollTimeTable/New'));
const Edit = lazy(() => import(/* webpackChunkName: "TollTimeTableEdit" */ '@/pages/TollTimeTable/Edit'));

export const tollTimeTableRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.TollTimeTable,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.TollTimeTable}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.TollTimeTable}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.TollTimeTable}/:id`,
    component: Detail,
    exact: true,
  },
];

import { ReactNode, PropsWithChildren, FunctionComponent } from 'react';

import styled from 'styled-components';

import { Card, CardHeader, CardBody, CardFooter, CardProps, CardHeaderProps, CardFooterProps, CardBodyProps } from '@/components/template';

interface CardTemplateProps {
  showFooter?: boolean;
  headerChildren?: ReactNode | FunctionComponent;
  footerChildren?: ReactNode | FunctionComponent;
  cardProps?: CardProps;
  cardHeaderProps?: CardHeaderProps;
  cardBodyProps?: CardBodyProps;
  cardFooterProps?: CardFooterProps;
}

const Body = styled(CardBody)`
  > * + * {
    margin-top: 15px;
  }
`;

function CardTemplate({
  children,
  headerChildren: HeaderChildren,
  footerChildren: FooterChildren,
  cardProps,
  cardHeaderProps,
  cardBodyProps,
  cardFooterProps,
  showFooter = true,
}: PropsWithChildren<CardTemplateProps>) {
  return (
    <Card {...cardProps}>
      {HeaderChildren && <CardHeader {...cardHeaderProps}>{typeof HeaderChildren === 'function' ? <HeaderChildren /> : HeaderChildren}</CardHeader>}
      <Body {...cardBodyProps}>{children}</Body>
      {showFooter && FooterChildren && (
        <CardFooter {...cardFooterProps}>{typeof FooterChildren === 'function' ? <FooterChildren /> : FooterChildren}</CardFooter>
      )}
    </Card>
  );
}

export default CardTemplate;

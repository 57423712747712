import { DEFAULT_COLUMNS } from '@/components/ColumnSelector/default';
import { TargetKey } from '@/components/ColumnSelector/type';
import { getColumn, getColumnVersion, removeColumn, removeColumnVersion, saveColumn, saveColumnVersion } from '@/utils/localStorage';

const getShowColumnStorage = (): Record<string, string[]> => {
  const item = getColumn();

  try {
    if (item) {
      const data = JSON.parse(item);

      if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
        return data;
      }

      throw new Error('Invalid Storage Data');
    }
  } catch {
    removeColumn();
  }

  return {};
};

export const getShowColumnList = (key: TargetKey): string[] => {
  const item = getShowColumnStorage();

  return item[key] ?? DEFAULT_COLUMNS[key];
};

export const saveShowColumnList = (key: TargetKey, data: string[]) => {
  const item = getShowColumnStorage();

  item[key] = data;

  saveColumn(JSON.stringify(item));
};

export const removeShowColumnList = (key: TargetKey) => {
  const item = getShowColumnStorage();

  delete item[key];

  saveColumn(JSON.stringify(item));
};

const getColumnVersionStorage = (): Record<string, string> => {
  const item = getColumnVersion();

  try {
    if (item) {
      const data = JSON.parse(item);

      if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
        return data;
      }

      throw new Error('Invalid Storage Data');
    }
  } catch {
    removeColumnVersion();
  }

  return {};
};

export const getColumnVersions = (key: TargetKey): string => {
  const item = getColumnVersionStorage();

  return item[key];
};

export const saveColumnVersions = (key: TargetKey, data: string) => {
  const item = getColumnVersionStorage();

  item[key] = data;

  saveColumnVersion(JSON.stringify(item));
};

export const removeColumnVersions = (key: TargetKey) => {
  const item = getColumnVersionStorage();

  delete item[key];

  saveColumnVersion(JSON.stringify(item));
};

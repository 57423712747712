import moment from 'moment';
import validator from 'validator';
import { array, boolean, mixed, number, object, ref, string } from 'yup';

import { regex } from '@tada/tada-web-common';

import {
  DISCOUNT_TYPE_REQUIRED_MAX_AMOUNT,
  DRIVER_TYPE_TAXI,
  NewsContentType,
  NEWS_CONTENT_TYPE,
  PROMO_TYPE_LOYALTY,
  PROMO_VALID_UNTIL_POLICY_FIXED,
  REFERRAL_REWARD_TYPE,
  REGION,
  VALIDATE_DRIVER_TYPE_NUMBERS,
  VALIDATE_CAR_MODEL_DRIVER_TYPE_NUMBERS,
} from '@/assets/constants';
import adminRegex from '@/assets/regex';
import { NormalDispatchInterface, SequenceDispatchInterface } from '@/pages/DispatchConfig/type';

export const dispatchConfigValidationSchema = object().shape({
  name: string().required('Required Field.'),
  type: string().required('Required Field.'),
  dispatchType: string().required('Required Field.'),
  fdDestDistance: number().required('Required Field.'),
  sameRiderRematchTimeLimit: number().required('Required Field.'),
  sameRiderRematchCountLimit: number().nullable(),
  dispatchPriority: number().required('Required Field.'),
  deferredEnableMs: number().required('Required Field.'),
  arPenaltyBelow: number().required('Required Field.'),
  arPenaltyBatch: number().required('Required Field.'),
  arPenaltyDistanceKm: number().required('Required Field.'),
  routeEngine: number().required('Required Field.'),
  sequenceSettings: array().of(
    object().shape(
      {
        resolution: string().required('Required Field.'),
        pingRadiusMeter: number().required('Required Field.'),
        adDistance: number().required('Required Field.'),
        priorityDispatchAdvantage: number().required('Required Field.'),
        roundTimeout: number().required('Required Field.'),
        maxSequence: number().required('Required Field.'),
        maxPingTarget: number().required('Required Field.'),
        minPingTargetPerSeq: number().required('Required Field.'),
        minDistance: number().required('Required Field.'),
        driverActiveTimeLimit: string().required('Required Field.'),
        fromTime: string()
          .nullable()
          // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
          .when(['isDefault', 'pickupH3List'], (isDefault: boolean, pickupH3List: SequenceDispatchInterface['pickupH3List'], schema) => {
            return !isDefault && !pickupH3List?.length ? schema.required('Required Field.') : schema;
          }),
        toTime: string()
          .nullable()
          // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
          .when(['isDefault', 'pickupH3List'], (isDefault: boolean, pickupH3List: SequenceDispatchInterface['pickupH3List'], schema) => {
            return !isDefault && !pickupH3List?.length ? schema.required('Required Field.') : schema;
          }),
        pickupH3List: array()
          .nullable()
          .when(
            ['isDefault', 'toTime', 'fromTime'],
            // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
            (isDefault: boolean, toTime: string | null, fromTime: string | null, schema) => {
              return isDefault || (toTime && fromTime) ? schema : schema.min(1, 'Required Field.');
            }
          ),
      },
      [
        ['toTime', 'pickupH3List'],
        ['fromTime', 'pickupH3List'],
      ]
    )
  ),
  normalSettings: array().of(
    object().shape(
      {
        roundTimeout: number().required('Required Field.'),
        minDistance: number().required('Required Field.'),
        driverActiveTimeLimit: number().required('Required Field.'),
        adDistance: number().required('Required Field.'),
        priorityDispatchAdvantage: number().required('Required Field.'),
        maxPingTarget: number().required('Required Field.'),
        fromTime: string()
          .nullable()
          // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
          .when(['isDefault', 'pickupH3List'], (isDefault: boolean, pickupH3List: NormalDispatchInterface['pickupH3List'], schema) => {
            return !isDefault && !pickupH3List?.length ? schema.required('Required Field.') : schema;
          }),
        toTime: string()
          .nullable()
          // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
          .when(['isDefault', 'pickupH3List'], (isDefault: boolean, pickupH3List: NormalDispatchInterface['pickupH3List'], schema) => {
            return !isDefault && !pickupH3List?.length ? schema.required('Required Field.') : schema;
          }),
        pickupH3List: array()
          .nullable()
          .when(
            ['isDefault', 'toTime', 'fromTime'],
            // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
            (isDefault: boolean, toTime: string | null, fromTime: string | null, schema) => {
              return isDefault || (toTime && fromTime) ? schema : schema.min(1, 'Required Field.');
            }
          ),
        roundConfig: array()
          .of(
            object().shape({
              radius: number().required('Required Field.'),
            })
          )
          .test('emptyArray', 'Required Field.', (value) => value?.length !== 0)
          .required('Required Field.'),
        mddAvailable: boolean().required('Required'),
      },
      [
        ['toTime', 'pickupH3List'],
        ['fromTime', 'pickupH3List'],
      ]
    )
  ),
});

export const referralValidationSchmea = object().shape({
  enabled: boolean().required('Required.'),
  region: string().required('Required.'),
  inviterType: string().required('Required.'),
  inviteeType: string().required('Required.'),
  inviterReward: object().shape({
    rewardType: string().required('Required'),
    withinDays: number().nullable(),
    immediateReward: boolean().required('Required'),
    amount: number().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    currency: string().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    transactionApprovalMode: number().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    promotionCodes: array()
      .of(
        string().when('inviterReward.rewardType', {
          is: REFERRAL_REWARD_TYPE.COUPON,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.nullable().optional(),
        })
      )
      .when('rewardType', {
        is: REFERRAL_REWARD_TYPE.COUPON,
        then: (schema) => schema.min(1, 'Required'),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    limitCount: number()
      .nullable()
      .when('rewardType', {
        is: REFERRAL_REWARD_TYPE.COUPON,
        then: (schema) => schema,
        otherwise: (schema) => schema.optional(),
      }),
    delayApproveSec: number().nullable().optional(),
  }),
  inviteeReward: object().shape({
    rewardType: string().required('Required'),
    withinDays: number().nullable(),
    immediateReward: boolean().required('Required'),
    amount: number().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    currency: string().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    transactionApprovalMode: number().when('rewardType', {
      is: REFERRAL_REWARD_TYPE.BALANCE,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    promotionCodes: array()
      .of(
        string().when('inviteeReward.rewardType', {
          is: REFERRAL_REWARD_TYPE.COUPON,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.nullable().optional(),
        })
      )
      .when('rewardType', {
        is: REFERRAL_REWARD_TYPE.COUPON,
        then: (schema) => schema.min(1, 'Required'),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    limitCount: number()
      .nullable()
      .when('rewardType', {
        is: REFERRAL_REWARD_TYPE.COUPON,
        then: (schema) => schema,
        otherwise: (schema) => schema.optional(),
      }),
    delayApproveSec: number().nullable().optional(),
  }),
  tncs: array()
    .of(
      object().shape({
        description: string().required('Required'),
        locale: string().required('Required'),
      })
    )
    .min(1, 'T&Cs are Required'),
  inviterDescription: array()
    .of(
      object().shape({
        description: string().required('Required'),
        locale: string().required('Required'),
      })
    )
    .min(1, 'Inviter Description is Required'),
  inviteeDescription: array()
    .of(
      object().shape({
        description: string().required('Required'),
        locale: string().required('Required'),
      })
    )
    .when('inviterType', {
      is: 'Rider',
      then: (schema) => schema.min(1, 'Required'),
      otherwise: (schema) => schema.nullable().optional(),
    }),
});

export const documentGuideValidationSchema = object().shape({
  body: object().shape({
    documentDescriptions: array().of(
      object().shape({
        key: string(),
        descriptions: array().of(
          object().shape({
            description: string().required('Required'),
            bulleted: boolean(),
          })
        ),
      })
    ),
    additionalDescriptions: array().of(
      object().shape({
        key: string(),
        additionals: array().of(
          object().shape({
            key: string().required('Required'),
            descriptions: array().of(
              object().shape({
                description: string().required('Required'),
                bulleted: boolean(),
              })
            ),
          })
        ),
      })
    ),
    newSampleImageTargets: array().of(string().required('Required')).nullable(),
  }),
  sampleImages: array().of(string().required('Required')).nullable(),
});

export const promotionCreateSchema = object().shape({
  code: string().matches(adminRegex.alphabetAndNumberAndDash, 'Invalid code.').required('Code is Required.'),
  title: string().required('Title is Required.'),
  amount: number().min(1, 'Invalid Amount').required('Amount is Required.'),
  region: string().required('Invalid Region.'),
  cities: array()
    .of(
      object().shape({
        value: string().required(),
        label: string().required(),
      })
    )
    .min(1, 'City is Required.'),
  carTypes: array()
    .of(
      object().shape({
        value: number().required(),
        label: string().required(),
      })
    )
    .min(1, 'Car Type is Required.'),
  rideTypes: array()
    .of(
      object().shape({
        value: number().required(),
        label: string().required(),
      })
    )
    .min(1, 'Ride Type is Required.'),
  times: array().min(1, 'There is no times.'),
  validFrom: string().required('Valid from is Required.'),
  validUntil: string().required('Valid Until is Required.'),
  couponValidFrom: string().when('validUntilPolicy', {
    is: PROMO_VALID_UNTIL_POLICY_FIXED,
    then: (schema) => schema.required('Coupon Valid from is Required'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  couponValidUntil: string().when('validUntilPolicy', {
    is: PROMO_VALID_UNTIL_POLICY_FIXED,
    then: (schema) => schema.required('Coupon Valid until is Required'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  paymentMethods: array()
    .of(
      object().shape({
        value: string().required(),
        label: string().required(),
      })
    )
    .min(1, 'Payment Methods is Required.'),
  maxAmount: number().when('discountType', (discountType: number, schema) => {
    return DISCOUNT_TYPE_REQUIRED_MAX_AMOUNT.includes(discountType)
      ? schema.min(1, 'Invalid Max amount.').required('Max amount is Required.')
      : schema.nullable().optional();
  }),
  minSpendAmount: number().min(0, 'Invalid Min Spend Amount.').required('Min Speend Amount is Required.'),
  minChargeAmount: number().min(0, 'Invalid Min Charge Amount to Rider.').required('Min Charge Amount to Rider is Required.'),
  validPeriod: number().min(1, 'Invalid Coupon Valid Period.'),
  rideCount: number().when('promoType', {
    is: PROMO_TYPE_LOYALTY,
    then: (schema) => schema.required('Invalid Ride Count.').typeError('Invalid Type of Ride Count.'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  countLimit: number().min(1, 'Invalid Count Limit.'),
  issueCount: number()
    .min(1, 'Invalid IssueCount.')
    //@ts-ignore
    .when('countLimit', (countLimit: number | string, schema) => {
      return schema.test('is-over', 'Issue count cannot exceed count limit.', (value: number | string) => +countLimit >= +value);
    }),
  receiptEmail: string()
    .test('is-email', 'Not a valid email', (value) => (!value ? true : validator.isEmail(value)))
    .optional()
    .nullable(),
  targetType: number().required('There is no Target Type.'),
  description: string().required('Description is Required.'),
  rideTypesForLoyalty: array().when('promoType', {
    is: PROMO_TYPE_LOYALTY,
    then: (schema) =>
      schema
        .of(
          object().shape({
            value: number().required(),
            label: string().required(),
          })
        )
        .min(1, 'Ride Type is Required.'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
});

export const promotionEditSchema = object().shape({
  times: array().min(1, 'There is no Times.'),
  validPeriod: number().min(1, 'Invalid Coupon Valid Period.'),
  rideCount: number().when('promoType', {
    is: PROMO_TYPE_LOYALTY,
    then: (schema) => schema.required('Invalid Ride Count.').typeError('Invalid Type of Ride Count.'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  countLimit: number().min(1, 'Invalid Count Limit.'),
  issueCount: number()
    .min(1, 'Invalid IssueCount.')
    //@ts-ignore
    .when('countLimit', (countLimit: number | string, schema) => {
      return schema.test('is-over', 'Issue count cannot exceed count limit.', (value: number | string) => +countLimit >= +value);
    }),
  couponValidFrom: string().when('validUntilPolicy', {
    is: PROMO_VALID_UNTIL_POLICY_FIXED,
    then: (schema) => schema.required('Coupon Valid from is Required'),
  }),
  couponValidUntil: string().when('validUntilPolicy', {
    is: PROMO_VALID_UNTIL_POLICY_FIXED,
    then: (schema) => schema.required('Coupon Valid until is Required'),
  }),
  description: string().required('Description is Required.'),
  rideTypesForLoyalty: array().when('promoType', {
    is: PROMO_TYPE_LOYALTY,
    then: (schema) =>
      schema
        .of(
          object().shape({
            value: number().required(),
            label: string().required(),
          })
        )
        .min(1, 'Ride Type is Required.'),
    otherwise: (schema) => schema.nullable().optional(),
  }),
});

export const scheduleSurgeValidationSchema = object().shape({
  name: string().required('Required'),
  startTimeStr: string().required('Required'),
  endTimeStr: string().required('Required'),
  rate: number().required('Required'),
  fixedAmount: number().required('Required'),
  amountPerKm: number().required('Required'),
  productCodes: array().min(1, 'Required'),
  currency: string().required('Required'),
});

export const dynamicSurgeDetailSchema = object().shape({
  baseFixedSurge: number().required('Required'),
  baseRateSurge: number().required('Required'),
  h3Id: string().required('Required'),
  fixedCoeff: number().required('Required'),
  rateCoeff: number().required('Required'),
  levelRatio: number().required('Required'),
  levelMultiplier: number().required('Required'),
  neighborCallsMultiplier: number().required('Required'),
  currency: string().required('Required'),
});

export const dynamicSurgeFormSchema = object().shape({
  baseFixedSurge: number().required('Base Fixed Surge is Required Field.'),
  baseRateSurge: number().required('Base Rate Surge is Required Field.'),
  fixedCoeff: number().required('Fixed Coeff is Required Field.'),
  rateCoeff: number().required('Rate Coeff is Required Field.'),
  levelRatio: number().required('Level Ratio is Required Field.'),
  levelMultiplier: number().required('Level Multiplier is Required Field.'),
  neighborCallsMultiplier: number().required('Neighbor Call Multiplier is Required Field.'),
  currency: string().required('Currency is Required Field.'),
});

export const helpCenterTopicValidationSchema = object().shape({
  type: string().required('Required'),
  contents: array()
    .of(
      object().shape({
        topicName: string().required('Required'),
        description: string().required('Required'),
      })
    )
    .min(1, 'Required'),
});

export const helpCenterListValidationSchema = object().shape({
  topicId: number().required('Requird').min(1, 'Required'),
  contents: array()
    .of(
      object().shape({
        title: string().required('Required'),
        description: string().required('Required'),
      })
    )
    .min(1, 'Required'),
});

export const accountValidationSchema = object().shape({
  bankName: string().required('Required'),
});

export const instantSurgeRuleListSchema = object().shape({
  fromZoneId: number().required('Required'),
  dto: object().shape({
    fixedAmount: number().required('Required'),
    currency: string().required('Required'),
    rate: number().required('Required'),
    since: number().required('Required'),
    durationMinutes: number().required('Required'),
  }),
});

export const instantSurgeRuleFormSchema = object().shape({
  productCodes: array().min(1, 'Required'),
  fromZoneIds: array().min(1, 'Required'),
  dto: object().shape({
    fixedAmount: number().required('Required'),
    currency: string().required('Required'),
    rate: number().required('Required'),
    since: string().required('Required'),
    durationMinutes: number().required('Required'),
  }),
});

export const placeTesterFormSchema = object().shape({
  id: string().test('invalidFormat', 'Invalid Type of Field', (value) => (value ? regex.uuid.test(value) : false)),
});

export const adyenTransactionoFromSchema = object().shape({
  entityId: string().test('invalidFormat', 'Invalid Type of Field', (value) => regex.uuid.test(value || '')),
  transactionId: string().test('invalidFormat', 'Invalid Type of Field', (value) => regex.uuid.test(value || '')),
  riderUuid: string().test('invalidFormat', 'Invalid Type of Field', (value) => regex.uuid.test(value || '')),
  cardUuid: string().test('invalidFormat', 'Invalid Type of Field', (value) => regex.uuid.test(value || '')),
  reason: string().required('Required'),
  region: string().required('Required'),
  currency: string().required('Required'),
  amount: number().required('Required'),
});

export const driverApplicationFormSchema = object().shape({
  cityId: number().required('Required'),
  phone: string().required('Required'),
  firstName: string().when('regionCode', (regionCode: REGION, schema) => {
    return regionCode === REGION.SG
      ? schema
          .required('Required Field.')
          .test('InvalidFormat', 'Incorrect Name', (value: string | undefined) => !!(value && adminRegex.SG.name.test(value)))
      : schema.required('Required Field.');
  }),
  lastName: string().when('regionCode', (regionCode: REGION, schema) => {
    return regionCode === REGION.SG
      ? schema
          .required('Required Field.')
          .test('InvalidFormat', 'Incorrect Name', (value: string | undefined) => !!(value && adminRegex.SG.name.test(value)))
      : schema.required('Required Field.');
  }),
  email: string().when('regionCode', (regionCode: REGION, schema) => {
    return regionCode === REGION.SG
      ? schema
          .required('Required Field.')
          .test('InvalidFormat', 'Incorrect Email', (value: string | undefined) => !!(value && adminRegex.SG.driverEmail.test(value)))
      : schema;
  }),
  // @ts-ignore
  nric: string().when(['regionCode', 'type'], (regionCode: string, type: number, schema) => {
    return regionCode === REGION.SG && VALIDATE_DRIVER_TYPE_NUMBERS.includes(type)
      ? schema.test('InvalidFormat', 'Incorrect NRIC', (value: string | undefined) => !!(value && adminRegex.SG.nric.test(value)))
      : schema;
  }),
  // @ts-ignore
  residentialAddress: string().when(['regionCode', 'type'], (regionCode: string, type: number, schema) => {
    return regionCode === REGION.SG && VALIDATE_DRIVER_TYPE_NUMBERS.includes(type)
      ? schema.test('InvalidFormat', 'Incorrect Residential Address', (value: string | undefined) => !!(value && adminRegex.SG.address.test(value)))
      : schema;
  }),
  // @ts-ignore
  drivingLicenseNumber: string().when(['regionCode'], (regionCode: REGION, schema) => {
    return regionCode === REGION.TH ? schema.required('Required') : schema;
  }),
  // @ts-ignore
  carMaker: string().when(['regionCode', 'type'], (regionCode: string, type: number, schema) => {
    return regionCode === REGION.SG && VALIDATE_CAR_MODEL_DRIVER_TYPE_NUMBERS.includes(type)
      ? schema.test('InvalidFormat', 'Incorrect Car Maker', (value: string | undefined) => !!(value && adminRegex.SG.carMaker.test(value)))
      : schema;
  }),
  // @ts-ignore
  carModel: string().when(['regionCode', 'type'], (regionCode: string, type: number, schema) => {
    return regionCode === REGION.SG && VALIDATE_CAR_MODEL_DRIVER_TYPE_NUMBERS.includes(type)
      ? schema.test('InvalidFormat', 'Incorrect Car Model', (value: string | undefined) => !!(value && adminRegex.SG.carModel.test(value)))
      : schema;
  }),
  // @ts-ignore
  taxiCompany: string().when(['type', 'regionCode'], (type: number, regionCode: REGION, schema) => {
    return type === DRIVER_TYPE_TAXI && regionCode !== REGION.TH ? schema.required('Required') : schema;
  }),
  insuranceExpireDate: string().when(['isChangedInsuranceExpireDate'], (isChangedInsuranceExpireDate: boolean, schema) => {
    return !isChangedInsuranceExpireDate
      ? schema
      : schema.test('invalidFormat', 'Invalid Date', (value?: string) => moment().startOf('days').diff(moment(value).startOf('days')) <= 0);
  }),
});

export const newsFormSchema = object().shape({
  dto: object().shape({
    locale: array().min(1, 'Required'),
    title: string().required('Required'),
    summary: string().required('Required'),
    displayPriority: number().required('Required').min(0, 'Invalid value.'),
    validFromMillis: number().required('Required'),
    validUntilMillis: number().required('Required'),
    contents: array(
      object({
        content: string().required('Required'),
        image: mixed()
          // @ts-ignore
          .when(['type', 'content'], (type: NewsContentType, content: string, schema) =>
            type === NEWS_CONTENT_TYPE.IMAGE && !content ? schema.required('Required') : schema.nullable()
          ),
        buttonName: string().when('type', {
          is: NEWS_CONTENT_TYPE.CALL_TO_ACTION_BUTTON,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.nullable(),
        }),
        buttonIdentifier: string().when('type', {
          is: NEWS_CONTENT_TYPE.CALL_TO_ACTION_BUTTON,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.nullable(),
        }),
      })
    )
      .min(1)
      .required(),
  }),
});

export const rideProductInfoTemplateSchema = object().shape({
  dto: object().shape({
    region: string().required('Required'),
    title: string().required('Required'),
    contents: array()
      .of(
        object().shape({
          locale: string().required('Required'),
          description: string().required('Required'),
        })
      )
      .min(1, 'Required'),
  }),
});

export const taxPrefillDescriptionFormSchema = object().shape({
  region: string().required('Required'),
  descriptionKey: string().required('Required'),
  locale: string().required('Required'),
  content: string().required('Required'),
  defaultLocale: boolean().required('Required'),
});

export const googlePlaceFormSchema = object().shape({
  addressComponents: array().of(
    object().shape({
      long_name: string().required('Required'),
      short_name: string().required('Required'),
      types: array().required('Required'),
    })
  ),
  formattedAddress: string().required('Required'),
  latitude: number().required('Required'),
  longitude: number().required('Required'),
  name: string().required('Required'),
});

export const surgeCapFormSchema = object().shape({
  title: string().required('Required'),
  region: string().required('Required'),
  currency: string().required('Required'),
  enabled: boolean().required('Required'),
});

export const validationSafetyAccidentToppicSchema = object().shape({
  contents: array(
    object({
      topicName: string().required('Required'),
    })
  )
    .min(1)
    .required(),
});

export const validationSafetyAccidentListSchema = object().shape({
  contents: array(
    object({
      title: string().required('Required'),
      description: string().required('Required'),
    })
  )
    .min(1)
    .required(),
  topicId: number().test('notMinus', 'Required Field.', (value) => (value && value !== -1) as boolean),
});

export const validateRestrictedAreaSchema = object().shape({
  name: string().required('Required'),
  zoneId: number()
    .test('notMinus', 'Required Field.', (value) => (value && value !== -1) as boolean)
    .required('Required'),
  regionId: number().required('Required'),
  enabled: boolean().required('Required'),
  startTime: string().required('Required'),
  endTime: string().required('Required'),
  drawInMap: boolean().required('Required'),
  restrictType: number().required('Required'),
  timeSpans: array(
    object({
      begin: number().required('Required'),
      until: number().required('Required'),
    })
  ).nullable(),
  availableLocation: object()
    .shape({
      placeId: string().required('Required'),
      allowanceType: number().required('Required'),
      subLocations: array(
        object({
          id: string().required('Required'),
          name: string().required('Required'),
          latitude: number().required('Required'),
          longitude: number().required('Required'),
          allowanceType: number().required('Required'),
        })
      ),
    })
    .required('Required'),
  locationInfos: array(
    object({
      id: string().required('Required'),
      name: string().required('Required'),
      latitude: number().required('Required'),
      longitude: number().required('Required'),
    })
  ),
});

export const validatePlaceSchema = object().shape({
  dto: object().shape({
    locationPoint: object().shape({
      latitude: number().test('notMinus', 'Required Field.', (value) => (value && value !== -999) as boolean),
      longitude: number().test('notMinus', 'Required Field.', (value) => (value && value !== -999) as boolean),
    }),
    postalAddress: object().shape({
      streetName: string().required('Required'),
    }),
    region: string().required('Required'),
    city: string().required('Required'),
    language: string().required('Required').typeError('Please Select a Language'),
    additionalLanguage: array(
      object({
        language: string().required('Required'),
        name: string().required('Required'),
        streetName: string().required('Required'),
      })
    ).nullable(),
    attributeType: string().required('Required').typeError('Please Select Category'),
  }),
});

export const validationPolygonSchema = object().shape({
  name: string().required('Required'),
  region: string().required('Required'),
});

export const validationSubPlaceSchema = object().shape({
  dto: object({
    name: string().required('Required'),
    locationPoint: object({
      latitude: number().required('Required'),
      longitude: number().required('Required'),
    }).required('Required'),
    language: string().required('Required'),
    additionalLanguage: array(
      object({
        language: string().required('Required'),
        name: string().required('Required'),
        streetName: string().required('Required'),
      })
    ).nullable(),
    postalAddress: object({
      streetName: string().required('Required'),
    }).nullable(),
  }),
});

export const validationUserSubmissionSchema = object().shape({
  locationPoint: object({
    latitude: number().required('Required'),
    longitude: number().required('Required'),
  }).nullable(),
});

export const validationPreventMatchSchema = object().shape({
  riderUuid: string()
    .test('uuidString', 'Please Input UUID format', (value) => value !== undefined && regex.uuidOnly.test(value))
    .required('Required'),
  driverUuid: string()
    .test('uuidString', 'Please Input UUID format', (value) => value !== undefined && regex.uuidOnly.test(value))
    .required('Required'),
  region: string().required('Required'),
  city: string().required('Required'),
});

export const validationTollGantrySchema = object().shape({
  id: number().required('Required'),
  region: string().required('Required'),
  zoneId: string().required('Required'),
  name: string().required('Required'),
  latitude: number().required('Required'),
  longitude: number().required('Required'),
});

export const validationTollTimeTableSchema = object().shape({
  zoneId: string().required('Required'),
  timePrices: array(
    object({
      timeInterval: object({
        begin: number().required('Required'),
        until: number().required('Required'),
      }),
      price: number().required('Required'),
      effectiveFromMillis: number().required('Required'),
    })
  )
    .required('Required')
    .min(1),
});

export const validationTollFreeDateSchema = object().shape({
  region: string().required('Required'),
  dates: array(
    object({
      name: string().required('Required'),
      additionalFareType: number().required('Required'),
      startDateTime: string().required('Required'),
      endDateTime: string().required('Required'),
    })
  )
    .required('Required')
    .min(1),
});

export const validationResetPasswordSchema = object().shape({
  newPassword: string().test(
    'passwordFormat',
    'Please follow criteria',
    (value) =>
      value !== undefined &&
      adminRegex.passwordMinimumCharacterLength.test(value) &&
      adminRegex.passwordContainBothLetterAndNumber.test(value) &&
      adminRegex.passwordContainUpperCaseLetter.test(value) &&
      adminRegex.passwordContainLowerCaseLetter.test(value) &&
      adminRegex.passwordContainSymbol.test(value) &&
      adminRegex.passwordNotIncludeSpace.test(value)
  ),
  confirmPassword: string()
    .oneOf([ref('newPassword')], 'Passwords do not match')
    .required('Required'),
  token: string().required('Required'),
});

export const validationSupportPageManagementSchema = object().shape({
  region: string().required('Required'),
  appAuthority: string().required('Required'),
  contents: array(
    object({
      locale: string().required('Required'),
      description: string().required('Required'),
    })
  )
    .min(1)
    .required('Required'),
});

export const validationCouponSchema = object().shape({
  title: string().required('No title available'),
  code: string()
    .matches(adminRegex.alphabetAndNumberAndDash, 'Invalid code.')
    .required('Code is Required.')
    // @ts-ignore 원래 yup builder에서 values 값을 배열로 내려주나, 무슨 문제인지 각각의 Param으로 내려줌.
    .when(['bulkCreate', 'userIds'], (bulkCreate: boolean, userIds: string[] | undefined, schema) => {
      if (!bulkCreate && !userIds?.length) {
        return schema.required('There is no code.');
      }

      return schema;
    }),
  amount: number().required('There is no amount.').min(1, 'Invalid amount.'),
  cities: array().min(1, 'There is no city.'),
  carTypes: array().min(1, 'There is no Car Type.'),
  rideTypes: array().min(1, 'There is no ride type.'),
  paymentMethods: array().min(1, 'There is no payment methods'),
  tims: array().min(1, 'There is no times'),
  maxAmount: number().when('discountType', (discountType, schema) => {
    if (DISCOUNT_TYPE_REQUIRED_MAX_AMOUNT.includes(discountType)) {
      return schema.required('There is no max amount').min(1, 'Invalid max amount.');
    }
    return schema;
  }),
  minSpendAmount: number().required('There is no Min Spend Amount.').min(0, 'Invalid Min Spend Amount.'),
  minChargeAmount: number().required('There is no Min Charge Amount to Rider.').min(0, 'Invalid Min Charge Amount to Rider.'),
  targetType: number().required('There is no target type.'),
  count: number().when('bulkCreate', (bulkCreate, schema) =>
    bulkCreate
      ? schema
          .required('There is no coupons count')
          .min(1, 'You cannot make up to Lower than 0 coupons at a time.')
          .max(1000, 'You can make up to 1000 coupons at a time.')
      : schema
  ),
  codePrefix: string().when('bulkCreate', (bulkCreate, schema) => (bulkCreate ? schema.required('There is no coupon code prefix') : schema)),
  codeLength: number().when('bulkCreate', (bulkCreate, schema) =>
    bulkCreate ? schema.min(5, 'Code Length is a number between 5 and 10.').max(10, 'Code Length is a number between 5 and 10.') : schema
  ),
  currency: string().required('Required'),
});

export const validationRiderMissionSchema = object().shape({
  dto: object({
    missionType: number().required('Required'),
    title: array()
      .of(
        object({
          language: string().required('Required'),
          content: string().required('Required'),
        })
      )
      .min(1, 'Title requires at least one'),
    summary: array(
      object({
        language: string().required('Required'),
        content: string().required('Required'),
      })
    ).min(1, 'Summary requires at least one'),
    description: array(
      object({
        language: string().required('Required'),
        content: string().required('Required'),
      })
    ).min(1, 'Description requires at least one'),
    termsAndConditions: array(
      object({
        language: string().required('Required'),
        content: string().required('Required'),
      })
    ).min(1, 'T&Cs requires at least one'),
    displayOrder: number().required('Required'),
    pinnedOnMain: boolean().required('Required'),
    enabled: boolean().required('Required'),
    region: string().required('Required'),
    validFrom: string().required('Required'),
    validUntil: string().required('Required'),
    timeSpans: array(
      object({
        weekDays: array().of(number().required('Required')).required('Required').min(1),
        startTimeStr: string().required('Required'),
        endTimeStr: string().required('Required'),
      })
    ).nullable(),
    targetType: number().required('Required'),
    rideTypes: array(string().optional()).min(1),
    paymentMethods: array(string().optional()).min(1),
    explicitParticipation: boolean().required('Required'),
    polygonGeofencePolicy: number().required('Required'),
    totalStampCount: number().required('Required'),
    rewardUntil: string().required('Required'),
    tiers: array(
      object({
        targetAmount: number().required('Required'),
        rewardType: number().required('Required'),
        rewardTitles: array(
          object({
            language: string().required('Required'),
            content: string().required('Required'),
          })
        ).min(1, 'Reward Title requires at least one'),
        rewardDescriptions: array(
          object({
            language: string().required('Required'),
            content: string().required('Required'),
          })
        ).min(1, 'Reward Description requires at least one'),
      })
    ).min(1, 'Tier requires at least one'),
  }),
  thumbnailImage: string().when(['dto.thumbnailDescription'], (thumbnailDescription: string, schema) => {
    return !thumbnailDescription ? schema.required('Required Field.') : schema;
  }),
  mainImage: string().when(['dto.mainImageDescription'], (mainImageDescription: string, schema) => {
    return !mainImageDescription ? schema.required('Required Field.') : schema;
  }),
});

export const validationImportZoneSchema = object().shape({
  regionId: number().required('Required'),
  data: object().required('Required').typeError('Required'),
});

export const validationRemarkLogSettingSchema = object().shape({
  name: string().required('Required'),
  type: string().required('Required'),
  regions: array().of(string()).min(1, 'Required'),
});

export const validationRemarkLogSchema = object().shape({
  comment: string().required('Required'),
  reportedAt: string().required('Required'),
});

export const validationRemarkLogFormSchema = object().shape({
  relatedId: string().matches(regex.uuid, 'Enter UUID'),
  relatedModel: string(),
  receiverId: string().matches(regex.uuid, 'Enter UUID'),
  reportedAt: string().required('Required'),
  writer: string().required('Required'),
  comment: string().required('Required'),
  channelId: string().required('Required'),
  interactionId: string().required('Required'),
});

export const validationBackgroundCheckSchema = object().shape({
  backgroundCheckImageFiles: array()
    .of(string())
    .when(['body.backgroundCheckFileBody'], (backgroundCheckFileBody: string[], schema) =>
      !backgroundCheckFileBody.length ? schema.min(1, 'Required Field').required('Required Field.') : schema
    ),
  body: object().shape({
    backgroundCheckDate: string().required('Required'),
  }),
});

export const validationVehicleMakerSchema = object().shape({
  maker: string().required('Required'),
  region: string().required('Required'),
  driverTypes: array().min(1, 'Required').required('Required'),
});

export const validationVehicleModelSchema = object().shape({
  model: string().required('Required'),
  vehicleType: number().required('Required'),
  engineType: string().required('Reuiqred'),
});

export const validationBannerSchema = object().shape({
  type: string().required('Required'),
  displayPriority: number().required('Required'),
  cities: array().min(1, 'Cities requires at least one'),
  region: string().required('Required'),
  thumbnailContentType: string().required('Required'),
  bannerName: string().required('Required'),
  thumbnailDescription: array().of(
    object().shape({
      locale: string(),
      content: string().test(
        'hasThreeOrMoreNewLines',
        `Description can't have more then 3 new lines`,
        (value) => !value || adminRegex.maxTwoNewLines.test(value)
      ),
    })
  ),
  validFrom: string().required('Required'),
  validTo: string().required('Required'),
});

export const validationBINManagementSchema = object().shape({
  issuerCode: string().required('Required'),
  binPrefixes: array().of(string().min(6, 'BIN Prefix needs to be at least 6 characters').required('Required')),
  displayText: string().required('Required'),
});

export const validationCpfContributionTableSchema = object().shape({
  name: string().required('Required'),
  forOptIn: array()
    .nullable()
    .of(
      object().shape({
        value: boolean().required(),
        label: string().required(),
      })
    )
    .min(1, 'Required.'),
  validFrom: string().nullable().required('Required'),
  validUntil: string().nullable().optional(),
});

export const otpWhitelistSchema = object().shape({
  phoneNumber: string()
    .required('Required')
    .test(
      'InvalidFormat',
      'Phone Number format should be in E164 format',
      (value: string | undefined) => !!(value && adminRegex.e164PhoneNumber.test(value))
    ),
});

export const validationDynamicSurgeSettingSchema = object().shape({
  dto: object().shape({
    name: string().required('Required'),
    region: string().required('Required'),
    city: string().required('Required'),
    dayOfWeeks: array().of(string()).min(1, 'Required'),
    carGroup: number().required('Required'),
    neighborMultiplierRound1: number().required('Required'),
    neighborMultiplierRound2: number().required('Required'),
  }),
  settingTemp: object().shape({
    baseFixedSurge: number().required('Required'),
    baseRateSurge: number().required('Required'),
    fixedCoeff: number().required('Required'),
    rateCoeff: number().required('Required'),
    levelRatio: number().required('Required'),
    levelMultiplier: number().required('Required'),
    neighborCallsMultiplier: number().required('Required'),
    positiveNoCallCycle: number().required('Required'),
    negativeNoCallCycle: number().required('Required'),
  }),
});

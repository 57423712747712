import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Detail = lazy(() => import(/* webpackChunkName: "TadaMaintenanceDetail" */ '@/pages/TadaMaintenance/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "TadaMaintenanceDetail" */ '@/pages/TadaMaintenance/Edit'));

export const tadaMaintenanceRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.TadaMaintenance,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.TadaMaintenance}/edit`,
    component: Edit,
    exact: true,
  },
];

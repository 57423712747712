import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createRestrictedAreaFailure,
  createRestrictedAreaRequest,
  createRestrictedAreaSuccess,
  deleteRestrictedAreaFailure,
  deleteRestrictedAreaRequest,
  deleteRestrictedAreaSuccess,
  syncRestrictedAreaFailure,
  syncRestrictedAreaRequest,
  syncRestrictedAreaSuccess,
  updateRestrictedAreaFailure,
  updateRestrictedAreaRequest,
  updateRestrictedAreaSuccess,
} from '@/pages/RestrictedAreas/reducer';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/restricted_areas';

function* createRestrictedArea({ payload }: ReturnType<typeof createRestrictedAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createRestrictedAreaSuccess(response.data) : createRestrictedAreaFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createRestrictedAreaFailure((e as Error).message));
  }
}

function* updateRestrictedArea({ payload: { id, body } }: ReturnType<typeof updateRestrictedAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateRestrictedAreaSuccess() : updateRestrictedAreaFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateRestrictedAreaFailure((e as Error).message));
  }
}

function* deleteRestrictedArea({ payload }: ReturnType<typeof deleteRestrictedAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${basePath}/${payload}`);

    const action = response.ok ? deleteRestrictedAreaSuccess() : deleteRestrictedAreaFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteRestrictedAreaFailure((e as Error).message));
  }
}

function* syncRestrictedArea({ payload }: ReturnType<typeof syncRestrictedAreaRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${basePath}/${payload}/sync`);

    const action = response.ok ? syncRestrictedAreaSuccess() : syncRestrictedAreaFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(syncRestrictedAreaFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createRestrictedAreaRequest.type, createRestrictedArea);
  yield takeLatest(updateRestrictedAreaRequest.type, updateRestrictedArea);
  yield takeLatest(deleteRestrictedAreaRequest.type, deleteRestrictedArea);
  yield takeLatest(syncRestrictedAreaRequest.type, syncRestrictedArea);
}

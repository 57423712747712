import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

const PREFIX = `${Service.placeSvc}/userSubmission`;

export const bulkApproveSubmissionRequest = createAction<FormData>(`${PREFIX}/bulkApproveSubmissionRequest`);
export const bulkApproveSubmissionSuccess = createAction(`${PREFIX}/bulkApproveSubmissionSuccess`);
export const bulkApproveSubmissionFailure = createAction<string>(`${PREFIX}/bulkApproveSubmissionFailure`);

export const bulkRejectSubmissionRequest = createAction<FormData>(`${PREFIX}/bulkRejectSubmissionRequest`);
export const bulkRejectSubmissionSuccess = createAction(`${PREFIX}/bulkRejectSubmissionSuccess`);
export const bulkRejectSubmissionFailure = createAction<string>(`${PREFIX}/bulkRejectSubmissionFailure`);

export const updatePoiSubmissionRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/updatePoiSubmissionRequest`);
export const updatePoiSubmissionSuccess = createAction(`${PREFIX}/updatePoiSubmissionSuccess`);
export const updatePoiSubmissionFailure = createAction<string>(`${PREFIX}/updatePoiSubmissionFailure`);

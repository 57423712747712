import { createAction } from '@reduxjs/toolkit';

import { PreventMatchDto, PreventMatchUpdateDto } from '@/pages/PreventMatch/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.penaltySvc}/preventMatch`;

export const createPreventMatchRequest = createAction<PreventMatchUpdateDto>(`${PREFIX}/createPreventMatchRequest`);
export const createPreventMatchSuccess = createAction<PreventMatchDto>(`${PREFIX}/createPreventMatchSuccess`);
export const createPreventMatchFailure = createAction<string>(`${PREFIX}/createPreventMatchFailure`);

export const updatePreventMatchRequest = createAction<{ id: string; data: PreventMatchUpdateDto }>(`${PREFIX}/updatePreventMatchRequest`);
export const updatePreventMatchSuccess = createAction(`${PREFIX}/updatePreventMatchSuccess`);
export const updatePreventMatchFailure = createAction<string>(`${PREFIX}/updatePreventMatchFailure`);

export const deletePreventMatchRequest = createAction<string>(`${PREFIX}/deletePreventMatchRequest`);
export const deletePreventMatchSuccess = createAction(`${PREFIX}/deletePreventMatchSuccess`);
export const deletePreventMatchFailure = createAction<string>(`${PREFIX}/deletePreventMatchFailure`);

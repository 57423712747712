import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createPolygonRequest,
  createPolygonSuccess,
  createPolygonFailure,
  updatePolygonRequest,
  updatePolygonSuccess,
  updatePolygonFailure,
  searchPolygonRequest,
  searchPolygonSuccess,
  searchPolygonFailure,
  fetchPolygonListByIdsRequest,
  fetchPolygonListByIdsSuccess,
  fetchPolygonListByIdsFailure,
} from '@/pages/Polygons/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { schema } = getContentSchema();

function* createPolygon({ payload }: ReturnType<typeof createPolygonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/polygons', { data: payload });

    const action = response.ok ? createPolygonSuccess(response.data) : createPolygonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createPolygonFailure((e as Error).message));
  }
}

function* updatePolygon({ payload: { id, body } }: ReturnType<typeof updatePolygonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/polygons/${id}`, { data: body });

    const action = response.ok ? updatePolygonSuccess(response.data) : updatePolygonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updatePolygonFailure((e as Error).message));
  }
}

function* searchPolygon({ payload }: ReturnType<typeof searchPolygonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/polygons', { params: { query: payload }, schema });

    const action = response.ok ? searchPolygonSuccess(response.data) : searchPolygonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(searchPolygonFailure((e as Error).message));
  }
}

function* fetchPolygonListByIds({ payload }: ReturnType<typeof fetchPolygonListByIdsRequest>): IterableIterator {
  try {
    const polygons = [];

    for (const id of payload) {
      const response = yield authenticatedRequest.get(`/polygons/${id}`);

      if (response.ok) {
        polygons.push(response.data);
      } else {
        throw new Error(response.data.message);
      }
    }

    if (polygons.length) {
      yield put(fetchPolygonListByIdsSuccess(polygons));
    }
  } catch (e) {
    yield put(fetchPolygonListByIdsFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createPolygonRequest.type, createPolygon);
  yield takeLatest(updatePolygonRequest.type, updatePolygon);
  yield takeLatest(searchPolygonRequest.type, searchPolygon);
  yield takeLatest(fetchPolygonListByIdsRequest.type, fetchPolygonListByIds);
}

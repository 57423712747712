import { all, call } from 'redux-saga/effects';

import bannerSaga from '@/svc/inbox-svc/banner/saga';
import inboxItemSaga from '@/svc/inbox-svc/inboxItem/saga';
import maintenanceSaga from '@/svc/inbox-svc/maintenance/saga';
import newsSaga from '@/svc/inbox-svc/news/saga';

export default function* defaultSaga() {
  yield all([call(maintenanceSaga), call(bannerSaga), call(inboxItemSaga), call(newsSaga)]);
}

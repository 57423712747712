import { createSlice } from '@reduxjs/toolkit';

import { CarSvcState } from '@/svc/member-svc/car/type';

const initialState: CarSvcState = {};

const carSvcSlice = createSlice({
  name: 'car-svc',
  initialState,
  reducers: {},
});

export default carSvcSlice.reducer;

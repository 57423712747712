import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  deleteCreditCardRequest,
  setFraudRequest,
  deleteCreditCardSuccess,
  deleteCreditCardFailure,
  setFraudSuccess,
  setFraudFailure,
} from '@/svc/pay-svc/creditCards/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deleteCreditCard({ payload: id }: ReturnType<typeof deleteCreditCardRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/credit_cards/${id}/destroy`);

    if (response.ok) {
      yield put(deleteCreditCardSuccess());
    } else {
      yield put(deleteCreditCardFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteCreditCardFailure((e as Error).message));
  }
}

function* setFraud({ payload: { id, fraud } }: ReturnType<typeof setFraudRequest>): IterableIterator {
  const response = yield authenticatedRequest.post(`/credit_cards/${id}/toggle_fraud`, { data: { fraud } });

  if (response.ok) {
    yield put(setFraudSuccess());
  } else {
    yield put(setFraudFailure(response.data.errorMessage));
  }
}

export default function* defaultSaga() {
  yield takeLatest(deleteCreditCardRequest.type, deleteCreditCard);
  yield takeLatest(setFraudRequest.type, setFraud);
}

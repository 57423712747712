import { PropsWithChildren, MouseEvent, CSSProperties, ComponentType } from 'react';

import styled from 'styled-components';

export interface TextProps {
  /**
   * text의 HTML 태그를 설정하는 값
   */
  tag?: keyof JSX.IntrinsicElements | ComponentType<any>;
  /**
   * text의 스타일을 커스텀할 수 있는 prop
   */
  customStyle?: CSSProperties | string;
  /**
   * 텍스트를 클릭했을 시에 발생하는 이벤트 함수
   */
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  /**
   * 클래스명
   */
  className?: string;
  /**
   * 폰트 색깔
   */
  color?: any;
}

const StyledText = styled.div<TextProps>`
  margin: 0;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  letter-spacing: normal;
  font-style: normal;
  color: ${(props: any) => props.color ?? '#151b1e'};
  font-size: inherit;
  ${(props: any) => props.customStyle}
`;

/**
 * `children` 으로 `value`를 받아서 Text로 보여주는 컴포넌트이다.
 */
function Text({ children, tag = 'p', customStyle, ...props }: PropsWithChildren<TextProps>) {
  return (
    <StyledText as={tag} customStyle={customStyle} {...props}>
      {children}
    </StyledText>
  );
}

export default Text;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  upsertTollGantryRequest,
  upsertTollGantrySuccess,
  upsertTollGantryFailure,
  deleteTollGantryRequest,
  deleteTollGantrySuccess,
  deleteTollGantryFailure,
  upsertTollTimeTableRequest,
  upsertTollTimeTableSuccess,
  upsertTollTimeTableFailure,
  deleteTollTimeTableRequest,
  deleteTollTimeTableSuccess,
  deleteTollTimeTableFailure,
  upsertTollFreeDateRequest,
  upsertTollFreeDateSuccess,
  upsertTollFreeDateFailure,
  deleteTollFreeDateRequest,
  deleteTollFreeDateSuccess,
  deleteTollFreeDateFailure,
} from '@/svc/routing-svc/toll/reducer';
import { authenticatedRequest } from '@/utils/request';

const BASE_URL = '/routingsvc/admin/v1';

function* upsertTollGantry({ payload: { id, data } }: ReturnType<typeof upsertTollGantryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_URL}/toll-gantries/${id ?? data.id}`, { data });

    if (response.ok) {
      yield put(upsertTollGantrySuccess(response.data));
    } else {
      yield put(upsertTollGantryFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertTollGantryFailure((e as Error).message));
  }
}

function* deleteTollGantry({ payload }: ReturnType<typeof deleteTollGantryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_URL}/toll-gantries/${payload}`);

    if (response.ok) {
      yield put(deleteTollGantrySuccess());
    } else {
      yield put(deleteTollGantryFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteTollGantryFailure((e as Error).message));
  }
}

function* upsertTollTimeTable({ payload: { id, data } }: ReturnType<typeof upsertTollTimeTableRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_URL}/toll-time-tables/${id}`, { data });

    if (response.ok) {
      yield put(upsertTollTimeTableSuccess(response.data));
    } else {
      yield put(upsertTollTimeTableFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertTollTimeTableFailure((e as Error).message));
  }
}

function* deleteTollTimeTable({ payload }: ReturnType<typeof deleteTollTimeTableRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_URL}/toll-time-tables/${payload}`);

    if (response.ok) {
      yield put(deleteTollTimeTableSuccess());
    } else {
      yield put(deleteTollTimeTableFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteTollTimeTableFailure((e as Error).message));
  }
}

function* upsertTollFreeDate({ payload: { region, data } }: ReturnType<typeof upsertTollFreeDateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_URL}/toll-free-dates/${region}`, { data });

    if (response.ok) {
      yield put(upsertTollFreeDateSuccess(response.data));
    } else {
      yield put(upsertTollFreeDateFailure(response.data.message));
    }
  } catch (e) {
    yield put(upsertTollFreeDateFailure((e as Error).message));
  }
}

function* deleteTollFreeDate({ payload }: ReturnType<typeof deleteTollFreeDateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_URL}/toll-free-dates/${payload}`);

    if (response.ok) {
      yield put(deleteTollFreeDateSuccess());
    } else {
      yield put(deleteTollFreeDateFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteTollFreeDateFailure((e as Error).message));
  }
}

export default function* tollSaga() {
  yield takeLatest(upsertTollGantryRequest.type, upsertTollGantry);
  yield takeLatest(deleteTollGantryRequest.type, deleteTollGantry);
  yield takeLatest(upsertTollTimeTableRequest.type, upsertTollTimeTable);
  yield takeLatest(deleteTollTimeTableRequest.type, deleteTollTimeTable);
  yield takeLatest(upsertTollFreeDateRequest.type, upsertTollFreeDate);
  yield takeLatest(deleteTollFreeDateRequest.type, deleteTollFreeDate);
}

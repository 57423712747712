import { CSSProperties } from 'react';

import { DatetimepickerProps } from 'react-datetime';
import styled from 'styled-components';

import { TIMEZONE } from '@/assets/constants';
import { Datetime } from '@/components/Datetime';

interface Props extends DatetimepickerProps {
  /**
   * 타임존 국가 지정
   */
  timeZoneCountry?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * 커스텀 스타일
   */
  customStyle?: CSSProperties;
}

const DateTimeWrapper = styled.div`
  display: flex;

  > .rdt {
    flex: 1;

    > input {
      height: 40px;

      &::placeholder,
      &::-webkit-input-placeholder {
        font-size: 12px;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
      }
    }
  }
`;

const TimeZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
  padding: 0 10px;
  border: 1px solid #c2cfd6;
  background-color: #f3f6f9;
  color: #3f4254;
`;
export const TimeZone = ({ value }: { value: string }) => <TimeZoneWrapper>{value}</TimeZoneWrapper>;

/**
 * 날짜와 시간을 캘린더로 입력 받는 이벤트를 처리하는 컴포넌트이다.
 */
function DateInput({
  dateFormat = false,
  timeFormat = false,
  closeOnSelect = true,
  timeZoneCountry,
  placeholder,
  customStyle,
  ...dateTimeProps
}: Props) {
  if (!placeholder) {
    if (dateFormat && timeFormat) {
      placeholder = 'Select Date, Time';
    } else if (dateFormat) {
      placeholder = 'Select Date';
    } else if (timeFormat) {
      placeholder = 'Select Time';
    }
  }

  return (
    <DateTimeWrapper style={customStyle}>
      <Datetime
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        closeOnSelect={closeOnSelect}
        {...dateTimeProps}
        inputProps={{
          placeholder,
          ...dateTimeProps.inputProps,
        }}
      />
      {timeZoneCountry && <TimeZone value={TIMEZONE[timeZoneCountry]} />}
    </DateTimeWrapper>
  );
}

export default DateInput;

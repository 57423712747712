import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "SafetyAccidentListManagementList" */ '@/pages/SafetyAccidentListManagement/List'));
const Detail = lazy(() => import(/* webpackChunkName: "SafetyAccidentListManagementDetail" */ '@/pages/SafetyAccidentListManagement/Detail'));
const New = lazy(() => import(/* webpackChunkName: "SafetyAccidentListManagementNew" */ '@/pages/SafetyAccidentListManagement/New'));
const Edit = lazy(() => import(/* webpackChunkName: "SafetyAccidentListManagementEdit" */ '@/pages/SafetyAccidentListManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "SafetyAccidentListManagementOrder" */ '@/pages/SafetyAccidentListManagement/Order'));

export const safetyAccidentListManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.SafetyAccidentListManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.SafetyAccidentListManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.SafetyAccidentListManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.SafetyAccidentListManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.SafetyAccidentListManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

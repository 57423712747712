import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createNewsRequest,
  createNewsSuccess,
  createNewsFailure,
  deleteNewsRequest,
  deleteNewsSuccess,
  deleteNewsFailure,
  updateNewsRequest,
  updateNewsSuccess,
  updateNewsFailure,
  downloadBulkUploadNewsTargetTemplateRequest,
  downloadBulkUploadNewsTargetTemplateSuccess,
  downloadBulkUploadNewsTargetTemplateFailure,
  bulkUploadNewsTargetsRequest,
  bulkUploadNewsTargetsSuccess,
  bulkUploadNewsTargetsFailure,
} from '@/svc/inbox-svc/news/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* createNews({ payload: data }: ReturnType<typeof createNewsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/news`, {
      data,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? createNewsSuccess() : createNewsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createNewsFailure((e as Error).message));
  }
}

function* deleteNews({ payload: id }: ReturnType<typeof deleteNewsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/news/${id}`);

    const action = response.ok ? deleteNewsSuccess() : deleteNewsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteNewsFailure((e as Error).message));
  }
}

function* updateNews({ payload: { id, data } }: ReturnType<typeof updateNewsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/news/${id}`, {
      data,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? updateNewsSuccess() : updateNewsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateNewsFailure((e as Error).message));
  }
}

function* downloadBulkUploadNewsTargetTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/news/bulk-upload-news-targets-template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'news_bulk_upload_news_template.xlsx');
      yield put(downloadBulkUploadNewsTargetTemplateSuccess());
    } else {
      yield put(downloadBulkUploadNewsTargetTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadBulkUploadNewsTargetTemplateFailure((e as Error).message));
  }
}

function* bulkUploadNewsTargets({ payload: { id, data } }: ReturnType<typeof bulkUploadNewsTargetsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/news/${id}/bulk-upload-news-targets`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(bulkUploadNewsTargetsSuccess());
    } else {
      yield put(bulkUploadNewsTargetsFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkUploadNewsTargetsFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createNewsRequest.type, createNews);
  yield takeLatest(deleteNewsRequest.type, deleteNews);
  yield takeLatest(updateNewsRequest.type, updateNews);
  yield takeLatest(downloadBulkUploadNewsTargetTemplateRequest.type, downloadBulkUploadNewsTargetTemplate);
  yield takeLatest(bulkUploadNewsTargetsRequest.type, bulkUploadNewsTargets);
}

export default {
  translation: {
    menu: {
      ride_products: 'Ride Products',
      dashboard: 'Dashboard',
      tada_rides: 'Tada Rides',
      reviews: 'Reviews',
      payment_transactions: 'Payment Transactions',
      alipay_transactions: 'Alipay Transactions',
      pao_transactions: 'Pao Transactions',
      momo_transactions: 'Momopay Transactions',
      pipay_transactions: 'Pipay Transactions',
      cmcb_transactions: 'Cmcb Transactions',
      shb_request_history: 'Shb Request HIstory',
      payment_transaction: 'Payment Transaction',
      drivers: 'Drivers',
      driver_dispatch_status: 'Driver Dispatch Status',
      driver_pinglogs: 'Driver PingLogs',
      cars: 'Cars',
      profile_picture_change: 'Profile Picture Change',
      phone_verification: 'Phone Verification',
      driver_tutorial: 'Driver Tutorial',
      penalty: 'Penalty',
      promotion_and_coupon: 'Promotion & Coupon',
      promotion: 'Promotion',
      coupon: 'Coupon',
      rider: 'Rider',
      credit_card: 'Credit Card',
      delivery: 'Delivery',
      delivery_drivers: 'Delivery Drivers',
      warehouse: 'Warehouse',
      merchant: 'Merchant',
      delivery_ride: 'Delivery Ride',
      delivery_ride_request: 'Delivery Ride Request',
      routing_result: 'Routing Result',
      delivery_ride_schedule: 'Delivery Ride Schedule',
      delivery_export_schedule: 'Delivery Export Schedule',
      pending_ride: 'Pending Ride',
      delivery_ride_route_option: 'Delivery Ride Route Option',
      delivery_ride_payment_option: 'Delivery Ride Payment Option',
      delivery_ride_price_option: 'Delivery Ride Price Option',
      hourly_rental_car: 'Hourly Rental Car',
      hourly_rental_request: 'Hourly Rental Request',
      balance: 'Balance',
      account: 'Account',
      cashout_requests: 'Cashout Requests',
      transaction: 'Transaction',
      deposit_transaction: 'Deposit Transaction',
      shb_wallet_list: 'Shb Wallet List',
      operations: 'Operations',
      inbox: 'Inbox',
      banner: 'Banner',
      products: 'Products',
      dispatch_config: 'Dispatch Config',
      pois: 'POIs',
      nearby_pois: 'Nearby POIs',
      geo: 'Geo',
      cities: 'Cities',
      areas: 'Areas',
      zones: 'Zones',
      polygons: 'Polygons',
      airports: 'Airports',
      regions: 'Regions',
      restricted_areas: 'Restricted Areas',
      surges: 'Surges',
      dynamic_surge: 'Dynamic Surge',
      dynamic_hot_zone: 'Dynamic Hot Zone',
      dynamic_surge_monitor: 'Dynamic Surge Monitor',
      instant_surge_rule: 'Instant Surge Rule',
      additional_price_timetable: 'Additional Price Timetable',
      additional_price_rules: 'Additional Price Rules',
      price_verification: 'Price Verification',
      monitoring: 'Monitoring',
      heatmap: 'HeatMap',
      admin_and_role_setting: 'Admin & Role Setting',
      admin_setting: 'Admin Setting',
      role_setting: 'Role Setting',
      corporate: 'Corporate',
      corporates: 'Corporates',
      corporate_manager: 'Corporate Manager',
      admin_log: 'Admin Log',
      sms_template: 'SMS Template',
      prefix_coupon: 'Prefix Coupon',
      bank_info: 'Cashout Bank Info',
      referral: 'Referral',
      corporate_invoice: 'Corporate Invoice',
    },
    car_type: 'Car Type',
    ride_status: 'Ride Status',
    pay_status: 'Pay Status',
    ride_id: 'Ride Id',
    search: 'Search',
    driver: 'Driver',
    rider: 'Rider',
    all_data: 'All {{data}}',
    all: 'All',
    remove_from_cr: 'Remove From CR',
    go_to_transaction_item: 'Go To Transaction Item',
    generate_transaction: 'Generate Transaction',
    fraud: 'Fraud',
    boolean: {
      true: 'True ',
      false: 'False ',
    },
    region: 'Region',
    city: 'City',
    fraud_type: 'Fraud Type',
    create_time_start: 'Create Time Start',
    create_time_end: 'Create Time End',
    select_date: 'Select Date',
    order_from: 'Order From',
    payment_method: 'Payment Method',
    product_type: 'Product Type',
    date_preset: 'Date Preset',
    select_data: 'Select {{data}}',
    update_data: 'Update {{data}}',
    update: 'Update',
    ride_type: 'Ride Type',
    dispatch_type: 'Dispatch Type',
    rides_per_page: 'Rides Per Page',
    discount: 'Discount',
    matched: 'Matched',
    valid: 'Valid',
    id: 'Id',
    driver_name_uuid: 'Driver name(uuid)',
    rider_info: 'Rider Info',
    pick_up_name: 'Pick Up Name',
    destination_name: 'Destination Name',
    total_price: 'Total Price',
    create_time: 'Create Time',
    drop_off_time: 'Drop Off Time',
    assign_time: 'Assign Time',
    pick_up: 'Pick Up',
    drop_off: 'Drop Off',
    base_ride: 'Base Ride',
    delivery_ride: 'Delivery Ride',
    tada_na: 'Tada N/A',
    street_hailing: 'Street Hailing',
    na_ride_type: 'N/A Ride ({{type}})',
    ride: 'Ride',
    raw_price_info: 'Raw Price Info',
    ride_price: 'Ride Price',
    estimated_price: 'Estimated Price',
    rider_application_fee: 'Rider Application Fee',
    driver_application_fee: 'Driver Application Fee',
    na: 'N/A',
    toll_fee: 'Toll Fee',
    gantry_fee: 'Gantry Fee',
    etc_fee: 'Etc Fee',
    ride_option_fee: 'Ride Option Fee',
    booking_fee: 'Booking Fee',
    reservation_booking_fee: 'Reservation Booking Fee',
    rider_system_fee: 'Rider System Fee',
    rider_system_fee_tax: 'Rider System Fee Tax',
    driver_system_fee: 'Driver System Fee',
    driver_system_fee_tax: 'Driver System Fee Tax',
    refund_amount: 'Refund Amount',
    total_price_info: 'Total Price Info',
    net_price_rider_paid_amount: 'Net Price (Rider Paid amount)',
    driver_job_card_price: 'Driver Job Card Price',
    driver_received_from_tada: 'Driver Received From TADA',
    driver_earning: 'Driver Earning',
    net_earning_before_fedr: 'Net Earnings (Before FEDR)',
    net_earning_after_fedr: 'Net Earnings (After FEDR)',
    credit_to_reserves: 'Credit to Reserves',
    added_to_driver_balance: 'Added to Driver Balance',
    ad: 'Auto Dispatch',
    sd: 'System Dispatch',
    mdt: 'Mdt',
    currency: 'Currency',
    surge_multiplier: 'Surge Multiplier',
    surge_fixed: 'Surge Fixed',
    dynamic_surge_level: 'Dynamic Surge Level',
    coupon_code: 'Coupon Code',
    driver_uuid: 'Driver Uuid',
    driver_name: 'Driver Name',
    credit_card_id: 'Credit Card Id',
    rider_uuid: 'Rider Uuid',
    pick_up_address: 'Pick Up Address',
    qr_code: 'Qr Code',
    ride_distance_km: 'Ride Distance Km',
    cancel_reason_type: 'Cancel Reason Type',
    cancel_reason: 'Cancel Reason',
    app_meter: 'App Meter',
    pick_up_start_time: 'Pick Up Start Time',
    pick_up_arrived_time: 'Pick Up Arrived Time',
    start_time: 'Start Time',
    driver_info: 'Driver Info',
    product: 'Product',
    options: 'Options',
    tollway: 'Tollway',
    additional_fare: 'Additional Fare',
    car_model: 'Car Model',
    car_plate: 'Car Plate',
    name: 'Name',
    phone: 'Phone',
    profile_photo_url: 'Profile Photo Url',
    rate: 'Rate',
    taxi_company: 'Taxi Company',
    hired: 'Hired',
    company_id: 'Company Id',
    email: 'Email',
    fare_type: 'Fare Type',
    type: 'Type',
    price: 'Price',
    remove: 'Remove',
    append: 'Append',
    edit_data: 'Edit {{data}}',
    status: 'Status',
    amount: 'Amount',
    idempotency_key: 'Idempotency Key',
    fraud_history: 'Fraud History',
    associated_ride_identifiers: 'Associated Ride Identifiers',
    driver_ping_logs: 'Driver Ping Logs',
    uuid: 'UUID',
    assign_type: 'Assign Type',
    driver_location: 'Driver Location',
    round: 'Round',
    created_at: 'Created At',
    accepted: 'Accepted',
    received: 'Received',
    hide: 'Hide',
    show: 'Show',
    driving_route: 'Driving Route',
    estimation_logs: 'Estimation Logs',
    coordinates: 'Coordinates',
    address: 'Address',
    call_location: 'Call Location',
    pick_up_point: 'Pick Up Point',
    way_point: 'Way Point',
    destination_point: 'Destination Point',
    driver_start_location: 'Driver Start Location',
    driver_current_location_car: 'Driver Current Location (Car)',
    target_point_with_index: 'Target Point #{{idx}}',
    driver_arrived_with_index: 'Driver Arrived at #{{idx}}',
    time_and_price: 'time: {{time}} price: {{price}}',
    insert_minute_interval_to_see_locations: 'Insert minute interval to see locations',
    red_line: 'Red line',
    route_from_start_driving_to_finish_job: 'Route from start driving ~ finish job',
    blue_line: 'Blue line',
    route_from_assigned_to_pick_up: 'Route from Assigned ~ Pick Up',
    orange_line: 'Orange line',
    product_search_route: 'Product search route',
    map_matching: 'Map Matching',
    estimated_distance: 'Estimated Distance',
    estimated_duration: 'Estimated Duration',
    actor: 'Actor',
    payload: 'Payload',
    result: 'Result',
    action_logs: 'Action Logs',
    latest_location: 'Latest Location',
    distance_km: 'Distance Km',
    matched_distance_km: 'Matched Distance Km',
    pick_up_time: 'Pick Up Time',
    lat: 'Lat',
    lng: 'Lng',
    chattings: 'Chattings',
    customer: 'Customer',
    success_toast: '{{content}} Successfully!',
    receipt_was_sent: 'Receipt was Sent',
    cancel_reason_type_offset: 'Cancel reason type offset',
    distance: 'Distance',
    list_title: '{{title}} List',
    total: 'Total',
    reset_filter: 'Reset Filter',
    car: 'Car',
    ratings: 'Ratings',
    total_cr: 'Total CR',
    legit_cr: 'Legit CR',
    timestamps: 'Timestamps',
    banned: 'Banned',
    documents_status: 'Documents Status',
    online: 'Online',
    offline: 'Offline',
    keyword: 'Keyword',
    approved_at: 'Approved At',
    approved_at_date: 'Approved at Date',
    add_data: 'Add {{data}}',
    go_to_driver_application: 'Go to Driver Application',
    profile_picture: 'Profile Picture',
    go_to_account: 'Go to Account',
    approved: 'Approved',
    on_probation: 'On Probation',
    unset_data: 'Unset {{data}}',
    set_data: 'Set {{data}}',
    dynamic_heatmap: 'Dynamic Heatmap',
    unban_data: 'Unban {{data}}',
    banned_reason: 'Banned Reason',
    birth: 'Birth',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    locale: 'Locale',
    license_number: 'License Number',
    auth_count: 'Auth Count',
    referral_code: 'Referral Code',
    input_referral_code: 'Input Referral Code',
    fbid: 'Fb Id',
    ad_email_agreed: 'Receive Marketing Email',
    ad_push_agreed: 'Receive Marketing Push',
    login_identifiers: 'Login Identifiers',
    identifier: 'Identifier',
    app_id: 'App Id',
    delete: 'Delete',
    migrate_login_identifiers: 'Migrate Login Identifiers',
    devices: 'Devices',
    active: 'Active',
    os: 'Os',
    app_version: 'App Version',
    imei: 'Imei',
    push_token: 'Push Token',
    last_activated_at: 'Last activated at',
    dispatch_status: 'Dispatch Status',
    on: 'On',
    off: 'Off',
    priority_dispatch: 'Priority Dispatch',
    last_location: 'Last Location',
    last_location_updated_at: 'Last Location Updated At',
    reset: 'Reset',
    active_orders: 'Activer Order(s)',
    model: 'Model',
    current_status: 'Current Status',
    idle: 'Idle',
    equipments: 'Equipments',
    delivery: 'Delivery',
    tier: 'Tier',
    balance_breakdown: 'Balance breakdown',
    channel: 'Channel',
    channel_name: 'Channel Name',
    channel_identifier: 'Channel Identifier',
    wing_transaction_id: 'Wing Transaction ID',
    reason: 'Reason',
    ban: 'Ban',
    hourly_rental_driver_ban: 'Hourly Rental Driver Ban',
    hourly_rental_cars: 'Hourly Rental Cars',
    plate: 'Plate',
    rentals: 'Rentals',
    rental_number: 'Rental Number',
    optional: 'Optional',
    comment: 'Comment',
    export_earnings: 'Export Earnings',
    month: 'Month',
    format: 'Format',
    pdf: 'Pdf',
    excel: 'Excel',
    email_to_receive_earnings: 'Email to receive earnings',
    export: 'Export',
    no: 'No',
    edit: 'Edit',
    delete_login_identifier: 'Delete Login Identifier',
    migration_login_identifier: 'Migration Login Identifier',
    set_ad_status: 'Set Auto Dispatch Status',
    success: 'Success',
    ban_driver: 'Ban Driver',
    send_mail: 'Send Mail',
    loading: 'Loading',
    download_template: 'Download Template',
    create: 'Create',
    dropzone_default_info_message_1: 'Drag & Drop the excel file containing Pages informations',
    file_parse_success: 'File parsed, total {{count}} records found.',
    uploading: 'Uploading',
    or: 'OR',
    file: 'File',
    file_name: 'FileName',
    file_size: 'FileSize',
    upload: 'Upload',
    file_upload_success: 'Created new {{created}} records, updated {{updated}} records',
    driver_type: 'Driver Type',
    driver_status: 'Driver Status',
    region_and_city: 'region/city',
    edit_driver: 'Edit Driver',
    first_name: 'First Name',
    last_name: 'Last Name',
    password: 'Password',
    search_by_data: 'Search By {{data}}',
    select_by_data: 'Select By {{data}}',
    transaction: 'Transaction',
    account_uuid: 'Account UUID',
    account_number: 'Account Number',
    account_model: 'Account Model',
    date_start: 'Start Date',
    date_end: 'End Date',
    bulk: 'bulk',
    user: 'user',
    pm: 'PM',
    prev: 'Prev',
    next: 'Next',
    bulk_process_by_excel: 'Bulk Process By Excel',
    toggle_select_all: 'Toggle Select All',
    bulk_paid: 'Bulk Paid',
    bulk_approve: 'Bulk Approve',
    required: 'Required',
    select_region_first: 'Select Region First',
    create_new_data: 'Create New {{data}}',
    create_bulk_data: 'Create Bulk {{data}}',
    cpf_create_new_data: 'Create New {{data}} (CPF)',
    cpf_create_bulk_data: 'Create Bulk {{data}} (CPF)',
    fraud_type_string: {
      fraud: 'FRAUD',
      non_fraud: 'NON FRAUD',
      not_checked: 'NOT CHECKED',
    },
    copied: 'Copied',
    bulk_approve_success: 'Bulk Approve Success!',
    please_select_transactions: 'Please select transactions',
    bulk_paid_confirm: 'Are you Sure?\nSome problems will be occurred.',
    pending: 'Pending',
    replayed: 'Replayed',
    resolve_pending_requested: 'Resolve Pending Requested',
    rejected: 'Rejected',
    approve: 'Approve',
    restored: 'Restored',
    change_balance_item_to_cash: 'Change Balance Item to Cash',
    cash_out_retry: 'Cash out Retry',
    detail_data: '{{data}} Detail',
    replay: 'Replay',
    account: 'Account',
    go_to_data: 'Go to {{data}}',
    cash_out_request_logs: 'Cash Out Request Logs',
    msg_id: 'msgId',
    paid: 'Paid',
    change_to_cash_restore: 'Change To Cash & Restore (Required: Reason)',
    cash_out_transaction_prompt: 'Please Enter CashOut Local Amount.',
    cash_out_transaction_alert: 'Invalid CashOut amount.',
    resolve_pending: 'Resolve Pending',
    show_next_item: 'Show Next Item',
    reject_required_reason: 'Reject (Required: Reason)',
    fail_required_reason: 'Fail (Required: Reason)',
    restore_required_reason: 'Restore (Required: Reason)',
    submit: 'Submit',
    new_balance_item: 'New Balance Item',
    issue_new_transaction: 'Issue New Transaction',
    return_to_list: 'Return to List',
    owner_model: 'Owner Model',
    label_transaction_description: 'Description (will be displayed on mobile apps)',
    update_bulk_transaction: 'Upload Bulk Transaction',
    bulk_transaction: 'Bulk Transaction',
    tps: 'Tps',
    is_failed: 'is Failed',
    cancel_upload_bulk_transaction: 'Cancel Upload Bulk Transaction',
    bulk_process: 'Bulk Process',
    drag_drop_excel_file: 'Drag & Drop the excel file containing Pages informations',
    file_parsed_total_found: 'File parsed, total {{count}} records found.',
    select_file: 'Select file',
    created_and_updated_records: 'Created new {{created}} records, updated {{updated}} records',
    request_command: 'requestCommand',
    customer_reference: 'customerReference',
    response_message: 'responseMessage',
    response_body: 'responseBody',
    response_code: 'responseCode',
    paid_at: 'paid At',
    description: 'Description',
    invalid_worksheet: 'Invalid WorkSheet',
    user_uuid: 'User UUID',
    filter_by_data: 'Filter By {{data}}',
    user_model: 'User Model',
    balance: 'balance',
    liability: 'liability',
    reserves: 'reserves',
    copy: 'Copy',
    verify_currenct_account_bank_info: 'Verify Current Account Bank Info',
    replay_current_account: 'Replay Current Account',
    replay_deposit_account: 'Replay Deposit Account',
    detail: 'detail',
    bank_info: 'Bank Info',
    verified: 'Verified',
    bank_name: 'Bank Name',
    bank_account_name: 'Bank Account Name',
    bank_account_number: 'Bank Account Number',
    bank_branch: 'Bank Branch',
    bank_book_photo: 'Bank Book Photo',
    wallet_id: 'Wallet ID',
    current_account_events: 'Current Account Events',
    event_type: 'Event Type',
    event_id: 'Event Id',
    contract_id: 'Contract Id',
    total_balance: 'Total Balance',
    open_deposit_account_list: 'Open Deposit Account List',
    user_id: 'User Id',
    last_event_id: 'Last Event Id',
    deposit_transaction: 'Deposit Transaction',
    deposit_transaction_id: 'Deposit Transaction Id',
    transaction_type: 'Transaction Type',
    transaction_channel: 'Transaction Channel',
    transaction_status: 'Transaction Status',
    date: 'Date',
    approve_deposit_transaction: 'Approve Deposit Transaction',
    restore_deposit_transaction: 'Restore Deposit Transaction',
    reject_deposit_transaction: 'Reject Deposit Transaction',
    fail_deposit_transaction: 'Fail Deposit Transaction',
    restore_reason_required: 'Restore (Reason: Required)',
    reject_reason_required: 'Reject (Reason: Required)',
    fail_reason_required: 'Fail (Reason: Required)',
    deposit_transaction_events: 'Deposit Transaction Events',
    deposit_account_events: 'Deposit Account Events',
    shb_wallet: 'Shb Wallet',
    account_type: 'Account Type',
    shb_balance: 'Shb Balance',
    current_account: 'Current Account',
    alipay_transaction: 'Alipay Transaction',
    search_data: 'Search {{data}}',
    region_code: 'Region Code',
    out_trade_no: 'Out Trade No',
    total_fee: 'Total Fee',
    application_fee: 'Application Fee',
    alipay_trans_id: 'Alipay Trans Id',
    buyer_login_id: 'Buyer Login Id',
    buyer_user_id: 'Buyer User Id',
    checking: 'Checking',
    customer_pay_fee: 'Customer Pay Fee',
    finished: 'Finished',
    pay_time: 'Pay Time',
    pic_url: 'Pic URL',
    ready: 'Ready',
    total_fee_cny: 'Total Fee CNY',
    cancel: 'Cancel',
    force_refund: 'Force Refund',
    refund: 'Refund',
    check_finished: 'Check Finished',
    updated_at: 'Updated At',
    pao_transaction: 'Pao Transaction',
    stan: 'Stan',
    txn_identifier: 'Txn Identifier',
    user_data: 'User Data',
    momopay_transaction: 'Momopay Transaction',
    momo_trans_id: 'Momo Trans Id',
    qr_image: 'QR Image',
    pipay_transaction: 'Pipay Transaction',
    cmcb_transaction: 'Cmcb Transaction',
    shb_request_history: 'Shb Request History',
    owner_uuid: 'Owner UUID',
    owner_wallet_id: 'Owner Wallet ID',
    opposite_uuid: 'Opposite UUID',
    opposite_wallet_id: 'Opposite Wallet ID',
    tx_id: 'Tx Id',
    raw_response_code: 'Raw Response Code',
    latency_second: 'latency (second)',
    revert: 'Revert',
    vehicle_type: 'Vehicle Type',
    transfer_type: 'Transfer Type',
    transfer_target: 'Transfer Target',
    raw_response: 'Raw Response',
    launched_at: 'Launched At',
    responded_at: 'Responded At',
    duplicate: 'Duplicate',
    verify: 'Verify',
    passed: 'Passed',
    url: 'Url',
    enabled: 'Enabled',
    new: 'New',
    title: 'Title',
    new_data: 'New {{data}}',
    create_data: 'Create {{data}}',
    inbox_items: 'Inbox Items',
    item_id: 'Item Id',
    item_type: 'Item Type',
    target_id: 'Target Id',
    target_group: 'Target Group',
    target: 'Target',
    inbox_item: 'Inbox Item',
    read: 'Read',
    images: 'Images',
    html: 'HTML',
    image: 'Image',
    banner: 'Banner',
    all_banner_type: 'All Banner Type',
    display_identifier: 'Display Identifier',
    display_priority: 'Display Priority',
    start: 'Start',
    end: 'End',
    content: 'Content',
    thumbnail_content: 'Thumbnail Content',
    detail_content: 'Detail Content',
    detail_content_type: 'Detail Content Type',
    en: 'En',
    zh_hans: 'Zh Hans',
    km: 'Km',
    vi: 'Vi',
    regions: 'Regions',
    super: 'Super',
    roles: 'Roles',
    permissions: 'Permissions',
    admin: 'Admin',
    admin_fee: 'Admin Fee',
    write: 'Write',
    account_access_password: 'Account Access Password',
    confirm_password: 'Confirm Password',
    promotion: 'Promotion',
    coupon: 'Coupon',
    discount_type: 'Discount Type',
    implicit: 'Implicit',
    expired: 'Expired',
    code: 'Code',
    valid_from: 'Valid From',
    valid_until: 'Valid Until',
    valid_period: 'Valid Period',
    coupon_valid_from: 'Coupon Valid From',
    coupon_valid_until: 'Coupon Valid Until',
    issuer: 'Issuer',
    receipt_email: 'Receipt Email',
    code_publishable: 'Code Publishable',
    car_types: 'Car Types',
    max_amount: 'Max Amount',
    payment_methods: 'Payment Methods',
    hired_only: 'Hired Only',
    auto_apply: 'Auto Apply',
    price_excess_policy: 'Price Excess Policy',
    count_check_policy: 'Count Check Policy',
    signup: 'Signup',
    loyalty: 'Loyalty',
    ride_count: 'Ride Count',
    issued_count: 'Issued Count',
    used_count: 'Used Count',
    day_limit: 'Day Limit',
    total_count: 'Total Count',
    count_limit: 'Count Limit',
    issue_count: 'Issue Count',
    polygon_ids: 'Polygon Ids',
    times: 'Times',
    valid_until_policy: 'Valid Until Policy',
    days: 'Days',
    publish_coupon_to_user: 'Publish Coupon To User',
    publish_coupon: 'Publish Coupon',
    cities: 'Cities',
    delay_day: 'Delay Day',
    delay_hour: 'Delay Hour',
    export_type: 'Export Type',
    range_day: 'Range Day',
    range_hour: 'Range Hour',
    schedule: 'Schedule',
    available: 'Available',
    net_surge_multiplier: 'Net Surge Multiplier',
    export_transaction: 'Export Transaction',
    exporting: 'Exporting',
    account_id: 'Account Id',
    coupon_updated_at: 'Updated At',
    coupon_created_at: 'Created At',
    simplified_address: 'Simplified Address',
    transaction_events: 'Transaction Events',
    account_events: 'Account Events',
    reject_code: 'Reject Code',
    rider_point_payment_amount: 'Rider Point Payment Amount',
    rider_point_transaction_id: 'Rider Point Transaction Id',
    driver_point_payment_amount: 'Driver Point Payment Amount',
    driver_point_transaction_id: 'Driver Point Transaction Id',
    promo_code: 'Promo Code',
    image_url: 'Image Url',
    min_ride_finished_count: 'Min Ride Finished Count',
    max_ride_finished_count: 'Max Ride Finished Count',
    update_coupon_status: 'Update Coupon Status',
    coupon_log: 'Coupon Log',
    coupon_id: 'Coupon Id',
    original_price: 'Original Price',
    discounted_price: 'Discounted Price',
    action: 'Action',
    error_info: 'Error Info',
    bulk_create: 'Bulk Create',
    user_ids: 'User Ids',
    required_field: 'Required Field',
    code_prefix: 'Code Prefix',
    code_length: 'Code Length',
    character_enabled: 'Character Enabled',
    selected_times: 'Selected Times',
    add: 'Add',
    times_preset: 'Times Preset',
    morning_rush_hour: 'Morning Rush Hour',
    evening_rush_hour: 'Evening Rush Hour',
    weekend: 'Weekend',
    all_times: 'All Times',
    create_coupon: 'Create Coupon',
    count: 'Count',
    coupon_log_created_at: 'Created At',
    rider_cancellation_fee: 'Rider Cancellation Fee',
    rider_cancellation_reward: 'Rider Cancellation Reward',
    driver_cancellation_fee: 'Driver Cancellation Fee',
    driver_cancellation_reward: 'Driver Cancellation Reward',
    driver_waiting_time_reward: 'Driver Waiting Time Reward',
    rider_waiting_time_fee: 'Rider Waiting Time Fee',
    driver_penalty_fee: 'Driver Penalty Fee',
    from_ride_id: 'From Ride Id',
    paid_ride_id: 'Paid Ride Id',
    reservation_time: 'Reservation Time',
    is_starting_soon: 'Is Starting Soon',
    target_type: 'Target Type',
    polygon_geofence_policy: 'Polygon Geo-fence Policy',
    onetime_code: 'Onetime Code',
    category_type: 'Category Type',
    create_transaction_limit: 'Create Transaction Limit',
    statusCode: 'Status Code',
    create_transaction_weekly_limit: 'Create Transaction Weekly Limit',
    loop: 'Loop',
    prefilling_agreed: 'Pre-filling Agreement',
    aba_transaction_id: 'ABA Transaction ID',
    destination_dispatch: 'Destination Dispatch',
    alternative_email: 'Alternative Email',
    bank_reference: 'Bank Reference',
    external_transaction_id: 'External Transaction ID',
    age_group: 'Age Group',
    reserve_per: 'Reserve %',
    cpf_contribution_status: 'CPF Contribution Status',
    transaction_reversal: 'Transaction Reversal',
    month_of_earnings: 'Month Of Earnings',
    total_amount: 'Total Amount',
    total_reserves: 'Total Resevers',
    available_for_withdrawal_in_twelve_hour: 'Available For Withdrawl In 12 hours',
    available_for_now: 'Available For Now',
    rider_vat: 'Rider VAT',
    income_tax: 'Income Tax',
    constants: {
      region_string: {
        singapore: 'Singapore',
        vietnam: 'Vietnam',
        cambodia: 'Cambodia',
        korea: 'Korea',
        ethiopia: 'Ethiopia',
        indonesia: 'Indonesia',
        hongkong: 'Hongkong',
        thailand: 'Thailand',
        global: 'Global',
      },
      fare_type_app_metered_fare: 'App Metered Fare',
      fare_type_car_metered_fare: 'Car Metered Fare',
      fare_type_fixed_fare: 'Fixed Fare',
      car_type_sedan: 'Economy',
      car_type_van: 'Economy large',
      car_type_business_van: 'Premium large',
      car_type_middle_sedan: 'Middle Sedan (Deprecated)',
      car_type_black_sedan: 'Premium',
      car_type_business_bus: 'Business Bus (Deprecated)',
      car_type_mini_bus: 'Mini Bus',
      car_type_suv: 'SUV (Deprecated)',
      car_type_compact_suv: 'Compact SUV (Deprecated)',
      car_type_tuktuk: 'TUKTUK',
      car_type_bike: 'BIKE',
      car_type_taxi_standard: 'STANDARD TAXI',
      car_type_taxi_standard_large: 'STANDARD LARGE TAXI',
      car_type_taxi_premium: 'PREMIUM TAXI',
      car_type_taxi_premium_large: 'PREMIUM LARGE TAXI',
      car_type_delivery_van: 'DELIVERY VAN',
      car_type_delivery_bike: 'DELIVERY BIKE',
      car_type_delivery_car: 'DELIVERY CAR',
      rental_status_waitpay: 'WAITPAY',
      rental_status_not_matched: 'NOT_MATCHED',
      rental_status_matched: 'MATCHED',
      rental_status_pending: 'PENDING',
      rental_status_confirmed: 'CONFIRMED',
      rental_status_assigned: 'Assigned',
      rental_status_pickup: 'Pickup',
      rental_status_pickup_arrived: 'Pickup Arrived',
      rental_status_inuse: 'INUSE',
      rental_status_inuse_with_cancel_pending: 'INUSE WITH CANCEL PENDING',
      rental_status_finished: 'FINISHED',
      rental_status_user_canceled: 'USER_CANCELED',
      rental_status_company_canceled: 'COMPANY_CANCELED',
      rental_status_driver_canceled: 'DRIVER_CANCELED',
      rental_status_expired: 'EXPIRED',
      rental_status_error_payment: 'ERROR_PAYMENT',
      rental_status_error: 'ERROR',
      pay_status_pending: 'Pending',
      pay_status_holding: 'Holding',
      pay_status_paying: 'Paying',
      pay_status_paid: 'Paid',
      pay_status_refund: 'Refunded',
      pay_status_canceled: 'Canceled',
      pay_status_error_payment: 'Error Payment',
      pay_status_error_unpaid: 'Error Unpaid',
      pay_status_partial_error_payment: 'Partial Error Payment',
      payment_method_creditcard: 'Credit Card',
      payment_method_cash: 'Cash',
      payment_method_alipay: 'Alipay',
      payment_method_pipay: 'Pipay',
      payment_method_pipay_p: 'Pipay Post',
      payment_method_alipay_p: 'Alipay Post',
      payment_method_momo_pay_p: 'MoMo pay',
      payment_method_pao: 'QR Pay Nets (legacy)',
      payment_method_cmcb: 'CMCB',
      payment_method_tadapay: 'TADA Wallet',
      payment_method_corporate: 'Corporate',
      payment_method_wing: 'Wing',
      payment_method_netsqr: 'NETSQR',
      payment_method_ocbc_pao: 'OCBC Pay Anyone',
      payment_method_delivery: 'Delivery',
      product_type_chauffeur: 'CHAUFFEUR',
      product_type_airport_pickup: 'AIRPORT_PICKUP',
      product_type_hourly: 'HOURLY',
      product_type_daily: 'DAILY',
      product_type_airport_sending: 'AIRPORT_SENDING',
      product_type_api: 'API_PRODUCT',
      product_type_airport_both: 'AIRPORT_BOTH',
      product_type_ride_hailing: 'RIDE_HAILING',
      product_type_taxi_hailing: 'TAXI_HAILING',
      product_type_hailing: 'COMBINED_HAILING',
      product_type_delivery: 'DELIVERY',
      street_hailing: 'Street Hailing',
      tada_na: 'Tada Ride',
      fraud_type_drop_off_mismatch_with_dest: 'DROP_OFF_MISMATCH_WITH_DEST',
      fraud_type_too_early_finished: 'TOO_EARLY_FINISHED',
      fraud_type_too_many_cancel_after_match: 'TOO_MANY_CANCEL_AFTER_MATCH',
      fraud_type_same_driver_rider: 'SAME_DRIVER_RIDER',
      fraud_type_same_imei: 'SAME_IMEI',
      fraud_type_too_many_finished_trips: 'TOO_MANY_FINISHED_TRIPS',
      fraud_type_same_dest_trip: 'SAME_DEST_TRIP',
      fraud_type_too_close_between_pickup_and_assigned_point: 'TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT',
      fraud_type_too_many_finished_trips_for_same_imei: 'TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI',
      fraud_type_same_driver_rider_phone_number: 'SAME_DRIVER_RIDER_PHONE_NUMBER',
      fraud_type_complete_without_pick_up: 'COMPLETE_WITHOUT_PICK_UP',
      date_preset_type_this_month: 'This Month',
      date_preset_type_this_week: 'This Week',
      date_preset_type_last_month: 'Last Month',
      date_preset_type_last_week: 'Last Week',
      date_preset_type_today: 'Today',
      ride_type_app_meter_ride: 'App Meter Ride',
      ride_type_car_meter_ride: 'Car Meter Ride',
      ride_type_base_ride: 'Base Ride',
      ride_type_advanced_booking: 'Advanced Booking',
      ride_type_delivery: 'Delivery',
      auto_dispatch_sd: 'System Dispatch',
      auto_dispatch_ad: 'Auto Dispatch',
      auto_dispatch_driver_ping: 'Driver Ping',
      auto_dispatch_mdt: 'MDT',
      available_payment_method_cash: 'Cash',
      available_payment_method_pao: 'QR Pay Nets (legacy)',
      available_payment_method_alipay_p: 'Alipay Post',
      available_payment_method_pipay_p: 'Pipay Post',
      available_payment_method_momopay_p: 'MoMo pay',
      available_payment_method_creditcard: 'Creditcard',
      available_payment_method_tadapay: 'TADA Wallet',
      available_payment_method_corporate: 'Corporate',
      available_payment_method_cmcb: 'CMCB',
      available_payment_method_delivery: 'Delivery',
      available_payment_method_netsqr: 'NETSQR',
      available_payment_method_ocbc_pao: 'OCBC Pay Anyone',
      car_type_remorque: 'Remorque',
      tada_concierge: 'Tada Concierge',
      available_payment_method_aba_aof: 'ABA AOF',
      payment_method_aba_aof: 'ABA AOF',
      car_type_premium_bike: 'Premium Bike',
      ride_option_type_smart_call: 'Smart Call',
      ride_option_type_car_seat: 'Car Seat',
      ride_option_type_booster_seat: 'Booster Seat',
      additional_fare_type_toll_fee: 'Toll Fee',
      additional_fare_type_additional_stop: 'Additional Stop',
      additional_fare_type_baby_seat: 'Baby Seat',
      additional_fare_type_booking_fee: 'Booking Fee',
      additional_fare_type_airport_fee: 'Airport Fee',
      additional_fare_type_waiting_fee: 'Waiting Fee',
      additional_fare_type_booster_seat: 'Booster Seat',
      additional_fare_type_gantry_fee: 'Gantry Fee',
      additional_fare_type_etc: 'Etc',
      additional_fare_type_none: 'None',
      charge_status_pending: 'Pending',
      charge_status_uncaptured: 'Uncaptured',
      charge_status_succeeded: 'Successded',
      charge_status_refunded: 'Refunded',
      charge_status_error: 'Error',
      driver_type_ph: 'Private Hire',
      driver_type_ph_parttime: 'Private Hire(Part timer)',
      driver_type_taxi: 'Taxi',
      driver_type_tuktuk: 'Tuk Tuk',
      driver_type_bike: 'Bike',
      driver_type_hourly_rental: 'Hourly Rental',
      driver_type_delivery: 'Delivery',
      application_status_passed: 'PASSED',
      application_status_rejected: 'REJECTED',
      application_status_change_requested: 'CHANGE_REQUESTED',
      application_status_on_probation: 'ON PROBATION',
      application_status_idle: 'IDLE',
      application_status_in_review: 'IN REVIEW',
      application_status_approved: 'APPROVED',
      driver_status_idle: 'IDLE',
      driver_status_approved: 'APPROVED',
      driver_status_resigned: 'RESIGNED',
      driver_status_banned: 'BANNED',
      driver_status_erased: 'ERASED',
      force_dispatch_status_return: 'Return',
      force_dispatch_status_return_arrived: 'Return Arrived',
      force_dispatch_status_return_warning: 'Return Warning',
      force_dispatch_status_pickup_warning: 'Pickup Warning',
      force_dispatch_status_inuse_warning: 'Inuse Warning',
      force_dispatch_status_idle: 'Idle',
      force_dispatch_status_pickup: 'Pickup',
      force_dispatch_status_pickup_arrived: 'Pickup Arrived',
      rental_stauts_inuse: 'In Use',
      transaction_channel_rental: 'Rental',
      transaction_channel_ride: 'Ride',
      transaction_channel_bank: 'Bank',
      transaction_channel_wing: 'Wing',
      transaction_channel_pipay: 'Pipay',
      transaction_channel_sbc: 'SBC',
      transaction_channel_admin: 'Admin',
      transaction_channel_ride_street_hailing: 'Street Hail',
      transaction_channel_delivery: 'Delivery',
      transaction_channel_aba: 'ABA',
      transaction_channel_delivery_hailing: 'Delivery Hailing',
      transaction_type_ride: 'RIDE',
      transaction_type_top_up: 'TOP_UP',
      transaction_type_cash_out: 'CASH_OUT',
      transaction_type_deduct: 'DEDUCT',
      transaction_type_pay_out: 'CPF_PAYOUT',
      transaction_account_model: {
        driver: 'Driver',
        rider: 'Rider',
        delivery_vendor: 'DeliveryVendor',
        delivery_agent: 'DeliveryAgent',
      },
      transaction_status: {
        default: {
          pending: 'Under Review / Pending',
          approved: 'Approved / Processed',
          rejected: 'Rejected',
          failed: 'Failed',
        },
        ride: {
          pending: 'Under Review',
          approved: 'Approved',
          rejected: 'Rejected',
          failed: 'Failed',
        },
        topup: {
          pending: 'Pending',
          approved: 'Top Up',
          rejected: 'Rejected',
          failed: 'Failed',
        },
        cash_out: {
          pending: 'Pending',
          approved: 'PAID',
          rejected: 'Rejected',
          failed: 'Failed',
        },
        deduct: {
          pending: 'Pending',
          approved: 'Deducted',
          rejected: 'Rejected',
          failed: 'Failed',
        },
        pay_out: {
          pending: 'Pending',
          approved: 'PAID',
          rejected: 'Rejected',
          failed: 'Failed',
        },
      },
      transaction_payment_method: {
        cash: 'CASH',
        pao: 'PAO',
        alipay_p: 'ALIPAY_P',
        pipay_p: 'PIPAY_P',
        momopay_p: 'MOMOPAY_P',
        creditcard: 'CREDITCARD',
        tadapay: 'TADAPAY',
        corporatepay: 'CORPORATE',
        cmcb: 'CMCB',
        delivery: 'DELIVERY',
        aba: 'ABA',
        current_balance: 'CURRENT_BALANCE',
        netsqr: 'NETSQR',
        ocbc_pao: 'OCBC_PAO',
        aba_aof: 'ABA AOF',
        corporate_card: 'Corporate Card',
      },
      transaction_fraud_type: {
        not_checked: 'NOT_CHECKED',
        non_fraud: 'NON_FRAUD',
        fraud: 'FRAUD',
      },
      new_cash_out_status: {
        pending: 'PENDING',
        paid: 'PAID',
        canceled: 'CANCELED',
        rejected: 'REJECTED',
        failed: 'FAILED',
      },
      transaction_currency: {
        usd: 'USD',
        sgd: 'SGD',
        vnd: 'VND',
        khr: 'KHR',
        etb: 'ETB',
        idr: 'IDR',
        hkd: 'HKD',
        krw: 'KRW',
        thb: 'THB',
      },
      user_model: {
        driver: 'Driver',
        rider: 'Rider',
        deliveryvendor: 'DeliveryVendor',
        delivery_agent: 'DeliveryAgent',
        corp_manager: 'CorpManager',
      },
      account_detail_page_type: {
        current_account: 'Current Account',
        deposit_account: 'Deposit Account',
      },
      shb_request_history_account_type: {
        user: 'User',
        driver: 'Driver',
      },
      shb_request_history_type: {
        payment: 'Payment',
        payment_capture: 'Payment Capture',
        transfer: 'Transfer',
        charge: 'Charge',
        charge_trip_fare_admin: 'Charge Trip Fare Admin',
        charge_discount_fare_admin: 'Charge Discount Fare Admin',
        payment_cancel: 'Payment Cancel',
        payment_time_out_error: 'Payment Timeout Error',
        transfer_cancel: 'Transfer Cancel',
        charge_cancel: 'Charge Cancel',
        charge_trip_fare_admin_cacnel: 'Charge Trip Fare Admin Cancel',
        charge_discount_fare_admin_cancel: 'Charge Discount Fare Admin Cancel',
      },
      always_displayed: 'Always Displayed',
      coupon_from_promo_issued: 'Coupon from Promo issued',
      discount_type: {
        percent: 'Percent',
        fixed_amount: 'Fixed Amount',
        target_price: 'Target Price',
      },
      count_check_policy: {
        publish: 'Publish',
        used: 'Used',
      },
      city: {
        singapore: 'Singapore',
        hochiminh: 'Hochiminh',
        hanoi: 'Hanoi',
        phnompenh: 'Phnompenh',
        siemreap: 'Siemreap',
        bangkok: 'Bangkok',
        seoul: 'Seoul',
        addis_ababa: 'Addis Ababa',
        jakarta: 'Jakarta',
        hong_kong: 'Hong Kong',
        global: 'Global',
        sihanouk_ville: 'Sihanouk Ville',
        kampot_and_kep: 'Kampot & Kep city',
      },
      promo_valid_until_policy: {
        inherit: 'Inherit',
        after_issue: 'After Issue',
        fixed: 'Fixed',
      },
      product_type_student_fleet: 'Student Fleet',
      instant_cash_out_reject_code: {
        others: 'Others',
        tada_balance_insufficient: 'Tada Balance Insufficient',
      },
      cash_out_request_log_type: {
        unknown: 'Unknown',
        instant_cashout: 'Instant Cashout',
        sync_sub: 'Sync Shb',
      },
      coupon_status: {
        ready: 'Ready',
        used: 'Used',
        canceled: 'Canceled',
        expired: 'Expired',
      },
      price_excess_policy: {
        apply: 'Apply',
        reject: 'Reject',
      },
      target_type_ride: 'Ride',
      target_type_delivery: 'Delivery Ride',
      car_type_onion_tuktuk: 'Onion T1',
      car_type_business_sedan: 'Business Sedan (Deprecated)',
      car_type_bus: 'Bus',
      car_type_foot: 'Foot (Deprecated)',
      fraud_type_too_far_away_between_pickup_and_call_point: 'TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT',
      fraud_type_driver_ignore_ride: 'DRIVER_IGNORE_RIDE',
      fraud_type_same_driver_dider_pair_strict: 'SAME_DRIVER_RIDER_PAIR_STRICT',
      fraud_type_system_finished_trip: 'SYSTEM_FINISHED_TRIP',
      fraud_type_arrived_too_far_away: 'ARRIVED_TOO_FAR_AWAY',
      driver_status_expired: 'EXPIRED',
      product_type_ev: 'EV',
      ride_option_type_change_of_destination: 'Change Of Destination',
      ride_option_type_fuel_surcharge: 'Fuel Surcharge',
      ride_option_type_waiting_fee: 'Waiting Fee',
      ride_option_type_airport_fee: 'Airport Fee',
      auto_dispatch_driver_destination: 'Driver Destination',
      payment_method_corporate_card: 'Corporate Card',
      available_payment_method_corporate_card: 'Corporate Card',
      car_type_win_bike: 'Win Bike',
      available_payment_method_aba: 'ABA',
      available_payment_method_wing: 'Wing',
      available_payment_method_current_balance: 'Current Balance',
      rental_status_accident_canceled: 'ACCIDENT_CANCELED',
      additional_fare_type_change_of_destination: 'Change of Destination',
      additional_fare_type_harbour_crossing_fee: 'Harbour Crossing Fee',
      no_waiting: 'No waiting area, be ready at the pickup point',
    },
    error: {
      there_is_no_estimation_logs: 'There is no Estimation Logs',
      there_is_no_action_logs: 'There is no Action Logs',
      not_enough_gps_logs: 'There are not enough GPS logs to draw the {{line}}.',
      not_assigned: 'Not assigned',
      none: 'None',
      null: 'NULL',
      empty_data: 'Empty Data',
      there_is_no_record: 'There is no Record',
      no_devices: 'No Devices.',
      not_updated_for_a_while: 'Not Updated for a While',
      no_active_order: 'No Active Order',
      there_is_no_status: 'There is no Status.',
      insert_correct_email: 'Insert Correct Email',
      file_parse_error: 'File Parse Error',
      error_occurred: 'Error occurred',
      required_data: '{{data}} is Required.',
      city_and_region_not_matched: 'The selected city is not in the region',
      check_your_field: 'Please check your {{field}} field',
      select_region_first: 'please select region first',
      logs_available_for_one_year: 'Logs are available for 1 year',
      amount_should_be_positive: 'Amount should be positive.',
      cell_required: 'Cell: {{address}} is Required.',
      cell_invalid: 'Cell: {{address}} is Invalid.',
      no_data_in_excel: "There's no data in excel file",
      invalid_image: 'Invalid Image',
      image_error: 'Image Error',
      is_wrong: '{{data}} is Wrong.',
      wrong_url: 'Wrong Url',
      please_select_detail_content_type: 'Please Select Detail Content Type.',
      only_img_file_format_allowed: 'Only .jpg, .jpeg, .png file formats are allowed',
      no_record: 'No Record',
    },
    alert: {
      reset_driver_status: 'Are you sure to reset status of this driver?',
      default: 'Are you sure?',
      delete_login_identifier: 'Are you sure to delete this login identifier?',
      verify_bank_info: 'Are you sure?\nPending cashouts will be launched.',
      approve_transaction_confirm: 'Are you sure?\nRestoring approval action will cause several problems.',
      change_cash_transaction_confirm: 'Are you sure?\nAfter Changed to Cash, status will be restored to Under Review.',
      force_reset_driver_status:
        'This button should only be used if driver has NO ONGOING RIDE but has active order. Please do not use this unless you have to.',
    },
    paymentTransaction: {
      success_toast: '{{data}} Successfully!',
      update_payment_transaction: 'Update Payment Transaction',
      refund_prompt: "Please confirm the transaction's amount for refunding",
      refund_alert: 'Input amount is not matching.',
      refund: 'Refund',
      pay_with_existing_card_confirm: 'Are you sure to retry this payment with existing card info',
      pay_with_existing_card: 'Retry to Pay',
      cancel_confirm: 'Are you sure to cancel this payment transaction?',
      cancel: 'Cancel',
      fail_confirm: 'Are you sure to make this payment transaction failed?',
      fail: 'Fail',
      launch: 'Launch',
      launch_confirm: 'Are you sure to launch this payment transaction?',
    },
  },
};

import moment from 'moment';
import { Link } from 'react-router-dom';

import { PATH, REGION } from '@/assets/constants';
import CardTemplate from '@/components/CardTemplate';
import HeaderContent from '@/components/HeaderContent';
import Null from '@/components/Null';
import Paginator from '@/components/Paginator';
import Table from '@/components/Table';
import useQuery from '@/hooks/useQueryString';
import { useCpfContributionTableList } from '@/pages/CpfContributionTable/hooks';
import ListFooterContent from '@/pages/CpfContributionTable/parts/ListFooterContent';
import { CpfContributionTableListQueryInterface } from '@/pages/CpfContributionTable/type';
import { formatDateWithRegion } from '@/utils/etc';

function List() {
  const initialState: CpfContributionTableListQueryInterface = {
    page: 1,
    size: 20,
  };
  const { query } = useQuery<CpfContributionTableListQueryInterface>({
    initialState,
    searchConfig: {
      types: {
        page: 'number',
        size: 'number',
      },
    },
  });

  const {
    format: { data, page, totalCount },
  } = useCpfContributionTableList(query);

  return (
    <CardTemplate headerChildren={<HeaderContent title={`CPF Contribution Table (total: #${totalCount})`} />} footerChildren={<ListFooterContent />}>
      <Table
        columns={[
          {
            key: 'id',
            title: 'id',
            renderContent: ({ id }) => <Link to={`${PATH.CpfContributionTable}/${id}`}>{id}</Link>,
          },
          {
            key: 'title',
            title: 'Name',
          },
          {
            key: 'forOptIn',
            title: 'Type',
            renderContent: ({ forOptIn }) => (forOptIn ? 'Mandatory/Opted In' : 'Not Opted In'),
          },
          {
            key: 'period',
            title: 'Period',
            renderContent: ({ validFrom, validUntil }) => (
              <div>
                <p>{formatDateWithRegion({ date: moment(validFrom), region: REGION.SG, format: 'DD-MM-YYYY HH:mm Z', keepLocalTime: false })}</p>
                <p>
                  {validUntil ? (
                    formatDateWithRegion({ date: moment(validUntil), region: REGION.SG, format: 'DD-MM-YYYY HH:mm Z', keepLocalTime: false })
                  ) : (
                    <Null />
                  )}
                </p>
              </div>
            ),
          },
        ]}
        data={data}
      />
      <Paginator total={page.total} current={page.current} />
    </CardTemplate>
  );
}

export default List;

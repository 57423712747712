import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RiderList" */ '@/pages/Rider/List'));
const PublicList = lazy(() => import(/* webpackChunkName: "RiderPublicList" */ '@/pages/Rider/PublicList'));
const Detail = lazy(() => import(/* webpackChunkName: "RiderDetail" */ '@/pages/Rider/Detail'));
const BulkMappingMobile = lazy(() => import(/* webpackChunkName: "RiderDetail" */ '@/pages/Rider/BulkMappingMobile'));

export const riderRoute: RouteProps[] = [
  {
    permission: ActionType.LIST,
    path: PATH.Rider,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Rider}/restrict`,
    component: PublicList,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Rider}/bulk-mapping-mobile`,
    component: BulkMappingMobile,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Rider}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Rider}/restrict/:id`,
    component: Detail,
    exact: true,
  },
];

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RestrictedAreasList" */ '@/pages/RestrictedAreas/List'));
const Detail = lazy(() => import(/* webpackChunkName: "RestrictedAreasDetail" */ '@/pages/RestrictedAreas/Detail'));
const New = lazy(() => import(/* webpackChunkName: "RestrictedAreasNew" */ '@/pages/RestrictedAreas/New'));
const Edit = lazy(() => import(/* webpackChunkName: "RestrictedAreasEdit" */ '@/pages/RestrictedAreas/Edit'));

export const restrictedAreasRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.RestrictedAreas,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.RestrictedAreas}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RestrictedAreas}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.RestrictedAreas}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

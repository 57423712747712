import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createDriverTutorialFailure,
  createDriverTutorialRequest,
  createDriverTutorialSuccess,
  fetchDownloadDriverTutorialTemplateFailure,
  fetchDownloadDriverTutorialTemplateRequest,
  fetchDownloadDriverTutorialTemplateSuccess,
  updateDriverTutorialFailure,
  updateDriverTutorialRequest,
  updateDriverTutorialSuccess,
  uploadDriverTutorialTargetDriversFailure,
  uploadDriverTutorialTargetDriversRequest,
  uploadDriverTutorialTargetDriversSuccess,
} from '@/svc/driver-tutorial-svc/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* createDriverTutorial({ payload }: ReturnType<typeof createDriverTutorialRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/drivertutorial/admin/v2/tutorials', { data: payload });

    if (response.ok) {
      yield put(createDriverTutorialSuccess(response.data));
    } else {
      yield put(createDriverTutorialFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDriverTutorialFailure((e as Error).message));
  }
}

function* updateDriverTutorial({ payload }: ReturnType<typeof updateDriverTutorialRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/drivertutorial/admin/v2/tutorials/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateDriverTutorialSuccess(response.data));
    } else {
      yield put(updateDriverTutorialFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDriverTutorialFailure((e as Error).message));
  }
}

function* fetchDownloadDriverTutorialTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/drivertutorial/admin/v2/tutorials/upload-target-drivers-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'driver_bulk_upload_template.xlsx');
      yield put(fetchDownloadDriverTutorialTemplateSuccess());
    } else {
      yield put(fetchDownloadDriverTutorialTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDownloadDriverTutorialTemplateFailure((e as Error).message));
  }
}

function* uploadDriverTutorialTargetDrivers({
  payload: { id, data },
}: ReturnType<typeof uploadDriverTutorialTargetDriversRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/drivertutorial/admin/v2/tutorials/${id}/upload-target-drivers`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(uploadDriverTutorialTargetDriversSuccess());
    } else {
      yield put(uploadDriverTutorialTargetDriversFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadDriverTutorialTargetDriversFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(createDriverTutorialRequest.type, createDriverTutorial);
  yield takeLatest(updateDriverTutorialRequest.type, updateDriverTutorial);
  yield takeLatest(fetchDownloadDriverTutorialTemplateRequest.type, fetchDownloadDriverTutorialTemplate);
  yield takeLatest(uploadDriverTutorialTargetDriversRequest.type, uploadDriverTutorialTargetDrivers);
}

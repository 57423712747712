import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { getPriceAllFailure, getPriceAllRequest, getPriceAllSuccess } from '@/pages/PriceVerification/reducer';
import { authenticatedRequest } from '@/utils/request';

function* getPriceAll({ payload }: ReturnType<typeof getPriceAllRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/products/getPrices', { data: payload, timeout: 60000 });

    const action = response.ok ? getPriceAllSuccess(response.data) : getPriceAllFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(getPriceAllFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(getPriceAllRequest.type, getPriceAll);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "PolygonList" */ '@/pages/Polygons/List'));
const Detail = lazy(() => import(/* webpackChunkName: "PolygonDetail" */ '@/pages/Polygons/Detail'));
const New = lazy(() => import(/* webpackChunkName: "PolygonNew" */ '@/pages/Polygons/New'));
const Edit = lazy(() => import(/* webpackChunkName: "PolygonEdit" */ '@/pages/Polygons/Edit'));

export const polygonsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Polygons,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Polygons}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Polygons}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Polygons}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { useMemo } from 'react';

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import Text from '@/components/ValueField/Text';

const phoneUtil = PhoneNumberUtil.getInstance();

interface PhoneNumberProps {
  /**
   * 핸드폰 번호
   */
  value: string;
}

/**
 * 핸드폰 번호를 입력받고 정해진 형식으로 포맷팅 후, Text로 나타내는 컴포넌트이다.
 *
 * 정해진 포맷에 맞지 않을 경우 값 그대로 리턴한다.
 */
function PhoneNumber({ value }: PhoneNumberProps) {
  const number = useMemo(() => {
    try {
      const parseNumber = phoneUtil.parseAndKeepRawInput(value);

      return phoneUtil.format(parseNumber, PhoneNumberFormat.INTERNATIONAL);
    } catch {
      return value;
    }
  }, [value]);

  return <Text>{number}</Text>;
}

export default PhoneNumber;

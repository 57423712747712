import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  upsertFaqTopicRequest,
  upsertFaqTopicSuccess,
  upsertFaqTopicFailure,
  deleteFaqTopicRequest,
  deleteFaqTopicSuccess,
  deleteFaqTopicFailure,
  upsertFaqQuestionRequest,
  upsertFaqQuestionSuccess,
  upsertFaqQuestionFailure,
  deleteFaqQuestionRequest,
  deleteFaqQuestionSuccess,
  deleteFaqQuestionFailure,
  releaseFaqQuestionRequest,
  releaseFaqQuestionSuccess,
  releaseFaqQuestionFailure,
  rejectFaqQuestionRequest,
  rejectFaqQuestionSuccess,
  rejectFaqQuestionFailure,
  bulkUpdateDisplayOrderTopicRequest,
  bulkUpdateDisplayOrderTopicSuccess,
  bulkUpdateDisplayOrderTopicFailure,
  bulkUpdateDisplayOrderQuestionRequest,
  bulkUpdateDisplayOrderQuestionSuccess,
  bulkUpdateDisplayOrderQuestionFailure,
} from '@/svc/cs-svc/faqManagement/reducer';
import { authenticatedRequest } from '@/utils/request';

function* upsertFaqTopic({ payload }: ReturnType<typeof upsertFaqTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/topic/upsert', { data: payload });

    const action = response.ok ? upsertFaqTopicSuccess() : upsertFaqTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertFaqTopicFailure((e as Error).message));
  }
}

function* deleteFaqTopic({ payload }: ReturnType<typeof deleteFaqTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/topic/${payload}`);

    const action = response.ok ? deleteFaqTopicSuccess() : deleteFaqTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteFaqTopicFailure((e as Error).message));
  }
}

function* upsertFaqQuestion({ payload }: ReturnType<typeof upsertFaqQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/question/upsert', { data: payload });

    const action = response.ok ? upsertFaqQuestionSuccess() : upsertFaqQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertFaqQuestionFailure((e as Error).message));
  }
}

function* deleteFaqQuestion({ payload }: ReturnType<typeof deleteFaqQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/question/${payload}`);

    const action = response.ok ? deleteFaqQuestionSuccess() : deleteFaqQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteFaqQuestionFailure((e as Error).message));
  }
}

function* releaseFaqQuestion({ payload }: ReturnType<typeof releaseFaqQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/question/${payload}/release`);

    const action = response.ok ? releaseFaqQuestionSuccess() : releaseFaqQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(releaseFaqQuestionFailure((e as Error).message));
  }
}

function* rejectFaqQuestion({ payload }: ReturnType<typeof rejectFaqQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/question/${payload}/reject`);

    const action = response.ok ? rejectFaqQuestionSuccess() : rejectFaqQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(rejectFaqQuestionFailure((e as Error).message));
  }
}

function* bulkUpdateDisplayOrderTopic({ payload }: ReturnType<typeof bulkUpdateDisplayOrderTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/topic/bulkUpdateDisplayOrder', { data: payload });

    const action = response.ok ? bulkUpdateDisplayOrderTopicSuccess() : bulkUpdateDisplayOrderTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateDisplayOrderTopicFailure((e as Error).message));
  }
}

function* bulkUpdateDisplayOrderQuestion({ payload }: ReturnType<typeof bulkUpdateDisplayOrderQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/question/bulkUpdateDisplayOrder', { data: payload });

    const action = response.ok ? bulkUpdateDisplayOrderQuestionSuccess() : bulkUpdateDisplayOrderQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateDisplayOrderQuestionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(upsertFaqTopicRequest.type, upsertFaqTopic);
  yield takeLatest(deleteFaqTopicRequest.type, deleteFaqTopic);
  yield takeLatest(upsertFaqQuestionRequest.type, upsertFaqQuestion);
  yield takeLatest(deleteFaqQuestionRequest.type, deleteFaqQuestion);
  yield takeLatest(releaseFaqQuestionRequest.type, releaseFaqQuestion);
  yield takeLatest(rejectFaqQuestionRequest.type, rejectFaqQuestion);
  yield takeLatest(bulkUpdateDisplayOrderTopicRequest.type, bulkUpdateDisplayOrderTopic);
  yield takeLatest(bulkUpdateDisplayOrderQuestionRequest.type, bulkUpdateDisplayOrderQuestion);
}

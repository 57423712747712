import { ReactNode } from 'react';

function HeaderContent({ title, icon, children }: { title?: string; icon?: ReactNode; children?: ReactNode }) {
  return (
    <>
      {icon ?? <i className='fa fa-align-justify' />} {title ?? children}
    </>
  );
}

export default HeaderContent;

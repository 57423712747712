import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryRideProductOptionFormInterface, DeliveryRideProductOptionQuery } from '@/pages/DeliveryRideProductOption/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface DeliveryRideProductOptionState extends PaginationStoreState, StandardStoreState {
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
}

const initialState: DeliveryRideProductOptionState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

const deliveryRideProductOptionSlice = createSlice({
  name: 'deliveryRideProductOption',
  initialState,
  reducers: {
    fetchDeliveryRideProductOptionListRequest: {
      prepare: (search: DeliveryRideProductOptionQuery) => ({ payload: search }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchDeliveryRideProductOptionListSuccess: listSuccess,
    fetchDeliveryRideProductOptionListFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isFetching = false;
    },

    fetchDeliveryRideProductOptionDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchDeliveryRideProductOptionDetailSuccess: detailSuccess,
    fetchDeliveryRideProductOptionDetailFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isFetching = false;
    },

    createDeliveryRideProductOptionRequest: {
      prepare: (data: DeliveryRideProductOptionFormInterface) => ({ payload: data }),
      reducer: (state) => {
        state.error = null;
        state.isCreating = true;
      },
    },
    createDeliveryRideProductOptionSuccess: (state) => {
      state.isCreating = false;
    },
    createDeliveryRideProductOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isCreating = false;
    },

    updateDeliveryRideProductOptionRequest: {
      prepare: (id: string, data: DeliveryRideProductOptionFormInterface) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.error = null;
        state.isUpdating = true;
      },
    },
    updateDeliveryRideProductOptionSuccess: (state) => {
      state.isUpdating = false;
    },
    updateDeliveryRideProductOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isUpdating = false;
    },

    deleteDeliveryRideProductOptionRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
        state.isDeleting = true;
      },
    },
    deleteDeliveryRideProductOptionSuccess: (state) => {
      state.isDeleting = false;
    },
    deleteDeliveryRideProductOptionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isDeleting = false;
    },
  },
});

export const {
  fetchDeliveryRideProductOptionListRequest,
  fetchDeliveryRideProductOptionListSuccess,
  fetchDeliveryRideProductOptionListFailure,
  fetchDeliveryRideProductOptionDetailRequest,
  fetchDeliveryRideProductOptionDetailSuccess,
  fetchDeliveryRideProductOptionDetailFailure,
  createDeliveryRideProductOptionRequest,
  createDeliveryRideProductOptionSuccess,
  createDeliveryRideProductOptionFailure,
  updateDeliveryRideProductOptionRequest,
  updateDeliveryRideProductOptionSuccess,
  updateDeliveryRideProductOptionFailure,
  deleteDeliveryRideProductOptionRequest,
  deleteDeliveryRideProductOptionSuccess,
  deleteDeliveryRideProductOptionFailure,
} = deliveryRideProductOptionSlice.actions;

export default deliveryRideProductOptionSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sanitizeDeliveryPriceOptionQuery } from '@/pages/DeliveryPriceOption/hooks';
import { DeliveryPriceOption, DeliveryPriceOptionCreateOrUpdateDto, DeliveryPriceOptionState } from '@/pages/DeliveryPriceOption/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

const initialState: DeliveryPriceOptionState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

const deliveryPriceOptionSlice = createSlice({
  name: 'deliveryPriceOption',
  initialState,
  reducers: {
    fetchDeliveryPriceOptionListRequest: {
      prepare: (search: ReturnType<typeof sanitizeDeliveryPriceOptionQuery>) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDeliveryPriceOptionListSuccess: {
      prepare: (payload: ListResponse<DeliveryPriceOption>) => ({ payload }),
      reducer: (state, action: PayloadAction<ListResponse<DeliveryPriceOption>>) => {
        Object.assign(state, listSuccess(state, action));
      },
    },
    fetchDeliveryPriceOptionListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchDeliveryPriceOptionDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDeliveryPriceOptionDetailSuccess: {
      prepare: (payload: DetailResponse<DeliveryPriceOption>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<DeliveryPriceOption>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchDeliveryPriceOptionDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createDeliveryPriceOptionRequest: {
      prepare: (data: DeliveryPriceOptionCreateOrUpdateDto) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createDeliveryPriceOptionSuccess: {
      prepare: (payload: DeliveryPriceOption) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createDeliveryPriceOptionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateDeliveryPriceOptionRequest: {
      prepare: (id: string, data: DeliveryPriceOptionCreateOrUpdateDto) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateDeliveryPriceOptionSuccess: {
      prepare: (payload: DeliveryPriceOption) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateDeliveryPriceOptionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },

    deleteDeliveryPriceOptionRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isDeleting = true;
        state.error = null;
      },
    },
    deleteDeliveryPriceOptionSuccess(state) {
      state.isDeleting = false;
    },
    deleteDeliveryPriceOptionFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isDeleting = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchDeliveryPriceOptionListRequest,
  fetchDeliveryPriceOptionListSuccess,
  fetchDeliveryPriceOptionListFailure,
  fetchDeliveryPriceOptionDetailRequest,
  fetchDeliveryPriceOptionDetailSuccess,
  fetchDeliveryPriceOptionDetailFailure,
  createDeliveryPriceOptionRequest,
  createDeliveryPriceOptionSuccess,
  createDeliveryPriceOptionFailure,
  updateDeliveryPriceOptionRequest,
  updateDeliveryPriceOptionSuccess,
  updateDeliveryPriceOptionFailure,
  deleteDeliveryPriceOptionRequest,
  deleteDeliveryPriceOptionSuccess,
  deleteDeliveryPriceOptionFailure,
} = deliveryPriceOptionSlice.actions;

export default deliveryPriceOptionSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PaymentTransaction,
  PaymentTransactionQueryInterface,
  PaymentTransactionRequestData,
  PaymentTransactionState,
} from '@/pages/PaymentTransaction/type';
import { originListResponse } from '@/utils/types';

const initialState: PaymentTransactionState = {
  isFetching: false,
  isRefunding: false,
  isProcessing: false,
  page: { total: 1, current: 1, limit: 20 },
  list: [],
  detail: null,
  totalCount: 0,
  error: null,
};

const paymentTransactionSlice = createSlice({
  name: 'paymentTransaction',
  initialState,
  reducers: {
    fetchPaymentTransactionRequest: {
      prepare: (search: PaymentTransactionQueryInterface) => ({ payload: { search } }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchPaymentTransactionSuccess(state, { payload }: PayloadAction<originListResponse<PaymentTransaction>>) {
      state.isFetching = false;
      state.list = payload.content;
      state.page = {
        total: payload.totalPages,
        current: payload.number + 1,
        limit: payload.size,
      };
      state.totalCount = payload.totalElements;
    },
    fetchPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    fetchPaymentTransactionDetailRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.isFetching = true;
        state.detail = null;
        state.error = null;
      },
    },
    fetchPaymentTransactionDetailSuccess: {
      prepare: (payload: PaymentTransaction) => ({ payload }),
      reducer: (state, { payload }: PayloadAction<PaymentTransaction>) => {
        state.detail = payload;
        state.isFetching = false;
      },
    },
    fetchPaymentTransactionDetailFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    refundPaymentTransactionRequest: {
      prepare: (id: string, data: PaymentTransactionRequestData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isProcessing = true;
      },
    },
    refundPaymentTransactionSuccess(state) {
      state.isProcessing = false;
    },
    refundPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isProcessing = false;
      state.error = payload;
    },
    cancelPaymentTransactionRequest: {
      prepare: (id: string, data: PaymentTransactionRequestData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isProcessing = true;
      },
    },
    cancelPaymentTransactionSuccess(state) {
      state.isProcessing = false;
    },
    cancelPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isProcessing = false;
      state.error = payload;
    },
    payWithExistingCardPaymentTransactionRequest: {
      prepare: (id: string, data: PaymentTransactionRequestData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isProcessing = true;
      },
    },
    payWithExistingCardPaymentTransactionSuccess(state) {
      state.isProcessing = false;
    },
    payWithExistingCardPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isProcessing = false;
      state.error = payload;
    },
    failPaymentTransactionRequest: {
      prepare: (id: string, data: PaymentTransactionRequestData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isProcessing = true;
      },
    },
    failPaymentTransactionSuccess(state) {
      state.isProcessing = false;
    },
    failPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isProcessing = false;
      state.error = payload;
    },

    launchPaymentTransactionRequest: {
      prepare: (id: string, data: PaymentTransactionRequestData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isProcessing = true;
      },
    },
    launchPaymentTransactionSuccess(state) {
      state.isProcessing = false;
    },
    launchPaymentTransactionFailure(state, { payload }: PayloadAction<string>) {
      state.isProcessing = false;
      state.error = payload;
    },
  },
});

export const {
  fetchPaymentTransactionRequest,
  fetchPaymentTransactionSuccess,
  fetchPaymentTransactionFailure,
  fetchPaymentTransactionDetailRequest,
  fetchPaymentTransactionDetailSuccess,
  fetchPaymentTransactionDetailFailure,
  refundPaymentTransactionRequest,
  refundPaymentTransactionSuccess,
  refundPaymentTransactionFailure,
  cancelPaymentTransactionRequest,
  cancelPaymentTransactionSuccess,
  cancelPaymentTransactionFailure,
  payWithExistingCardPaymentTransactionRequest,
  payWithExistingCardPaymentTransactionSuccess,
  payWithExistingCardPaymentTransactionFailure,
  failPaymentTransactionRequest,
  failPaymentTransactionSuccess,
  failPaymentTransactionFailure,
  launchPaymentTransactionRequest,
  launchPaymentTransactionSuccess,
  launchPaymentTransactionFailure,
} = paymentTransactionSlice.actions;

export default paymentTransactionSlice.reducer;

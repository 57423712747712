import { createAction } from '@reduxjs/toolkit';

import { SMSTemplateForm } from '@/pages/SMSTemplate/type';
import Service from '@/svc/serviceName';

export const createSmsTemplateRequest = createAction<{ data: SMSTemplateForm }>(`${Service.deliverySvc}/smsTemplate/createSmsTemplateRequest`);
export const createSmsTemplateSuccess = createAction(`${Service.deliverySvc}/smsTemplate/createSmsTemplateSuccess`);
export const createSmsTemplateFailure = createAction<string>(`${Service.deliverySvc}/smsTemplate/createSmsTemplateFailure`);

export const updateSmsTemplateRequest = createAction<{ id: string; data: SMSTemplateForm }>(
  `${Service.deliverySvc}/smsTemplate/updateSmsTemplateRequest`
);
export const updateSmsTemplateSuccess = createAction(`${Service.deliverySvc}/smsTemplate/updateSmsTemplateSuccess`);
export const updateSmsTemplateFailure = createAction<string>(`${Service.deliverySvc}/smsTemplate/updateSmsTemplateFailure`);

export const deleteSmsTemplateRequest = createAction<string>(`${Service.deliverySvc}/smsTemplate/deleteSmsTemplateRequest`);
export const deleteSmsTemplateSuccess = createAction(`${Service.deliverySvc}/smsTemplate/deleteSmsTemplateSuccess`);
export const deleteSmsTemplateFailure = createAction<string>(`${Service.deliverySvc}/smsTemplate/deleteSmsTemplateFailure`);

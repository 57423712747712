import { createAction } from '@reduxjs/toolkit';

export const createInstantSurgeRequest = createAction<any>('instantSurgeRule/createInstantSurgeRequest');
export const createInstantSurgeSuccess = createAction<any>('instantSurgeRule/createInstantSurgeSuccess');
export const createInstantSurgeFailure = createAction<string>('instantSurgeRule/createInstantSurgeFailure');

export const upsertInstantSurgeRequest = createAction<any>('instantSurgeRule/upsertInstantSurgeRequest');
export const upsertInstantSurgeSuccess = createAction<any>('instantSurgeRule/upsertInstantSurgeSuccess');
export const upsertInstantSurgeFailure = createAction<string>('instantSurgeRule/upsertInstantSurgeFailure');

export const upsertBulkInstantSurgeRequest = createAction<any>('instantSurgeRule/upsertBulkInstantSurgeRequest');
export const upsertBulkInstantSurgeSuccess = createAction<any>('instantSurgeRule/upsertBulkInstantSurgeSuccess');
export const upsertBulkInstantSurgeFailure = createAction<string>('instantSurgeRule/upsertBulkInstantSurgeFailure');

export const getSurgeForCitiesRequest = createAction<any>('instantSurgeRule/getSurgeForCitiesRequest');
export const getSurgeForCitiesSuccess = createAction<any>('instantSurgeRule/getSurgeForCitiesSuccess');
export const getSurgeForCitiesFailure = createAction<string>('instantSurgeRule/getSurgeForCitiesFailure');

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RideProductInfoTemplateList" */ '@/pages/RideProductInfoTemplate/List'));
const Detail = lazy(() => import(/* webpackChunkName: "RideProductInfoTemplateDetail" */ '@/pages/RideProductInfoTemplate/Detail'));
const New = lazy(() => import(/* webpackChunkName: "RideProductInfoTemplateNew" */ '@/pages/RideProductInfoTemplate/New'));
const Edit = lazy(() => import(/* webpackChunkName: "RideProductInfoTemplateEdit" */ '@/pages/RideProductInfoTemplate/Edit'));

export const RideProductInfoTemplateRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.RideProductInfoTemplate,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.RideProductInfoTemplate}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.RideProductInfoTemplate}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.RideProductInfoTemplate}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

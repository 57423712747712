import { valueOf } from '@/types';

export const TARGET_KEY = {
  PipayTransactionList: 'PipayTransactionList',
  ABATransactionList: 'ABATransactionList',
  AlipayTransactionList: 'AlipayTransactionList',
  PaoTransactionList: 'PaoTransactionList',
  MomopayTransactionList: 'MomopayTransactionList',
  CmcbTransactionList: 'CmcbTransactionList',
  AdyenTransactionList: 'AdyenTransactionList',
  WingTransactionList: 'WingTransactionList',
  PaymentTransactionList: 'PaymentTransactionList',
  DepositTransactionList: 'DepositTransactionList',
  TransactionList: 'TransactionList',
} as const;

export type TargetKey = valueOf<typeof TARGET_KEY>;

// column 추가되면 버전 1씩 올릴 것
export const COLUMN_VERSION: Record<TargetKey, string> = {
  PipayTransactionList: '1',
  ABATransactionList: '1',
  AlipayTransactionList: '1',
  PaoTransactionList: '1',
  MomopayTransactionList: '1',
  CmcbTransactionList: '1',
  AdyenTransactionList: '1',
  WingTransactionList: '1',
  PaymentTransactionList: '1',
  DepositTransactionList: '1',
  TransactionList: '1',
};

export interface ColumnFomrikValues {
  showColumnList: Set<string>;
  listColumns: string[];
}

export type ColumnStore = {
  [key in TargetKey]: string[];
};

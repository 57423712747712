import { createSlice, createAction } from '@reduxjs/toolkit';

import { detailSuccess } from '@/utils/reducerUtils';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

type DispatchConfigState = PaginationStoreState & StandardStoreState;

const initialState: DispatchConfigState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  error: null,
};

const failure = (state: DispatchConfigState) => {
  state.isFetching = false;
};

export const createDispatchConfigRequest = createAction<any>('dispatchConfig/createDispatchConfigRequest');
export const createDispatchConfigSuccess = createAction('dispatchConfig/createDispatchConfigSuccess');
export const createDispatchConfigFailure = createAction<string>('dispatchConfig/createDispatchConfigFailure');
export const updateDispatchConfigRequest = createAction('dispatchConfig/updateDispatchConfigRequest', (id: string, body) => ({
  payload: { id, body },
}));
export const updateDispatchConfigSuccess = createAction('dispatchConfig/updateDispatchConfigSuccess');
export const updateDispatchConfigFailure = createAction<string>('dispatchConfig/updateDispatchConfigFailure');

const dispatchConfigSlice = createSlice({
  name: 'dispatchConfig',
  initialState,
  reducers: {
    fetchDispatchConfigDetailRequest: {
      reducer: (state) => {
        state.isFetching = true;
      },
      prepare: (id: string) => ({ payload: id }),
    },
    fetchDispatchConfigDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchDispatchConfigDetailFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: failure,
    },
  },
});

export const { fetchDispatchConfigDetailRequest, fetchDispatchConfigDetailSuccess, fetchDispatchConfigDetailFailure } = dispatchConfigSlice.actions;

export default dispatchConfigSlice.reducer;

import { BreadcrumbItem as BreadcrumbItemComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface BreadcrumbItemProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  active?: boolean;
  cssModule?: CSSModule;
}

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  return <BreadcrumbItemComponent {...props} />;
}

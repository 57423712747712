import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Area, AreaBody, AreaState } from '@/pages/Areas/type';
import { detailSuccess } from '@/utils/reducerUtils';
import { DetailResponse } from '@/utils/types';

const initialState: AreaState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isReloading: false,
};

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    fetchAreaDetailRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchAreaDetailSuccess: {
      prepare: (payload: DetailResponse<Area>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<Area>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchAreaDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    createAreaRequest: {
      prepare: (data: AreaBody) => ({ payload: data }),
      reducer: (state) => {
        state.isCreating = true;
        state.error = null;
      },
    },
    createAreaSuccess: {
      prepare: (payload: Area) => ({ payload }),
      reducer: (state) => {
        state.isCreating = false;
      },
    },
    createAreaFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreating = false;
        state.error = payload;
      },
    },

    updateAreaRequest: {
      prepare: (id: number, data: AreaBody) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateAreaSuccess: {
      prepare: (payload: Area) => ({ payload }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateAreaFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdating = false;
        state.error = payload;
      },
    },

    deleteAreaRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.isDeleting = true;
        state.error = null;
      },
    },
    deleteAreaSuccess(state) {
      state.isDeleting = false;
    },
    deleteAreaFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isDeleting = false;
        state.error = payload;
      },
    },

    reloadLocMgrRequest(state) {
      state.isReloading = true;
      state.error = null;
    },
    reloadLocMgrSuccess(state) {
      state.isReloading = false;
    },
    reloadLocMgrFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const {
  fetchAreaDetailRequest,
  fetchAreaDetailSuccess,
  fetchAreaDetailFailure,
  createAreaRequest,
  createAreaSuccess,
  createAreaFailure,
  updateAreaRequest,
  updateAreaSuccess,
  updateAreaFailure,
  deleteAreaRequest,
  deleteAreaSuccess,
  deleteAreaFailure,
  reloadLocMgrRequest,
  reloadLocMgrSuccess,
  reloadLocMgrFailure,
} = areaSlice.actions;

export default areaSlice.reducer;

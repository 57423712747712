import { createAction } from '@reduxjs/toolkit';

import { ChangePasswordDto } from '@/pages/Merchant/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.accountSvc}/merchant`;

export const resetPasswordRequest = createAction<{ id: string; data: ChangePasswordDto }>(`${PREFIX}/resetPasswordRequest`);
export const resetPasswordSuccess = createAction(`${PREFIX}/resetPasswordSuccess`);
export const resetPasswordFailure = createAction<string>(`${PREFIX}/resetPasswordFailure`);

export const createApiKeyRequest = createAction<string>(`${PREFIX}/createApiKeyRequest`);
export const createApiKeySuccess = createAction(`${PREFIX}/createApiKeySuccess`);
export const createApiKeyFailure = createAction<string>(`${PREFIX}/createApiKeyFailure`);

export const deleteApiKeyRequest = createAction<{ id: string; keyId: string }>(`${PREFIX}/deleteApiKeyRequest`);
export const deleteApiKeySuccess = createAction(`${PREFIX}/deleteApiKeySuccess`);
export const deleteApiKeyFailure = createAction<string>(`${PREFIX}/deleteApiKeyFailure`);

import { SearchDeliveryButton } from '@/components/Popup/DeliveryQrSearchPopup';
import SelectLanguage from '@/components/SelectLanguage';
import { Dropdown, DropdownMenu, DropdownItem, Nav, NavItem, DropdownToggle } from '@/components/template';
import { useToggle } from '@/hooks/toggle';
import useDeliveryQrScanner from '@/layout/hooks';

interface Props {
  logoutAdmin: () => void;
  username: string;
}

function Header(props: Props) {
  const [dropdownOpen, toggleDropdownOpen] = useToggle();
  const { isVisible } = useDeliveryQrScanner();

  return (
    <header className='app-header navbar'>
      {isVisible && <SearchDeliveryButton />}
      <SelectLanguage isVisibleDeliveryQrScanner={isVisible} />
      <Nav className='tw-ml-5' navbar>
        <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdownOpen}>
            <DropdownToggle className='nav-link dropdown-toggle' data-cy='logout-show'>
              <span className='d-md-down-none'>{props.username}</span>
            </DropdownToggle>
            <DropdownMenu right className={dropdownOpen ? 'show' : ''}>
              <DropdownItem onClick={props.logoutAdmin} data-cy='logout-btn'>
                <i className='fa fa-lock' /> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </header>
  );
}

export default Header;

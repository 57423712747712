/* @flow */
import CryptoJS from 'crypto-js';
import moment from 'moment';
import * as store from 'store/dist/store.modern';

import { SuggestionType } from '@/components/Suggestion/constants';
import { AdminMetaData } from '@/pages/Admin/type';
import { UserData } from '@/pages/LoginPage/types';
import { Region } from '@/pages/Regions/type';
import { sanitizePermission } from '@/utils/permissionUtils';

const namespace = 'easi6-vendor-admin';
const secretKey = `${namespace}:userData`;
export const KEYS = {
  user: 'user',
  login: 'login',
  username: 'username',
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  availableRegion: 'availableRegion',
  userData: 'userData',
  language: 'language',
  lastSelectedRegion: 'lastSelectedRegion',
  createToday: 'createToday',
  suggestion: 'suggestion',
  column: 'column',
  columnVersion: 'columnVersion',
  googlePlaceSessionToken: 'googlePlaceSessionToken',
};

const get = (key: string) => store.get(`@${namespace}:${key}`);
const set = (key: string, value: any) => store.set(`@${namespace}:${key}`, value);
const remove = (key: string) => store.remove(`@${namespace}:${key}`);
// const clearAll = () => store.clearAll();

export const saveUserData = (userData: UserData) => {
  set(
    KEYS.userData,
    CryptoJS.AES.encrypt(JSON.stringify({ ...userData, permissions: sanitizePermission(userData.permissions, userData.super) }), secretKey).toString()
  );
};

interface FullUserData {
  createdAt: number;
  id: string;
  login: string;
  name: string;
  permissions: any;
  regions: string[];
  roles: any[];
  super: boolean;
  updatedAt: number;
  regionObject: Region;
  metaData: AdminMetaData | null;
}

export const getUserData: () => FullUserData | null = () => {
  const encryptedUserData = get(KEYS.userData);
  if (encryptedUserData === undefined) {
    return null;
  }

  const userData = JSON.parse(CryptoJS.AES.decrypt(encryptedUserData, secretKey).toString(CryptoJS.enc.Utf8));

  if (
    (userData.permissions ?? null) === null ||
    (userData.name ?? null) === null ||
    (userData.regions ?? null) === null ||
    (userData.super ?? null) === null
  ) {
    return null;
  }

  return userData;
};

export const removeUserData = () => remove(KEYS.userData);

export const getLoginId = () => getUserData()?.login;

export const getAccessToken = () => get(KEYS.accessToken);

export const saveAccessToken = (payload: any) => set(KEYS.accessToken, payload.access_token);

export const getRefreshToken = () => get(KEYS.refreshToken);

export const saveRefreshToken = (payload: any) => set(KEYS.refreshToken, payload.refresh_token);

export const saveTokens = (payload: any) => {
  saveAccessToken(payload);
  saveRefreshToken(payload);
};

export const removeTokens = () => {
  remove(KEYS.accessToken);
  remove(KEYS.refreshToken);
};

export const getUsername = () => get(KEYS.username);

export const saveUsername = (username: any) => set(KEYS.username, username);

export const removeUsername = () => remove(KEYS.username);

export const getAvailableRegion = () => getUserData()?.regions;

export const saveAvailableRegion = (region: any) => set(KEYS.availableRegion, region);

export const removeAvailableRegion = () => remove(KEYS.availableRegion);

export const getLastSelectedRegion = () => get(KEYS.lastSelectedRegion);

export const saveLastSelectedRegion = (name: any) => set(KEYS.lastSelectedRegion, name);

export const removeLastSelectedRegion = () => remove(KEYS.lastSelectedRegion);

export const saveLanguage = (language: string) => set(KEYS.language, language);

export const getLanguage = () => get(KEYS.language);

export const removeLanguage = () => remove(KEYS.language);

export const saveRefreshTime = () => set('refreshtime', +moment());

export const getRefreshTime = () => get('refreshtime');

export const removeRefreshTime = () => remove('refreshtime');

export const getCreateToday = () => get(KEYS.createToday);

export const saveCreateToday = (isCreateToday: boolean) => set(KEYS.createToday, isCreateToday);

export const removeCreateToday = () => remove(KEYS.createToday);

export const getSuggestionData = () => {
  try {
    return JSON.parse(get(KEYS.suggestion));
  } catch (e) {
    return {};
  }
};

export const setSuggestionData = <T>(key: SuggestionType, value: Record<string, T>) => {
  let data: Partial<Record<SuggestionType, Record<string, any>>> = {};
  try {
    data = JSON.parse(getSuggestionData());
  } catch (e) {}

  if (!data[key]) data[key] = { ...value };
  else data[key] = { ...data[key], ...value };

  set(KEYS.suggestion, JSON.stringify(data));
};

export const getColumn = () => get(KEYS.column);

export const saveColumn = (column: string) => set(KEYS.column, column);

export const removeColumn = () => remove(KEYS.column);

export const getColumnVersion = () => get(KEYS.columnVersion);

export const saveColumnVersion = (column: string) => set(KEYS.columnVersion, column);

export const removeColumnVersion = () => remove(KEYS.columnVersion);

export const getGooglePlaceSessionToken = () => get(KEYS.googlePlaceSessionToken);

export const saveGooglePlaceSessionToken = (token: string | null) => set(KEYS.googlePlaceSessionToken, token);

export const removeGooglePlaceSessionToken = () => remove(KEYS.googlePlaceSessionToken);

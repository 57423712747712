import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CorporateState, InvitationBulkParams, InvitationExportParams, TotalAmountDto } from '@/pages/Corporate/type';
import { BranchAndRideCountDto } from '@/svc/corporate-svc/type';
import { Ride } from '@/svc/ride-svc/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { originListResponse } from '@/utils/types';

const initialState: CorporateState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  branchList: [],
  statement: {
    list: [],
    summary: {},
    page: { total: 1, current: 1 },
    totalCount: 0,
  },
  isFetching: false,
  error: null,
};

export const fetchCorporateListRequest = createAction('corporate/fetchCorporateListRequest', (param) => ({ payload: param }));
export const fetchCorporateListFailure = createAction<string>('corporate/fetchCorporateListFailure');
export const fetchCorporateDetailRequest = createAction('corporate/fetchCorporateDetailRequest', (id) => ({ payload: id }));
export const fetchCorporateDetailFailure = createAction<string>('corporate/fetchCorporateDetailFailure');

export const createCorporateRequest = createAction<FormData>('corporate/createCorporateRequest');
export const createCorporateSuccess = createAction<number>('corporate/createCorporateSuccess');
export const createCorporateFailure = createAction<string>('corporate/createCorporateFailure');

export const updateCorporateRequest = createAction('corporate/updateCorporateRequest', (id, body) => ({ payload: { id, body } }));
export const updateCorporateSuccess = createAction<number>('corporate/updateCorporateSuccess');
export const updateCorporateFailure = createAction<string>('corporate/updateCorporateFailure');

export const deleteContractsRequest = createAction<number>('corporate/deleteContractsRequest');
export const deleteContractsSuccess = createAction('corporate/deleteContractsSuccess');
export const deleteContractsFailure = createAction<string>('corporate/deleteContractsFailure');

export const fetchBranchListRequest = createAction('corporate/fetchBranchListRequest', (id, params?: { since: number; until: number }) => ({
  payload: { id, params },
}));
export const fetchBranchListFailure = createAction<string>('corporate/fetchBranchListFailure');

export const exportStatementRequest = createAction('corporate/exportStatementRequest', (id, query) => ({
  payload: { id, query },
}));
export const exportStatementSuccess = createAction('corporate/exportStatementSuccess');
export const exportStatementFailure = createAction<string>('corporate/exportStatementFailure');

export const fetchStatementListRequest = createAction('corporate/fetchStatementListRequest', (id, query) => ({
  payload: { id, query },
}));
export const fetchStatementListFailure = createAction<string>('corporate/fetchStatementListFailure');

export const fetchStatementSummaryRequest = createAction('corporate/fetchStatementSummaryRequest', (id, query) => ({
  payload: { id, query },
}));
export const fetchStatementSummaryFailure = createAction<string>('corporate/fetchStatementSummaryFailure');

export const refetchCorporateDetailRequest = createAction<number>('corporate/refetchCorporateDetailRequest');

export const exportInvitationListRequest = createAction<{ id: number; params: InvitationExportParams }>('corporate/exportInvitationListRequest');
export const exportInvitationListSuccess = createAction('corporate/exportInvitationListSuccess');
export const exportInvitationListFailure = createAction<string>('corporate/exportInvitationListFailure');

export const downloadInvitationTemplateRequest = createAction<number>('corporate/downloadInvitationTemplateRequest');
export const downloadInvitationTemplateSuccess = createAction('corporate/downloadInvitationTemplateSuccess');
export const downloadInvitationTemplateFailure = createAction<string>('corporate/downloadInvitationTemplateFailure');

export const bulkInvitationRequest = createAction<{ id: number; data: FormData; params: InvitationBulkParams }>('corporate/bulkInvitationRequest');
export const bulkInvitationSuccess = createAction('corporate/bulkInvitationSuccess');
export const bulkInvitationFailure = createAction<string>('corporate/bulkInvitationFailure');

const corporateSlice = createSlice({
  name: 'corporate',
  initialState,
  reducers: {
    fetchCorporateListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchCorporateDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchStatementListSuccess(state, { payload }: PayloadAction<originListResponse<Ride>>) {
      state.statement.list = payload.content;
      state.statement.page = { total: payload.totalPages, current: payload.pageable.pageNumber + 1, limit: payload.size };
      state.statement.totalCount = payload.totalElements;
    },
    fetchStatementSummarySuccess(state, { payload }: PayloadAction<TotalAmountDto>) {
      state.statement.summary = payload;
    },
    fetchBranchListSuccess(state, { payload }: PayloadAction<BranchAndRideCountDto[]>) {
      state.branchList = payload;
    },
  },
});

export const {
  fetchCorporateListSuccess,
  fetchCorporateDetailSuccess,
  fetchStatementListSuccess,
  fetchStatementSummarySuccess,
  fetchBranchListSuccess,
} = corporateSlice.actions;

export default corporateSlice.reducer;

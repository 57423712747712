import { Switch, Redirect, Route } from 'react-router-dom';

import { Pages } from '@/assets/constants';
import { ActionType, checkPermission } from '@/components/CheckPermission';
import { Loading } from '@/components/Loading';
import nav from '@/layout/Sidebar/_nav';
import { useAuth, useRegionManager } from '@/pages/Full/hooks';
import { Permissions } from '@/pages/LoginPage/types';
import { routes } from '@/routes';

interface AuthenticatedRouteSwitcherProps {
  firstPageUrl: string;
}

const filterRoute = (permissions: Permissions) => {
  const navItems = nav.items;
  const pagePermissions = Object.keys(permissions || {});
  const filteredRoute: React.ReactNode[] = [];

  Object.entries(routes).map(([page, route]) =>
    route.map(({ permission, ...routeProps }) => {
      if (!permission || checkPermission(permission, permissions[page as Pages])) {
        filteredRoute.push(<Route key={Array.prototype.join.call(routeProps.path, '')} {...routeProps} />);
      }
    })
  );

  // deliveryDriver, dashboard, h3heatmap, heatmap, pendingRide, phoneVerification, 여기서 걸러야 됨
  navItems.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        if (pagePermissions.findIndex((value) => value === child.Pages) !== -1) {
          const type = child.permissionType || ActionType.READ;

          if (checkPermission(type, permissions[(child.Pages as Pages) || '']) && child.component) {
            filteredRoute.push(<Route key={child.url} path={child.url} component={child.component} />);
          }
        }
      });
    } else {
      if (pagePermissions.includes(item.Pages || '') && item.component) {
        filteredRoute.push(<Route key={item.url} path={item.url} component={item.component} />);
      }
    }
  });

  return filteredRoute;
};

export default function AuthenticatedRouteSwitcher({ firstPageUrl }: AuthenticatedRouteSwitcherProps) {
  const { permissions } = useAuth();
  const { validRenderPageCondition } = useRegionManager();

  return (
    <>
      {validRenderPageCondition() ? (
        <Switch>
          {filterRoute(permissions)}
          <Route exact path='/' render={() => <Redirect to={firstPageUrl} />} />
        </Switch>
      ) : (
        <Loading />
      )}
    </>
  );
}

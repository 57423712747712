export const TRANSACTION_COLUMNS = {
  BULK: 'bulk',
  ID: 'id',
  USER: 'accountModel',
  REGION: 'region',
  TYPE: 'type',
  CATEGORY_TYPE: 'categoryType',
  STATUS: 'status',
  AMOUNT: 'amount',
  PM: 'paymeentMethod',
  CHANNEL: 'channel',
  CHANNEL_IDENTIFIER: 'channelIdentifier',
  EXTERNAL_TRANSACTION_ID: 'externalTransactionId',
  CREATED_AT: 'createdAt',
} as const;

export const TRANSACTION_COLUMNS_LIST = Object.values(TRANSACTION_COLUMNS);

export const TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: TRANSACTION_COLUMNS.BULK, label: 'bulk' },
  { key: TRANSACTION_COLUMNS.ID, label: 'id' },
  { key: TRANSACTION_COLUMNS.USER, label: 'user' },
  { key: TRANSACTION_COLUMNS.REGION, label: 'region' },
  { key: TRANSACTION_COLUMNS.TYPE, label: 'Type' },
  { key: TRANSACTION_COLUMNS.CATEGORY_TYPE, label: 'Category Type' },
  { key: TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: TRANSACTION_COLUMNS.PM, label: 'PM' },
  { key: TRANSACTION_COLUMNS.CHANNEL, label: 'Channel' },
  { key: TRANSACTION_COLUMNS.CHANNEL_IDENTIFIER, label: 'Channel Identifier' },
  { key: TRANSACTION_COLUMNS.EXTERNAL_TRANSACTION_ID, label: 'External Transaction ID' },
  { key: TRANSACTION_COLUMNS.CREATED_AT, label: 'Created At' },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createDeliveryRideOptionFailure,
  createDeliveryRideOptionRequest,
  createDeliveryRideOptionSuccess,
  deleteDeliveryRideOptionFailure,
  deleteDeliveryRideOptionRequest,
  deleteDeliveryRideOptionSuccess,
  updateDeliveryRideOptionFailure,
  updateDeliveryRideOptionRequest,
  updateDeliveryRideOptionSuccess,
} from '@/svc/delivery-svc/deliveryRideRouteOption/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createDeliveryRideOption({ payload }: ReturnType<typeof createDeliveryRideOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/config/options`, {
      data: payload,
    });

    if (response.ok) {
      yield put(createDeliveryRideOptionSuccess());
    } else {
      yield put(createDeliveryRideOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryRideOptionFailure((e as Error).message));
  }
}

function* updateDeliveryRideOption({ payload: { id, data } }: ReturnType<typeof updateDeliveryRideOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/options/${id}`, {
      data,
    });

    if (response.ok) {
      yield put(updateDeliveryRideOptionSuccess());
    } else {
      yield put(updateDeliveryRideOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryRideOptionFailure((e as Error).message));
  }
}

function* deleteDeliveryRideOption({ payload }: ReturnType<typeof deleteDeliveryRideOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/options/${payload}`);

    if (response.ok) {
      yield put(deleteDeliveryRideOptionSuccess());
    } else {
      yield put(deleteDeliveryRideOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryRideOptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(createDeliveryRideOptionRequest.type, createDeliveryRideOption);
  yield takeLatest(updateDeliveryRideOptionRequest.type, updateDeliveryRideOption);
  yield takeLatest(deleteDeliveryRideOptionRequest.type, deleteDeliveryRideOption);
}

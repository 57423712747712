import { createAction } from '@reduxjs/toolkit';

import { IssuerDto } from '@/pages/BINManagement/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.paySvc}/binManagement`;

export const deleteBinManagementRequest = createAction<string>(`${PREFIX}/deleteBinManagementRequest`);
export const deleteBinManagementSuccess = createAction(`${PREFIX}/deleteBinManagementSuccess`);
export const deleteBinManagementFailure = createAction<string>(`${PREFIX}/deleteBinManagementFailure`);

export const upsertBinManagementRequest = createAction<IssuerDto>(`${PREFIX}/upsertBinManagementRequest`);
export const upsertBinManagementSuccess = createAction<IssuerDto>(`${PREFIX}/upsertBinManagementSuccess`);
export const upsertBinManagementFailure = createAction<string>(`${PREFIX}/upsertBinManagementFailure`);

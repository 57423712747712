import { Pages } from '@/assets/constants';
import { abaTransactionRoute } from '@/pages/AbaTransaction/route';
import { accountRoute } from '@/pages/Account/route';
import { adminRoute } from '@/pages/Admin/route';
import { adminLogRoute } from '@/pages/AdminLog/route';
import { adyenTransactionRoute } from '@/pages/AdyenTransaction/route';
import { airportsRoute } from '@/pages/Airports/route';
import { alipayTransactionsRoute } from '@/pages/AlipayTransactions/route';
import { aofAccountRoute } from '@/pages/AofAccount/route';
import { appVersionRoute } from '@/pages/AppVersion/route';
import { areaRoute } from '@/pages/Areas/route';
import { binManagementRoute } from '@/pages/BINManagement/route';
import { bankInfoRoute } from '@/pages/BankInfo/route';
import { maintenanceRoute } from '@/pages/BankMaintenance/route';
import { bannerRoute } from '@/pages/Banner/route';
import { carRoute } from '@/pages/Cars/route';
import { cityRoute } from '@/pages/Cities/route';
import { cmcbTransactionRoute } from '@/pages/CmcbTransactions/route';
import { corporateRoute } from '@/pages/Corporate/route';
import { corporateInvoiceRoute } from '@/pages/CorporateInvoice/route';
import { corporateManagerRoute } from '@/pages/CorporateManager/route';
import { couponRoute } from '@/pages/Coupon/route';
import { cpfContributionTableRoute } from '@/pages/CpfContributionTable/route';
import { creditCardRoute } from '@/pages/CreditCard/route';
import { dashboardRoute } from '@/pages/Dashboard/route';
import { deliveryRoute } from '@/pages/Delivery/route';
import { deliveryExportResultsRoute } from '@/pages/DeliveryExportResults/route';
import { deliveryPaymentOptionRoute } from '@/pages/DeliveryPaymentOption/route';
import { deliveryPriceOptionRoute } from '@/pages/DeliveryPriceOption/route';
import { deliveryRideRoute } from '@/pages/DeliveryRide/route';
import { deliveryRidePriceOptionRoute } from '@/pages/DeliveryRidePriceOption/route';
import { deliveryRideProductOptionRoute } from '@/pages/DeliveryRideProductOption/route';
import { deliveryRideRequestRoute } from '@/pages/DeliveryRideRequest/route';
import { deliveryRideOptionRoute } from '@/pages/DeliveryRideRouteOption/route';
import { deliveryScheduleRoute } from '@/pages/DeliverySchedule/route';
import { deliveryTagRoute } from '@/pages/DeliveryTag/route';
import { depositTransactionRoute } from '@/pages/DepositTransaction/route';
import { dispatchConfigRoute } from '@/pages/DispatchConfig/route';
import { documentGuideManagementRoute } from '@/pages/DocumentGuideManagement/route';
import { driverApplicationRoute } from '@/pages/DriverApplications/route';
import { driverGroupRoute } from '@/pages/DriverGroup/route';
import { driverIncentiveRoute } from '@/pages/DriverIncentive/route';
import { driverTutorialRoute } from '@/pages/DriverTutorial/route';
import { driverRoute } from '@/pages/Drivers/route';
import { dynamicSurgeRoute } from '@/pages/DynamicSurge/route';
import { dynamicSurgeMonitorRoute } from '@/pages/DynamicSurgeMonitor/route';
import { dynamicSurgeSettingRoute } from '@/pages/DynamicSurgeSetting/route';
import { exportScheduleRoute } from '@/pages/ExportSchedule/route';
import { faqListManagementRoute } from '@/pages/FaqListManagement/route';
import { faqTopicManagementRoute } from '@/pages/FaqTopicManagement/route';
import { fraudCardRoute } from '@/pages/FraudCard/route';
import { fraudLogRoute } from '@/pages/FraudLog/route';
import { GooglePlaceRoute } from '@/pages/GooglePlace/route';
import { helpCenterListManagementRoute } from '@/pages/HelpCenterListManagement/route';
import { helpCenterTopicManagementRoute } from '@/pages/HelpCenterTopicManagement/route';
import { hourlyRentalCarRoute } from '@/pages/HourlyRentalCar/route';
import { hourlyRentalRequestRoute } from '@/pages/HourlyRentalRequest/route';
import { inboxItemRoute } from '@/pages/InboxItem/route';
import { instantSurgeRuleRoute } from '@/pages/InstantSurgeRule/route';
import { merchantRoute } from '@/pages/Merchant/route';
import { momopayTransactionsRoute } from '@/pages/MomopayTransactions/route';
import { newsRoute } from '@/pages/News/route';
import { otpSkipRuleRouter } from '@/pages/OtpSkipRule/route';
import { otpWhitelistRouter } from '@/pages/OtpWhitelist/route';
import { paoTransactionsRoute } from '@/pages/PaoTransactions/route';
import { paymentTransactionRoute } from '@/pages/PaymentTransaction/route';
import { penaltyRoute } from '@/pages/Penalty/route';
import { pipayTransactionsRoute } from '@/pages/PipayTransactions/route';
import { placeRoute } from '@/pages/Place/route';
import { placeTesterRoute } from '@/pages/PlaceTester/route';
import { polygonsRoute } from '@/pages/Polygons/route';
import { prefixCouponRoute } from '@/pages/PrefixCoupon/route';
import { preventMatchRoute } from '@/pages/PreventMatch/route';
import { priceVerificationRoute } from '@/pages/PriceVerification/route';
import { profilePictureChangeRoute } from '@/pages/ProfilePictureChange/route';
import { promotionRoute } from '@/pages/Promotion/route';
import { recoverDeletedAccountRoute } from '@/pages/RecoverDeletedAccount/route';
import { referralRoute } from '@/pages/Referral/route';
import { regionsRoute } from '@/pages/Regions/route';
import { remarkLogSettingRoute } from '@/pages/RemarkLogSetting/route';
import { restrictedAreasRoute } from '@/pages/RestrictedAreas/route';
import { reviewsRoute } from '@/pages/Reviews/route';
import { rideRoute } from '@/pages/Ride/route';
import { RideProductInfoTemplateRoute } from '@/pages/RideProductInfoTemplate/route';
import { rideProductsRoute } from '@/pages/RideProducts/route';
import { riderRoute } from '@/pages/Rider/route';
import { riderMissionRoute } from '@/pages/RiderMission/route';
import { rolesRoute } from '@/pages/Roles/route';
import { routingResultRoute } from '@/pages/RoutingResult/route';
import { SMSTemplateRoute } from '@/pages/SMSTemplate/route';
import { safetyAccidentListManagementRoute } from '@/pages/SafetyAccidentListManagement/route';
import { safetyAccidentTopicManagementRoute } from '@/pages/SafetyAccidentTopicManagement/route';
import { scheduleSurgeRoute } from '@/pages/ScheduleSurge/route';
import { scheduleSurgePresetRoute } from '@/pages/ScheduleSurgePreset/route';
import { shbLoanInfoRoute } from '@/pages/ShbLoanInfo/route';
import { shbRequestHistoryRoute } from '@/pages/ShbRequestHistory/route';
import { shbWalletRoute } from '@/pages/ShbWallet/route';
import { supportPageManagementRoute } from '@/pages/SupportPageManagement/route';
import { surgeCapRoute } from '@/pages/SurgeCap/route';
import { tadaMaintenanceRoute } from '@/pages/TadaMaintenance/route';
import { taxPrefillDescriptionRoute } from '@/pages/TaxPrefillDescription/route';
import { routeTestRoute } from '@/pages/Test/route';
import { tollFreeDateRoute } from '@/pages/TollFreeDate/route';
import { tollGantryRoute } from '@/pages/TollGantry/route';
import { tollTimeTableRoute } from '@/pages/TollTimeTable/route';
import { transactionRoute } from '@/pages/Transaction/route';
import { userSubmissionRoute } from '@/pages/UserSubmission/route';
import { vehicleMakerRoute } from '@/pages/VehicleMaker/route';
import { warehouseRoute } from '@/pages/Warehouse/route';
import { wingTransactionRoute } from '@/pages/WingTransaction/route';
import { withdrawRoute } from '@/pages/Withdraw/route';
import { zonesRoute } from '@/pages/Zones/route';
import { RouteProps } from '@/utils/types';

export const routes: Record<string, RouteProps[]> = {
  [Pages.Dashboard]: dashboardRoute,
  [Pages.Ride]: rideRoute,
  [Pages.Drivers]: driverRoute,
  [Pages.DriverApplications]: driverApplicationRoute,
  [Pages.DriverGroup]: driverGroupRoute,
  [Pages.Rider]: riderRoute,
  [Pages.Account]: accountRoute,
  [Pages.Admin]: adminRoute,
  [Pages.AdminLog]: adminLogRoute,
  [Pages.Airports]: airportsRoute,
  [Pages.AlipayTransactions]: alipayTransactionsRoute,
  [Pages.AppVersion]: appVersionRoute,
  [Pages.Areas]: areaRoute,
  [Pages.Banner]: bannerRoute,
  [Pages.Cars]: carRoute,
  [Pages.Cities]: cityRoute,
  [Pages.CmcbTransactions]: cmcbTransactionRoute,
  [Pages.Corporate]: corporateRoute,
  [Pages.CorporateManager]: corporateManagerRoute,
  [Pages.Coupon]: couponRoute,
  [Pages.CreditCard]: creditCardRoute,
  [Pages.DeliveryTag]: deliveryTagRoute,
  [Pages.DispatchConfig]: dispatchConfigRoute,
  [Pages.Delivery]: deliveryRoute,
  [Pages.DeliveryExportResult]: deliveryExportResultsRoute,
  [Pages.DeliveryPaymentOption]: deliveryPaymentOptionRoute,
  [Pages.DeliveryPriceOption]: deliveryPriceOptionRoute,
  [Pages.DeliveryRide]: deliveryRideRoute,
  [Pages.DeliveryRideOption]: deliveryRideOptionRoute,
  [Pages.DeliveryRidePriceOption]: deliveryRidePriceOptionRoute,
  [Pages.DeliveryRideProductOption]: deliveryRideProductOptionRoute,
  [Pages.DeliveryRideRequest]: deliveryRideRequestRoute,
  [Pages.DeliverySchedule]: deliveryScheduleRoute,
  [Pages.DepositTransaction]: depositTransactionRoute,
  [Pages.DriverTutorial]: driverTutorialRoute,
  [Pages.DynamicSurge]: dynamicSurgeRoute,
  [Pages.DynamicSurgeMonitor]: dynamicSurgeMonitorRoute,
  [Pages.ExportSchedule]: exportScheduleRoute,
  [Pages.FraudCard]: fraudCardRoute,
  [Pages.FraudLog]: fraudLogRoute,
  [Pages.HourlyRentalCar]: hourlyRentalCarRoute,
  [Pages.HourlyRentalRequest]: hourlyRentalRequestRoute,
  [Pages.Inbox]: inboxItemRoute,
  [Pages.InstantSurgeRule]: instantSurgeRuleRoute,
  [Pages.Merchant]: merchantRoute,
  [Pages.MomopayTransactions]: momopayTransactionsRoute,
  [Pages.PaoTransactions]: paoTransactionsRoute,
  [Pages.PaymentTransaction]: paymentTransactionRoute,
  [Pages.Penalty]: penaltyRoute,
  [Pages.PipayTransactions]: pipayTransactionsRoute,
  [Pages.Place]: placeRoute,
  [Pages.Polygons]: polygonsRoute,
  [Pages.PriceVerification]: priceVerificationRoute,
  [Pages.ProfilePictureChange]: profilePictureChangeRoute,
  [Pages.Promotion]: promotionRoute,
  [Pages.Regions]: regionsRoute,
  [Pages.RestrictedAreas]: restrictedAreasRoute,
  [Pages.Reviews]: reviewsRoute,
  [Pages.RideProducts]: rideProductsRoute,
  [Pages.Roles]: rolesRoute,
  [Pages.RoutingResult]: routingResultRoute,
  [Pages.AdditionalPriceRules]: scheduleSurgeRoute,
  [Pages.ShbRequestHistory]: shbRequestHistoryRoute,
  [Pages.ShbWallet]: shbWalletRoute,
  [Pages.SMSTemplate]: SMSTemplateRoute,
  [Pages.RouteTest]: routeTestRoute,
  [Pages.Transaction]: transactionRoute,
  [Pages.Warehouse]: warehouseRoute,
  [Pages.Zones]: zonesRoute,
  [Pages.Withdraw]: withdrawRoute,
  [Pages.DriverIncentive]: driverIncentiveRoute,
  [Pages.BankMaintenance]: maintenanceRoute,
  [Pages.PrefixCoupon]: prefixCouponRoute,
  [Pages.TadaMaintenance]: tadaMaintenanceRoute,
  [Pages.BankInfo]: bankInfoRoute,
  [Pages.FaqListManagement]: faqListManagementRoute,
  [Pages.FaqTopicManagement]: faqTopicManagementRoute,
  [Pages.OtpSkipRule]: otpSkipRuleRouter,
  [Pages.Referral]: referralRoute,
  [Pages.ShbLoanInfo]: shbLoanInfoRoute,
  [Pages.CorporateInvoice]: corporateInvoiceRoute,
  [Pages.AbaTransaction]: abaTransactionRoute,
  [Pages.DocumentGuideManagement]: documentGuideManagementRoute,
  [Pages.ScheduleSurgePreset]: scheduleSurgePresetRoute,
  [Pages.HelpCenterTopicManagement]: helpCenterTopicManagementRoute,
  [Pages.HelpCenterListManagement]: helpCenterListManagementRoute,
  [Pages.PlaceTester]: placeTesterRoute,
  [Pages.RecoverDeletedAccount]: recoverDeletedAccountRoute,
  [Pages.AdyenTransaction]: adyenTransactionRoute,
  [Pages.WingTransaction]: wingTransactionRoute,
  [Pages.News]: newsRoute,
  [Pages.AofAccount]: aofAccountRoute,
  [Pages.RideProductInfoTemplate]: RideProductInfoTemplateRoute,
  [Pages.TaxPrefillDescription]: taxPrefillDescriptionRoute,
  [Pages.GooglePlace]: GooglePlaceRoute,
  [Pages.SurgeCap]: surgeCapRoute,
  [Pages.SafetyAccidentTopicManagement]: safetyAccidentTopicManagementRoute,
  [Pages.SafetyAccidentListManagement]: safetyAccidentListManagementRoute,
  [Pages.UserSubmission]: userSubmissionRoute,
  [Pages.PreventMatch]: preventMatchRoute,
  [Pages.TollGantry]: tollGantryRoute,
  [Pages.TollTimeTable]: tollTimeTableRoute,
  [Pages.TollFreeDate]: tollFreeDateRoute,
  [Pages.SupportPageManagement]: supportPageManagementRoute,
  [Pages.RiderMission]: riderMissionRoute,
  [Pages.RemarkLogSetting]: remarkLogSettingRoute,
  [Pages.VehicleMaker]: vehicleMakerRoute,
  [Pages.BINManagement]: binManagementRoute,
  [Pages.CpfContributionTable]: cpfContributionTableRoute,
  [Pages.OtpWhitelist]: otpWhitelistRouter,
  [Pages.DynamicSurgeSetting]: dynamicSurgeSettingRoute,
};

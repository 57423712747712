export const PAO_TRANSACTION_COLUMNS = {
  ID: 'id',
  RENTAL_NUMBER: 'rentalNumber',
  STATUS: 'status',
  STAN: 'stan',
  AMOUNT: 'amount',
  APPLICATION_FEE: 'applicationFee',
} as const;

export const PAO_TRANSACTION_COLUMNS_LIST = Object.values(PAO_TRANSACTION_COLUMNS);

export const PAO_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: PAO_TRANSACTION_COLUMNS.ID, label: 'Id' },
  { key: PAO_TRANSACTION_COLUMNS.RENTAL_NUMBER, label: 'Ride Id' },
  { key: PAO_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: PAO_TRANSACTION_COLUMNS.STAN, label: 'Stan' },
  { key: PAO_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: PAO_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Application Fee' },
];

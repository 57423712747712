import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CouponBulkCancelResponse, CouponCreateDto } from '@/pages/Coupon/type';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface CouponState extends PaginationStoreState, StandardStoreState {}

const initialState: CouponState = {
  error: null,
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
};

const failure = (state: CouponState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

export const updateCouponStatusRequest = createAction('coupon/updateCouponStatusRequest', (id, status) => ({
  payload: { id, status },
}));

export const publishCouponRequest = createAction('coupon/publishCouponRequest', (id, userId) => ({
  payload: { id, userId },
}));

export const downloadCouponBulkCancelTemplateRequest = createAction('coupon/downloadCouponBulkCancelTemplateRequest');
export const downloadCouponBulkCancelTemplateSuccess = createAction('coupon/downloadCouponBulkCancelTemplateSuccess');
export const downloadCouponBulkCancelTemplateFailure = createAction<string>('coupon/downloadCouponBulkCancelTemplateFailure');

export const bulkCancelCouponRequest = createAction<FormData>('coupon/bulkCancelCouponRequest');
export const bulkCancelCouponSuccess = createAction<CouponBulkCancelResponse>('coupon/bulkCancelCouponSuccess');
export const bulkCancelCouponFailure = createAction<string>('coupon/bulkCancelCouponFailure');

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    createCouponRequest: {
      prepare: (body: CouponCreateDto) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    createCouponSuccess(state) {
      state.isFetching = false;
    },
    createCouponFailure: failure,

    bulkCreateCouponRequest: {
      prepare: (body: CouponCreateDto) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    bulkCreateCouponSuccess(state) {
      state.isFetching = false;
    },
    bulkCreateCouponFailure: failure,

    publishCouponSuccess(state, { payload }) {
      state.byId = {
        ...state.byId,
        [payload.id]: {
          ...state.byId[payload.id],
          ...payload,
        },
      };
    },
    publishCouponFailure(state, { payload }) {
      state.error = payload;
    },

    updateCouponStatusSuccess(state, { payload }) {
      state.byId = {
        ...state.byId,
        [payload.id]: {
          ...state.byId[payload.id],
          ...payload,
        },
      };
    },
    updateCouponStatusFailure(state, { payload }) {
      state.error = payload;
    },
  },
});

export const {
  createCouponRequest,
  createCouponSuccess,
  createCouponFailure,
  bulkCreateCouponRequest,
  bulkCreateCouponSuccess,
  bulkCreateCouponFailure,
  publishCouponSuccess,
  publishCouponFailure,
  updateCouponStatusSuccess,
  updateCouponStatusFailure,
} = couponSlice.actions;

export default couponSlice.reducer;

import { ToastContainer as _ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import FadeInAndOut from '@/utils/transitionAnimation';

function ToastContainer() {
  return <_ToastContainer transition={FadeInAndOut} />;
}

export default ToastContainer;

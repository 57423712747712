import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MobileSvcState, Mobile } from '@/svc/mobile-svc/type';
import Service from '@/svc/serviceName';

const initialState: MobileSvcState = {
  byUserId: {},
};

export const fetchMobilesByUserIdRequest = createAction(`${Service.mobileSvc}/fetchMobilesByUserIdRequest`, (userId: string) => ({
  payload: userId,
}));

export const fetchMobilesByUserIdFailure = createAction(`${Service.mobileSvc}/fetchMobilesByUserIdFailure`, (error: string) => ({
  payload: error,
}));

const mobileSvcSlice = createSlice({
  name: Service.mobileSvc,
  initialState,
  reducers: {
    fetchMobilesByUserIdSuccess: {
      prepare: (id: string, mobiles: Mobile[]) => ({ payload: { id, mobiles } }),
      reducer: (state, { payload: { id, mobiles } }: PayloadAction<{ id: string; mobiles: Mobile[] }>) => {
        state.byUserId[id] = mobiles;
      },
    },
  },
});

export const { fetchMobilesByUserIdSuccess } = mobileSvcSlice.actions;

export default mobileSvcSlice.reducer;

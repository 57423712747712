import { createSlice } from '@reduxjs/toolkit';

import maintenanceReducer, { maintenanceInitialState } from '@/svc/inbox-svc/maintenance/reducer';
import { InboxServiceState } from '@/svc/inbox-svc/type';
import Service from '@/svc/serviceName';

const initialState: InboxServiceState = {
  maintenance: maintenanceInitialState,
};

const inboxSlice = createSlice({
  name: Service.inboxSvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.maintenance, maintenanceReducer(state.maintenance, action));
    }),
});

// export const {} = inboxSlice.actions;

export default inboxSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "PaoTransactionsList" */ '@/pages/PaoTransactions/List'));
const Detail = lazy(() => import(/* webpackChunkName: "PaoTransactionsDetail" */ '@/pages/PaoTransactions/Detail'));

export const paoTransactionsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.PaoTransactions,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.PaoTransactions}/:id`,
    component: Detail,
    exact: true,
  },
];

import { PaginationItem as PaginationItemComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface PaginationItemProps extends React.HTMLAttributes<HTMLElement> {
  cssModule?: CSSModule;
  active?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
}

export function PaginationItem(props: PaginationItemProps) {
  return <PaginationItemComponent {...props} />;
}

import FileSaver from 'file-saver';
import { schema } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import deliveryRideRequestSaga from '@/svc/delivery-svc/deliveryRideRequest/saga';
import deliveryRideRouteOptionSaga from '@/svc/delivery-svc/deliveryRideRouteOption/saga';
import deliveryScheduleSaga from '@/svc/delivery-svc/deliverySchedule/saga';
import merchantSaga from '@/svc/delivery-svc/merchant/saga';
import pendingRideSaga from '@/svc/delivery-svc/pendingRide/saga';
import {
  addMerchantEmailRequest,
  addMerchantEmailSuccess,
  addMerchantEmailFailure,
  fetchDeliveryKeyInByIdRequest,
  fetchDeliveryKeyInByIdSuccess,
  exportDeliveryIdListRequest,
  exportDeliveryIdListSuccess,
  exportDeliveryIdListFailure,
  toggleDeliveryPriorityRequest,
  toggleDeliveryPrioritySuccess,
  toggleDeliveryPriorityFailure,
  addAgentCustomPlanRequest,
  addAgentCustomPlanSuccess,
  addAgentCustomPlanFailure,
  deleteAgentCustomPlanRequest,
  deleteAgentCustomPlanSuccess,
  deleteAgentCustomPlanFailure,
  fetchDeliveryPaymentGroupRequest,
  fetchDeliveryPaymentGroupSuccess,
  fetchDeliveryPaymentGroupFailure,
  updateMerchantSmartPricingConfigRequest,
  updateMerchantSmartPricingConfigFailure,
  updateMerchantSmartPricingConfigSuccess,
  createMerchantCustomSmartPricingPlanRequest,
  createMerchantCustomSmartPricingPlanFailure,
  createMerchantCustomSmartPricingPlanSuccess,
  deleteMerchantCustomSmartPricingPlanRequest,
  deleteMerchantCustomSmartPricingPlanSuccess,
  deleteMerchantCustomSmartPricingPlanFailure,
  fetchAvailableRideProductsRequest,
  fetchAvailableRideProductsFailure,
  fetchAvailableRideProductsSuccess,
  createDeliveryRequest,
  createDeliverySuccess,
  createDeliveryFailure,
  updateDeliveryRequest,
  updateDeliverySuccess,
  updateDeliveryFailure,
  updateDeliveryStatusRequest,
  updateDeliveryStatusSuccess,
  updateDeliveryStatusFailure,
  deleteDeliveryRequest,
  deleteDeliverySuccess,
  deleteDeliveryFailure,
  confirmArrivalRequest,
  confirmArrivalSuccess,
  confirmArrivalFailure,
  confirmDepartureRequest,
  confirmDepartureSuccess,
  confirmDepartureFailure,
  confirmArrivalsRequest,
  confirmArrivalsSuccess,
  confirmArrivalsFailure,
  confirmDeparturesSuccess,
  confirmDeparturesFailure,
  confirmDeparturesRequest,
  confirmMissingRequest,
  confirmMissingSuccess,
  confirmMissingFailure,
  confirmMissingsRequest,
  confirmMissingsSuccess,
  confirmMissingsFailure,
  finishDeliveryRequest,
  finishDeliverySuccess,
  finishDeliveryFailure,
  sendSmsToDeliveryRequest,
  sendSmsToDeliverySuccess,
  sendSmsToDeliveryFailure,
  sendSmsToDeliveryBulkRequest,
  sendSmsToDeliveryBulkSuccess,
  sendSmsToDeliveryBulkFailure,
  failDeliveryRequest,
  failDeliverySuccess,
  failDeliveryFailure,
  expireDeliveryRequest,
  expireDeliverySuccess,
  expireDeliveryFailure,
  expireDeliveriesRequest,
  expireDeliveriesSuccess,
  expireDeliveriesFailure,
  confirmPickupOrderRequest,
  confirmPickupOrderSuccess,
  confirmPickupOrderFailure,
  confirmDropoffOrderRequest,
  confirmDropoffOrderSuccess,
  confirmDropoffOrderFailure,
  confirmPickupOrdersSuccess,
  confirmPickupOrdersFailure,
  confirmPickupOrdersRequest,
  confirmDropoffOrdersRequest,
  confirmDropoffOrdersSuccess,
  confirmDropoffOrdersFailure,
  confirmReturnOrderSuccess,
  confirmReturnOrderFailure,
  confirmReturnOrderRequest,
  confirmReturnOrdersRequest,
  confirmReturnOrdersSuccess,
  confirmReturnOrdersFailure,
  confirmTransferOrderRequest,
  confirmTransferOrderSuccess,
  confirmTransferOrderFailure,
  confirmTransferOrdersRequest,
  confirmTransferOrdersSuccess,
  confirmTransferOrdersFailure,
  exportDeliveryRequest,
  exportDeliverySuccess,
  exportDeliveryFailure,
  updateCommentRequest,
  updateCommentSuccess,
  updateCommentFailure,
  updateDeliveryMoldByDeliveryIdRequest,
  updateDeliveryMoldByDeliveryIdSuccess,
  updateDeliveryMoldByDeliveryIdFailure,
  rescheduleDeliveriesRequest,
  rescheduleDeliveriesSuccess,
  rescheduleDeliveriesFailure,
  unScheduleDeliveriesRequest,
  unScheduleDeliveriesSuccess,
  unScheduleDeliveriesFailure,
  finishDeliveriesRequest,
  finishDeliveriesSuccess,
  finishDeliveriesFailure,
  unScheduleDeliveryRequest,
  unScheduleDeliverySuccess,
  unScheduleDeliveryFailure,
  updateDeliveryPriceRequest,
  updateDeliveryPriceSuccess,
  updateDeliveryPriceFailure,
  returnDeliveryRequest,
  returnDeliverySuccess,
  returnDeliveryFailure,
  returnDeliveriesRequest,
  returnDeliveriesSuccess,
  returnDeliveriesFailure,
  recalculateDeliveryPriceRequest,
  recalculateDeliveryPriceSuccess,
  recalculateDeliveryPriceFailure,
  downloadTemplateRequest,
  preCreateFromCsvRequest,
  preCreateFromCsvSuccess,
  preCreateFromCsvFailure,
  createBulkDeliveryRequest,
  createBulkDeliverySuccess,
  createBulkDeliveryFailure,
  createContactLogRequest,
  createContactLogSuccess,
  createContactLogFailure,
  rollbackDeliveriesRequest,
  rollbackDeliveriesSuccess,
  rollbackDeliveriesFailure,
  rollbackDeliveryByIdRequest,
  rollbackDeliveryByIdSuccess,
  rollbackDeliveryByIdFailure,
  fetchDeliveryListFailure,
  fetchDeliveryListRequest,
  fetchDeliveryListSuccess,
  fetchDeliveryDetailRequest,
  fetchDeliveryDetailSuccess,
  fetchDeliveryDetailFailure,
  fetchDeliveryGlobalConfigSuccess,
  fetchDeliveryGlobalConfigFailure,
  fetchDeliveryGlobalConfigRequest,
  fetchDeliveryRequestInfoRequest,
  fetchDeliveryRequestInfoSuccess,
  fetchDeliveryRequestInfoFailure,
  preparePrintBulkDeliveryRequest,
  preparePrintBulkDeliverySuccess,
  preparePrintBulkDeliveryFailure,
  templatePayloadRequest,
  templatePayloadSuccess,
  templatePayloadFailure,
} from '@/svc/delivery-svc/reducer';
import smsTemplateSaga from '@/svc/delivery-svc/smsTemplate/saga';
import deliveryTagSaga from '@/svc/delivery-svc/tag/saga';
import warehouseSaga from '@/svc/delivery-svc/warehouse/saga';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchDeliveryKeyInById({ payload }: ReturnType<typeof fetchDeliveryKeyInByIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/key_in/by_id', { params: { idList: payload } });

    if (response.ok) {
      yield put(fetchDeliveryKeyInByIdSuccess(response.data));
    }
  } catch (e) {}
}

function* exportDeliveryIdList({ payload }: ReturnType<typeof exportDeliveryIdListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery:id_list:export', {
      params: { idList: payload },
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'delivery-export.xlsx');

      yield put(exportDeliveryIdListSuccess());
    } else {
      yield put(exportDeliveryIdListFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportDeliveryIdListFailure((e as Error).message));
  }
}

function* toggleDeliveryPriority({ payload: { id, body } }: ReturnType<typeof toggleDeliveryPriorityRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/delivery_priority/toggle`, { data: body });

    if (response.ok) {
      yield put(toggleDeliveryPrioritySuccess(response.data));
    } else {
      yield put(toggleDeliveryPriorityFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleDeliveryPriorityFailure((e as Error).message));
  }
}

function* addMerchantEmail({ payload: { id, body } }: ReturnType<typeof addMerchantEmailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/sender/${id}/email`, { data: body });

    if (response.ok) {
      yield put(addMerchantEmailSuccess(response.data));
    } else {
      yield put(addMerchantEmailFailure(response.data.message));
    }
  } catch (e) {
    yield put(addMerchantEmailFailure((e as Error).message));
  }
}

function* addAgentCustomPlan({ payload: { id, data } }: ReturnType<typeof addAgentCustomPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/agent_fee`, { data });

    if (response.ok) {
      yield put(addAgentCustomPlanSuccess(response.data));
    } else {
      yield put(addAgentCustomPlanFailure(response.data.message));
    }
  } catch (e) {
    yield put(addAgentCustomPlanFailure((e as Error).message));
  }
}

function* deleteAgentCustomPlan({ payload: { id } }: ReturnType<typeof deleteAgentCustomPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/agent_fee`);

    if (response.ok) {
      yield put(deleteAgentCustomPlanSuccess(response.data));
    } else {
      yield put(deleteAgentCustomPlanFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteAgentCustomPlanFailure((e as Error).message));
  }
}

function* fetchDeliveryPaymentGroup({ payload: { id, stakeHolder } }: ReturnType<typeof fetchDeliveryPaymentGroupRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/${id}/payment_group?deliveryStakeholder=${stakeHolder}`);

    const action = response.ok ? fetchDeliveryPaymentGroupSuccess(id, response.data) : fetchDeliveryPaymentGroupFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDeliveryPaymentGroupFailure((e as Error).message));
  }
}

function* updateMerchantSmartPricingConfig({ payload: { id, data } }: ReturnType<typeof updateMerchantSmartPricingConfigRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/smart_pricing`, { data });

    const callbackAction = response.ok ? updateMerchantSmartPricingConfigSuccess() : updateMerchantSmartPricingConfigFailure(response.data.message);

    yield put(callbackAction);
  } catch (e) {
    yield put(updateMerchantSmartPricingConfigFailure((e as Error).message));
  }
}

function* createMerchantCustomSmartPricingPlan({
  payload: { id, data },
}: ReturnType<typeof createMerchantCustomSmartPricingPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/sender/${id}/smart_pricing/plan`, { data });

    const callbackAction = response.ok
      ? createMerchantCustomSmartPricingPlanSuccess()
      : createMerchantCustomSmartPricingPlanFailure(response.data.message);

    yield put(callbackAction);
  } catch (e) {
    yield put(createMerchantCustomSmartPricingPlanFailure((e as Error).message));
  }
}

function* deleteMerchantCustomSmartPricingPlan({
  payload: { id, data },
}: ReturnType<typeof deleteMerchantCustomSmartPricingPlanRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/sender/${id}/smart_pricing/plan`, { data });

    const callbackAction = response.ok
      ? deleteMerchantCustomSmartPricingPlanSuccess()
      : deleteMerchantCustomSmartPricingPlanFailure(response.data.message);

    yield put(callbackAction);
  } catch (e) {
    yield put(deleteMerchantCustomSmartPricingPlanFailure((e as Error).message));
  }
}

function* fetchAvailableRideProducts({ payload }: ReturnType<typeof fetchAvailableRideProductsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/available_ride_products', { params: payload });

    const action = response.ok ? fetchAvailableRideProductsSuccess(response.data) : fetchAvailableRideProductsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchAvailableRideProductsFailure((e as Error).message));
  }
}

function* fetchDeliveryList({ payload }: ReturnType<typeof fetchDeliveryListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery', { params: payload, schema: { content: [entity] } });

    if (response.ok) {
      yield put(fetchDeliveryListSuccess(response.data));
    } else {
      yield put(fetchDeliveryListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryListFailure((e as Error).message));
  }
}

function* fetchDeliveryDetail({ payload }: ReturnType<typeof fetchDeliveryDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchDeliveryDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryDetailFailure((e as Error).message));
  }
}

function* fetchDeliveryGlobalConfig(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config');

    if (response.ok) {
      yield put(fetchDeliveryGlobalConfigSuccess(response.data));
    } else {
      yield put(fetchDeliveryGlobalConfigFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryGlobalConfigFailure((e as Error).message));
  }
}

function* fetchDeliveryRequestInfo({ payload }: ReturnType<typeof fetchDeliveryRequestInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/deliveryRequests/${payload}`);

    if (response.ok) {
      yield put(fetchDeliveryRequestInfoSuccess(response.data));
    } else {
      yield put(fetchDeliveryRequestInfoFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRequestInfoFailure((e as Error).message));
  }
}

function* createDelivery({ payload }: ReturnType<typeof createDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery', { data: payload });

    const action = response.ok ? createDeliverySuccess() : createDeliveryFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDeliveryFailure((e as Error).message));
  }
}

function* updateDelivery({ payload: { id, data } }: ReturnType<typeof updateDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${id}/update_info`, { data });

    const action = response.ok ? updateDeliverySuccess() : updateDeliveryFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDeliveryFailure((e as Error).message));
  }
}

function* updateDeliveryStatus({ payload: { id, data } }: ReturnType<typeof updateDeliveryStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${id}`, { data });

    const action = response.ok ? updateDeliveryStatusSuccess() : updateDeliveryStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDeliveryStatusFailure((e as Error).message));
  }
}

function* deleteDelivery({ payload }: ReturnType<typeof deleteDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/${payload}`);

    const action = response.ok ? deleteDeliverySuccess() : deleteDeliveryFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteDeliveryFailure((e as Error).message));
  }
}

function* confirmArrival({ payload }: ReturnType<typeof confirmArrivalRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/confirm_arrival`);

    const action = response.ok ? confirmArrivalSuccess() : confirmArrivalFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(confirmArrivalFailure((e as Error).message));
  }
}

function* confirmDeparture({ payload }: ReturnType<typeof confirmDepartureRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/confirm_departure`);

    const action = response.ok ? confirmDepartureSuccess() : confirmDepartureFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(confirmDepartureFailure((e as Error).message));
  }
}

//

function* confirmArrivals({ payload }: ReturnType<typeof confirmArrivalsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_arrival', { data: payload });

    if (response.ok) {
      yield put(confirmArrivalsSuccess());
    } else {
      yield put(confirmArrivalsFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmArrivalsFailure((e as Error).message));
  }
}

function* confirmDepartures({ payload }: ReturnType<typeof confirmDeparturesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_departure', { data: payload });

    if (response.ok) {
      yield put(confirmDeparturesSuccess());
    } else {
      yield put(confirmDeparturesFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmDeparturesFailure((e as Error).message));
  }
}

function* confirmMissing({ payload }: ReturnType<typeof confirmMissingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/confirm_missing`);

    if (response.ok) {
      yield put(confirmMissingSuccess());
    } else {
      yield put(confirmMissingFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmMissingFailure((e as Error).message));
  }
}

function* confirmMissings({ payload }: ReturnType<typeof confirmMissingsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_missing', { data: payload });

    if (response.ok) {
      yield put(confirmMissingsSuccess());
    } else {
      yield put(confirmMissingsFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmMissingsFailure((e as Error).message));
  }
}

function* finishDelivery({ payload: { id, data } }: ReturnType<typeof finishDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${id}/finish`, { data: data });

    if (response.ok) {
      yield put(finishDeliverySuccess());
    } else {
      yield put(finishDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(finishDeliveryFailure((e as Error).message));
  }
}

function* sendSmsToDelivery({ payload }: ReturnType<typeof sendSmsToDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/send_sms`);

    if (response.ok) {
      yield put(sendSmsToDeliverySuccess());
    } else {
      yield put(sendSmsToDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(sendSmsToDeliveryFailure((e as Error).message));
  }
}

function* sendSmsToDeliveryBulk({ payload }: ReturnType<typeof sendSmsToDeliveryBulkRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/send_sms', { data: { deliveryIdList: payload } });

    if (response.ok) {
      yield put(sendSmsToDeliveryBulkSuccess());
    } else {
      yield put(sendSmsToDeliveryBulkFailure(response.data.message));
    }
  } catch (e) {
    yield put(sendSmsToDeliveryBulkFailure((e as Error).message));
  }
}

function* failDelivery({ payload: { id, data } }: ReturnType<typeof failDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${id}/fail`, { data });

    if (response.ok) {
      yield put(failDeliverySuccess());
    } else {
      yield put(failDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(failDeliveryFailure((e as Error).message));
  }
}

function* expireDelivery({ payload }: ReturnType<typeof expireDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/expire`);

    if (response.ok) {
      yield put(expireDeliverySuccess());
    } else {
      yield put(expireDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(expireDeliveryFailure((e as Error).message));
  }
}

function* expireDeliveries({ payload }: ReturnType<typeof expireDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/expire', { data: payload });

    if (response.ok) {
      yield put(expireDeliveriesSuccess());
    } else {
      yield put(expireDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(expireDeliveriesFailure((e as Error).message));
  }
}

function* confirmPickupOrder({ payload }: ReturnType<typeof confirmPickupOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/confirm_pickup_order`, { data: payload.data });

    if (response.ok) {
      yield put(confirmPickupOrderSuccess());
    } else {
      yield put(confirmPickupOrderFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmPickupOrderFailure((e as Error).message));
  }
}

function* confirmDropoffOrder({ payload }: ReturnType<typeof confirmDropoffOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/confirm_dropoff_order`, { data: payload.data });

    if (response.ok) {
      yield put(confirmDropoffOrderSuccess());
    } else {
      yield put(confirmDropoffOrderFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmDropoffOrderFailure((e as Error).message));
  }
}

function* confirmPickupOrders({ payload }: ReturnType<typeof confirmPickupOrdersRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_pickup_order', { data: payload });

    if (response.ok) {
      yield put(confirmPickupOrdersSuccess());
    } else {
      yield put(confirmPickupOrdersFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmPickupOrdersFailure((e as Error).message));
  }
}

function* confirmDropoffOrders({ payload }: ReturnType<typeof confirmDropoffOrdersRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_dropoff_order', { data: payload });

    if (response.ok) {
      yield put(confirmDropoffOrdersSuccess());
    } else {
      yield put(confirmDropoffOrdersFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmDropoffOrdersFailure((e as Error).message));
  }
}

function* confirmReturnOrder({ payload }: ReturnType<typeof confirmReturnOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/confirm_return_order`, { data: payload.data });

    if (response.ok) {
      yield put(confirmReturnOrderSuccess());
    } else {
      yield put(confirmReturnOrderFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmReturnOrderFailure((e as Error).message));
  }
}

function* confirmReturnOrders({ payload }: ReturnType<typeof confirmReturnOrdersRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_return_order', { data: payload });

    if (response.ok) {
      yield put(confirmReturnOrdersSuccess());
    } else {
      yield put(confirmReturnOrdersFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmReturnOrdersFailure((e as Error).message));
  }
}

function* confirmTransferOrder({ payload }: ReturnType<typeof confirmTransferOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/confirm_transfer_order`, { data: payload.data });

    if (response.ok) {
      yield put(confirmTransferOrderSuccess());
    } else {
      yield put(confirmTransferOrderFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmTransferOrderFailure((e as Error).message));
  }
}

function* confirmTransferOrders({ payload }: ReturnType<typeof confirmTransferOrdersRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_transfer_order', { data: payload });

    if (response.ok) {
      yield put(confirmTransferOrdersSuccess());
    } else {
      yield put(confirmTransferOrdersFailure(response.data.message));
    }
  } catch (e) {
    yield put(confirmTransferOrdersFailure((e as Error).message));
  }
}

function* exportDelivery({ payload }: ReturnType<typeof exportDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery:export', { params: payload, responseType: 'blob' });
    if (response.ok) {
      yield saveFile(response, `delivery-${payload.timeRequestedFrom}~${payload.timeRequestedTo}.xlsx`);
      yield put(exportDeliverySuccess());
    } else {
      yield put(exportDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportDeliveryFailure((e as Error).message));
  }
}

function* updateComment({ payload }: ReturnType<typeof updateCommentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${payload.id}/update_comment`, { data: payload.data });

    if (response.ok) {
      yield put(updateCommentSuccess());
    } else {
      yield put(updateCommentFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateCommentFailure((e as Error).message));
  }
}

function* updateDeliveryMoldByDeliveryId({ payload }: ReturnType<typeof updateDeliveryMoldByDeliveryIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${payload.id}/mold`, { data: payload.data });

    if (response.ok) {
      yield put(updateDeliveryMoldByDeliveryIdSuccess());
    } else {
      yield put(updateDeliveryMoldByDeliveryIdFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryMoldByDeliveryIdFailure((e as Error).message));
  }
}

function* rescheduleDeliveries({ payload }: ReturnType<typeof rescheduleDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/reschedule', { data: payload });

    if (response.ok) {
      yield put(rescheduleDeliveriesSuccess());
    } else {
      yield put(rescheduleDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(rescheduleDeliveriesFailure((e as Error).message));
  }
}

function* unScheduleDeliveries({ payload }: ReturnType<typeof unScheduleDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_unschedulable_order', { data: payload });

    if (response.ok) {
      yield put(unScheduleDeliveriesSuccess());
    } else {
      yield put(unScheduleDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(unScheduleDeliveriesFailure((e as Error).message));
  }
}

function* finishDeliveries({ payload }: ReturnType<typeof finishDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/finish', { data: payload });

    if (response.ok) {
      yield put(finishDeliveriesSuccess());
    } else {
      yield put(finishDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(finishDeliveriesFailure((e as Error).message));
  }
}

function* unScheduleDelivery({ payload }: ReturnType<typeof unScheduleDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/confirm_unschedulable_order`);

    if (response.ok) {
      yield put(unScheduleDeliverySuccess());
    } else {
      yield put(unScheduleDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(unScheduleDeliveryFailure((e as Error).message));
  }
}

function* updateDeliveryPrice({ payload }: ReturnType<typeof updateDeliveryPriceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${payload.id}/update_price`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(updateDeliveryPriceSuccess());
    } else {
      yield put(updateDeliveryPriceFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryPriceFailure((e as Error).message));
  }
}

function* returnDelivery({ payload }: ReturnType<typeof returnDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/return`, { data: payload.data });

    if (response.ok) {
      yield put(returnDeliverySuccess());
    } else {
      yield put(returnDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(returnDeliveryFailure((e as Error).message));
  }
}

function* returnDeliveries({ payload }: ReturnType<typeof returnDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/return', { data: payload });

    if (response.ok) {
      yield put(returnDeliveriesSuccess());
    } else {
      yield put(returnDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(returnDeliveriesFailure((e as Error).message));
  }
}

function* recalculateDeliveryPrice({ payload }: ReturnType<typeof recalculateDeliveryPriceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/${payload}/recalculate_price`);

    if (response.ok) {
      yield put(recalculateDeliveryPriceSuccess());
    } else {
      yield put(recalculateDeliveryPriceFailure(response.data.message));
    }
  } catch (e) {
    yield put(recalculateDeliveryPriceFailure((e as Error).message));
  }
}

function* downloadTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/file/create_deliveries.csv', {
      responseType: 'blob',
    });

    if (response.ok) {
      FileSaver.saveAs(response.data, 'delivery_template.csv');
    }
  } catch (e) {}
}

function* preCreateFromCsv({ payload: { userFromId, priority, body } }: ReturnType<typeof preCreateFromCsvRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/csv/pre?userFromId=${userFromId}&priority=${priority}`, {
      data: body,
      timeout: 5 * 60 * 1000,
    });

    if (response.ok) {
      yield put(preCreateFromCsvSuccess(response.data));
    } else {
      let errorData = null;

      if (response.data.reasonsInvalid) {
        errorData = response.data;
      } else if (response.data.error.reasonsInvalid) {
        errorData = response.data.error;
      }
      yield put(preCreateFromCsvFailure({ error: response.data.message, bulk: errorData }));
    }
  } catch (e) {
    yield put(preCreateFromCsvFailure({ error: (e as Error).message, bulk: null }));
  }
}

function* createBulkDelivery({ payload }: ReturnType<typeof createBulkDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/bulk', { data: payload, timeout: 3 * 60 * 1000 });

    if (response.ok) {
      yield put(createBulkDeliverySuccess(response.data));
    } else {
      let errorData = null;

      if (response.data.reasonsInvalid) {
        errorData = response.data;
      } else if (response.data.error.reasonsInvalid) {
        errorData = response.data.error;
      }
      yield put(createBulkDeliveryFailure(response.data.message, errorData));
    }
  } catch (e) {
    yield put(createBulkDeliveryFailure((e as Error).message, null));
  }
}

function* createContactLog({ payload }: ReturnType<typeof createContactLogRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.id}/contact`, { data: payload.data });

    if (response.ok) {
      yield put(createContactLogSuccess(response.data));
    } else {
      yield put(createContactLogFailure(response.data.message));
    }
  } catch (e) {
    yield put(createContactLogFailure((e as Error).message));
  }
}

function* rollbackDeliveries({ payload }: ReturnType<typeof rollbackDeliveriesRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/confirm_rollback_order', { data: payload });

    if (response.ok) {
      yield put(rollbackDeliveriesSuccess(response.data));
    } else {
      yield put(rollbackDeliveriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(rollbackDeliveriesFailure((e as Error).message));
  }
}

function* rollbackDeliveryById({ payload }: ReturnType<typeof rollbackDeliveryByIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload}/confirm_rollback_order`);

    if (response.ok) {
      yield put(rollbackDeliveryByIdSuccess(response.data));
    } else {
      yield put(rollbackDeliveryByIdFailure(response.data.message));
    }
  } catch (e) {
    yield put(rollbackDeliveryByIdFailure((e as Error).message));
  }
}

function* preparePrintBulkDelivery({ payload }: ReturnType<typeof preparePrintBulkDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/mold/${payload}/prepare_print`);

    if (response.ok) {
      yield put(preparePrintBulkDeliverySuccess(response.data));
    } else {
      yield put(preparePrintBulkDeliveryFailure(response.data.message));
    }
  } catch (e) {
    yield put(preparePrintBulkDeliveryFailure((e as Error).message));
  }
}

function* templatePayload({ payload }: ReturnType<typeof templatePayloadRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/${payload.deliveryId}/messageTemplate/${payload.id}/payloads`);

    if (response.ok) {
      yield put(templatePayloadSuccess(response.data));
    } else {
      yield put(templatePayloadFailure(response.data.message));
    }
  } catch (e) {
    yield put(templatePayloadFailure((e as Error).message));
  }
}

export default function* deliverySaga() {
  yield takeLatest(fetchDeliveryKeyInByIdRequest.type, fetchDeliveryKeyInById);
  yield takeLatest(exportDeliveryIdListRequest.type, exportDeliveryIdList);
  yield takeLatest(toggleDeliveryPriorityRequest.type, toggleDeliveryPriority);
  yield takeLatest(addMerchantEmailRequest.type, addMerchantEmail);
  yield takeLatest(addAgentCustomPlanRequest.type, addAgentCustomPlan);
  yield takeLatest(deleteAgentCustomPlanRequest.type, deleteAgentCustomPlan);
  yield takeLatest(fetchDeliveryPaymentGroupRequest.type, fetchDeliveryPaymentGroup);
  yield takeLatest(updateMerchantSmartPricingConfigRequest.type, updateMerchantSmartPricingConfig);
  yield takeLatest(createMerchantCustomSmartPricingPlanRequest.type, createMerchantCustomSmartPricingPlan);
  yield takeLatest(deleteMerchantCustomSmartPricingPlanRequest.type, deleteMerchantCustomSmartPricingPlan);
  yield takeLatest(fetchAvailableRideProductsRequest.type, fetchAvailableRideProducts);
  yield takeLatest(createDeliveryRequest.type, createDelivery);
  yield takeLatest(updateDeliveryRequest.type, updateDelivery);
  yield takeLatest(updateDeliveryStatusRequest.type, updateDeliveryStatus);
  yield takeLatest(deleteDeliveryRequest.type, deleteDelivery);
  yield takeLatest(confirmArrivalRequest.type, confirmArrival);
  yield takeLatest(confirmDepartureRequest.type, confirmDeparture);
  yield takeLatest(confirmArrivalsRequest.type, confirmArrivals);
  yield takeLatest(confirmDeparturesRequest.type, confirmDepartures);
  yield takeLatest(confirmMissingRequest.type, confirmMissing);
  yield takeLatest(confirmMissingsRequest.type, confirmMissings);
  yield takeLatest(finishDeliveryRequest.type, finishDelivery);
  yield takeLatest(sendSmsToDeliveryRequest.type, sendSmsToDelivery);
  yield takeLatest(sendSmsToDeliveryBulkRequest.type, sendSmsToDeliveryBulk);
  yield takeLatest(failDeliveryRequest.type, failDelivery);
  yield takeLatest(expireDeliveryRequest.type, expireDelivery);
  yield takeLatest(expireDeliveriesRequest.type, expireDeliveries);
  yield takeLatest(confirmPickupOrderRequest.type, confirmPickupOrder);
  yield takeLatest(confirmDropoffOrderRequest.type, confirmDropoffOrder);
  yield takeLatest(confirmPickupOrdersRequest.type, confirmPickupOrders);
  yield takeLatest(confirmDropoffOrdersRequest.type, confirmDropoffOrders);
  yield takeLatest(confirmReturnOrderRequest.type, confirmReturnOrder);
  yield takeLatest(confirmReturnOrdersRequest.type, confirmReturnOrders);
  yield takeLatest(confirmTransferOrderRequest.type, confirmTransferOrder);
  yield takeLatest(confirmTransferOrdersRequest.type, confirmTransferOrders);
  yield takeLatest(exportDeliveryRequest.type, exportDelivery);
  yield takeLatest(updateCommentRequest.type, updateComment);
  yield takeLatest(updateDeliveryMoldByDeliveryIdRequest.type, updateDeliveryMoldByDeliveryId);
  yield takeLatest(rescheduleDeliveriesRequest.type, rescheduleDeliveries);
  yield takeLatest(unScheduleDeliveriesRequest.type, unScheduleDeliveries);
  yield takeLatest(finishDeliveriesRequest.type, finishDeliveries);
  yield takeLatest(unScheduleDeliveryRequest.type, unScheduleDelivery);
  yield takeLatest(updateDeliveryPriceRequest.type, updateDeliveryPrice);
  yield takeLatest(returnDeliveryRequest.type, returnDelivery);
  yield takeLatest(returnDeliveriesRequest.type, returnDeliveries);
  yield takeLatest(recalculateDeliveryPriceRequest.type, recalculateDeliveryPrice);
  yield takeLatest(downloadTemplateRequest.type, downloadTemplate);
  yield takeLatest(preCreateFromCsvRequest.type, preCreateFromCsv);
  yield takeLatest(createBulkDeliveryRequest.type, createBulkDelivery);
  yield takeLatest(createContactLogRequest.type, createContactLog);
  yield takeLatest(rollbackDeliveriesRequest.type, rollbackDeliveries);
  yield takeLatest(rollbackDeliveryByIdRequest.type, rollbackDeliveryById);
  yield takeLatest(fetchDeliveryListRequest.type, fetchDeliveryList);
  yield takeLatest(fetchDeliveryDetailRequest.type, fetchDeliveryDetail);
  yield takeLatest(fetchDeliveryGlobalConfigRequest.type, fetchDeliveryGlobalConfig);
  yield takeLatest(fetchDeliveryRequestInfoRequest.type, fetchDeliveryRequestInfo);
  yield takeLatest(preparePrintBulkDeliveryRequest.type, preparePrintBulkDelivery);
  yield takeLatest(templatePayloadRequest.type, templatePayload);
  yield all([
    call(deliveryTagSaga),
    call(deliveryRideRequestSaga),
    call(smsTemplateSaga),
    call(warehouseSaga),
    call(pendingRideSaga),
    call(merchantSaga),
    call(deliveryRideRouteOptionSaga),
    call(deliveryScheduleSaga),
  ]);
}

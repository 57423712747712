import { createAction } from '@reduxjs/toolkit';

import Service from '@/svc/serviceName';

export const deleteCreditCardRequest = createAction<string>(`${Service.paySvc}/creditCards/deleteCreditCardRequest`);
export const deleteCreditCardSuccess = createAction(`${Service.paySvc}/creditCards/deleteCreditCardSuccess`);
export const deleteCreditCardFailure = createAction<string>(`${Service.paySvc}/creditCards/deleteCreditCardFailure`);

export const setFraudRequest = createAction<{ id: string; fraud: boolean }>(`${Service.paySvc}/creditCards/setFraudRequest`);
export const setFraudSuccess = createAction(`${Service.paySvc}/creditCards/setFraudSuccess`);
export const setFraudFailure = createAction<string>(`${Service.paySvc}/creditCards/setFraudFailure`);

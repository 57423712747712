import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  upsertSupportPageManagementRequest,
  upsertSupportPageManagementSuccess,
  upsertSupportPageManagementFailure,
  deleteSupportPageManagementRequest,
  deleteSupportPageManagementSuccess,
  deleteSupportPageManagementFailure,
} from '@/svc/cs-svc/supportPageManagement/reducer';
import { authenticatedRequest } from '@/utils/request';

const BASE_PATH = '/admin/supportpage';

function* upsertSupportPage({ payload }: ReturnType<typeof upsertSupportPageManagementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/upsert`, { data: payload });

    const action = response.ok ? upsertSupportPageManagementSuccess(response.data) : upsertSupportPageManagementFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertSupportPageManagementFailure((e as Error).message));
  }
}

function* deleteSupportPage({ payload }: ReturnType<typeof deleteSupportPageManagementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_PATH}/${payload}`);

    const action = response.ok ? deleteSupportPageManagementSuccess() : deleteSupportPageManagementFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteSupportPageManagementFailure((e as Error).message));
  }
}

export default function* supportPageManagementSaga() {
  yield takeLatest(upsertSupportPageManagementRequest.type, upsertSupportPage);
  yield takeLatest(deleteSupportPageManagementRequest.type, deleteSupportPage);
}

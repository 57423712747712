import { useCallback, useState } from 'react';

import { ToastOptions } from 'react-toastify';

import { DEFAULT_VALUE } from '@/assets/constants';
import { showSuccessToast } from '@/components/etc';
import { Input, InputProps } from '@/components/template';
import { useToastWithDispatch } from '@/hooks/toast';
import { useActionSubscribe } from '@/store/middleware/actionSubscribe';
import {
  fetchDeliveryDetailRequest,
  fetchDeliveryDetailSuccess,
  fetchDeliveryRequestInfoRequest,
  fetchDeliveryRequestInfoSuccess,
} from '@/svc/delivery-svc/reducer';
import { parseQRCode, QR_CODE_TYPE } from '@/utils/etc';

interface ScanQRInputProps extends InputProps {
  onSuccess: (id: string, type?: QR_CODE_TYPE) => void;
  isShowToast?: boolean;
  toastOptions?: ToastOptions;
}

export default function ScanDeliveryQRInput({ onSuccess, isShowToast = false, toastOptions, ...inputProps }: ScanQRInputProps) {
  const dispatch = useToastWithDispatch();

  const [id, setId] = useState<string>(DEFAULT_VALUE.STRING);

  const { id: qrId, type } = parseQRCode(id);

  const handleChange = useCallback((e: any) => {
    setId(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key !== 'Enter' || !id.trim()) {
        return;
      }

      switch (type) {
        case QR_CODE_TYPE.ID:
          dispatch(fetchDeliveryDetailRequest(qrId));
          return;
        case QR_CODE_TYPE.DR_ID:
          dispatch(fetchDeliveryRequestInfoRequest(qrId));
          return;
        // fallback parcelId
        default:
          onScanSuccess(qrId);
          return;
      }
    },
    [id]
  );

  const onScanSuccess = (id: string, type?: QR_CODE_TYPE) => {
    setId(DEFAULT_VALUE.STRING);
    onSuccess(id, type);

    isShowToast && showSuccessToast('Scan Success!', { autoClose: 1500, ...toastOptions });
  };

  useActionSubscribe(fetchDeliveryDetailSuccess.type, ({ payload }: ReturnType<typeof fetchDeliveryDetailSuccess>) => {
    onScanSuccess(payload.result, type);
  });

  useActionSubscribe(fetchDeliveryRequestInfoSuccess.type, ({ payload }: ReturnType<typeof fetchDeliveryRequestInfoSuccess>) => {
    const deliveryId = payload.delivery?.id;
    if (!deliveryId) {
      return;
    }

    dispatch(fetchDeliveryDetailRequest(deliveryId));
  });

  return <Input placeholder={'Scan QR Code...'} {...inputProps} type={'text'} value={id} onChange={handleChange} onKeyDown={handleKeyDown} />;
}

import { createAction, createSlice } from '@reduxjs/toolkit';

import { ReferralRewardRule, ReferralState, UpsertReferralRewardRuleBody } from '@/svc/member-svc/referral/type';
import Service from '@/svc/serviceName';
import { ErrorResponse } from '@/utils/types';

export const referralInitialState: ReferralState = {};

export const createReferralRequest = createAction<UpsertReferralRewardRuleBody>(`${Service.memberSvc}/referral/createReferralRequest`);
export const createReferralSuccess = createAction<ReferralRewardRule>(`${Service.memberSvc}/referral/createReferralSuccess`);
export const createReferralFailure = createAction<ErrorResponse>(`${Service.memberSvc}/referral/createReferralFailure`);

export const updateReferralRequest = createAction<{ id: string; data: UpsertReferralRewardRuleBody }>(
  `${Service.memberSvc}/referral/updateReferralRequest`
);
export const updateReferralSuccess = createAction(`${Service.memberSvc}/referral/updateReferralSuccess`);
export const updateReferralFailure = createAction<ErrorResponse>(`${Service.memberSvc}/referral/updateReferralFailure`);

export const deleteReferralRequest = createAction<string>(`${Service.memberSvc}/referral/deleteReferralRequest`);
export const deleteReferralSuccess = createAction(`${Service.memberSvc}/referral/deleteReferralSuccess`);
export const deleteReferralFailure = createAction<string>(`${Service.memberSvc}/referral/deleteReferralFailure`);

const referralSlice = createSlice({
  name: `${Service.memberSvc}/referral`,
  initialState: referralInitialState,
  reducers: {},
});

const referralReducer = referralSlice.reducer;
export default referralReducer;

import { Col as ColComponent } from 'reactstrap';

import { ColumnProps, CSSModule } from '@/components/template/type';

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
  lg?: ColumnProps;
  xl?: ColumnProps;
  lx?: ColumnProps;

  // custom widths
  widths?: string[];
}

export function Col(props: ColProps) {
  return <ColComponent {...props} />;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommonState, POPUP_TYPE } from '@/svc/common/type';
import { getIsMobileView } from '@/utils/etc';

const initialState: CommonState = {
  popup: {
    [POPUP_TYPE.FindModal]: false,
    [POPUP_TYPE.DeliverySearch]: false,
    [POPUP_TYPE.KeyIn]: false,
    [POPUP_TYPE.IdentifierMigration]: false,
    [POPUP_TYPE.Confirm]: false,
    [POPUP_TYPE.ScheduleSurge]: false,
    [POPUP_TYPE.ScheduleSyncConfirm]: false,
    [POPUP_TYPE.RidePenaltyFeeReason]: false,
    [POPUP_TYPE.BulkSyncDriverIncentive]: false,
    [POPUP_TYPE.RetryCashOutResult]: false,
  },
  confirmData: {
    content: null,
    ok: () => {},
    fail: () => {},
    disabled: false,
  },
  ridePenaltyData: {
    id: '',
  },
  scheduleSurgeData: [],
  collapse: {
    bulkBanDriverIncentive: false,
  },
  retryCashOutResponse: {
    succeedIds: [],
    failIds: [],
  },
  isHiddenSidebar: getIsMobileView(),
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // popup
    togglePopup(state, { payload }: PayloadAction<keyof CommonState['popup']>) {
      state.popup[payload] = !state.popup[payload];
    },
    closePopup(state, { payload }: PayloadAction<keyof CommonState['popup']>) {
      state.popup[payload] = false;
    },
    closeAll(state) {
      for (const key in state.popup) {
        state.popup[key as keyof typeof state.popup] = false;
      }
    },
    setConfirmData(state, { payload }: PayloadAction<Partial<CommonState['confirmData']>>) {
      Object.assign(state.confirmData, initialState.confirmData, payload);

      state.popup[POPUP_TYPE.Confirm] = true;
    },
    setScheduleSyncConfirmData(state, { payload }: PayloadAction<Partial<CommonState['confirmData']>>) {
      Object.assign(state.confirmData, initialState.confirmData, payload);

      state.popup[POPUP_TYPE.ScheduleSyncConfirm] = true;
    },
    setBulkSyncConfirmData(state, { payload }: PayloadAction<Partial<CommonState['confirmData']>>) {
      Object.assign(state.confirmData, initialState.confirmData, payload);

      state.popup[POPUP_TYPE.BulkSyncDriverIncentive] = true;
    },
    setConfirmDataField: {
      prepare: <T extends keyof CommonState['confirmData']>(key: T, value: CommonState['confirmData'][T]) => ({
        payload: { key, value },
      }),
      reducer: <T extends keyof CommonState['confirmData']>(
        state: CommonState,
        { payload: { key, value } }: PayloadAction<{ key: T; value: CommonState['confirmData'][T] }>
      ) => {
        state.confirmData[key] = value;
      },
    },
    setScheduleSurgeData(state, { payload }: PayloadAction<CommonState['scheduleSurgeData']>) {
      state.scheduleSurgeData = [...payload];
    },
    // collapse
    toggleCollapse(state, { payload: { key, flag } }: PayloadAction<{ key: keyof CommonState['collapse']; flag?: unknown }>) {
      state.collapse[key] = flag === undefined ? !state.collapse[key] : Boolean(flag);
    },
    setRidePenaltyData(state, { payload }: PayloadAction<string>) {
      state.ridePenaltyData.id = payload;
    },
    setRetryCashOutResponse(state, { payload }: PayloadAction<CommonState['retryCashOutResponse']>) {
      state.retryCashOutResponse = { ...payload };
      state.popup[POPUP_TYPE.RetryCashOutResult] = true;
    },
    setIsHiddenSidebar(state, { payload }: PayloadAction<boolean>) {
      state.isHiddenSidebar = payload;
    },
  },
});

export const {
  togglePopup,
  closePopup,
  closeAll,
  setConfirmData,
  setScheduleSyncConfirmData,
  setConfirmDataField,
  toggleCollapse,
  setScheduleSurgeData,
  setRidePenaltyData,
  setBulkSyncConfirmData,
  setRetryCashOutResponse,
  setIsHiddenSidebar,
} = commonSlice.actions;

export default commonSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "NewsList" */ '@/pages/News/List'));
const New = lazy(() => import(/* webpackChunkName: "NewsNew" */ '@/pages/News/New'));
const Edit = lazy(() => import(/* webpackChunkName: "NewsEdit" */ '@/pages/News/Edit'));
const Detail = lazy(() => import(/* webpackChunkName: "NewsDetail" */ '@/pages/News/Detail'));

export const newsRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.News,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.News}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.News}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.News}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

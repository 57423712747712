import { createAction } from '@reduxjs/toolkit';

import { WaitingFeeLog } from '@/svc/ride-svc/type';
import { UpdateWaitingFeeStatusDto } from '@/svc/ride-svc/waitingFee/types';
import Service from '@/svc/serviceName';

export const updateWaitingFeeStatusRequest = createAction(
  `${Service.rideSvc}/waitingFee/updateWaitingFeeStatusRequest`,
  (id: string, data: UpdateWaitingFeeStatusDto) => ({ payload: { id, data } })
);
export const updateWaitingFeeStatusSuccess = createAction<WaitingFeeLog>(`${Service.rideSvc}/waitingFee/updateWaitingFeeStatusSuccess`);
export const updateWaitingFeeStatusFailure = createAction<string>(`${Service.rideSvc}/waitingFee/updateWaitingFeeStatusFailure`);

// const waitingFeeSlice = createSlice({
//   name: `${Service.rideSvc}/waitingFee`,
//   initialState: {},
//   reducers: {},
// });

// export const {} = waitingFeeSlice.actions;

// export default waitingFeeSlice.reducer;

import { InputGroup as InputGroupComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface InputGroupProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  size?: string;
  cssModule?: CSSModule;
}

export function InputGroup(props: InputGroupProps) {
  return <InputGroupComponent {...props} />;
}

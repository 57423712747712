import { all, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { setMenuManagerData } from '@/components/MenuManager/reducer';
import { mergePagesWithDefaultValue } from '@/components/MenuManager/util';
import nav from '@/layout/Sidebar/_nav';
import {
  fetchMeRequest,
  fetchMeSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  postPushSubscriptionRequest,
  fetchMeFailure,
  postPushSubscriptionSuccess,
  postPushSubscriptionFailure,
} from '@/pages/LoginPage/reducer';
import { saveTokens } from '@/utils/localStorage';
import { authenticatedRequest, request } from '@/utils/request';

const loginURL = '/oauth/token';

export function* login({ payload }: ReturnType<typeof loginRequest>): IterableIterator {
  try {
    const response = yield request.post(loginURL, { data: { ...payload, grant_type: 'password' } });

    if (response.ok) {
      saveTokens(response.data);
    }

    const action = response.ok ? loginSuccess() : loginFailure(response.data.error_description);

    yield put(action);
  } catch (e) {
    yield put(loginFailure((e as Error).message));
  }
}

export function* fetchMe(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/v1/admins/me');

    if (response.ok) {
      yield all([
        put(fetchMeSuccess(response.data)),
        put(
          setMenuManagerData({
            pageStatusValues: response.data.pageStatus ? mergePagesWithDefaultValue(JSON.parse(response.data.pageStatus)) : nav.items,
          })
        ),
      ]);
    } else {
      yield put(fetchMeFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchMeFailure((e as Error).message));
  }
}

export function* postPushSubscription({ payload }: ReturnType<typeof postPushSubscriptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/push_subscription', { data: payload });

    const action = response.ok ? postPushSubscriptionSuccess() : postPushSubscriptionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(postPushSubscriptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(fetchMeRequest.type, fetchMe);
  yield takeLatest(postPushSubscriptionRequest.type, postPushSubscription);
}

import { Card as CardComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface CardProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  inverse?: boolean;
  color?: string;
  body?: boolean;
  outline?: boolean;
  cssModule?: CSSModule;
  innerRef?: React.Ref<HTMLElement>;
}

export function Card(props: CardProps) {
  return <CardComponent {...props} />;
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AdyenTransactionList" */ '@/pages/AdyenTransaction/List'));
const Detail = lazy(() => import(/* webpackChunkName: "AdyenTransactionDetail" */ '@/pages/AdyenTransaction/Detail'));
const New = lazy(() => import(/* webpackChunkName: "AdyenTransactionNew" */ '@/pages/AdyenTransaction/New'));

export const adyenTransactionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.AdyenTransaction,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.AdyenTransaction}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.AdyenTransaction}/:id`,
    component: Detail,
    exact: true,
  },
];

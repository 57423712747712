import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  deleteZoneFailure,
  deleteZoneRequest,
  deleteZoneSuccess,
  downloadZoneGeoJsonFailure,
  downloadZoneGeoJsonRequest,
  downloadZoneGeoJsonSuccess,
  importZonesGeoJsonFailure,
  importZonesGeoJsonRequest,
  importZonesGeoJsonSuccess,
} from '@/svc/ride-svc/zone/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* deleteZone({ payload }: ReturnType<typeof deleteZoneRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/zones/${payload}`);

    const action = response.ok ? deleteZoneSuccess() : deleteZoneFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteZoneFailure((e as Error).message));
  }
}

function* downloadZoneGeoJson({ payload }: ReturnType<typeof downloadZoneGeoJsonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/zones/export-as-geojson', {
      params: payload,
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, `copied_zones_from_${import.meta.env.VITE_NODE_ENV}.geojson`);
      yield put(downloadZoneGeoJsonSuccess());
    } else {
      yield put(downloadZoneGeoJsonFailure(response.data));
    }
  } catch (e) {
    yield put(downloadZoneGeoJsonFailure((e as Error).message));
  }
}

function* importZonesGeoJson({ payload: { regionId, data } }: ReturnType<typeof importZonesGeoJsonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/zones/${regionId}/import-with-geojson`, {
      data,
    });

    const action = response.ok ? importZonesGeoJsonSuccess() : importZonesGeoJsonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(importZonesGeoJsonFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(deleteZoneRequest.type, deleteZone);
  yield takeLatest(downloadZoneGeoJsonRequest.type, downloadZoneGeoJson);
  yield takeLatest(importZonesGeoJsonRequest.type, importZonesGeoJson);
}

import { useState, useCallback } from 'react';

export const useToggle = (initial = false) => {
  const [flag, setFlag] = useState(initial);

  const toggle = useCallback((value?: unknown) => {
    if (typeof value === 'boolean') {
      setFlag(value);
      return;
    }

    setFlag((prev) => !prev);
  }, []);

  return [flag, toggle] as const;
};

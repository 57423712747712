import { PaginationLink as PaginationLinkComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface PaginationLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  'aria-label'?: string;
  cssModule?: CSSModule;
  next?: boolean;
  previous?: boolean;
  first?: boolean;
  last?: boolean;
  tag?: React.ElementType;
}

export function PaginationLink(props: PaginationLinkProps) {
  return <PaginationLinkComponent {...props} />;
}

import { MomentInput } from 'moment';

import Text from '@/components/ValueField/Text';
import { getFormattingTime } from '@/utils/util';

/**
 * `moment`, `string`, `Date` 와 같은 타입을 가진 `date`의 날짜 정보를 입력받아 Text로 나타내는 컴포넌트이다.
 */
function DateTime({ date, ...props }: { date: MomentInput }) {
  return <Text {...props}>{getFormattingTime(date)}</Text>;
}

export default DateTime;

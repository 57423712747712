import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  generateDeliveryRideReqFailure,
  generateDeliveryRideReqRequest,
  generateDeliveryRideReqSuccess,
  rejectDeliveryRideReqFailure,
  rejectDeliveryRideReqRequest,
  rejectDeliveryRideReqSuccess,
} from '@/svc/delivery-svc/deliveryRideRequest/reducer';
import { authenticatedRequest } from '@/utils/request';

function* generateDeliveryRideReq({ payload: id }: ReturnType<typeof generateDeliveryRideReqRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/requested_rides/${id}/generate`, {
      timeout: 5 * 60 * 1000,
    });

    if (response.ok) {
      yield put(generateDeliveryRideReqSuccess());
    } else {
      yield put(generateDeliveryRideReqFailure(response.data.message));
    }
  } catch (e) {
    yield put(generateDeliveryRideReqFailure((e as Error).message));
  }
}

function* rejectDeliveryRideReq({ payload: id }: ReturnType<typeof rejectDeliveryRideReqRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/delivery/requested_rides/${id}/reject`);

    if (response.ok) {
      yield put(rejectDeliveryRideReqSuccess());
    } else {
      yield put(rejectDeliveryRideReqFailure(response.data.message));
    }
  } catch (e) {
    yield put(rejectDeliveryRideReqFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(generateDeliveryRideReqRequest.type, generateDeliveryRideReq);
  yield takeLatest(rejectDeliveryRideReqRequest.type, rejectDeliveryRideReq);
}

import { Formik } from 'formik';
import { object, string } from 'yup';

import ButtonContainer from '@/components/ButtonContainer';
import VInput from '@/components/ValidatedInput';
import { Container, Row, Col, CardGroup, Card, CardBody, Button, InputGroupAddon, Form } from '@/components/template';
import ResetPasswordPopup from '@/pages/ResetPassword/parts/ResetPasswordPopup';

interface LoginFormProps {
  onSubmit: (data: UserForm) => void;
}

export interface UserForm {
  username: string;
  password: string;
}

const initialForm: UserForm = {
  username: '',
  password: '',
};

const validationSchema = object().shape({
  username: string().required('Username is Required.'),
  password: string()
    .required('Password is Required.')
    .test('len', 'The password must be 6 to 20 characters.', (val) => typeof val === 'string' && val.length >= 6 && val.length <= 20),
});

function LoginForm({ onSubmit }: LoginFormProps) {
  return (
    <div className='app flex-row align-items-center'>
      <Container>
        <Formik initialValues={initialForm} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ getFieldProps, handleSubmit, errors, isValid }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row className='justify-content-center'>
                  <Col md='8'>
                    <CardGroup className='mb-0'>
                      <Card className='p-4'>
                        <CardBody className='card-body'>
                          <h1>Login</h1>
                          <p className='text-muted'>Sign In to your account</p>
                          <Row>
                            <Col xs='12' sm='12' md='1'>
                              {/* @ts-ignore */}
                              <InputGroupAddon addonType='prepend'>
                                <i className='icon-user' />
                              </InputGroupAddon>
                            </Col>
                            <Col xs='12' sm='12' md='10'>
                              <VInput
                                {...getFieldProps('username')}
                                name='username'
                                type='text'
                                placeholder='Login ID'
                                errors={errors}
                                data-cy='login-id'
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs='12' sm='12' md='1'>
                              {/* @ts-ignore */}
                              <InputGroupAddon addonType='prepend'>
                                <i className='icon-lock' />
                              </InputGroupAddon>
                            </Col>
                            <Col xs='12' sm='12' md='10'>
                              <VInput {...getFieldProps('password')} type='password' placeholder='Password' errors={errors} data-cy='login-pw' />
                            </Col>
                          </Row>
                          <Row className='tw-mt-[15px]'>
                            <ButtonContainer>
                              <Button type='submit' color='primary' className='px-4' disabled={!isValid} data-cy='login-btn'>
                                Login
                              </Button>
                              <ResetPasswordPopup />
                            </ButtonContainer>
                          </Row>
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
}

export default LoginForm;

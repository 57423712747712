import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { deletePolygonFailure, deletePolygonRequest, deletePolygonSuccess } from '@/svc/ride-svc/polygon/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deletePolygon({ payload }: ReturnType<typeof deletePolygonRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/polygons/${payload}`);

    const action = response.ok ? deletePolygonSuccess() : deletePolygonFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deletePolygonFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(deletePolygonRequest.type, deletePolygon);
}

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DriverIncentiveDto,
  DriverIncentiveForm,
  DriverIncentiveListParam,
  DriverIncentiveState,
  DriverIncentiveUpdateDto,
  DriverMissionSyncResultDto,
  DriverMissionRewardDto,
  MissionProgressDriverBanDto,
  DriverMissionProgress,
  DriverIncentiveCreateDto,
  BulkDriverValidationResultDto,
} from '@/svc/member-svc/driverIncentive/type';
import Service from '@/svc/serviceName';
import { listSuccess } from '@/utils/reducerUtils';
import { ListResponse } from '@/utils/types';

export const driverIncentiveInitialState: DriverIncentiveState = {
  page: { total: 1, current: 1 },
  totalCount: 0,
  isFetching: false,
  isUpload: false,
  isSync: false,
  error: null,
  ids: [],
  byId: {},
};

export const createDriverIncentiveSuccess = createAction<PayloadAction<DriverIncentiveDto>>(
  `${Service.memberSvc}/driverIncentive/createDriverIncentiveSuccess`
);
export const createDriverIncentiveFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/createDriverIncentiveFailure`);
export const updateDriverIncentiveSuccess = createAction<PayloadAction<DriverIncentiveDto>>(
  `${Service.memberSvc}/driverIncentive/updateDriverIncentiveSuccess`
);
export const updateDriverIncentiveFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/updateDriverIncentiveFailure`);
export const addMissionRewardRequest = createAction<DriverMissionRewardDto>(`${Service.memberSvc}/driverIncentive/addMissionRewardRequest`);
export const addMissionRewardSuccess = createAction(`${Service.memberSvc}/driverIncentive/addMissionRewardSuccess`);
export const addMissionRewardFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/addMissionRewardFailure`);
export const addBulkMissionRewardRequest = createAction<FormData>(`${Service.memberSvc}/driverIncentive/addBulkMissionRewardRequest`);
export const addBulkMissionRewardSuccess = createAction(`${Service.memberSvc}/driverIncentive/addBulkMissionRewardSuccess`);
export const addBulkMissionRewardFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/addBulkMissionRewardFailure`);
export const banDriverIncentiveRequest = createAction<{ id: string; data: MissionProgressDriverBanDto }>(
  `${Service.memberSvc}/driverIncentive/banDriverIncentiveRequest`
);
export const banDriverIncentiveSuccess = createAction<DriverMissionProgress>(`${Service.memberSvc}/driverIncentive/banDriverIncentiveSuccess`);
export const banDriverIncentiveFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/banDriverIncentiveFailure`);
export const updateEditableDriverIncentiveRequest = createAction<{ id: string; data: DriverIncentiveCreateDto }>(
  `${Service.memberSvc}/driverIncentive/updateEditableDriverIncentiveRequest`
);
export const updateEditableDriverIncentiveSuccess = createAction<DriverIncentiveDto>(
  `${Service.memberSvc}/driverIncentive/updateEditableDriverIncentiveSuccess`
);
export const updateEditableDriverIncentiveFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/updateEditableDriverIncentiveFailure`);
export const validateBulkDriverIdsRequest = createAction<FormData>(`${Service.memberSvc}/driverIncentive/validateBulkDriverIdsRequest`);
export const validateBulkDriverIdsSuccess = createAction<BulkDriverValidationResultDto>(
  `${Service.memberSvc}/driverIncentive/validateBulkDriverIdsSuccess`
);
export const validateBulkDriverIdsFailure = createAction<string>(`${Service.memberSvc}/driverIncentive/validateBulkDriverIdsFailure`);

const driverIncentiveSlice = createSlice({
  name: `${Service.memberSvc}/driverIncentive`,
  initialState: driverIncentiveInitialState,
  reducers: {
    fetchDriverIncentiveListRequest: {
      prepare: (param: DriverIncentiveListParam) => ({ payload: param }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchDriverIncentiveListSuccess(state, action: PayloadAction<ListResponse<DriverIncentiveDto>>) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchDriverIncentiveListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    createDriverIncentiveRequest: {
      prepare: (data: DriverIncentiveForm) => ({ payload: data }),
      reducer: (state) => {
        state.error = null;
      },
    },
    updateDriverIncentiveRequest: {
      prepare: (id: string, data: DriverIncentiveUpdateDto) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    syncDriverProgressRequest: {
      prepare: (id: string, data: { driverId: string }) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isSync = true;
        state.error = null;
      },
    },
    syncDriverProgressSuccess: {
      prepare: (data: DriverMissionSyncResultDto) => ({ payload: data }),
      reducer: (state) => {
        state.isSync = false;
      },
    },
    syncDriverProgressFailure(state, { payload }: PayloadAction<string>) {
      state.isSync = false;
      state.error = payload;
    },
  },
});

export const {
  fetchDriverIncentiveListRequest,
  fetchDriverIncentiveListSuccess,
  fetchDriverIncentiveListFailure,
  createDriverIncentiveRequest,
  updateDriverIncentiveRequest,
  syncDriverProgressRequest,
  syncDriverProgressSuccess,
  syncDriverProgressFailure,
} = driverIncentiveSlice.actions;

const driverIncentiveReducer = driverIncentiveSlice.reducer;
export default driverIncentiveReducer;

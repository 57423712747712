import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const uuid = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

const List = lazy(() => import(/* webpackChunkName: "DriverApplicationList" */ '@/pages/DriverApplications/List'));
const PublicDriverApplicationList = lazy(
  () => import(/* webpackChunkName: "DriverApplicationPublicDriverApplicationList" */ '@/pages/DriverApplications/PublicList')
);
const Detail = lazy(() => import(/* webpackChunkName: "DriverApplicationDetail" */ '@/pages/DriverApplications/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "DriverApplicationEdit" */ '@/pages/DriverApplications/Edit'));
const ProgressList = lazy(() => import(/* webpackChunkName: "DriverApplicationProgressList" */ '@/pages/DriverApplications/ProgressList'));
const ProgressDetail = lazy(() => import(/* webpackChunkName: "DriverApplicationProgressDetail" */ '@/pages/DriverApplications/ProgressDetail'));

export const driverApplicationRoute: RouteProps[] = [
  {
    permission: ActionType.LIST,
    path: PATH.DriverApplications,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverApplications}/restrict`,
    component: PublicDriverApplicationList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverApplications}/:id(${uuid})`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.DriverApplications}/:id(${uuid})/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.DriverApplications}/progress`,
    component: ProgressList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.DriverApplications}/progress/:id`,
    component: ProgressDetail,
    exact: true,
  },
];

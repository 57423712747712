import { InputGroupAddon as InputGroupAddonComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface InputGroupAddonProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
  addonType: 'prepend' | 'append';
}

export function InputGroupAddon(props: InputGroupAddonProps) {
  return <InputGroupAddonComponent {...props} />;
}

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import draggingHandle from '@/assets/images/dragging_handle.svg';
import { NAV_ICON_URL } from '@/assets/images/nav';
import { toggleMenu } from '@/components/MenuManager/reducer';
import SideMenuContainer from '@/layout/Sidebar/SideMenuContainer';
import { useMenuContext } from '@/layout/Sidebar/parts/SidebarContext';
import { getNavIconByUrl } from '@/layout/Sidebar/util';
import { cn } from '@/lib/utils';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';
import { debounce } from '@/utils/etc';

const SideSubMenuContainer = styled.li<{ $isHover: boolean; $isDragging: boolean }>`
  margin-top: 2px !important;
  border-top: ${({ $isHover }) => ($isHover ? '3px solid #fff' : '0px')};
  opacity: ${({ $isDragging }) => ($isDragging ? '0.5' : '1')};
`;

const dropdownCloseDebounce = debounce();

function SideSubMenu() {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    dragRef,
    dropRef,
    handleMouseDownOverTime,
    handleMouseOutOverTime,
    handleMouseUpOverTime,
    index,
    isAvailableDrag,
    isDragging,
    isHover,
    item,
  } = useMenuContext();
  const isHiddenSidebar = useAppState((state) => state.common.isHiddenSidebar);

  const [isOpen, setIsOpen] = useState(item?.isOpenMenu);

  const getActiveRoute = (routeName: string) => {
    if (item?.isOpenMenu !== undefined) {
      return item.isOpenMenu ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    return location && location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown' : 'nav-item nav-dropdown open';
  };

  const handleClick = () => {
    if (!item) {
      console.error('Side Menu Item Not Found!');
      return;
    }

    dispatch(toggleMenu({ name: item.name }));
  };

  useEffect(() => {
    if (item?.isOpenMenu) {
      setIsOpen(item?.isOpenMenu);
      return;
    }

    dropdownCloseDebounce(() => {
      setIsOpen(item?.isOpenMenu);
    }, 500);
  }, [item?.isOpenMenu]);

  if (!dragRef || !dropRef || !item) {
    return null;
  }

  return (
    <SideSubMenuContainer
      key={index}
      className={getActiveRoute(item.url)}
      ref={(node) => dragRef(dropRef(node))}
      onMouseDown={handleMouseDownOverTime}
      onMouseUp={handleMouseUpOverTime}
      onMouseOut={handleMouseOutOverTime}
      $isHover={isHover}
      $isDragging={isDragging}
    >
      <a className={cn('nav-link nav-dropdown-toggle tw-flex', !isHiddenSidebar ? 'nav-show' : '')} href='#' onClick={handleClick}>
        {isDragging || isAvailableDrag ? (
          <img src={draggingHandle} className='tw-p-[5px]' alt='dragging' />
        ) : (
          <img src={`${NAV_ICON_URL[getNavIconByUrl(item.url)]}`} className={cn('tw-p-[5px] tw-text-white', isHiddenSidebar ? 'tw-ml-auto' : '')} />
        )}
        {!isHiddenSidebar && t(item.name)}
      </a>
      <ul className='nav-dropdown-items'>
        {isOpen && item.children?.map((item, itemIdx) => <SideMenuContainer item={item} parentIndex={index} index={itemIdx} key={item.url} />)}
      </ul>
    </SideSubMenuContainer>
  );
}

export default SideSubMenu;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  cancelPaymentTransactionFailure,
  cancelPaymentTransactionRequest,
  cancelPaymentTransactionSuccess,
  failPaymentTransactionFailure,
  failPaymentTransactionRequest,
  failPaymentTransactionSuccess,
  fetchPaymentTransactionDetailFailure,
  fetchPaymentTransactionDetailRequest,
  fetchPaymentTransactionDetailSuccess,
  fetchPaymentTransactionFailure,
  fetchPaymentTransactionRequest,
  fetchPaymentTransactionSuccess,
  payWithExistingCardPaymentTransactionFailure,
  payWithExistingCardPaymentTransactionRequest,
  payWithExistingCardPaymentTransactionSuccess,
  refundPaymentTransactionFailure,
  refundPaymentTransactionRequest,
  refundPaymentTransactionSuccess,
  launchPaymentTransactionRequest,
  launchPaymentTransactionSuccess,
  launchPaymentTransactionFailure,
} from '@/pages/PaymentTransaction/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchPaymentTransaction({ payload }: ReturnType<typeof fetchPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/payment/payment/payment_transaction', { params: payload.search });

    if (response.ok) {
      yield put(fetchPaymentTransactionSuccess(response.data));
    } else {
      yield put(fetchPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchPaymentTransactionFailure((e as Error).message));
  }
}

function* fetchPaymentTransactionDetail({ payload }: ReturnType<typeof fetchPaymentTransactionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/payment/payment/payment_transaction/${payload.id}`);

    if (response.ok) {
      yield put(fetchPaymentTransactionDetailSuccess(response.data));
    } else {
      yield put(fetchPaymentTransactionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchPaymentTransactionDetailFailure((e as Error).message));
  }
}

function* refundPaymentTransaction({ payload }: ReturnType<typeof refundPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/payment/payment_transaction/${payload.id}/refund`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(refundPaymentTransactionSuccess());
    } else {
      yield put(refundPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(refundPaymentTransactionFailure((e as Error).message));
  }
}

function* cancelPaymentTransaction({ payload }: ReturnType<typeof cancelPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/payment/payment_transaction/${payload.id}/cancel_payment`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(cancelPaymentTransactionSuccess());
    } else {
      yield put(cancelPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(cancelPaymentTransactionFailure((e as Error).message));
  }
}

function* payWithExistingCardPaymentTransaction({ payload }: ReturnType<typeof payWithExistingCardPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/payment/payment_transaction/${payload.id}/pay_with_default_card`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(payWithExistingCardPaymentTransactionSuccess());
    } else {
      yield put(payWithExistingCardPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(payWithExistingCardPaymentTransactionFailure((e as Error).message));
  }
}

function* failPaymentTransaction({ payload }: ReturnType<typeof failPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/payment/payment_transaction/${payload.id}/fail_processing`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(failPaymentTransactionSuccess());
    } else {
      yield put(failPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(failPaymentTransactionFailure((e as Error).message));
  }
}

function* launchPaymentTransaction({ payload }: ReturnType<typeof launchPaymentTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/payment/payment/payment_transaction/${payload.id}/launch_payment`, {
      data: payload.data,
    });

    if (response.ok) {
      yield put(launchPaymentTransactionSuccess());
    } else {
      yield put(launchPaymentTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(launchPaymentTransactionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchPaymentTransactionRequest.type, fetchPaymentTransaction);
  yield takeLatest(fetchPaymentTransactionDetailRequest.type, fetchPaymentTransactionDetail);
  yield takeLatest(refundPaymentTransactionRequest.type, refundPaymentTransaction);
  yield takeLatest(cancelPaymentTransactionRequest.type, cancelPaymentTransaction);
  yield takeLatest(payWithExistingCardPaymentTransactionRequest.type, payWithExistingCardPaymentTransaction);
  yield takeLatest(failPaymentTransactionRequest.type, failPaymentTransaction);
  yield takeLatest(launchPaymentTransactionRequest.type, launchPaymentTransaction);
}

import { useState, useEffect, ReactNode } from 'react';
import * as React from 'react';

import styled from 'styled-components';

import { loadImage } from '@/components/InputField/utils';
import { Input, Button } from '@/components/template';
import { blobToFile, getBlobFromS3, getFileDataUrl, rotateImage } from '@/utils/image';

interface ImageFileInputProps {
  imageFile?: File | File[] | string | string[] | null;
  onImageChange: (value: File | File[] | null) => void;
  isMultiple?: boolean;
  isRotatable?: boolean;
}

interface RotateButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

const Image = styled.img`
  height: 400px;
  max-width: 100%;
`;

function RotateButton({ children, ...props }: RotateButtonProps) {
  return (
    <Button color={props.color ?? 'light'} {...props}>
      {children ?? <i className='fa fa-rotate-right' />}
    </Button>
  );
}

const fileRead = (file: File, cb: (dataURL: string) => void) => {
  const reader = new FileReader();

  reader.onload = () => {
    if (typeof reader.result === 'string') {
      cb(reader.result);
    }
  };

  reader.readAsDataURL(file);
};

export default function ImageFileInput({ imageFile, onImageChange, isMultiple = false, isRotatable = false }: ImageFileInputProps) {
  const [imageUrl, setImageUrl] = useState<string[] | null>(null);

  const isDefaultImage = typeof imageFile === 'string' && imageFile !== '';
  const isDefaultImageArray = Array.isArray(imageFile) && imageFile.length && typeof imageFile?.[0] === 'string' && imageFile?.[0] !== '';

  const handleImageUrl = (url: string) => setImageUrl((imageUrls) => (imageUrls && isMultiple ? imageUrls.concat(url) : [url]));

  const handleRotateImage = async (idx: number = 0) => {
    if (!imageFile) return;

    const files =
      typeof imageFile === 'string'
        ? [blobToFile(await getBlobFromS3(imageFile), '')]
        : (imageFile as File[])?.length
          ? [...(imageFile as File[])]
          : [imageFile as File];

    const fileToRotate = files[idx];
    const imgSrc = await getFileDataUrl(fileToRotate as File);
    const img = await loadImage(imgSrc);

    const rotatedData = await rotateImage(img);
    const rotatedFile = blobToFile(rotatedData, `rotate_image`);
    const rotatedUrl = URL.createObjectURL(rotatedFile);

    files[idx] = rotatedFile;

    setImageUrl((prev) => {
      const newUrls = [...(prev ?? [])];
      newUrls[idx] = rotatedUrl;
      return newUrls;
    });

    onImageChange(files);
  };

  const deleteImageUrl = (data: { url: string; idx: number } | null) => {
    if (data) {
      const { url, idx } = data;
      const imageFiles = (imageFile as File[])?.length ? [...(imageFile as File[])] : null;

      onImageChange(imageFiles?.filter((_, id) => id !== idx) ?? []);
      setImageUrl((prev) => prev?.filter((prevUrl) => prevUrl !== url) ?? []);
      return;
    }

    onImageChange(data);
    setImageUrl(data);
  };

  const readFile = (e: React.ChangeEvent<any>) => {
    if (e.target.files.length) {
      if (isMultiple) {
        onImageChange(e.target.files);
        const imageFiles: File[] = Object.values(e.target.files);
        imageFiles.forEach((file) => fileRead(file, handleImageUrl));
        return;
      }

      onImageChange(e.target.files[0]);
      fileRead(e.target.files[0], handleImageUrl);
    }
  };

  useEffect(() => {
    if (!imageFile) {
      setImageUrl(null);
    }
  }, [imageFile]);

  if (isDefaultImage) {
    return (
      <div>
        <Image src={imageFile as string} />
        <div className='tw-mt-1'>
          {isRotatable && <RotateButton onClick={() => handleRotateImage()} />}
          <Button onClick={() => deleteImageUrl(null)} color='link'>
            Delete
          </Button>
        </div>
      </div>
    );
  } else if (isDefaultImageArray) {
    return (
      <div>
        {(imageFile as string[]).map((url, idx) => (
          <React.Fragment key={idx}>
            <Image src={url} />
            <div className='tw-mt-1'>
              {isRotatable && <RotateButton onClick={() => handleRotateImage(idx)} />}
              <Button onClick={() => deleteImageUrl({ url, idx })} color='link'>
                Delete
              </Button>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }

  if (imageUrl === null || !imageUrl.length) {
    return <Input type={'file'} onChange={readFile} accept={'.png,.jpg,.jpeg'} multiple={isMultiple} />;
  }

  return (
    <div>
      {imageUrl.map((url, idx) => (
        <React.Fragment key={idx}>
          <Image src={url} />
          <div className='tw-mt-1'>
            {isRotatable && <RotateButton onClick={() => handleRotateImage(idx)} />}
            <Button onClick={() => deleteImageUrl(isMultiple ? { url, idx } : null)} color='link'>
              Delete
            </Button>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

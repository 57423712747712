import { useCallback, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast, ToastOptions } from 'react-toastify';
import { AnyAction } from 'redux';

import regex from '@/assets/regex';
import { addErrorSubscribes, resetErrorSubscribes, useActionSubscribe, addToolkitErrorSubscribes } from '@/store/middleware/actionSubscribe';
import { ErrorResponse } from '@/utils/types';

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const useDispatchCallback = (
  { flag, error }: { flag: boolean; error: string | null },
  { onSuccess, onError }: { onSuccess?: () => void; onError?: (error: string) => void }
) => {
  const prevFlag = usePrevious(flag);
  useEffect(() => {
    if (prevFlag && !flag) {
      // if the request is finished
      if (error) {
        onError && onError(error);
      } else {
        // if success
        onSuccess && onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, error]);
};

export const useToast = (
  { flag, error }: { flag: boolean; error: string | null },
  successMessage?: string,
  returnUrl?: string,
  DelayRedirect = 1000
) => {
  const history = useHistory();
  const onError = useCallback((e: string) => {
    toast.error(e, {
      position: (toast as any).POSITION.TOP_CENTER,
    });
  }, []);

  const onSuccess = useCallback(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: (toast as any).POSITION.TOP_CENTER,
      });
    }

    if (returnUrl) {
      setTimeout(() => history.push(returnUrl), DelayRedirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage, returnUrl]);

  useDispatchCallback({ flag, error }, { onError, onSuccess });
};

interface dispatchOptions {
  toastOptions?: ToastOptions;
  showErrorToast: boolean;
}

const initialOptions: dispatchOptions = {
  showErrorToast: true,
};

/**
 *
 */
export function useToastWithDispatch() {
  const dispatch = useDispatch();

  useEffect(() => {
    return resetErrorSubscribes;
  }, []);

  return useCallback((action: AnyAction, options: dispatchOptions | undefined = initialOptions) => {
    const { type: actionType }: { type: string } = action;
    const { showErrorToast, toastOptions } = options;

    if (showErrorToast && !(action instanceof Promise)) {
      if (actionType.includes('FETCH') && actionType.includes('_REQUEST')) {
        addErrorSubscribes(actionType.slice(0, -8), { autoClose: 2600, ...toastOptions });
      } else if (regex.fetchAction.test(actionType)) {
        addToolkitErrorSubscribes(actionType.slice(0, -7), { autoClose: 2600, ...toastOptions });
      }
    }

    return dispatch(action);
  }, []);
}

interface Option<Res = any> {
  successMessage?: string;
  successRedirect?: string | ((data: Res) => string | undefined);
  successTimeout?: number;
  failureRedirect?: string;
  failureTimeout?: number;
  hideFailure?: boolean;
}

export const useToolkitActionToast = <Res = any>(action: string, option?: Option<Res>) => {
  const history = useHistory();

  const { successMessage, successRedirect, successTimeout, failureRedirect, failureTimeout, hideFailure } = option ?? {};

  const successAction = action.replace(/^(.+)Request$/, '$1Success');
  const failureAction = action.replace(/^(.+)Request$/, '$1Failure');

  useActionSubscribe(successAction, (data: Res) => {
    if (successMessage) {
      toast.success(successMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (successRedirect) {
      setTimeout(() => {
        const path = typeof successRedirect === 'string' ? successRedirect : successRedirect(data);
        if (path !== undefined) {
          history.push(path);
        }
      }, successTimeout ?? 1000);
    }
  });

  useActionSubscribe(failureAction, ({ payload }) => {
    if (hideFailure) {
      return;
    }

    const error = payload && typeof payload === 'object' ? (payload as ErrorResponse).message : (payload ?? 'Error');

    toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });

    if (failureRedirect) {
      setTimeout(() => {
        history.push(failureRedirect);
      }, failureTimeout ?? 1000);
    }
  });
};

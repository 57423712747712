import { createSelector } from 'reselect';

import { AppState } from '@/store/reducers';

const idsSelector = (state: AppState) => state.regions.ids;
const byIdSelector = (state: AppState) => state.regions.byId;
export const getRegionById = (state: AppState) => (id: number) => byIdSelector(state)[id];
export const getVisibleRegions = createSelector(idsSelector, byIdSelector, (ids: number[], byId) => ids.map((id) => byId[id]));

export const getRegionList = (state: AppState) => state.regions.regionList;

import { Action, Pages, Resource, BLACK_LIST_PAGES_BY_REGION, REGION } from '@/assets/constants';
import { ACTION } from '@/assets/constants/permission';
import { Permissions } from '@/pages/LoginPage/types';
import { getResourcePages } from '@/utils/etc';
import { getAvailableRegion } from '@/utils/localStorage';

export function sanitizePermission(permissions: Array<{ resource: Resource; action: Action }>, isSuper: boolean): Permissions {
  const resourcePages = getResourcePages();
  const availableRegions = getAvailableRegion() ?? [];

  const getBlackListPages = () => {
    const blackListPages = availableRegions.flatMap((region) => BLACK_LIST_PAGES_BY_REGION[region as REGION] ?? []);
    const blackListRegionCount = availableRegions.map((region) => BLACK_LIST_PAGES_BY_REGION[region as REGION]).length;

    if (blackListRegionCount <= 1) {
      return blackListPages;
    }

    const blackListCountObj = blackListPages.reduce((data: Partial<Record<Pages, number>>, page) => {
      data[page] = (data[page] ?? 0) + 1;
      return data;
    }, {});

    return blackListPages.filter((page: Pages) => blackListCountObj[page] === blackListRegionCount);
  };

  const blackListPages = getBlackListPages();

  if (isSuper) {
    const per = Object.values(resourcePages).reduce<Permissions>(
      (per, pages) => {
        pages.forEach((page) => {
          per[page] = [ACTION.ALL];
        });

        return per;
      },
      { [Pages.Admin]: [ACTION.ALL], [Pages.Roles]: [ACTION.ALL], [Pages.AdminLog]: [ACTION.ALL] }
    );

    return per;
  }

  const permissionPages = permissions.reduce<Permissions>((pagePermissions, { resource, action }) => {
    resourcePages[resource]?.forEach((page) => {
      if (pagePermissions[page] === undefined) {
        pagePermissions[page] = [];
      }

      if (blackListPages.includes(page)) {
        pagePermissions[page] = [];
        return;
      }

      if (resource === Resource.Admin) {
        switch (page) {
          case Pages.AdminLog:
            if (action === ACTION.READ) {
              pagePermissions[page] = [ACTION.ALL];
            }
            break;
          case Pages.Admin:
          case Pages.Roles:
            if (action === ACTION.WRITE) {
              pagePermissions[page] = [ACTION.ALL];
            }
            break;
          default:
            break;
        }
        return;
      }

      !pagePermissions[page]?.includes(action) && pagePermissions[page]?.push(action);
    });

    return pagePermissions;
  }, {});

  return permissionPages;
}

import { createAction } from '@reduxjs/toolkit';

import { DynamicSurgeWithScheduleSurgeDto } from '@/pages/DynamicSurgeMonitor/type';

export const fetchDynamicSurgeChangeLogRequest = createAction('dynamicSurgeMonitor/fetchDynamicSurgeChangeLogRequest', (id, data) => ({
  payload: { id, data },
}));
export const fetchDynamicSurgeChangeLogSuccess = createAction<Record<string, string>>('dynamicSurgeMonitor/fetchDynamicSurgeChangeLogSuccess');
export const fetchDynamicSurgeChangeLogFailure = createAction<string>('dynamicSurgeMonitor/fetchDynamicSurgeChangeLogFailure');

export const fetchDynamicSurgeForCitiesRequest = createAction<{ cityCode: string; productCode: string }>(
  'dynamicSurgeMonitor/fetchDynamicSurgeForCitiesRequest'
);
export const fetchDynamicSurgeForCitiesSuccess = createAction<DynamicSurgeWithScheduleSurgeDto[]>(
  'dynamicSurgeMonitor/fetchDynamicSurgeForCitiesSuccess'
);
export const fetchDynamicSurgeForCitiesFailure = createAction<string>('dynamicSurgeMonitor/fetchDynamicSurgeForCitiesFailure');

export const updateDynamicSurgeEnabledRequest = createAction(
  'dynamicSurgeMonitor/updateDynamicSurgeEnabledRequest',
  ({ region, enabled, carType }) => ({ payload: { region, enabled, carType } })
);
export const updateDynamicSurgeEnabledSuccess = createAction('dynamicSurgeMonitor/updateDynamicSurgeEnabledSuccess');
export const updateDynamicSurgeEnabledFailure = createAction<string>('dynamicSurgeMonitor/updateDynamicSurgeEnabledFailure');

export const updateDynamicSurgeAllowNegativeRequest = createAction(
  'dynamicSurgeMonitor/updateDynamicSurgeAllowNegativeRequest',
  ({ region, allowNegative, carType }) => ({
    payload: { region, allowNegative, carType },
  })
);
export const updateDynamicSurgeAllowNegativeSuccess = createAction('dynamicSurgeMonitor/updateDynamicSurgeAllowNegativeSuccess');
export const updateDynamicSurgeAllowNegativeFailure = createAction<string>('dynamicSurgeMonitor/updateDynamicSurgeAllowNegativeFailure');

export const increaseDynamicSurgeLevelRequest = createAction('dynamicSurgeMonitor/increaseDynamicSurgeLevelRequest', ({ region, step }) => ({
  payload: { region, step },
}));
export const increaseDynamicSurgeLevelSuccess = createAction('dynamicSurgeMonitor/increaseDynamicSurgeLevelSuccess');
export const increaseDynamicSurgeLevelFailure = createAction<string>('dynamicSurgeMonitor/increaseDynamicSurgeLevelFailure');

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "ScheduleSurgeList" */ '@/pages/ScheduleSurge/List'));
const New = lazy(() => import(/* webpackChunkName: "ScheduleSurgeNew" */ '@/pages/ScheduleSurge/New'));
const Edit = lazy(() => import(/* webpackChunkName: "ScheduleSurgeEdit" */ '@/pages/ScheduleSurge/Edit'));
const Detail = lazy(() => import(/* webpackChunkName: "ScheduleSurgeDetail" */ '@/pages/ScheduleSurge/Detail'));
const View = lazy(() => import(/* webpackChunkName: "ScheduleSurgeView" */ '@/pages/ScheduleSurge/View'));

export const scheduleSurgeRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.NewAdditionalPriceRules,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.NewAdditionalPriceRules}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.NewAdditionalPriceRules}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.NewAdditionalPriceRules}/:id/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: PATH.AdditionalPriceRules,
    component: View,
    exact: true,
  },
];

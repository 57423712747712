import { createAction } from '@reduxjs/toolkit';

import { TollFreeDateEntity, UpsertTollFreeDateDto } from '@/pages/TollFreeDate/type';
import { TollGantryDetailDto } from '@/pages/TollGantry/type';
import { TollTimeTableEntity, UpsertTollTimeTableDto } from '@/pages/TollTimeTable/type';
import { UpsertTollGantryDto } from '@/svc/routing-svc/toll/type';
import Service from '@/svc/serviceName';

const prefix = `${Service.routingSvc}/toll`;

export const upsertTollGantryRequest = createAction<{ id?: string; data: UpsertTollGantryDto }>(`${prefix}/upsertTollGantryRequest`);
export const upsertTollGantrySuccess = createAction<TollGantryDetailDto>(`${prefix}/upsertTollGantrySuccess`);
export const upsertTollGantryFailure = createAction<string>(`${prefix}/upsertTollGantryFailure`);

export const deleteTollGantryRequest = createAction<string>(`${prefix}/deleteTollGantryRequest`);
export const deleteTollGantrySuccess = createAction(`${prefix}/deleteTollGantrySuccess`);
export const deleteTollGantryFailure = createAction<string>(`${prefix}/deleteTollGantryFailure`);

export const upsertTollTimeTableRequest = createAction<{ id: string; data: UpsertTollTimeTableDto }>(`${prefix}/upsertTollTimeTableRequest`);
export const upsertTollTimeTableSuccess = createAction<TollTimeTableEntity>(`${prefix}/upsertTollTimeTableSuccess`);
export const upsertTollTimeTableFailure = createAction<string>(`${prefix}/upsertTollTimeTableFailure`);

export const deleteTollTimeTableRequest = createAction<string>(`${prefix}/deleteTollTimeTableRequest`);
export const deleteTollTimeTableSuccess = createAction(`${prefix}/deleteTollTimeTableSuccess`);
export const deleteTollTimeTableFailure = createAction<string>(`${prefix}/deleteTollTimeTableFailure`);

export const upsertTollFreeDateRequest = createAction<{ region: string; data: UpsertTollFreeDateDto }>(`${prefix}/upsertTollFreeDateRequest`);
export const upsertTollFreeDateSuccess = createAction<TollFreeDateEntity>(`${prefix}/upsertTollFreeDateSuccess`);
export const upsertTollFreeDateFailure = createAction<string>(`${prefix}/upsertTollFreeDateFailure`);

export const deleteTollFreeDateRequest = createAction<string>(`${prefix}/deleteTollFreeDateRequest`);
export const deleteTollFreeDateSuccess = createAction(`${prefix}/deleteTollFreeDateSuccess`);
export const deleteTollFreeDateFailure = createAction<string>(`${prefix}/deleteTollFreeDateFailure`);

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AbaTransactionList" */ '@/pages/AbaTransaction/List'));
const Detail = lazy(() => import(/* webpackChunkName: "AbaTransactionDetail" */ '@/pages/AbaTransaction/Detail'));

export const abaTransactionRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.AbaTransaction,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.AbaTransaction}/:id`,
    component: Detail,
    exact: true,
  },
];

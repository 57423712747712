import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { PASSWORD_CRITERIA, PASSWORD_CRITERIA_REGEX, PASSWORD_CRITERIA_STRING } from '@/assets/constants';
import { ALL_PASSWORD_CRITERIA } from '@/assets/constants/all';
import CardTemplate from '@/components/CardTemplate';
import Section, { Title } from '@/components/Section';
import { FormikErrorMessage } from '@/components/etc';
import { Col, Container, FormGroup, Input, Row } from '@/components/template';
import FormFooterContent from '@/pages/ResetPassword/parts/FormFooterContent';
import { ResetPasswordForm } from '@/pages/ResetPassword/type';

const CriteriaSection = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    > i {
      font-size: 22px;
      margin-left: 5px;
    }
  }

  div + div {
    margin-top: 8px;
  }
`;

function ResetPasswordFormContent() {
  const { values, isValid, getFieldProps, handleSubmit } = useFormikContext<ResetPasswordForm>();
  return (
    <form className='app flex-row align-items-center' onSubmit={handleSubmit}>
      <Container>
        <Row className='tw-justify-center'>
          <Col md={10}>
            <CardTemplate cardBodyProps={{ style: { padding: '2.25rem' } }} footerChildren={<FormFooterContent disabled={!isValid} />}>
              <Section>
                <Title>
                  <h5>Reset Password</h5>
                </Title>
                <FormGroup row>
                  <Col md={3}>Password</Col>
                  <Col md={9}>
                    <Input {...getFieldProps('newPassword')} type='password' />
                    <FormikErrorMessage name='newPassword' />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={3}>Confirm Password</Col>
                  <Col md={9}>
                    <Input {...getFieldProps('confirmPassword')} type='password' />
                    <FormikErrorMessage name='confirmPassword' />
                  </Col>
                </FormGroup>
              </Section>
              <Section>
                <Title>
                  <h5>Password must meet the following criteria</h5>
                </Title>
                <CriteriaSection>
                  {ALL_PASSWORD_CRITERIA.map((criteria) => (
                    <div key={criteria}>
                      <li>{PASSWORD_CRITERIA_STRING[criteria]}</li>
                      {criteria !== PASSWORD_CRITERIA.NOT_SAME_CURRENT_PASSWORD && (
                        <i
                          className={
                            PASSWORD_CRITERIA_REGEX[criteria]?.test(values.newPassword)
                              ? 'fa fa-check-circle tw-text-green-poi'
                              : 'fa fa-times-circle tw-text-red-poi'
                          }
                        />
                      )}
                    </div>
                  ))}
                </CriteriaSection>
              </Section>
            </CardTemplate>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

export default ResetPasswordFormContent;

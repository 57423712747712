import moment, { Moment } from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss Z';

export const REMARK_INPUT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const RIDE_DURATION_TIME_FORMAT = 'H [hours] mm [minutes] ss [seconds]';

export const getFormattingTime = (t: moment.MomentInput, format: string = DEFAULT_DATE_FORMAT) => (t ? moment(t).format(format) : '');

export const getFormattingDiffDurationTime = (startTime: string | null, endTime: string | null): string => {
  if (!startTime || !endTime) {
    return '0 hours 00 minutes 00 seconds';
  }

  const duration = moment.duration(moment(startTime).diff(moment(endTime)));

  return getFormattingTime(moment.utc(duration.asMilliseconds()), RIDE_DURATION_TIME_FORMAT);
};

export const getFormattedSeconds = (seconds: number, format: string = RIDE_DURATION_TIME_FORMAT): string => {
  const duration = moment.duration(seconds, 'seconds');
  return getFormattingTime(moment.utc(duration.asMilliseconds()), format);
};

export function getDiffDate(startDate: Moment | string, endDate: string) {
  startDate = moment(startDate).format('YYYY-MM-DD');
  endDate = moment(endDate).format('YYYY-MM-DD');

  return moment(startDate).diff(moment(endDate), 'days');
}

export const checkFormat = (value: moment.MomentInput, format: string) => moment(value, format, true).isValid();

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "ShbWalletList" */ '@/pages/ShbWallet/List'));
const Detail = lazy(() => import(/* webpackChunkName: "ShbWalletDetail" */ '@/pages/ShbWallet/Detail'));

export const shbWalletRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.ShbWallet,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.ShbWallet}/:id`,
    component: Detail,
    exact: true,
  },
];

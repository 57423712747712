import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const uuid = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

const List = lazy(() => import(/* webpackChunkName: "DriverList" */ '@/pages/Drivers/List'));
const PublicList = lazy(() => import(/* webpackChunkName: "DriverPublicList" */ '@/pages/Drivers/PublicList'));
const Detail = lazy(() => import(/* webpackChunkName: "DriverDetail" */ '@/pages/Drivers/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "DriverEdit" */ '@/pages/Drivers/Edit'));
const PingLog = lazy(() => import(/* webpackChunkName: "DriverPingLog" */ '@/pages/Drivers/PingLog'));
const DispatchStatusDriverList = lazy(
  () => import(/* webpackChunkName: "DriverDispatchStatusDriverList" */ '@/pages/Drivers/DispatchStatusDriverList')
);
const DriverEquipment = lazy(() => import(/* webpackChunkName: "DriverDriverEquipment" */ '@/pages/Drivers/Equipment'));
const DriverBulkResign = lazy(() => import(/* webpackChunkName: "DriverBulkResign" */ '@/pages/Drivers/BulkResign'));
const DriverBulkFail = lazy(() => import(/* webpackChunkName: "DriverBulkFail" */ '@/pages/Drivers/BulkFail'));
const DriverBulkApprove = lazy(() => import(/* webpackChunkName: "DriverBulkApprove" */ '@/pages/Drivers/BulkApprove'));
const DriverVaccinationBulk = lazy(() => import(/* webpackChunkName: "DriverVaccinationBulk" */ '@/pages/Drivers/VaccinationBulk'));
const DriverBulkBan = lazy(() => import(/* webpackChunkName: "DriverBulkBan" */ '@/pages/Drivers/BulkBan'));
const DriverBackgroundProgressList = lazy(
  () => import(/* webpackChunkName: "DriverBackgroundProgressList" */ '@/pages/Drivers/DriverBackgroundProgressList')
);
const DriverBackgroundProgressDetail = lazy(
  () => import(/* webpackChunkName: "DriverBackgroundProgressDetail" */ '@/pages/Drivers/DriverBackgroundProgressDetail')
);

export const driverRoute: RouteProps[] = [
  {
    permission: ActionType.LIST,
    path: PATH.Drivers,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/restrict`,
    component: PublicList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/dispatch-status`,
    component: DispatchStatusDriverList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/pingLogs`,
    component: PingLog,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/equipment`,
    component: DriverEquipment,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/:id(${uuid})`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/restrict/:id(${uuid})`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/:id(${uuid})/edit`,
    component: Edit,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/resign`,
    component: DriverBulkResign,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/fail`,
    component: DriverBulkFail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/approve`,
    component: DriverBulkApprove,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/vaccination-bulk`,
    component: DriverVaccinationBulk,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Drivers}/ban`,
    component: DriverBulkBan,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/driver-background-progress`,
    component: DriverBackgroundProgressList,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Drivers}/driver-background-progress/:id`,
    component: DriverBackgroundProgressDetail,
    exact: true,
  },
];

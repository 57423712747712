enum Service {
  deliverySvc = 'delivery-svc',
  rideSvc = 'ride-svc',
  paySvc = 'pay-svc',
  mobileSvc = 'mobile-svc',
  memberSvc = 'member-svc',
  corporateSvc = 'corporate-svc',
  accountSvc = 'account-svc',
  penaltySvc = 'penalty-svc',
  balanceSvc = 'balance-svc',
  couponSvc = 'coupon-svc',
  inboxSvc = 'inbox-svc',
  csSvc = 'cs-svc',
  incentiveSvc = 'incentive-svc',
  placeSvc = 'place-svc',
  driverTutorialSvc = 'driver-tutorial-svc',
  routingSvc = 'routing-svc',
  adminProxySvc = 'admin-proxy-svc',
}

export default Service;

import ForceRefresh from '@/components/ForceRefresh';
import { HelmetTitle } from '@/components/HelmetTitle';
import PopupController from '@/components/Popup/PopupController';
import Layout from '@/layout/Layout';
import { useAuth, useDetermineFirstPageAvailable, usePageTitle } from '@/pages/Full/hooks';
import AuthenticatedRouteSwitcher from '@/pages/Full/parts/AuthenticatedRouteSwitcher';

function Full() {
  const { username, logout } = useAuth();
  const firstPageUrl = useDetermineFirstPageAvailable();
  const pageTitle = usePageTitle();

  return (
    <div className='app'>
      <HelmetTitle title={pageTitle} />
      <Layout username={username} logoutAdmin={logout} firstPageUrl={firstPageUrl}>
        <AuthenticatedRouteSwitcher firstPageUrl={firstPageUrl} />
      </Layout>
      <PopupController />
      <ForceRefresh />
    </div>
  );
}
export default Full;

import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { ListResponse } from '@tada/tada-web-common';

import { AdminListUserDto, UserState, WithdrawListParam } from '@/svc/member-svc/user/type';
import Service from '@/svc/serviceName';

export const userInitialState: UserState = {
  list: [],
  page: { total: 1, current: 1 },
  totalCount: 0,
  isFetching: false,
  error: null,
};

export const cancelWithdrawalRequest = createAction<string>(`${Service.memberSvc}/user/cancelWithdrawalRequest`);
export const cancelWithdrawalSuccess = createAction(`${Service.memberSvc}/user/cancelWithdrawalSuccess`);
export const cancelWithdrawalFailure = createAction<string>(`${Service.memberSvc}/user/cancelWithdrawalFailure`);

const userSlice = createSlice({
  name: `${Service.memberSvc}/user`,
  initialState: userInitialState,
  reducers: {
    fetchWithdrawalListRequest: {
      prepare: (param: WithdrawListParam) => ({ payload: param }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchWithdrawalListSuccess(state, { payload }: PayloadAction<ListResponse<AdminListUserDto>>) {
      state.list = payload.content;
      state.totalCount = payload.totalElements;
      state.page.total = payload.totalPages;
      state.page.current = payload.pageable.pageNumber + 1;
      state.page.limit = payload.size;
      state.isFetching = false;
    },
    fetchWithdrawalListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const { fetchWithdrawalListRequest, fetchWithdrawalListSuccess, fetchWithdrawalListFailure } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;

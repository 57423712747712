import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import LeavePopup from '@/components/LeavePopup';
import SignRoute from '@/components/Routes/SignRoutes';
import ToastContainers from '@/components/ToastContainers';
import { AppState } from '@/store/reducers';
import { getFaviconByType } from '@/utils/etc';

function App() {
  const { isAuthenticated } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']"); // get favicon ele

    if (link) {
      link.href = getFaviconByType();
    }
  }, []);

  useEffect(() => {
    const wheelEvent = () => {
      // @ts-ignore
      if (document.activeElement?.type !== 'number') {
        return;
      }

      // @ts-ignore
      document.activeElement.blur();
    };

    document.addEventListener('wheel', wheelEvent, { passive: false });

    return () => {
      document.removeEventListener('wheel', wheelEvent);
    };
  }, []);

  return (
    // @ts-ignore
    <Router basename={'/'}>
      <Switch>
        <SignRoute isAuthenticated={isAuthenticated} />
      </Switch>
      <ToastContainers />
      <LeavePopup />
    </Router>
  );
}

export default App;

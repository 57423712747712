import { Dispatch } from 'redux';

import { fetchDeliveryTagListRequest } from '@/svc/delivery-svc/tag/reducer';

// Delivery Svc들은 { pageToken, pageLimit }으로 보내야함
const DELIVERY_SVC_LIST_REQUESTS = [
  'FETCH_DELIVERY_LIST_REQUEST',
  'FETCH_WAREHOUSE_LIST_REQUEST',
  'FETCH_MERCHANT_LIST_REQUEST',
  'FETCH_DELIVERY_SCHEDULE_LIST_REQUEST',
  'FETCH_DELIVERY_RIDE_OPTION_LIST_REQUEST',
  'FETCH_DELIVERY_RIDE_PRICE_OPTION_LIST_REQUEST',
  'FETCH_DISPATCH_PAYMENT_OPTION_LIST_REQUEST',
  'FETCH_DELIVERY_EXPORT_RESULT_LIST_REQUEST',
  'FETCH_DELIVERY_RIDE_PRODUCT_OPTION_LIST_REQUEST',
  'FETCH_DELIVERY_PRICE_OPTION_LIST_REQUEST',
  'FETCH_DELIVERY_KEY_IN_LIST_REQUEST',
  fetchDeliveryTagListRequest.type,
];

// LIST_REQUEST string이 안들어가는 ListRequest들
const LIST_REQUESTS = [
  'FETCH_PAYMENT_TRANSACTION_REQUEST',
  'FETCH_CURRENT_ACCOUNT_EVENTS_REQUEST',
  'FETCH_DRIVER_PING_LOGS_REQUEST',
  'driverApplication/fetchDriverApplicationDetailRequest',
];

const paginationMiddleware = () => (next: Dispatch) => (action: any) => {
  const { type, payload, search, data, query } = action;

  if (type.includes('LIST_REQUEST') || LIST_REQUESTS.includes(type)) {
    let cloneQuery: any = null;

    if (payload === undefined) {
      if (search !== undefined) {
        cloneQuery = { ...search };
      }

      if (data !== undefined) {
        cloneQuery = { ...data };
      }

      if (query !== undefined) {
        cloneQuery = { ...query };
      }
    } else {
      if ((payload?.search ?? null) !== null) {
        cloneQuery = { ...payload.search };
      }

      if ((payload?.query ?? null) !== null) {
        cloneQuery = { ...payload.query };
      }
    }

    if (DELIVERY_SVC_LIST_REQUESTS.includes(type) || cloneQuery === null) {
      return next(action);
    }

    // page가 있을 경우 Default: 1
    // pageToken이 있을 경우 Default: 0
    // 위의 가정하에 짠거라 다른 페이지에서도 대응 필요
    if (cloneQuery.page !== undefined) {
      cloneQuery.page = cloneQuery.page - 1;
    } else if (cloneQuery.pageToken !== undefined) {
      cloneQuery.page = cloneQuery.pageToken;
      delete cloneQuery.pageToken;
    } else if (cloneQuery.page_token !== undefined) {
      cloneQuery.page = cloneQuery.page_token;
      delete cloneQuery.page_token;
    }

    if (cloneQuery.size === undefined) {
      if (cloneQuery.page !== undefined) {
        cloneQuery.size = 20;
      }

      if (cloneQuery.pageSize !== undefined) {
        cloneQuery.size = cloneQuery.pageSize;
        delete cloneQuery.pageSize;
      } else if (cloneQuery.page_size !== undefined) {
        cloneQuery.size = cloneQuery.page_size;
        delete cloneQuery.page_size;
      } else if (cloneQuery.limit !== undefined) {
        cloneQuery.size = cloneQuery.limit;
        delete cloneQuery.limit;
      }
    }

    if (payload === undefined) {
      if (search !== undefined) {
        action.search = cloneQuery;
      }

      if (data !== undefined) {
        action.data = cloneQuery;
      }

      if (query !== undefined) {
        action.query = cloneQuery;
      }

      return next(action);
    } else {
      if (payload?.search ?? null !== null) {
        action.payload.search = cloneQuery;
      }

      if (payload?.query ?? null !== null) {
        action.payload.query = cloneQuery;
      }

      return next(action);
    }
  } else {
    return next(action);
  }
};

export default paginationMiddleware;

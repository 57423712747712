import { createSlice } from '@reduxjs/toolkit';

import { PriceVerificationState } from '@/pages/PriceVerification/type';

const initialState: PriceVerificationState = {
  isFetching: false,
  error: null,
  productPriceResult: [],
};

const priceVerificationSlice = createSlice({
  name: 'priceVerification',
  initialState,
  reducers: {
    getPriceAllRequest: {
      prepare: (data) => ({ payload: data }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    getPriceAllSuccess(state, { payload }) {
      state.productPriceResult = payload;
      state.isFetching = false;
    },
    getPriceAllFailure(state, { payload }) {
      state.error = payload;
      state.isFetching = false;
    },
    resetPriceResult(state) {
      state.productPriceResult = [];
    },
  },
});

export const { getPriceAllRequest, getPriceAllSuccess, getPriceAllFailure, resetPriceResult } = priceVerificationSlice.actions;

export default priceVerificationSlice.reducer;

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import documentGuideSaga from '@/svc/member-svc/documentGuide/saga';
import driverIncentiveSaga from '@/svc/member-svc/driverIncentive/saga';
import hourlyRentalCarSaga from '@/svc/member-svc/hourlyRentalCar/saga';
import hourlyRentalRequestSaga from '@/svc/member-svc/hourlyRentalRequest/saga';
import {
  generateDocumentRequest,
  generateDocumentSuccess,
  generateDocumentFailure,
  bulkUpdateEquipmentsFailure,
  bulkUpdateEquipmentsRequest,
  bulkUpdateEquipmentsSuccess,
  fetchBulkUpdateEquipmentsTemplateRequest,
  generateDriverInfoUpsertFailure,
  generateDriverInfoUpsertRequest,
  generateDriverInfoUpsertSuccess,
  searchDriverRequest,
  searchDriverSuccess,
  searchRiderRequest,
  searchRiderSuccess,
  toggleOnProbationFailure,
  toggleOnProbationRequest,
  toggleOnProbationSuccess,
  syncTemplateRequest,
  syncTemplateSuccess,
  syncTemplateFailure,
  fetchBulkResignTemplateRequest,
  bulkResignRequest,
  bulkResignSuccess,
  bulkResignFailure,
  bulkFailRequest,
  bulkFailSuccess,
  bulkFailFailure,
  bulkApproveRequest,
  bulkApproveSuccess,
  bulkApproveFailure,
  fetchBulkVaccinationTemplateRequest,
  bulkVaccinationRequest,
  bulkVaccinationSuccess,
  bulkVaccinationFailure,
  blockDriverReferralCodeRequest,
  blockDriverReferralCodeSuccess,
  blockDriverReferralCodeFailure,
  unBlockDriverReferralCodeRequest,
  unBlockDriverReferralCodeSuccess,
  unBlockDriverReferralCodeFailure,
  blockRiderReferralCodeRequest,
  blockRiderReferralCodeSuccess,
  blockRiderReferralCodeFailure,
  unBlockRiderReferralCodeRequest,
  unBlockRiderReferralCodeSuccess,
  unBlockRiderReferralCodeFailure,
  toggleTestAccountRequest,
  toggleTestAccountFailure,
  toggleTestAccountSuccess,
  deleteVlIdRequest,
  deleteVlIdSuccess,
  deleteVlIdFailure,
  resetMyDestinationCountRequest,
  resetMyDestinationCountSuccess,
  resetMyDestinationCountFailure,
  fetchDriverBulkBanTemplateRequest,
  fetchDriverBulkBanTemplateSuccess,
  fetchDriverBulkBanTemplateFailure,
  bulkBanDriverRequest,
  bulkBanDriverSuccess,
  bulkBanDriverFailure,
  editChangeRequestDocumentRequest,
  editChangeRequestDocumentSuccess,
  editChangeRequestDocumentFailure,
  downloadInternalDocumentTemplateSuccess,
  downloadInternalDocumentTemplateFailure,
  downloadInternalDocumentTemplateRequest,
  createInternalDocumentProgressRequest,
  createInternalDocumentProgressSuccess,
  createInternalDocumentProgressFailure,
  validateProgressRequest,
  validateProgressSuccess,
  validateProgressFailure,
  uploadProgressRequest,
  uploadProgressSuccess,
  uploadProgressFailure,
} from '@/svc/member-svc/reducer';
import referralSaga from '@/svc/member-svc/referral/saga';
import taxPrefillDescriptionSaga from '@/svc/member-svc/taxPrefillDescription/saga';
import userSaga from '@/svc/member-svc/user/saga';
import vehicleMakerSaga from '@/svc/member-svc/vehicleMaker/saga';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* generateDriverInfoUpsert({ payload }: ReturnType<typeof generateDriverInfoUpsertRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/${payload}/generate-driver-info-upsert`);

    if (response.ok) {
      yield put(generateDriverInfoUpsertSuccess());
    } else {
      yield put(generateDriverInfoUpsertFailure(response.data.message));
    }
  } catch (e) {
    yield put(generateDriverInfoUpsertFailure((e as Error).message));
  }
}

function* fetchBulkUpdateEquipmentsTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/bulk-update-equipments-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk_equipment_update_template.xlsx');
    }
  } catch (e) {}
}

function* bulkUpdateEquipments({ payload }: ReturnType<typeof bulkUpdateEquipmentsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-update-equipments', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkUpdateEquipmentsSuccess(response.data) : bulkUpdateEquipmentsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateEquipmentsFailure((e as Error).message));
  }
}

function* searchDriver({ payload }: ReturnType<typeof searchDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/search', { params: payload });

    if (response.ok) {
      yield put(searchDriverSuccess(response.data));
    }
  } catch {}
}

function* searchRider({ payload }: ReturnType<typeof searchRiderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/riders/-/search', { params: { q: payload } });

    if (response.ok) {
      yield put(searchRiderSuccess(response.data));
    }
  } catch {}
}

function* toggleOnProbation({ payload }: ReturnType<typeof toggleOnProbationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/${payload.id}/toggle-on-probation`, { data: payload.data });

    if (response.ok) {
      yield put(toggleOnProbationSuccess());
    } else {
      yield put(toggleOnProbationFailure(response.data.message));
    }
  } catch (e) {
    yield put(toggleOnProbationFailure((e as Error).message));
  }
}

function* generateDocument({ payload }: ReturnType<typeof generateDocumentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/generate-documents', { data: payload });

    const action = response.ok ? generateDocumentSuccess() : generateDocumentFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(generateDocumentFailure((e as Error).message));
  }
}

function* syncTemplate({ payload }: ReturnType<typeof syncTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/sync-template', { data: payload });

    const action = response.ok ? syncTemplateSuccess() : syncTemplateFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(syncTemplateFailure((e as Error).message));
  }
}

function* fetchBulkResignTemplate({ payload }: ReturnType<typeof fetchBulkResignTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/bulk-resign-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, payload ?? 'bulk_resign_template.xlsx');
    }
  } catch (e) {}
}

function* bulkResign({ payload }: ReturnType<typeof bulkResignRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-resign', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkResignSuccess() : bulkResignFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkResignFailure((e as Error).message));
  }
}

function* bulkFail({ payload }: ReturnType<typeof bulkFailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-fail', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkFailSuccess() : bulkFailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkFailFailure((e as Error).message));
  }
}

function* bulkApprove({ payload }: ReturnType<typeof bulkApproveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-approve', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkApproveSuccess() : bulkApproveFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkApproveFailure((e as Error).message));
  }
}

function* fetchBulkVaccinationTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/bulk-upload-vaccination-status-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk_vaccination_template.xlsx');
    }
  } catch (e) {}
}

function* bulkVaccination({ payload }: ReturnType<typeof bulkVaccinationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-upload-vaccination-status', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? bulkVaccinationSuccess() : bulkVaccinationFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkVaccinationFailure((e as Error).message));
  }
}

function* blockDriverReferralCode({ payload }: ReturnType<typeof blockDriverReferralCodeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/referralCode/${payload}/block`);

    const action = response.ok ? blockDriverReferralCodeSuccess() : blockDriverReferralCodeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(blockDriverReferralCodeFailure((e as Error).message));
  }
}

function* unBlockDriverReferralCode({ payload }: ReturnType<typeof unBlockDriverReferralCodeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/referralCode/${payload}/unBlock`);

    const action = response.ok ? unBlockDriverReferralCodeSuccess() : unBlockDriverReferralCodeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(unBlockDriverReferralCodeFailure((e as Error).message));
  }
}

function* blockRiderReferralCode({ payload }: ReturnType<typeof blockRiderReferralCodeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/riders/referralCode/${payload}/block`);

    const action = response.ok ? blockRiderReferralCodeSuccess() : blockRiderReferralCodeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(blockRiderReferralCodeFailure((e as Error).message));
  }
}

function* unBlockRiderReferralCode({ payload }: ReturnType<typeof unBlockRiderReferralCodeRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/riders/referralCode/${payload}/unBlock`);

    const action = response.ok ? unBlockRiderReferralCodeSuccess() : unBlockRiderReferralCodeFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(unBlockRiderReferralCodeFailure((e as Error).message));
  }
}

function* toggleTestAccount({ payload: { id, flag } }: ReturnType<typeof toggleTestAccountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/${id}/toggle-test-account`, { data: { testAccount: flag } });

    const action = response.ok ? toggleTestAccountSuccess() : toggleTestAccountFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(toggleTestAccountFailure((e as Error).message));
  }
}

function* deleteVlId({ payload: { id, data } }: ReturnType<typeof deleteVlIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/drivers/${id}/remove-field-values`, { data });

    const action = response.ok ? deleteVlIdSuccess() : deleteVlIdFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteVlIdFailure((e as Error).message));
  }
}

function* resetMyDestinationCount({ payload }: ReturnType<typeof resetMyDestinationCountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/dispatchsvc/admin/drivers/${payload}/reset-daily-my-destination-count`);

    const action = response.ok ? resetMyDestinationCountSuccess() : resetMyDestinationCountFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(resetMyDestinationCountFailure((e as Error).message));
  }
}

function* fetchDriverBulkBanTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/bulk-ban-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk-ban-template.xlsx');
      yield put(fetchDriverBulkBanTemplateSuccess());
    } else {
      yield put(fetchDriverBulkBanTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDriverBulkBanTemplateFailure((e as Error).message));
  }
}

function* bulkBanDriver({ payload }: ReturnType<typeof bulkBanDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-ban', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(bulkBanDriverSuccess());
    } else {
      yield put(bulkBanDriverFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkBanDriverFailure((e as Error).message));
  }
}

function* editChangeRequestDocument({ payload: { id, data } }: ReturnType<typeof editChangeRequestDocumentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/drivers/${id}/edit-change-request-documents`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(editChangeRequestDocumentSuccess());
    } else {
      yield put(editChangeRequestDocumentFailure(response.data.message));
    }
  } catch (e) {
    yield put(editChangeRequestDocumentFailure((e as Error).message));
  }
}

function* downloadInternalDocumentTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/progresses/download-internal-document-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'internal_document_template.xlsx');
      yield put(downloadInternalDocumentTemplateSuccess());
    } else {
      yield put(downloadInternalDocumentTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadInternalDocumentTemplateFailure((e as Error).message));
  }
}

function* createInternalDocumentProgress({ payload: { params, file } }: ReturnType<typeof createInternalDocumentProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/progresses/create-progress', {
      params,
      data: file,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(createInternalDocumentProgressSuccess());
    } else {
      yield put(createInternalDocumentProgressFailure(response.data.message));
    }
  } catch (e) {
    yield put(createInternalDocumentProgressFailure((e as Error).message));
  }
}

function* validateProgress({ payload }: ReturnType<typeof validateProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/progresses/validate-progress', {
      data: payload,
    });

    if (response.ok) {
      yield put(validateProgressSuccess(response.data));
    } else {
      yield put(validateProgressFailure(response.data.message));
    }
  } catch (e) {
    yield put(validateProgressFailure((e as Error).message));
  }
}

function* uploadProgress({ payload: { id, uploadIdx, data } }: ReturnType<typeof uploadProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/progresses/${id}/upload`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(uploadProgressSuccess(uploadIdx));
    } else {
      yield put(uploadProgressFailure(response.data.message));
    }
  } catch (e) {
    yield put(uploadProgressFailure((e as Error).message));
  }
}

export default function* memberSvcSaga() {
  yield takeLatest(generateDriverInfoUpsertRequest.type, generateDriverInfoUpsert);
  yield takeLatest(fetchBulkUpdateEquipmentsTemplateRequest.type, fetchBulkUpdateEquipmentsTemplate);
  yield takeLatest(bulkUpdateEquipmentsRequest.type, bulkUpdateEquipments);
  yield takeLatest(searchDriverRequest.type, searchDriver);
  yield takeLatest(searchRiderRequest.type, searchRider);
  yield takeLatest(toggleOnProbationRequest.type, toggleOnProbation);
  yield takeLatest(generateDocumentRequest.type, generateDocument);
  yield takeLatest(syncTemplateRequest.type, syncTemplate);
  yield takeLatest(fetchBulkResignTemplateRequest.type, fetchBulkResignTemplate);
  yield takeLatest(bulkResignRequest.type, bulkResign);
  yield takeLatest(bulkFailRequest.type, bulkFail);
  yield takeLatest(bulkApproveRequest.type, bulkApprove);
  yield takeLatest(fetchBulkVaccinationTemplateRequest.type, fetchBulkVaccinationTemplate);
  yield takeLatest(bulkVaccinationRequest.type, bulkVaccination);
  yield takeLatest(blockDriverReferralCodeRequest.type, blockDriverReferralCode);
  yield takeLatest(unBlockDriverReferralCodeRequest.type, unBlockDriverReferralCode);
  yield takeLatest(blockRiderReferralCodeRequest.type, blockRiderReferralCode);
  yield takeLatest(unBlockRiderReferralCodeRequest.type, unBlockRiderReferralCode);
  yield takeLatest(toggleTestAccountRequest.type, toggleTestAccount);
  yield takeLatest(deleteVlIdRequest.type, deleteVlId);
  yield takeLatest(resetMyDestinationCountRequest.type, resetMyDestinationCount);
  yield takeLatest(fetchDriverBulkBanTemplateRequest.type, fetchDriverBulkBanTemplate);
  yield takeLatest(bulkBanDriverRequest.type, bulkBanDriver);
  yield takeLatest(editChangeRequestDocumentRequest.type, editChangeRequestDocument);
  yield takeLatest(downloadInternalDocumentTemplateRequest.type, downloadInternalDocumentTemplate);
  yield takeLatest(createInternalDocumentProgressRequest.type, createInternalDocumentProgress);
  yield takeLatest(validateProgressRequest.type, validateProgress);
  yield takeLatest(uploadProgressRequest.type, uploadProgress);
  yield all([
    call(driverIncentiveSaga),
    call(userSaga),
    call(referralSaga),
    call(documentGuideSaga),
    call(hourlyRentalCarSaga),
    call(hourlyRentalRequestSaga),
    call(taxPrefillDescriptionSaga),
    call(vehicleMakerSaga),
  ]);
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createRemarkLogSettingFailure,
  createRemarkLogSettingRequest,
  createRemarkLogSettingSuccess,
  deleteRemarkLogSettingFailure,
  deleteRemarkLogSettingRequest,
  deleteRemarkLogSettingSuccess,
  updateRemarkLogFailure,
  updateRemarkLogRequest,
  updateRemarkLogSettingFailure,
  updateRemarkLogSettingRequest,
  updateRemarkLogSettingSuccess,
  updateRemarkLogSuccess,
} from '@/svc/cs-svc/remarkLogSetting/reducer';
import { authenticatedRequest } from '@/utils/request';

const BASE_PATH = '/remark_logs';

function* createRemarkLogSetting({ payload }: ReturnType<typeof createRemarkLogSettingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`${BASE_PATH}/settings`, { data: payload });

    const action = response.ok ? createRemarkLogSettingSuccess() : createRemarkLogSettingFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createRemarkLogSettingFailure((e as Error).message));
  }
}

function* updateRemarkLogSetting({ payload: { id, data } }: ReturnType<typeof updateRemarkLogSettingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_PATH}/settings/${id}`, { data });

    const action = response.ok ? updateRemarkLogSettingSuccess() : updateRemarkLogSettingFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateRemarkLogSettingFailure((e as Error).message));
  }
}

function* deleteRemarkLogSetting({ payload }: ReturnType<typeof deleteRemarkLogSettingRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`${BASE_PATH}/settings/${payload}`);

    const action = response.ok ? deleteRemarkLogSettingSuccess() : deleteRemarkLogSettingFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteRemarkLogSettingFailure((e as Error).message));
  }
}

function* updateRemarkLog({ payload: { id, data } }: ReturnType<typeof updateRemarkLogRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${BASE_PATH}/${id}`, { data });

    const action = response.ok ? updateRemarkLogSuccess() : updateRemarkLogFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateRemarkLogFailure((e as Error).message));
  }
}

export default function* remarkLogSettingSaga() {
  yield takeLatest(createRemarkLogSettingRequest.type, createRemarkLogSetting);
  yield takeLatest(updateRemarkLogSettingRequest.type, updateRemarkLogSetting);
  yield takeLatest(deleteRemarkLogSettingRequest.type, deleteRemarkLogSetting);
  yield takeLatest(updateRemarkLogRequest.type, updateRemarkLog);
}

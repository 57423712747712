import { useRef } from 'react';

import qs from 'qs';
import useSWR, { useSWRConfig } from 'swr';

import { TadaResponse, ListResponse } from '@tada/tada-web-common';

import { authenticatedRequestWithPromise } from '@/utils/request/promise';
import { SWRDataConfig, SWRListConfig } from '@/utils/request/type';

export const fetcher = async (url: string) => {
  const res = await authenticatedRequestWithPromise.get(url);

  if (!res.ok) {
    const error = new Error(res.data.message || 'Internal Server Error');
    //@ts-ignore
    error.res = res;

    throw error;
  }

  return res;
};

// eslint-disable-next-line
export const formatSWRData = <D = any, E = any>(data: TadaResponse<ListResponse<D>> | undefined, error: E) => ({
  data,
  format: {
    totalCount: data?.data.totalElements ?? 0,
    data: data?.data.content ?? [],
    page: {
      total: data?.data.totalPages ?? 1,
      current: (data?.data.number ?? 0) + 1,
      limit: data?.data.size ?? 20,
    },
  },
  isLoading: !error && !data,
});

export const formatListResponse = <D>(data: ListResponse<D> | undefined) => ({
  data,
  format: {
    totalCount: data?.totalElements ?? 0,
    data: data?.content ?? [],
    page: {
      total: data?.totalPages ?? 1,
      current: (data?.number ?? 0) + 1,
      limit: data?.size ?? 20,
    },
  },
  isLoading: !data,
});

// eslint-disable-next-line
export const useSWRList = <D = any, Q extends { [key: string]: any } = { [key: string]: any }>(url: string, params?: SWRListConfig<D, Q>) => {
  const prevData = useRef<TadaResponse<D> | undefined>();

  const { query, skip = false, config } = params ?? {};
  const cloneQuery = { ...query };

  if (query?.page) {
    cloneQuery.page = query.page - 1;
  }

  const swrResponse = useSWR<TadaResponse<D>>(
    () => (skip ? null : `${url}?${cloneQuery ? qs.stringify(cloneQuery, { arrayFormat: 'repeat' }) : ''}`),
    config ?? {}
  );

  // pagination or filter 변경으로 api 재요청시 중간에 데이터가 안보이는걸 막기 위해 cache 해둔 데이터를 return 함
  if (!swrResponse.data && !swrResponse.error) {
    return { ...swrResponse, data: prevData.current };
  }

  prevData.current = swrResponse.data;

  return swrResponse;
};

// eslint-disable-next-line
export const useSWRData = <D = any, Q extends { [key: string]: any } = { [key: string]: any }>(url: string, params?: SWRDataConfig<D, Q>) => {
  const { query, skip = false, config } = params ?? {};

  return useSWR<TadaResponse<D>>(() => (skip ? null : `${url}?${query ? qs.stringify(query, { arrayFormat: 'repeat' }) : ''}`), config ?? {});
};

interface State<Data = unknown, Error = unknown> {
  data?: Data;
  error?: Error;
  isValidating?: boolean;
  isLoading?: boolean;
}

interface SWRCacheResponse<D> {
  data?: State<D>;
}

export const useSWRCache = <D>(url: string): SWRCacheResponse<D> => {
  const { cache } = useSWRConfig();

  const data = cache.get(url);

  return { data };
};

import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryPriceOptionListRequest,
  fetchDeliveryPriceOptionListSuccess,
  fetchDeliveryPriceOptionListFailure,
  fetchDeliveryPriceOptionDetailRequest,
  fetchDeliveryPriceOptionDetailSuccess,
  fetchDeliveryPriceOptionDetailFailure,
  createDeliveryPriceOptionRequest,
  createDeliveryPriceOptionSuccess,
  createDeliveryPriceOptionFailure,
  updateDeliveryPriceOptionRequest,
  updateDeliveryPriceOptionSuccess,
  updateDeliveryPriceOptionFailure,
  deleteDeliveryPriceOptionRequest,
  deleteDeliveryPriceOptionSuccess,
  deleteDeliveryPriceOptionFailure,
} from '@/pages/DeliveryPriceOption/reducer';
import { createSchema } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { entity, pagedModels } = createSchema('content');

function* fetchDeliveryPriceOptionList({ payload }: ReturnType<typeof fetchDeliveryPriceOptionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config/options/delivery_price', {
      params: payload,
      schema: pagedModels,
    });

    if (response.ok) {
      yield put(fetchDeliveryPriceOptionListSuccess(response.data));
    } else {
      yield put(fetchDeliveryPriceOptionListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryPriceOptionListFailure((e as Error).message));
  }
}

function* fetchDeliveryPriceOptionDetail({ payload }: ReturnType<typeof fetchDeliveryPriceOptionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/config/options/delivery_price/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchDeliveryPriceOptionDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryPriceOptionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryPriceOptionDetailFailure((e as Error).message));
  }
}

function* createDeliveryPriceOption({ payload }: ReturnType<typeof createDeliveryPriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/options/delivery_price', { data: payload });

    if (response.ok) {
      yield put(createDeliveryPriceOptionSuccess(response.data));
    } else {
      yield put(createDeliveryPriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryPriceOptionFailure((e as Error).message));
  }
}

function* updateDeliveryPriceOption({ payload: { id, data } }: ReturnType<typeof updateDeliveryPriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/options/delivery_price/${id}`, { data });

    if (response.ok) {
      yield put(updateDeliveryPriceOptionSuccess(response.data));
    } else {
      yield put(updateDeliveryPriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryPriceOptionFailure((e as Error).message));
  }
}

function* deleteDeliveryPriceOption({ payload }: ReturnType<typeof deleteDeliveryPriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/options/delivery_price/${payload}`);

    if (response.ok) {
      yield put(deleteDeliveryPriceOptionSuccess());
    } else {
      yield put(deleteDeliveryPriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryPriceOptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchDeliveryPriceOptionListRequest.type, fetchDeliveryPriceOptionList);
  yield takeLatest(fetchDeliveryPriceOptionDetailRequest.type, fetchDeliveryPriceOptionDetail);
  yield takeLatest(createDeliveryPriceOptionRequest.type, createDeliveryPriceOption);
  yield takeLatest(updateDeliveryPriceOptionRequest.type, updateDeliveryPriceOption);
  yield takeLatest(deleteDeliveryPriceOptionRequest.type, deleteDeliveryPriceOption);
}

import { useDispatch } from 'react-redux';

import { Pages } from '@/assets/constants';
import CardTemplate from '@/components/CardTemplate';
import CheckPermission, { ActionType } from '@/components/CheckPermission';
import HeaderContent from '@/components/HeaderContent';
import Paginator from '@/components/Paginator';
import Table, { TableData } from '@/components/Table';
import { Button } from '@/components/template';
import { useToolkitActionToast } from '@/hooks/toast';
import useQuery from '@/hooks/useQueryString';
import { useOtpWhitelistList } from '@/pages/OtpWhitelist/hooks';
import ListFooterContent from '@/pages/OtpWhitelist/parts/ListFooterContent';
import { OtpWhitelist, OtpWhitelistListQueryInterface } from '@/pages/OtpWhitelist/types';
import { useActionSubscribe } from '@/store/middleware/actionSubscribe';
import { usePermissionSelector } from '@/store/selector';
import { deleteOtpWhitelistRequest, deleteOtpWhitelistSuccess } from '@/svc/account-svc/reducer';
import { getFormattingTime } from '@/utils/util';

export default function List() {
  const dispatch = useDispatch();
  const permission = usePermissionSelector(Pages.OtpWhitelist);

  const initialState: OtpWhitelistListQueryInterface = {
    page: 1,
    size: 20,
  };

  const { query, skip } = useQuery<OtpWhitelistListQueryInterface>({
    initialState,
    searchConfig: {
      types: {
        page: 'number',
        size: 'number',
      },
    },
  });

  const {
    data: {
      format: { totalCount, data, page },
    },
    mutate,
  } = useOtpWhitelistList(query, skip);

  useActionSubscribe(deleteOtpWhitelistSuccess.type, () => {
    mutate();
  });

  useToolkitActionToast(deleteOtpWhitelistRequest.type, {
    successMessage: 'Delete Otp Whitelist Successfully!',
  });

  return (
    <CardTemplate headerChildren={<HeaderContent title={`Otp Whitelist (total: #${totalCount})`} />} footerChildren={<ListFooterContent />}>
      <Table
        columns={[
          {
            key: 'id',
            title: 'id',
          },
          {
            key: 'phoneNumber',
            title: 'Phone Number',
          },
          {
            key: 'createdAt',
            title: 'createdAt',
            renderContent: ({ createdAt }) => getFormattingTime(createdAt),
          },
          {
            key: 'lastUpdateAt',
            title: 'lastUpdateAt',
            renderContent: ({ lastUpdateAt }) => getFormattingTime(lastUpdateAt),
          },
        ]}
        data={data}
        subCol={(otp: OtpWhitelist) => (
          <CheckPermission permission={permission} action={ActionType.WRITE}>
            <TableData>
              <Button
                color='danger'
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this item?')) {
                    dispatch(deleteOtpWhitelistRequest(otp.id));
                  }
                }}
              >
                Delete
              </Button>
            </TableData>
          </CheckPermission>
        )}
      />
      <Paginator total={page.total} current={page.current} />
    </CardTemplate>
  );
}

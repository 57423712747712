import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import sidebarToggleIcon from '@/assets/images/icon_sidebar_toggle.svg';
import logo from '@/assets/logo/tada.png';
import SidebarResetPopup from '@/layout/Sidebar/parts/SidebarResetPopup';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';
import { setIsHiddenSidebar } from '@/svc/common/reducer';
import { debounce, getIsMobileView } from '@/utils/etc';

const SidebarHeaderContainer = styled.div<{ $isHiddenSide: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 24px 12px 16px 12px;
  background-color: #fff;
  > a {
    margin-right: auto;
    > img {
      width: 80px;
      height: 26px;
    }
  }
  > .toggleIcon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #26353b;
    cursor: pointer;
    > img {
      margin: auto;
      transform: ${({ $isHiddenSide }) => ($isHiddenSide ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`;

const resizeDebounce = debounce();

interface SidebarHeaderProps {
  onClickLogo: () => void;
}

function SidebarHeader({ onClickLogo }: SidebarHeaderProps) {
  const isHiddenSidebar = useAppState((state) => state.common.isHiddenSidebar);

  const sidebarToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    if (getIsMobileView()) {
      document.body.classList.toggle('sidebar-mobile-show');
      dispatch(setIsHiddenSidebar(!document.body.classList.contains('sidebar-mobile-show')));
      return;
    }

    document.body.classList.toggle('sidebar-hidden');
    dispatch(setIsHiddenSidebar(document.body.classList.contains('sidebar-hidden')));
  };

  useEffect(() => {
    const handleResizeDebounce = () => {
      resizeDebounce(() => {
        const isMobile = getIsMobileView();

        dispatch(setIsHiddenSidebar(isMobile));
        document.body.classList.remove(isMobile ? 'sidebar-hidden' : 'sidebar-mobile-show');
      }, 250);
    };

    window.addEventListener('resize', handleResizeDebounce);

    return () => {
      window.removeEventListener('resize', handleResizeDebounce);
    };
  }, []);

  return (
    <SidebarHeaderContainer $isHiddenSide={isHiddenSidebar}>
      <Link to='/' onClick={onClickLogo}>
        <img src={logo} alt='logo' />
      </Link>
      <SidebarResetPopup />
      <div className='toggleIcon' onClick={sidebarToggle}>
        <img src={sidebarToggleIcon} alt='sidebar-toggle' />
      </div>
    </SidebarHeaderContainer>
  );
}

export default SidebarHeader;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  checkFinishedPaoTransactionRequest,
  checkFinishedPaoTransactionSuccess,
  checkFinishedPaoTransactionFailure,
} from '@/pages/PaoTransactions/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { entity } = getContentSchema();

const basePath = '/payment/pao_transactions';

function* checkFinishedPaoTransaction({ payload }: ReturnType<typeof checkFinishedPaoTransactionRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`${basePath}/${payload}/check_finished`, {
      schema: entity,
    });

    const action = res.ok ? checkFinishedPaoTransactionSuccess(res.data) : checkFinishedPaoTransactionFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(checkFinishedPaoTransactionFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(checkFinishedPaoTransactionRequest.type, checkFinishedPaoTransaction);
}

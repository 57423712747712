import _ from 'lodash';
import qs from 'qs';
import { Route, Redirect } from 'react-router-dom';

import Full from '@/pages/Full/Full';
import LoginPage from '@/pages/LoginPage';
import ResetPassword from '@/pages/ResetPassword';

interface SignRouteProps {
  isAuthenticated: boolean;
}

export default function SignRoute({ isAuthenticated }: SignRouteProps) {
  return (
    <>
      <Route
        exact
        path='/login'
        // name='Login Page'
        render={(props) => (isAuthenticated ? <Redirect to={_.get(props, 'location.state.from', '/')} /> : <LoginPage />)}
      />
      <Route
        exact
        path='/reset-password'
        // name='Reset Password Page'
        render={(props) => {
          const query = qs.parse(props.location.search.replace('?', ''));
          if (isAuthenticated) {
            return <Redirect to={_.get(props, 'location.state.from', '/')} />;
          }

          if (!query?.token) {
            return <Redirect to='/login' />;
          }

          return <ResetPassword />;
        }}
      />
      <Route
        path='/'
        // name='Home'
        render={(props) => {
          const query = qs.parse(props.location.search.replace('?', ''));
          return isAuthenticated ? (
            <Full />
          ) : query?.token ? (
            <ResetPassword />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: _.get(props, 'location.state.logout', false) ? undefined : { from: props.location.pathname },
              }}
            />
          );
        }}
        // render={() => <LoginPage {...this.props.auth} loginAdmin={this.props.loginAdmin} />}
      />
    </>
  );
}

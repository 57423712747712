import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  deleteHourlyRentalCarFailure,
  deleteHourlyRentalCarRequest,
  deleteHourlyRentalCarSuccess,
  getHourlyRentalCarExcelTemplateFailure,
  getHourlyRentalCarExcelTemplateRequest,
  getHourlyRentalCarExcelTemplateSuccess,
  registerHourlyRentalCarFailure,
  registerHourlyRentalCarRequest,
  registerHourlyRentalCarSuccess,
} from '@/svc/member-svc/hourlyRentalCar/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* registerHourlyRentalCar({ payload: data }: ReturnType<typeof registerHourlyRentalCarRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/rental-car/upload-bulk', { data: data });

    if (response.ok) {
      response.data.created = response.data.newCarCount;
      response.data.updated = 0;
      yield put(registerHourlyRentalCarSuccess());
    } else {
      yield put(registerHourlyRentalCarFailure(response.data.message));
    }
  } catch (e) {
    yield put(registerHourlyRentalCarFailure((e as Error).message));
  }
}

function* deleteHourlyRentalCar({ payload: id }: ReturnType<typeof deleteHourlyRentalCarRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/member/vehicle/delete-rental-car/${id}`);

    if (response.ok) {
      yield put(deleteHourlyRentalCarSuccess());
    } else {
      yield put(deleteHourlyRentalCarFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteHourlyRentalCarFailure((e as Error).message));
  }
}

function* getHourlyRentalCarExcelTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/drivers/rental-car/template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'HourlyRentalCar_Template.xlsx');
      yield put(getHourlyRentalCarExcelTemplateSuccess());
    } else {
      yield put(getHourlyRentalCarExcelTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(getHourlyRentalCarExcelTemplateFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(registerHourlyRentalCarRequest.type, registerHourlyRentalCar);
  yield takeLatest(deleteHourlyRentalCarRequest.type, deleteHourlyRentalCar);
  yield takeLatest(getHourlyRentalCarExcelTemplateRequest.type, getHourlyRentalCarExcelTemplate);
}

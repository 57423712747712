import { pascalcase } from 'stringcase';

import regex from '@@assets/regex';
import { allNavItems } from '@@layout/Sidebar/_nav';

export const getPaths = (pathname: string, initialPath?: string) => {
  const paths: string[] = [];

  if (pathname === initialPath) {
    return [initialPath];
  }

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

export const findRouteName = (url: string): string => {
  const splitUrl = url.split('/');
  const path = splitUrl[splitUrl.length - 1];

  if (regex.uuid.test(path)) {
    return path;
  }

  const routeName = pascalcase(path);

  return allNavItems.find((item) => item.url === `/${path}`)?.name || routeName;
};

import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { findRouteName } from '@/components/Breadcrumb/util';
import { BreadcrumbItem } from '@/components/template';

export default function BreadcrumbsItem({ path }: { path: string }) {
  const { t } = useTranslation();
  const match = useRouteMatch(path);

  if (match === null) {
    return null;
  }

  const routeName = findRouteName(match.url);

  if (match.isExact) {
    return <BreadcrumbItem active>{t(routeName)}</BreadcrumbItem>;
  }

  return (
    <BreadcrumbItem>
      <Link to={match.url || ''}>{t(routeName)}</Link>
    </BreadcrumbItem>
  );
}

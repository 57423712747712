// import {register} from "register-service-worker";

const applicationServerPublicKey = 'BNjG0f-pJP9SROE2x9DVAJssfJkB0zibPnONN7p3Bc-qMba29PDBBuBIv9j1lhKfheE3rJGNxu9XJ3WDFgZuVjI';

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const registerServiceWorker = (updateSubscriptionOnServer: (data: { push_subscription: PushSubscription }) => void) => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker
      .register('sw.js', { scope: '/' })
      .then(function (swReg) {
        swReg.pushManager.getSubscription().then(async (subscription) => {
          if (!subscription) {
            const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
            swReg.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey,
              })
              .then((subscription) => {
                updateSubscriptionOnServer({ push_subscription: subscription });
              })
              .catch((err) => {
                console.error('Failed to subscribe the user: ', err);
              });
          }
        });
      })
      .catch(function (error) {
        console.error('Service Worker Error', error);
      });
  } else {
    console.warn('Push messaging is not supported');
  }
};

export const unregisterServiceWorker = () => {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
};

import { Container as ContainerComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  fluid?: boolean | string;
  cssModule?: CSSModule;
}

export function Container(props: ContainerProps) {
  return <ContainerComponent {...props} />;
}

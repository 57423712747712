import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Promotion, PromotionCreateDto, PromotionDetailDto, PromotionState, PromotionUpdateDto } from '@/pages/Promotion/type';
import { listSuccess } from '@/utils/reducerUtils';

const initialState: PromotionState = {
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  publishError: null,
  isFetching: false,
  isUploadingFile: false,
  page: { total: 1, current: 1 },
  search: { isFetching: false, byId: {}, ids: [] },
};

const failure = (state: PromotionState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

export const updatePromotionSuccess = createAction<PromotionDetailDto>('promotion/updatePromotionSuccess');
export const getPublishCouponToUserTemplateRequest = createAction('promotion/getPublishCouponToUserTemplateRequest');
export const getPublishCouponToUserTemplateSuccess = createAction('promotion/getPublishCouponToUserTemplateSuccess');
export const getPublishCouponToUserTemplateFailure = createAction('promotion/getPublishCouponToUserTemplateFailure');
export const publishCouponToUserRequest = createAction('promotion/publishCouponToUserRequest', (id: string, file: FormData) => ({
  payload: { id, file },
}));

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    fetchPromotionListRequest(state) {
      state.isFetching = true;
      state.error = null;
    },
    fetchPromotionListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchPromotionListFailure: failure,

    createPromotionRequest: {
      prepare: (body: PromotionCreateDto) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    createPromotionSuccess: {
      prepare: (promotion: Promotion) => ({ payload: promotion }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    createPromotionFailure: failure,

    updatePromotionRequest: {
      prepare: (id: string, body: PromotionUpdateDto) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.error = null;
      },
    },
    updatePromotionFailure: failure,
    publishCouponToUserSuccess: (state) => {
      state.isUploadingFile = false;
    },
    publishCouponToUserFailure: (state, { payload }) => {
      state.isUploadingFile = false;
      state.publishError = payload;
    },
  },
  extraReducers: {
    publishCouponToUserRequest: (state) => {
      state.isUploadingFile = true;
      state.publishError = null;
    },
  },
});

export const {
  fetchPromotionListRequest,
  fetchPromotionListSuccess,
  fetchPromotionListFailure,
  createPromotionRequest,
  createPromotionSuccess,
  createPromotionFailure,
  updatePromotionRequest,
  updatePromotionFailure,
  publishCouponToUserSuccess,
  publishCouponToUserFailure,
} = promotionSlice.actions;

export default promotionSlice.reducer;

import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchProductListRequest,
  fetchProductListSuccess,
  fetchProductListFailure,
  fetchProductDetailRequest,
  fetchProductDetailSuccess,
  fetchProductDetailFailure,
  createProductRequest,
  createProductSuccess,
  createProductFailure,
  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,
  fetchProductListByIdListRequest,
  fetchProductListByIdListSuccess,
  fetchProductListByIdListFailure,
} from '@/pages/RideProducts/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');
const arrayOfModels = [entity];
const pagedModels = {
  content: arrayOfModels,
};

function* fetchProductList({ payload }: ReturnType<typeof fetchProductListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/products', { params: { ...payload }, schema: pagedModels });

    if (response.ok) {
      yield put(fetchProductListSuccess(response.data));
    } else {
      yield put(fetchProductListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchProductListFailure((e as Error).message));
  }
}

function* fetchProductDetail({ payload }: ReturnType<typeof fetchProductDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/products/${payload}`, { schema: entity });

    if (response.ok) {
      yield put(fetchProductDetailSuccess(response.data));
    } else {
      yield put(fetchProductDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchProductDetailFailure((e as Error).message));
  }
}

function* createProduct({ payload }: ReturnType<typeof createProductRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/products', { data: payload, schema: entity });

    if (response.ok) {
      yield put(createProductSuccess(response.data));
    } else {
      yield put(createProductFailure(response.data.message));
    }
  } catch (e) {
    yield put(createProductFailure((e as Error).message));
  }
}

function* updateProduct({ payload }: ReturnType<typeof updateProductRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/products/${payload.id}`, { data: payload.data, schema: entity });

    if (response.ok) {
      yield put(updateProductSuccess(response.data));
    } else {
      yield put(updateProductFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateProductFailure((e as Error).message));
  }
}

function* fetchProductListByIdList({ payload }: ReturnType<typeof fetchProductListByIdListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/products/idList', { params: payload, schema: arrayOfModels });

    const action = response.ok ? fetchProductListByIdListSuccess(response.data) : fetchProductListByIdListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchProductListByIdListFailure((e as Error).message));
  }
}

export default function* RideProductSaga() {
  yield takeLatest(fetchProductListRequest.type, fetchProductList);
  yield takeLatest(fetchProductDetailRequest.type, fetchProductDetail);
  yield takeLatest(createProductRequest.type, createProduct);
  yield takeLatest(updateProductRequest.type, updateProduct);
  yield takeLatest(fetchProductListByIdListRequest.type, fetchProductListByIdList);
}

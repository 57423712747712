import { useEffect, useRef } from 'react';

import { setLeavePopupData } from '@/components/LeavePopup/reducer';
import { dispatch } from '@/store';

interface LeaveCheckProps {
  container?: string[];
  leaveContent?: ((clickedLink?: string) => string | null) | string;
  disableUnloadPopup?: boolean;
  disableLeavePopup?: boolean;
}

export function useLeaveCheck({ container, leaveContent, disableUnloadPopup = false, disableLeavePopup = false }: LeaveCheckProps = {}) {
  const isInputed = useRef(false);

  useEffect(() => {
    const root = document.body;

    const eventListener = (e: Event) => {
      const target = e.target as HTMLElement;

      if (container ? container.some((ele) => target.closest(ele)) : target.closest('form')) {
        isInputed.current = e.type === 'input';
      }
    };

    root.addEventListener('input', eventListener);
    root.addEventListener('submit', eventListener);

    return () => {
      root.removeEventListener('input', eventListener);
      root.removeEventListener('submit', eventListener);
    };
  }, []);

  useEffect(() => {
    if (disableLeavePopup) {
      return;
    }

    const clickListener = (e: MouseEvent) => {
      if (!isInputed.current) {
        return;
      }

      const anchorTarget = (e.target as HTMLElement).closest('a');

      if (anchorTarget && anchorTarget.target !== '_blank' && !e.ctrlKey && !e.metaKey) {
        e.preventDefault();

        const link = anchorTarget?.getAttribute('href') as string;
        const content = leaveContent && (typeof leaveContent === 'string' ? leaveContent : leaveContent(link));

        if (link) {
          dispatch(
            setLeavePopupData({
              open: true,
              link,
              content,
            })
          );
        }
      }
    };

    document.body.addEventListener('click', clickListener, { capture: true });

    return () => {
      document.body.removeEventListener('click', clickListener, { capture: true });
    };
  }, [disableLeavePopup]);

  useEffect(() => {
    if (disableUnloadPopup) {
      return;
    }

    const beforeunloadListener = (e: BeforeUnloadEvent) => {
      if (isInputed.current) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', beforeunloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, [disableUnloadPopup]);
}

import { createAction } from '@reduxjs/toolkit';

import { DeliveryScheduleFormInterface } from '@/pages/DeliverySchedule/type';
import Service from '@/svc/serviceName';

export const createDeliveryScheduleRequest = createAction<DeliveryScheduleFormInterface>(
  `${Service.deliverySvc}/deliverySchedule/createDeliveryScheduleRequest`
);
export const createDeliveryScheduleSuccess = createAction(`${Service.deliverySvc}/deliverySchedule/createDeliveryScheduleSuccess`);
export const createDeliveryScheduleFailure = createAction<string>(`${Service.deliverySvc}/deliverySchedule/createDeliveryScheduleFailure`);

export const updateDeliveryScheduleRequest = createAction(
  `${Service.deliverySvc}/deliverySchedule/updateDeliveryScheduleRequest`,
  (id: string, data: DeliveryScheduleFormInterface) => ({
    payload: { id, data },
  })
);
export const updateDeliveryScheduleSuccess = createAction(`${Service.deliverySvc}/deliverySchedule/updateDeliveryScheduleSuccess`);
export const updateDeliveryScheduleFailure = createAction<string>(`${Service.deliverySvc}/deliverySchedule/updateDeliveryScheduleFailure`);

export const deleteDeliveryScheduleRequest = createAction<string>(`${Service.deliverySvc}/deliverySchedule/deleteDeliveryScheduleRequest`);
export const deleteDeliveryScheduleSuccess = createAction(`${Service.deliverySvc}/deliverySchedule/deleteDeliveryScheduleSuccess`);
export const deleteDeliveryScheduleFailure = createAction<string>(`${Service.deliverySvc}/deliverySchedule/deleteDeliveryScheduleFailure`);

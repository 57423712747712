export const ABA_TRANSACTION_COLUMNS = {
  ID: 'id',
  UUID: 'uuid',
  ABA_TX_ID: 'abaTxId',
  STATUS: 'status',
  AMOUNT: 'amount',
  APPLICATION_FEE: 'applicationFee',
} as const;

export const ABA_TRANSACTION_COLUMNS_LIST = Object.values(ABA_TRANSACTION_COLUMNS);

export const ABA_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: ABA_TRANSACTION_COLUMNS.ID, label: 'ID' },
  { key: ABA_TRANSACTION_COLUMNS.UUID, label: 'UUID' },
  { key: ABA_TRANSACTION_COLUMNS.ABA_TX_ID, label: 'ABA TX ID' },
  { key: ABA_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: ABA_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: ABA_TRANSACTION_COLUMNS.APPLICATION_FEE, label: 'Application Fee' },
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createBulkInboxItemFailure,
  createBulkInboxItemRequest,
  createBulkInboxItemSuccess,
  downloadBulkInboxItemTemplateFailure,
  downloadBulkInboxItemTemplateRequest,
  downloadBulkInboxItemTemplateSuccess,
  inboxCreateFailure,
  inboxCreateRequest,
  inboxCreateSuccess,
  inboxDeleteFailure,
  inboxDeleteRequest,
  inboxDeleteSuccess,
  inboxUpdateFailure,
  inboxUpdateRequest,
  inboxUpdateSuccess,
} from '@/svc/inbox-svc/inboxItem/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* inboxUpdate({ payload }: ReturnType<typeof inboxUpdateRequest>): IterableIterator {
  try {
    if (!payload.id) {
      yield put(inboxUpdateFailure('Invalid Id'));
    }

    const response = yield authenticatedRequest.put(`/inbox/${payload.id}`, { data: payload.data });
    if (response.ok) {
      yield put(inboxUpdateSuccess());
    } else {
      yield put(inboxUpdateFailure(response.data.message));
    }
  } catch (e) {
    yield put(inboxUpdateFailure((e as Error).message));
  }
}

function* inboxCreate({ payload }: ReturnType<typeof inboxCreateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/inbox', { data: payload });
    if (response.ok) {
      yield put(inboxCreateSuccess(response.data));
    } else {
      yield put(inboxCreateFailure(response.data.message));
    }
  } catch (e) {
    yield put(inboxCreateFailure((e as Error).message));
  }
}

function* inboxDelete({ payload }: ReturnType<typeof inboxDeleteRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/inbox/${payload}`);
    if (response.ok) {
      yield put(inboxDeleteSuccess());
    } else {
      yield put(inboxDeleteFailure(response.data.message));
    }
  } catch (e) {
    yield put(inboxDeleteFailure((e as Error).message));
  }
}

function* createBulkInboxItem({ payload }: ReturnType<typeof createBulkInboxItemRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/inbox/bulk', {
      data: payload,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(createBulkInboxItemSuccess());
    } else {
      yield put(createBulkInboxItemFailure(response.data.message));
    }
  } catch (e) {
    yield put(createBulkInboxItemFailure((e as Error).message));
  }
}

function* downloadBulkInboxItemTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/inbox/bulk-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk-inbox-item-template.xlsx');
      yield put(downloadBulkInboxItemTemplateSuccess());
    } else {
      yield put(downloadBulkInboxItemTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadBulkInboxItemTemplateFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(inboxUpdateRequest.type, inboxUpdate);
  yield takeLatest(inboxCreateRequest.type, inboxCreate);
  yield takeLatest(createBulkInboxItemRequest.type, createBulkInboxItem);
  yield takeLatest(downloadBulkInboxItemTemplateRequest.type, downloadBulkInboxItemTemplate);
  yield takeLatest(inboxDeleteRequest.type, inboxDelete);
}

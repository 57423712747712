import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import _ from 'lodash';
import Autosuggest, { ChangeEvent } from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { checkRoutePermission } from '@/components/CheckPermission';
import nav, { BaseNavItemType } from '@/layout/Sidebar/_nav';
import { useAppState } from '@/store/selector';

// Imagine you have a list of pages that you'd like to autosuggest.
const pages = () => {
  const navList = nav.items;

  return navList.reduce<BaseNavItemType[]>((data, item) => {
    if (Array.isArray(item.children)) {
      data = [...data, ...item.children];
    } else {
      data = [...data, item];
    }

    return data;
  }, []);
};

export default function SearchPageInput({ closeModal }: { closeModal: () => void }) {
  const [transKey, setTransKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<BaseNavItemType[]>([]);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const autosuggestionInput = useRef<any>();

  const { permissions } = useAppState((state) => state.auth);

  useEffect(() => {
    if ((autosuggestionInput.current ?? null) !== null) {
      autosuggestionInput.current.focus();
    }
  }, [autosuggestionInput]);

  useEffect(() => {
    // @ts-ignore
    setValue(t(transKey));
  }, [i18n.language]);

  const onChange = (_: React.FormEvent<any>, { newValue }: ChangeEvent) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(getSuggestions(value));
    },
    [setSuggestions]
  );

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = useCallback(
    (value: any) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      if (inputLength === 0) {
        return [];
      }

      const findPages = pages().filter((navItem) => t(navItem.name).toLowerCase().includes(inputValue) && checkRoutePermission(navItem, permissions));

      return _.sortBy(findPages, [(page) => page.name.length]);
    },
    [pages, permissions]
  );

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, [setSuggestions]);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: BaseNavItemType) => {
    setTransKey(suggestion.name);

    return t(suggestion.name);
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion: BaseNavItemType) => (
    <div>
      {t(suggestion.name)} / Link: {suggestion.url}
    </div>
  );

  const onSuggestionSelected = (_: any, { suggestion }: any) => {
    history.push(suggestion.url);
    closeModal();
  };

  const inputProps = {
    placeholder: 'Searching...',
    value,
    onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      ref={(autosuggest) => {
        if (autosuggest && autosuggest.input) {
          autosuggestionInput.current = autosuggest.input;
        }
      }}
      onSuggestionSelected={onSuggestionSelected}
      shouldRenderSuggestions={() => true}
    />
  );
}

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { authenticatedRequest } from '@/utils/request';

import {
  createSmsTemplateFailure,
  createSmsTemplateRequest,
  createSmsTemplateSuccess,
  deleteSmsTemplateFailure,
  deleteSmsTemplateRequest,
  deleteSmsTemplateSuccess,
  updateSmsTemplateFailure,
  updateSmsTemplateRequest,
  updateSmsTemplateSuccess,
} from './reducer';

export function* createSMSTemplate({ payload: { data } }: ReturnType<typeof createSmsTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/messageTemplate', { data });

    if (response.ok) {
      yield put(createSmsTemplateSuccess());
    } else {
      yield put(createSmsTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(createSmsTemplateFailure((e as Error).message));
  }
}

export function* updateSMSTemplate({ payload: { id, data } }: ReturnType<typeof updateSmsTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/messageTemplate/${id}`, { data });

    if (response.ok) {
      yield put(updateSmsTemplateSuccess());
    } else {
      yield put(updateSmsTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateSmsTemplateFailure((e as Error).message));
  }
}

export function* deleteSMSTemplate({ payload }: ReturnType<typeof deleteSmsTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/messageTemplate/${payload}`);

    if (response.ok) {
      yield put(deleteSmsTemplateSuccess());
    } else {
      yield put(deleteSmsTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteSmsTemplateFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(createSmsTemplateRequest, createSMSTemplate);
  yield takeLatest(updateSmsTemplateRequest, updateSMSTemplate);
  yield takeLatest(deleteSmsTemplateRequest, deleteSMSTemplate);
}

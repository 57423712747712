import { useEffect, useState } from 'react';

import moment, { Moment } from 'moment';

import { AVAILABLE_DRIVER_AGE, REGION } from '@/assets/constants';
import regex from '@/assets/regex';
import { getFormattingTime } from '@/utils/util';

import { getTimeZoneOfRegion } from './etc';

export enum DATE_PRESET_TYPE {
  THIS_MONTH = 1,
  THIS_WEEK = 2,
  LAST_MONTH = 3,
  LAST_WEEK = 4,
  PAST_3_MONTHS = 5,
  PAST_6_MONTHS = 6,
  YESTERDAY = 7,
  TODAY = 8,
}

export const DEFAULT_DATE_PRESET_TYPES = [
  DATE_PRESET_TYPE.THIS_MONTH,
  DATE_PRESET_TYPE.THIS_WEEK,
  DATE_PRESET_TYPE.LAST_MONTH,
  DATE_PRESET_TYPE.LAST_WEEK,
  DATE_PRESET_TYPE.TODAY,
];

export const RIDE_DATE_PRESET_TYPES = [
  DATE_PRESET_TYPE.THIS_MONTH,
  DATE_PRESET_TYPE.THIS_WEEK,
  DATE_PRESET_TYPE.LAST_MONTH,
  DATE_PRESET_TYPE.LAST_WEEK,
  DATE_PRESET_TYPE.PAST_3_MONTHS,
  DATE_PRESET_TYPE.PAST_6_MONTHS,
  DATE_PRESET_TYPE.YESTERDAY,
  DATE_PRESET_TYPE.TODAY,
];

export const DATE_PRESET_STRING: Record<DATE_PRESET_TYPE, string> = {
  [DATE_PRESET_TYPE.THIS_MONTH]: 'This Month',
  [DATE_PRESET_TYPE.THIS_WEEK]: 'This Week',
  [DATE_PRESET_TYPE.LAST_MONTH]: 'Last Month',
  [DATE_PRESET_TYPE.LAST_WEEK]: 'Last Week',
  [DATE_PRESET_TYPE.PAST_3_MONTHS]: 'Past 3 Months',
  [DATE_PRESET_TYPE.PAST_6_MONTHS]: 'Past 6 Months',
  [DATE_PRESET_TYPE.YESTERDAY]: 'Yesterday',
  [DATE_PRESET_TYPE.TODAY]: 'Today',
};

export const calcDatePresetByTimeZone = (datePresetType: DATE_PRESET_TYPE | string, timezone: number = 8): { fromTime: Moment; toTime: Moment } => {
  /* If region is not selected, regard as SG */
  // @ts-ignore
  switch (parseInt(datePresetType, 10)) {
    case DATE_PRESET_TYPE.THIS_MONTH:
      return calcThisMonthByTimezone(timezone);
    case DATE_PRESET_TYPE.THIS_WEEK:
      return calcThisWeekByTimeZone(timezone);
    case DATE_PRESET_TYPE.LAST_MONTH:
      return calcLastMonthByTimezone(timezone);
    case DATE_PRESET_TYPE.LAST_WEEK:
      return calcLastWeekByTimeZone(timezone);
    case DATE_PRESET_TYPE.PAST_3_MONTHS:
      return calcPast3MonthsByTimeZone(timezone);
    case DATE_PRESET_TYPE.PAST_6_MONTHS:
      return calcPast6MonthsByTimeZone(timezone);
    case DATE_PRESET_TYPE.YESTERDAY:
      return calcYesterdayByTimeZone(timezone);
    case DATE_PRESET_TYPE.TODAY:
    default:
      return calcToday(timezone);
  }
};

const calcToday = (timezone: number = 8) => {
  const fromTime = moment().utcOffset(timezone).startOf('day');
  const toTime = moment().utcOffset(timezone).endOf('day');

  return {
    fromTime,
    toTime,
  };
};

const calcThisMonthByTimezone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).startOf('month');
  const toTime = moment().utcOffset(timezone).endOf('day');

  return {
    fromTime,
    toTime,
  };
};

const calcThisWeekByTimeZone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).startOf('week');
  const toTime = moment().utcOffset(timezone).endOf('day');

  return {
    fromTime,
    toTime,
  };
};

const calcLastMonthByTimezone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).startOf('month').subtract(1, 'month');
  const toTime = moment().utcOffset(timezone).endOf('month').subtract(1, 'month');

  return {
    fromTime,
    toTime,
  };
};

const calcLastWeekByTimeZone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).startOf('week').subtract(1, 'week');
  const toTime = moment().utcOffset(timezone).endOf('week').subtract(1, 'week');

  return {
    fromTime,
    toTime,
  };
};

const calcPast3MonthsByTimeZone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).subtract(3, 'months').startOf('day');
  const toTime = moment().utcOffset(timezone).endOf('day');

  return {
    fromTime,
    toTime,
  };
};

const calcPast6MonthsByTimeZone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).subtract(6, 'months').startOf('day');
  const toTime = moment().utcOffset(timezone).endOf('day');

  return {
    fromTime,
    toTime,
  };
};

const calcYesterdayByTimeZone = (timezone: number) => {
  const fromTime = moment().utcOffset(timezone).subtract(1, 'day').startOf('day');
  const toTime = moment().utcOffset(timezone).subtract(1, 'day').endOf('day');

  return {
    fromTime,
    toTime,
  };
};

export enum TIME_PRESET_TYPE {
  ALL = 0,
  MORNING = 1,
  AFTER_NOON = 2,
}

export const TIME_PRESET_TYPES = [TIME_PRESET_TYPE.ALL, TIME_PRESET_TYPE.MORNING, TIME_PRESET_TYPE.AFTER_NOON];

export const TIME_PRESET_STRINGS = {
  [TIME_PRESET_TYPE.ALL]: 'All (00:00 ~ 23:59)',
  [TIME_PRESET_TYPE.MORNING]: 'Morning (00:00 ~ 12:59)',
  [TIME_PRESET_TYPE.AFTER_NOON]: 'After Noon (13:00 ~ 23:59)',
};

export const calcTimePreset = (
  preset: TIME_PRESET_TYPE | string,
  dateStr: string | Moment,
  timezone: number
): { fromTime: Moment; toTime: Moment } => {
  if (!moment(dateStr).isValid()) {
    throw new Error(`Invalid Date: ${dateStr}`);
  }
  // @ts-ignore
  switch (parseInt(preset, 10)) {
    case TIME_PRESET_TYPE.ALL:
      return calcTimePresetAll(dateStr, timezone);
    case TIME_PRESET_TYPE.MORNING:
      return calcTimePresetMorning(dateStr, timezone);
    case TIME_PRESET_TYPE.AFTER_NOON:
      return calcTimePresetAfternoon(dateStr, timezone);
    default:
      return calcTimePresetAll(dateStr, timezone);
  }
};

const calcTimePresetAll = (date: string | Moment, timezone: number): { fromTime: Moment; toTime: Moment } => {
  const fromTime = moment(date).utcOffset(timezone, true);
  const toTime = moment(date).utcOffset(timezone, true);

  fromTime.set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  toTime.set({
    hour: 23,
    minute: 59,
    second: 59,
  });

  return {
    fromTime,
    toTime,
  };
};

const calcTimePresetMorning = (date: string | Moment, timezone: number): { fromTime: Moment; toTime: Moment } => {
  const fromTime = moment(date).utcOffset(timezone, true);
  const toTime = moment(date).utcOffset(timezone, true);

  fromTime.set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  toTime.set({
    hour: 12,
    minute: 59,
    second: 59,
  });

  return {
    fromTime,
    toTime,
  };
};

const calcTimePresetAfternoon = (date: string | Moment, timezone: number): { fromTime: Moment; toTime: Moment } => {
  const fromTime = moment(date).utcOffset(timezone, true);
  const toTime = moment(date).utcOffset(timezone, true);

  fromTime.set({
    hour: 13,
    minute: 0,
    second: 0,
  });

  toTime.set({
    hour: 23,
    minute: 59,
    second: 59,
  });

  return {
    fromTime,
    toTime,
  };
};

export function useDatePreset(timezone: number, callback: (start: Moment, end: Moment) => void) {
  const [datePreset, setDatePreset] = useState(-1);

  useEffect(() => {
    if (datePreset !== -1) {
      const { fromTime, toTime } = calcDatePresetByTimeZone(datePreset, timezone);
      callback(fromTime, toTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePreset]);

  return [datePreset, setDatePreset];
}

export const formatHourMinString = ({ hour, minute }: { hour: number; minute: number }) =>
  `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

export const formatUnix = ({ date, formatString = 'DD-MM-YYYY', offset }: { date: number; formatString?: string; offset?: string | number }) => {
  const unix = moment.unix(date);

  return (offset ? unix.utcOffset(offset) : unix).format(formatString);
};

export const isValidDate = (date: string | Moment) =>
  (moment.isMoment(date) || regex.date.test(date) || regex.dateTime.test(date)) && moment(date).isValid();

// Region에 나이 제한 없을 때는 무조건 true로 보내게 설정
export const isAvailableDriverBirth = (current: Moment | string, region: REGION | undefined) => {
  const age = region && AVAILABLE_DRIVER_AGE[region];

  return !age || moment(current).isSameOrBefore(moment().subtract(age, 'years'), 'day');
};

// B.E = A.D(일반적으로 쓰는 그레고리력 날짜) + 543 Year
const AD_TO_BE_GAP = 543;

export const convertADToBE = (date: string | Moment) => moment(date).add(AD_TO_BE_GAP, 'year');

export const getFormattingADWithBEDate = (date: string | Moment, format: string = 'MM-DD-YYYY') =>
  `${getFormattingTime(date, format)} [${getFormattingTime(convertADToBE(date), format)}]`;

export const getIsoStringWithTimezone = (date: string | Moment, region: REGION) =>
  moment(date).utcOffset(getTimeZoneOfRegion(region), true).toISOString();

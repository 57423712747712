import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchCorporateListRequest,
  fetchCorporateListSuccess,
  fetchCorporateListFailure,
  fetchCorporateDetailRequest,
  fetchCorporateDetailSuccess,
  fetchCorporateDetailFailure,
  refetchCorporateDetailRequest,
  createCorporateRequest,
  createCorporateSuccess,
  createCorporateFailure,
  deleteContractsRequest,
  deleteContractsSuccess,
  deleteContractsFailure,
  exportStatementRequest,
  exportStatementSuccess,
  exportStatementFailure,
  fetchBranchListRequest,
  fetchBranchListSuccess,
  fetchBranchListFailure,
  fetchStatementListRequest,
  fetchStatementListSuccess,
  fetchStatementListFailure,
  fetchStatementSummaryRequest,
  fetchStatementSummarySuccess,
  fetchStatementSummaryFailure,
  updateCorporateRequest,
  updateCorporateSuccess,
  updateCorporateFailure,
  exportInvitationListRequest,
  exportInvitationListSuccess,
  exportInvitationListFailure,
  downloadInvitationTemplateRequest,
  downloadInvitationTemplateSuccess,
  downloadInvitationTemplateFailure,
  bulkInvitationRequest,
  bulkInvitationSuccess,
  bulkInvitationFailure,
} from '@/pages/Corporate/reducer';
import { getContentSchema } from '@/utils/api';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { schema, entity } = getContentSchema();

function* fetchCorporateList({ payload }: ReturnType<typeof fetchCorporateListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/corporates', { params: payload, schema });

    const action = response.ok ? fetchCorporateListSuccess(response.data) : fetchCorporateListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchCorporateListFailure((e as Error).message));
  }
}

function* fetchCorporateDetail({ payload }: ReturnType<typeof fetchCorporateDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${payload}`, { schema: entity });

    const action = response.ok ? fetchCorporateDetailSuccess(response.data) : fetchCorporateDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchCorporateDetailFailure((e as Error).message));
  }
}

function* createCorporate({ payload }: ReturnType<typeof createCorporateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/corporates', { data: payload });

    const action = response.ok ? createCorporateSuccess(response.data.id) : createCorporateFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createCorporateFailure((e as Error).message));
  }
}

function* updateCorporate({ payload: { id, body } }: ReturnType<typeof updateCorporateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/corporates/${id}`, { data: body });

    const action = response.ok ? updateCorporateSuccess(response.data.id) : updateCorporateFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateCorporateFailure((e as Error).message));
  }
}

function* fetchStatementList({ payload: { id, query } }: ReturnType<typeof fetchStatementListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/statements`, { params: query });

    const action = response.ok ? fetchStatementListSuccess(response.data) : fetchStatementListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchStatementListFailure((e as Error).message));
  }
}

function* fetchStatementSummary({ payload: { id, query } }: ReturnType<typeof fetchStatementSummaryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/statements/summary`, { params: query });

    const action = response.ok ? fetchStatementSummarySuccess(response.data) : fetchStatementSummaryFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchStatementSummaryFailure((e as Error).message));
  }
}

function* exportStatement({ payload: { id, query } }: ReturnType<typeof exportStatementRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/statements/export`, {
      params: query,
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response);

      yield put(exportStatementSuccess());
    } else {
      const data = yield response.json();

      yield put(exportStatementFailure(data.message));
    }
  } catch (e) {
    yield put(exportStatementFailure((e as Error).message));
  }
}

function* deleteContracts({ payload }: ReturnType<typeof deleteContractsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/corporates/contracts/${payload}`);

    const action = response.ok ? deleteContractsSuccess() : deleteContractsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteContractsFailure((e as Error).message));
  }
}

function* fetchBranchList({ payload: { id, params } }: ReturnType<typeof fetchBranchListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/branches`, { params });

    const action = response.ok ? fetchBranchListSuccess(response.data) : fetchBranchListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchBranchListFailure((e as Error).message));
  }
}

function* exportInvitationList({ payload: { id, params } }: ReturnType<typeof exportInvitationListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/invitations:export`, {
      params,
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response);

      yield put(exportInvitationListSuccess());
    } else {
      const data = yield response.json();

      yield put(exportInvitationListFailure(data.message));
    }
  } catch (e) {
    yield put(exportInvitationListFailure((e as Error).message));
  }
}

function* downloadInvitationTemplate({ payload: id }: ReturnType<typeof downloadInvitationTemplateRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/corporates/${id}/invitations:template`, {
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response);

      yield put(downloadInvitationTemplateSuccess());
    } else {
      const data = yield response.json();

      yield put(downloadInvitationTemplateFailure(data.message));
    }
  } catch (e) {
    yield put(downloadInvitationTemplateFailure((e as Error).message));
  }
}

function* bulkInvitation({ payload: { id, data, params } }: ReturnType<typeof bulkInvitationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/corporates/${id}/invitations:bulk`, {
      data,
      params,
      contentType: 'multipart/form-data',
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response);
      yield put(bulkInvitationSuccess());
    } else {
      yield put(bulkInvitationFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkInvitationFailure((e as Error).message));
  }
}

export default function* corporateSaga() {
  yield takeLatest(fetchCorporateListRequest.type, fetchCorporateList);
  yield takeLatest(fetchCorporateDetailRequest.type, fetchCorporateDetail);
  yield takeLatest(refetchCorporateDetailRequest.type, fetchCorporateDetail);
  yield takeLatest(createCorporateRequest.type, createCorporate);
  yield takeLatest(updateCorporateRequest.type, updateCorporate);
  yield takeLatest(fetchStatementListRequest.type, fetchStatementList);
  yield takeLatest(fetchStatementSummaryRequest.type, fetchStatementSummary);
  yield takeLatest(exportStatementRequest.type, exportStatement);
  yield takeLatest(deleteContractsRequest.type, deleteContracts);
  yield takeLatest(fetchBranchListRequest.type, fetchBranchList);
  yield takeLatest(exportInvitationListRequest.type, exportInvitationList);
  yield takeLatest(downloadInvitationTemplateRequest.type, downloadInvitationTemplate);
  yield takeLatest(bulkInvitationRequest.type, bulkInvitation);
}

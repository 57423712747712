import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FraudCardState, FraudDetectionCardInterface, FraudDetectionCardQueryInterface, UpdateFraudCardData } from '@/pages/FraudCard/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';

const initialState: FraudCardState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isUpdating: false,
};

const fraudCardSlice = createSlice({
  name: 'fraudCard',
  initialState,
  reducers: {
    fetchFraudCardListRequest: {
      prepare: (search: FraudDetectionCardQueryInterface) => ({ payload: { search } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchFraudCardListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchFraudCardListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    fetchFraudCardDetailRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchFraudCardDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchFraudCardDetailFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    updateFraudCardRequest: {
      prepare: (id: string, data: UpdateFraudCardData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.isUpdating = true;
      },
    },
    updateFraudCardSuccess: {
      prepare: (data: FraudDetectionCardInterface) => ({ payload: { data } }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateFraudCardFailure(state, { payload }: PayloadAction<string>) {
      state.isUpdating = false;
      state.error = payload;
    },
  },
});

export const {
  fetchFraudCardListRequest,
  fetchFraudCardListSuccess,
  fetchFraudCardListFailure,
  fetchFraudCardDetailRequest,
  fetchFraudCardDetailSuccess,
  fetchFraudCardDetailFailure,
  updateFraudCardRequest,
  updateFraudCardSuccess,
  updateFraudCardFailure,
} = fraudCardSlice.actions;

export default fraudCardSlice.reducer;

import { createAction } from '@reduxjs/toolkit';

import { GooglePlaceLanguageParam, UpdateGoogleCacheDto } from '@/pages/GooglePlace/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.placeSvc}/googlePlace`;

export const updateGooglePlaceRequest = createAction<{ id: string; param?: GooglePlaceLanguageParam; data: UpdateGoogleCacheDto }>(
  `${PREFIX}/updateGooglePlaceRequest`
);
export const updateGooglePlaceSuccess = createAction(`${PREFIX}/updateGooglePlaceSuccess`);
export const updateGooglePlaceFailure = createAction<string>(`${PREFIX}/updateGooglePlaceFailure`);

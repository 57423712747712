import { CardHeader as CardHeaderComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface CardHeaderProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function CardHeader(props: CardHeaderProps) {
  return <CardHeaderComponent {...props} />;
}

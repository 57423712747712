import { schema } from 'normalizr';
import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryPaymentOptionListRequest,
  fetchDeliveryPaymentOptionListSuccess,
  fetchDeliveryPaymentOptionListFailure,
  fetchDeliveryPaymentOptionDetailRequest,
  fetchDeliveryPaymentOptionDetailSuccess,
  fetchDeliveryPaymentOptionDetailFailure,
  createDeliveryPaymentOptionRequest,
  createDeliveryPaymentOptionSuccess,
  createDeliveryPaymentOptionFailure,
  updateDeliveryPaymentOptionRequest,
  updateDeliveryPaymentOptionSuccess,
  updateDeliveryPaymentOptionFailure,
} from '@/pages/DeliveryPaymentOption/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchDeliveryPaymentOptionList({ payload }: ReturnType<typeof fetchDeliveryPaymentOptionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config/options/payment', {
      params: payload,
      schema: { content: [entity] },
    });

    if (response.ok) {
      yield put(fetchDeliveryPaymentOptionListSuccess(response.data));
    } else {
      yield put(fetchDeliveryPaymentOptionListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryPaymentOptionListFailure((e as Error).message));
  }
}

function* fetchDeliveryPaymentOptionDetail({ payload }: ReturnType<typeof fetchDeliveryPaymentOptionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/config/options/payment/${payload}`, {
      schema: entity,
    });

    if (response.ok) {
      yield put(fetchDeliveryPaymentOptionDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryPaymentOptionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryPaymentOptionDetailFailure((e as Error).message));
  }
}

function* createDeliveryPaymentOption({ payload }: ReturnType<typeof createDeliveryPaymentOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/options/payment', { data: payload });

    if (response.ok) {
      yield put(createDeliveryPaymentOptionSuccess(response.data));
    } else {
      yield put(createDeliveryPaymentOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryPaymentOptionFailure((e as Error).message));
  }
}

function* updateDeliveryPaymentOption({ payload }: ReturnType<typeof updateDeliveryPaymentOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/options/payment/${payload.id}`, { data: payload.data });

    if (response.ok) {
      yield put(updateDeliveryPaymentOptionSuccess(response.data));
    } else {
      yield put(updateDeliveryPaymentOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryPaymentOptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchDeliveryPaymentOptionListRequest.type, fetchDeliveryPaymentOptionList);
  yield takeLatest(fetchDeliveryPaymentOptionDetailRequest.type, fetchDeliveryPaymentOptionDetail);
  yield takeLatest(createDeliveryPaymentOptionRequest.type, createDeliveryPaymentOption);
  yield takeLatest(updateDeliveryPaymentOptionRequest.type, updateDeliveryPaymentOption);
}

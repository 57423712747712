export const CMCB_TRANSACTION_COLUMNS = {
  ID: 'id',
  RIDE_ID: 'rideId',
  STATUS: 'status',
  AMOUNT: 'amount',
  REGION_CODE: 'regionCode',
  CREATED_AT: 'createdAt',
} as const;

export const CMCB_TRANSACTION_COLUMNS_LIST = Object.values(CMCB_TRANSACTION_COLUMNS);

export const CMCB_TRANSACTION_FORM_COLUMNS: Array<{ key: string; label: string }> = [
  { key: CMCB_TRANSACTION_COLUMNS.ID, label: 'Id' },
  { key: CMCB_TRANSACTION_COLUMNS.RIDE_ID, label: 'Ride Id' },
  { key: CMCB_TRANSACTION_COLUMNS.STATUS, label: 'Status' },
  { key: CMCB_TRANSACTION_COLUMNS.AMOUNT, label: 'Amount' },
  { key: CMCB_TRANSACTION_COLUMNS.REGION_CODE, label: 'Region Code' },
  { key: CMCB_TRANSACTION_COLUMNS.CREATED_AT, label: 'Created At' },
];

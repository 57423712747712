import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CorporateManagerActionLogInterface,
  CorporateManagerActionLogQueryInterface,
  CorporateManagerActionLogState,
} from '@/svc/corporate-svc/CorporateManagerActionLog/type';
import { ListResponse } from '@/utils/types';

const initialState: CorporateManagerActionLogState = {
  isFetching: false,
  page: { total: 1, current: 0 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
};

const corporateManagerActionLogSvcSlice = createSlice({
  name: 'corporateManagerActionLog',
  initialState,
  reducers: {
    fetchInfiniteCorporateManagerActionLogListRequest: {
      prepare: (search: CorporateManagerActionLogQueryInterface) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchInfiniteCorporateManagerActionLogListSuccess: {
      prepare: (payload: ListResponse<CorporateManagerActionLogInterface>) => ({ payload }),
      reducer: (state, { payload }: PayloadAction<ListResponse<CorporateManagerActionLogInterface>>) => {
        Object.assign(state, {
          isFetching: false,
          byId: { ...state.byId, ...payload.entities.content },
          ids: payload.result.number === 0 ? payload.result.content : state.ids.concat(...payload.result.content),
          page: {
            total: payload.result.totalPages,
            current: payload.result.number + 1,
          },
          totalCount: payload.result.totalElements,
        });
      },
    },
    fetchInfiniteCorporateManagerActionLogListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchInfiniteCorporateManagerActionLogListRequest,
  fetchInfiniteCorporateManagerActionLogListSuccess,
  fetchInfiniteCorporateManagerActionLogListFailure,
} = corporateManagerActionLogSvcSlice.actions;

export default corporateManagerActionLogSvcSlice.reducer;

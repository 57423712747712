import { useDispatch } from 'react-redux';

import { PATH } from '@/assets/constants';
import { useToolkitActionToast } from '@/hooks/toast';
import Form from '@/pages/OtpWhitelist/Form';
import { OtpWhitelistBody } from '@/pages/OtpWhitelist/types';
import { createOtpWhitelistRequest } from '@/svc/account-svc/reducer';

export default function New() {
  const dispatch = useDispatch();
  const onSubmit = (data: OtpWhitelistBody) => {
    dispatch(createOtpWhitelistRequest(data));
  };

  useToolkitActionToast(createOtpWhitelistRequest.type, {
    successMessage: 'Create Successfully!',
    successRedirect: `${PATH.OtpWhitelist}`,
  });

  return <Form onSubmit={onSubmit} />;
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard'));

export const dashboardRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Dashboard,
    exact: true,
    component: Dashboard,
  },
];

import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { TransactionLink } from '@/components/Links';
import { Button } from '@/components/template';
import { useAppState } from '@/store/selector';
import { closePopup } from '@/svc/common/reducer';
import { selectRetryCashOutResponse, selectRetryCashOutResultOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';
import { uuidRegex } from '@/utils/etc';

const POPUP_STYLE = {
  width: '500px',
  height: '60%',
};

const Wrapper = styled.div`
  height: 100%;
  & > header {
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
  .response-container {
    height: calc(100% - 40px);
    overflow-y: scroll;
  }
`;

function RetryCashOutResultPopup() {
  const dispatch = useDispatch();

  const open = useAppState(selectRetryCashOutResultOpenFlag);
  const { succeedIds, failIds } = useAppState(selectRetryCashOutResponse);

  const close = () => {
    dispatch(closePopup(POPUP_TYPE.RetryCashOutResult));
  };

  return (
    <Popup contentStyle={POPUP_STYLE} overlayStyle={{ background: 'none' }} open={open} onClose={close} modal>
      {() => (
        <Wrapper>
          <header>
            <Button color='clear' onClick={close}>
              <i className='fa fa-close' />
            </Button>
          </header>
          <div className='response-container'>
            <h5>Request Retry Cash Out Count: {succeedIds.length + failIds.length}</h5>
            <h5 className='tw-text-green-500'>Success Retry Cash Out Count: {succeedIds.length}</h5>
            {!!failIds.length && (
              <div>
                <h5 className='tw-text-red-500'>Failed Retry Cash Out List - Count: {failIds.length}</h5>
                {failIds.map((id) => (
                  <p key={id}>
                    <TransactionLink id={id}>
                      {uuidRegex.test(id.split('_')[3])
                        ? id.split('_')[3].split('-')[0]
                        : [id.split('_')[3].split('-')[0], id.split('_')[3].split('-')[1]].join('-')}
                    </TransactionLink>
                  </p>
                ))}
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </Popup>
  );
}

export default RetryCashOutResultPopup;

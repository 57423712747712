import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "RolesList" */ '@/pages/Roles/List'));
const Detail = lazy(() => import(/* webpackChunkName: "RolesDetail" */ '@/pages/Roles/Detail'));
const New = lazy(() => import(/* webpackChunkName: "RolesNew" */ '@/pages/Roles/New'));
const Edit = lazy(() => import(/* webpackChunkName: "RolesEdit" */ '@/pages/Roles/Edit'));

export const rolesRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Roles,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Roles}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Roles}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Roles}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { ERROR } from '@/assets/constants';
import {
  fetchDriverListFailure,
  fetchDriverListRequest,
  fetchDriverListSuccess,
  fetchDriverRestrictListFailure,
  fetchDriverRestrictListRequest,
  fetchDriverRestrictListSuccess,
  fetchDriverDetailFailure,
  fetchDriverDetailRequest,
  fetchDriverDetailSuccess,
  updateDriverFailure,
  updateDriverRequest,
  updateDriverSuccess,
  deleteDriverFailure,
  deleteDriverRequest,
  deleteDriverSuccess,
  banDriverFailure,
  banDriverRequest,
  banDriverSuccess,
  resetStatusFailure,
  resetStatusRequest,
  resetStatusSuccess,
  resetLocationUpdatedAtFailure,
  resetLocationUpdatedAtRequest,
  resetLocationUpdatedAtSuccess,
  setDeliveryFailure,
  setDeliveryRequest,
  setDeliverySuccess,
  setDynamicHeatmapFailure,
  setDynamicHeatmapRequest,
  setDynamicHeatmapSuccess,
  setAutoDispatchStatusFailure,
  setAutoDispatchStatusRequest,
  setAutoDispatchStatusSuccess,
  banHourlyRentalDriverFailure,
  banHourlyRentalDriverRequest,
  banHourlyRentalDriverSuccess,
  fetchDriverPingLogsFailure,
  fetchDriverPingLogsRequest,
  fetchDriverPingLogsSuccess,
  fetchDriverDispatchStatusFailure,
  fetchDriverDispatchStatusRequest,
  fetchDriverDispatchStatusSuccess,
  deleteDriverLoginIdentifierFailure,
  deleteDriverLoginIdentifierRequest,
  deleteDriverLoginIdentifierSuccess,
  recoverDriverFailure,
  recoverDriverRequest,
  recoverDriverSuccess,
  resignDriverFailure,
  resignDriverRequest,
  resignDriverSuccess,
  fetchRateInfoFailure,
  fetchRateInfoRequest,
  fetchRateInfoSuccess,
  fetchDispatchStatusByDriverFailure,
  fetchDispatchStatusByDriverRequest,
  fetchDispatchStatusByDriverSuccess,
  updateDispatchStatusByDriverFailure,
  updateDispatchStatusByDriverRequest,
  updateDispatchStatusByDriverSuccess,
  updateOverrideDriverLocationRequest,
  updateOverrideDriverLocationSuccess,
  updateOverrideDriverLocationFailure,
} from '@/pages/Drivers/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');
const driverEntity = new schema.Entity('drivers');
const arrayOfEntity = [entity];

function* fetchDriverList({ payload }: ReturnType<typeof fetchDriverListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/member/drivers/list', {
      params: payload,
      schema: { content: arrayOfEntity },
    });

    const action = response.ok ? fetchDriverListSuccess(response.data) : fetchDriverListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverListFailure((e as Error).message));
  }
}

function* fetchDriverRestrictList({ payload }: ReturnType<typeof fetchDriverRestrictListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/member/drivers', { params: payload, schema: { content: arrayOfEntity } });

    if (response.ok && (payload.page === 0 || response.data.result.content.length !== 0)) {
      yield put(fetchDriverRestrictListSuccess(response.data));
    } else {
      const errorMessage = response.data.result.last ? ERROR.LAST_SCROLL : response.data.message;
      yield put(fetchDriverRestrictListFailure(errorMessage));
    }
  } catch (e) {
    yield put(fetchDriverRestrictListFailure((e as Error).message));
  }
}

function* fetchDriverDetail({ payload: { id, query } }: ReturnType<typeof fetchDriverDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/member/drivers/${id}`, { schema: driverEntity, params: query });

    const action = response.ok ? fetchDriverDetailSuccess(response.data) : fetchDriverDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverDetailFailure((e as Error).message));
  }
}

function* updateDriver({ payload: { id, body } }: ReturnType<typeof updateDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${id}/update-with-files`, {
      data: body,
      contentType: 'multipart/form-data',
    });

    const action = response.ok ? updateDriverSuccess() : updateDriverFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDriverFailure((e as Error).message));
  }
}

function* deleteDriver({ payload }: ReturnType<typeof deleteDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/drivers/${payload}/erase`);

    const action = response.ok ? deleteDriverSuccess() : deleteDriverFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(deleteDriverFailure((e as Error).message));
  }
}

function* banDriver({ payload: { id, body } }: ReturnType<typeof banDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${id}/toggle-ban`, { data: body, schema: entity });

    const action = response.ok ? banDriverSuccess() : banDriverFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(banDriverFailure((e as Error).message));
  }
}

function* resetStatus({ payload }: ReturnType<typeof resetStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/dispatch/drivers/resetStatusAll', { data: { driverUuid: [payload] } });

    const action = response.ok ? resetStatusSuccess() : resetStatusFailure();

    yield put(action);
  } catch (e) {
    yield put(resetStatusFailure());
  }
}

function* resetLocationUpdatedAt({ payload }: ReturnType<typeof resetLocationUpdatedAtRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/dispatch/drivers/${payload}/resetDriverLocation`);

    const action = response.ok ? resetLocationUpdatedAtSuccess() : resetLocationUpdatedAtFailure(response.message);

    yield put(action);
  } catch (e) {
    yield put(resetLocationUpdatedAtFailure((e as Error).message));
  }
}

function* setDelivery({ payload: { id, body } }: ReturnType<typeof setDeliveryRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${id}/toggle-delivery`, { data: body });

    const action = response.ok ? setDeliverySuccess() : setDeliveryFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(setDeliveryFailure((e as Error).message));
  }
}

function* setDynamicHeatmap({ payload: { id, body } }: ReturnType<typeof setDynamicHeatmapRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${id}/toggle-dynamic-heatmap`, { data: body });

    const action = response.ok ? setDynamicHeatmapSuccess(response.data) : setDynamicHeatmapFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(setDynamicHeatmapFailure((e as Error).message));
  }
}

function* setAutoDispatchStatus({ payload: { id, body } }: ReturnType<typeof setAutoDispatchStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${id}/toggle-auto-dispatch`, { data: body });

    const action = response.ok
      ? setAutoDispatchStatusSuccess({
          ...response.data,
          id,
          autoDispatch: body.autoDispatch,
        })
      : setAutoDispatchStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(setAutoDispatchStatusFailure((e as Error).message));
  }
}

function* banHourlyRentalDriver({ payload }: ReturnType<typeof banHourlyRentalDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/rental-schedule/ban-by-driver', { data: payload });

    const action = response.ok ? banHourlyRentalDriverSuccess(response.data) : banHourlyRentalDriverFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(banHourlyRentalDriverFailure((e as Error).message));
  }
}

function* fetchDriverPingLogs({ payload: { id, param } }: ReturnType<typeof fetchDriverPingLogsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/dispatch/drivers/${id}/pingLogs`, { params: param });

    const action = response.ok ? fetchDriverPingLogsSuccess(response.data) : fetchDriverPingLogsFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverPingLogsFailure((e as Error).message));
  }
}

function* fetchDriverDispatchStatus({ payload }: ReturnType<typeof fetchDriverDispatchStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/dispatchsvc/admin/drivers', { params: payload });

    const action = response.ok ? fetchDriverDispatchStatusSuccess(response.data) : fetchDriverDispatchStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverDispatchStatusFailure((e as Error).message));
  }
}

function* deleteDriverLoginIdentifier({
  payload: { userId, loginIdentifierId },
}: ReturnType<typeof deleteDriverLoginIdentifierRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accounts/${userId}/identifiers/${loginIdentifierId}`);

    const action = response.ok ? deleteDriverLoginIdentifierSuccess() : deleteDriverLoginIdentifierFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteDriverLoginIdentifierFailure((e as Error).message));
  }
}

function* recoverDriver({ payload }: ReturnType<typeof recoverDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${payload}/recover`);

    const action = response.ok ? recoverDriverSuccess(response.data) : recoverDriverFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(recoverDriverFailure((e as Error).message));
  }
}

function* resignDriver({ payload }: ReturnType<typeof resignDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/member/drivers/${payload}/resign`);

    const action = response.ok ? resignDriverSuccess(response.data) : resignDriverFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(resignDriverFailure((e as Error).message));
  }
}

function* fetchRateInfo({ payload }: ReturnType<typeof fetchRateInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/rate/${payload}/rate`);

    const action = response.ok ? fetchRateInfoSuccess(response.data) : fetchRateInfoFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchRateInfoFailure((e as Error).message));
  }
}

function* fetchDispatchStatusByDriver({ payload }: ReturnType<typeof fetchDispatchStatusByDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/dispatch/drivers/${payload}`);

    const action = response.ok
      ? fetchDispatchStatusByDriverSuccess(payload, response.data)
      : fetchDispatchStatusByDriverFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(fetchDispatchStatusByDriverFailure((e as Error).message));
  }
}

function* updateDispatchStatusByDriver({ payload: { id, body } }: ReturnType<typeof updateDispatchStatusByDriverRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/dispatch/drivers/${id}`, { data: body });

    const action = response.ok ? updateDispatchStatusByDriverSuccess(response.data) : updateDispatchStatusByDriverFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(updateDispatchStatusByDriverFailure((e as Error).message));
  }
}

function* updateOverrideDriverLocation({ payload: { id, data } }: ReturnType<typeof updateOverrideDriverLocationRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/dispatch/drivers/${id}/overrideDriverLocation`, { data });

    const action = response.ok ? updateOverrideDriverLocationSuccess() : updateOverrideDriverLocationFailure(response.data.message);
    yield put(action);
  } catch (e) {
    yield put(updateOverrideDriverLocationFailure((e as Error).message));
  }
}

export default function* driverSaga() {
  yield takeLatest(fetchDriverRestrictListRequest.type, fetchDriverRestrictList);
  yield takeLatest(banDriverRequest.type, banDriver);
  yield takeLatest(resetStatusRequest.type, resetStatus);
  yield takeLatest(resetLocationUpdatedAtRequest.type, resetLocationUpdatedAt);
  yield takeLatest(setDynamicHeatmapRequest.type, setDynamicHeatmap);
  yield takeLatest(setAutoDispatchStatusRequest.type, setAutoDispatchStatus);
  yield takeLatest(banHourlyRentalDriverRequest.type, banHourlyRentalDriver);
  yield takeLatest(fetchDriverPingLogsRequest.type, fetchDriverPingLogs);
  yield takeLatest(setDeliveryRequest.type, setDelivery);
  yield takeLatest(fetchDriverDispatchStatusRequest.type, fetchDriverDispatchStatus);
  yield takeLatest(deleteDriverLoginIdentifierRequest.type, deleteDriverLoginIdentifier);
  yield takeLatest(recoverDriverRequest.type, recoverDriver);
  yield takeLatest(resignDriverRequest.type, resignDriver);
  yield takeLatest(fetchRateInfoRequest.type, fetchRateInfo);
  yield takeLatest(fetchDispatchStatusByDriverRequest.type, fetchDispatchStatusByDriver);
  yield takeLatest(updateDispatchStatusByDriverRequest.type, updateDispatchStatusByDriver);
  yield takeLatest(fetchDriverListRequest.type, fetchDriverList);
  yield takeLatest(fetchDriverDetailRequest.type, fetchDriverDetail);
  yield takeLatest(updateDriverRequest.type, updateDriver);
  yield takeLatest(deleteDriverRequest.type, deleteDriver);
  yield takeLatest(updateOverrideDriverLocationRequest.type, updateOverrideDriverLocation);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "ReferralList" */ '@/pages/Referral/List'));
const New = lazy(() => import(/* webpackChunkName: "ReferralNew" */ '@/pages/Referral/New'));
const Detail = lazy(() => import(/* webpackChunkName: "ReferralDetail" */ '@/pages/Referral/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "ReferralEdit" */ '@/pages/Referral/Edit'));

export const referralRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    component: List,
    path: PATH.Referral,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    component: New,
    path: `${PATH.Referral}/new`,
    exact: true,
  },
  {
    permission: ActionType.READ,
    component: Detail,
    path: `${PATH.Referral}/:id`,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    component: Edit,
    path: `${PATH.Referral}/:id/edit`,
    exact: true,
  },
];

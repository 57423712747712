import moment from 'moment';
import { useParams } from 'react-router';

import { DetailParams } from '@tada/tada-web-common';

import { REGION } from '@/assets/constants';
import CardTemplate from '@/components/CardTemplate';
import HeaderContent from '@/components/HeaderContent';
import { Loading } from '@/components/Loading';
import Null from '@/components/Null';
import DetailSection from '@/components/Section/DetailSection';
import Table from '@/components/Table';
import { useCpfContributionTableDetail } from '@/pages/CpfContributionTable/hooks';
import DetailFooterContent from '@/pages/CpfContributionTable/parts/DetailFooterContent';
import { formatDateWithRegion } from '@/utils/etc';

function Detail() {
  const { id } = useParams<DetailParams>();
  const data = useCpfContributionTableDetail(id);

  if (!data) {
    return <Loading />;
  }

  return (
    <CardTemplate headerChildren={<HeaderContent title={`CPF Contribution Table #${id}`} />} footerChildren={<DetailFooterContent id={id} />}>
      <DetailSection
        options={[
          {
            key: 'title',
            name: 'Name',
          },
          {
            key: 'forOptIn',
            name: 'Type',
            renderContent: ({ forOptIn }) => (forOptIn ? 'Mandatory/Opted In' : 'Not Opted In'),
          },
          {
            key: 'validFrom',
            name: 'Start Date/Time',
            renderContent: ({ validFrom }) =>
              formatDateWithRegion({ date: moment(validFrom), region: REGION.SG, format: 'DD-MM-YYYY HH:mm Z', keepLocalTime: false }),
          },
          {
            key: 'validUntil',
            name: 'End Date/Time (Optional)',
            renderContent: ({ validUntil }) =>
              validUntil ? (
                formatDateWithRegion({ date: moment(validUntil), region: REGION.SG, format: 'DD-MM-YYYY HH:mm Z', keepLocalTime: false })
              ) : (
                <Null />
              ),
          },
        ]}
        data={data}
      />
      <Table
        columns={[
          {
            key: 'ageGroupDescription',
            title: 'Age Group',
          },
          {
            key: 'netEarningThreshold',
            title: 'Net Earning Threshold (More Than)',
            renderContent: ({ contributionRateInfos }) => (
              <>
                {contributionRateInfos.map((item, index) => (
                  <p key={`netEarningThreshold-${index}`}>{item.netEarningThreshold}</p>
                ))}
              </>
            ),
          },
          {
            key: 'driverContributionRate',
            title: `Driver's Contribution`,
            renderContent: ({ contributionRateInfos }) => (
              <>
                {contributionRateInfos.map((item, index) => (
                  <p key={`driverContributionRate-${index}`}>{item.driverContributionRate.toFixed(2)}%</p>
                ))}
              </>
            ),
          },
          {
            key: 'earningDeductionAmount',
            title: 'Earning Deduction Amount',
            renderContent: ({ contributionRateInfos }) => (
              <>
                {contributionRateInfos.map((item, index) => (
                  <p key={`earningDeductionAmount-${index}`}>{item.earningDeductionAmount}</p>
                ))}
              </>
            ),
          },
          {
            key: 'tadaContributionRate',
            title: `TADA's Contributions`,
            renderContent: ({ contributionRateInfos }) => (
              <>
                {contributionRateInfos.map((item, index) => (
                  <p key={`tadaContributionRate-${index}`}>{item.tadaContributionRate}%</p>
                ))}
              </>
            ),
          },
          {
            key: 'totalContributions',
            title: `Total Contributions`,
            renderContent: ({ contributionRateInfos }) => (
              <>
                {contributionRateInfos.map((item, index) => (
                  <p key={`totalContributions-${index}`}>{item.totalContributions}</p>
                ))}
              </>
            ),
          },
        ]}
        data={data.contributionRateTable.contributionRates}
      />
    </CardTemplate>
  );
}

export default Detail;

import { createAction } from '@reduxjs/toolkit';

import { CreateRemarkLogSettingBody, UpdateRemarkLogBody, UpdateRemarkLogSettingBody } from '@/pages/RemarkLogSetting/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.csSvc}/remarkLogSetting`;

export const createRemarkLogSettingRequest = createAction<CreateRemarkLogSettingBody>(`${PREFIX}/createRemarkLogSettingRequest`);
export const createRemarkLogSettingSuccess = createAction(`${PREFIX}/createRemarkLogSettingSuccess`);
export const createRemarkLogSettingFailure = createAction<string>(`${PREFIX}/createRemarkLogSettingFailure`);

export const updateRemarkLogSettingRequest = createAction<{ id: string; data: UpdateRemarkLogSettingBody }>(
  `${PREFIX}/updateRemarkLogSettingRequest`
);
export const updateRemarkLogSettingSuccess = createAction(`${PREFIX}/updateRemarkLogSettingSuccess`);
export const updateRemarkLogSettingFailure = createAction<string>(`${PREFIX}/updateRemarkLogSettingFailure`);

export const deleteRemarkLogSettingRequest = createAction<string>(`${PREFIX}/deleteRemarkLogSettingRequest`);
export const deleteRemarkLogSettingSuccess = createAction(`${PREFIX}/deleteRemarkLogSettingSuccess`);
export const deleteRemarkLogSettingFailure = createAction<string>(`${PREFIX}/deleteRemarkLogSettingFailure`);

export const updateRemarkLogRequest = createAction<{ id: number; data: UpdateRemarkLogBody }>(`${PREFIX}/updateRemarkLogRequest`);
export const updateRemarkLogSuccess = createAction(`${PREFIX}/updateRemarkLogSuccess`);
export const updateRemarkLogFailure = createAction<string>(`${PREFIX}/updateRemarkLogFailure`);

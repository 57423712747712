import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Account } from '@/pages/Account/type';
import { OtpWhitelistBody } from '@/pages/OtpWhitelist/types';
import { otpSkipRuleInitialState } from '@/svc/account-svc/otpSkipRule/reducer';
import { AccountSvcState } from '@/svc/account-svc/type';
import Service from '@/svc/serviceName';

const initialState: AccountSvcState = {
  accountInfo: {},
  otpSkipRule: otpSkipRuleInitialState,
};

export const migrateAccountIdentifierRequest = createAction<{ uuid1: string; uuid2: string }>(
  `${Service.accountSvc}/migrateAccountIdentifierRequest`
);
export const migrateAccountIdentifierSuccess = createAction<boolean>(`${Service.accountSvc}/migrateAccountIdentifierSuccess`);
export const migrateAccountIdentifierFailure = createAction<string>(`${Service.accountSvc}/migrateAccountIdentifierFailure`);

export const updateRiderEmailRequest = createAction<{ id: string; email: string }>(`${Service.accountSvc}/updateRiderEmailRequest`);
export const updateRiderEmailSuccess = createAction(`${Service.accountSvc}/updateRiderEmailSuccess`);
export const updateRiderEmailFailure = createAction<string>(`${Service.accountSvc}/updateRiderEmailFailure`);

export const fetchAccountDetailRequest = createAction<string>(`${Service.accountSvc}/fetchAccountDetailRequest`);
export const fetchAccountDetailFailure = createAction<string>(`${Service.accountSvc}/fetchAccountDetailFailure`);

export const createOtpWhitelistRequest = createAction<OtpWhitelistBody>(`${Service.accountSvc}/createOtpWhitelistRequest`);
export const createOtpWhitelistSuccess = createAction(`${Service.accountSvc}/createOtpWhitelistSuccess`);
export const createOtpWhitelistFailure = createAction<string>(`${Service.accountSvc}/createOtpWhitelistFailure`);

export const deleteOtpWhitelistRequest = createAction<number>(`${Service.accountSvc}/deleteOtpWhitelistRequest`);
export const deleteOtpWhitelistSuccess = createAction(`${Service.accountSvc}/deleteOtpWhitelistSuccess`);
export const deleteOtpWhitelistFailure = createAction<string>(`${Service.accountSvc}/deleteOtpWhitelistFailure`);

const accountSlice = createSlice({
  name: Service.accountSvc,
  initialState,
  reducers: {
    fetchAccountDetailSuccess(state: AccountSvcState, { payload }: PayloadAction<Account>) {
      state.accountInfo = { ...state.accountInfo, [payload.uuid]: payload };
    },
  },
});

export const { fetchAccountDetailSuccess } = accountSlice.actions;

export default accountSlice.reducer;

import { Breadcrumb as BreadcrumbComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface BreadcrumbProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  listTag?: React.ElementType;
  listClassName?: string;
  cssModule?: CSSModule;
  initialPath?: string;
}

export function Breadcrumb(props: BreadcrumbProps) {
  return <BreadcrumbComponent {...props} />;
}

import { REGION } from '@/assets/constants/enum';
import { valueOf } from '@/types';

export enum Pages {
  Cars = 'Cars',
  Drivers = 'Drivers',
  Airports = 'Airports',
  Cities = 'Cities',
  Polygons = 'Polygons',
  Orders = 'Orders',
  Rentals = 'Rentals',
  Zones = 'Zones',
  PartnerMonitor = 'PartnerMonitor',
  ForceDispatchRental = 'ForceDispatchRental',
  Regions = 'Regions',
  Areas = 'Areas',
  DriverApplications = 'DriverApplications',
  RestrictedAreas = 'RestrictedAreas',
  Dashboard = 'Dashboard',
  PriceVerification = 'PriceVerification',
  Reviews = 'Reviews',
  InstantSurgeRule = 'InstantSurgeRule',
  AdditionalPriceRules = 'AdditionalPriceRules',
  NewAdditionalPriceRules = 'NewAdditionalPriceRules',
  ForceDispatchDriver = 'ForceDispatchDriver',
  ForceDispatchCar = 'ForceDispatchCar',
  DispatchConfig = 'DispatchConfig',
  ProfilePictureChange = 'ProfilePictureChange',
  ForceDispatchHubs = 'ForceDispatchHubs',
  DynamicSurge = 'DynamicSurge',
  DynamicSurgeMonitor = 'DynamicSurgeMonitor',
  AlipayTransactions = 'AlipayTransactions',
  PaoTransactions = 'PaoTransactions',
  Ride = 'Ride',
  RideProducts = 'RideProducts',
  SystemDispatchRide = 'SystemDispatchRide',
  MomopayTransactions = 'MomopayTransactions',
  PipayTransactions = 'PipayTransactions',
  Inbox = 'Inbox',
  Transaction = 'Transaction',
  CmcbTransactions = 'CmcbTransactions',
  ShbRequestHistory = 'ShbRequestHistory',
  ShbWallet = 'ShbWallet',
  HourlyRentalRequest = 'HourlyRentalRequest',
  HourlyRentalCar = 'HourlyRentalCar',
  PhoneVerification = 'PhoneVerification',
  Banner = 'Banner',
  Delivery = 'Delivery',
  DeliveryRide = 'DeliveryRide',
  Merchant = 'Merchant',
  Warehouse = 'Warehouse',
  DeliveryDriver = 'DeliveryDriver',
  RoutingResult = 'RoutingResult',
  DepositTransaction = 'DepositTransaction',
  Penalty = 'Penalty',
  DeliverySchedule = 'DeliverySchedule',
  Account = 'Account',
  DeliveryRideOption = 'DeliveryRideOption',
  PendingRide = 'PendingRide',
  Rider = 'Rider',
  DeliveryRideRequest = 'DeliveryRideRequest',
  Promotion = 'Promotion',
  Coupon = 'Coupon',
  Admin = 'Admin',
  AdminLog = 'AdminLog',
  SMSTemplate = 'SMSTemplate',
  Roles = 'Roles',
  Corporate = 'Corporate',
  CorporateManager = 'CorporateManager',
  CorporateInvoice = 'CorporateInvoice',
  PaymentTransaction = 'PaymentTransaction',
  OtpSkipRule = 'OtpSkipRule',
  CreditCard = 'CreditCard',
  DeliveryPaymentOption = 'DeliveryPaymentOption',
  DeliveryRidePriceOption = 'DeliveryRidePriceOption',
  DriverTutorial = 'DriverTutorial',
  Poi = 'Poi',
  NearByPoi = 'NearByPoi',
  AppVersion = 'AppVersion',
  DeliveryDashboard = 'DeliveryDashboard',
  ExportSchedule = 'ExportSchedule',
  DeliveryExportResult = 'DeliveryExportResult',
  FraudCard = 'FraudCard',
  FraudLog = 'FraudLog',
  DeliveryRideProductOption = 'DeliveryRideProductOption',
  Place = 'Place',
  DeliveryPriceOption = 'DeliveryPriceOption',
  RouteTest = 'RouteTest',
  DeliveryTag = 'DeliveryTag',
  AccountMigration = 'Account:Migration',
  ReservationRide = 'ReservationRide',
  DriverGroup = 'DriverGroup',
  Withdraw = 'Withdraw',
  RecoverDeletedAccount = 'RecoverDeletedAccount',
  DriverIncentive = 'DriverIncentive',
  BankMaintenance = 'BankMaintenance',
  PrefixCoupon = 'PrefixCoupon',
  TadaMaintenance = 'TadaMaintenance',
  BankInfo = 'BankInfo',
  FaqListManagement = 'FaqListManagement',
  FaqTopicManagement = 'FaqTopicManagement',
  Referral = 'Referral',
  ShbLoanInfo = 'ShbLoanInfo',
  AbaTransaction = 'AbaTransaction',
  DocumentGuideManagement = 'DocumentGuideManagement',
  ScheduleSurgePreset = 'ScheduleSurgePreset',
  HelpCenterListManagement = 'HelpCenterListManagement',
  HelpCenterTopicManagement = 'HelpCenterTopicManagement',
  PlaceTester = 'PlaceTester',
  AdyenTransaction = 'AdyenTransaction',
  WingTransaction = 'WingTransaction',
  News = 'News',
  AofAccount = 'AofAccount',
  DeviceOtpReset = 'DeviceOtpReset',
  RideProductInfoTemplate = 'RideProductInfoTemplate',
  TaxPrefillDescription = 'TaxPrefillDescription',
  GooglePlace = 'GooglePlace',
  SurgeCap = 'SurgeCap',
  SafetyAccidentListManagement = 'SafetyAccidentListManagement',
  SafetyAccidentTopicManagement = 'SafetyAccidentTopicManagement',
  UserSubmission = 'UserSubmission',
  PreventMatch = 'PreventMatch',
  TollGantry = 'TollGantry',
  TollTimeTable = 'TollTimeTable',
  TollFreeDate = 'TollFreeDate',
  SupportPageManagement = 'SupportPageManagement',
  RiderMission = 'RiderMission',
  RemarkLogSetting = 'RemarkLogSetting',
  VehicleMaker = 'VehicleMaker',
  BINManagement = 'BINManagement',
  CpfContributionTable = 'CpfContributionTable',
  OtpWhitelist = 'OtpWhitelist',
  DynamicSurgeSetting = 'DynamicSurgeSetting',
}

export enum Resource {
  Ride = 'ride',
  Driver = 'driver',
  Balance = 'balance',
  PaymentTransaction = 'payment',
  Rider = 'rider',
  Products = 'product',
  Promotion = 'promotion',
  Dispatch = 'dispatch',
  Delivery = 'delivery',
  Merchant = 'merchant',
  DeliveryOption = 'deliveryOption',
  Coupon = 'coupon',
  Insurance = 'insurance',
  CommonResource = 'common-resource',
  Notice = 'notice',
  Account = 'account',
  Monitoring = 'monitoring',
  Fine = 'fine',
  Reviews = 'reviews',
  Cars = 'cars',
  Geo = 'geo',
  Corporate = 'corporate',
  Dashboard = 'dashboard',
  DeliveryRide = 'deliveryRide',
  HourlyRental = 'hourlyRental',
  Operation = 'operation',
  SDMenu = 'sd',
  Surge = 'surge',
  AppVersion = 'app-version',
  Penalty = 'penalty',
  Place = 'place',
  Finance = 'finance',
  AccountMigration = 'accountMigration',
  Maintenance = 'maintenance',
  ContentManagement = 'contentManagement',
  Admin = 'admin',
}

export const TADA_RESOURCES = [
  Resource.Dashboard,
  Resource.Ride,
  Resource.Products,
  Resource.Promotion,
  Resource.Geo,
  Resource.Notice,
  Resource.Dispatch,
  Resource.PaymentTransaction,
  Resource.Rider,
  Resource.Driver,
  Resource.Account,
  Resource.Balance,
  Resource.SDMenu,
  Resource.Surge,
  Resource.Place,
  Resource.AppVersion,
  Resource.Penalty,
  Resource.Corporate,
  Resource.Delivery,
  Resource.Merchant,
  Resource.DeliveryOption,
  Resource.HourlyRental,
  Resource.Finance,
  Resource.AccountMigration,
  Resource.Maintenance,
  Resource.ContentManagement,
  Resource.Admin,
];

export const TADA_RESOURCE_PAGES = {
  [Resource.AccountMigration]: [Pages.AccountMigration],
  [Resource.Finance]: [],
  [Resource.Dashboard]: [Pages.Dashboard],
  [Resource.Ride]: [Pages.Ride, Pages.Reviews, Pages.RouteTest, Pages.ReservationRide],
  [Resource.Products]: [Pages.RideProducts, Pages.RideProductInfoTemplate],
  [Resource.Promotion]: [Pages.Promotion, Pages.Coupon, Pages.DriverIncentive, Pages.PrefixCoupon, Pages.Referral, Pages.RiderMission],
  [Resource.Geo]: [Pages.Regions, Pages.Cities, Pages.Areas, Pages.RestrictedAreas, Pages.Polygons, Pages.Zones, Pages.Airports],
  [Resource.Notice]: [
    Pages.Banner,
    Pages.Inbox,
    Pages.News,
    Pages.DriverTutorial,
    Pages.RemarkLogSetting,
    Pages.VehicleMaker,
    Pages.CpfContributionTable,
  ],
  [Resource.Dispatch]: [Pages.DispatchConfig],
  [Resource.PaymentTransaction]: [
    Pages.AlipayTransactions,
    Pages.PaoTransactions,
    Pages.PipayTransactions,
    Pages.MomopayTransactions,
    Pages.CmcbTransactions,
    Pages.ShbRequestHistory,
    Pages.CreditCard,
    Pages.FraudCard,
    Pages.FraudLog,
    Pages.PaymentTransaction,
    Pages.AbaTransaction,
    Pages.AdyenTransaction,
    Pages.WingTransaction,
    Pages.AofAccount,
    Pages.BINManagement,
  ],
  [Resource.Rider]: [Pages.Rider, Pages.Withdraw, Pages.RecoverDeletedAccount],
  [Resource.Driver]: [
    Pages.Cars,
    Pages.Drivers,
    Pages.DriverApplications,
    Pages.ProfilePictureChange,
    Pages.DriverGroup,
    Pages.Withdraw,
    Pages.RecoverDeletedAccount,
    Pages.TaxPrefillDescription,
  ],
  [Resource.Account]: [Pages.OtpSkipRule, Pages.DeviceOtpReset, Pages.PhoneVerification, Pages.OtpWhitelist],
  [Resource.Balance]: [Pages.Account, Pages.DepositTransaction, Pages.ShbWallet, Pages.Transaction, Pages.BankInfo, Pages.ShbLoanInfo],
  [Resource.SDMenu]: [Pages.PartnerMonitor, Pages.ForceDispatchCar, Pages.ForceDispatchDriver, Pages.ForceDispatchHubs, Pages.SystemDispatchRide],
  [Resource.Surge]: [
    Pages.DynamicSurge,
    Pages.DynamicSurgeMonitor,
    Pages.PriceVerification,
    Pages.InstantSurgeRule,
    Pages.NewAdditionalPriceRules,
    Pages.AdditionalPriceRules,
    Pages.ScheduleSurgePreset,
    Pages.SurgeCap,
    Pages.DynamicSurgeSetting,
  ],
  [Resource.Place]: [
    Pages.Place,
    Pages.PlaceTester,
    Pages.GooglePlace,
    Pages.UserSubmission,
    Pages.TollGantry,
    Pages.TollTimeTable,
    Pages.TollFreeDate,
  ],
  [Resource.Penalty]: [Pages.Penalty, Pages.PreventMatch],
  [Resource.Corporate]: [Pages.Corporate, Pages.CorporateManager, Pages.CorporateInvoice],
  [Resource.Delivery]: [
    Pages.Delivery,
    Pages.DeliverySchedule,
    Pages.DeliveryDriver,
    Pages.Warehouse,
    Pages.DeliveryRide,
    Pages.DeliveryRideRequest,
    Pages.RoutingResult,
    Pages.PendingRide,
    Pages.DeliveryDashboard,
    Pages.ExportSchedule,
    Pages.DeliveryExportResult,
    Pages.SMSTemplate,
    Pages.DeliveryTag,
  ],
  [Resource.DeliveryOption]: [
    Pages.DeliveryRidePriceOption,
    Pages.DeliveryPaymentOption,
    Pages.DeliveryRideOption,
    Pages.DeliveryRideProductOption,
    Pages.DeliveryPriceOption,
  ],
  [Resource.Merchant]: [Pages.Merchant],
  [Resource.AppVersion]: [Pages.AppVersion],
  [Resource.HourlyRental]: [Pages.HourlyRentalCar, Pages.HourlyRentalRequest],
  [Resource.Maintenance]: [Pages.BankMaintenance, Pages.TadaMaintenance],
  [Resource.ContentManagement]: [
    Pages.FaqListManagement,
    Pages.FaqTopicManagement,
    Pages.HelpCenterListManagement,
    Pages.HelpCenterTopicManagement,
    Pages.SafetyAccidentListManagement,
    Pages.SafetyAccidentTopicManagement,
    Pages.DocumentGuideManagement,
    Pages.SupportPageManagement,
  ],
  [Resource.Admin]: [Pages.Admin, Pages.Roles, Pages.AdminLog],
};

export const ACTION = {
  ALL: 'a',
  READ: 'r',
  LIST: 'l',
  WRITE: 'w',
  EXPORT: 'x',
  SUPER: 's',
} as const;

export type Action = valueOf<typeof ACTION>;

export const BLACK_LIST_PAGES_BY_REGION: Record<REGION, Pages[]> = {
  [REGION.SG]: [
    Pages.PipayTransactions,
    Pages.CmcbTransactions,
    Pages.ShbRequestHistory,
    Pages.AbaTransaction,
    Pages.WingTransaction,
    Pages.MomopayTransactions,
  ],
  [REGION.KH]: [Pages.AlipayTransactions, Pages.PaoTransactions, Pages.MomopayTransactions, Pages.CpfContributionTable],
  [REGION.VN]: [
    Pages.AlipayTransactions,
    Pages.PaoTransactions,
    Pages.PipayTransactions,
    Pages.CmcbTransactions,
    Pages.ShbRequestHistory,
    Pages.AbaTransaction,
    Pages.WingTransaction,
    Pages.CpfContributionTable,
  ],
  [REGION.KR]: [Pages.CpfContributionTable],
  [REGION.ET]: [Pages.CpfContributionTable],
  [REGION.ID]: [Pages.CpfContributionTable],
  [REGION.HK]: [Pages.CpfContributionTable],
  [REGION.TH]: [Pages.CpfContributionTable],
  [REGION.GLOBAL]: [],
};

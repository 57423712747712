import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createReferralFailure,
  createReferralRequest,
  createReferralSuccess,
  deleteReferralFailure,
  deleteReferralRequest,
  deleteReferralSuccess,
  updateReferralFailure,
  updateReferralRequest,
  updateReferralSuccess,
} from '@/svc/member-svc/referral/reducer';
import { authenticatedRequest } from '@/utils/request';
import { ErrorResponse } from '@/utils/types';

function* createReferral({ payload }: ReturnType<typeof createReferralRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/referral-reward-rules/create', { data: payload });

    const action = response.ok ? createReferralSuccess(response.data) : createReferralFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(createReferralFailure(e as ErrorResponse));
  }
}

function* updateReferral({ payload: { id, data } }: ReturnType<typeof updateReferralRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/referral-reward-rules/edit/${id}`, { data });

    const action = response.ok ? updateReferralSuccess() : updateReferralFailure(response.data);

    yield put(action);
  } catch (e) {
    yield put(updateReferralFailure(e as ErrorResponse));
  }
}

function* deleteReferral({ payload }: ReturnType<typeof deleteReferralRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/referral-reward-rules/${payload}`);

    const action = response.ok ? deleteReferralSuccess() : deleteReferralFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteReferralFailure((e as Error).message));
  }
}

export default function* referralSaga() {
  yield takeLatest(createReferralRequest.type, createReferral);
  yield takeLatest(updateReferralRequest.type, updateReferral);
  yield takeLatest(deleteReferralRequest.type, deleteReferral);
}

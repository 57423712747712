import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdminLogInterface, AdminLogQueryInterface, AdminLogState } from '@/pages/AdminLog/type';
import { detailSuccess } from '@/utils/reducerUtils';
import { DetailResponse, ListResponse } from '@/utils/types';

const initialState: AdminLogState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
};

const adminLogSlice = createSlice({
  name: 'adminLog',
  initialState,
  reducers: {
    fetchInfiniteAdminLogListRequest: {
      prepare: (search: AdminLogQueryInterface) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchInfiniteAdminLogListSuccess: {
      prepare: (payload: ListResponse<AdminLogInterface>) => ({ payload }),
      reducer: (state, { payload }: PayloadAction<ListResponse<AdminLogInterface>>) => {
        Object.assign(state, {
          isFetching: false,
          byId: { ...state.byId, ...payload.entities.content },
          ids: payload.result.pageable.pageNumber === 0 ? payload.result.content : state.ids.concat(...payload.result.content),
          page: {
            total: payload.result.totalPages,
            current: payload.result.pageable.pageNumber + 1,
            size: payload.result.size,
          },
          totalCount: payload.result.totalElements,
        });
      },
    },
    fetchInfiniteAdminLogListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },

    fetchAdminLogDetailRequest: {
      prepare: (id: string) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchAdminLogDetailSuccess: {
      prepare: (payload: DetailResponse<AdminLogInterface>) => ({ payload }),
      reducer: (state, action: PayloadAction<DetailResponse<AdminLogInterface>>) => {
        Object.assign(state, detailSuccess(state, action));
      },
    },
    fetchAdminLogDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchInfiniteAdminLogListRequest,
  fetchInfiniteAdminLogListSuccess,
  fetchInfiniteAdminLogListFailure,
  fetchAdminLogDetailRequest,
  fetchAdminLogDetailSuccess,
  fetchAdminLogDetailFailure,
} = adminLogSlice.actions;

export default adminLogSlice.reducer;

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryExportResultListRequest,
  fetchDeliveryExportResultListSuccess,
  fetchDeliveryExportResultListFailure,
  exportDeliveryResultRequest,
  exportDeliveryResultSuccess,
  exportDeliveryResultFailure,
} from '@/pages/DeliveryExportResults/reducer';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchDeliveryExportResultList({ payload }: ReturnType<typeof fetchDeliveryExportResultListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/export_result', {
      params: payload,
      schema: { content: [entity] },
    });

    if (response.ok) {
      yield put(fetchDeliveryExportResultListSuccess(response.data));
    } else {
      yield put(fetchDeliveryExportResultListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryExportResultListFailure((e as Error).message));
  }
}

function* exportDeliveryExportResult({ payload }: ReturnType<typeof exportDeliveryResultRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/export_result:export', {
      params: payload,
      responseType: 'blob',
    });
    if (response.ok) {
      yield saveFile(response, `deliveryExportResult-${payload.timeFromStart}~${payload.timeFromEnd}.xlsx`);
      yield put(exportDeliveryResultSuccess());
    } else {
      yield put(exportDeliveryResultFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportDeliveryResultFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchDeliveryExportResultListRequest.type, fetchDeliveryExportResultList);
  yield takeLatest(exportDeliveryResultRequest.type, exportDeliveryExportResult);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "HourlyRentalCarList" */ '@/pages/HourlyRentalCar/List'));
const New = lazy(() => import(/* webpackChunkName: "HourlyRentalCarNew" */ '@/pages/HourlyRentalCar/New'));

export const hourlyRentalCarRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.HourlyRentalCar,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.HourlyRentalCar}/new`,
    component: New,
    exact: true,
  },
];

import styled from 'styled-components';

import { Input, InputProps } from '@/components/template';

/**
 * `radio` 타입의 Input 컴포넌트이다.
 */
const RadioInput = styled(Input).attrs((props: InputProps) => ({
  ...props,
  type: 'radio',
}))<InputProps>`
  position: unset;
  width: 23px;
  height: 23px;
  margin: 0;
  border: solid 1px #c2cfd6;
  background-color: #ffffff;
  outline: none;
  border-radius: 50%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:after {
    content: '';
    display: none;
    width: 15px;
    height: 15px;
    margin: 3px;
    background-color: #21a8d8;
    border-radius: 50%;
  }

  &:checked:after {
    display: block;
  }
`;

export default RadioInput;

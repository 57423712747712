import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CreditList" */ '@/pages/CreditCard/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CreditDetail" */ '@/pages/CreditCard/Detail'));

export const creditCardRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.CreditCard,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.CreditCard}/:id`,
    exact: true,
    component: Detail,
  },
];

import { CSSProperties, OptionHTMLAttributes } from 'react';
import * as React from 'react';

import { StyledInput } from '@/components/InputField/KeyIn/TextInput';
import { InputProps } from '@/components/template';

export type DropdownOptionsType = Array<
  Omit<OptionHTMLAttributes<HTMLOptionElement>, 'value'> & {
    value: string | number | boolean;
    text: string | number;
  }
>;

export interface DropdownProps extends Omit<InputProps, 'value' | 'onChange'> {
  options: DropdownOptionsType;
  value?: string | number | boolean | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customStyle?: CSSProperties | string;
  colOption?: { [key: string]: number };
  label?: string;
}

function Dropdown({ options, value, disabled = false, ...props }: DropdownProps) {
  return (
    <StyledInput type='select' value={`${value}`} disabled={disabled} {...props}>
      {options.map(({ value: opValue, text, ...props }, idx) => (
        <option value={`${opValue}`} key={`${opValue}_${text}_${idx}`} data-cy={`option-${idx}`} {...props}>
          {text}
        </option>
      ))}
    </StyledInput>
  );
}

export default Dropdown;

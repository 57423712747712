import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  replayCurrentAccountFailure,
  replayCurrentAccountRequest,
  replayCurrentAccountSuccess,
  replayDepositAccountFailure,
  replayDepositAccountRequest,
  replayDepositAccountSuccess,
  unverifyCurrentAccountBankInfoFailure,
  unverifyCurrentAccountBankInfoRequest,
  unverifyCurrentAccountBankInfoSuccess,
  updateCurrentAccountBankInfoFailure,
  updateCurrentAccountBankInfoRequest,
  updateCurrentAccountBankInfoSuccess,
  verifyCurrentAccountBankInfoFailure,
  verifyCurrentAccountBankInfoRequest,
  verifyCurrentAccountBankInfoSuccess,
} from '@/svc/balance-svc/account/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateCurrentAccountBankInfo({ payload: { id, data } }: ReturnType<typeof updateCurrentAccountBankInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/balance/account/current/${id}/bankInfo`, {
      data,
      contentType: 'multipart/form-data',
    });

    if (response.ok) {
      yield put(updateCurrentAccountBankInfoSuccess());
    } else {
      yield put(updateCurrentAccountBankInfoFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateCurrentAccountBankInfoFailure((e as Error).message));
  }
}

function* replayCurrentAccount({ payload }: ReturnType<typeof replayCurrentAccountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/balance/account/current/${payload}/replay`);

    if (response.ok) {
      yield put(replayCurrentAccountSuccess('Replay Successfully!'));
    } else {
      yield put(replayCurrentAccountFailure(response.data.message));
    }
  } catch (e) {
    yield put(replayCurrentAccountFailure((e as Error).message));
  }
}

function* verifyCurrentAccountBankInfo({ payload }: ReturnType<typeof verifyCurrentAccountBankInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/balance/account/current/${payload}/verifyBankInfo`);

    if (response.ok) {
      yield put(verifyCurrentAccountBankInfoSuccess());
    } else {
      yield put(verifyCurrentAccountBankInfoFailure(response.data.message));
    }
  } catch (e) {
    yield put(verifyCurrentAccountBankInfoFailure((e as Error).message));
  }
}

function* unverifyCurrentAccountBankInfo({ payload: { id, data } }: ReturnType<typeof unverifyCurrentAccountBankInfoRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/balance/account/current/${id}/unverifyBankInfo`, { data });

    if (response.ok) {
      yield put(unverifyCurrentAccountBankInfoSuccess());
    } else {
      yield put(unverifyCurrentAccountBankInfoFailure(response.data.message));
    }
  } catch (e) {
    yield put(unverifyCurrentAccountBankInfoFailure((e as Error).message));
  }
}

function* replayDepositAccount({ payload }: ReturnType<typeof replayDepositAccountRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/balance/account/deposit/${payload}/replay`);

    if (response.ok) {
      yield put(replayDepositAccountSuccess('Replay Deposit Account Successfully!'));
    } else {
      yield put(replayDepositAccountFailure(response.data.message));
    }
  } catch (e) {
    yield put(replayDepositAccountFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(updateCurrentAccountBankInfoRequest.type, updateCurrentAccountBankInfo);
  yield takeLatest(replayCurrentAccountRequest.type, replayCurrentAccount);
  yield takeLatest(verifyCurrentAccountBankInfoRequest.type, verifyCurrentAccountBankInfo);
  yield takeLatest(unverifyCurrentAccountBankInfoRequest.type, unverifyCurrentAccountBankInfo);
  yield takeLatest(replayDepositAccountRequest.type, replayDepositAccount);
}

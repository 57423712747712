import { takeLatest, put } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { PAYMENT_TRANSACTION_STATUS } from '@/assets/constants';
import { showErrorToast, showSuccessToast } from '@/components/etc';
import {
  fetchEntityTransactionHistoriesRequest,
  fetchEntityTransactionHistoriesSuccess,
  fetchEntityTransactionHistoriesFailure,
  createAdyenTransactionRequest,
  createAdyenTransactionSuccess,
  createAdyenTransactionFailure,
  topupRefundTransactionRequest,
  topupRefundTransactionSuccess,
  topupRefundTransactionFailure,
} from '@/svc/pay-svc/transaction/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchEntityTransactionHistories({ payload }: ReturnType<typeof fetchEntityTransactionHistoriesRequest>): IterableIterator {
  try {
    // 서버에서 데이터 한개만 내려줌
    const response = yield authenticatedRequest.get('/entity_transaction_histories', { params: payload });

    if (response.ok) {
      yield put(fetchEntityTransactionHistoriesSuccess(payload.entityId, response.data.content));
    } else {
      yield put(fetchEntityTransactionHistoriesFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchEntityTransactionHistoriesFailure((e as Error).message));
  }
}

function* createAdyenTransaction({ payload }: ReturnType<typeof createAdyenTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/payment/adyen-transactions', { data: payload });

    if (response.ok) {
      if (response.data.status === PAYMENT_TRANSACTION_STATUS.CANCELED) {
        showErrorToast('Created Adyen Transaction Failed');
      } else {
        showSuccessToast('Created Adyen Transaction Succesfully!');
      }

      yield put(createAdyenTransactionSuccess());
    } else {
      yield put(createAdyenTransactionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createAdyenTransactionFailure((e as Error).message));
  }
}

function* topupRefundTransaction({ payload: { id, data } }: ReturnType<typeof topupRefundTransactionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/v1/transaction/${id}/topupRefund`, { data });

    const action = response.ok ? topupRefundTransactionSuccess() : topupRefundTransactionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(topupRefundTransactionFailure((e as Error).message));
  }
}

export default function* paySvcTransactionSaga() {
  yield takeLatest(fetchEntityTransactionHistoriesRequest.type, fetchEntityTransactionHistories);
  yield takeLatest(createAdyenTransactionRequest.type, createAdyenTransaction);
  yield takeLatest(topupRefundTransactionRequest.type, topupRefundTransaction);
}

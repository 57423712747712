import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { branchRoute } from '@/pages/Branch/route';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CorporateList" */ '@/pages/Corporate/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CorporateDetail" */ '@/pages/Corporate/Detail'));
const New = lazy(() => import(/* webpackChunkName: "CorporateNew" */ '@/pages/Corporate/New'));
const Edit = lazy(() => import(/* webpackChunkName: "CorporateEdit" */ '@/pages/Corporate/Edit'));
const InviteWithEmail = lazy(() => import(/* webpackChunkName: "InviteWithEmail" */ '@/pages/Corporate/InviteWithEmail'));

export const corporateRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    exact: true,
    path: PATH.Corporate,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    exact: true,
    path: `${PATH.Corporate}/new`,
    component: New,
  },
  {
    permission: ActionType.READ,
    exact: true,
    path: `${PATH.Corporate}/:id`,
    component: Detail,
  },
  {
    permission: ActionType.EDIT,
    exact: true,
    path: `${PATH.Corporate}/:id/edit`,
    component: Edit,
  },
  {
    permission: ActionType.READ,
    exact: true,
    path: `${PATH.Corporate}/:id/invite-with-email`,
    component: InviteWithEmail,
  },
  ...branchRoute,
];

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  addBulkMissionRewardFailure,
  addBulkMissionRewardRequest,
  addBulkMissionRewardSuccess,
  addMissionRewardFailure,
  addMissionRewardRequest,
  addMissionRewardSuccess,
  banDriverIncentiveFailure,
  banDriverIncentiveRequest,
  banDriverIncentiveSuccess,
  createDriverIncentiveFailure,
  createDriverIncentiveRequest,
  createDriverIncentiveSuccess,
  fetchDriverIncentiveListFailure,
  fetchDriverIncentiveListRequest,
  fetchDriverIncentiveListSuccess,
  syncDriverProgressFailure,
  syncDriverProgressRequest,
  syncDriverProgressSuccess,
  updateDriverIncentiveFailure,
  updateDriverIncentiveRequest,
  updateDriverIncentiveSuccess,
  updateEditableDriverIncentiveFailure,
  updateEditableDriverIncentiveRequest,
  updateEditableDriverIncentiveSuccess,
  validateBulkDriverIdsFailure,
  validateBulkDriverIdsRequest,
  validateBulkDriverIdsSuccess,
} from '@/svc/member-svc/driverIncentive/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const { schema } = getContentSchema();

function* fetchDriverIncentiveList({ payload }: ReturnType<typeof fetchDriverIncentiveListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/v1/driver_missions', { params: payload, schema });

    const action = response.ok ? fetchDriverIncentiveListSuccess(response.data) : fetchDriverIncentiveListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverIncentiveListFailure((e as Error).message));
  }
}

function* createDriverIncentive({ payload }: ReturnType<typeof createDriverIncentiveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/driver_missions', { data: payload });

    const action = response.ok ? createDriverIncentiveSuccess(response.data) : createDriverIncentiveFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createDriverIncentiveFailure((e as Error).message));
  }
}

function* updateDriverIncentive({ payload }: ReturnType<typeof updateDriverIncentiveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/v1/driver_missions/${payload.id}/info`, { data: payload.data });

    const action = response.ok ? updateDriverIncentiveSuccess(response.data) : updateDriverIncentiveFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDriverIncentiveFailure((e as Error).message));
  }
}

function* syncDriverProgress({ payload }: ReturnType<typeof syncDriverProgressRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${payload.id}/sync`, { data: payload.data });

    const action = response.ok ? syncDriverProgressSuccess(response.data) : syncDriverProgressFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(syncDriverProgressFailure((e as Error).message));
  }
}

function* addMissionReward({ payload }: ReturnType<typeof addMissionRewardRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/driver_missions/reward', { data: payload, timeout: 0 });

    const action =
      response.ok && response.data.failCount === 0
        ? addMissionRewardSuccess()
        : addMissionRewardFailure(response.data.message || `Failed User Ids: ${response.data.failUserIds.join(', ')}`);

    yield put(action);
  } catch (e) {
    yield put(addMissionRewardFailure((e as Error).message));
  }
}

function* addBulkMissionReward({ payload }: ReturnType<typeof addBulkMissionRewardRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/v1/driver_missions/reward:bulk', { data: payload, contentType: 'multipart/form-data' });

    const action =
      response.ok && response.data.failCount === 0
        ? addBulkMissionRewardSuccess()
        : addBulkMissionRewardFailure(response.data.message || `Failed User Ids: ${response.data.failUserIds.join(', ')}`);

    yield put(action);
  } catch (e) {
    yield put(addBulkMissionRewardFailure((e as Error).message));
  }
}

function* banDriverIncentive({ payload }: ReturnType<typeof banDriverIncentiveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/driver_missions/${payload.id}/ban`, { data: payload.data });

    const action = response.ok ? banDriverIncentiveSuccess(response.data) : banDriverIncentiveFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(banDriverIncentiveFailure((e as Error).message));
  }
}

function* updateEditableDriverIncentive({ payload }: ReturnType<typeof updateEditableDriverIncentiveRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/admin/v1/driver_missions/${payload.id}`, { data: payload.data });

    const action = response.ok ? updateEditableDriverIncentiveSuccess(response.data) : updateEditableDriverIncentiveFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateEditableDriverIncentiveFailure((e as Error).message));
  }
}

function* validateBulkDriverIds({ payload }: ReturnType<typeof validateBulkDriverIdsRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/admin/drivers/bulk-validate-driver-ids', { data: payload });

    const action = response.ok ? validateBulkDriverIdsSuccess(response.data) : validateBulkDriverIdsFailure(response.data.message ?? response.data);

    yield put(action);
  } catch (e) {
    yield put(validateBulkDriverIdsFailure((e as Error).message));
  }
}

export default function* driverIncentiveSaga() {
  yield takeLatest(fetchDriverIncentiveListRequest.type, fetchDriverIncentiveList);
  yield takeLatest(createDriverIncentiveRequest.type, createDriverIncentive);
  yield takeLatest(updateDriverIncentiveRequest.type, updateDriverIncentive);
  yield takeLatest(syncDriverProgressRequest.type, syncDriverProgress);
  yield takeLatest(addMissionRewardRequest.type, addMissionReward);
  yield takeLatest(banDriverIncentiveRequest.type, banDriverIncentive);
  yield takeLatest(updateEditableDriverIncentiveRequest.type, updateEditableDriverIncentive);
  yield takeLatest(validateBulkDriverIdsRequest.type, validateBulkDriverIds);
  yield takeLatest(addBulkMissionRewardRequest.type, addBulkMissionReward);
}

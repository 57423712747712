import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  updateDocumentStatusRequest,
  updateDocumentStatusSuccess,
  updateDocumentStatusFailure,
  searchDriverApplicationRequest,
  searchDriverApplicationSuccess,
  searchDriverApplicationFailure,
  updateCarTypeRequest,
  updateCarTypeSuccess,
  updateCarTypeFailure,
  updateChangeRequestRequest,
  updateChangeRequestSuccess,
  updateChangeRequestFailure,
  fetchDriverApplicationListRequest,
  fetchDriverApplicationListSuccess,
  fetchDriverApplicationListFailure,
  fetchDriverApplicationRestrictListRequest,
  fetchDriverApplicationRestrictListSuccess,
  fetchDriverApplicationRestrictListFailure,
  fetchDriverApplicationDetailRequest,
  fetchDriverApplicationDetailSuccess,
  fetchDriverApplicationDetailFailure,
  updateDriverApplicationRequest,
  updateDriverApplicationSuccess,
  updateDriverApplicationFailure,
  registerVehicleRequest,
  registerVehicleSuccess,
  registerVehicleFailure,
  resetVehicleChangeStatusRequest,
  resetVehicleChangeStatusSuccess,
  resetVehicleChangeStatusFailure,
  changeApproveOrRejectApplicationRequest,
  changeApproveOrRejectApplicationSuccess,
  changeApproveOrRejectApplicationFailure,
  updateInternalDocumentRequest,
  updateInternalDocumentSuccess,
  updateInternalDocumentFailure,
} from '@/pages/DriverApplications/reducer';
import { getContentSchema } from '@/utils/api/index';
import { authenticatedRequest } from '@/utils/request';

const { schema, entity } = getContentSchema();

function* updateDocumentStatus({ payload: { id, body } }: ReturnType<typeof updateDocumentStatusRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/member/drivers/${id}/update-document-status`, { data: body });

    const action = res.ok ? updateDocumentStatusSuccess(res.data) : updateDocumentStatusFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDocumentStatusFailure((e as Error).message));
  }
}

function* searchDriverApplication({ payload }: ReturnType<typeof searchDriverApplicationRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.get('/member/drivers/search', { params: { query: payload }, schema: [entity] });

    const action = res.ok ? searchDriverApplicationSuccess(res.data) : searchDriverApplicationFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(searchDriverApplicationFailure((e as Error).message));
  }
}

function* updateCarType({ payload: { id, body } }: ReturnType<typeof updateCarTypeRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/member/drivers/${id}/update-car-type`, { data: body });

    const action = res.ok ? updateCarTypeSuccess() : updateCarTypeFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateCarTypeFailure((e as Error).message));
  }
}

function* updateChangeRequest({ payload: { id, body } }: ReturnType<typeof updateChangeRequestRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/member/drivers/${id}/update-change-request`, { data: body });

    const action = res.ok ? updateChangeRequestSuccess() : updateChangeRequestFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateChangeRequestFailure((e as Error).message));
  }
}

function* changeApproveOrRejectApplication({
  payload: { driverId, applicationId, approve, approveFields, failedFieldsWithRemark },
}: ReturnType<typeof changeApproveOrRejectApplicationRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(
      `/member/drivers/${driverId}/${approve ? 'approve-change-application' : 'reject-change-application'}/${applicationId}`,
      {
        data: approve ? (approveFields ?? {}) : { failedFieldsWithRemark },
      }
    );

    const action = res.ok ? changeApproveOrRejectApplicationSuccess() : changeApproveOrRejectApplicationFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(changeApproveOrRejectApplicationFailure((e as Error).message));
  }
}

function* fetchDriverApplicationList({ payload }: ReturnType<typeof fetchDriverApplicationListRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.get('/member/drivers/applications/list', { params: payload, schema });

    const action = res.ok ? fetchDriverApplicationListSuccess(res.data) : fetchDriverApplicationListFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverApplicationListFailure((e as Error).message));
  }
}

function* fetchDriverApplicationRestrictList({ payload }: ReturnType<typeof fetchDriverApplicationRestrictListRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.get('/member/drivers/applications', { params: payload, schema });

    const action = res.ok ? fetchDriverApplicationRestrictListSuccess(res.data) : fetchDriverApplicationRestrictListFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverApplicationRestrictListFailure((e as Error).message));
  }
}

function* fetchDriverApplicationDetail({ payload: { id, query } }: ReturnType<typeof fetchDriverApplicationDetailRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.get(`/member/drivers/${id}`, { schema: entity, params: query });

    const action = res.ok ? fetchDriverApplicationDetailSuccess(res.data) : fetchDriverApplicationDetailFailure(res.data.messag);

    yield put(action);
  } catch (e) {
    yield put(fetchDriverApplicationDetailFailure((e as Error).message));
  }
}

function* updateDriverApplication({ payload: { id, body, isChangeRegion } }: ReturnType<typeof updateDriverApplicationRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/member/drivers/${id}/update-document-with-files`, { data: body });

    const action = res.ok ? updateDriverApplicationSuccess(!!isChangeRegion) : updateDriverApplicationFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateDriverApplicationFailure((e as Error).message));
  }
}

function* registerVehicle({ payload }: ReturnType<typeof registerVehicleRequest>): IterableIterator {
  try {
    const res = yield authenticatedRequest.post(`/admin/drivers/${payload}/register-vehicle`);

    const action = res.ok ? registerVehicleSuccess() : registerVehicleFailure(res.data.message);

    yield put(action);
  } catch (e) {
    yield put(registerVehicleFailure((e as Error).message));
  }
}

function* resetVehicleChangeStatus({ payload }: ReturnType<typeof resetVehicleChangeStatusRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/${payload}/reset-vehicle-change-status`);

    const action = response.ok ? resetVehicleChangeStatusSuccess() : resetVehicleChangeStatusFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(resetVehicleChangeStatusFailure((e as Error).message));
  }
}

function* updateInternalDocument({ payload: { id, data } }: ReturnType<typeof updateInternalDocumentRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/drivers/${id}/update-internal-document`, { data, contentType: 'multipart/form-data' });

    const action = response.ok ? updateInternalDocumentSuccess() : updateInternalDocumentFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateInternalDocumentFailure((e as Error).message));
  }
}

export default function* driverApplicationSaga() {
  yield takeLatest(updateDocumentStatusRequest.type, updateDocumentStatus);
  yield takeLatest(searchDriverApplicationRequest.type, searchDriverApplication);
  yield takeLatest(updateCarTypeRequest.type, updateCarType);
  yield takeLatest(updateChangeRequestRequest.type, updateChangeRequest);
  yield takeLatest(changeApproveOrRejectApplicationRequest.type, changeApproveOrRejectApplication);
  yield takeLatest(fetchDriverApplicationListRequest.type, fetchDriverApplicationList);
  yield takeLatest(fetchDriverApplicationRestrictListRequest.type, fetchDriverApplicationRestrictList);
  yield takeLatest(fetchDriverApplicationDetailRequest.type, fetchDriverApplicationDetail);
  yield takeLatest(updateDriverApplicationRequest.type, updateDriverApplication);
  yield takeLatest(registerVehicleRequest.type, registerVehicle);
  yield takeLatest(resetVehicleChangeStatusRequest.type, resetVehicleChangeStatus);
  yield takeLatest(updateInternalDocumentRequest.type, updateInternalDocument);
}

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface HelmetTitleProps {
  title: string;
}

export function HelmetTitle({ title }: HelmetTitleProps) {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t(title)}</title>
    </Helmet>
  );
}

import { createAction, createSlice } from '@reduxjs/toolkit';

import { DriverTutorialInterface } from '@/pages/DriverTutorial/type';
import Service from '@/svc/serviceName';

export const createDriverTutorialRequest = createAction<FormData>(`${Service.driverTutorialSvc}/createDriverTutorialRequest`);
export const createDriverTutorialSuccess = createAction<DriverTutorialInterface>(`${Service.driverTutorialSvc}/createDriverTutorialSuccess`);
export const createDriverTutorialFailure = createAction<string>(`${Service.driverTutorialSvc}/createDriverTutorialFailure`);

export const updateDriverTutorialRequest = createAction(`${Service.driverTutorialSvc}/updateDriverTutorialRequest`, (id: string, data: FormData) => ({
  payload: { id, data },
}));
export const updateDriverTutorialSuccess = createAction<DriverTutorialInterface>(`${Service.driverTutorialSvc}/updateDriverTutorialSuccess`);
export const updateDriverTutorialFailure = createAction<string>(`${Service.driverTutorialSvc}/updateDriverTutorialFailure`);

export const fetchDownloadDriverTutorialTemplateRequest = createAction(`${Service.driverTutorialSvc}/fetchDownloadDriverTutorialTemplateRequest`);
export const fetchDownloadDriverTutorialTemplateSuccess = createAction(`${Service.driverTutorialSvc}/fetchDownloadDriverTutorialTemplateSuccess`);
export const fetchDownloadDriverTutorialTemplateFailure = createAction<string>(
  `${Service.driverTutorialSvc}/fetchDownloadDriverTutorialTemplateFailure`
);

export const uploadDriverTutorialTargetDriversRequest = createAction<{ id: number; data: FormData }>(
  `${Service.driverTutorialSvc}/uploadDriverTutorialTargetDriversRequest`
);
export const uploadDriverTutorialTargetDriversSuccess = createAction(`${Service.driverTutorialSvc}/uploadDriverTutorialTargetDriversSuccess`);
export const uploadDriverTutorialTargetDriversFailure = createAction<string>(`${Service.driverTutorialSvc}/uploadDriverTutorialTargetDriversFailure`);

const driverTutorialSlice = createSlice({
  name: Service.driverTutorialSvc,
  initialState: {},
  reducers: {},
});

export default driverTutorialSlice.reducer;

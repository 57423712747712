import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Pages } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import ButtonContainer from '@/components/ButtonContainer';
import CheckPermission, { ActionType } from '@/components/CheckPermission';
import { Button } from '@/components/template';
import { useToolkitActionToast } from '@/hooks/toast';
import { usePermissionSelector } from '@/store/selector';
import { exportCpfContributionTableRequest } from '@/svc/balance-svc/cpfContributionTable/reducer';

function DetailFooterContent({ id }: { id: string }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const permission = usePermissionSelector(Pages.CpfContributionTable);

  const handleExportCpfContributionTable = (id: string) => {
    dispatch(exportCpfContributionTableRequest({ id }));
  };

  useToolkitActionToast(exportCpfContributionTableRequest.type, {
    successMessage: 'Successfully Exported!',
  });

  return (
    <ButtonContainer>
      <CheckPermission action={ActionType.EDIT} permission={permission}>
        <Button color={'primary'} onClick={() => history.push(`${PATH.CpfContributionTable}/${id}/edit`)}>
          Edit
        </Button>
      </CheckPermission>
      <CheckPermission action={ActionType.EXPORT} permission={permission}>
        <Button
          color={'primary'}
          onClick={() => {
            handleExportCpfContributionTable(id);
          }}
        >
          Export
        </Button>
      </CheckPermission>
    </ButtonContainer>
  );
}

export default DetailFooterContent;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "AdminList" */ '@/pages/Admin/List'));
const New = lazy(() => import(/* webpackChunkName: "AdminNew" */ '@/pages/Admin/New'));
const Detail = lazy(() => import(/* webpackChunkName: "AdminDetail" */ '@/pages/Admin/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "AdminEdit" */ '@/pages/Admin/Edit'));

export const adminRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    component: List,
    path: PATH.Admin,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    component: New,
    path: `${PATH.Admin}/new`,
    exact: true,
  },
  {
    permission: ActionType.READ,
    component: Detail,
    path: `${PATH.Admin}/:id`,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    component: Edit,
    path: `${PATH.Admin}/:id/edit`,
    exact: true,
  },
];

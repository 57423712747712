import { createSlice } from '@reduxjs/toolkit';

import test, { initialTestState } from '@/svc/routing-svc/test/reducer';
import { TestState } from '@/svc/routing-svc/test/type';
import Service from '@/svc/serviceName';

export interface RoutingSvcState {
  test: TestState;
}

const initialState: RoutingSvcState = {
  test: initialTestState,
};

const routingSlice = createSlice({
  name: Service.routingSvc,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.test, test(state.test, action));
    }),
});

export default routingSlice.reducer;

import { useMemo } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

import regex from '@/assets/regex';
// import { useLocation } from 'react-router-dom';

type T = 'number' | 'boolean' | 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm:ss';

export interface SearchConfig {
  types?: Record<string, T>;
}

const booleanFields: Record<string, boolean> = {
  true: true,
  false: false,
};

const parsingTable = {
  number: (value: string) => (/^-?(0|(?:(?:\d+|\d+\.\d+)))$/.test(value) ? parseFloat(value) : null),
  boolean: (value: string) => booleanFields[value] ?? null,
  'YYYY-MM-DD': (value: string) => (regex.date.test(value) ? value : null),
  'YYYY-MM-DD HH:mm:ss': (value: string) => (regex.dateTime.test(value) ? value : null),
};

let parseNext: boolean = false;
let key: string | null = null;

export function useSearch(config: SearchConfig = {}) {
  const { types } = config;

  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const search: any = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder(value, _, __, type) {
          if (parseNext && key && types) {
            parseNext = false;

            const v = parsingTable[types[key]](decodeURIComponent(value));

            key = null;

            return v;
          }

          if (type === 'key' && types && value in types) {
            parseNext = true;
            key = value;
          }

          return decodeURIComponent(value);
        },
      }),
    [location.search]
  );

  return search;
}

import { InputHTMLAttributes, useState } from 'react';

import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { InputType, Input, FormFeedback } from '@/components/template';
import { LoginErrors } from '@/types';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  errors: LoginErrors;
  name: string;
  type?: InputType;
  touched?: boolean;
}

function ValidatedInput(props: Props) {
  const { errors, name, type = 'text', onChange, onFocus, ...others } = props;

  const { t } = useTranslation();

  const [touched, setTouch] = useState(false);

  const touch = props.touched || touched;
  const error = get(errors, name);

  return (
    <div>
      <Input
        autoComplete='off'
        {...others}
        className={`form-control ${touch && error ? 'is-invalid' : ''}`}
        type={type}
        name={name}
        onChange={onChange}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }

          setTouch(true);

          onChange?.(e);
        }}
      />
      {touch && error && <FormFeedback className='invalid-feedback'>{error.includes(' ') ? error : t(error)}</FormFeedback>}
    </div>
  );
}

export default ValidatedInput;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchRegionListRequest,
  fetchRegionListSuccess,
  fetchRegionListFailure,
  fetchRegionDetailRequest,
  fetchRegionDetailSuccess,
  fetchRegionDetailFailure,
  createRegionRequest,
  createRegionSuccess,
  createRegionFailure,
  updateRegionRequest,
  updateRegionSuccess,
  updateRegionFailure,
  fetchRegionListWithoutPageRequest,
  fetchRegionListWithoutPageSuccess,
} from '@/pages/Regions/reducer';
import { getContentSchema } from '@/utils/api';
import { authenticatedRequest } from '@/utils/request';

const basePath = '/regions';

const { schema, entity } = getContentSchema();

function* fetchRegionList({ payload }: ReturnType<typeof fetchRegionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(basePath, { params: payload, schema });

    const action = response.ok ? fetchRegionListSuccess(response.data) : fetchRegionListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchRegionListFailure((e as Error).message));
  }
}

function* fetchRegionDetail({ payload }: ReturnType<typeof fetchRegionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`${basePath}/${payload}`, { schema: entity });

    const action = response.ok ? fetchRegionDetailSuccess(response.data) : fetchRegionDetailFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(fetchRegionDetailFailure((e as Error).message));
  }
}

function* createRegion({ payload }: ReturnType<typeof createRegionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(basePath, { data: payload });

    const action = response.ok ? createRegionSuccess(response.data) : createRegionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(createRegionFailure((e as Error).message));
  }
}

function* updateRegion({ payload: { id, body } }: ReturnType<typeof updateRegionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`${basePath}/${id}`, { data: body });

    const action = response.ok ? updateRegionSuccess(response.data) : updateRegionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateRegionFailure((e as Error).message));
  }
}

function* fetchRegionListWithoutPage(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(basePath);

    if (response.ok) {
      yield put(fetchRegionListWithoutPageSuccess(response.data));
    }
  } catch {}
}

export default function* defaultSaga() {
  yield takeLatest(fetchRegionListRequest.type, fetchRegionList);
  yield takeLatest(fetchRegionDetailRequest.type, fetchRegionDetail);
  yield takeLatest(createRegionRequest.type, createRegion);
  yield takeLatest(updateRegionRequest.type, updateRegion);
  yield takeLatest(fetchRegionListWithoutPageRequest.type, fetchRegionListWithoutPage);
}

import { FormFeedback as FormFeedbackComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface FormFeedbackProps extends React.HTMLAttributes<HTMLElement> {
  tag?: string;
  cssModule?: CSSModule;
  valid?: boolean;
  tooltip?: boolean;
}

export function FormFeedback(props: FormFeedbackProps) {
  return <FormFeedbackComponent {...props} />;
}

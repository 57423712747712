import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryTagListRequest,
  fetchDeliveryTagListSuccess,
  fetchDeliveryTagListFailure,
  fetchDeliveryTagDetailRequest,
  fetchDeliveryTagDetailSuccess,
  fetchDeliveryTagDetailFailure,
  createDeliveryTagRequest,
  createDeliveryTagSuccess,
  createDeliveryTagFailure,
  updateDeliveryTagRequest,
  updateDeliveryTagSuccess,
  updateDeliveryTagFailure,
  deleteDeliveryTagRequest,
  deleteDeliveryTagSuccess,
  deleteDeliveryTagFailure,
} from '@/svc/delivery-svc/tag/reducer';
import { authenticatedRequest } from '@/utils/request';

function* fetchDeliveryTagList({ payload }: ReturnType<typeof fetchDeliveryTagListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('delivery/tags', { params: payload });

    if (response.ok) {
      yield put(fetchDeliveryTagListSuccess(response.data));
    } else {
      yield put(fetchDeliveryTagListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryTagListFailure((e as Error).message));
  }
}

function* fetchDeliveryTagDetail({ payload }: ReturnType<typeof fetchDeliveryTagDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`delivery/tags/${payload}`);

    if (response.ok) {
      yield put(fetchDeliveryTagDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryTagDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryTagDetailFailure((e as Error).message));
  }
}

function* createDeliveryTag({ payload }: ReturnType<typeof createDeliveryTagRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('delivery/tags', { data: payload });

    if (response.ok) {
      yield put(createDeliveryTagSuccess(response.data));
    } else {
      yield put(createDeliveryTagFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryTagFailure((e as Error).message));
  }
}

function* updateDeliveryTag({ payload: { id, body } }: ReturnType<typeof updateDeliveryTagRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`delivery/tags/${id}`, { data: body });

    if (response.ok) {
      yield put(updateDeliveryTagSuccess(response.data));
    } else {
      yield put(updateDeliveryTagFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryTagFailure((e as Error).message));
  }
}

function* deleteDeliveryTag({ payload }: ReturnType<typeof deleteDeliveryTagRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`delivery/tags/${payload}`);

    if (response.ok) {
      yield put(deleteDeliveryTagSuccess());
    } else {
      yield put(deleteDeliveryTagFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryTagFailure((e as Error).message));
  }
}

export default function* deliveryTagSaga() {
  yield takeLatest(fetchDeliveryTagListRequest.type, fetchDeliveryTagList);
  yield takeLatest(fetchDeliveryTagDetailRequest.type, fetchDeliveryTagDetail);
  yield takeLatest(createDeliveryTagRequest.type, createDeliveryTag);
  yield takeLatest(updateDeliveryTagRequest.type, updateDeliveryTag);
  yield takeLatest(deleteDeliveryTagRequest.type, deleteDeliveryTag);
}

import { useLocation } from 'react-router';

import BreadcrumbsItem from '@/components/Breadcrumb/BreadcrumbsItem';
import { getPaths } from '@/components/Breadcrumb/util';
import { Breadcrumb } from '@/components/template';

export default function Breadcrumbs({ initialPath }: { initialPath: string }) {
  const { pathname } = useLocation();
  const paths = getPaths(pathname, initialPath);

  return (
    <div>
      <Breadcrumb>
        {paths.map((path, idx) => (
          <BreadcrumbsItem key={`${path}_${idx}`} path={path} />
        ))}
      </Breadcrumb>
    </div>
  );
}

import { createAction } from '@reduxjs/toolkit';

import {
  ExcludeRiderDto,
  RiderMissionAdminDto,
  RiderMissionSyncAllDto,
  RiderMissionSyncDto,
  RiderMissionSyncResultDto,
} from '@/pages/RiderMission/type';
import { BulkReSyncParams } from '@/svc/incentive-svc/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.incentiveSvc}/riderMission`;

export const createRiderMissionRequest = createAction<FormData>(`${PREFIX}/createRiderMissionRequest`);
export const createRiderMissionSuccess = createAction<RiderMissionAdminDto>(`${PREFIX}/createRiderMissionSuccess`);
export const createRiderMissionFailure = createAction<string>(`${PREFIX}/createRiderMissionFailure`);

export const updateRiderMissionRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/updateRiderMissionRequest`);
export const updateRiderMissionSuccess = createAction<RiderMissionAdminDto>(`${PREFIX}/updateRiderMissionSuccess`);
export const updateRiderMissionFailure = createAction<string>(`${PREFIX}/updateRiderMissionFailure`);

export const fetchRiderMissionParticipantsTemplateRequest = createAction(`${PREFIX}/fetchRiderMissionParticipantsTemplateRequest`);
export const fetchRiderMissionParticipantsTemplateSuccess = createAction(`${PREFIX}/fetchRiderMissionParticipantsTemplateSuccess`);
export const fetchRiderMissionParticipantsTemplateFailure = createAction<string>(`${PREFIX}/fetchRiderMissionParticipantsTemplateFailure`);

export const fetchRiderMissionVoucherTemplateRequest = createAction(`${PREFIX}/fetchRiderMissionVoucherTemplateRequest`);
export const fetchRiderMissionVoucherTemplateSuccess = createAction(`${PREFIX}/fetchRiderMissionVoucherTemplateSuccess`);
export const fetchRiderMissionVoucherTemplateFailure = createAction<string>(`${PREFIX}/fetchRiderMissionVoucherTemplateFailure`);

export const deleteRiderMissionRequest = createAction<string>(`${PREFIX}/deleteRiderMissionRequest`);
export const deleteRiderMissionSuccess = createAction(`${PREFIX}/deleteRiderMissionSuccess`);
export const deleteRiderMissionFailure = createAction<string>(`${PREFIX}/deleteRiderMissionFailure`);

export const fetchRiderMissionExcludeTemplateRequest = createAction(`${PREFIX}/fetchRiderMissionExcludeTemplateRequest`);
export const fetchRiderMissionExcludeTemplateSuccess = createAction(`${PREFIX}/fetchRiderMissionExcludeTemplateSuccess`);
export const fetchRiderMissionExcludeTemplateFailure = createAction<string>(`${PREFIX}/fetchRiderMissionExcludeTemplateFailure`);

export const syncRiderMissionRequest = createAction<RiderMissionSyncDto>(`${PREFIX}/syncRiderMissionRequest`);
export const syncRiderMissionSuccess = createAction<RiderMissionSyncResultDto>(`${PREFIX}/syncRiderMissionSuccess`);
export const syncRiderMissionFailure = createAction<string>(`${PREFIX}/syncRiderMissionFailure`);

export const syncAllRiderMissionRequest = createAction<RiderMissionSyncAllDto>(`${PREFIX}/syncAllRiderMissionRequest`);
export const syncAllRiderMissionSuccess = createAction(`${PREFIX}/syncAllRiderMissionSuccess`);
export const syncAllRiderMissionFailure = createAction<string>(`${PREFIX}/syncAllRiderMissionFailure`);

export const syncBulkRiderMissionRequest = createAction<{ id: string; data: FormData; params: BulkReSyncParams }>(
  `${PREFIX}/syncBulkRiderMissionRequest`
);
export const syncBulkRiderMissionSuccess = createAction(`${PREFIX}/syncBulkRiderMissionSuccess`);
export const syncBulkRiderMissionFailure = createAction<string>(`${PREFIX}/syncBulkRiderMissionFailure`);

export const fetchRiderMissionSyncTemplateRequest = createAction(`${PREFIX}/fetchRiderMissionSyncTemplateRequest`);
export const fetchRiderMissionSyncTemplateSuccess = createAction(`${PREFIX}/fetchRiderMissionSyncTemplateSuccess`);
export const fetchRiderMissionSyncTemplateFailure = createAction<string>(`${PREFIX}/fetchRiderMissionSyncTemplateFailure`);

export const excludeRiderMissionRequest = createAction<{ id: string; data: ExcludeRiderDto }>(`${PREFIX}/excludeRiderMissionRequest`);
export const excludeRiderMissionSuccess = createAction(`${PREFIX}/excludeRiderMissionSuccess`);
export const excludeRiderMissionFailure = createAction<string>(`${PREFIX}/excludeRiderMissionFailure`);

export const excludeBulkRiderMissionRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/excludeBulkRiderMissionRequest`);
export const excludeBulkRiderMissionSuccess = createAction(`${PREFIX}/excludeBulkRiderMissionSuccess`);
export const excludeBulkRiderMissionFailure = createAction<string>(`${PREFIX}/excludeBulkRiderMissionFailure`);

export const exportRewardHistoriesRequest = createAction<{ id: string; tierId: string }>(`${PREFIX}/exportRewardHistoriesRequest`);
export const exportRewardHistoriesSuccess = createAction(`${PREFIX}/exportRewardHistoriesSuccess`);
export const exportRewardHistoriesFailure = createAction<string>(`${PREFIX}/exportRewardHistoriesFailure`);

export const updateRiderMissionConditionRequest = createAction<{ id: string; data: FormData }>(`${PREFIX}/updateRiderMissionConditionRequest`);
export const updateRiderMissionConditionSuccess = createAction(`${PREFIX}/updateRiderMissionConditionSuccess`);
export const updateRiderMissionConditionFailure = createAction<string>(`${PREFIX}/updateRiderMissionConditionFailure`);

export const updateTierVoucherRequest = createAction<{ id: string; tierId: string; data: FormData }>(`${PREFIX}/updateTierVoucherRequest`);
export const updateTierVoucherSuccess = createAction(`${PREFIX}/updateTierVoucherSuccess`);
export const updateTierVoucherFailure = createAction<string>(`${PREFIX}/updateTierVoucherFailure`);

export const bulkUploadRiderMissionParticipantsRequest = createAction<{ id: string; data: FormData }>(
  `${PREFIX}/bulkUploadRiderMissionParticipantsRequest`
);
export const bulkUploadRiderMissionParticipantsSuccess = createAction(`${PREFIX}/bulkUploadRiderMissionParticipantsSuccess`);
export const bulkUploadRiderMissionParticipantsFailure = createAction<string>(`${PREFIX}/bulkUploadRiderMissionParticipantsFailure`);

export const exportRiderMissionParticipantsRequest = createAction<string>(`${PREFIX}/exportRiderMissionParticipantsRequest`);
export const exportRiderMissionParticipantsSuccess = createAction(`${PREFIX}/exportRiderMissionParticipantsSuccess`);
export const exportRiderMissionParticipantsFailure = createAction<string>(`${PREFIX}/exportRiderMissionParticipantsFailure`);

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  createBannerRequest,
  createBannerFailure,
  createBannerSuccess,
  deleteBannerFailure,
  deleteBannerRequest,
  deleteBannerSuccess,
  updateBannerFailure,
  updateBannerRequest,
  updateBannerSuccess,
} from '@/svc/inbox-svc/banner/reducer';
import { authenticatedRequest } from '@/utils/request';

function* createBanner({ payload }: ReturnType<typeof createBannerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/banner', { data: payload });

    if (response.ok) {
      yield put(createBannerSuccess());
    } else {
      yield put(createBannerFailure(response.data.message));
    }
  } catch (e) {
    yield put(createBannerFailure((e as Error).message));
  }
}

function* updateBanner({ payload: { id, data } }: ReturnType<typeof updateBannerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/banner/${id}`, { data });

    if (response.ok) {
      yield put(updateBannerSuccess());
    } else {
      yield put(updateBannerFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateBannerFailure((e as Error).message));
  }
}

function* deleteBanner({ payload }: ReturnType<typeof deleteBannerRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/banner/${payload}`);

    if (response.ok) {
      yield put(deleteBannerSuccess());
    } else {
      yield put(deleteBannerFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteBannerFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(createBannerRequest.type, createBanner);
  yield takeLatest(updateBannerRequest.type, updateBanner);
  yield takeLatest(deleteBannerRequest.type, deleteBanner);
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "InstantSurgeRuleList" */ '@/pages/InstantSurgeRule/List'));
const Form = lazy(() => import(/* webpackChunkName: "InstantSurgeRuleForm" */ '@/pages/InstantSurgeRule/Form'));

export const instantSurgeRuleRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.InstantSurgeRule,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.InstantSurgeRule}/form`,
    component: Form,
    exact: true,
  },
];

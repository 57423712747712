import { FormGroup as FormGroupComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  row?: boolean;
  check?: boolean;
  inline?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
  cssModule?: CSSModule;
}

export function FormGroup(props: FormGroupProps) {
  return <FormGroupComponent {...props} />;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FraudDetectionLogQueryInterface } from '@/pages/FraudLog/type';
import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface FraudLogState extends PaginationStoreState, StandardStoreState {}

const initialState: FraudLogState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
};

const fraudLogSlice = createSlice({
  name: 'fraudLog',
  initialState,
  reducers: {
    fetchFraudLogListRequest: {
      prepare: (search: FraudDetectionLogQueryInterface) => ({ payload: { search } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchFraudLogListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchFraudLogListFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    fetchFraudLogDetailRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    fetchFraudLogDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchFraudLogDetailFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export const {
  fetchFraudLogListRequest,
  fetchFraudLogListSuccess,
  fetchFraudLogListFailure,
  fetchFraudLogDetailRequest,
  fetchFraudLogDetailSuccess,
  fetchFraudLogDetailFailure,
} = fraudLogSlice.actions;

export default fraudLogSlice.reducer;

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const Detail = lazy(() => import(/* webpackChunkName: "RecoverDeletedAccountDetail" */ '@/pages/RecoverDeletedAccount/Detail'));

export const recoverDeletedAccountRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: `${PATH.RecoverDeletedAccount}`,
    component: Detail,
    exact: true,
  },
];

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "ZoneList" */ '@/pages/Zones/List'));
const Detail = lazy(() => import(/* webpackChunkName: "ZoneDetail" */ '@/pages/Zones/Detail'));
const New = lazy(() => import(/* webpackChunkName: "ZoneNew" */ '@/pages/Zones/New'));
const Edit = lazy(() => import(/* webpackChunkName: "ZoneEdit" */ '@/pages/Zones/Edit'));
const Import = lazy(() => import(/* webpackChunkName: "ZoneImport" */ '@/pages/Zones/Import'));

export const zonesRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Zones,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Zones}/import`,
    component: Import,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Zones}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Zones}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.Zones}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { createAction } from '@reduxjs/toolkit';

import {
  MerchantFormInterface,
  NotificationTypeToggleDto,
  SenderCustomPlanCreateDto,
  SenderCustomPlanUpdateDto,
  SenderLocationAddDto,
  SenderLocationUpdateDto,
  SenderMessageChannelToggleDto,
  SenderMessageChannelUpdateDto,
  SenderUserFeatureCreateOrUpdateDto,
  SubscribeWebhookForm,
  ToggleWebhookForm,
} from '@/pages/Merchant/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.deliverySvc}/merchant`;

export const createMerchantRequest = createAction<MerchantFormInterface>(`${PREFIX}/createMerchantRequest`);
export const createMerchantSuccess = createAction(`${PREFIX}/createMerchantSuccess`);
export const createMerchantFailure = createAction<string>(`${PREFIX}/createMerchantFailure`);

export const updateMerchantRequest = createAction<{ id: string; data: Partial<MerchantFormInterface> }>(`${PREFIX}/updateMerchantRequest`);
export const updateMerchantSuccess = createAction(`${PREFIX}/updateMerchantSuccess`);
export const updateMerchantFailure = createAction<string>(`${PREFIX}/updateMerchantFailure`);

export const setMerchantDefaultLocationRequest = createAction<{ id: string; locationId: string }>(`${PREFIX}/setMerchantDefaultLocationRequest`);
export const setMerchantDefaultLocationSuccess = createAction(`${PREFIX}/setMerchantDefaultLocationSuccess`);
export const setMerchantDefaultLocationFailure = createAction<string>(`${PREFIX}/setMerchantDefaultLocationFailure`);

export const deleteMerchantLocationRequest = createAction<{ id: string; locationId: string }>(`${PREFIX}/deleteMerchantLocationRequest`);
export const deleteMerchantLocationSuccess = createAction(`${PREFIX}/deleteMerchantLocationSuccess`);
export const deleteMerchantLocationFailure = createAction<string>(`${PREFIX}/deleteMerchantLocationFailure`);

export const updateMerchantLocationRequest = createAction<{ id: string; locationId: string; data: SenderLocationUpdateDto }>(
  `${PREFIX}/updateMerchantLocationRequest`
);
export const updateMerchantLocationSuccess = createAction(`${PREFIX}/updateMerchantLocationSuccess`);
export const updateMerchantLocationFailure = createAction<string>(`${PREFIX}/updateMerchantLocationFailure`);

export const addMerchantLocationRequest = createAction<{ id: string; data: SenderLocationAddDto }>(`${PREFIX}/addMerchantLocationRequest`);
export const addMerchantLocationSuccess = createAction(`${PREFIX}/addMerchantLocationSuccess`);
export const addMerchantLocationFailure = createAction<string>(`${PREFIX}/addMerchantLocationFailure`);

export const addMerchantFeatureRequest = createAction<{ id: string; data: SenderUserFeatureCreateOrUpdateDto }>(
  `${PREFIX}/addMerchantFeatureRequest`
);
export const addMerchantFeatureSuccess = createAction(`${PREFIX}/addMerchantFeatureSuccess`);
export const addMerchantFeatureFailure = createAction<string>(`${PREFIX}/addMerchantFeatureFailure`);

export const toggleMerchantFeatureRequest = createAction<{ id: string; data: { feature: number; toggle: boolean } }>(
  `${PREFIX}/toggleMerchantFeatureRequest`
);
export const toggleMerchantFeatureSuccess = createAction(`${PREFIX}/toggleMerchantFeatureSuccess`);
export const toggleMerchantFeatureFailure = createAction<string>(`${PREFIX}/toggleMerchantFeatureFailure`);

export const deleteMerchantFeatureRequest = createAction<{ id: string; data: SenderUserFeatureCreateOrUpdateDto }>(
  `${PREFIX}/deleteMerchantFeatureRequest`
);
export const deleteMerchantFeatureSuccess = createAction(`${PREFIX}/deleteMerchantFeatureSuccess`);
export const deleteMerchantFeatureFailure = createAction<string>(`${PREFIX}/deleteMerchantFeatureFailure`);

export const addAgentWarehouseRequest = createAction<{ id: string; warehouseId: string }>(`${PREFIX}/addAgentWarehouseRequest`);
export const addAgentWarehouseSuccess = createAction(`${PREFIX}/addAgentWarehouseSuccess`);
export const addAgentWarehouseFailure = createAction<string>(`${PREFIX}/addAgentWarehouseFailure`);

export const removeAgentWarehouseRequest = createAction<{ id: string; warehouseId: string }>(`${PREFIX}/removeAgentWarehouseRequest`);
export const removeAgentWarehouseSuccess = createAction(`${PREFIX}/removeAgentWarehouseSuccess`);
export const removeAgentWarehouseFailure = createAction<string>(`${PREFIX}/removeAgentWarehouseFailure`);

export const subscribeWebhookRequest = createAction<{ id: string; data: SubscribeWebhookForm }>(`${PREFIX}/subscribeWebhookRequest`);
export const subscribeWebhookSuccess = createAction(`${PREFIX}/subscribeWebhookSuccess`);
export const subscribeWebhookFailure = createAction<string>(`${PREFIX}/subscribeWebhookFailure`);

export const toggleWebhookRequest = createAction<{ id: string; data: ToggleWebhookForm }>(`${PREFIX}/toggleWebhookRequest`);
export const toggleWebhookSuccess = createAction(`${PREFIX}/toggleWebhookSuccess`);
export const toggleWebhookFailure = createAction<string>(`${PREFIX}/toggleWebhookFailure`);

export const deleteWebhookRequest = createAction<string>(`${PREFIX}/deleteWebhookRequest`);
export const deleteWebhookSuccess = createAction(`${PREFIX}/deleteWebhookSuccess`);
export const deleteWebhookFailure = createAction<string>(`${PREFIX}/deleteWebhookFailure`);

export const testWebhookRequest = createAction<string>(`${PREFIX}/testWebhookRequest`);
export const testWebhookSuccess = createAction(`${PREFIX}/testWebhookSuccess`);
export const testWebhookFailure = createAction<string>(`${PREFIX}/testWebhookFailure`);

export const toggleMessageChannelRequest = createAction<{ id: string; data: SenderMessageChannelToggleDto }>(`${PREFIX}/toggleMessageChannelRequest`);
export const toggleMessageChannelSuccess = createAction(`${PREFIX}/toggleMessageChannelSuccess`);
export const toggleMessageChannelFailure = createAction<string>(`${PREFIX}/toggleMessageChannelFailure`);

export const deleteMessageChannelRequest = createAction<{ id: string; data: SenderMessageChannelUpdateDto }>(`${PREFIX}/deleteMessageChannelRequest`);
export const deleteMessageChannelSuccess = createAction(`${PREFIX}/deleteMessageChannelSuccess`);
export const deleteMessageChannelFailure = createAction<string>(`${PREFIX}/deleteMessageChannelFailure`);

export const toggleMessageNotificationTypeRequest = createAction<{ id: string; data: NotificationTypeToggleDto }>(
  `${PREFIX}/toggleMessageNotificationTypeRequest`
);
export const toggleMessageNotificationTypeSuccess = createAction(`${PREFIX}/toggleMessageNotificationTypeSuccess`);
export const toggleMessageNotificationTypeFailure = createAction<string>(`${PREFIX}/toggleMessageNotificationTypeFailure`);

export const createCloudPrinterRequest = createAction<string>(`${PREFIX}/createCloudPrinterRequest`);
export const createCloudPrinterSuccess = createAction(`${PREFIX}/createCloudPrinterSuccess`);
export const createCloudPrinterFailure = createAction<string>(`${PREFIX}/createCloudPrinterFailure`);

export const deleteCloudPrinterRequest = createAction<string>(`${PREFIX}/deleteCloudPrinterRequest`);
export const deleteCloudPrinterSuccess = createAction(`${PREFIX}/deleteCloudPrinterSuccess`);
export const deleteCloudPrinterFailure = createAction<string>(`${PREFIX}/deleteCloudPrinterFailure`);

export const postDeliveryExportTriggerRequest = createAction<{
  userFromId: string;
  deliveryPriority?: string;
  timeFinishedOrFailedFrom: string;
  timeFinishedOrFailedTo: string;
}>(`${PREFIX}/postDeliveryExportTriggerRequest`);
export const postDeliveryExportTriggerSuccess = createAction(`${PREFIX}/postDeliveryExportTriggerSuccess`);
export const postDeliveryExportTriggerFailure = createAction<string>(`${PREFIX}/postDeliveryExportTriggerFailure`);

export const addCustomDeliveryPlanRequest = createAction<{ id: string; data: SenderCustomPlanCreateDto }>(`${PREFIX}/addCustomDeliveryPlanRequest`);
export const addCustomDeliveryPlanSuccess = createAction(`${PREFIX}/addCustomDeliveryPlanSuccess`);
export const addCustomDeliveryPlanFailure = createAction<string>(`${PREFIX}/addCustomDeliveryPlanFailure`);

export const deleteCustomDeliveryPlanRequest = createAction<{ id: string; data: SenderCustomPlanUpdateDto }>(
  `${PREFIX}/deleteCustomDeliveryPlanRequest`
);
export const deleteCustomDeliveryPlanSuccess = createAction(`${PREFIX}/deleteCustomDeliveryPlanSuccess`);
export const deleteCustomDeliveryPlanFailure = createAction<string>(`${PREFIX}/deleteCustomDeliveryPlanFailure`);

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  deleteHelpCenterTopicRequest,
  deleteHelpCenterTopicSuccess,
  deleteHelpCenterTopicFailure,
  upsertHelpCenterTopicRequest,
  upsertHelpCenterTopicSuccess,
  upsertHelpCenterTopicFailure,
  bulkUpdateDisplayOrderHelpCenterTopicRequest,
  bulkUpdateDisplayOrderHelpCenterTopicSuccess,
  bulkUpdateDisplayOrderHelpCenterTopicFailure,
  deleteHelpCenterQuestionRequest,
  deleteHelpCenterQuestionSuccess,
  deleteHelpCenterQuestionFailure,
  releaseHelpCenterQuestionRequest,
  releaseHelpCenterQuestionSuccess,
  releaseHelpCenterQuestionFailure,
  rejectHelpCenterQuestionRequest,
  rejectHelpCenterQuestionSuccess,
  rejectHelpCenterQuestionFailure,
  upsertHelpCenterQuestionRequest,
  upsertHelpCenterQuestionSuccess,
  upsertHelpCenterQuestionFailure,
  bulkUpdateDisplayOrderHelpCenterListRequest,
  bulkUpdateDisplayOrderHelpCenterListSuccess,
  bulkUpdateDisplayOrderHelpCenterListFailure,
} from '@/svc/cs-svc/helpCenterManagement/reducer';
import { authenticatedRequest } from '@/utils/request';

function* deleteHelpCenterTopic({ payload }: ReturnType<typeof deleteHelpCenterTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/reportTopic/${payload}`);

    const action = response.ok ? deleteHelpCenterTopicSuccess() : deleteHelpCenterTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteHelpCenterTopicFailure((e as Error).message));
  }
}

function* upsertHelpCenterTopic({ payload }: ReturnType<typeof upsertHelpCenterTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportTopic/upsert`, {
      data: payload,
    });

    const action = response.ok ? upsertHelpCenterTopicSuccess(response.data) : upsertHelpCenterTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertHelpCenterTopicFailure((e as Error).message));
  }
}

function* bulkUpdateDisplayOrderHelpCenterTopic({ payload }: ReturnType<typeof bulkUpdateDisplayOrderHelpCenterTopicRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportTopic/bulkUpdateDisplayOrder`, {
      data: payload,
    });

    const action = response.ok ? bulkUpdateDisplayOrderHelpCenterTopicSuccess() : bulkUpdateDisplayOrderHelpCenterTopicFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateDisplayOrderHelpCenterTopicFailure((e as Error).message));
  }
}

function* deleteHelpCenterQuestion({ payload }: ReturnType<typeof deleteHelpCenterQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/admin/v1/reportQuestion/${payload}`);

    const action = response.ok ? deleteHelpCenterQuestionSuccess() : deleteHelpCenterQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(deleteHelpCenterQuestionFailure((e as Error).message));
  }
}

function* releaseHelpCenterQuestion({ payload }: ReturnType<typeof releaseHelpCenterQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportQuestion/${payload}/release`);

    const action = response.ok ? releaseHelpCenterQuestionSuccess() : releaseHelpCenterQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(releaseHelpCenterQuestionFailure((e as Error).message));
  }
}

function* rejectHelpCenterQuestion({ payload }: ReturnType<typeof rejectHelpCenterQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportQuestion/${payload}/reject`);

    const action = response.ok ? rejectHelpCenterQuestionSuccess() : rejectHelpCenterQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(rejectHelpCenterQuestionFailure((e as Error).message));
  }
}

function* upsertHelpCenterQuestion({ payload }: ReturnType<typeof upsertHelpCenterQuestionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportQuestion/upsert`, {
      data: payload,
    });

    const action = response.ok ? upsertHelpCenterQuestionSuccess(response.data) : upsertHelpCenterQuestionFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(upsertHelpCenterQuestionFailure((e as Error).message));
  }
}

function* bulkUpdateDisplayOrderHelpCenterList({ payload }: ReturnType<typeof bulkUpdateDisplayOrderHelpCenterListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/v1/reportQuestion/bulkUpdateDisplayOrder`, {
      data: payload,
    });

    const action = response.ok ? bulkUpdateDisplayOrderHelpCenterListSuccess() : bulkUpdateDisplayOrderHelpCenterListFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateDisplayOrderHelpCenterListFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(deleteHelpCenterTopicRequest.type, deleteHelpCenterTopic);
  yield takeLatest(upsertHelpCenterTopicRequest.type, upsertHelpCenterTopic);
  yield takeLatest(bulkUpdateDisplayOrderHelpCenterTopicRequest.type, bulkUpdateDisplayOrderHelpCenterTopic);
  yield takeLatest(deleteHelpCenterQuestionRequest.type, deleteHelpCenterQuestion);
  yield takeLatest(releaseHelpCenterQuestionRequest.type, releaseHelpCenterQuestion);
  yield takeLatest(rejectHelpCenterQuestionRequest.type, rejectHelpCenterQuestion);
  yield takeLatest(upsertHelpCenterQuestionRequest.type, upsertHelpCenterQuestion);
  yield takeLatest(bulkUpdateDisplayOrderHelpCenterListRequest.type, bulkUpdateDisplayOrderHelpCenterList);
}

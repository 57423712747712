import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "CouponList" */ '@/pages/Coupon/List'));
const Detail = lazy(() => import(/* webpackChunkName: "CouponDetail" */ '@/pages/Coupon/Detail'));
const New = lazy(() => import(/* webpackChunkName: "CouponNew" */ '@/pages/Coupon/New'));
const BulkCancel = lazy(() => import(/* webpackChunkName: "CouponBulkCancel" */ '@/pages/Coupon/BulkCancel'));

export const couponRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.Coupon,
    exact: true,
    component: List,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Coupon}/new`,
    exact: true,
    component: New,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.Coupon}/bulk-cancel`,
    exact: true,
    component: BulkCancel,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.Coupon}/:id`,
    exact: true,
    component: Detail,
  },
];

import { setAxiosConfig, httpRequest, generatorRequestModule, TadaResponse } from '@tada/tada-web-common';

import { API_HOST } from '@/assets/constants';
import { logout } from '@/pages/LoginPage/reducer';
import { dispatch } from '@/store';
import { getAccessToken, getRefreshToken, saveTokens } from '@/utils/localStorage';

setAxiosConfig({
  baseURL: API_HOST,
  headers: {
    Authorization: 'Basic dGFkYS1hZG1pbjp0YWRhLWFkbWluLXNlY3JldA==',
  },
});

export const getAuthorization = () => `Bearer ${getAccessToken()}`;

export const tokenExpiredCallback = async () => {
  const refreshToken = getRefreshToken();

  const refrehsResponse: TadaResponse = await httpRequest({
    url: '/oauth/token',
    method: 'POST',
    data: JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken }),
  });

  // refresh에 성공했을때
  if (refrehsResponse.ok) {
    saveTokens(refrehsResponse.data);
  } else {
    dispatch(logout());
  }

  return refrehsResponse;
};

export const { request, authenticatedRequest } = generatorRequestModule(getAuthorization, tokenExpiredCallback);

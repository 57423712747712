import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const RouteTest = lazy(() => import(/* webpackChunkName: "RouteTest" */ '@/pages/Test/Route/index'));

export const routeTestRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.RouteTest,
    component: RouteTest,
    exact: true,
  },
];

import { CardBody as CardBodyComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface CardBodyProps extends React.HTMLAttributes<HTMLElement> {
  tag?: React.ElementType;
  cssModule?: CSSModule;
  innerRef?: React.Ref<HTMLElement>;
}

export function CardBody(props: CardBodyProps) {
  return <CardBodyComponent {...props} />;
}

import nav, { NavItems } from '@/layout/Sidebar/_nav';

export const mergePagesWithDefaultValue = (pages: NavItems) =>
  nav.items.reduce((data, item, index) => {
    const clone = [...data];
    const pageNames = data.map((p) => p.name);

    if (!pageNames.includes(item.name)) {
      clone[index] = item;
    }

    const pageChildrenNames = data.find((p) => p.name === item.name)?.children?.map((child) => child.name);
    const unIncludePageChildren = item.children?.find((child) => !pageChildrenNames?.includes(child.name));

    if (unIncludePageChildren) {
      clone[index].children = [...(clone[index].children ?? []), unIncludePageChildren];

      return clone;
    }

    return clone;
  }, pages);

import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchRiderListRequest,
  fetchRiderListSuccess,
  fetchRiderListFailure,
  fetchRiderRestrictListRequest,
  fetchRiderRestrictListSuccess,
  fetchRiderRestrictListFailure,
  fetchRiderDetailRequest,
  fetchRiderDetailSuccess,
  fetchRiderDetailFailure,
  updateRiderRequest,
  updateRiderSuccess,
  updateRiderFailure,
  deleteRiderRequest,
  deleteRiderSuccess,
  deleteRiderFailure,
  fetchCorporateByUserIdRequest,
  fetchCorporateByUserIdSuccess,
  fetchCorporateByUserIdFailure,
  deleteLoginIdentifierRequest,
  deleteLoginIdentifierSuccess,
  deleteLoginIdentifierFailure,
  banUserRequest,
  banUserSuccess,
  banUserFailure,
  unbanRequest,
  unbanSuccess,
  unbanFailure,
  fetchRemarkLogRequest,
  fetchRemarkLogSuccess,
  fetchRemarkLogFailure,
  createRemarkLogRequest,
  createRemarkLogSuccess,
  createRemarkLogFailure,
  fetchRiderDisconnectRequest,
  fetchRiderDisconnectSuccess,
  fetchRiderDisconnectFailure,
  fetchRiderConnectRequest,
  fetchRiderConnectSuccess,
  fetchRiderConnectFailure,
  downloadBulkMappingMobileTemplateSuccess,
  downloadBulkMappingMobileTemplateFailure,
  downloadBulkMappingMobileTemplateRequest,
  bulkMappingMobileNumberRequest,
  bulkMappingMobileNumberSuccess,
  bulkMappingMobileNumberFailure,
  deleteLongTokenIdentifierFailure,
  deleteLongTokenIdentifierRequest,
  deleteLongTokenIdentifierSuccess,
} from '@/pages/Rider/reducer';
import { getContentSchema } from '@/utils/api';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

const { schema, entity } = getContentSchema();

function* fetchRiderList({ payload }: ReturnType<typeof fetchRiderListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/riders/list', { params: payload, schema });

    if (response.ok) {
      yield put(fetchRiderListSuccess(response.data));
    } else {
      yield put(fetchRiderListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRiderListFailure((e as Error).message));
  }
}

function* fetchRiderRestrictList({ payload }: ReturnType<typeof fetchRiderRestrictListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/riders', { params: payload.query, schema });

    //@ts-ignore
    if (response.ok && (payload?.page === 0 || !_.isEmpty(response.data))) {
      yield put(fetchRiderRestrictListSuccess(response.data));
    } else {
      const errorMessage = _.isEmpty(response.data) ? 'Empty Data.' : response.data.message;
      yield put(fetchRiderRestrictListFailure(errorMessage));
    }
  } catch (e) {
    yield put(fetchRiderRestrictListFailure((e as Error).message));
  }
}

function* fetchRiderDetail({ payload: { id, query } }: ReturnType<typeof fetchRiderDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/admin/riders/${id}`, { schema: entity, params: query });

    if (response.ok) {
      yield put(fetchRiderDetailSuccess(response.data));
    } else {
      yield put(fetchRiderDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRiderDetailFailure((e as Error).message));
  }
}

function* updateRider({ payload: { id, body } }: ReturnType<typeof updateRiderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/riders/${id}/update`, { data: body });

    if (response.ok) {
      yield put(updateRiderSuccess(response.data));
    } else {
      yield put(updateRiderFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateRiderFailure((e as Error).message));
  }
}

function* deleteRider({ payload: { id, data } }: ReturnType<typeof deleteRiderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/admin/riders/${id}/erase`, { data });

    if (response.ok) {
      yield put(deleteRiderSuccess(response.data));
    } else {
      yield put(deleteRiderFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteRiderFailure((e as Error).message));
  }
}

function* banUser({ payload }: ReturnType<typeof banUserRequest>): IterableIterator {
  const banBody = {
    ban: true,
  };

  const response = yield authenticatedRequest.post(`/admin/riders/${payload}/ban`, { data: banBody });

  if (response.ok) {
    yield put(banUserSuccess(response.data));
  } else {
    yield put(banUserFailure(response.data.message));
  }
}

function* unban({ payload }: ReturnType<typeof unbanRequest>): IterableIterator {
  const banBody = {
    ban: false,
  };

  const response = yield authenticatedRequest.post(`/admin/riders/${payload}/ban`, { data: banBody });

  if (response.ok) {
    yield put(unbanSuccess(response.data));
  } else {
    yield put(unbanFailure(response.data.message));
  }
}

function* createRemarkLog({ payload }: ReturnType<typeof createRemarkLogRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/remark_logs', { data: payload });

    if (response.ok) {
      yield put(createRemarkLogSuccess(response));
    } else {
      yield put(createRemarkLogFailure(response.data.message));
    }
  } catch (e) {
    yield put(createRemarkLogFailure((e as Error).message));
  }
}

function* deleteLoginIdentifier({ payload: { id, identifierId } }: ReturnType<typeof deleteLoginIdentifierRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accounts/${id}/identifiers/${identifierId}`);

    if (response.ok) {
      yield put(deleteLoginIdentifierSuccess(response.data));
    } else {
      yield put(deleteLoginIdentifierFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteLoginIdentifierFailure((e as Error).message));
  }
}

function* deleteLongTokenIdentifier({ payload: { id, imei } }: ReturnType<typeof deleteLongTokenIdentifierRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accounts/${id}/remove-long-refresh-token`, { data: { imei } });

    if (response.ok) {
      yield put(deleteLongTokenIdentifierSuccess());
    } else {
      yield put(deleteLongTokenIdentifierFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteLongTokenIdentifierFailure((e as Error).message));
  }
}

function* fetchCorporateByUserId({ payload }: ReturnType<typeof fetchCorporateByUserIdRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/corporate_members/by_user_id/${payload}`);

    if (response.ok) {
      yield put(fetchCorporateByUserIdSuccess(response.data));
    } else {
      yield put(fetchCorporateByUserIdFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchCorporateByUserIdFailure((e as Error).message));
  }
}

function* fetchRemarkLog({ payload }: ReturnType<typeof fetchRemarkLogRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/remark_logs', { params: payload });

    if (response.ok) {
      yield put(fetchRemarkLogSuccess(response.data));
    } else {
      yield put(fetchRemarkLogFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRemarkLogFailure((e as Error).message));
  }
}

function* fetchRiderConnect({ payload }: ReturnType<typeof fetchRiderConnectRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/corporate_members/connect', { data: payload });

    if (response.ok) {
      yield put(fetchRiderConnectSuccess());
    } else {
      yield put(fetchRiderConnectFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRiderConnectFailure((e as Error).message));
  }
}

function* fetchRiderDisconnect({ payload }: ReturnType<typeof fetchRiderDisconnectRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/corporate_members/disconnect?userId=${payload}`);

    if (response.ok) {
      yield put(fetchRiderDisconnectSuccess());
    } else {
      yield put(fetchRiderDisconnectFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchRiderDisconnectFailure((e as Error).message));
  }
}

function* downloadBulkMappingMobileTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/admin/riders/bulk-mapping-mobile-number-template', {
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response, 'bulk_mapping_mobile_template.xlsx');
      yield put(downloadBulkMappingMobileTemplateSuccess());
    } else {
      yield put(downloadBulkMappingMobileTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadBulkMappingMobileTemplateFailure((e as Error).message));
  }
}

function* bulkMappingMobileNumber({ payload }: ReturnType<typeof bulkMappingMobileNumberRequest>): IterableIterator {
  try {
    const formData = new FormData();
    formData.append('file', payload);

    const response = yield authenticatedRequest.post('/admin/riders/bulk-mapping-mobile-number', {
      data: formData,
      contentType: 'multipart/form-data',
      responseType: 'blob',
    });

    if (response.ok) {
      yield saveFile(response);
      yield put(bulkMappingMobileNumberSuccess());
    } else {
      yield put(bulkMappingMobileNumberFailure(response.data.message));
    }
  } catch (e) {
    yield put(bulkMappingMobileNumberFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(fetchRiderListRequest.type, fetchRiderList);
  yield takeLatest(fetchRiderDetailRequest.type, fetchRiderDetail);
  yield takeLatest(updateRiderRequest.type, updateRider);
  yield takeLatest(deleteRiderRequest.type, deleteRider);
  yield takeLatest(fetchRiderRestrictListRequest.type, fetchRiderRestrictList);
  yield takeLatest(banUserRequest.type, banUser);
  yield takeLatest(createRemarkLogRequest.type, createRemarkLog);
  yield takeLatest(unbanRequest.type, unban);
  yield takeLatest(deleteLoginIdentifierRequest.type, deleteLoginIdentifier);
  yield takeLatest(deleteLongTokenIdentifierRequest.type, deleteLongTokenIdentifier);
  yield takeLatest(fetchCorporateByUserIdRequest.type, fetchCorporateByUserId);
  yield takeLatest(fetchRemarkLogRequest.type, fetchRemarkLog);
  yield takeLatest(fetchRiderConnectRequest.type, fetchRiderConnect);
  yield takeLatest(fetchRiderDisconnectRequest.type, fetchRiderDisconnect);
  yield takeLatest(downloadBulkMappingMobileTemplateRequest.type, downloadBulkMappingMobileTemplate);
  yield takeLatest(bulkMappingMobileNumberRequest.type, bulkMappingMobileNumber);
}

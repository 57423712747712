import { all, fork } from 'redux-saga/effects';

import admin from '@/pages/Admin/saga';
import adminLog from '@/pages/AdminLog/saga';
import airport from '@/pages/Airports/saga';
import alipayTransactions from '@/pages/AlipayTransactions/saga';
import appVersion from '@/pages/AppVersion/saga';
import areas from '@/pages/Areas/saga';
import corporate from '@/pages/Corporate/saga';
import corporateManager from '@/pages/CorporateManager/saga';
import coupon from '@/pages/Coupon/saga';
import dashboard from '@/pages/Dashboard/saga';
import deliveryExportResult from '@/pages/DeliveryExportResults/saga';
import deliveryPaymentOption from '@/pages/DeliveryPaymentOption/saga';
import deliveryPriceOption from '@/pages/DeliveryPriceOption/saga';
import deliveryRide from '@/pages/DeliveryRide/saga';
import deliveryRidePriceOption from '@/pages/DeliveryRidePriceOption/saga';
import deliveryRideProductOption from '@/pages/DeliveryRideProductOption/saga';
import dispatchConfig from '@/pages/DispatchConfig/saga';
import driverApplications from '@/pages/DriverApplications/saga';
import drivers from '@/pages/Drivers/saga';
import dynamicSurge from '@/pages/DynamicSurge/saga';
import dynamicSurgeMonitor from '@/pages/DynamicSurgeMonitor/saga';
import exportSchedule from '@/pages/ExportSchedule/saga';
import fraudCard from '@/pages/FraudCard/saga';
import fraudLog from '@/pages/FraudLog/saga';
import instantSurgePrices from '@/pages/InstantSurgeRule/saga';
import loginsaga from '@/pages/LoginPage/saga';
import momopayTransactions from '@/pages/MomopayTransactions/saga';
import paoTransactions from '@/pages/PaoTransactions/saga';
import paymentTransaction from '@/pages/PaymentTransaction/saga';
import pipayTransactions from '@/pages/PipayTransactions/saga';
import place from '@/pages/Place/saga';
import polygons from '@/pages/Polygons/saga';
import priceVerification from '@/pages/PriceVerification/saga';
import profilePictureChange from '@/pages/ProfilePictureChange/saga';
import promotion from '@/pages/Promotion/saga';
import regions from '@/pages/Regions/saga';
import restrictedAreas from '@/pages/RestrictedAreas/saga';
import rides from '@/pages/Ride/saga';
import rideProducts from '@/pages/RideProducts/saga';
import rider from '@/pages/Rider/saga';
import role from '@/pages/Roles/saga';
import scheduleSurge from '@/pages/ScheduleSurge/saga';
import zones from '@/pages/Zones/saga';
import accountSvc from '@/svc/account-svc/saga';
import adminProxySvc from '@/svc/admin-proxy-svc/saga';
import balanceSvc from '@/svc/balance-svc/saga';
import CorporateManagerActionLogSvc from '@/svc/corporate-svc/CorporateManagerActionLog/saga';
import InvoiceBulkSvc from '@/svc/corporate-svc/InvoiceBulk/saga';
import locationInfoSvc from '@/svc/corporate-svc/locationInfo/saga';
import couponSvc from '@/svc/coupon-svc/saga';
import csSvc from '@/svc/cs-svc/saga';
import deliverySvc from '@/svc/delivery-svc/saga';
import driverTutorialSvc from '@/svc/driver-tutorial-svc/saga';
import inboxSvc from '@/svc/inbox-svc/saga';
import incentiveSvc from '@/svc/incentive-svc/saga';
import carSvc from '@/svc/member-svc/car/saga';
import memberSvc from '@/svc/member-svc/saga';
import mobileSvc from '@/svc/mobile-svc/saga';
import paySvc from '@/svc/pay-svc/saga';
import penaltySvc from '@/svc/penalty-svc/saga';
import placeSvc from '@/svc/place-svc/saga';
import rideSvc from '@/svc/ride-svc/saga';
import routingSvc from '@/svc/routing-svc/saga';

export default function* root() {
  yield all(
    [
      admin,
      adminLog,
      loginsaga,
      airport,
      drivers,
      polygons,
      zones,
      dashboard,
      regions,
      areas,
      driverApplications,
      restrictedAreas,
      priceVerification,
      instantSurgePrices,
      scheduleSurge,
      dispatchConfig,
      profilePictureChange,
      dynamicSurge,
      dynamicSurgeMonitor,
      alipayTransactions,
      paoTransactions,
      rides,
      rideProducts,
      momopayTransactions,
      pipayTransactions,
      deliveryPaymentOption,
      deliveryRidePriceOption,
      rider,
      promotion,
      coupon,
      corporate,
      corporateManager,
      CorporateManagerActionLogSvc,
      role,
      paymentTransaction,
      appVersion,
      exportSchedule,
      deliveryExportResult,
      deliveryRide,
      fraudCard,
      fraudLog,
      deliveryRideProductOption,
      place,
      deliveryPriceOption,
      paySvc,
      deliverySvc,
      rideSvc,
      carSvc,
      accountSvc,
      locationInfoSvc,
      InvoiceBulkSvc,
      memberSvc,
      mobileSvc,
      penaltySvc,
      balanceSvc,
      couponSvc,
      inboxSvc,
      csSvc,
      incentiveSvc,
      placeSvc,
      driverTutorialSvc,
      routingSvc,
      adminProxySvc,
    ].map((saga) => fork(saga))
  );
}

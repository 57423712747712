import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { detailSuccess, listSuccess } from '@/utils/reducerUtils';
import { PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface ZoneState extends PaginationStoreState, StandardStoreState {
  isFetchingJSON: boolean;
  search: {
    isFetching: boolean;
    byId: Record<string, any>;
    ids: string[];
  };
}

const initialState: ZoneState = {
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,

  isFetching: false,
  isFetchingJSON: false,
  error: null,
  search: { isFetching: false, byId: {}, ids: [] },
};

const failure = (state: ZoneState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

const PREFIX = 'zone';

export const updateZoneRequest = createAction(`${PREFIX}/updateZoneRequest`, (id, body) => ({ payload: { id, body } }));
export const updateZoneSuccess = createAction<any>(`${PREFIX}/updateZoneSuccess`);
export const updateZoneFailure = createAction<string>(`${PREFIX}/updateZoneFailure`);

export const syncZoneRequest = createAction(`${PREFIX}/syncZoneRequest`);
export const syncZoneSuccess = createAction(`${PREFIX}/syncZoneSuccess`);
export const syncZoneFailure = createAction<string>(`${PREFIX}/syncZoneFailure`);

const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    fetchZoneListRequest: {
      prepare: (param: any) => ({ payload: param }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchZoneListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchZoneListFailure: failure,

    fetchZoneDetailRequest: {
      prepare: (id) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchZoneDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchZoneDetailFailure: failure,

    createZoneRequest: {
      prepare: (body: any) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    createZoneSuccess: {
      prepare: (zone) => ({ payload: zone }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    createZoneFailure: failure,

    searchZoneRequest: {
      prepare: (query: string, atomic?: boolean) => ({ payload: { query, atomic } }),
      reducer: (state) => {
        state.search.isFetching = true;
      },
    },
    searchZoneSuccess(state, { payload }) {
      state.search.isFetching = false;
      state.search.ids = payload.result.content;
      state.search.byId = payload.entities.content;
    },
    searchZoneFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state) => {
        state.search.isFetching = false;
      },
    },

    fetchZoneGeoJsonRequest: {
      prepare: (zoneId: string) => ({ payload: zoneId }),
      reducer: (state) => {
        state.isFetchingJSON = true;
      },
    },
    fetchZoneGeoJsonSuccess(state) {
      state.isFetchingJSON = false;
    },
    fetchZoneGeoJsonFailure(state, { payload }: PayloadAction<string>) {
      state.isFetchingJSON = false;
      state.error = payload;
    },
  },
});

export const {
  fetchZoneListRequest,
  fetchZoneListSuccess,
  fetchZoneListFailure,
  fetchZoneDetailRequest,
  fetchZoneDetailSuccess,
  fetchZoneDetailFailure,
  createZoneRequest,
  createZoneSuccess,
  createZoneFailure,
  searchZoneRequest,
  searchZoneSuccess,
  searchZoneFailure,
  fetchZoneGeoJsonRequest,
  fetchZoneGeoJsonSuccess,
  fetchZoneGeoJsonFailure,
} = zoneSlice.actions;

export default zoneSlice.reducer;

import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
  CSSProperties,
  ReactNode,
  ImgHTMLAttributes,
  HTMLAttributes,
} from 'react';

import copy from 'copy-to-clipboard';
import { ErrorMessage as ErrorMessageFormik } from 'formik';
import { TFunction } from 'i18next';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { CAR_TYPE_STRINGS, DEFAULT_VALUE, Pages } from '@/assets/constants';
import { PATH } from '@/assets/constants/path';
import regex from '@/assets/regex';
import CheckPermission, { ActionType } from '@/components/CheckPermission';
import { MediaImg, MediaVideo } from '@/components/FilePreview';
import Null from '@/components/Null';
import { Text } from '@/components/ValueField';
import { InputType, Button, Row, Collapse, Card, CardBody, Col, Input, FormGroup, Label } from '@/components/template';
import { Price } from '@/pages/DeliveryRide/type';
import { RideProduct } from '@/pages/RideProducts/type';
import { useAppState, useIdSelector } from '@/store/selector';

export function booleanIcon(flag?: boolean | null) {
  return flag ? <i className={'fa fa-check'} style={{ color: 'green' }} /> : <i className={'fa fa-times'} style={{ color: 'red' }} />;
}

export function ListCollapse({
  title,
  children,
  onClickCallbackFn,
  openFlag,
}: {
  title: JSX.Element;
  children: JSX.Element;
  onClickCallbackFn: () => void;
  openFlag: boolean;
}) {
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (openFlag) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
  }, [openFlag]);

  return (
    <>
      <Row>
        <Button
          onClick={() => {
            onClickCallbackFn();
            setShowDetail(!showDetail);
          }}
          style={{ width: '100%', height: '100%' }}
        >
          {title}
        </Button>
        <Collapse isOpen={showDetail} style={{ width: '100%', marginTop: '7px' }}>
          <Card>
            <CardBody>{children}</CardBody>
          </Card>
        </Collapse>
      </Row>
      <br />
    </>
  );
}

export function CopyButton({
  data,
  successMessage,
  iconStyle,
  ...props
}: { data: string; successMessage: string; iconStyle?: any } & HTMLAttributes<HTMLElement>) {
  return (
    <Button
      color={'link'}
      size={'sm'}
      className='tw-contents'
      onClick={(e) => {
        e.stopPropagation();

        copy(data);

        successMessage && showSuccessToast(successMessage);
      }}
    >
      <i {...props} className={'fa fa-copy '.concat(props.className || '')} style={iconStyle} />
    </Button>
  );
}

export function RenderRiderAdminLink({ url, children, style }: { url: string; children: any; style?: any }) {
  switch (window.location.host) {
    case 'admin.driver.tada.global':
      return (
        <a href={`https://admin.tada.global${url}`} target={'_blank'} style={style} rel='noreferrer'>
          {children}
        </a>
      );
    case 'admin.driver.tada-staging.com':
      return (
        <a href={`https://admin.tada-staging.com${url}`} target={'_blank'} style={style} rel='noreferrer'>
          {children}
        </a>
      );
    case 'localhost:3100':
      return (
        <a href={`http://localhost:3000${url}`} target={'_blank'} style={style} rel='noreferrer'>
          {children}
        </a>
      );
    default:
      return <div />;
  }
}

export function showSuccessToast(toastContent: ReactNode = 'Success!', toastOptions?: ToastOptions) {
  toast.success(toastContent, { position: 'top-center', ...toastOptions });
}

export function showErrorToast(toastContent: ReactNode = 'Error Occurred', toastOptions?: ToastOptions) {
  toast.error(toastContent, { position: 'top-center', ...toastOptions });
}

export function renderFormErrorMessage(errorMessage?: string) {
  if (errorMessage === undefined) {
    return null;
  }

  return <div className='text-danger'>{errorMessage}</div>;
}

export function FormInput({
  label,
  innerRef,
  name,
  errors,
  onChange,
  type = 'text',
  placeholder = 'NULL',
  step,
  min,
  readOnly,
  value,
  disabled,
  defaultValue,
  ...props
}: {
  label: string;
  innerRef?: React.Ref<HTMLInputElement>;
  name: string;
  errors?: Record<string, string>;
  onChange?: ChangeEventHandler<any>;
  onBlur?: FocusEventHandler<any>;
  type?: InputType;
  placeholder?: string;
  step?: number | string;
  min?: number | string;
  readOnly?: boolean;
  value?: number | string;
  disabled?: boolean;
  defaultValue?: string | number;
  autoComplete?: string;
}) {
  return (
    <FormGroup row={true}>
      <Col md={3}>{label}</Col>
      <Col md={9}>
        <Input
          readOnly={readOnly}
          value={value}
          placeholder={placeholder}
          step={step}
          type={type}
          name={name}
          innerRef={innerRef}
          onChange={onChange}
          min={min}
          disabled={disabled}
          defaultValue={defaultValue}
          {...props}
        />
        {errors && renderFormErrorMessage(errors[name])}
      </Col>
    </FormGroup>
  );
}

export function CheckLabel({
  label,
  name,
  checked,
  onChange,
  innerRef,
  style = { marginLeft: '10px' },
}: {
  label: string;
  name: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<any>;
  innerRef?: React.Ref<HTMLInputElement>;
  style?: any;
}) {
  return (
    <Label check={true}>
      {label}
      <Input checked={checked} onChange={onChange} bsSize={'lg'} style={style} type={'checkbox'} name={name} innerRef={innerRef} />
    </Label>
  );
}

export function FormSelectInput({
  label,
  name,
  innerRef,
  errors,
  value,
  defaultValue = DEFAULT_VALUE.STRING,
  showDefaultValue = true,
  optionData,
  optionDataString,
  onChange,
  t,
}: {
  value?: any;
  label: string;
  name: string;
  innerRef: React.Ref<HTMLInputElement>;
  errors?: Record<string, string>;
  defaultValue: string | number;
  showDefaultValue?: boolean;
  optionData: Array<string | number>;
  optionDataString?: Record<string | number, string>;
  onChange?: ChangeEventHandler<any>;
  t?: TFunction;
}) {
  return (
    <FormGroup row={true}>
      <Col md={3}>{label}</Col>
      <Col md={9}>
        <Input type={'select'} name={name} innerRef={innerRef} onChange={onChange} value={value}>
          {showDefaultValue && <option value={defaultValue}>Select {label}</option>}
          {optionData.map((value, idx) => (
            <option value={value} key={idx}>
              {optionDataString ? (t ? t(optionDataString[value]) : optionDataString[value]) : value}
            </option>
          ))}
        </Input>
        {renderFormErrorMessage(errors && errors[name])}
      </Col>
    </FormGroup>
  );
}

export function CloseButton({ onClick }: { onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined }) {
  return (
    <button type='button' className='close entity-close' aria-label='Close' onClick={onClick}>
      <span aria-hidden='true'>&times;</span>
    </button>
  );
}

interface FlexWrapProps {
  customStyle?: CSSProperties | string;
}

export const FlexWrap = styled.div<FlexWrapProps>`
  display: flex;
  ${({ customStyle }: any) => customStyle}
`;

const ErrorWrapper = styled.div`
  color: #f00;
`;

export function Error(props: any) {
  return <ErrorWrapper>{props.children}</ErrorWrapper>;
}

export const validateImage = (url: string) =>
  new Promise((res) => {
    const img = new Image();
    img.onerror = () => {
      res(false);
    };
    img.onload = () => {
      res(true);
    };
    img.src = url;
  });

export const validateVideo = (url: string) =>
  new Promise((res) => {
    const video = document.createElement('video');
    video.onerror = () => {
      res(false);
    };
    video.oncanplay = () => {
      res(true);
    };
    video.src = url;
  });

interface MediaFromUrlInterface {
  url: string;
  mediaType?: string;
}

export function MediaFromUrl({ url, mediaType }: MediaFromUrlInterface) {
  const [type, setType] = useState('');

  useEffect(() => {
    const checkType = async (url: string) => {
      if (url.trim() === DEFAULT_VALUE.STRING) {
        return setType('');
      }

      if (url.includes('https://www.youtube.com')) {
        return setType('youtube');
      }

      const isImage = await validateImage(url);
      if (isImage) {
        return setType('image');
      }

      const isVideo = await validateVideo(url);
      if (isVideo) {
        return setType('video');
      }

      setType('');
    };

    checkType(url);
  }, [url]);

  if (type === 'image' && (!mediaType || mediaType === 'IMAGE')) {
    return <MediaImg src={url} />;
  }

  if (type === 'video' && (!mediaType || mediaType === 'VIDEO')) {
    return <MediaVideo src={url} controls></MediaVideo>;
  }

  if (type === 'youtube' && (!mediaType || mediaType === 'VIDEO') && ReactPlayer.canPlay(url)) {
    return <ReactPlayer url={url} controls />;
  }

  return <div></div>;
}

export function RideProductInfo({ rideProductId, isLink = false }: { rideProductId?: string | number; isLink?: boolean }) {
  const rideProduct = useIdSelector<RideProduct>('rideProducts', `${rideProductId}`);

  if (rideProduct === undefined) {
    return <Null />;
  }

  const rideProductInfoText = `${rideProduct.id} / ${CAR_TYPE_STRINGS[rideProduct.car_type]} / ${rideProduct.region}`;

  if (isLink) {
    return <Link to={`${PATH.RideProducts}/${rideProductId}`}>{rideProductInfoText}</Link>;
  }

  return <Text>{rideProductInfoText}</Text>;
}

export const NotAvailable = styled.span<{ color?: string }>`
  margin: 0;
  padding: 0;

  :before {
    content: 'N/A';
    display: block;
    font-size: 12px;
    font-weight: 300;
    color: ${({ color }) => color ?? '#000'};
  }
`;

const ExpandOrCollapseButtonContainer = styled(Button)`
  display: contents;
  font-size: 1.3rem;

  > i {
    padding: 12px;
  }
`;

export function ExpandOrCollapseButton({ collapsed, onClick }: { collapsed: boolean; onClick?: () => void }) {
  return (
    <ExpandOrCollapseButtonContainer onClick={onClick}>
      <i className={`fa fa-angle-${collapsed ? 'down' : 'up'}`} />
    </ExpandOrCollapseButtonContainer>
  );
}

interface QRImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  qrCode?: string | null;
}

export function QRImg({ qrCode, ...imgProps }: QRImgProps) {
  const style = imgProps?.style ?? { width: '300px', height: '300px' };

  if (!qrCode) {
    return <Null />;
  }

  if (/https:/.test(qrCode)) {
    return <img alt='loading' src={qrCode} {...imgProps} style={style} />;
  }

  return <img alt='loading' src={`data:image/png;base64, ${qrCode}`} {...imgProps} style={style} />;
}

export const RentalNumber = ({ id }: { id?: string }) => {
  const permissions = useAppState((state) => state.auth.permissions);

  if (!id) {
    return <Null />;
  }

  const isRide = id.includes('-') && regex.uuidOnly.test(id);

  if (!isRide) {
    return <span>{id}</span>;
  }

  const ridePermission = permissions[Pages.Ride];
  const to = `${PATH.Ride}/${id}`;

  return (
    <CheckPermission permission={ridePermission} action={ActionType.READ} fallbackElement={id}>
      <Link to={to}>{id}</Link>
    </CheckPermission>
  );
};

export const renderPrice = (price: Price | null) => (price ? `${price.value} ${price.currency}` : <Null />);

export function ErrorMessage({ message }: { message?: string | null }) {
  if (!message) {
    return null;
  }

  return <div className={'text-danger'}>{message}</div>;
}

export function FormikErrorMessage({ name }: { name: string }) {
  return (
    <ErrorMessageFormik
      name={name}
      component={(props) => (
        <div className='text-danger' {...props}>
          {/* @ts-ignore */}
          {props.children}
        </div>
      )}
    />
  );
}

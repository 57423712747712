import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdyenTransactionDto, CreateAdyenTransactionAdminDto } from '@/pages/AdyenTransaction/type';
import {
  PaySvcTransactionState,
  EntityTransactionHistoriesQuery,
  EntityTransactionHistoryAdminDto,
  RefundTopupTransactionDto,
} from '@/svc/pay-svc/transaction/type';
import Service from '@/svc/serviceName';

export const initialState: PaySvcTransactionState = {
  entityTransactionHistories: null,
  error: null,
  isFetching: false,
  adyenTransactions: [],
};

const PREFIX = `${Service.paySvc}/transaction`;

export const createAdyenTransactionRequest = createAction<CreateAdyenTransactionAdminDto>(`${PREFIX}/createAdyenTransactionRequest`);
export const createAdyenTransactionSuccess = createAction(`${PREFIX}/createAdyenTransactionSuccess`);
export const createAdyenTransactionFailure = createAction<string>(`${PREFIX}/createAdyenTransactionFailure`);

export const topupRefundTransactionRequest = createAction<{ id: string; data: RefundTopupTransactionDto }>(`${PREFIX}/topupRefundTransactionRequest`);
export const topupRefundTransactionSuccess = createAction(`${PREFIX}/topupRefundTransactionSuccess`);
export const topupRefundTransactionFailure = createAction<string>(`${PREFIX}/topupRefundTransactionFailure`);

const paySvcTransactionSlice = createSlice({
  name: `${Service.paySvc}/transaction`,
  initialState,
  reducers: {
    fetchEntityTransactionHistoriesRequest: {
      prepare: (search: EntityTransactionHistoriesQuery) => ({ payload: search }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchEntityTransactionHistoriesSuccess: {
      prepare: (entityId: string, data: EntityTransactionHistoryAdminDto[]) => ({
        payload: {
          entityId,
          data,
        },
      }),
      reducer: (state, { payload }: PayloadAction<{ entityId: string; data: EntityTransactionHistoryAdminDto[] }>) => {
        state.isFetching = false;
        state.entityTransactionHistories = {
          ...state.entityTransactionHistories,
          [payload.entityId]: payload.data,
        };
      },
    },
    fetchEntityTransactionHistoriesFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = true;
        state.error = payload;
      },
    },
    setAdyenTransactions(state, { payload }: PayloadAction<AdyenTransactionDto[]>) {
      state.adyenTransactions = payload;
    },
  },
});

export const {
  fetchEntityTransactionHistoriesRequest,
  fetchEntityTransactionHistoriesSuccess,
  fetchEntityTransactionHistoriesFailure,
  setAdyenTransactions,
} = paySvcTransactionSlice.actions;

export default paySvcTransactionSlice.reducer;

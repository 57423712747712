import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QuestionDisplayOrderDto, QuestionUpsertDto } from '@/pages/FaqListManagement/type';
import { TopicDisplayOrderDto, TopicUpsertDto } from '@/pages/FaqTopicManagement/type';
import { FaqManagementState, lastSelectedData } from '@/svc/cs-svc/faqManagement/type';
import Service from '@/svc/serviceName';

export const faqManagementInitialState: FaqManagementState = {
  flag: {
    isFetching: false,
  },
  lastSelectedData: {},
};

const faqManagementFailure = {
  prepare: (error: string) => ({ payload: error }),
  reducer: (state: FaqManagementState) => {
    state.flag.isFetching = false;
  },
};

const faqManagementSlice = createSlice({
  name: `${Service.csSvc}/faqManagement`,
  initialState: faqManagementInitialState,
  reducers: {
    upsertFaqTopicRequest: {
      prepare: (body: TopicUpsertDto) => ({ payload: body }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    upsertFaqTopicSuccess(state) {
      state.flag.isFetching = false;
    },
    upsertFaqTopicFailure: faqManagementFailure,

    deleteFaqTopicRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    deleteFaqTopicSuccess(state) {
      state.flag.isFetching = false;
    },
    deleteFaqTopicFailure: faqManagementFailure,

    upsertFaqQuestionRequest: {
      prepare: (body: QuestionUpsertDto) => ({ payload: body }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    upsertFaqQuestionSuccess(state) {
      state.flag.isFetching = false;
    },
    upsertFaqQuestionFailure: faqManagementFailure,

    deleteFaqQuestionRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    deleteFaqQuestionSuccess(state) {
      state.flag.isFetching = false;
    },
    deleteFaqQuestionFailure: faqManagementFailure,

    releaseFaqQuestionRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    releaseFaqQuestionSuccess(state) {
      state.flag.isFetching = false;
    },
    releaseFaqQuestionFailure: faqManagementFailure,

    rejectFaqQuestionRequest: {
      prepare: (id: number) => ({ payload: id }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    rejectFaqQuestionSuccess(state) {
      state.flag.isFetching = false;
    },
    rejectFaqQuestionFailure: faqManagementFailure,

    bulkUpdateDisplayOrderTopicRequest: {
      prepare: (data: TopicDisplayOrderDto[]) => ({ payload: data }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    bulkUpdateDisplayOrderTopicSuccess(state) {
      state.flag.isFetching = false;
    },
    bulkUpdateDisplayOrderTopicFailure: faqManagementFailure,

    bulkUpdateDisplayOrderQuestionRequest: {
      prepare: (data: QuestionDisplayOrderDto[]) => ({ payload: data }),
      reducer: (state) => {
        state.flag.isFetching = true;
      },
    },
    bulkUpdateDisplayOrderQuestionSuccess(state) {
      state.flag.isFetching = false;
    },
    bulkUpdateDisplayOrderQuestionFailure: faqManagementFailure,

    setLastSelectedData(state, { payload }: PayloadAction<lastSelectedData>) {
      state.lastSelectedData = payload;
    },
  },
});

export const {
  upsertFaqTopicRequest,
  upsertFaqTopicSuccess,
  upsertFaqTopicFailure,
  deleteFaqTopicRequest,
  deleteFaqTopicSuccess,
  deleteFaqTopicFailure,
  upsertFaqQuestionRequest,
  upsertFaqQuestionSuccess,
  upsertFaqQuestionFailure,
  deleteFaqQuestionRequest,
  deleteFaqQuestionSuccess,
  deleteFaqQuestionFailure,
  releaseFaqQuestionRequest,
  releaseFaqQuestionSuccess,
  releaseFaqQuestionFailure,
  rejectFaqQuestionRequest,
  rejectFaqQuestionSuccess,
  rejectFaqQuestionFailure,
  bulkUpdateDisplayOrderTopicRequest,
  bulkUpdateDisplayOrderTopicSuccess,
  bulkUpdateDisplayOrderTopicFailure,
  bulkUpdateDisplayOrderQuestionRequest,
  bulkUpdateDisplayOrderQuestionSuccess,
  bulkUpdateDisplayOrderQuestionFailure,
  setLastSelectedData,
} = faqManagementSlice.actions;

export default faqManagementSlice.reducer;

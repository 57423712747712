import qs from 'qs';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { updateGooglePlaceFailure, updateGooglePlaceRequest, updateGooglePlaceSuccess } from '@/svc/place-svc/googlePlace/reducer';
import { authenticatedRequest } from '@/utils/request';

function* updateGooglePlace({ payload: { id, data, param } }: ReturnType<typeof updateGooglePlaceRequest>): IterableIterator {
  try {
    const languageParam = `${qs.stringify(param)}`;
    const response = yield authenticatedRequest.put(`/places/autocompleteGoogle/${id}?${languageParam}`, {
      data,
    });

    const action = response.ok ? updateGooglePlaceSuccess() : updateGooglePlaceFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(updateGooglePlaceFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(updateGooglePlaceRequest.type, updateGooglePlace);
}

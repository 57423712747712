import { createAction } from '@reduxjs/toolkit';

import { DynamicSurgeSettingGroupDto } from '@/pages/DynamicSurgeSetting/type';
import Service from '@/svc/serviceName';

const PREFIX = `${Service.rideSvc}/dynamicSurgeSetting`;

export const createDynamicSurgeSettingRequest = createAction<DynamicSurgeSettingGroupDto>(`${PREFIX}/createDynamicSurgeSettingRequest`);
export const createDynamicSurgeSettingSuccess = createAction<DynamicSurgeSettingGroupDto>(`${PREFIX}/createDynamicSurgeSettingSuccess`);
export const createDynamicSurgeSettingFailure = createAction<string>(`${PREFIX}/createDynamicSurgeSettingFailure`);

export const updateDynamicSurgeSettingRequest = createAction<{ id: string; data: DynamicSurgeSettingGroupDto }>(
  `${PREFIX}/updateDynamicSurgeSettingRequest`
);
export const updateDynamicSurgeSettingSuccess = createAction(`${PREFIX}/updateDynamicSurgeSettingSuccess`);
export const updateDynamicSurgeSettingFailure = createAction<string>(`${PREFIX}/updateDynamicSurgeSettingFailure`);

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlaceInterface, ReverseGeoQuery, SuggestionPlaceDetailQuery, SuggestionPlaceDto, SuggestionPlaceListQuery } from '@/pages/Place/type';
import { ListResponse, PaginationStoreState, StandardStoreState } from '@/utils/types';

export interface PlaceState extends PaginationStoreState, StandardStoreState {
  isUploadingSubPlaceExcel: boolean;
  isUpdatingPlace: boolean;
  isDeletingPlace: boolean;
  isCreatingSubPlace: boolean;
  isUpdatingSubPlace: boolean;
  isDeletingSubPlace: boolean;
  isFetchingSuggestionPlaceList: boolean;
  isFetchingSuggestionPlaceDetail: boolean;
  isFetchingReverseGeo: boolean;
  suggestionPlaces: SuggestionPlaceDto[];
}

const PREFIX = 'place';

export const createPlaceRequest = createAction<FormData>(`${PREFIX}/createPlaceRequest`);
export const createPlaceSuccess = createAction(`${PREFIX}/createPlaceSuccess`);
export const createPlaceFailure = createAction<string>(`${PREFIX}/createPlaceFailure`);

const initialState: PlaceState = {
  isFetching: false,
  page: { total: 1, current: 1 },
  ids: [],
  byId: {},
  totalCount: 0,
  error: null,
  isUploadingSubPlaceExcel: false,
  isUpdatingPlace: false,
  isDeletingPlace: false,
  isCreatingSubPlace: false,
  isUpdatingSubPlace: false,
  isDeletingSubPlace: false,
  isFetchingSuggestionPlaceList: false,
  isFetchingSuggestionPlaceDetail: false,
  isFetchingReverseGeo: false,
  suggestionPlaces: [],
};

const placeSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    fetchPlaceDetailRequest: {
      prepare: (id) => ({ payload: id }),
      reducer: (state) => {
        state.error = null;
        state.isFetching = true;
      },
    },
    fetchPlaceDetailSuccess: (state, { payload }: PayloadAction<ListResponse<PlaceInterface>>) => {
      state.isFetching = false;
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    fetchPlaceDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetching = false;
        state.error = payload;
      },
    },
    uploadPlaceAndSubPlaceExcelRequest: {
      prepare: (data: FormData) => ({ payload: { data } }),
      reducer: (state) => {
        state.error = null;
        state.isUploadingSubPlaceExcel = true;
      },
    },
    uploadPlaceAndSubPlaceExcelSuccess: (state) => {
      state.isUploadingSubPlaceExcel = false;
    },
    uploadPlaceAndSubPlaceExcelFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUploadingSubPlaceExcel = false;
        state.error = payload;
      },
    },

    updatePlaceRequest: {
      prepare: (id: string, data: FormData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.error = null;
        state.isUpdatingPlace = true;
      },
    },
    updatePlaceSuccess: (state, { payload }: PayloadAction<ListResponse<PlaceInterface>>) => {
      state.isUpdatingPlace = false;
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    updatePlaceFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdatingPlace = false;
        state.error = payload;
      },
    },

    deletePlaceRequest: {
      prepare: (id: string) => ({ payload: { id } }),
      reducer: (state) => {
        state.error = null;
        state.isDeletingPlace = true;
      },
    },
    deletePlaceSuccess: (state) => {
      state.isDeletingPlace = false;
    },
    deletePlaceFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isDeletingPlace = false;
        state.error = payload;
      },
    },

    createSubPlaceRequest: {
      prepare: (id: string, data: FormData) => ({ payload: { id, data } }),
      reducer: (state) => {
        state.error = null;
        state.isCreatingSubPlace = true;
      },
    },
    createSubPlaceSuccess: (state, { payload }: PayloadAction<ListResponse<PlaceInterface>>) => {
      state.isCreatingSubPlace = false;
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    createSubPlaceFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isCreatingSubPlace = false;
        state.error = payload;
      },
    },

    updateSubPlaceRequest: {
      prepare: (id: string, subPlaceId: string, data: FormData) => ({ payload: { id, subPlaceId, data } }),
      reducer: (state) => {
        state.error = null;
        state.isUpdatingSubPlace = true;
      },
    },
    updateSubPlaceSuccess: (state, { payload }: PayloadAction<ListResponse<PlaceInterface>>) => {
      state.isUpdatingSubPlace = false;
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    updateSubPlaceFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isUpdatingSubPlace = false;
        state.error = payload;
      },
    },

    deleteSubPlaceRequest: {
      prepare: (id: string, subPlaceId: string) => ({ payload: { id, subPlaceId } }),
      reducer: (state) => {
        state.error = null;
        state.isDeletingSubPlace = true;
      },
    },
    deleteSubPlaceSuccess: (state, { payload }: PayloadAction<ListResponse<PlaceInterface>>) => {
      state.isDeletingSubPlace = false;
      state.byId = { ...state.byId, ...payload.entities.content };
    },
    deleteSubPlaceFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isDeletingSubPlace = false;
        state.error = payload;
      },
    },

    fetchSuggestionPlaceListRequest: {
      prepare: (query: SuggestionPlaceListQuery) => ({ payload: { query } }),
      reducer: (state) => {
        state.error = null;
        state.isFetchingSuggestionPlaceList = true;
      },
    },
    fetchSuggestionPlaceListSuccess: (state, { payload }) => {
      state.isFetchingSuggestionPlaceList = false;
      state.suggestionPlaces = payload;
    },
    fetchSuggestionPlaceListFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetchingSuggestionPlaceList = false;
        state.error = payload;
      },
    },

    fetchSuggestionPlaceDetailRequest: {
      prepare: (id: string, query: SuggestionPlaceDetailQuery) => ({ payload: { id, query } }),
      reducer: (state) => {
        state.error = null;
        state.isFetchingSuggestionPlaceDetail = true;
      },
    },
    fetchSuggestionPlaceDetailSuccess: (state, { payload }) => {
      state.isFetchingSuggestionPlaceDetail = false;
      state.suggestionPlaces = [payload];
    },
    fetchSuggestionPlaceDetailFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetchingSuggestionPlaceDetail = false;
        state.error = payload;
      },
    },

    fetchReverseGeoRequest: {
      prepare: (query: ReverseGeoQuery) => ({ payload: { query } }),
      reducer: (state) => {
        state.error = null;
        state.isFetchingReverseGeo = true;
      },
    },
    fetchReverseGeoSuccess: (state, { payload }) => {
      state.isFetchingReverseGeo = false;
      state.suggestionPlaces = [payload];
    },
    fetchReverseGeoFailure: {
      prepare: (error: string) => ({ payload: error }),
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.isFetchingReverseGeo = false;
        state.error = payload;
      },
    },
  },
});

export const {
  fetchPlaceDetailRequest,
  fetchPlaceDetailSuccess,
  fetchPlaceDetailFailure,
  uploadPlaceAndSubPlaceExcelRequest,
  uploadPlaceAndSubPlaceExcelSuccess,
  uploadPlaceAndSubPlaceExcelFailure,
  updatePlaceRequest,
  updatePlaceSuccess,
  updatePlaceFailure,
  deletePlaceRequest,
  deletePlaceSuccess,
  deletePlaceFailure,
  createSubPlaceRequest,
  createSubPlaceSuccess,
  createSubPlaceFailure,
  updateSubPlaceRequest,
  updateSubPlaceSuccess,
  updateSubPlaceFailure,
  deleteSubPlaceRequest,
  deleteSubPlaceSuccess,
  deleteSubPlaceFailure,
  fetchSuggestionPlaceListRequest,
  fetchSuggestionPlaceListSuccess,
  fetchSuggestionPlaceListFailure,
  fetchSuggestionPlaceDetailRequest,
  fetchSuggestionPlaceDetailSuccess,
  fetchSuggestionPlaceDetailFailure,
  fetchReverseGeoRequest,
  fetchReverseGeoSuccess,
  fetchReverseGeoFailure,
} = placeSlice.actions;

export default placeSlice.reducer;

import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import { authenticatedRequest } from '@/utils/request';

import {
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  createApiKeyRequest,
  createApiKeySuccess,
  createApiKeyFailure,
  deleteApiKeyRequest,
  deleteApiKeySuccess,
  deleteApiKeyFailure,
} from './reducer';

function* resetPassword({ payload: { id, data } }: ReturnType<typeof resetPasswordRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/accounts/${id}/changePassword`, { data });

    if (response.ok) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordFailure(response.data.message));
    }
  } catch (e) {
    yield put(resetPasswordFailure((e as Error).message));
  }
}

function* createApiKey({ payload: id }: ReturnType<typeof createApiKeyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post(`/accounts/${id}/apikeys`);

    if (response.ok) {
      yield put(createApiKeySuccess());
    } else {
      yield put(createApiKeyFailure(response.data.message));
    }
  } catch (e) {
    yield put(createApiKeyFailure((e as Error).message));
  }
}

function* deleteApiKey({ payload: { id, keyId } }: ReturnType<typeof deleteApiKeyRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/accounts/${id}/apikeys/${keyId}`);

    if (response.ok) {
      yield put(deleteApiKeySuccess());
    } else {
      yield put(deleteApiKeyFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteApiKeyFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(resetPasswordRequest.type, resetPassword);
  yield takeLatest(createApiKeyRequest.type, createApiKey);
  yield takeLatest(deleteApiKeyRequest.type, deleteApiKey);
}

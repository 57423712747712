import { Dropdown as DropdownComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

type Direction = 'up' | 'down' | 'left' | 'right';

export interface DropdownProps extends React.HTMLAttributes<HTMLElement> {
  a11y?: boolean;
  disabled?: boolean;
  direction?: Direction;
  group?: boolean;
  isOpen?: boolean;
  nav?: boolean;
  active?: boolean;
  addonType?: boolean | 'prepend' | 'append';
  size?: string;
  tag?: React.ElementType;
  toggle?: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  cssModule?: CSSModule;
  inNavbar?: boolean;
  setActiveFromChild?: boolean;
}

export function Dropdown(props: DropdownProps) {
  return <DropdownComponent {...props} />;
}

import { lazy } from 'react';

import { PATH } from '@/assets/constants/path';
import { ActionType } from '@/components/CheckPermission';
import { RouteProps } from '@/utils/types';

const List = lazy(() => import(/* webpackChunkName: "FaqTopicManagementList" */ '@/pages/FaqTopicManagement/List'));
const New = lazy(() => import(/* webpackChunkName: "FaqTopicManagementNew" */ '@/pages/FaqTopicManagement/New'));
const Detail = lazy(() => import(/* webpackChunkName: "FaqTopicManagementDetail" */ '@/pages/FaqTopicManagement/Detail'));
const Edit = lazy(() => import(/* webpackChunkName: "FaqTopicManagementEdit" */ '@/pages/FaqTopicManagement/Edit'));
const Order = lazy(() => import(/* webpackChunkName: "FaqTopicManagementOrder" */ '@/pages/FaqTopicManagement/Order'));

export const faqTopicManagementRoute: RouteProps[] = [
  {
    permission: ActionType.READ,
    path: PATH.FaqTopicManagement,
    component: List,
    exact: true,
  },
  {
    permission: ActionType.WRITE,
    path: `${PATH.FaqTopicManagement}/new`,
    component: New,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.FaqTopicManagement}/re-order`,
    component: Order,
    exact: true,
  },
  {
    permission: ActionType.READ,
    path: `${PATH.FaqTopicManagement}/:id`,
    component: Detail,
    exact: true,
  },
  {
    permission: ActionType.EDIT,
    path: `${PATH.FaqTopicManagement}/:id/edit`,
    component: Edit,
    exact: true,
  },
];

import { schema } from 'normalizr';
import { put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import {
  fetchDeliveryRidePriceOptionListRequest,
  fetchDeliveryRidePriceOptionListSuccess,
  fetchDeliveryRidePriceOptionListFailure,
  fetchDeliveryRidePriceOptionDetailRequest,
  fetchDeliveryRidePriceOptionDetailSuccess,
  fetchDeliveryRidePriceOptionDetailFailure,
  createDeliveryRidePriceOptionRequest,
  createDeliveryRidePriceOptionSuccess,
  createDeliveryRidePriceOptionFailure,
  updateDeliveryRidePriceOptionRequest,
  updateDeliveryRidePriceOptionSuccess,
  updateDeliveryRidePriceOptionFailure,
  deleteDeliveryRidePriceOptionRequest,
  deleteDeliveryRidePriceOptionSuccess,
  deleteDeliveryRidePriceOptionFailure,
} from '@/pages/DeliveryRidePriceOption/reducer';
import { authenticatedRequest } from '@/utils/request';

const entity = new schema.Entity('content');

function* fetchDeliveryRidePriceOptionList({ payload }: ReturnType<typeof fetchDeliveryRidePriceOptionListRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/delivery/config/options/price', {
      params: payload,
      schema: { content: [entity] },
    });

    if (response.ok) {
      yield put(fetchDeliveryRidePriceOptionListSuccess(response.data));
    } else {
      yield put(fetchDeliveryRidePriceOptionListFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRidePriceOptionListFailure((e as Error).message));
  }
}

function* fetchDeliveryRidePriceOptionDetail({ payload }: ReturnType<typeof fetchDeliveryRidePriceOptionDetailRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get(`/delivery/config/options/price/${payload}`, {
      schema: entity,
    });

    if (response.ok) {
      yield put(fetchDeliveryRidePriceOptionDetailSuccess(response.data));
    } else {
      yield put(fetchDeliveryRidePriceOptionDetailFailure(response.data.message));
    }
  } catch (e) {
    yield put(fetchDeliveryRidePriceOptionDetailFailure((e as Error).message));
  }
}

function* createDeliveryRidePriceOption({ payload }: ReturnType<typeof createDeliveryRidePriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/delivery/config/options/price', { data: payload });

    if (response.ok) {
      yield put(createDeliveryRidePriceOptionSuccess());
    } else {
      yield put(createDeliveryRidePriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(createDeliveryRidePriceOptionFailure((e as Error).message));
  }
}

function* updateDeliveryRidePrieOption({ payload: { id, data } }: ReturnType<typeof updateDeliveryRidePriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/delivery/config/options/price/${id}`, { data });

    if (response.ok) {
      yield put(updateDeliveryRidePriceOptionSuccess());
    } else {
      yield put(updateDeliveryRidePriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(updateDeliveryRidePriceOptionFailure((e as Error).message));
  }
}

function* deleteDeliveryRidePrieOption({ payload }: ReturnType<typeof deleteDeliveryRidePriceOptionRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.delete(`/delivery/config/options/price/${payload}`);

    if (response.ok) {
      yield put(deleteDeliveryRidePriceOptionSuccess());
    } else {
      yield put(deleteDeliveryRidePriceOptionFailure(response.data.message));
    }
  } catch (e) {
    yield put(deleteDeliveryRidePriceOptionFailure((e as Error).message));
  }
}

export default function* () {
  yield takeLatest(fetchDeliveryRidePriceOptionListRequest.type, fetchDeliveryRidePriceOptionList);
  yield takeLatest(fetchDeliveryRidePriceOptionDetailRequest.type, fetchDeliveryRidePriceOptionDetail);
  yield takeLatest(createDeliveryRidePriceOptionRequest.type, createDeliveryRidePriceOption);
  yield takeLatest(updateDeliveryRidePriceOptionRequest.type, updateDeliveryRidePrieOption);
  yield takeLatest(deleteDeliveryRidePriceOptionRequest.type, deleteDeliveryRidePrieOption);
}

import { createAction } from '@reduxjs/toolkit';

import { RestrictedArea, RestrictedAreaDto } from '@/pages/RestrictedAreas/type';

const PREFIX = `restrictedArea`;

export const createRestrictedAreaRequest = createAction<RestrictedAreaDto>(`${PREFIX}/createRestrictedAreaRequest`);
export const createRestrictedAreaSuccess = createAction<RestrictedArea>(`${PREFIX}/createRestrictedAreaSuccess`);
export const createRestrictedAreaFailure = createAction<string>(`${PREFIX}/createRestrictedAreaFailure`);

export const updateRestrictedAreaRequest = createAction('restrictedArea/updateRestrictedAreaRequest', (id: string, body: RestrictedAreaDto) => ({
  payload: { id, body },
}));
export const updateRestrictedAreaSuccess = createAction('restrictedArea/updateRestrictedAreaSuccess');
export const updateRestrictedAreaFailure = createAction<string>('restrictedArea/updateRestrictedAreaFailure');

export const deleteRestrictedAreaRequest = createAction<string>(`${PREFIX}/deleteRestrictedAreaRequest`);
export const deleteRestrictedAreaSuccess = createAction(`${PREFIX}/deleteRestrictedAreaSuccess`);
export const deleteRestrictedAreaFailure = createAction<string>(`${PREFIX}/deleteRestrictedAreaFailure`);

export const syncRestrictedAreaRequest = createAction<string>(`${PREFIX}/syncRestrictedAreaRequest`);
export const syncRestrictedAreaSuccess = createAction(`${PREFIX}/syncRestrictedAreaSuccess`);
export const syncRestrictedAreaFailure = createAction<string>(`${PREFIX}/syncRestrictedAreaFailure`);

import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { Button } from '@/components/template';
import { dispatch } from '@/store';
import { useAppState } from '@/store/selector';
import { closePopup } from '@/svc/common/reducer';
import { selectConfirmData, selectBulkSyncDriverincentiveOpenFlag } from '@/svc/common/selector';
import { POPUP_TYPE } from '@/svc/common/type';

const popupStyle = {
  padding: '50px',
  minWidth: '300px',
  maxWidth: '700px',
  maxHeight: '80%',
  overflow: 'auto',
  minHeight: '200px',
};

const Wrapper = styled.div`
  footer {
    display: flex;
    gap: 15px;
    margin-top: 25px;

    > button {
      flex: 1;
    }
  }
`;

function BulkSyncDriverIncentivePopup() {
  const open = useAppState(selectBulkSyncDriverincentiveOpenFlag);
  const { ok, content } = useAppState(selectConfirmData);

  const close = () => {
    dispatch(closePopup(POPUP_TYPE.BulkSyncDriverIncentive));
  };

  return (
    <Popup contentStyle={popupStyle} open={open} onClose={close} modal>
      {() => (
        <Wrapper>
          {content}
          <footer>
            <Button
              color='primary'
              onClick={() => {
                close();
                ok();
              }}
            >
              Ok
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </footer>
        </Wrapper>
      )}
    </Popup>
  );
}

export default BulkSyncDriverIncentivePopup;

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { IterableIterator } from '@tada/tada-web-common';

import googlePlaceSaga from '@/svc/place-svc/googlePlace/saga';
import placeTesterSaga from '@/svc/place-svc/placeTester/saga';
import {
  bulkUpdatePlaceDisplayOrderFailure,
  bulkUpdatePlaceDisplayOrderRequest,
  bulkUpdatePlaceDisplayOrderSuccess,
  bulkUpdateSubPlaceDisplayOrderFailure,
  bulkUpdateSubPlaceDisplayOrderRequest,
  bulkUpdateSubPlaceDisplayOrderSuccess,
  downloadBulkPlaceTemplateFailure,
  downloadBulkPlaceTemplateRequest,
  downloadBulkPlaceTemplateSuccess,
  exportPlaceFailure,
  exportPlaceRequest,
  exportPlaceSuccess,
} from '@/svc/place-svc/reducer';
import userSubmissionSaga from '@/svc/place-svc/userSubmission/saga';
import { saveFile } from '@/utils/etc';
import { authenticatedRequest } from '@/utils/request';

function* bulkUpdatePlaceDisplayOrder({ payload }: ReturnType<typeof bulkUpdatePlaceDisplayOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.post('/places/bulkUpdateDisplayOrder', { data: payload });

    const action = response.ok ? bulkUpdatePlaceDisplayOrderSuccess() : bulkUpdatePlaceDisplayOrderFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdatePlaceDisplayOrderFailure((e as Error).message));
  }
}

function* bulkUpdateSubPlaceDisplayOrder({ payload: { id, data } }: ReturnType<typeof bulkUpdateSubPlaceDisplayOrderRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.put(`/places/${id}/subPlaces/order`, { data });

    const action = response.ok ? bulkUpdateSubPlaceDisplayOrderSuccess() : bulkUpdateSubPlaceDisplayOrderFailure(response.data.message);

    yield put(action);
  } catch (e) {
    yield put(bulkUpdateSubPlaceDisplayOrderFailure((e as Error).message));
  }
}

function* downloadBulkPlaceTemplate(): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/places/bulkUploadPlaceAndSubPlace:upload', {
      responseType: 'blob',
      headers: { accept: '*/*' },
    });

    if (response.ok) {
      saveFile(response, 'place_bulk_upsert_template.xlsx');
      yield put(downloadBulkPlaceTemplateSuccess());
    } else {
      yield put(downloadBulkPlaceTemplateFailure(response.data.message));
    }
  } catch (e) {
    yield put(downloadBulkPlaceTemplateFailure((e as Error).message));
  }
}

function* exportPlace({ payload }: ReturnType<typeof exportPlaceRequest>): IterableIterator {
  try {
    const response = yield authenticatedRequest.get('/places/export', {
      params: payload,
      responseType: 'blob',
    });

    if (response.ok) {
      saveFile(response, 'export_place.xlsx');
      yield put(exportPlaceSuccess());
    } else {
      yield put(exportPlaceFailure(response.data.message));
    }
  } catch (e) {
    yield put(exportPlaceFailure((e as Error).message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(bulkUpdatePlaceDisplayOrderRequest.type, bulkUpdatePlaceDisplayOrder);
  yield takeLatest(bulkUpdateSubPlaceDisplayOrderRequest.type, bulkUpdateSubPlaceDisplayOrder);
  yield takeLatest(downloadBulkPlaceTemplateRequest.type, downloadBulkPlaceTemplate);
  yield takeLatest(exportPlaceRequest.type, exportPlace);
  yield all([call(placeTesterSaga), call(googlePlaceSaga), call(userSubmissionSaga)]);
}

import { createAction, createSlice } from '@reduxjs/toolkit';

export interface DynamicSurgeState {
  zones: any[];
  isFetching: boolean;
  isUpdatingDynamicSurge: boolean;
  isUpdatingAllDynamicSurge: boolean;
  isCreatingDynamicSurge: boolean;
  errorMessage: string | null;
}

const initialState: DynamicSurgeState = {
  zones: [],
  isFetching: false,
  isUpdatingDynamicSurge: false,
  isUpdatingAllDynamicSurge: false,
  isCreatingDynamicSurge: false,
  errorMessage: null,
};

export const fetchSurgeHotZoneRequest = createAction<string>('dynamicSurge/fetchSurgeHotZoneRequest');
export const fetchSurgeHotZoneSuccess = createAction<any[]>('dynamicSurge/fetchSurgeHotZoneSuccess');
export const fetchSurgeHotZoneFailure = createAction<string>('dynamicSurge/fetchSurgeHotZoneFailure');

export const fetchDynamicHotZoneRequest = createAction<{ cityCode: string; timeSlot: number }>('dynamicSurge/fetchDynamicHotZoneRequest');
export const fetchDynamicHotZoneSuccess = createAction<any[]>('dynamicSurge/fetchDynamicHotZoneSuccess');
export const fetchDynamicHotZoneFailure = createAction<string>('dynamicSurge/fetchDynamicHotZoneFailure');

export const updateDynamicHotZoneRequest = createAction<{ cityCode: string; timeSlot: number }>('dynamicSurge/updateDynamicHotZoneRequest');
export const updateDynamicHotZoneSuccess = createAction<any[]>('dynamicSurge/updateDynamicHotZoneSuccess');
export const updateDynamicHotZoneFailure = createAction<string>('dynamicSurge/updateDynamicHotZoneFailure');

const dynamicSurgeSlice = createSlice({
  name: 'dynamicSurge',
  initialState,
  reducers: {
    clearZone(state) {
      state.zones = [];
    },
    fetchDynamicSurgeRequest: {
      prepare: (cityCode, param) => ({ payload: { cityCode, param } }),
      reducer: (state) => {
        state.isFetching = true;
        state.errorMessage = null;
        state.zones = [];
      },
    },
    fetchDynamicSurgeSuccess(state, { payload }) {
      state.zones = payload;
      state.isFetching = false;
    },
    fetchDynamicSurgeFailure(state, { payload }) {
      state.isFetching = false;
      state.errorMessage = payload;
    },
    updateDynamicSurgeRequest: {
      prepare: (id, body) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.isUpdatingDynamicSurge = true;
        state.errorMessage = null;
      },
    },
    updateDynamicSurgeSuccess(state, { payload }) {
      const idx = state.zones.findIndex(({ id }) => id === payload.id);

      state.zones[idx] = payload;
      state.isUpdatingDynamicSurge = false;
    },
    updateDynamicSurgeFailure(state, { payload }) {
      state.isUpdatingDynamicSurge = false;
      state.errorMessage = payload;
    },
    createDynamicSurgeRequest: {
      prepare: (body) => ({ payload: body }),
      reducer: (state) => {
        state.isCreatingDynamicSurge = true;
        state.errorMessage = null;
      },
    },
    createDynamicSurgeSuccess(state) {
      state.isCreatingDynamicSurge = false;
    },
    createDynamicSurgeFailure(state, { payload }) {
      state.isCreatingDynamicSurge = false;
      state.errorMessage = payload;
    },
    updateAllDynamicSurgeRequest: {
      prepare: (body) => ({ payload: body }),
      reducer: (state) => {
        state.isUpdatingAllDynamicSurge = true;
        state.errorMessage = null;
      },
    },
    updateAllDynamicSurgeSuccess(state) {
      state.isUpdatingAllDynamicSurge = false;
    },
    updateAllDynamicSurgeFailure(state, { payload }) {
      state.isUpdatingAllDynamicSurge = false;
      state.errorMessage = payload;
    },
  },
});

export const {
  clearZone,
  fetchDynamicSurgeRequest,
  fetchDynamicSurgeSuccess,
  fetchDynamicSurgeFailure,
  updateDynamicSurgeRequest,
  updateDynamicSurgeSuccess,
  updateDynamicSurgeFailure,
  createDynamicSurgeRequest,
  createDynamicSurgeSuccess,
  createDynamicSurgeFailure,
  updateAllDynamicSurgeRequest,
  updateAllDynamicSurgeSuccess,
  updateAllDynamicSurgeFailure,
} = dynamicSurgeSlice.actions;

export default dynamicSurgeSlice.reducer;

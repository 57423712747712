import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ListResponse } from '@tada/tada-web-common';

import { Region, RegionQuery, RegionState } from '@/pages/Regions/type';
import { listSuccess, detailSuccess } from '@/utils/reducerUtils';

const initialState: RegionState = {
  ids: [],
  byId: {},
  totalCount: 0,
  page: { total: 1, current: 1 },
  isFetching: false,
  isUpdating: false,
  error: null,
  regionList: [],
};

const failure = (state: RegionState, { payload }: PayloadAction<string>) => {
  state.isFetching = false;
  state.error = payload;
};

export const fetchRegionListWithoutPageRequest = createAction('region/fetchRegionListWithoutPageRequest');

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    fetchRegionListRequest: {
      prepare: (payload?: RegionQuery) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchRegionListSuccess(state, action) {
      Object.assign(state, listSuccess(state, action));
    },
    fetchRegionListFailure: failure,

    fetchRegionDetailRequest: {
      prepare: (id) => ({ payload: id }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    fetchRegionDetailSuccess(state, action) {
      Object.assign(state, detailSuccess(state, action));
    },
    fetchRegionDetailFailure: failure,

    createRegionRequest: {
      prepare: (body) => ({ payload: body }),
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
    },
    createRegionSuccess: {
      prepare: (region) => ({ payload: region }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    createRegionFailure: failure,

    updateRegionRequest: {
      prepare: (id, body) => ({ payload: { id, body } }),
      reducer: (state) => {
        state.isUpdating = true;
        state.error = null;
      },
    },
    updateRegionSuccess: {
      prepare: (region) => ({ payload: region }),
      reducer: (state) => {
        state.isUpdating = false;
      },
    },
    updateRegionFailure(state, { payload }) {
      state.isUpdating = false;
      state.error = payload;
    },
    fetchRegionListWithoutPageSuccess(state, { payload }: PayloadAction<ListResponse<Region>>) {
      state.regionList = payload.content;
    },
  },
});

export const {
  fetchRegionListRequest,
  fetchRegionListSuccess,
  fetchRegionListFailure,
  fetchRegionDetailRequest,
  fetchRegionDetailSuccess,
  fetchRegionDetailFailure,
  createRegionRequest,
  createRegionSuccess,
  createRegionFailure,
  updateRegionRequest,
  updateRegionSuccess,
  updateRegionFailure,
  fetchRegionListWithoutPageSuccess,
} = regionSlice.actions;

export default regionSlice.reducer;

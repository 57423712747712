import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { InternalDocumentProgressCheckRequest, InternalDocumentProgressCheckResponse } from '@/pages/DriverApplications/type';
import { SimpleDriver } from '@/pages/Drivers/type';
import documentGuideReducer, { documentGuideInitialState } from '@/svc/member-svc/documentGuide/reducer';
import { DocumentGuideState } from '@/svc/member-svc/documentGuide/type';
import driverIncentiveReducer, { driverIncentiveInitialState } from '@/svc/member-svc/driverIncentive/reducer';
import { DriverIncentiveState } from '@/svc/member-svc/driverIncentive/type';
import referralReducer, { referralInitialState } from '@/svc/member-svc/referral/reducer';
import { ReferralState } from '@/svc/member-svc/referral/type';
import { AdminGenerateDocumentsDto, RemoveFieldValuesDto, SyncTemplateDto } from '@/svc/member-svc/type';
import userReducer, { userInitialState } from '@/svc/member-svc/user/reducer';
import { UserState } from '@/svc/member-svc/user/type';
import Service from '@/svc/serviceName';

export interface MemberSvcState {
  isFetching: boolean;
  error: string | null;
  bulkUpdateResult: Record<string, string> | null;
  search: {
    driver: SimpleDriver[];
    rider: any[];
  };
  user: UserState;
  driverIncentive: DriverIncentiveState;
  referral: ReferralState;
  documentGuide: DocumentGuideState;
}

const initialState: MemberSvcState = {
  isFetching: false,
  error: null,
  bulkUpdateResult: null,
  search: {
    driver: [],
    rider: [],
  },
  user: userInitialState,
  driverIncentive: driverIncentiveInitialState,
  referral: referralInitialState,
  documentGuide: documentGuideInitialState,
};

export const fetchBulkUpdateEquipmentsTemplateRequest = createAction(`${Service.memberSvc}/fetchBulkUpdateEquipmentsTemplateRequest`);
export const bulkUpdateEquipmentsFailure = createAction<string>(`${Service.memberSvc}/bulkUpdateEquipmentsFailure`);
export const searchDriverRequest = createAction<{ query: string; withVehicle?: boolean }>(`${Service.memberSvc}/searchDriverRequest`);
export const searchRiderRequest = createAction<string>(`${Service.memberSvc}/searchRiderRequest`);
export const toggleOnProbationRequest = createAction(
  `${Service.memberSvc}/toggleOnProbationRequest`,
  (id: string, data: { onProbation: boolean }) => ({ payload: { id, data } })
);
export const toggleOnProbationSuccess = createAction(`${Service.memberSvc}/toggleOnProbationSuccess`);
export const toggleOnProbationFailure = createAction<string>(`${Service.memberSvc}/toggleOnProbationFailure`);

export const generateDocumentRequest = createAction<AdminGenerateDocumentsDto>(`${Service.memberSvc}/generateDocumentRequest`);
export const generateDocumentSuccess = createAction(`${Service.memberSvc}/generateDocumentSuccess`);
export const generateDocumentFailure = createAction<string>(`${Service.memberSvc}/generateDocumentFailure`);

export const syncTemplateRequest = createAction<SyncTemplateDto>(`${Service.memberSvc}/syncTemplateRequest`);
export const syncTemplateSuccess = createAction(`${Service.memberSvc}/syncTemplateSuccess`);
export const syncTemplateFailure = createAction<string>(`${Service.memberSvc}/syncTemplateFailure`);

export const fetchBulkResignTemplateRequest = createAction<string | undefined>(`${Service.memberSvc}/fetchBulkResignTemplateRequest`);

export const fetchBulkVaccinationTemplateRequest = createAction(`${Service.memberSvc}/fetchBulkVaccinationTemplateRequest`);

export const blockDriverReferralCodeRequest = createAction<string>(`${Service.memberSvc}/blockDriverReferralCodeRequest`);
export const blockDriverReferralCodeSuccess = createAction(`${Service.memberSvc}/blockDriverReferralCodeSuccess`);
export const blockDriverReferralCodeFailure = createAction<string>(`${Service.memberSvc}/blockDriverReferralCodeFailure`);

export const unBlockDriverReferralCodeRequest = createAction<string>(`${Service.memberSvc}/unBlockDriverReferralCodeRequest`);
export const unBlockDriverReferralCodeSuccess = createAction(`${Service.memberSvc}/unBlockDriverReferralCodeSuccess`);
export const unBlockDriverReferralCodeFailure = createAction<string>(`${Service.memberSvc}/unBlockDriverReferralCodeFailure`);

export const blockRiderReferralCodeRequest = createAction<string>(`${Service.memberSvc}/blockRiderReferralCodeRequest`);
export const blockRiderReferralCodeSuccess = createAction(`${Service.memberSvc}/blockRiderReferralCodeSuccess`);
export const blockRiderReferralCodeFailure = createAction<string>(`${Service.memberSvc}/blockRiderReferralCodeFailure`);

export const unBlockRiderReferralCodeRequest = createAction<string>(`${Service.memberSvc}/unBlockRiderReferralCodeRequest`);
export const unBlockRiderReferralCodeSuccess = createAction(`${Service.memberSvc}/unBlockRiderReferralCodeSuccess`);
export const unBlockRiderReferralCodeFailure = createAction<string>(`${Service.memberSvc}/unBlockRiderReferralCodeFailure`);

export const toggleTestAccountRequest = createAction<{ id: string; flag: boolean }>(`${Service.memberSvc}/toggleTestAccountRequest`);
export const toggleTestAccountSuccess = createAction(`${Service.memberSvc}/toggleTestAccountSuccess`);
export const toggleTestAccountFailure = createAction<string>(`${Service.memberSvc}/toggleTestAccountFailure`);

export const deleteVlIdRequest = createAction<{ id: string; data: RemoveFieldValuesDto }>(`${Service.memberSvc}/deleteVlIdRequest`);
export const deleteVlIdSuccess = createAction(`${Service.memberSvc}/deleteVlIdSuccess`);
export const deleteVlIdFailure = createAction<string>(`${Service.memberSvc}/deleteVlIdFailure`);

export const resetMyDestinationCountRequest = createAction<string>(`${Service.memberSvc}/resetMyDestinationCountRequest`);
export const resetMyDestinationCountSuccess = createAction(`${Service.memberSvc}/resetMyDestinationCountSuccess`);
export const resetMyDestinationCountFailure = createAction<string>(`${Service.memberSvc}/resetMyDestinationCountFailure`);

export const fetchDriverBulkBanTemplateRequest = createAction(`${Service.memberSvc}/fetchDriverBulkBanTemplateRequest`);
export const fetchDriverBulkBanTemplateSuccess = createAction(`${Service.memberSvc}/fetchDriverBulkBanTemplateSuccess`);
export const fetchDriverBulkBanTemplateFailure = createAction<string>(`${Service.memberSvc}/fetchDriverBulkBanTemplateFailure`);

export const bulkBanDriverRequest = createAction<FormData>(`${Service.memberSvc}/bulkBanDriverRequest`);
export const bulkBanDriverSuccess = createAction(`${Service.memberSvc}/bulkBanDriverSuccess`);
export const bulkBanDriverFailure = createAction<string>(`${Service.memberSvc}/bulkBanDriverFailure`);

export const editChangeRequestDocumentRequest = createAction<{ id: string; data: FormData }>(`${Service.memberSvc}/editChangeRequestDocumentRequest`);
export const editChangeRequestDocumentSuccess = createAction(`${Service.memberSvc}/editChangeRequestDocumentSuccess`);
export const editChangeRequestDocumentFailure = createAction<string>(`${Service.memberSvc}/editChangeRequestDocumentFailure`);

export const downloadInternalDocumentTemplateRequest = createAction(`${Service.memberSvc}/downloadInternalDocumentTemplateRequest`);
export const downloadInternalDocumentTemplateSuccess = createAction(`${Service.memberSvc}/downloadInternalDocumentTemplateSuccess`);
export const downloadInternalDocumentTemplateFailure = createAction<string>(`${Service.memberSvc}/downloadInternalDocumentTemplateFailure`);

export const createInternalDocumentProgressRequest = createAction<{ params: { region: string }; file: FormData }>(
  `${Service.memberSvc}/createInternalDocumentProgressRequest`
);
export const createInternalDocumentProgressSuccess = createAction(`${Service.memberSvc}/createInternalDocumentProgressSuccess`);
export const createInternalDocumentProgressFailure = createAction<string>(`${Service.memberSvc}/createInternalDocumentProgressFailure`);

export const validateProgressRequest = createAction<InternalDocumentProgressCheckRequest>(`${Service.memberSvc}/validateProgressRequest`);
export const validateProgressSuccess = createAction<InternalDocumentProgressCheckResponse>(`${Service.memberSvc}/validateProgressSuccess`);
export const validateProgressFailure = createAction<string>(`${Service.memberSvc}/validateProgressFailure`);

export const uploadProgressRequest = createAction<{ id: string; uploadIdx: number; data: FormData }>(`${Service.memberSvc}/uploadProgressRequest`);
export const uploadProgressSuccess = createAction<number>(`${Service.memberSvc}/uploadProgressSuccess`);
export const uploadProgressFailure = createAction<string>(`${Service.memberSvc}/uploadProgressFailure`);

const memberSvcSlice = createSlice({
  name: Service.memberSvc,
  initialState,
  reducers: {
    generateDriverInfoUpsertRequest: {
      reducer: (state) => {
        state.isFetching = true;
        state.error = null;
      },
      prepare: (id: string) => ({ payload: id }),
    },
    generateDriverInfoUpsertSuccess(state) {
      state.isFetching = false;
    },
    generateDriverInfoUpsertFailure(state, { payload }: PayloadAction<string>) {
      state.isFetching = false;
      state.error = payload;
    },
    bulkUpdateEquipmentsRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.bulkUpdateResult = null;
      },
    },
    bulkUpdateEquipmentsSuccess(state, { payload }: PayloadAction<{ results: Record<string, string> }>) {
      state.bulkUpdateResult = payload.results;
    },
    searchDriverSuccess(state, { payload }: PayloadAction<SimpleDriver[]>) {
      state.search.driver = payload;
    },
    searchRiderSuccess(state, { payload }: PayloadAction<any[]>) {
      state.search.rider = payload;
    },
    bulkResignRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    bulkResignSuccess(state) {
      state.isFetching = false;
    },
    bulkResignFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    bulkFailRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    bulkFailSuccess(state) {
      state.isFetching = false;
    },
    bulkFailFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },

    bulkApproveRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    bulkApproveSuccess(state) {
      state.isFetching = false;
    },
    bulkApproveFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
    bulkVaccinationRequest: {
      prepare: (payload: FormData) => ({ payload }),
      reducer: (state) => {
        state.isFetching = true;
      },
    },
    bulkVaccinationSuccess(state) {
      state.isFetching = false;
    },
    bulkVaccinationFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetching = false;
      },
    },
  },
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      Object.assign(state.driverIncentive, driverIncentiveReducer(state.driverIncentive, action));
      Object.assign(state.user, userReducer(state.user, action));
      Object.assign(state.referral, referralReducer(state.referral, action));
      Object.assign(state.documentGuide, documentGuideReducer(state.documentGuide, action));
    }),
});

export const {
  generateDriverInfoUpsertRequest,
  generateDriverInfoUpsertSuccess,
  generateDriverInfoUpsertFailure,
  bulkUpdateEquipmentsRequest,
  bulkUpdateEquipmentsSuccess,
  searchDriverSuccess,
  searchRiderSuccess,
  bulkResignRequest,
  bulkResignSuccess,
  bulkResignFailure,
  bulkFailRequest,
  bulkFailSuccess,
  bulkFailFailure,
  bulkApproveRequest,
  bulkApproveSuccess,
  bulkApproveFailure,
  bulkVaccinationRequest,
  bulkVaccinationSuccess,
  bulkVaccinationFailure,
} = memberSvcSlice.actions;

export default memberSvcSlice.reducer;

import { Row as RowComponent } from 'reactstrap';

import { CSSModule } from '@/components/template/type';

export interface RowProps extends Omit<React.HTMLAttributes<HTMLElement>, 'form'> {
  cssModule?: CSSModule;
  tag?: React.ElementType;
  noGutters?: boolean;
  form?: boolean;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
  row?: boolean;
}

export function Row(props: RowProps) {
  return <RowComponent {...props} />;
}
